import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import serverApi from '../../../_api/server.api';
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from '../../../_constants/queryConstants';
import { processCategoryWidget } from '../../../_utils/chart.utils';
import useCommon from '../../pages/useCommon';


const useCategory = () =>
{
    const common = useCommon();
    const { environmentConstants, filters, hasAccessToProperty } = common;

    const { analyticsRequestFilter } = filters;

    const categoryQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED, { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {

            const response = await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED, environmentConstants);

            if (!response?.success)
            {
                throw new Error("category searched vs visited response was not ok");
            }

            return processCategoryWidget({ ...response.data });
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });

    const boxClassName = React.useMemo(() =>
    {
        if (categoryQuery.isError || (!!categoryQuery.isSuccess && categoryQuery?.data?.categoryBarData.length === 0))
        {
            return "hasMessage";
        }
        else
        {
            return "";
        }

    }, [categoryQuery]);

    const onError = useCallback(() =>
    {
        categoryQuery.refetchQuery();

    }, [
        categoryQuery
    ]);

    return { ...categoryQuery, onError, boxClassName, ...common };
};

export default useCategory;