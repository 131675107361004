import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { environmentConstantsState } from "../../store/AppAtoms";
import
{
    analyticsRequestFilterSelector,
    dateRangeState,
    hasAccessToPropertySelector,
    propertyTimeZoneSelectorState,
    selectedPropertyInfoSelector,
    activeTimeZoneSelectorState,
} from "../../store/DashboardAtoms";
import serverApi from "../../_api/server.api";
import
{
    WEATHER_CHART_ICONS,
    WEATHER_CODE_HASH_MAP,
    WEATHER_LINE_CHART_OPTIONS,
} from "../../_constants/chartConstants";
import { BLUES } from "../../_constants/colors";
import
{
    ANALYTICS_QUERY_KEYS,
    NAV_ANALYTICS_QUERY_KEYS,
    QUERY_OPTIONS,
} from "../../_constants/queryConstants";
import
{
    getDateGroupAndFormat,
    processWeatherVisitsVsTime,
} from "../../_utils/chart.utils";
import { LineChart } from "../charts/LineChart";
import { WidgetLoadingBoxFull } from "../common/placeholders/Placeholders";
import { NotAvailableWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { DefaultBox } from "../dashboard/DefaultBox";
import { celsiusToFahrenheit } from "../../_utils/utils";
import useCsvExportEventEmitter from "../common/ExportToCsv/useCsvExportEventEmitter";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { formatDateValuesFromChartToCSV } from "../../_utils/exportToCsvUtils";

export const WeatherAnalyticsWidget = ({ generatePdf }) =>
{
    const trans = useTranslation().t;

    const dateRange = useRecoilValue(dateRangeState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);
    const propertyInfo = useRecoilValue(selectedPropertyInfoSelector);
    const { analyticsRequestFilter } = useRecoilValue(
        analyticsRequestFilterSelector
    );
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    const propertyTimeZoneId = useRecoilValue(propertyTimeZoneSelectorState);

    // set minimum date range to one week
    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        dateRange.startDate,
        dateRange.endDate
    );
    const [isChecked, setIsChecked] = useState(true);
    const exportHandlers = useCsvExportEventEmitter();
    const { injectProcessedData } = useExportDataConnector("Flows");

    // get visits for date range
    const visitsQuery = useQuery(
        [
            "UserFlow",
            NAV_ANALYTICS_QUERY_KEYS.USER_VS_TIME,
            { analyticsRequestFilter, environmentConstants, timeZone },
        ],
        async () =>
        {
            if (!!analyticsRequestFilter && environmentConstants)
            {
                let result = await serverApi.getNavigationAnalyticsData(
                    analyticsRequestFilter,
                    NAV_ANALYTICS_QUERY_KEYS.USER_VS_TIME,
                    environmentConstants
                );

                if (!result?.success)
                {
                    throw new Error("User vs time response was not ok");
                }

                const dateRange = {
                    startDate: new Date(analyticsRequestFilter.startTimeUTC),
                    endDate: new Date(analyticsRequestFilter.endTimeUTC),
                };

                const { returningUsers, newUsers } = result.data;

                return { returningUsers, newUsers, dateRange, timeZone: { id: propertyTimeZoneId, name: propertyTimeZoneId } };
            }
            else
            {
                throw new Error("Filter was not ok");
            }
        },
        {
            enabled:
                !!analyticsRequestFilter
                && !!environmentConstants
                && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );

    // send date range and location to API /api/v1/weather/history/{latt}/{long}/{startUnixTime}/{endUnixTime}
    const weatherQuery = useQuery(
        [
            ANALYTICS_QUERY_KEYS.WEATHER,
            { dateRange, hasAccessToProperty, propertyInfo },
        ],
        async () =>
        {
            const propertyLocation = propertyInfo?.centroid?.coordinates;
            const result = await serverApi.getWeatherData(
                propertyLocation,
                dateRange
            );

            if (!result)
            {
                throw new Error("Weather response was not ok");
            }

            return result.data;
        },
        {
            enabled: !!propertyInfo && !!dateRange && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );

    const processedVisitsWeatherQuery = useMemo(() =>
    {
        const isError = weatherQuery.data === undefined && visitsQuery.isError;
        const isSuccess = !!weatherQuery.data && visitsQuery.isSuccess;
        // const isError = visitsQuery.isError;
        // const isSuccess = visitsQuery.isSuccess;

        let data;

        if (isSuccess && !isError)
        {
            data = processWeatherVisitsVsTime(
                visitsQuery.data,
                weatherQuery.data,
                dateRange
            );
        }

        return { isError, isSuccess, data };
    }, [weatherQuery, visitsQuery, dateRange]);

    const dateLabelBasedOnDateFormat = useMemo(() =>
    {
        let label = "";
        if (dateGroupFormat === "MMM yyyy")
        {
            label = "Month";
        } else if (dateGroupFormat == "h aa")
        {
            label = "Time";
        } else
        {
            label = "Date";
        }
        return label;
    }, [dateGroupFormat]);

    useEffect(() =>
    {

        if (!exportHandlers.isDataSelectionModalOpen) 
        {
            return;
        }

        const modifiedData = processedVisitsWeatherQuery?.data?.chartData?.flatMap((weatherInfo) =>
        {
            return weatherInfo.data.map((wtitem) =>
            {
                const { averageTemperature } = wtitem.weather;

                return {
                    [dateLabelBasedOnDateFormat]: formatDateValuesFromChartToCSV(wtitem.date, dateGroupFormat),
                    AverageTemperature: isChecked ? `${averageTemperature.toFixed(1)} °C` : `${celsiusToFahrenheit(averageTemperature).toFixed(2)} °F`,
                    Status: WEATHER_CODE_HASH_MAP[wtitem.weather.weathercode].description,
                    NewVisits: wtitem.newVisits,
                    Visits: wtitem.y,
                    AverageDurationMins: wtitem.averageDuration,
                    AverageDurationFormatted: wtitem.averageDurationFormatted


                };
            });
            // return 
        });

        const payloads = [
            {
                name: "Weather vs number of visitors",
                data: modifiedData || [],
                description: "",
                inject: injectProcessedData
            },

            // {
            //     name: "Visit Data",
            //     data: visitsQuery,
            //     description: "",
            //     inject: injectRawData
            // },
            // {
            //     name: "Weather Data",
            //     data: weatherQuery,
            //     description: "",
            //     inject: injectRawData
            // }
        ];

        payloads.forEach(({ name, data, description, inject }) =>
        {
            if (data)
            {
                const payload = { name, data, description };
                // console.log(payload);
                inject(payload);
            }
        });
    }, [
        processedVisitsWeatherQuery?.data?.dataUpdatedAt, visitsQuery?.dataUpdatedAt, weatherQuery?.dataUpdatedAt, exportHandlers?.isDataSelectionModalOpen

    ]);

    // Will get triggered on tooltip and gives us string(Time or Hour or Date) to be used varying on dateRange
    const showDateFormat = React.useCallback(
        (point) =>
        {

            return (
                <div>
                    {dateLabelBasedOnDateFormat}: <span>{point.data.x}</span>
                </div>
            );


        },
        [dateLabelBasedOnDateFormat]
    );

    const customSliceTooltip = React.useCallback(
        ({ slice }) =>
        {
            let point = slice.points[0];

            let { newVisits, averageDurationFormatted, y, weather } =
                point.data;

            return (
                <div className="widgetsTooltipBox WeatherWidgetsTooltipBox">
                    {showDateFormat(point)}

                    <div>
                        {trans("WeatherAnalyticsWidget.Avg_Temp")}:{" "}
                        <span>
                            {weather?.averageTemperature
                                ? `${isChecked
                                    ? weather.averageTemperature.toFixed(
                                        1
                                    )
                                    : celsiusToFahrenheit(
                                        weather.averageTemperature
                                    ).toFixed(2)
                                } ${isChecked ? "°C" : "°F"}`
                                : "no data"}
                        </span>
                    </div>
                    <div>
                        {trans("Status")}:{" "}
                        <span>
                            <text>
                                {WEATHER_CODE_HASH_MAP[weather?.weathercode]
                                    ? WEATHER_CODE_HASH_MAP[
                                        weather?.weathercode
                                    ].icon
                                    : "❓"}{" "}
                            </text>
                        </span>
                    </div>
                    <div>
                        {trans("Number of Visits")}: <span>{y}</span>
                    </div>
                    <div>
                        {trans("Number of New Visits")}:{" "}
                        <span>{newVisits}</span>
                    </div>
                    <div>
                        {trans("WeatherAnalyticsWidget.Average_Duration")}:{" "}
                        <span>{averageDurationFormatted} </span>
                    </div>
                </div>
            );
        },
        [trans, dateRange, isChecked]
    );

    const customPointSymbol = React.useCallback((e) =>
    {
        const { weather } = e.datum;
        const { color, size, borderWidth, borderColor } = e;

        const xTransform = -9;
        const yTransform = -29;
        if (weather)
        {
            const { weathercode } = weather;
            return (
                <g transform={`translate(${xTransform}, ${yTransform})`}>
                    <g>
                        <circle
                            r="14"
                            cx="9"
                            cy="9"
                            fill="#fff"
                            strokeWidth="1"
                            stroke="#ccc"
                            style={{ pointerEvents: "none" }}
                        ></circle>

                        <text x="0" y="15" fontSize="14">
                            {WEATHER_CODE_HASH_MAP[weathercode]
                                ? WEATHER_CODE_HASH_MAP[weathercode].icon
                                : "❓"}{" "}
                            {/* Default icon if not found */}
                        </text>
                    </g>

                    {/* CHART POINT */}
                    <g transform={`translate(${-xTransform}, ${-yTransform})`}>
                        <circle
                            fill={color}
                            r={size / 2}
                            strokeWidth={borderWidth}
                            stroke={borderColor}
                        />
                    </g>
                </g>
            );
        }
    }, []);

    const renderWeatherAnalyticsWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedVisitsWeatherQuery;

        if (isSuccess && data)
        {
            let { chartData } = data;
            const legendData = [
                {
                    label: trans("WeatherAnalyticsWidget.Total_Visits"),
                    color: BLUES.light2,
                    id: "Total Visits",
                },
            ];

            return (
                <LineChart
                    options={{ ...WEATHER_LINE_CHART_OPTIONS, legendData }}
                    data={chartData}
                    customSliceTooltip={customSliceTooltip}
                    customPointSymbol={customPointSymbol}
                />
            );
        }
        else if (isError)
        {
            return (
                <div className="errorWidgetWrap">
                    <NotAvailableWidgetMessage />
                </div>
            );
        }
        else
        {
            return (
                <WidgetLoadingBoxFull>
                    {trans(
                        "Placeholders.Our_software_has_a_lot_of_great_data_to_"
                    )}
                    <br />{" "}
                    {trans(
                        "Placeholders.Thank_you_for_your_patience_while_we_gat"
                    )}{" "}
                </WidgetLoadingBoxFull>
            );
        }
    }, [
        trans,
        processedVisitsWeatherQuery,
        customSliceTooltip,
        customPointSymbol,
    ]);

    return (
        <DefaultBox
            independent
            heading={trans(
                "WeatherAnalyticsWidget.Weather_vs__number_of_visitors"
            )}
            className="weatherBoxWrapper"
            toolTip={trans("See how the weather affects number of visitor")}
            toggle={true}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            includeDateRange={true}
            includeExportOption={true}
            generatePdf={generatePdf}
        >
            <div className="weatherChartWrapper">
                {renderWeatherAnalyticsWidget()}
            </div>
        </DefaultBox>
    );
};
