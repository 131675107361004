import { capitalize } from "lodash";
import React from "react";
import { createListOfColors } from "../_utils/misc.utils";
import { BLUES, GREENS, PEAK_TIMES_COLORS } from "./colors";
import { TrajectoryColor } from "./insightsConstants";
import Translation from "../components/common/translation/Translation";

export const NEW_VS_RETURNING_USER_IDS = {
    NEW: "New",
    REPEAT: "Repeat",
    AVERAGE: "Average",
};

const NEW_VS_RETURNING_USER_CHART_COLORS = {
    [NEW_VS_RETURNING_USER_IDS.NEW]: "#29AD73",
    [NEW_VS_RETURNING_USER_IDS.REPEAT]: "#727AFF",
    [NEW_VS_RETURNING_USER_IDS.AVERAGE]: "#667581",
};

export const NEW_VS_RETURNING_USER_CHART_OPTIONS = {
    colors: (line) => NEW_VS_RETURNING_USER_CHART_COLORS[line.label],
    legendData: Object.keys(NEW_VS_RETURNING_USER_CHART_COLORS).map((id) => ({
        id,
        color: NEW_VS_RETURNING_USER_CHART_COLORS[id],
        label: id,
    })),
    animate: false,
    pointSize: 1,
    gridYValues: 4,
    axisLeft: {
        tickValues: 7,
    },
};

export const COMMONLY_USED_DEVICES_CHART_OPTIONS = {
    colors: { scheme: "paired" },
    layout: "horizontal",
    margin: { top: 0, right: 20, bottom: 40, left: 100 },
    enableGridX: false,
    enableGridY: true,
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
    },
};

export const SCREEN_RESOLUTION_PROVIDER_CHART_OPTIONS = {
    colors: { scheme: "paired" },
    layout: "horizontal",
    margin: { top: 0, right: 20, bottom: 40, left: 100 },
    enableGridX: false,
    enableGridY: true,
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
    },
};

export const UNIQUE_SESSION_BAR_CHART_OPTIONS = {
    enableGridX: true,
    margin: { top: 5, right: 30, bottom: 48, left: 30 },
    colors: ["rgb(87,95,126)"], // TODO move to color constant
    colorBy: "id",
    axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
    },
    axisLeft: {
        tickValues: 7,
    },
    legends: [],
};

export const APP_USAGE_PER_DAY_LINE_CHART_OPTIONS = {
    enableGridX: true,
    enableArea: true,
    enablePoints: false,
    margin: { top: 5, right: 35, bottom: 48, left: 35 },
    colors: ["rgb(41,173,115)"], // TODO move to color constant
    axisLeft: {
        tickValues: 7,
    },
};

export const RETENTION_RATE_LINE_CHART_OPTIONS = {
    enableGridX: true,
    enablePoints: false,
    margin: { top: 5, right: 45, bottom: 55, left: 35 },
    colors: ["rgb(41,173,115)"], // TODO move to color constant
    curve: "linear",
    axisLeft: {
        maxValue: 100,
        tickValues: 7,
    },
    legends: [],
};

export const SESSION_DURATION_LINE_CHART_OPTIONS = {
    enableGridX: true,
    enablePoints: false,
    margin: { top: 5, right: 35, bottom: 48, left: 35 },
    colors: ["rgb(108,117,184)"], // TODO move to color constant
    curve: "linear",
    legends: [],
    axisLeft: {
        tickValues: 7,
    },
};

export const CATEGORY_BAR_CHART_OPTIONS = {
    colors: { scheme: "paired" },
    layout: "horizontal",
    margin: { top: 5, right: 20, bottom: 40, left: 100 },
    enableGridX: false,
    enableGridY: true,
    colorBy: "id",
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
    },
};

export const ZONE_TRAFFIC_CHART_OPTIONS = {
    // colors: { scheme: "blues" },
    layout: "vertical",
    margin: { top: 10, right: 20, bottom: 20, left: 20 },
    enableGridY: false,
    enableGridX: false,
    axisLeft: null,
    colorBy: "id",
    colors: PEAK_TIMES_COLORS,
    valueScale: { type: "symlog" },
    animate: true
};


export const ZONE_TAGS_TRAFFIC_CHART_OPTIONS = {
    // colors: { scheme: "blues" },
    layout: "vertical",
    margin: { top: 10, right: 20, bottom: 20, left: 20 },
    enableGridY: false,
    enableGridX: false,
    axisLeft: null,
    colorBy: "id",
    colors: PEAK_TIMES_COLORS,
    valueScale: { type: "symlog" },
    animate: true
};

export const POI_VISITS_CHART_OPTIONS = {
    id: "id",
    value: "visits",
    colors: { scheme: "paired" },
};

export const POI_CATEGORY_SEARCHES_CHART_OPTIONS = {
    id: "id",
    value: "searches",
    colors: { scheme: "paired" },
};

export const WEB_ANA_CATEGORY_STORE_OPTIONS = {
    id: "id",
    value: "value",
    colors: { scheme: "paired" },
};
export const CATEGORY_CONVERSION_CHART_OPTIONS = {
    colors: [BLUES.default, GREENS.default],
};

export const SERVICE_PROVIDER_OPTIONS = {
    margin: { top: 30, right: 0, bottom: 30, left: 0 },
    enableArcLinkLabels: true,
    enableArcLabels: false,
    colors: { scheme: "paired" },
};

export const PIE_CHART_ARC_LINK_CHAR_LIMIT = 13;
export const AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT = 13;

export const BAR_CHART_AXIS_LEFT_TEXT_OPTIONS = {
    textAnchor: "end",
    dominantBaseline: "central",
    transform: "translate(-10,0)",
    style: { fontFamily: "Rubik", fontSize: "11px", fill: "rgb(51, 51, 51)" },
};

export const OccupancyBarChartOptions = {
    enableGridX: true,
    margin: { top: 5, right: 30, bottom: 150, left: 50 },
    colorBy: "id",
    axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
    },
    axisLeft: {
        tickValues: 7,
    },
    legends: [],
};

export const WEATHER_LINE_CHART_OPTIONS = {
    colors: () => BLUES.light2,
    margin: { top: 40, right: 40, bottom: 80, left: 40 },
    animate: false,
    pointSize: 6,
    pointColor: { from: "color" },
};

export const INSIDES_LINE_CHART_OPTIONS = {
    enableGridX: false,
    enableArea: false,
    enablePoints: false,
    xScaleType: "linear",
    margin: { top: 10, right: 50, bottom: 60, left: 50 },
    colors: [
        TrajectoryColor.BLUE,
        TrajectoryColor.GREEN,
        TrajectoryColor.ORANGE,
        TrajectoryColor.RED,
    ], // TODO move to color constant

    axisLeft: {
        tickValues: 7,
        legend: <Translation text="Insights.Legends.Duration" />,
        legendOffset: -46,
    },
    axisBottom: {
        legend: <Translation text="Insights.Legends.Cumulative_distance_along_the_projected_trajectory_meter" />,
        legendOffset: 46,
    },
    legends: [],
};

export const INSIDES_LINE_CHART_OPTIONS_DURATION = {
    enableGridX: false,
    enableArea: false,
    enablePoints: false,
    xScaleType: "linear",
    margin: { top: 10, right: 50, bottom: 60, left: 50 },
    colors: [
        TrajectoryColor.BLUE,
        TrajectoryColor.GREEN,
        TrajectoryColor.ORANGE,
        TrajectoryColor.RED,
    ], // TODO move to color constant

    axisLeft: {
        tickValues: 7,
        legend: <Translation text="Insights.Legends.Duration" />,
        legendOffset: -46,
    },
    axisBottom: {
        legend: <Translation text="Insights.Legends.Cumulative_duration_along_the_projected_trajectory_min" />,
        legendOffset: 46,
    },
    legends: [],
};

export const WEATHER_CHART_ICONS = {
    c: (
        <g>
            <path
                style={{ fill: "#C88C32" }}
                d="M15.762,9.903c-0.398-0.261-0.618-0.588-0.618-0.921
		c0-0.334,0.22-0.661,0.617-0.921l2.024-1.321c0.177-0.116,0.257-0.337,0.193-0.539c-0.063-0.203-0.248-0.341-0.465-0.334
		l-2.51,0.065c-0.486,0-0.864-0.158-1.062-0.446c-0.186-0.267-0.202-0.639-0.05-1.049l0.821-2.201
		c0.074-0.196,0.01-0.418-0.157-0.544c-0.166-0.127-0.396-0.129-0.565-0.005l-1.944,1.426c-0.779,0.571-1.623,0.321-1.899-0.574
		L9.453,0.298C9.33-0.1,8.67-0.1,8.547,0.298L7.855,2.539C7.577,3.435,6.732,3.685,5.954,3.114L4.01,1.687
		c-0.169-0.124-0.4-0.122-0.566,0.005C3.277,1.818,3.213,2.04,3.287,2.236l0.821,2.201c0.153,0.41,0.135,0.782-0.049,1.049
		C3.86,5.774,3.484,5.932,2.942,5.932H2.938L0.486,5.867C0.271,5.861,0.085,5.999,0.021,6.201C-0.042,6.403,0.038,6.624,0.215,6.74
		l2.023,1.321c0.398,0.26,0.618,0.587,0.618,0.921c0,0.333-0.22,0.661-0.618,0.921l-2.023,1.32
		c-0.177,0.116-0.257,0.337-0.194,0.539c0.064,0.203,0.249,0.32,0.465,0.334l2.51-0.064c0.486,0,0.863,0.157,1.063,0.444
		c0.184,0.269,0.202,0.641,0.049,1.051l-0.821,2.2c-0.074,0.196-0.01,0.419,0.157,0.545c0.166,0.127,0.397,0.129,0.566,0.004
		l1.944-1.426c0.78-0.571,1.624-0.319,1.901,0.574l0.692,2.241C8.609,17.865,8.792,18,9,18s0.391-0.135,0.453-0.334l0.693-2.241
		c0.276-0.895,1.12-1.144,1.899-0.574l1.944,1.426c0.086,0.063,0.2,0.098,0.289,0.092c0.262-0.004,0.482-0.208,0.482-0.475
		c0-0.104-0.034-0.201-0.092-0.28l-0.778-2.086c-0.152-0.41-0.136-0.782,0.05-1.051c0.198-0.286,0.576-0.453,1.12-0.443l2.452,0.063
		c0.217-0.013,0.401-0.131,0.465-0.334c0.063-0.202-0.017-0.423-0.193-0.539L15.762,9.903z M9,12.5c-1.93,0-3.5-1.57-3.5-3.5
		c0-1.93,1.57-3.5,3.5-3.5c1.93,0,3.5,1.57,3.5,3.5C12.5,10.93,10.93,12.5,9,12.5"
            />
        </g>
    ),
    h: (
        <g>
            <path
                style={{ fill: "#02AFBE" }}
                d="M3,14c0,0.553-0.447,1-1,1s-1-0.447-1-1s0.447-1,1-1S3,13.447,3,14"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M15,14c0,0.553,0.447,1,1,1s1-0.447,1-1s-0.447-1-1-1S15,13.447,15,14"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M10,17c0,0.553-0.447,1-1,1c-0.553,0-1-0.447-1-1s0.447-1,1-1
		C9.553,16,10,16.447,10,17"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,3
		c-0.213,0-0.42,0.026-0.622,0.062C13.454,1.308,11.884,0,10,0C7.791,0,6,1.791,6,4c0,0.276-0.224,0.5-0.5,0.5S5,4.276,5,4
		c0-0.622,0.128-1.212,0.337-1.762C4.918,2.089,4.47,2,4,2C1.791,2,0,3.791,0,6s1.791,4,4,4h10.5c1.933,0,3.5-1.566,3.5-3.5
		S16.433,3,14.5,3"
            />
            <path
                style={{ fill: "#BEBDBD" }}
                d="M6.5,11v4.215c-0.433-0.756-1.242-1.465-2.75-1.465
		c-0.138,0-0.25,0.112-0.25,0.25s0.112,0.25,0.25,0.25c2.709,0,2.75,2.638,2.75,2.75v0.001c0,0.138,0.112,0.249,0.25,0.249
		c0.136,0,0.247-0.108,0.25-0.243v-0.002v-0.001v-0.002V17v-6H6.5z"
            />
            <path
                style={{ fill: "#BEBDBD" }}
                d="M14.25,13.75c-1.508,0-2.317,0.709-2.75,1.465V11H11v6
		c0,0.138,0.112,0.25,0.25,0.25S11.5,17.138,11.5,17c0-0.112,0.031-2.75,2.75-2.75c0.138,0,0.25-0.112,0.25-0.25
		S14.388,13.75,14.25,13.75"
            />
            <path
                style={{ fill: "#BEBDBD" }}
                d="M8.75,11v4c0,0.138,0.112,0.25,0.25,0.25c0.138,0,0.25-0.112,0.25-0.25v-4
		H8.75z"
            />
        </g>
    ),
    hc: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#DDDCDC",
                }}
                d="M15,11
c-0.018,0-0.033,0.005-0.05,0.005C14.708,9.309,13.263,8,11.5,8C9.567,8,8,9.567,8,11.5C8,11.776,7.776,12,7.5,12S7,11.776,7,11.5
c0-0.495,0.1-0.962,0.248-1.408C7.007,10.036,6.759,10,6.5,10C4.567,10,3,11.567,3,13.5S4.567,17,6.5,17H15c1.656,0,3-1.343,3-3
S16.656,11,15,11"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#DDDCDC",
                }}
                d="M7.771,9
c0.809-1.202,2.175-2,3.729-2c0.743,0,1.449,0.189,2.074,0.52c0.265-0.448,0.426-0.963,0.426-1.52c0-1.657-1.342-3-3-3
c-0.273,0-0.533,0.048-0.785,0.117C9.677,1.873,8.441,1,7,1C5.559,1,4.324,1.873,3.787,3.118C3.535,3.048,3.273,3,3,3
C1.342,3,0,4.342,0,6c0,1.656,1.343,3,3,3H7.771z"
            />
        </g>
    ),
    hr: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,3
		c-0.213,0-0.42,0.026-0.622,0.062C13.454,1.308,11.884,0,10,0C7.791,0,6,1.791,6,4c0,0.276-0.224,0.5-0.5,0.5S5,4.276,5,4
		c0-0.622,0.128-1.212,0.337-1.762C4.918,2.089,4.47,2,4,2C1.791,2,0,3.791,0,6s1.791,4,4,4h10.5c1.933,0,3.5-1.567,3.5-3.5
		S16.433,3,14.5,3"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M8,13.8C8,14.464,7.553,15,7,15
		s-1-0.536-1-1.2C6,13.137,7,11,7,11S8,13.137,8,13.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M4,13.8C4,14.464,3.553,15,3,15
		s-1-0.536-1-1.2C2,13.137,3,11,3,11S4,13.137,4,13.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M16,13.8c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.663,1-2.8,1-2.8S16,13.137,16,13.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M12,13.8c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.663,1-2.8,1-2.8S12,13.137,12,13.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M10,16.8c0,0.664-0.447,1.2-1,1.2
		c-0.553,0-1-0.536-1-1.2C8,16.137,9,14,9,14S10,16.137,10,16.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M6,16.8C6,17.464,5.553,18,5,18
		s-1-0.536-1-1.2C4,16.137,5,14,5,14S6,16.137,6,16.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M14,16.8c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.663,1-2.8,1-2.8S14,16.137,14,16.8"
            />
        </g>
    ),
    lc: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#DDDCDC",
                }}
                d="M14.5,11
            c-0.213,0-0.42,0.026-0.622,0.062C13.454,9.308,11.884,8,10,8c-2.209,0-4,1.791-4,4c0,0.276-0.224,0.5-0.5,0.5S5,12.276,5,12
            c0-0.622,0.128-1.212,0.337-1.762C4.918,10.089,4.47,10,4,10c-2.209,0-4,1.791-4,4s1.791,4,4,4h10.5c1.933,0,3.5-1.567,3.5-3.5
            S16.433,11,14.5,11"
            />
            <path
                style={{ fill: "#C88C32" }}
                d="M3.879,8.382l1.939,0.226C5.975,8.63,6.102,8.682,6.219,8.74
            c0.447-0.518,1-0.939,1.622-1.239C7.778,7.181,7.755,6.85,7.804,6.509c0.234-1.636,1.758-2.777,3.396-2.544
            c1.639,0.234,2.777,1.756,2.543,3.393c-0.052,0.36-0.174,0.691-0.337,0.994c0.476,0.447,0.868,0.985,1.143,1.6
            c0.156-0.043,0.343-0.051,0.56-0.016l1.882,0.318c0.168,0.015,0.323-0.058,0.395-0.206c0.07-0.148,0.034-0.327-0.09-0.436
            l-1.412-1.24c-0.278-0.244-0.411-0.52-0.374-0.777c0.037-0.257,0.241-0.485,0.577-0.641l1.702-0.795
            c0.149-0.07,0.234-0.231,0.208-0.394c-0.027-0.163-0.153-0.289-0.32-0.308l-1.94-0.226c-0.374-0.053-0.647-0.216-0.769-0.46
            c-0.114-0.227-0.087-0.515,0.077-0.814l0.874-1.603c0.079-0.143,0.053-0.321-0.062-0.437c-0.112-0.115-0.29-0.142-0.434-0.065
            l-1.654,0.883c-0.66,0.354-1.285,0.069-1.399-0.65l-0.288-1.802c-0.051-0.32-0.558-0.393-0.696-0.099l-0.779,1.648
            c-0.312,0.659-0.99,0.759-1.527,0.234l-1.34-1.312C7.621,0.641,7.442,0.617,7.301,0.696c-0.143,0.08-0.218,0.243-0.182,0.402
            L7.51,2.882C7.583,3.215,7.528,3.5,7.357,3.685c-0.185,0.2-0.493,0.28-0.91,0.22H6.444l-1.879-0.32
            C4.4,3.557,4.241,3.642,4.17,3.792C4.1,3.94,4.137,4.119,4.26,4.228l1.412,1.24c0.277,0.244,0.409,0.52,0.373,0.777
            C6.009,6.501,5.804,6.729,5.469,6.885L3.767,7.68c-0.15,0.07-0.235,0.231-0.209,0.393C3.585,8.236,3.714,8.348,3.879,8.382"
            />
        </g>
    ),
    lr: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,4
		c-0.213,0-0.42,0.026-0.622,0.062C13.454,2.308,11.884,1,10,1C7.791,1,6,2.791,6,5c0,0.276-0.224,0.5-0.5,0.5S5,5.276,5,5
		c0-0.622,0.128-1.212,0.337-1.762C4.918,3.089,4.47,3,4,3C1.791,3,0,4.791,0,7s1.791,4,4,4h10.5c1.933,0,3.5-1.567,3.5-3.5
		C18,5.567,16.433,4,14.5,4"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M10,15.8c0,0.664-0.447,1.2-1,1.2
		c-0.553,0-1-0.536-1-1.2C8,15.137,9,13,9,13S10,15.137,10,15.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M6,15.8C6,16.464,5.553,17,5,17
		s-1-0.536-1-1.2C4,15.137,5,13,5,13S6,15.137,6,15.8"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M14,15.8c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.663,1-2.8,1-2.8S14,15.137,14,15.8"
            />
        </g>
    ),
    s: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M8.5,16.799c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.662,1-2.8,1-2.8S8.5,16.137,8.5,16.799"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M4.5,16.799c0,0.664-0.447,1.2-1,1.2
		s-1-0.536-1-1.2c0-0.662,1-2.8,1-2.8S4.5,16.137,4.5,16.799"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#00697D",
                }}
                d="M12.5,16.799
		c0,0.664-0.447,1.2-1,1.2s-1-0.536-1-1.2c0-0.662,1-2.8,1-2.8S12.5,16.137,12.5,16.799"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M12,7
		c-0.018,0-0.033,0.005-0.05,0.005C11.708,5.31,10.263,4,8.5,4C6.567,4,5,5.567,5,7.5C5,7.776,4.776,8,4.5,8S4,7.776,4,7.5
		c0-0.494,0.1-0.962,0.248-1.408C4.007,6.037,3.759,6,3.5,6C1.567,6,0,7.567,0,9.501c0,1.933,1.567,3.5,3.5,3.5H12
		c1.656,0,3-1.345,3-3C15,8.345,13.656,7,12,7"
            />
            <path
                style={{ fill: "#C88C32" }}
                d="M16.512,5.379l1.338-0.799c0.117-0.072,0.176-0.212,0.141-0.345
		c-0.037-0.131-0.152-0.225-0.292-0.226l-1.622-0.023c-0.314-0.013-0.554-0.125-0.674-0.316c-0.113-0.178-0.115-0.418-0.005-0.68
		l0.587-1.398c0.054-0.123,0.016-0.27-0.089-0.356c-0.103-0.085-0.251-0.092-0.363-0.016l-1.293,0.87
		c-0.515,0.348-1.056,0.164-1.21-0.42l-0.391-1.465c-0.068-0.26-0.493-0.277-0.585-0.023l-0.505,1.427
		c-0.202,0.571-0.752,0.71-1.242,0.322L9.09,0.96C8.985,0.876,8.834,0.872,8.725,0.948c-0.111,0.077-0.16,0.22-0.116,0.348
		l0.472,1.441c0.037,0.113,0.044,0.215,0.043,0.314c0.587,0.084,1.137,0.288,1.633,0.582c0.425-0.267,0.93-0.414,1.468-0.393
		c1.419,0.058,2.521,1.257,2.465,2.673c-0.014,0.339-0.096,0.658-0.228,0.95c0.402,0.316,0.747,0.7,1.004,1.145
		c0.132-0.059,0.288-0.095,0.489-0.082l1.58,0.103c0.141-0.002,0.264-0.074,0.31-0.204c0.046-0.128,0.001-0.272-0.111-0.35
		l-1.271-0.907c-0.25-0.178-0.383-0.394-0.375-0.611C16.098,5.742,16.246,5.538,16.512,5.379"
            />
        </g>
    ),
    sl: (
        <g>
            <path
                style={{ fill: "#02AFBE" }}
                d="M10,13c0,0.553-0.447,1-1,1c-0.553,0-1-0.447-1-1s0.447-1,1-1
		C9.553,12,10,12.447,10,13"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M6,13c0,0.553-0.447,1-1,1s-1-0.447-1-1s0.447-1,1-1S6,12.447,6,13"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M14,13c0,0.553-0.447,1-1,1s-1-0.447-1-1s0.447-1,1-1S14,12.447,14,13"
            />
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,4
		c-0.213,0-0.42,0.026-0.622,0.062C13.454,2.308,11.884,1,10,1C7.791,1,6,2.791,6,5c0,0.276-0.224,0.5-0.5,0.5S5,5.276,5,5
		c0-0.622,0.128-1.212,0.337-1.762C4.918,3.089,4.47,3,4,3C1.791,3,0,4.791,0,7s1.791,4,4,4h10.5c1.933,0,3.5-1.566,3.5-3.5
		C18,5.566,16.433,4,14.5,4"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M8,16c0,0.553-0.447,1-1,1s-1-0.447-1-1s0.447-1,1-1S8,15.447,8,16"
            />
            <path
                style={{ fill: "#02AFBE" }}
                d="M12,16c0,0.553-0.447,1-1,1s-1-0.447-1-1s0.447-1,1-1S12,15.447,12,16"
            />
        </g>
    ),
    sn: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,4
		c-0.213,0-0.42,0.026-0.622,0.062C13.454,2.308,11.884,1,10,1C7.791,1,6,2.791,6,5c0,0.276-0.224,0.5-0.5,0.5S5,5.275,5,5
		c0-0.622,0.128-1.212,0.337-1.762C4.918,3.088,4.47,3,4,3C1.791,3,0,4.791,0,7c0,2.209,1.791,4,4,4h10.5c1.933,0,3.5-1.566,3.5-3.5
		S16.433,4,14.5,4"
            />
            <path
                style={{ fill: "#8CB4B4" }}
                d="M5.738,14.739H4.631l0.785-0.782c0.049-0.051,0.076-0.116,0.076-0.187
		c0-0.068-0.027-0.136-0.077-0.186c-0.091-0.089-0.278-0.093-0.369,0.001l-0.784,0.781v-1.106C4.262,13.117,4.146,13,4.001,13
		c-0.145,0-0.262,0.117-0.262,0.261v1.108l-0.782-0.783c-0.092-0.092-0.278-0.092-0.369,0C2.537,13.636,2.51,13.7,2.51,13.771
		c0,0.071,0.027,0.136,0.078,0.187l0.781,0.782H2.26C2.117,14.739,2,14.855,2,15c0,0.144,0.117,0.261,0.26,0.261h1.109l-0.781,0.782
		c-0.051,0.05-0.078,0.115-0.078,0.185c0,0.071,0.028,0.138,0.078,0.188c0.09,0.09,0.278,0.091,0.369-0.001l0.782-0.782v1.107
		c0,0.145,0.116,0.262,0.261,0.262c0.144,0,0.261-0.117,0.261-0.262v-1.108l0.785,0.784c0.09,0.09,0.278,0.089,0.371-0.001
		c0.1-0.102,0.1-0.27-0.001-0.372l-0.782-0.781h1.104C5.883,15.261,6,15.144,6,15C6,14.855,5.883,14.739,5.738,14.739"
            />
            <path
                style={{ fill: "#8CB4B4" }}
                d="M10.738,14.739H9.631l0.785-0.782c0.049-0.051,0.076-0.116,0.076-0.187
		c0-0.068-0.027-0.136-0.077-0.186c-0.091-0.089-0.277-0.093-0.369,0.001l-0.783,0.781v-1.106C9.263,13.117,9.146,13,9.001,13
		s-0.262,0.117-0.262,0.261v1.108l-0.782-0.783c-0.092-0.092-0.278-0.092-0.369,0C7.537,13.636,7.51,13.7,7.51,13.771
		c0,0.071,0.027,0.136,0.078,0.187l0.781,0.782H7.26C7.117,14.739,7,14.855,7,15c0,0.144,0.117,0.261,0.26,0.261h1.109l-0.781,0.782
		c-0.051,0.05-0.078,0.115-0.078,0.185c0,0.071,0.028,0.138,0.078,0.188c0.09,0.09,0.278,0.091,0.369-0.001l0.782-0.782v1.107
		c0,0.145,0.116,0.262,0.261,0.262s0.262-0.117,0.262-0.262v-1.108l0.784,0.784c0.091,0.09,0.278,0.089,0.371-0.001
		c0.101-0.102,0.101-0.27-0.001-0.372l-0.781-0.781h1.104C10.883,15.261,11,15.144,11,15C11,14.855,10.883,14.739,10.738,14.739"
            />
            <path
                style={{ fill: "#8CB4B4" }}
                d="M15.738,14.739h-1.107l0.785-0.782c0.049-0.051,0.076-0.116,0.076-0.187
		c0-0.068-0.027-0.136-0.077-0.186c-0.091-0.089-0.277-0.093-0.369,0.001l-0.783,0.781v-1.106c0-0.144-0.117-0.261-0.262-0.261
		s-0.262,0.117-0.262,0.261v1.108l-0.782-0.783c-0.092-0.092-0.277-0.092-0.369,0c-0.051,0.05-0.077,0.114-0.077,0.185
		c0,0.071,0.026,0.136,0.077,0.187l0.781,0.782h-1.108C12.117,14.739,12,14.855,12,15c0,0.144,0.117,0.261,0.261,0.261h1.108
		l-0.781,0.782c-0.051,0.05-0.077,0.115-0.077,0.185c0,0.071,0.027,0.138,0.077,0.188c0.091,0.09,0.278,0.091,0.369-0.001
		l0.782-0.782v1.107c0,0.145,0.116,0.262,0.261,0.262s0.262-0.117,0.262-0.262v-1.108l0.784,0.784
		c0.091,0.09,0.278,0.089,0.371-0.001c0.101-0.102,0.101-0.27-0.001-0.372l-0.781-0.781h1.104C15.883,15.261,16,15.144,16,15
		C16,14.855,15.883,14.739,15.738,14.739"
            />
        </g>
    ),
    t: (
        <g>
            <path
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#BEBDBD",
                }}
                d="M14.5,3
		c-0.213,0-0.42,0.026-0.623,0.062C13.453,1.308,11.883,0,10,0C7.791,0,6,1.791,6,4c0,0.276-0.225,0.5-0.5,0.5
		C5.223,4.5,5,4.276,5,4c0-0.622,0.127-1.212,0.336-1.762C4.918,2.089,4.469,2,4,2C1.791,2,0,3.791,0,6s1.791,4,4,4h10.5
		c1.932,0,3.5-1.566,3.5-3.5S16.432,3,14.5,3"
            />
            <polygon
                style={{
                    fillRule: "evenodd",
                    clupRule: "evenodd",
                    fill: "#DBBD45",
                }}
                points="11.057,11 7.143,11 5,14
		7.855,14 5,18 13,12.857 9.57,12.857 	"
            />
        </g>
    ),
};
export const WEATHER_CODE_HASH_MAP = {
    0: { description: "Clear sky", icon: "☀️" },
    1: { description: "Mainly clear", icon: "🌤️" },
    2: { description: "Partly cloudy", icon: "⛅" },
    3: { description: "Overcast", icon: "☁️" },
    45: { description: "Fog and depositing rime fog", icon: "🌫️" },
    48: { description: "Fog and depositing rime fog", icon: "🌫️" },
    51: { description: "Drizzle: Light intensity", icon: "🌧️" },
    53: { description: "Drizzle: Moderate intensity", icon: "🌧️" },
    55: { description: "Drizzle: Dense intensity", icon: "🌧️" },
    56: { description: "Freezing Drizzle: Light intensity", icon: "❄️" },
    57: { description: "Freezing Drizzle: Dense intensity", icon: "❄️" },
    61: { description: "Rain: Slight intensity", icon: "🌦️" },
    63: { description: "Rain: Moderate intensity", icon: "🌦️" },
    65: { description: "Rain: Heavy intensity", icon: "🌧️" },
    66: { description: "Freezing Rain: Light intensity", icon: "❄️" },
    67: { description: "Freezing Rain: Heavy intensity", icon: "❄️" },
    71: { description: "Snow fall: Slight intensity", icon: "🌨️" },
    73: { description: "Snow fall: Moderate intensity", icon: "🌨️" },
    75: { description: "Snow fall: Heavy intensity", icon: "🌨️" },
    77: { description: "Snow grains", icon: "🌨️" },
    80: { description: "Rain showers: Slight intensity", icon: "🌧️" },
    81: { description: "Rain showers: Moderate intensity", icon: "🌧️" },
    82: { description: "Rain showers: Violent intensity", icon: "🌧️" },
    85: { description: "Snow showers slight", icon: "🌨️" },
    86: { description: "Snow showers heavy", icon: "🌨️" },
    95: { description: "Thunderstorm: Slight or moderate", icon: "⛈️" },
    96: { description: "Thunderstorm with slight hail", icon: "⛈️" },
    99: { description: "Thunderstorm with heavy hail", icon: "⛈️" },
};

const colors = createListOfColors(3);
export const WEB_ENGAGEMENT__SESSION_SOURCE = {
    LINK: "LINK",
    SHARE: "SHARE",
    QRCODE: "QRCODE",
};
export const WEB_ENGAGEMENT__SESSION_APP = {
    WEB: "MAPS",
    KIOSK: "KIOSK",
    MOBILE: "MOBILE",
};
export const WEB_SESSIONS_SOURCE_TYPE_TO_COLOR_AND_LABEL = {
    [WEB_ENGAGEMENT__SESSION_SOURCE.LINK]: {
        id: "LINK",
        color: colors[0],
        label: "Link",
    },
    [WEB_ENGAGEMENT__SESSION_SOURCE.SHARE]: {
        id: "SHARE",
        color: colors[1],
        label: "Share Link",
    },
    [WEB_ENGAGEMENT__SESSION_SOURCE.QRCODE]: {
        id: "QRCODE",
        color: colors[2],
        label: "QR Code",
    },
};

export const SESSION_SOUCRE_PIE_CHART_OPTIONS = {
    // margin: { top: 30, right: 0, bottom: 30, left: 0 },
    margin: { top: 20, right: 40, bottom: 20, left: 20 },
    enableArcLinkLabels: false,
    enableArcLabels: false,
    colors: { datum: "data.color" },
    legends: [
        {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: -10,
            translateY: 20,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: {
                from: "color",
                modifiers: [["brighter", "3"]],
            },
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            effects: [
                {
                    on: "hover",
                    style: {
                        itemTextColor: "#000",
                    },
                },
            ],
        },
    ],
};

export const WEB_ENGAGEMENT_TOP20SEARCHES_WIDGET_OPTIONS = {
    leavesOnly: true,
    colors: ({ data }) => data.color,
    enableLabels: true,
    label: function (e)
    {
        const formattedLabel = capitalize(`${e.id} (${e.value})`);
        if (e.radius < 13)
        {
            return formattedLabel.length <= 2 ? formattedLabel : `(${e.value})`;
        }
        if (e.radius < 25)
        {
            // eslint-disable-next-line
            return formattedLabel.length <= 5
                ? formattedLabel
                : `${formattedLabel.substring(0, 1) + ".." + `(${e.value})`}`;
        }

        if (e.radius < 50)
        {
            // eslint-disable-next-line
            return formattedLabel.length <= 10
                ? formattedLabel
                : `${formattedLabel.substring(0, 5) + ".." + `(${e.value})`}`;
        }
        // eslint-disable-next-line
        return formattedLabel.length <= 20
            ? formattedLabel
            : `${formattedLabel.substring(0, 16) + ".." + `(${e.value})`}`;
    },
    labelsFilter: (n) => true,
    labelsSkipRadius: 10,
    labelTextColor: "hsla(360, 100%, 100%, .6)",
    borderWidth: 5,
    borderColor: "hsla(360, 100%, 100%, 1)",
};

export const PI_CHART_THRESHOLD_PERCENTAGE_CONSTANTS = 1;

export const MAX_LENGTH_DESTINATION_WIDGET_LABEL = 30;
