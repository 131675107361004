import {
    AppUsageVsTimeWidget,
    AverageAppSessionLengthNumberWidget,
    AverageMonthlyVisitorsNumberWidget,
    AverageSessionIntervalNumberWidget,
    AverageVistTimeNumberWidget,
    CategoryWidget,
    CommonlyUsedDevicesWidget,
    CurrentCapcityNumberWidget,
    DownloadsVsTimeWidget,
    HeatmapWidget,
    NewUsersNumberWidget,
    NewVsRepeatUsersWidget,
    NumberOfTotalDownloadsNumberWidgets,
    RetentionRateWidget,
    ScreenResolutionWidget,
    SearchedConversionRateWidget,
    ServiceProviderWidget,
    SessionDurationVsTimeWidget,
    StoreAndCategorySearchesWidget,
    TopSearchesWidget,
    UniqueSessionsVsTimeWidget,
    VisitorsNumberWidget,
    ZoneGeofencePoiVisitsWidget,
    ZoneTrafficWidget,
    // OccupancyAnalyticsBarWidget, 
} from "../components/widgets";
import { WIDGET_IDS, WIDGET_TYPES } from "./widgets";
export const ALL_WIDGETS = [
    {
        id: WIDGET_IDS.CategoryWidget,
        thumbnailUrl: "/img/icon-widget-category.svg",
        title: "CategoryWidget.What_is_the_most_searched_and_visited_ca", // using translation key,
        type: WIDGET_TYPES.graphWidget,
        component: CategoryWidget,
    },
    {
        id: WIDGET_IDS.CommonlyUsedDevicesWidget,
        thumbnailUrl: "/img/icon-widget-device.svg",
        title: "CommonlyUsedDevicesWidget.What_is_the_most_commonly_used_device_in", // using translation key,
        type: WIDGET_TYPES.graphWidget,
        component: CommonlyUsedDevicesWidget,
    },
    {
        id: WIDGET_IDS.HeatmapWidget,
        title: "HeatmapWidget.See_where_visitors_spend_the_most_time_u",
        thumbnailUrl: "/img/icon-widget-heatmaps.svg",
        type: WIDGET_TYPES.graphWidget,
        component: HeatmapWidget,
    },
    {
        id: WIDGET_IDS.NewVsRepeatUsersWidget,
        thumbnailUrl: "/img/icon-widget-navigate.svg",
        title: "NewVsRepeatUsersWidget.How_many_visits_are_new_vs__repeat?",
        type: WIDGET_TYPES.graphWidget,
        component: NewVsRepeatUsersWidget,
    },
    {
        id: WIDGET_IDS.ScreenResolutionWidget,
        title: "ScreenResolutionWidget.What_is_the_most_common_screen_resolutio",
        thumbnailUrl: "/img/icon-widget-resolution.svg",
        type: WIDGET_TYPES.graphWidget,
        component: ScreenResolutionWidget,
    },
    {
        id: WIDGET_IDS.ServiceProviderWidget,
        title: "ServiceProviderWidget.What_is_the_most_common_service_provider",
        thumbnailUrl: "/img/icon-widget-visitor.svg",
        type: WIDGET_TYPES.graphWidget,
        component: ServiceProviderWidget,
    },
    {
        id: WIDGET_IDS.ZoneGeofencePoiVisitsWidget,
        title: "ZoneGeofencePoiVisitsWidget.Which_areas_of_your_property_receive_the",
        thumbnailUrl: "/img/icon-widget-traffic.svg",
        type: WIDGET_TYPES.graphWidget,
        component: ZoneGeofencePoiVisitsWidget,
    },
    {
        id: WIDGET_IDS.ZoneTrafficWidget,
        title: "ZoneTrafficWidget.What_are_the_most_popular_peak_times_for",
        thumbnailUrl: "/img/icon-widget-zone.svg",
        type: WIDGET_TYPES.graphWidget,
        component: ZoneTrafficWidget,
    },

    {
        id: WIDGET_IDS.AppUsageVsTimeWidget,
        title: "AppUsageVsTimeWidget.On_average__how_often_do_users_open_your",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-zone.svg",
        component: AppUsageVsTimeWidget,
    },

    {
        id: WIDGET_IDS.DownloadsVsTimeWidget,
        title: "DownloadsVsTimeWidget.How_are_consumers_responding_to_your_bra",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-zone.svg",
        component: DownloadsVsTimeWidget,
    },

    // removing as  per AWA-308

    // {
    //     id: WIDGET_IDS.NumberOfZoneVisitsPerSessionWidget,
    //     title: "View the number of sessions used to access your most popular zones",
    //     type: WIDGET_TYPES.graphWidget,
    //     thumbnailUrl: "/img/icon-widget-zone.svg",
    //     component: NumberOfZoneVisitsPerSessionWidget,
    // },

    {
        id: WIDGET_IDS.RetentionRateWidget,
        title: "RetentionRateWidget.What_percentage_of_users_continue_using_",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-device.svg",
        component: RetentionRateWidget,
    },

    {
        id: WIDGET_IDS.SearchedConversionRateWidget,
        title: "SearchedConversionRateWidget.What_is_your_conversion_rate?",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-navigate.svg",
        component: SearchedConversionRateWidget,
    },

    {
        id: WIDGET_IDS.StoreAndCategorySearchesWidget,
        title: "StoreAndCategorySearchesWidget.What_was_the_most_searched_category_and_",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-visitor.svg",
        component: StoreAndCategorySearchesWidget,
    },

    {
        id: WIDGET_IDS.TopSearchesWidget,
        title: "TopSearchesWidget.What_people_search_in_the_app?",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-zone.svg",
        component: TopSearchesWidget,
    },
    // as per the conversation with Pranavi, this was duplicating
    //as per the ticket AWA-309 adding it back ....
    {
        id: WIDGET_IDS.SessionDurationVsTimeWidget,
        title: "SessionDurationVsTimeWidget.What_is_the_average_length_of_time_users",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-device.svg",
        component: SessionDurationVsTimeWidget,
    },

    {
        id: WIDGET_IDS.UniqueSessionsVsTimeWidget,
        title: "UniqueSessionsVsTimeWidget.How_many_users_interacted_with_your_app_",
        type: WIDGET_TYPES.graphWidget,
        thumbnailUrl: "/img/icon-widget-category.svg",
        component: UniqueSessionsVsTimeWidget,
    },
    // as suggest by QA TEAM AWA-308 - REMOVING FOR NOW
    // {
    //     id: WIDGET_IDS.WeatherAnalyticsWidget,
    //     title: "WeatherAnalyticsWidget.Weather_vs__number_of_visitors",
    //     type: WIDGET_TYPES.graphWidget,
    //     thumbnailUrl: "/img/icon-theme-blank.svg",
    //     component: WeatherAnalyticsWidget,
    // },

    // as suggest by QA TEAM AWA-306 - REMOVING FOR NOW
    // {
    //     id: WIDGET_IDS.VisitorFlowWidget,
    //     title: "VisitorFlowWidget.See_how_your_visitors_interact_with_your",
    //     type: WIDGET_TYPES.graphWidget,
    //     thumbnailUrl: "/img/icon-theme-blank.svg",
    //     component: VisitorFlowWidget,
    // },

    {
        id: WIDGET_IDS.CurrentCapacityNumberWidget,
        title: "DashboardOverviewWidget.Current_capacity",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: CurrentCapcityNumberWidget,
    },
    {
        id: WIDGET_IDS.AverageVistTimeNumberWidget,
        title: "DashboardOverviewWidget.Average_visit_time",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: AverageVistTimeNumberWidget,
    },
    {
        id: WIDGET_IDS.NewUsersNumberWidget,
        title: "DashboardOverviewWidget.New_users",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: NewUsersNumberWidget,
    },
    {
        id: WIDGET_IDS.VisitorsNumberWidget,
        title: "DashboardOverviewWidget.Visitors",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: VisitorsNumberWidget,
    },
    {
        id: WIDGET_IDS.NumberOfTotalDownloadsNumberWidgets,
        title: "ReportsOverviewDetail.Number_of_total_downloads_",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: NumberOfTotalDownloadsNumberWidgets,
    },
    {
        id: WIDGET_IDS.AverageMonthlyVisitorsNumberWidget,
        title: "ReportsOverviewDetail.Average_monthly_visitors_for_the_selecte",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: AverageMonthlyVisitorsNumberWidget,
    },
    {
        id: WIDGET_IDS.AverageAppSessionLengthNumberWidget,
        title: "ReportsOverviewDetail.Average_App_session_length_for_the_selec",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: AverageAppSessionLengthNumberWidget,
    },
    {
        id: WIDGET_IDS.AverageSessionIntervalNumberWidget,
        title: "ReportsOverviewDetail.Average_session_interval_for_the_selecte",
        type: WIDGET_TYPES.numberWidget,
        thumbnailUrl: "/img/icon-syncing-info.svg",
        component: AverageSessionIntervalNumberWidget,
    },
];
export const ALL_WIDGETS_MAP = ALL_WIDGETS.reduce((accObj, widget, index) => {
    accObj[widget.id] = widget;
    return accObj;
}, {});

export const DASHBOARD_PROPERTY_DATA_WIDGETS = ALL_WIDGETS;
export const DASHBOARD_NUMBER_WIDGETS = ALL_WIDGETS.filter((o) => o.type === WIDGET_TYPES.numberWidget);
export const DASHBOARD_GRAPH_WIDGETS = ALL_WIDGETS.filter((o) => o.type === WIDGET_TYPES.graphWidget);
