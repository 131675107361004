import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import serverApi from '../../../_api/server.api';
import
{
    NAV_ANALYTICS_QUERY_KEYS,
    QUERY_OPTIONS
} from "../../../_constants/queryConstants";
import { processCommonlyUsedDevices } from "../../../_utils/chart.utils";
import useCommon from "../../pages/useCommon";

const useCommonlyUsedDevices = () =>
{
    const common = useCommon();

    const { environmentConstants, filters, hasAccessToProperty } =
        common;
    const { analyticsRequestFilter } = filters;
    const commonlyUsedDevicesQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.HARDWARE, { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {

            const response = await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.HARDWARE, environmentConstants);

            if (!response?.success)
            {
                throw new Error("Commonly used devices response was not ok");
            }

            return processCommonlyUsedDevices(response.data);
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS

    });

    const boxClassName = React.useMemo(() =>
    {
        if (commonlyUsedDevicesQuery.isError || (!!commonlyUsedDevicesQuery.isSuccess && commonlyUsedDevicesQuery.data.commonlyUsedDeviceBarData.length === 0))
        {
            return "hasMessage";
        }
        else
        {
            return "";
        }

    }, [commonlyUsedDevicesQuery]);


    const onError = useCallback(() =>
    {
        commonlyUsedDevicesQuery.refetch();
    }, [commonlyUsedDevicesQuery]);
    return { ...common, ...commonlyUsedDevicesQuery, onError, boxClassName };
};

export default useCommonlyUsedDevices;