import { DATA_AGGREGATIONS } from "./constants";

export const HEAT_MAP_TYPES = {
    DWELL: "Dwell",
    POSITIONING: "Positioning",
};


export const DATA_AGGREGATION_OPTIONS_LIST = [
    {
        key: DATA_AGGREGATIONS.EVERY_TWO_MINUTES,
        text: "Every Two Minutes",
        value: DATA_AGGREGATIONS.EVERY_TWO_MINUTES,
    },
    {
        key: DATA_AGGREGATIONS.EVERY_HOUR,
        text: "Every Hour",
        value: DATA_AGGREGATIONS.EVERY_HOUR,
    },
    {
        key: DATA_AGGREGATIONS.EVERY_DAY,
        text: "Every Day",
        value: DATA_AGGREGATIONS.EVERY_DAY,
    },
];

export const DATA_AGGREGATION_OPTIONS_LIST_EXCLUDING_EVERY_DAY = [
    {
        key: DATA_AGGREGATIONS.EVERY_TWO_MINUTES,
        text: "Every Two Minutes",
        value: DATA_AGGREGATIONS.EVERY_TWO_MINUTES,
    },
    {
        key: DATA_AGGREGATIONS.EVERY_HOUR,
        text: "Every Hour",
        value: DATA_AGGREGATIONS.EVERY_HOUR,
    },
];

export const SLIDER_BAR_COLOR = "#D3D2DE";

export const SLIDER_THUMB_COLOR = "#727AFF";

export const DATA_AGGREGATION_OPTIONS_OBJ = DATA_AGGREGATION_OPTIONS_LIST.reduce((acc, o) =>
{
    acc[o.value] = o;
    return acc;
}, {});

export const DEFAULT_DATA_AGGREGATION_OBJ = DATA_AGGREGATION_OPTIONS_OBJ[DATA_AGGREGATIONS.EVERY_HOUR];

export const SLIDE_DISPLAY_TIME = 600;

export const BOOST_ZONE_HEADER_TABS = {
    TABLE: "ZONE_TABLE",
    BAR_CART: "ZONE_BAR_CART",
};

export const ZONE_DATA_VIEWS = {
    PERCENTAGE: "PERCENTAGE",
    MAX_DWELL_TIME: "MAX_DWELL_TIME"
};


export const BOOST_DEVICE_INFO_TABS = {
    BOOSTS: "GetBoostDeviceInfo",
    TAGS: "GetTagDeviceInfo",
    SENSORS: "GetSensorDeviceInfo"
};

export const BOOST_DEVICE_TAB_NON_ACTIVE_ICONS = {
    [BOOST_DEVICE_INFO_TABS.BOOSTS]: "/img/icon-bst-device-boost.svg",
    [BOOST_DEVICE_INFO_TABS.TAGS]: "/img/icon-bst-dv-tag.svg",
    [BOOST_DEVICE_INFO_TABS.SENSORS]: "/img/icon-bst-dv-sensor.svg"
};

export const BOOST_DEVICE_TAB_ACTIVE_ICONS = {
    [BOOST_DEVICE_INFO_TABS.BOOSTS]: "/img/icon-boost-device-active.svg",
    [BOOST_DEVICE_INFO_TABS.TAGS]: "/img/icon-bst-dv-tag.svg",
    [BOOST_DEVICE_INFO_TABS.SENSORS]: "/img/icon-bst-dv-sensor.svg"
};


export const BOOST_NO_DATA_WIDGET_PROPS_BY_TAB_NAME = {
    [BOOST_DEVICE_INFO_TABS.BOOSTS]: {
        customTitle: "Boost_Analytics.infoText.NO_DATA_BOOST_DEVICES_TITLE",
        customMessage: "Boost_Analytics.infoText.NO_DATA_BOOST_DEVICES_MSG",
    },
    [BOOST_DEVICE_INFO_TABS.SENSORS]: {
        customTitle: "Boost_Analytics.infoText.NO_DATA_BOOST_SENSORS_TITLE",
        customMessage: "Boost_Analytics.infoText.NO_DATA_BOOST_SENSORS_MSG",
    },
    [BOOST_DEVICE_INFO_TABS.TAGS]: {
        customTitle: "Boost_Analytics.infoText.NO_DATA_BOOST_TAGS_TITLE",
        customMessage: "Boost_Analytics.infoText.NO_DATA_BOOST_TAGS_MSG",
    }
};


export const DEFAULT = "DEFAULT";


export const HARDWARE_VENDORS_PIE_OPINIONS = {
    // margin: { top: 30, right: 0, bottom: 30, left: 0 },
    margin: { top: 20, right: 250, bottom: 100, left: 10 },
    enableArcLinkLabels: false,
    enableArcLabels: false,
    colors: { datum: 'data.color' },
    legends: [
        {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 10,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: {
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        '3'
                    ]
                ]
            },
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ]
};
export const FLOOR_CROWDEDNESS_OPTIONS = {
    // margin: { top: 30, right: 0, bottom: 30, left: 0 },
    margin: { top: 20, right: 80, bottom: 100, left: 20 },
    enableArcLinkLabels: false,
    enableArcLabels: false,
    colors: { datum: 'data.color' },
    legends: [
        {
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: -10,
            translateY: 100,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: {
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        '3'
                    ]
                ]
            },
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ]
};

export const TOP_PATHS_OPTIONS = {
    colors: { datum: 'data.color' },
    layout: "horizontal",
    margin: { top: 0, right: 50, bottom: 100, left: 50 },
    enableGridX: true,
    enableGridY: false,
    enableLabel: true,
    axisLeft: null,
    axisBottom: null,
    reverse: false,
    labelTextColor: "#FFFFF",
    legends: [
        {
            dataFrom: "indexes",
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: -10,
            translateY: 100,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,

        }
    ],
    // legends: [
    //     {
    //         anchor: 'bottom-left',
    //         direction: 'column',
    //         justify: false,
    //         translateX: -10,
    //         translateY: 100,
    //         itemsSpacing: 2,
    //         itemWidth: 100,
    //         itemHeight: 18,
    //         itemTextColor: {
    //             from: 'color',
    //             modifiers: [
    //                 [
    //                     'brighter',
    //                     '3'
    //                 ]
    //             ]
    //         },
    //         itemDirection: 'left-to-right',
    //         itemOpacity: 1,
    //         symbolSize: 18,
    //         effects: [
    //             {
    //                 on: 'hover',
    //                 style: {
    //                     itemTextColor: '#000'
    //                 }
    //             }
    //         ]
    //     }
    // ]
};


export const BOOST_COMFORT_TEMP_CONSTANTS = {
    HEAT_STROKE_IMMINENT: {
        color: "hsla(21, 100%, 50%,1)",
        icon: "heat-stroke-imminent",
        iconPopText: "Heat_stroke_imminent"
    },
    DANGEROUS: {
        color: "hsla(348, 99%, 41%,1)",
        icon: "dangerous",
        iconPopText: "Dangerous"
    },
    GREAT_DISCOMFORT: {
        color: "hsla(14, 100%, 50%,1)",
        outputData: "great-discomfort",
        iconPopText: "Great_Discomfort;_avoid_exertion"
    },
    SOME_DISCOMFORT: {
        color: "hsla(52, 100%, 50%,1)",
        icon: "some-discomfort",
        iconPopText: "Some_Discomfort"
    },
    NO_DISCOMFORT: {
        color: "hsla(68, 100%, 42%,1)",
        icon: "no-discomfort",
        iconPopText: "No_Discomfort"

    }


};

export const BOOST_COMFORT_TEMP_HEAT_STROKE_IMMINENT_LOWER_LIMIT = 54;

export const BOOST_COMFORT_TEMP_DANGEROUS_LOWER_LIMIT = 45;

export const BOOST_COMFORT_TEMP_GREAT_DISCOMFORT_LOWER_LIMIT = 40;

export const BOOST_COMFORT_TEMP_SOME_DISCOMFORT_LOWER_LIMIT = 30;


export const DISABLE_ZONE_GEOFENCE_CUSTOM_OPTIONS = {
    style: {
        fill: { color: "rgb(105,105,105,0.2)" },
        stroke: { color: "rgb(105,105,105,0.2)" }
    },
    // showVisitCount: false,
    // label: false
};
// 2mins
export const BOOST_QUICKSHAPSHOT_RE_FETCH_INTERVAL = 2 * 60 * 1000;

// 30sec
export const TAGS_INFO_RE_FETCH_INTERVAL = 30 * 1000;




export const BOOST_TAG_STATUS_LIST = [
    {
        value: "All",
        text: "All",
        key: "tall",
    },
    {
        value: "offline",
        text: "Offline",
        key: "toffline",
    },
    {
        value: "online",
        text: "Online",
        key: "tonline",
    }
];

export const BOOST_TAG_STATUS_DEFAULT_VALUE = "All";

export const BOOST_BATTERY_STATUS_LIST = [
    {
        value: "All",
        text: "All",
        key: "btAll"
    },
    {
        value: "Full Battery",
        text: "Full Battery",
        key: "btFull Battery"
    },
    {
        value: "Mid Battery",
        text: "Mid Battery",
        key: "btMid Battery"
    },
    {
        value: "Low Battery",
        text: "Low Battery",
        key: "btLow Battery"
    },
];

export const BOOST_BATTERY_FILTER_DEFAULT_VALUE = "All";

export const BOOST_TAG_INFO_MAP_LEGENDS = {
    labelKey: "text",
    colorKey: "color"
};


export const TAG_LAYER_META = { idKey: "uid", labelKey: "tag_label", activeIconKey: "activeIcon" };


export const BOOST_DEVICE_INFO_POINT_META = { idKey: "uid", fileUrlKey: "nonActiveIconKey", activeIconKey: "activeIconKey" };

export const BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX = {
    COMPLETE_TRAJECTORY_LINE: "Complete_Trajectory_Line_",
    SUB_SEGMENT_LINE: "Sub_Segment_Line_",
    COMPLETE_POINTS: "Complete_Point_",
    SUB_SEGMENT_POINTS: "Sub_Segment_Point_"
};
