import React from "react";
import { Checkbox } from "semantic-ui-react";
import "./CheckboxGroup.css";

export const CheckboxGroup = ({ className, name, checked, radio, label, reduce, onClick, onChange }) =>
{
    return (
        <Checkbox
            checked={!!checked}
            className={`checkboxGroup${reduce ? " reduced" : ""}${className ? " " + className : ""}`}
            radio={radio}
            label={label}
            name={name}
            onChange={onChange} 
            onClick={onClick} />
    );
}