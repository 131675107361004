import React from "react";
import { ButtonIcon } from "../buttonIcon/ButtonIcon";
import { Popup } from "semantic-ui-react";

export const DropdownListItem = ({ basic, position, className, content, isBuilding, isSelected, onMouseOver, onClick, popupContent }) => (
    <Popup basic={basic ? !!basic : undefined}
        className={`infoPopup${className ? " " + className : ""}`}
        hoverable
        mouseEnterDelay={500}
        trigger={
            <div className={`listItem ${isSelected ? "selected" : ""} `} onMouseOver={onMouseOver} onClick={onClick}>
                <ButtonIcon className="titleItem" content={content} />
                {
                    (!isBuilding) && (
                        <ButtonIcon className="listArrow" iconName="chevron right" />
                    )
                }
            </div>}
        position={position ? position : "top right"}
    >
        {popupContent || content}
    </Popup>
);

/**
 * style={{ maxWidth: max }}
 *
 *   <div className={`listItem ${isSelected ? "selected" : ""} `} onMouseOver={onMouseOver} onClick={onClick}>
            <ButtonIcon className="titleItem" content={content} />
            {
                (!isBuilding) && (
                    <ButtonIcon className="listArrow" iconName="chevron right" />
                )
            }
        </div>

 */
