import React, { useCallback } from "react";
import
{
    convertMinsToHrsAndMins,
    convertUnixSecondsToReadAbleTimeFormatWithTimeZone,
} from "../../../_utils/date.luxon.utils";
import { useRecoilValue } from "recoil";
import { insightsSelectedSessionIdxState, insightsSelectedSessionUIDState } from "../../../store/InsightsAtoms";
import { Popup } from "semantic-ui-react";
import { InsightsTrajectoryStatusToIcon, InsightsTrajectoryStatusToReadableText } from "../../../_constants/insightsConstants";
import { useTranslation } from "react-i18next";
import { isTrajectoryConstructed } from "../../../_utils/insights.utils";
import classNames from "classnames";

const SessionTableDropDown = ({
    dropdown,
    timeZone,
    onClick,
}) =>
{
    const selectedSessionUserIdx = useRecoilValue(insightsSelectedSessionIdxState);
    const selectedUserSessionUID = useRecoilValue(insightsSelectedSessionUIDState);
    const trans = useTranslation().t;
    /**
   * checks if current property visit row is selected
   */
    const isPropertyVisitSelected = React.useCallback(({ sessionIdx, sessionUID }) =>
    {
        return (sessionIdx === selectedSessionUserIdx && sessionUID === selectedUserSessionUID);
    }, [selectedSessionUserIdx, selectedUserSessionUID]);

    /**
     * handles onclick for property visit click
     */
    const handlePropertyVisitClick = useCallback((propertyVisit) =>
    {
        onClick && onClick(propertyVisit);
    }, [onClick]);

    /**
     * returns the status icon for the passed trajectory
     */
    const getTrajectoryStatusIconFromTrajectory = useCallback((trajectory) =>
    {
        const status = trajectory.trajectoryReconstructionStatus;

        const iconColor = InsightsTrajectoryStatusToIcon[status];

        return `/img/icon-status-${iconColor}.svg`;
    }, []);


    /**
     * returns the status text for the passed trajectory
     */
    const getTrajectoryStatusTextFromTrajectory = useCallback((trajectory) =>
    {
        const status = trajectory.trajectoryReconstructionStatus;

        const text = InsightsTrajectoryStatusToReadableText[status];

        return text;
    }, []);

    /**
     * renders property visit table
     */
    const renderPropertyVisitTable = useCallback(() =>
    {
        if (dropdown)
        {
            return (
                dropdown.session.propertyVisits.map((item, idx) => (
                    <div title={!isTrajectoryConstructed(item) && trans(`Insights.TrajectoryStatus.${getTrajectoryStatusTextFromTrajectory(item)}`)} onClick={() => isTrajectoryConstructed(item) && handlePropertyVisitClick(item)} key={idx} className={classNames({ "tableboxItemRow": true, "selected": isPropertyVisitSelected(item), "v-disabled": !isTrajectoryConstructed(item) })}>
                        <div className="tableboxItemColumn pname">{item?.buildingName}</div>
                        <div className="tableboxItemColumn">
                            {convertMinsToHrsAndMins(item?.duration_min)}
                        </div>
                        <div className="tableboxItemColumn">
                            {convertUnixSecondsToReadAbleTimeFormatWithTimeZone(item?.startTime / 1000, timeZone.id)}
                        </div>
                        <div className="tableboxItemColumn">
                            {convertUnixSecondsToReadAbleTimeFormatWithTimeZone(
                                (item.startTime +
                                    item?.duration_min * 60 * 1000) /
                                1000,
                                timeZone.id
                            )}
                        </div>

                        <div className="tableboxItemColumn visitSessionStatusColumn">
                            <Popup
                                trigger={<img src={getTrajectoryStatusIconFromTrajectory(item)} alt="" />} // for other icons change the color = yellow, green
                                basic
                                className="infoPopup"
                                position="top right"
                                offset={[10, 10]}
                                content={trans(`Insights.TrajectoryStatus.${getTrajectoryStatusTextFromTrajectory(item)}`)} />
                            {/* {sessionRow.sessionStatus} */}
                        </div>
                    </div>
                ))
            );
        }
    }, [dropdown, isPropertyVisitSelected, handlePropertyVisitClick, timeZone, getTrajectoryStatusIconFromTrajectory, getTrajectoryStatusTextFromTrajectory]);

    return (
        <div className="dataTablebox dataTableboxInsights dataTableSession">
            <div className="tableboxItemRow">
                <div className="tableboxItemColumn pname"><strong>{trans("Insights.Building_Name")}</strong></div>
                <div className="tableboxItemColumn"><strong>{trans("Insights.Duration")}</strong></div>
                <div className="tableboxItemColumn"><strong>{trans("Insights.Start")}</strong></div>
                <div className="tableboxItemColumn"><strong>{trans("Insights.End")}</strong></div>
                <div className="tableboxItemColumn"></div>
            </div>
            {/* PROPERTY VISIT TABLE */}
            {renderPropertyVisitTable()}
        </div>
    );
};

export default SessionTableDropDown;
