exports.MapConstants = {
    MAX_ZOOM: 24,
    MIN_ZOOM_TEXT_LAYER: 14,
    MIN_ZOOM: 10,
    HIDDEN_LAYER: "-1",
    TEXT_LAYER: "text_layer",
    IMAGE_LAYER: "image_layer",
    ANIMATION_DURATION: 200,

    FIT_PADDING_ENTITIES: [290, 290, 290, 290],
    FIT_PADDING_ENTITIES_MOBILE: [120, 85, 320, 85],

    FIT_PADDING_MAP_DATA: [20, 20, 20, 20],
    CLUSTER_DISTANCE: 18,
    MIN_SPLIT_AREA: 1,
};

exports.FLOOR_PLAN_LAYERS_IDS = {
    FLOOR_PLAN_GEO_REF_IMAGE_LAYER: "FloorPlan",
    FLOOR_PLAN_VECTOR_LAYER: "floor_plan_vector_layer",
    FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER: "floor_plan_image_map_image_layer",
    FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER: "floor_plan_image_map_vector_layer",
};

exports.FLOOR_PLAN_LAYERS_RENDER_ORDER = {
    [this.FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER]: 1,
    [this.FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER]: 2,
    [this.FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER]: 1,
    [this.FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER]: 2,
};

exports.FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS = {
    FLOOR_PLAN_ADD_POINT_INTERACTION: "FLOOR_PLAN_ADD_POINT_INTERACTION",
    FLOOR_PLAN_POINT_MODIFY_INTERACTION: "FLOOR_PLAN_POINT_MODIFY_INTERACTION",
    FLOOR_PLAN_GEO_REF_POINT_GROUP_MODIFY_INTERACTION: "FLOOR_PLAN_GEO_REF_POINT_GROUP_MODIFY_INTERACTION",
    FLOOR_PLAN_TRANSFORM_INTERACTION: "FLOOR_PLAN_TRANSFORM_INTERACTION",
};




exports.MapEditorConstants = {
    MAX_ZOOM: 34,
    MIN_ZOOM_TEXT_LAYER: 14,
    MIN_ZOOM: 10,
    HIDDEN_LAYER: "-1",
    TEXT_LAYER: "text_layer",
    IMAGE_LAYER: "image_layer",
    ENTITY_ID_LAYER: "entityIds",
    NODE_ID_LAYER: "nodeIds",
    ANIMATION_DURATION: 200,
    ...this.FLOOR_PLAN_LAYERS_IDS,
    FIT_PADDING_ENTITIES: [290, 290, 290, 290],
    FIT_PADDING_ENTITIES_MOBILE: [120, 85, 320, 85],

    FIT_PADDING_MAP_DATA: [20, 20, 20, 20],
    CLUSTER_DISTANCE: 18,
    MIN_SPLIT_AREA: 1,
};

exports.DEFAULT_HIGHLIGHT_STYLE = {
    stroke: {
        color: "blue",
        width: 2
    },
    fill: {
        color: "rgba(0,0,255,0.1)",
    }
};

exports.DEFAULT_HIGHLIGHT_STYLE_POINT = {
    stroke: {
        color: "blue",
        width: 2
    },
    fill: {
        color: "rgba(0,0,255,0.1)",
    },
    shape: {
        radius: 5,
    }
};

exports.DEFAULT_ERROR_HIGHLIGHT_STYLE = {
    stroke: {
        color: "red",
        width: 2
    },
    fill: {
        color: "rgba(255,0,0,0.1)",
    }
};

exports.DEFAULT_ERROR_HIGHLIGHT_STYLE_POINT = {
    stroke: {
        color: "red",
        width: 2
    },
    fill: {
        color: "rgba(255,0,0,0.1)",
    },
    shape: {
        radius: 5,
    }
};

exports.MAINTENANCE_HIGHLIGHT_STYLE = {
    SELECT: {
        stroke: {
            color: "#C9B8A1",
            width: 2
        },
        fill: {
            color: "rgba(246, 230, 171)",
        }
    },
    // used when picking spots for designated areas.
    CREATE: {
        stroke: {
            color: "#273EEC",
            width: 2
        },
        fill: {
            color: "rgba(27, 193, 248)",
        }
    }
};

exports.DEFAULT_ENTITY_TEXT_STYLE = {
    stroke: "rgba(0, 0, 0, 0)",
    fill: "rgba(0, 0, 0, 0)",
};

exports.TRANSITION_BOUNDING_BOX_SELECTED_STYLE = {
    fill: {
        color: "rgba(0, 0, 255, .2)"
    },
    stroke: {
        color: "rgba(0, 0, 255, 1)",
        width: 3
    },
};

exports.DEFAULT_CATEGORY_ICON_SIZE = [40, 40];
exports.DEFAULT_ICON_STYLE = {
    opacity: 0.9,
    scale: [0.4, 0.4],
};

// entities must be at least 50% of the avg entity size
exports.THRESHOLD_PERCENTAGE_FOR_IMAGES = .5;

exports.MAP_THEMES = {
    CLASSIC: "styleClassic",
    DARK: "styleDark",
    BLANK: "styleBlank",
};


exports.WALKER_DIRECTIONS = {
    LEFT: "left",
    RIGHT: "right",
};


exports.DEFAULT_LANGUAGE_CODE = "en";

exports.OVERLAY_MODAL_TEXT_LENGTH_LIMIT = 15;

exports.DEFAULT_HEX_RADIUS = 0.5 * Math.sqrt(7);

exports.POI_IMAGE_SIZE = {
    INDOOR: 2,
    OUTDOOR: 4
};

exports.INDOORS_REF_TYPE = "Floor";

exports.ENTITY_CLICK_THRESHOLD = 4;

exports.TOPOLOGY_OBJECTS = {
    ENTITIES: "entities",
    NODES: "nodes",
};
exports.TEXT_LABEL_LENGTH_LIMIT = 40;

exports.SNAP_PIXEL_TOLERANCE = 8;

exports.MAPSTED_LIGHT_THEME = {
    _id: "mapstedLight",
    name: {
        [exports.DEFAULT_LANGUAGE_CODE]: "Mapsted Light"
    },
    tileLayer: {
        url: "https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
        projection: "EPSG:3857",
        attributions: "\n                      <a href=\"https://openlayers.org\" target=\"_blank\" rel=\"noopener noreferrer\">Open Layers</a> |\n                    © <a href=\"https://cartodb.com/attributions\" target=\"_blank\" rel=\"noopener noreferrer\">Carto Voyager</a> | \n                    © <a href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\" rel=\"noopener noreferrer\">OpenStreetMap</a> \n                ",
        baseMapId: "voyager"
    },
    active: true,
    dark: false,
};

exports.MAPSTED_DARK_THEME = {
    _id: "mapstedDark",
    name: {
        [exports.DEFAULT_LANGUAGE_CODE]: "Mapsted Dark"
    },
    tileLayer: {
        url: "https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png",
        projection: "EPSG:3857",
        attributions: "\n                      <a href=\"https://openlayers.org\" target=\"_blank\" rel=\"noopener noreferrer\">Open Layers</a> |\n                    © <a href=\"https://cartodb.com/attributions\" target=\"_blank\" rel=\"noopener noreferrer\">Carto Dark All</a> | \n                    © <a href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\" rel=\"noopener noreferrer\">OpenStreetMap</a> \n                ",
        baseMapId: "darkMatter"
    },
    active: true,
    dark: true,
};

exports.NODE_RADIUS = 0.15; // radius of node
exports.NODE_LINK_POINTER_RADIUS = 0.000075; // radius of pointer cone
exports.NODE_LINK_BUFFER_DD = 0.0000013; // how much to shorten node link line in decimal degrees (higher number = shorter line)
exports.NODE_LINK_OFFSET = 0.075;// how much to separate two way node link connections (higher number = further apart)
exports.TRANSITION_BOX_RADIUS = .00021; // radius used to create the dimensions of the transition box (higher number = bigger box)

exports.POI_RADIUS = 0.5;

exports.NODE_GEOMETRY_OPTIONS = {
    pointToCircle: true,
    radius: exports.NODE_RADIUS
};

exports.EDITOR_POI_GEOMETRY_OPTIONS = {
    pointToCircle: true,
    radius: exports.POI_RADIUS
};
