import React from "react";
import { ResponsiveSunburst } from '@nivo/sunburst'

export const SunburstChart = ({ data, options, customTooltip, customLabel, onClick }) => 
{
    return (
        <ResponsiveSunburst
            data={data}
            margin={{ top: 10, right: 10, bottom: 20, left: 10 }}
            id={options.id}
            value={options.value}
            cornerRadius={1}
            colors={options.colors}
            childColor={{ from: 'color', modifiers: [['brighter', 0.2]] }}
            arcLabel={customLabel}
            enableArcLabels={true}
            arcLabelsSkipAngle={10}
            borderColor={"rgba(0,0,0,0.1)"}
            tooltip={customTooltip}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
            onClick={onClick}
        />
    )
}


