import React from "react";
import "./Mapsted.css";


export default function Mapsted({ small })
{
    const className = small ? "mapsted-splash-small" : "mapsted-splash";

    return (
        <React.Fragment>
            <div className={className}>
                <img src="https://studio.mapsted.com/Content/images/landing.gif" alt="loading..."/>
            </div>
        </React.Fragment>
    );
}
