import React from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import "./DropdownForm.css";

export const DropdownForm = ({ className, value, text, icon, options = defaultOptions, button, onClick, onChange, ...rest }) => (
    <Dropdown className={`dropdownGroup${className ? " " + className : ""}`}
        {...rest}
        button={button}
        text={text}
        icon={icon ? icon : "caret down"}
        value={value}
        onChange={onChange}
        selection
        onClick={onClick}
        options={options}
        fluid

    />

);

const defaultOptions = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
];
