export const NAV_ANALYTICS_QUERY_KEYS = {
    DASHBOARD_SUMMARY: "Widget/DashboardSummary",
    USER_VS_TIME: "Widget/UsersVsTime",
    HARDWARE: "Widget/Hardware",
    HEATMAP: "Widget/Heatmap",
    NUMBER_ZONE_VISITS_PER_SESSION: "Widget/ZoneGeofenceVisitsPerSession",
    SCREEN_RESOLUTION: "Widget/ScreenResolution",
    SERVICE_PROVIDER: "Widget/ServiceProvider",
    ZONE_GEOFENCES: "Widget/PoiVisitsPerZoneGeofence",
    ZONE_FLOWS: "Widget/ZoneGeofenceFlows",
    ZONE_TRAFFIC: "Widget/ZoneGeofenceTraffic",
    CATEGORY_SEARCHED_VS_VISITED: "Widget/CategorySearchedVsVisited",
    WIDGET_TRAJECTORY: "widget/trajectory",
    WIDGET_USER_TRAJECTORIES_INFO: "Widget/UserTrajectoriesInfo",
    FILTER_VALUES: "Widget/FilterValues",
    APP_USAGE_BY_USER: "Widget/AppUseageByUser",

    //NAV_ANALYTICS_QUERY_KEYS
    USER_SUMMARIES: "Positioning/UserSummaries",
    PROPERTY_SUMMARY: "Positioning/PropertySummary",
    PROPERTY_SUMMARIES: "Positioning/PropertySummaries",
    ANALYTICS_SUMMARY: "Positioning/AnalyticsSummary",
    INTERACTION_RECORDS: "Positioning/InteractionRecords",
    USER_TRAJECTORY_SUMMARIES: "Positioning/UserEmailTrajectoriesSummaries",
    // USER_TRAJECTORY_SUMMARIES: "Positioning/UserTrajectoriesSummariesV2",
    USER_SESSIONS_SUMMARY: "Positioning/UserSessionsSummary",



    //NAV_ANALYTICS_QUERY_KEYS
    ZONE_GEOFENCE: "Live/ZoneGeofence",

    //NAV_ENGAGEMENT_QUERY_KEYS
    ENGAGEMENT_OVERVIEW: "Engagement/Overview",
    ENGAGEMENT_SEARCH_RECORD: "Engagement/SearchRecords",

    //NAV_GENERAL_QUERY_KEYS
    GENERAL_TOTAL_DOWNLOADS: "General/TotalDownloads",

    RETENTION_RATE: "RetentionRate",
    BOOST_HEATMAP: "Boost/Heatmap",
    Boost_ZoneGeofenceTraffic: "Boost/ZoneGeofenceTraffic",
    BOOST_TRAFFIC_FLOWS: "Boost/ZoneGeofenceTrafficFlows",
    BOOST_QUICK_SNAPSHOT: "Boost/QuickSnapshot",
    BOOST_NETWORK_VISITORS: "Boost/NetworkVisitors/",
    BOOST_COMFORT: "Boost/Comfort",
    BOOST_DEVICE_INFO: "Boost/",
    BOOST_TAG_INFO: "Boost/GetTagDeviceInfo",
    BOOST_DEVICE_INFO_GET_INPUT_DEVICES: "Boost/GetClientsInputDevices",
    BOOST_DEVICE_INFO_SAVE_INPUT_DEVICES: "Boost/SaveClientsInputDevices",
    BOOST_DEVICE_INFO_REMOVE_INPUT_DEVICES: "Boost/RemoveClientsInputDevices",
    TAG_TRAJECTORIES: "BTags/Trajectories",
    TAG_TRAFFIC_FLOW: "BTags/ZoneGeofenceTrafficFlows",
    TAG_HEAT_MAPS: "BTags/BuildingHeatmaps",
    TAG_ZONEGEOFENCE_TRAFFIC: "BTags/BuildingZoneGeofenceTraffic",
    BOOST_TAG_QUICK_SNAPSHOT: "BTags/QuickSnapshot",

    ENGAGEMENT_WEB_SESSIONS: "webAnalytics/sessions",
    ENGAGEMENT_WEB_SEARCHES: "webAnalytics/search",
    ENGAGEMENT_WEB_DESTINATIONS: "webAnalytics/destination",
    ENGAGEMENT_WEB_FILTER_META: "webAnalytics/filtersMeta",

    GENERAL_ZONE_GEOFENCE: "General/zoneGeofences",
    PROPERTIES_USERS: "General/UsersWithSessions",
    TAG_INFO_UPDATE_TAGINFO: "asset/core/SaveTagDeviceInfo",
    NEW_QUERY_KEY: "NEW_QUERY_KEY",

    COMPLETE_ANALYTICS_SUMMARY: "Positioning/AnalyticsSummaries",

};


export const ALERTS_QUERY_KEYS = {
    CALENDER: "live/calendar",
    HEATMAP: "live/heatmap",
    POSITIONS: "live/positions",
};

export const ANALYTICS_QUERY_KEYS = {
    WEATHER: "Weather/History",
};

export const PUBLIC_QUERY_KEYS = {
    MAP_DATA: "MapData",
    BULK_MAP_DATA: "BulkMapData",
    MAP_OVERLAYS: "mapOverlays",
    OVERLAY_TEMPLATES: "overlayTemplates"
};

export const WRAPPER_QUERY_KEYS = {
    ENV_CONSTANTS: "EnvConstants",
    PROPERTY_MAP_DATA: "PropertyMapData",
};

export const QUERY_OPTIONS = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
};
