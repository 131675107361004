import React, { memo, useContext, useEffect, useMemo } from 'react';
import { SESSION_DURATION_LINE_CHART_OPTIONS } from '../../../_constants/chartConstants';
import { convertMinsTo_HHMMSS, formatMinsToHHMMSS } from '../../../_utils/date.utils';
import { LineChart } from '../../charts/LineChart';
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader';
import { WidgetPercentageText } from '../../common/PercentagePara';
import Translation from '../../common/translation/Translation';
import { DefaultBox } from '../../dashboard/DefaultBox';
import UseAverageSessionVsTimeWebEngagement from './UseAverageSessionVsTimeWebEngamenet';
import { EngagementContext } from '../../pages/engagement/EngagementContext';
import { formatDurationHMS, getDateGroupAndFormat } from '../../../_utils/chart.utils';
import { formatDateValuesFromChartToCSV, formatTimeStringsForCSV } from '../../../_utils/exportToCsvUtils';
import { connectedPageNameState } from '../../../store/ExportToCsvAtoms';
import { useRecoilValue } from 'recoil';




const CustomSliceTooltip = memo(
    ({ slice }) =>
    {
        return (
            <div className="widgetsTooltipBox">
                <strong>{slice.points[0].data.x}</strong>
                {
                    // each points data ie. "[point.color] 120 New Visits"
                    slice.points.map((point) => (
                        <div className="tooltipItemRow" key={point.id}>
                            <span style={{ background: point.borderColor }}></span>
                            {point.data.label} <Translation text={("SessionDurationVsTimeWidget.Average_Duration")} />
                            <strong>{convertMinsTo_HHMMSS(point.data.y)}</strong>
                        </div>
                    ))
                }
            </div>
        );
    }
);

const RenderDurationFormatted = memo(({ averageDurationFormatted }) =>
{
    if (!averageDurationFormatted)
    {
        return <></>;
    }

    const { extra, content } = averageDurationFormatted;

    return content.map((duration, i) => (
        <strong key={duration + extra[i]}>
            {" "}
            {duration}{" "}
            <Translation text={`SessionDurationVsTimeWidget.${extra[i]}`} />
        </strong>
    ));
});

const RenderSessionsLineChart = memo(({ data }) =>
{
    const { lineData, averageDurationFormatted, percentageOfChange } = data ?? {
        lineData: [],
        averageDurationFormatted: [],
        percentageOfChange: 0,
    };
    return (
        <>
            <p className="para gap0">
                <Translation text="Widgets.WebEngagementAvgSessionWidget.info_text_head" />{" "}
                &nbsp;
                <RenderDurationFormatted
                    averageDurationFormatted={averageDurationFormatted}
                />{" "}
                &nbsp;
                <Translation text="Widgets.WebEngagementAvgSessionWidget.info_text_tail" />
            </p>
            <WidgetPercentageText percentage={percentageOfChange} />
            <div className="ChartWrapper">
                <LineChart
                    data={lineData}
                    options={SESSION_DURATION_LINE_CHART_OPTIONS}
                    customSliceTooltip={CustomSliceTooltip}
                />
            </div>
        </>
    );
});

const Container = ({ injectProcessedData }) =>
{
    const { sessionsQuery, processedWidgetData, analyticsRequestFilter } = UseAverageSessionVsTimeWebEngagement();
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data)
        {
            return [];
        }
        const { lineData, averageDurationFormatted, percentageOfChange } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return lineData?.flatMap(item =>
            item?.data?.map((point) => {

                return {
                    Date: formatDateValuesFromChartToCSV(point.x, dateGroupFormat),
                    AverageDurationFormatted: convertMinsTo_HHMMSS(point.y),
                    AverageDurationMins: point.y,
                    // percentageOfChange,
                    // content: averageDurationFormatted?.content || "",
                    // format: averageDurationFormatted?.extra || ""}
                }
            })
        );
    }, [processedWidgetData?.data, analyticsRequestFilter, connectedPageName]);

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "Average Length Of Time Users Interacted With Your Web Applications.",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            //   {
            // 	name: "Average Length Of Time Users Interacted With Your Web Applications.",
            // 	data: sessionsQuery?.data,
            // 	description: "",
            // 	injector: injectRawData
            //   },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={
                <Translation text="Widgets.WebEngagementAvgSessionWidget.title" />
            }
            webIcon={true}
        >
            <ConditionalRenderLoadingErrorNoDataChildren
                rawQuery={sessionsQuery}
            >
                <RenderSessionsLineChart {...processedWidgetData} />
            </ConditionalRenderLoadingErrorNoDataChildren>
        </DefaultBox>
    );
};

export default Container;
