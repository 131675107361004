import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { generatePdf } from "../../../_utils/utils";
import { WidgetLoadingBoxFull } from "../../common/placeholders/Placeholders";
import
{
    ErrorWidgetMessage,
    NoDataWidgetMessage,
} from "../../common/WidgetMessage/WidgetMessage";
import { ZoneGeofenceTrajectoryMapComponent } from "../../map/Components/ZoneGeofenceTrajectoryMapComponent";
import "./insights.css";
import { InsightsChart } from "./InsightsChart";
import { InsightsSubheader } from "./InsightsSubheader";
import { InsightsTable } from "./InsightsTable";
import useInsights from "./useInsights";
import { InsightsActiveSessionUIDLegend } from "./InsightsLegend";

export const InsightsOutline = () =>
{
    const { addAdditionalBtnProps, onFloorChangeFromMap, propertyOptionsStatus, trajectoryMapLayer, zoneGeofenceHistoryOverwriteTimeStamp_ms } = useInsights();
    const trans = useTranslation().t;
    let screenShotRef = useRef(null);
    let rotateMap = true;


    const renderDataComponents = useCallback(() =>
    {
        const { isSuccess, isError, hasData } =
            propertyOptionsStatus;
        if (isSuccess)
        {
            if (hasData)
            {
                return (
                    <React.Fragment>
                        <div className="insightsZoneBoxContainer">
                            <ZoneGeofenceTrajectoryMapComponent
                                {...{ addAdditionalBtnProps, onFloorChangeFromMap, }}
                                positionLayers={trajectoryMapLayer?.layers}
                                zoneGeofenceHistoryOverwriteTimeStamp_ms={zoneGeofenceHistoryOverwriteTimeStamp_ms}
                                rotateMap={rotateMap}
                                trajectoryMapLayer={trajectoryMapLayer}
                                isHistoryDropdownVisible={false}
                            />
                            <InsightsActiveSessionUIDLegend />
                        </div>
                        <InsightsChart />
                    </React.Fragment>
                );
            }
            else
            {
                return (
                    <div className="insightsContainerWrap">
                        <div className="insightsLoadingMessage">
                            <NoDataWidgetMessage
                                customMessage={
                                    "The selected user does not have any recorded visits for the time range selected."
                                }
                            />
                        </div>
                    </div>
                );
            }
        }
        else if (isError)
        {
            return (
                <div className="insightsContainerWrap">
                    <div className="insightsLoadingMessage">
                        <ErrorWidgetMessage />
                    </div>
                </div>
            );
        }
        return <> <WidgetLoadingBoxFull>
            {trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}
            <br />{" "}
            {trans("Placeholders.Thank_you_for_your_patience_while_we_gat")}{" "}
        </WidgetLoadingBoxFull></>;
    }, [propertyOptionsStatus?.dataUpdatedAt, trajectoryMapLayer?.layers, zoneGeofenceHistoryOverwriteTimeStamp_ms, addAdditionalBtnProps, onFloorChangeFromMap,]);

    const handlePdfGeneration = React.useCallback(
        () =>
        {
            generatePdf(screenShotRef, "insights");
        },
        [screenShotRef.current]
    );

    return (
        <div className="insightsBody" ref={screenShotRef}>
            <InsightsSubheader isTodayDateAllowed={true} generatePdf={handlePdfGeneration} />

            {renderDataComponents()}
            {/*
                we want the table component to always show regardless if the data components are loading or have no data
                the table will handle its own loading and no data messages
            */}

            <InsightsTable />

        </div>
    );
};
