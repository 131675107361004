import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { environmentConstantsState } from "../../store/AppAtoms";
import { analyticsRequestFilterSelector, hasAccessToPropertySelector } from "../../store/DashboardAtoms";
import serverApi from "../../_api/server.api";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { processTopSearchedKeywordsWidget } from "../../_utils/chart.utils";
import { refetchQuery } from "../../_utils/query.utils";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";

export const TopSearchesWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);
    // query  engagement/searchRecords data with current and compare
    const searchRecordsQuery = useQuery(
        [
            "search" + NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD,
            { analyticsRequestFilter, environmentConstants },
        ],
        async () =>
        {
            if (!!analyticsRequestFilter && environmentConstants)
            {
                const result = await serverApi.getNavigationAnalyticsData(
                    analyticsRequestFilter,
                    NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD,
                    environmentConstants
                );

                if (!result?.success)
                {
                    throw new Error(`${NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD} response was not ok`);
                }

                return result.data;
            } else
            {
                throw new Error("Filter was not ok");
            }
        },
        {
            enabled: !!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );
    // render widget/loading/error
    const processedTopSearchesData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data, refetch } = searchRecordsQuery;

        let processedData;

        if (isSuccess && data)
        {
            processedData = processTopSearchedKeywordsWidget(data);
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [searchRecordsQuery]);

    const processedExportData = useMemo(() => {
        if (!processedTopSearchesData?.data) {
            return [];
        };

        const modifiedData = processedTopSearchesData?.data?.tableDataArray.map((item) => {
            return { Keywords: item.searchedString, Volume: item.searchCount }
        })
        return modifiedData;
    }, [processedTopSearchesData?.data, connectedPageName])

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "What people search in the app",
                data: processedExportData,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "What people search in the app",
            //     data: searchRecordsQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedExportData]);

    const renderTopSearchesWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedTopSearchesData;

        if (isSuccess && data)
        {
            const threshold = 6;
            const { tableDataArray, mostSearchedKeyword } = data;

            if (tableDataArray.length === 0)
            {
                return <NoDataWidgetMessage />;
            }

            let renderKeywordRows = [];
            let limit = tableDataArray.length > threshold ? threshold : tableDataArray.length;

            for (let i = 0; i < limit; i++)
            {
                const { searchedString: label, searchCount: volume } = tableDataArray[i];

                renderKeywordRows.push(<KeywordTableRow key={label} {...{ label, volume }} />);
            }

            return (
                <React.Fragment>
                    {mostSearchedKeyword && (
                        <p className="para">
                            <strong>{mostSearchedKeyword}</strong>{" "}
                            {trans("TopSearchesWidget.was_the_most_searched_keyword_in_the_sel")}
                        </p>
                    )}
                    <div className="peakHoursTable">
                        <KeywordTableHeader />
                        {renderKeywordRows}
                    </div>
                </React.Fragment>
            );
        } else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedTopSearchesData)} />;
        }
        // if loading
        else
        {
            return WidgetLoadingBoxReports();
        }
    }, [processedTopSearchesData, trans]);

    return (
        <DefaultBox className="alignedWidget" heading={trans("TopSearchesWidget.What_people_search_in_the_app?")}>
            {renderTopSearchesWidget()}
        </DefaultBox>
    );
};

const KeywordTableHeader = () =>
{
    const trans = useTranslation().t;

    return (
        <div className="peakHoursRow">
            <TableItem content={trans("TopSearchesWidget.KEYWORDS")} />
            <TableItem content={trans("TopSearchesWidget.VOLUME")} />
        </div>
    );
};

const KeywordTableRow = ({ label, volume }) =>
{
    return (
        <div className="peakHoursRow">
            <TableItem content={label} tooltip />
            <TableItem content={volume} />
        </div>
    );
};

const TableItem = ({ content, tooltip }) =>
{
    return (
        <div className={`peakHoursItem ${tooltip ? "tooltip" : ""}`}>
            <div className="peakHoursColumn">{content}</div>

            {tooltip && <div className="peekHoursColumnToolTipText"> {content} </div>}
        </div>
    );
};
