import React, { useCallback, useMemo } from "react";

import { Popup, Input, } from "semantic-ui-react";
import { doesStringIncludeFilter, sortDropdownCaseInsensitive as sortDropdownCaseInsensitive } from "../../../_utils/utils";
import { ButtonIcon } from "../buttonIcon/ButtonIcon";
import { DropdownListItem } from "./DropdownListItem";

/**
 *
 * @param {String} name
 * @param {Array} options - [{key, value, text}]
 * @param {String} value - selected value
 * @param {Function} onChange - on selected value change (value)
 * @returns
 */
export const DropdownPopup = (
    {
        sortOptions = true,
        name,
        options,
        value,
        onChange,
        placeholder,
        noOptionSelectedText,
    }
) =>
{
    const [filter, setFilter] = React.useState("");
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);

    const selectedOption = useMemo(() =>
    {
        if (!Array.isArray(options) || !value)
        {
            return value;
        }

        let selectedOption = options.find((option) => option?.value === value);

        return selectedOption?.text;

    }, [value, options]);

    /**
     * sets filter back to default value
     */
    const resetDefaults = React.useCallback(() =>
    {
        setFilter("");

    }, [setFilter]);

    /**
     * Toggles the dropdown selection
     */
    const handlePopupToggle = React.useCallback(() =>
    {
        setIsPopupOpen(!isPopupOpen);
        resetDefaults();
    }, [isPopupOpen, setIsPopupOpen, resetDefaults]);

    /**
    * Closes the dropdown selection
    */
    const handleClosePopup = React.useCallback(() =>
    {
        setIsPopupOpen(false);
        resetDefaults();
    }, [isPopupOpen, setIsPopupOpen, resetDefaults]);


    /**
     * On dropdown item select -> calls on change
     */
    const handleChange = useCallback((value, op) =>
    {
        (onChange) && onChange(value, op);
        handleClosePopup();
    }, [onChange, handleClosePopup]);

    /**
     * Called when user types in filter box -> change filter value
     */
    const handleChangeFilter = React.useCallback((e, { name, value }) =>
    {
        setFilter(value);

    }, [setFilter]);

    // --- RENDER FUNCTIONS --- //

    /**
     * Renders filtered sorted dropdown list of items provided by options
     */
    const renderDropdown = useCallback(() =>
    {
        if (!Array.isArray(options))
        {
            return;
        }

        let filteredOptions = options.filter((option) => doesStringIncludeFilter(option.text, filter));
        if (sortOptions)
        {
            filteredOptions = sortDropdownCaseInsensitive(filteredOptions, "text", value, "value");
        }
        else
        {
            filteredOptions.sort((a) => a.value === value ? -1 : 1);
        }

        return filteredOptions.map((option) =>
        {
            const { key, value: optionValue, text, popupContent } = option;
            return (
                <DropdownListItem
                    className={"propertyPopupTooltip"}
                    position="bottom left"
                    key={key}
                    isSelected={key === value}
                    content={text}
                    popupContent={popupContent ?? `${text}, ${key}`}
                    onClick={() => handleChange(optionValue, option)}
                    isBuilding
                />
            );
        });

    }, [options, filter, handleChange, value, sortOptions]);

    return (
        <Popup
            open={isPopupOpen}
            className="subheaderPropertyPopup"
            position="bottom left"
            on="click"
            closeOnDocumentClick={true}
            closeOnEscape={true}
            onClose={handleClosePopup}
            trigger={<ButtonIcon className="buttonPropertyToggle" icon="angle-circular" content={<span>{selectedOption || noOptionSelectedText}</span>} onClick={handlePopupToggle} />}
        >
            <Input icon="search" autoFocus autoComplete="off" placeholder={placeholder || "Search"} name={name || "dropdown filter"} value={filter} onChange={handleChangeFilter} />
            <div className="searchDropdownCover">
                {
                    renderDropdown()
                }
            </div>

        </Popup>
    );
};
