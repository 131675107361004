import React, { useEffect } from "react";
import WidgetView from "../Widget.view";
import CommonlyUsedDevicesBarChart from "./CommonlyUsedDevicesBar.chart";
import useCommonlyUsedDevices from "./useCommonlyUsedDevices";
import { useTranslation } from "react-i18next";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { getWidgetTitle } from "../../../_utils/utils";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const CommonlyUsedDevicesContainer = ({ injectProcessedData }) =>
{
    const comlyusedDevices = useCommonlyUsedDevices();
    const { data } = comlyusedDevices;
    const connectedPageName = useRecoilValue(connectedPageNameState);
    const { t } = useTranslation();

    useEffect(() =>
    {
        if(!data)
        {
            return; 
        }
        
        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.CommonlyUsedDevicesWidget),
            data: data?.commonlyUsedDeviceBarData.map(({modelName, Users}) => ({ ModelName: modelName, Users })) || [],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [data, connectedPageName]);

    return (
        <WidgetView
            {...comlyusedDevices}
            heading={comlyusedDevices.trans("CommonlyUsedDevicesWidget.What_is_the_most_commonly_used_device_in")}
        >
            <CommonlyUsedDevicesBarChart {...comlyusedDevices} />
        </WidgetView>
    );
};

export default CommonlyUsedDevicesContainer;
