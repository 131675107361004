import React, { useEffect } from "react";
import WidgetView from "../Widget.view";
import ServiceProviderPieChart from "./ServiceProviderPie.chart";
import useServiceProvider from "./useServiceProvider";
import { useTranslation } from "react-i18next";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { getWidgetTitle } from "../../../_utils/utils";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const ServiceProviderContainer = ({ injectProcessedData }) =>
{
    const serviceProvider = useServiceProvider();
    const { data } = serviceProvider;
    const { t } = useTranslation();
    const connectedPageName = useRecoilValue(connectedPageNameState);

    useEffect(() =>
    {
        if(!data) 
        {
            return;
        }

        let modifiedData = data?.serviceProviderPieData?.map(({label, value, percentage })=>{
            return {
                ServiceProvider: label,
                Users: value,
                Percentage: percentage
            }
        });

        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.ServiceProviderWidget),
            data: modifiedData || [],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [data, connectedPageName]);


    return (
        <WidgetView
            {...serviceProvider}
            heading={serviceProvider.trans("ServiceProviderWidget.What_is_the_most_common_service_provider")}
        >
            <ServiceProviderPieChart {...serviceProvider} />
        </WidgetView>
    );
};

export default ServiceProviderContainer;
