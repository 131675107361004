import
{
    addDays,
    addSeconds,
    compareAsc,
    differenceInCalendarDays,
    differenceInHours,
    differenceInMilliseconds,
    differenceInMinutes,
    differenceInMonths,
    differenceInWeeks,
    endOfDay,
    format,
    formatDistance,
    isToday,
    startOfDay,
    startOfHour,
    startOfMonth,
    subDays,
    subHours,
    subMilliseconds,
    subWeeks,
    subYears
} from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import startOfWeek from "date-fns/startOfWeek";
import { DATA_AGGREGATIONS, DATE_GROUP_RANGE, DATE_TYPES, DATE_TYPES_FOR_LAST_60_DAYS, DATE_TYPES_FOR_LAST_ONE_WEEK, DATE_TYPES_FOR_LAST_THREE_HOURS, DATE_TYPES_WITH_TODAY, DATE_TYPES_FOR_LAST_60_DAYS_WITHOUT_TODAY } from "../_constants/constants";

import { es, fr, ja, nl, pt } from "date-fns/locale";

import { subSeconds } from "date-fns";
import i18n from "i18next";
import { getAlertsDateRange } from "./alerts.utils";
import { convertDateTimeToLocalJSDateWithoutChangingTime, createDateTimeZoneDateFromJSDate, getLatestJsDateBasedOnTimeZone, isDateTimeToday, formatDateTimeToUTCString as luxonFormatDateTimeToUTCString } from "./date.luxon.utils";
const LOCALES = { pt, fr, nl, ja, es };

/**
 * Generates a date range based on the specified date type.
 *
 * @param {string} dateType - The type of date range to generate.
 * @param {boolean} isTodayDateAllowed - (Optional) Flag indicating if today's date is allowed in the range default is true.
 * @return {string} The generated date range.
 */
export const getDateRange = (dateType, isTodayDateAllowed = true, timeZoneId = "UTC") =>
{
    switch (dateType)
    {
        case DATE_TYPES.TODAY: {
            return getTodayDateRange(timeZoneId);
        }
        case DATE_TYPES.YESTERDAY: {
            return getYesterdayDateRange(timeZoneId);
        }
        case DATE_TYPES.LAST_WEEK: {
            return getLastWeekDateRange(isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.LAST_30_DAYS: {
            return getLast30DaysDateRange(isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.LAST_60_DAYS: {
            return getLast60DaysDateRange(isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.LAST_YEAR: {
            return getLastYearDateRange(isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.ONEHOUR: {
            return getLastHoursDateRange(1, isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.TWOHOURS: {
            return getLastHoursDateRange(2, isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.THREEHOURS: {
            return getLastHoursDateRange(3, isTodayDateAllowed, timeZoneId);
        }
        case DATE_TYPES.THIS_MONTH: {
            return getCurrentMonthDataRange(isTodayDateAllowed, timeZoneId);
        }
        default: {
            return "";
        }
    }
};


const getCurrentMonthDataRange = (isTodayDateAllowed, timeZoneId) =>
{
    let date = new Date();

    if (timeZoneId)
    {
        date = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }

    if (!isTodayDateAllowed)
    {
        date = subDays(date, 1);
    }

    let startDate = startOfMonth(date);

    return formatDateTimeToRange(startDate, date);

};

const isLast3HoursDateType = (activeDateTypes) => activeDateTypes.every((t) => DATE_TYPES_FOR_LAST_THREE_HOURS.includes(t));
const isLastOneWeek = (activeDateTypes) => activeDateTypes.every((t) => DATE_TYPES_FOR_LAST_ONE_WEEK.includes(t));
const isLast60Days = (activeDateTypes) => activeDateTypes.every((t) => DATE_TYPES_FOR_LAST_60_DAYS.includes(t));

/**
 * Generates a corrected date range based on the active date types provided. right now only handles DATE_TYPES_FOR_LAST_THREE_HOURS ,  DATE_TYPES_FOR_LAST_ONE_WEEK , DATE_TYPES_FOR_LAST_60_DAYS
 *
 * @param {array} activeDateTypes - An array of active date types.
 * @param {object} dataRange - The original data range object.
 * @return {object} The adjusted date range based on the active date types.
 */
export const correctDateRangeBasedOnActiveDateTypes = (activeDateTypes, dataRange) =>
{

    if (Array.isArray(activeDateTypes))
    {
        if (isLast3HoursDateType(activeDateTypes))
        {
            return getAdjustedDateRangeForBoostHeatMaps(dataRange, DATA_AGGREGATIONS.EVERY_TWO_MINUTES);
        }

        if (isLastOneWeek(activeDateTypes))
        {
            return getAdjustedDateRangeForBoostHeatMaps(dataRange, DATA_AGGREGATIONS.EVERY_HOUR);
        }

        if (isLast60Days(activeDateTypes))
        {
            return getAdjustedDateRangeForBoostHeatMaps(dataRange, DATA_AGGREGATIONS.EVERY_DAY);
        }

    }

    // to do need to add logic for other date types
    return dataRange;

};

export const getDateTypesBasedOnDataAggregation = (dataAggregationValue) =>
{
    if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_HOUR)
    {
        return DATE_TYPES_FOR_LAST_ONE_WEEK;
    }
    else if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_DAY)
    {
        return DATE_TYPES_FOR_LAST_60_DAYS_WITHOUT_TODAY;
    }
    else if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_TWO_MINUTES)
    {
        return DATE_TYPES_FOR_LAST_THREE_HOURS;
    }
    else
    {
        return DATE_TYPES_WITH_TODAY;
    }
};


export const getMaxDaysDiffInDateRangeByDataAggregation = (dataAggregationValue) =>
{
    if (dataAggregationValue == DATA_AGGREGATIONS.EVERY_HOUR)
    {

        //return 7 days
        return 7;
    }
    else if (dataAggregationValue == DATA_AGGREGATIONS.EVERY_DAY)
    {
        //return 60 days
        return 60;
    }
    else if (dataAggregationValue == DATA_AGGREGATIONS.EVERY_TWO_MINUTES)
    {
        //only one day
        return 1;
    }
    else return undefined;
};

export const getMinsToIncrement = (dateTypes) =>
{
    if (isLast3HoursDateType(dateTypes))
    {
        //lets keep it at 30 mins for now will see if needed
        return 30;
    }

    if (isLastOneWeek(dateTypes))
    {
        //returns time step size of 60 mins (1 hr )
        //i.e this dateType is been used for the heatmap boost /tags
        //setting step size to 60 mins will aline with the data aggregation settings
        return 60;
    }

    return 30;

};

export const getAdjustedDateRangeForBoostHeatMaps = ({ startDate, endDate }, dataAggregationValue, propertyTimeZoneId) =>
{
    const newDateRange = { startDate: new Date(startDate), endDate: new Date(endDate) };
    const allowedMaxCalenderDiffs = getMaxDaysDiffInDateRangeByDataAggregation(dataAggregationValue);
    const diffDays = differenceInCalendarDays(endDate, startDate);

    if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_TWO_MINUTES)
    {
        const maxHour = 3;
        const currentMaxDiff = differenceInHours(newDateRange.endDate, newDateRange.startDate);
        if (currentMaxDiff > maxHour)
        {
            //make sure we don't go over 3 hours so sub 1 sec
            newDateRange.endDate = addSeconds(newDateRange.startDate, maxHour * 60 * 60 - 1);
        }

    }
    else if (diffDays > allowedMaxCalenderDiffs)
    {
        newDateRange.endDate = addDays(newDateRange.startDate, allowedMaxCalenderDiffs - 1);
    }

    const possibleMaxEndDate = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);

    if (newDateRange.endDate > possibleMaxEndDate)
    {
        const beforeAdjustmentCalDiff = differenceInCalendarDays(newDateRange.endDate, possibleMaxEndDate);
        const diff = differenceInMilliseconds(newDateRange.endDate, newDateRange.startDate);
        newDateRange.startDate = subMilliseconds(possibleMaxEndDate, diff);
        newDateRange.endDate = possibleMaxEndDate;

        const newDiff = differenceInCalendarDays(newDateRange.endDate, newDateRange.startDate);

        if (beforeAdjustmentCalDiff !== newDiff)
        {
            newDateRange.startDate = startOfDay(newDateRange.endDate);
        }
    }

    return newDateRange;

};



export const getMaximinDateRangeByDataAggregation = (dataRange, dataAggregationValue, propertyTimeZoneId) =>
{
    if (!dataAggregationValue)
    {
        return dataRange;
    }

    let newDateRange = { startDate: new Date(dataRange.startDate), endDate: new Date(dataRange.endDate) };

    if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_DAY)
    {
        newDateRange.endDate = subDays(newDateRange.endDate, 1);
        newDateRange.startDate = subDays(newDateRange.startDate, 1);
    }

    const allowedMaxCalenderDiffs = getMaxDaysDiffInDateRangeByDataAggregation(dataAggregationValue);
    const startDateTime = createDateTimeZoneDateFromJSDate(newDateRange.startDate, propertyTimeZoneId);


    if (!isDateTimeToday(startDateTime, propertyTimeZoneId))
    {
        if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_TWO_MINUTES)
        {
            //for EVERY_TWO_MINUTES only three hours are allowed
            const maxHour = 3;
            //make sure we don't go over 3 hours so sub 1 sec
            newDateRange.endDate = addSeconds(newDateRange.startDate, maxHour * 60 * 60 - 1);

        }
        else
        {
            //newDateRange.endDate = addDays(newDateRange.startDate, allowedMaxCalenderDiffs - 1);
        }

    }
    else
    {

        const currentDate = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);
        if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_TWO_MINUTES)
        {
            //for EVERY_TWO_MINUTES only three hours are allowed
            const maxHour = 3;
            //make sure we don't go over 3 hours so sub 1 sec
            newDateRange.startDate = subSeconds(newDateRange.endDate, maxHour * 60 * 60 - 1);
        }
        else
        {
            newDateRange.startDate = startOfDay(currentDate);
        }

    }
    //the maximum date allowed is today so correcting if it exceeds
    const maxPossibleDate = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);

    if (maxPossibleDate < newDateRange.endDate)
    {
        const diff = differenceInMilliseconds(newDateRange.endDate, newDateRange.startDate);

        newDateRange.startDate = subMilliseconds(maxPossibleDate, diff);
        newDateRange.endDate = maxPossibleDate;

    }
    return newDateRange;


};


const getLastHoursDateRange = (hours, isTodayDateAllowed, timeZoneId) =>
{
    const endDate = getSetDateRangeEndDate(isTodayDateAllowed, timeZoneId);
    const startDate = subHours(endDate, hours);
    return formatDateTimeToRange(startDate, endDate);
};




/**
 * convert
 * @returns
 */
export const mostRecentAvailableDate = (timeZoneId, isTodayDateAllowed = false) =>
{
    let date = new Date();

    if (timeZoneId)
    {
        date = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }

    if (!isTodayDateAllowed)
    {
        date = subDays(date, 1);
    }


    return date;
};

/**
 * convert
 * @returns
 */
export const getTodayDateRange = (timeZoneId) =>
{

    let dt = new Date();

    if (timeZoneId)
    {
        dt = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }
    return formatDateTimeToRange(startOfDay(dt), dt);
};

// Function to get the date range representing two hours before the current time to the current time
export const getTodayDateRangeBeforeTwoHours = (hours, timeZoneId) =>
{
    // Get the current date and time
    let dt = new Date();

    if (timeZoneId)
    {
        dt = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }

    // Calculate the start time (two hours before the current time)
    let hoursBefore = new Date(dt.getTime() - (hours * 60 * 60 * 1000));

    return formatDateTimeToRange(hoursBefore, dt);
};

/**
 * convert
 * @returns
 */
export const getYesterdayDateRange = (timeZoneId) =>
{
    let currentDate = new Date();
    if (timeZoneId)
    {
        currentDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }
    let dt = subDays(currentDate, 1);
    return formatDateTimeToRange(startOfDay(dt), endOfDay(dt));
};

/**
 * Convert the given number of weeks ago to a date range.
 * If isTodayDateAllowed is true, the end date will be the current date.
 * If timeZoneId is provided, the dates will be converted to the given time zone.
 * @param {boolean} isTodayDateAllowed - Whether to include today's date in the range.
 * @param {string} timeZoneId - The time zone ID to convert the dates to.
 * @param {number} [lastWeeks=1] - The number of weeks ago to convert to a date range.
 * @returns {string} - The formatted date range.
 */
export const getLastWeekDateRange = (isTodayDateAllowed, timeZoneId, lastWeeks = 1) =>
{
    // Get the current date and time
    let currentDate = new Date();

    // If a time zone ID is provided, convert the current date and time to that time zone
    if (timeZoneId)
    {
        currentDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }

    // Calculate the start date (lastWeeks weeks ago)
    const startDate = subWeeks(startOfDay(currentDate), lastWeeks);

    // Calculate the end date based on the isTodayDateAllowed flag
    const endDate = getSetDateRangeEndDate(isTodayDateAllowed, timeZoneId);

    // Format the date range and return it
    return formatDateTimeToRange(startDate, endDate);
};
/**
 * convert
 * @returns
 */
export const getLast30DaysDateRange = (isTodayDateAllowed, timeZoneId) =>
{
    let currentDate = new Date();

    if (timeZoneId)
    {
        currentDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }
    const startDate = subDays(startOfDay(currentDate), 30);
    const endDate = getSetDateRangeEndDate(isTodayDateAllowed, timeZoneId);

    return formatDateTimeToRange(startDate, endDate);
};

export const getLast60DaysDateRange = (isTodayDateAllowed, timeZoneId) =>
{
    let currentDate = new Date();

    if (timeZoneId)
    {
        currentDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }
    const startDate = subDays(startOfDay(currentDate), 60);
    const endDate = getSetDateRangeEndDate(isTodayDateAllowed, timeZoneId);

    return formatDateTimeToRange(startDate, endDate);
};
/**
 * convert
 * @returns
 */
export const getLastYearDateRange = (isTodayDateAllowed, timeZoneId) =>
{
    let currentDate = new Date();

    if (timeZoneId)
    {
        currentDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }
    const startDate = subYears(startOfDay(currentDate), 1);
    const endDate = getSetDateRangeEndDate(isTodayDateAllowed, timeZoneId);

    return formatDateTimeToRange(startDate, endDate);
};

/**
 * Gets end date for the set date range intervals
 * returns either  current date
 * or
 * return end of yesterday date
 * @param {Boolean} isTodayDateAllowed
 * @returns
 */
export const getSetDateRangeEndDate = (isTodayDateAllowed = true, timeZoneId) =>
{
    let endDate = new Date();

    if (timeZoneId)
    {
        endDate = (getLatestJsDateBasedOnTimeZone(timeZoneId));
    }

    if (!isTodayDateAllowed)
    {
        endDate = endOfDay(subDays(endDate, 1));
    }

    return endDate;
};

/**
 * convert
 * @returns
 */
export const getRetentionDateRange = () =>
{
    const startDate = startOfWeek(subWeeks(new Date(), 12), {
        weekStartsOn: 1,
    });
    const endDate = mostRecentAvailableDate();

    return formatDateTimeToRange(startDate, endDate);
};

/**
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const formatDateTimeToRange = (startDate, endDate) => ({ startDate, endDate });

/**
 * convert
 * @param {*} param0
 * @returns
 */
export const dateRangeToString = ({ startDate, endDate }) =>
{
    if (!!startDate && !!endDate)
    {
        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
    else
    {
        return "";
    }
};

/**
 * convert
 * Default date format
 * @param {} date
 */
export const formatDate = (date) => i18Format(date, "PP");

/**
 * convert
 * Default time format
 * @param {*} date
 */
export const formatTime = (date) => i18Format(date, "p");

/**
 * convert
 * @param {*} date
 * @param {*} formatString
 * @returns
 */
export const i18Format = (date, formatString) => format(date, formatString, { locale: LOCALES[i18n.language] });

export const formatWithTimeZone = (date, timeZone) =>
{
    const formatString = "p";
    return formatInTimeZone(date, timeZone?.id, formatString, {
        locale: LOCALES[i18n.language],
    });
};

/**
 *  convert
 *  timezone formating for calendar events
 * @param {Date} unixTimeMs
 * @param {String} timeZoneId - timeZoneId
 */
export const formatLiveTimeCalendar = (unixTimeMs, timeZoneId) =>
{
    let timeZonedDate = utcToZonedTime(unixTimeMs, timeZoneId);

    return timeZonedDate;
};

/**
 * @deprecated use dateRangeToUTCStrings from date.luxon.utils
 * @param {*} param0
 * @param {*} timezone
 * @returns
 */
export const dateRangeToDateTime = (
    { startDate, endDate, considerTimeInDateRange },
    timezone
) =>
{

    if (!!startDate && !!endDate)
    {
        if (considerTimeInDateRange)
        {
            // we should be simply attaching timezone to the date so it can properly be turned into UTC Value

            if (endDate < startDate)
            {
                endDate = endOfDay(startDate);
            }
        }
        else
        {
            // set startDate hour to 0
            startDate = startOfDay(startDate);

            if (isToday(endDate))
            {
                // some issues with nav apis ("date is in the future")
                endDate = getUtcShiftedDate(new Date());
            }
            else
            {
                endDate = endOfDay(endDate);
            }
        }

        let diffDays = differenceInCalendarDays(endDate, startDate) + 1;

        const compareStartDate = subDays(startDate, diffDays);

        let startTimeUTC = formatDateTimeToUTCString(startDate);
        let endTimeUTC = formatDateTimeToUTCString(endDate);

        let compareStartTimeUTC = formatDateTimeToUTCString(compareStartDate);

        return {
            startTimeUTC,
            endTimeUTC,
            compareStartTimeUTC,
            compareEndTimeUTC: startTimeUTC,
        };
    }
    else
    {
        // console.error("dateRangeToDateTime: date range does not contain 2 dates in the format 'startDate - endDate'.");
        return {};
    }
};


/**
 * Returns a function that processes date ranges for boost tag heat maps using the provided data aggregation value.
 *
 * @param {type} dataAggregationValue - The data aggregation value used for processing.
 * @return {Function} A function that processes date ranges for boost tag heat maps.
 */
export const getBoostOrTagDateProcessorFn = (dataAggregationValue, propertyTimeZoneId) => (dateRange) => getAdjustedDateRangeForBoostHeatMaps(dateRange, dataAggregationValue, propertyTimeZoneId);


export const getTimeStepForBoostTags = (dataAggregationValue) =>
{
    if (dataAggregationValue === DATA_AGGREGATIONS.EVERY_HOUR)
    {
        return 60; // 60 mins
    }

    return 30;// 30 mins
};

/**
 * convert
 *  Returns the date range diffrence depending on the date group range provided
 * @param {Object} dateRange
 * @param {Date} dateRange.startDate
 * @param {Date} dateRange.endDate
 * @param {String} dateRange.dateGroupRange
 * @returns {Number}
 */
export const getDateRangeDiffrence = ({
    startDate,
    endDate,
    dateGroupRange = DATE_GROUP_RANGE.DAYS,
}) =>
{
    if (dateGroupRange === DATE_GROUP_RANGE.HOURS)
    {
        return differenceInHours(endDate, startDate);
    }
    else if (dateGroupRange === DATE_GROUP_RANGE.DAYS)
    {
        return differenceInCalendarDays(endDate, startDate);
    }
    else if (dateGroupRange === DATE_GROUP_RANGE.WEEKS)
    {
        return differenceInWeeks(endDate, startDate);
    }
    else if (dateGroupRange === DATE_GROUP_RANGE.MONTHS)
    {
        return differenceInMonths(endDate, startDate);
    }
};

/**
 *
 * @param {Array} objectList
 * @param {String} dateName - var name where the date is located in the objectList
 * @returns {Array} sortedDates
 */
export const getSortedDates = (objectList, dateName) =>
{
    let sortedDates = [];

    objectList.forEach((value) =>
    {
        sortedDates.push(new Date(value[dateName]));
    });

    sortedDates.sort(compareAsc);

    return sortedDates;
};

/**
 * convert
 * Returns an array of formatted dates
 * @param {*} objectList
 * @param {*} dateName
 * @param {*} dateFormat
 * @param {*} timeZone
 * @returns
 */
export const getFormattedDates = (
    objectList,
    dateName,
    dateFormat,
    timeZone
) =>
{
    let formatedDates = [];

    objectList.forEach((value) =>
    {
        let date = utcToZonedTime(value[dateName], timeZone?.id || "UTC");

        formatedDates.push(i18Format(date, dateFormat));
    });

    return formatedDates;
};

/**
 * convert
 * creates a formated date value under formattedDateName in the object list
 * @param {*} objectList
 * @param {*} dateName
 * @param {*} dateFormat
 * @param {*} timeZone
 * @returns void
 */
export const formatDatesInObjectList = (
    objectList,
    dateName,
    formatDateName,
    dateFormat,
    timeZone
) =>
{
    objectList.forEach((value) =>
    {
        let date = utcToZonedTime(value[dateName], timeZone?.id || "UTC");

        value[formatDateName] = i18Format(date, dateFormat);
    });
};

/**
 * turning our date array into a hashmap of date -> object
 * @param {*} objectList
 * @param {*} dateName
 * @param {*} dateFormat
 * @param {*} timeZone
 * @returns
 */
export const groupDataByDate = (objectList, dateName, dateFormat, timeZone) =>
{
    let groupedData = {};

    objectList.forEach((object) =>
    {
        let date;

        if (timeZone)
        {
            date = new Date(object[dateName]);

            date = utcToZonedTime(date, timeZone);
        }
        else
        {
            date = zonedTimeToUtc(
                object[dateName],
                Intl.DateTimeFormat().resolvedOptions().timeZone
            );
        }

        date = i18Format(date, dateFormat);

        if (!groupedData[date])
        {
            groupedData[date] = [];
        }
        groupedData[date].push(object);
    });

    return groupedData;
};

// convert
export const unixSecondsToTimeZoneDate = (unixTime_s, timeZone) =>
{
    const date = utcToZonedTime(
        new Date(unixTime_s * 1000),
        timeZone?.id || "UTC"
    );

    return date;
};

// convert
export const unixMilliSecondsToTimeZoneDate = (unixTime_ms, timeZone) =>
{
    const date = utcToZonedTime(new Date(unixTime_ms), timeZone?.id || "UTC");

    return date;
};
export const formatUnixTimeToYYYYMMDD = (unixTimestamp) =>
{
    const date = new Date(unixTimestamp);

    // Extract year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Formatted date in yyyy-mm-dd format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

/**
 * convert
 * creates a formated date value under formattedDateName in the object list
 * @param {*} objectList
 * @param {*} dateName
 * @param {*} dateFormat
 * @param {*} timeZone
 * @returns
 */
export const formatDatesInObjectList_UnixSeconds = (
    objectList,
    dateName,
    formatDateName,
    dateFormat,
    timeZone
) =>
{
    objectList.map((value) =>
    {
        let date = utcToZonedTime(
            new Date(value[dateName] * 1000),
            timeZone?.id || "UTC"
        );

        value[formatDateName] = i18Format(date, dateFormat);
    });
    return objectList;
};

/**
 * Convert
 * Sets date range to be the set range length provided
 * @param {*} dateRange
 * @param {*} rangeLength
 * @returns
 */
export const getSetSizeDateRange = (dateRange, rangeLength, timeZoneId) =>
{
    let { startDate, endDate } = dateRange;

    endDate = addDays(startDate, rangeLength - 1);

    let lastAvailableDate = mostRecentAvailableDate(timeZoneId);
    if (compareAsc(endDate, lastAvailableDate) === 1)
    {
        startDate = subDays(lastAvailableDate, rangeLength - 1);
        endDate = lastAvailableDate;
    }

    return { startDate, endDate };
};

/**
 *
 * @param {*} mins
 * @returns
 */
export const formatMinsToMMSS = (mins) =>
{
    let minutesFormatted = 0;
    let secondsFormatted = 0;

    // get minutes
    minutesFormatted = Math.floor(mins);

    // get seconds
    secondsFormatted = Math.round((mins % 1) * 60);

    secondsFormatted = secondsFormatted.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    return `${minutesFormatted}:${secondsFormatted}`;
};

/**
 * Formats mins to hh:mm:ss
 * @param {Number} mins
 * @returns
 */
export const formatMinsToHHMMSS = (mins) =>
{
    let hoursFormatted = 0;
    let minutesFormatted = 0;
    let secondsFormatted = 0;

    // get hours
    hoursFormatted = Math.floor(mins / 60);

    // get minutes
    let minutes = mins - hoursFormatted * 60;
    minutesFormatted = Math.floor(minutes);

    // get seconds
    secondsFormatted = Math.floor((minutes % 1) * 60);

    let formattedTimes = [
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
    ].map((myNumber) => myNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    }));

    return `${formattedTimes[0]}:${formattedTimes[1]}:${formattedTimes[2]}`;
};

/**
 * Formats mins to hh:mm
 * @param {Number} mins
 * @returns
 */
export const formatMinsToHHMM = (mins) =>
{
    let hoursFormatted = 0;
    let minutesFormatted = 0;

    // get hours
    hoursFormatted = Math.floor(mins / 60);

    // get minutes
    let minutes = mins - hoursFormatted * 60;
    minutesFormatted = Math.floor(minutes);

    let formattedTimes = [hoursFormatted, minutesFormatted].map((myNumber) => myNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    }));

    return `${formattedTimes[0]}:${formattedTimes[1]}`;
};

/**
 * this format date range always assumes compared date is previous no mater the order of the dates given
 * to show how much distance in the future use option  { addSuffix: true } in formatDistance function
 */
export const formatDateRangeDistance = (date1, date2 = new Date()) => `${formatDistance(date1, date2, {
    locale: LOCALES[i18n.language],
})} ago `;
export const UTC_FORMAT = "yyyy-MM-dd";
export const UTC_FORMAT_TIME = "HH:mm";
/**
 * @deprecated use formatDateTimeToUTCString from date.luxon.utils
 * @param {*} dateTime
 * @returns
 */
export const formatDateTimeToUTCString = (dateTime) => `${format(dateTime, UTC_FORMAT)} ${format(
    dateTime,
    UTC_FORMAT_TIME
)}Z`;

/**
 * @deprecated use dateRangeToUTCStrings from date.luxon.utils
 * @param {*} dateRange
 * @returns
 */
export const formatDateRageToUTCDateRange = (dateRange) =>
{
    const { startDate, endDate } = dateRange;
    let startTimeUTC = formatDateTimeToUTCString(startDate);
    let endTimeUTC = formatDateTimeToUTCString(endDate);

    return { startTimeUTC, endTimeUTC };
};

/**
 * convert
 * Hours [1-24]  - 24, 1, 2, ..., 23
 * @param {*} dateTime
 * @returns
 */
export const getHourOfDate = (dateTime) => format(dateTime, "H");

/**
 * Convert
 * @param {*} dateTime
 * @returns
 */
export const getUtcShiftedDate = (dateTime) => new Date(dateTime.getTime() + dateTime.getTimezoneOffset() * 60000);

/**
 * Generates rounded intervals based on the provided date range.
 *
 * @param {object} startDate - the start date of the range
 * @param {object} endDate - the end date of the range
 * @return {object} an object containing the rounded interval start and end hours
 */
export const getRoundedIntervalsFromDateRange = ({ startDate, endDate }) =>
{
    let IntervalStartUTCHour = 0 + +getHourOfDate(startDate);
    let IntervalEndUTCHour = 0 + +getHourOfDate(endDate);

    if (differenceInMinutes(endOfDay(startDate), endDate) <= 5)
    {
        IntervalEndUTCHour = 24;
    }

    if (startOfDay(startDate) - startOfDay(endDate) === 0)
    {
        const startHour = IntervalStartUTCHour;
        const endHour = IntervalEndUTCHour;
        // if (differenceInMinutes(endDate, startOfHour(endDate)) > 30) {
        //     IntervalStartUTCHour = startHour - 1
        // }
        if (differenceInMinutes(endDate, startOfHour(endDate)) >= 30)
        {
            if (endHour < 22)
            {
                IntervalEndUTCHour = 1 + endHour;
            }
        }
        if (differenceInMinutes(startDate, startOfHour(startDate)) > 30)
        {
            if (startHour < 22)
            {
                IntervalStartUTCHour = 1 + startHour;
            }
        }

        if (startHour === 23 && endHour === 23)
        {
            if (IntervalEndUTCHour < 24)
            {
                IntervalEndUTCHour = 24;
            }
        }
    }

    return {
        IntervalStartUTCHour: "" + IntervalStartUTCHour,
        IntervalEndUTCHour: "" + IntervalEndUTCHour,
    };
};
/**
 *
 * @param {*} mins
 * @returns
 */

export const convertMinsTo_HHMMSS = (mins) =>
{
    let correctedMins = mins;
    if (isNaN(0 + +mins))
    {
        correctedMins = 0;
    }

    // Prev format method from date-fns was incorrectly formatting time with more then 24hours
    const totalSeconds = Math.round(correctedMins * 60);

    // Find total hours, minutes, and seconds
    const totalHours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Creating final string with "hh:mm:ss" fromat regardless minutes exceeding days
    return `${String(totalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const convertSecondsTo_MMSS = (seconds) =>
{
    let timeSpent;

    let timeSpent_m = Math.floor(seconds / 60);
    let timeSpent_s = seconds - timeSpent_m * 60;

    if (timeSpent_m > 0)
    {
        timeSpent = `${timeSpent_m} mins ${Math.floor(timeSpent_s)} sec`;
    }
    else
    {
        timeSpent = `00 mins ${Math.floor(timeSpent_s)} sec`;
    }

    return timeSpent;
};


export const getDateSyncedWithTimezone = (date, timeZoneId) => convertDateTimeToLocalJSDateWithoutChangingTime(createDateTimeZoneDateFromJSDate(date), timeZoneId);
/**
 * Resynchronizes the date range on the active time zone.
 *
 * @param {Object} dateRange - The date range to be resynchronized.
 * @param {string} [activeTimeZoneId="UTC"] - The ID of the active time zone.
 * @param {string} [previousActiveTimeZoneId="UTC"] - The ID of the previous active time zone.
 * @param {boolean} [isTodayDateAllowed] - Indicates if today's date is allowed.
 * @return {Object} The resynchronized date range.
 */
export const resyncDateRangeOnActiveTimeZone = (dateRange, activeTimeZoneId = "UTC", previousActiveTimeZoneId = "UTC", isTodayDateAllowed) =>
{
    // Convert start and end dates to local dates based on the previous time zone.
    const { startDate, endDate } = Object.entries(dateRange).reduce((acc, [key, value]) => ({ ...acc, [key]: new Date(value) }), {});

    // Adjust the end date to the maximum possible date based on the active time zone.
    const endDateTime = createDateTimeZoneDateFromJSDate(endDate, previousActiveTimeZoneId); // End date in the active time zone.
    const isEndDateIsToday = isDateTimeToday(endDateTime, previousActiveTimeZoneId); // Check if the end date is today.
    const latestDateInActiveTimeZone = getLatestJsDateBasedOnTimeZone(activeTimeZoneId); // Latest date in the active time zone.
    const diffInCDays = differenceInCalendarDays(endDate, startDate); // Number of days between the start and end dates.

    // If the end date is ahead of the latest date in the active time zone, adjust the start date accordingly.
    if (isTodayDateAllowed && (isEndDateIsToday || endDate > latestDateInActiveTimeZone))
    {
        // If the range spans across different days, adjust the start date to maintain the same number of days.
        if (diffInCDays > 0)
        {
            // Set the end date to the latest date in the active time zone.
            const newEndDate = latestDateInActiveTimeZone;
            // Adjust the start date to maintain the same number of days.
            let startDateAdjusted = subMilliseconds(newEndDate, differenceInMilliseconds(endDate, startDate) - 1);

            startDateAdjusted = startOfDay(startDateAdjusted);

            return { // Return the resynchronized date range.
                ...dateRange,
                endDate: newEndDate,
                startDate: startDateAdjusted,
            };
        }
        // If the range is on the same day, set the end date to the latest date in the active time zone.
        else
        {
            let startDateAdjusted = subMilliseconds(latestDateInActiveTimeZone, differenceInMilliseconds(endDate, startDate) - 1);

            let diffCal = differenceInCalendarDays(latestDateInActiveTimeZone, startDateAdjusted);

            // Don't allow moving any other date - maintain start and end date as same
            startDateAdjusted = diffCal === 0 ? startOfDay(startDateAdjusted) : startOfDay(latestDateInActiveTimeZone);

            return { // Return the resynchronized date range.
                ...dateRange,
                endDate: latestDateInActiveTimeZone,
                startDate: startDateAdjusted,
            };
        }
    }
    else
    {
        // Yesterday's date in the active time zone.
        const yesterdayDateOfActiveTimeZone = subMilliseconds(startOfDay(latestDateInActiveTimeZone), 1);
        // Adjust the end date to the active time zone.
        let newEndDate = convertDateTimeToLocalJSDateWithoutChangingTime(endDateTime.setZone(activeTimeZoneId));
        // Set the end date to the end of the day.
        newEndDate = endOfDay(newEndDate);
        // If the end date is ahead of yesterday's date, set it to yesterday's date.
        newEndDate = newEndDate < yesterdayDateOfActiveTimeZone ? newEndDate : yesterdayDateOfActiveTimeZone;
        if (diffInCDays > 0)
        {
            // Adjust the start date to maintain the same number of days.
            let startDateAdjusted = subMilliseconds(newEndDate, differenceInMilliseconds(endDate, startDate) - 1);

            startDateAdjusted = startOfDay(startDateAdjusted);

            return {
                ...dateRange,
                endDate: newEndDate,
                startDate: startDateAdjusted,
            };
        }
        else
        {
            let startDateAdjusted = subMilliseconds(newEndDate, differenceInMilliseconds(endDate, startDate) - 1);

            let diffCal = differenceInCalendarDays(newEndDate, startDateAdjusted);

            // Don't allow moving any other date - maintain start and end date as same
            startDateAdjusted = diffCal === 0 ? startOfDay(startDateAdjusted) : startOfDay(newEndDate);
            return {
                ...dateRange,
                endDate: newEndDate,
                startDate: startDateAdjusted,
            };
        }
    }


};

/**
 * Returns the active month date range in UTC format.
 *
 * @param {string} timeZoneId - The ID of the active time zone. If not provided, local time zone is considered.
 * @param {boolean} [isTodayDateAllowed=true] - Flag indicating if today's date is allowed in the range.
 * @return {object} The active month date range in UTC format. The object has the following properties: startDateUTC, endDateUTC, considerTimeInDateRange.
 * @note If timeZoneId is not provided, local time zone is considered.
 */
export const getDateRangeForAlerts = (timeZoneId, isTodayDateAllowed = true) =>
{
    const latestDate = getLatestJsDateBasedOnTimeZone(timeZoneId);
    const startDate = getAlertsDateRange(isTodayDateAllowed, timeZoneId).start;

    // Format the start and end dates to UTC strings.
    return {
        startTimeUTC: luxonFormatDateTimeToUTCString(createDateTimeZoneDateFromJSDate(startDate, timeZoneId)),
        endTimeUTC: luxonFormatDateTimeToUTCString(createDateTimeZoneDateFromJSDate(latestDate, timeZoneId)),
    };
};


/**
 * Returns the latest available date in the specified time zone as a UTC string.
 *
 * @param {string} timeZoneId - The ID of the time zone. Default is the local time zone.
 * @param {boolean} [isTodayDateAllowed=true] - Indicates whether today's date is allowed in the range. Default is true.
 * @returns {string} The latest available date in the specified time zone as a UTC string.
 *
 * @description
 * This function calculates the latest available date based on the specified time zone and whether today's date is allowed.
 * It uses the `mostRecentAvailableDate` function to get the latest available date, and then formats it as a UTC string using the `luxonFormatDateTimeToUTCString` function.
 */
export const getLatestDateOfTimeZoneInUTC = (timeZoneId, isTodayDateAllowed = true) =>
{
    /**
     * Calculates the latest available date in the specified time zone.
     *
     * @param {string} timeZoneId - The ID of the time zone.
     * @param {boolean} isTodayDateAllowed - Indicates whether today's date is allowed in the range.
     * @returns {Date} The latest available date as a JavaScript Date object.
     */
    const calculateLatestDate = (timeZoneId, isTodayDateAllowed) => mostRecentAvailableDate(timeZoneId, isTodayDateAllowed);

    /**
     * Formats the specified date as a UTC string.
     *
     * @param {Date} date - The date to format.
     * @returns {string} The formatted date as a UTC string.
     */
    const formatDateAsUTCString = (date) => luxonFormatDateTimeToUTCString(createDateTimeZoneDateFromJSDate(date, timeZoneId));

    // Calculate the latest available date and format it as a UTC string.
    return formatDateAsUTCString(calculateLatestDate(timeZoneId, isTodayDateAllowed));
};
