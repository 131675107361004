import React, { useEffect, useMemo } from "react";
import WidgetView from "../Widget.view";
import useZoneTraffic from "./useZoneTraffic";
import ZoneTrafficErrorView from "./ZoneTrafficError.view";
import ZoneTrafficInfoText from "./ZoneTrafficInfo.text";
import ZoneLoadingView from "./ZoneTrafficLoading.view";
import ZoneTrafficTableView from "./ZoneTrafficTable.view";
import { useTranslation } from "react-i18next";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { getWidgetTitle } from "../../../_utils/utils";
import { convertMinsTo_HHMMSS } from "../../../_utils/date.utils";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const ZoneTrafficWidgetContainer = ({ injectProcessedData }) =>
{
    const zoneTraffic = useZoneTraffic();
    const { t } = useTranslation();
    const connectedPageName = useRecoilValue(connectedPageNameState);
    const { data } = zoneTraffic;

    const processedExportData = useMemo(() => {
        if (!data?.zoneTrafficArray?.length) 
        {
            return [];
        }
        return data.zoneTrafficArray.flatMap(({ userCount, averageDwellMinutes, zoneLabel, chartData }) => {
            
            let PeakHour = [];
            let PeakHourMaxVisits = 0;
            chartData.forEach((peakHour, indx) => {
                if(peakHour.maxUsers){
                    // PeakHourMaxVisits.push(peakHour.maxUsers);
                    if(peakHour.maxUsers > PeakHourMaxVisits)
                    {
                        PeakHourMaxVisits = peakHour.maxUsers;
                    }
                    PeakHour.push(peakHour.time);
                }
            });
            return {
                Zone: zoneLabel,
                Visits: userCount,
                AvgDwellTimeMinutes: averageDwellMinutes,
                AvgDwellTimeFormatted: convertMinsTo_HHMMSS(averageDwellMinutes),
                PeakHour: PeakHour.join(","),
                // "TimeZoneId": peakHour.timeZoneId,
                // "PeakHourVisits": peakHour.users,
                PeakHourMaxVisits: PeakHourMaxVisits || "",
            }

        });
    }, [data, connectedPageName]);
    

    useEffect(() =>
    {
        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.ZoneTrafficWidget),
            data: processedExportData,
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [processedExportData]);

    return (
        <WidgetView
            {...zoneTraffic}
            LoadingComponent={ZoneLoadingView}
            ErrorComponent={() => <ZoneTrafficErrorView onReloadClick={zoneTraffic.onError} />}
            heading={zoneTraffic.trans("ZoneTrafficWidget.What_are_the_most_popular_peak_times_for")}
        >
            <div>
                <ZoneTrafficInfoText {...zoneTraffic} />
                <ZoneTrafficTableView {...zoneTraffic} />
            </div>
        </WidgetView>
    );
};

export default ZoneTrafficWidgetContainer;
