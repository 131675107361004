import React, { useEffect } from "react";
import WidgetView from "../Widget.view";
import ScreenResolutionBarChart from "./ScreenResolutionBar.chart";
import useScreenResolution from "./useScreenResolution";
import { getWidgetTitle } from "../../../_utils/utils";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { useTranslation } from "react-i18next";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const ScreenResolutionContainer = ({ injectProcessedData }) =>
{
    const screenResWidget = useScreenResolution();
    const { data } = screenResWidget;
    const { t } = useTranslation();
    const connectedPageName = useRecoilValue(connectedPageNameState);

    useEffect(() =>
    {
        const modifiedData = data ? data?.resolutionBarData.map(({ resolution, users }) => ({ Resolution: resolution, Users: users })) : [];
        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.ScreenResolutionWidget),
            data: modifiedData,
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [data, connectedPageName]);

    return (
        <WidgetView
            {...screenResWidget}
            heading={screenResWidget.trans("ScreenResolutionWidget.What_is_the_most_common_screen_resolutio")}
        >
            <ScreenResolutionBarChart {...screenResWidget} />
        </WidgetView>
    );
};

export default ScreenResolutionContainer;
