import React, { memo, useCallback, useMemo } from "react";
import { convertMinsTo_HHMMSS } from "../../../_utils/date.utils";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import ZoneTrafficBarChart from "./ZoneTrafficBar.chart";

export const RenderZoneTrafficColItemView = memo(({ content, tooltip }) =>
{
    return (
        <div className={ `peakHoursItem ${ tooltip ? "tooltip" : "" }` }>
            <div className="peakHoursColumn">{ content }</div>

            { tooltip && <div className="peekHoursColumnToolTipText"> { content } </div> }
        </div>
    );
});

export const ZoneTrafficRowWrapper = ({ clsName = "", children, onClick = undefined }) =>
{
    return <div onClick={ onClick } className={ `peakHoursRow ${ clsName }` } >
        { children }
    </div>;
};

export const ZoneTableWrapper = ({ clsName = "", children }) =>
{
    return <div className={ `peakHoursTable ${ clsName }` }>{ children }</div>;
};
function ZoneTrafficTableView({ data, trans })
{
    const { zoneTrafficArray } = data;

    const RenderZoneTrafficTableRowView = useCallback(
        ({ zoneLabel, userCount, averageDwellMinutes, chartData, trans }) =>
        {
            return (
                <ZoneTrafficRowWrapper>
                    <RenderZoneTrafficColItemView content={ zoneLabel } tooltip />
                    <RenderZoneTrafficColItemView content={ userCount } />
                    <RenderZoneTrafficColItemView
                        content={ convertMinsTo_HHMMSS(averageDwellMinutes) }
                    />
                    <RenderZoneTrafficColItemView
                        content={ <ZoneTrafficBarChart chartData={ chartData } trans={ trans } /> }
                    />
                </ZoneTrafficRowWrapper>
            );
        },
        []
    );

    const renderZoneTrafficTableRows = useMemo(() =>
    {
        let threshold = 7;
        let renderZoneTrafficTableRows = [];
        let limit = (zoneTrafficArray.length > threshold)
            ? threshold
            : zoneTrafficArray.length;
        for (let i = 0; i < limit; i++)
        {
            renderZoneTrafficTableRows.push(
                <RenderZoneTrafficTableRowView
                    key={ zoneTrafficArray[i].zoneLabel }
                    { ...zoneTrafficArray[i] }
                    trans={ trans }
                />
            );
        }

        return renderZoneTrafficTableRows;
    }, [trans, zoneTrafficArray]);

    const RenderZoneTrafficTableHeaderView = ({ trans }) =>
    {
        return (
            <div className="peakHoursRow">
                <RenderZoneTrafficColItemView content={ trans("ZoneTrafficWidget.ZONE") } />
                <RenderZoneTrafficColItemView content={ trans("ZoneTrafficWidget.VISITS") } />
                <RenderZoneTrafficColItemView content={ trans("ZoneTrafficWidget.AVG__DWELL_TIME") } />
                <RenderZoneTrafficColItemView content={ trans("ZoneTrafficWidget.PEAK_HOUR") } />
            </div>
        );
    };

    if (renderZoneTrafficTableRows.length === 0)
    {
        return <NoDataWidgetMessage />;
    }

    return (
        <ZoneTableWrapper>
            <RenderZoneTrafficTableHeaderView trans={ trans } />
            { renderZoneTrafficTableRows }
        </ZoneTableWrapper>
    );
}

export default ZoneTrafficTableView;
