import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSearch } from '../../../_api/queries';
import { processSearchDataForTopSearches } from '../../../_utils/chart.utils';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const useWebEngagementTop20SearchesWidget = () =>
{
	const searchesQuery = useWebAnalyticsSearch();

	const processedWidgetData = useMemo(() =>
	{
		const { isSuccess, data: queryData } = searchesQuery;


		let processedData = { chartData: [], headMaxSearchNames: "", tailMaxSearchNames: "", allSortedSearchItems: [] };

		// process the data
		if (!!isSuccess && queryData)
		{
			const { currentIntervalData: data } = queryData || {};

			processedData = processSearchDataForTopSearches(data, 20);


		}


		return processedData;
	}, [searchesQuery
	]);


	return {
		searchesQuery,
		processedWidgetData,
	};
};

export default useWebEngagementTop20SearchesWidget;