import { atom } from "recoil";

export const liveLoadingState = atom({
    key: "liveLoadingState",
    default: true,
});

export const socketConnectedState = atom({
    key: "socketConnectedState",
    default: false,
});

export const liveDataTimeStampState = atom({
    key: "liveDataTimeStampState",
    default: undefined,
});

export const usersObjectState = atom({
    key: "usersObjectState",
    default: undefined,
});