import React, { useEffect } from "react";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { DASHBOARD_WIDGETS_INFO_TEXT } from "../../_constants/exportToCsvConstants";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const AverageMonthlyVisitorsNumberWidget = ({ processedWidgetData, trans, injectProcessedData }) =>
{
    const { isSuccess, data } = processedWidgetData;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    useEffect(() =>
    {
        const payload = {
            name: "Monthly Active Users",
            data: [{
                "MonthlyActiveUsers": data?.averageMonthlyUsers
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);
    }, [data?.averageMonthlyUsers, connectedPageName]);

    if (!!isSuccess && data !== undefined)
    {
        return (
            <OverviewDetailBox
                max="220px"
                popupContent={trans("ReportsOverviewDetail.Average_monthly_visitors_for_the_selecte")}
                icon="active-users"
                content={data?.averageMonthlyUsers || 0}
                slogan={trans("ReportsOverviewDetail.Monthly_Active_Users")}
            />
        );
    }
    return <OverviewDetailBoxLoading />;
};

export { AverageMonthlyVisitorsNumberWidget };
