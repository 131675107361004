import { DEFAULT_ZONE_COLOR_WITH_TEMPLATE } from "../_constants/mapConstants";




/**
 * Creates a list of diffrent colors of size length using HSL.
 * @param {*} length
 * @returns
 */
export const createListOfColors = (length) =>
{
    let colorList = [];

    const maxH = 320;
    let interval = Math.floor(maxH / (length - 1));

    for (let i = 0; i < length; i++)
    {
        colorList.push(`hsla(${interval * i}, 70%, 50%, 1)`);
    }

    return colorList;
};

/**
 * Creates a list of diffrent colors of size length using HSL.
 * @param {*} length
 * @returns
 */
export const createMapOfZonegeofenceToRandomColors = (zoneGeofenceList) =>
{
    let length = zoneGeofenceList.length;
    let zoneGeofenceToColorsMap = {};

    const colorList = createListOfColors(length);

    zoneGeofenceList.forEach((zoneGeofence, i) =>
    {
        const { label } = zoneGeofence;
        zoneGeofenceToColorsMap[label] = colorList[i];
    });

    return zoneGeofenceToColorsMap;
};



/**
 * Converts a hex color to an HSLA color.
 *
 * @param {string} hex - The hex color to convert.
 * @param {number} alpha - The alpha value for the HSLA color.
 * @return {string} The HSLA color.
 */
export function hexToHsla(hex, alpha)
{
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse the hex color into its RGB components
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    // Find the maximum and minimum values of R, G and B
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    // Calculate lightness
    let l = (max + min) / 2;

    let h, s;

    // If the color is achromatic (gray), set H and S to 0
    if (max === min)
    {
        h = s = 0;
    }
    else
    {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        // Calculate Hue
        switch (max)
        {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    // Return the HSLA color
    return `hsla(${h}, ${s}%, ${l}%, ${alpha})`;
}


export const createStyleWithColor = (color) =>
{
    // console.log(color);
    let fillColor = color.replace("1)", ("0.1)"));
    return {
        stroke: {
            color: color,
            width: 1
        },
        fill: {
            color: fillColor,
        },
        color: color,
    };
};

/**
 * Creates a list of diffrent colors of size length using HSL.
 * @param {Array} levelList
 * @returns
 */
export const createMapOfLevelToColors = (levelList) =>
{
    let length = levelList.length;
    let levelColorMap = {};

    const colorList = createListOfColors(length);

    levelList.forEach((levelData, i) =>
    {
        const { floorId } = levelData;
        levelColorMap[floorId] = colorList[i];
    });

    return levelColorMap;
};


/**
 * Creates a map of zone geofence labels to color and template styles for each zone geofence.
 * The color for each zone geofence is the same (DEFAULT_ZONE_COLOR_WITH_TEMPLATE) and the template is null.
 *
 * @param {Array} zoneGeofenceList - The list of zone geofences to generate styles for.
 * @return {Object} The mapping of zone geofence labels to color and template styles.
 *                  Example:
 *                  {
 *                      "zoneGeofenceLabel1": {
 *                          color: "hslaColor",
 *                          template: null
 *                      },
 *                      "zoneGeofenceLabel2": {
 *                          color: "hslaColor",
 *                          template: null
 *                      },
 *                      ...
 *                  }
 */
export const createMapOfZoneGeofenceLabelsToDefaultColorAndNullTemplate = (zoneGeofenceList) =>
{
    const color = DEFAULT_ZONE_COLOR_WITH_TEMPLATE; //blue  color

    let zoneGeofenceToColorsMap = {};

    zoneGeofenceList.forEach((zoneGeofence) =>
    {
        const { label } = zoneGeofence;
        zoneGeofenceToColorsMap[label] = {
            color,
            template: null,
        };
    });

    return zoneGeofenceToColorsMap;
};
