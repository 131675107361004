import React, { useCallback, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Input, Radio, Header } from "semantic-ui-react";
import { filterState, filterValuesState, heatmapFilterState, heatmapTypeState } from "../../../store/DashboardAtoms";
import { FILTER_OPTIONS, HEATMAP_FILTER_OPTIONS } from "../../../_constants/filterConstants";
import { validateWidgetFilter } from "../../../_utils/validation.utils";
import { CheckboxGroup } from "../../common/checkboxGroup/CheckboxGroup";
import { ErrorLabel } from "../../common/errorLabel/ErrorLabel";

// Heatmap filters
export const AccordionFilterSidebar = ({ showHeatmapType, heatMapSidebar, scrollSize }) =>
{
    const trans = useTranslation().t;
    const [filter, setFilter] = useRecoilState(filterState);
    const [heatmapFilter, setHeatmapFilter] = useRecoilState(heatmapFilterState);
    const [heatmapType, setHeatmapType] = useRecoilState(heatmapTypeState);

    const [localFilter, setLocalFilter] = useState(filter);
    const [localHeatmapType, setLocalHeatmapType] = useState(heatmapType);
    const [localHeatmapFilter, setLocalHeatmapFilter] = useState(heatmapFilter); // for heatmap specific filters 
    const [isLocalChanges, setLocalChanges] = useState(false);
    const [filterErrors, setFilterErrors] = useState(undefined);
    const filterValues = useRecoilValue(filterValuesState);

    const handleSaveLocalFilter = useCallback(() =>
    {
        let filterValidation = validateWidgetFilter(localFilter);

        if (!filterValidation)
        {
            setFilter(localFilter);
            setHeatmapFilter(localHeatmapFilter);
            setHeatmapType(localHeatmapType);
            setLocalChanges(false);
        }

        setFilterErrors(filterValidation);
    },
        [localFilter, localHeatmapFilter, localHeatmapType, setHeatmapFilter, setFilter, setHeatmapType, setFilterErrors, setLocalChanges]);

    const handleChangeLocalFilter = useCallback((e, { value, name }) =>
    {
        let newLocalFilter = { ...localFilter };

        newLocalFilter[name] = value;

        setLocalFilter(newLocalFilter);
        setLocalChanges(true);

        if (!!filterErrors)
        {
            setFilterErrors(validateWidgetFilter(localFilter));
        }

    },// eslint-disable-next-line react-hooks/exhaustive-deps
        [localFilter, setLocalFilter, setLocalChanges]);


    const handleChangeLocalHeatmapFilter = useCallback((e, { value, name }) =>
    {
        let newLocalHeatmapFilter = { ...localHeatmapFilter };

        newLocalHeatmapFilter[name] = value;

        setLocalHeatmapFilter(newLocalHeatmapFilter);

        setLocalChanges(true);

    }, [localHeatmapFilter, setLocalHeatmapFilter, setLocalChanges]);

    const handleChangeLocalHeatmapType = useCallback((e, { value }) =>
    {
        setLocalHeatmapType(value);
        setLocalChanges(true);

    }, [setLocalHeatmapType, setLocalChanges]);


    // RENDER FUNCTIONS

    const renderDeviceTypeOptions = useCallback(() =>
    {
        return FILTER_OPTIONS.PLATFORM.map((option) =>
        {
            return (
                <Radio
                    className="checkboxGroup"
                    key={option.key}
                    label={trans(`AccordionFilterSidebar.${option.text}`)}
                    name="platform"
                    value={option.value}
                    onChange={handleChangeLocalFilter}
                    checked={localFilter["platform"] === option.value}
                />
            )
        })
    }, [localFilter, handleChangeLocalFilter, trans]);

    const renderDwellPassbyOptions = useCallback(() =>
    {
        return HEATMAP_FILTER_OPTIONS.DWELL_VS_PASSBY.map((option) =>
        {
            return (
                <Radio
                    className="checkboxGroup"
                    key={option.key}
                    label={trans(`AccordionFilterSidebar.${option.text}`)}
                    name="dwellVsPassby"
                    value={option.value}
                    onChange={handleChangeLocalHeatmapFilter}
                    checked={localHeatmapFilter["dwellVsPassby"] === option.value}
                />
            )
        });
    }, [localHeatmapFilter, handleChangeLocalHeatmapFilter, trans]);

    const renderTypeOptions = useCallback(() =>
    {
        return HEATMAP_FILTER_OPTIONS.TYPE.map((option) =>
        {
            return (
                <Radio
                    className="checkboxGroup"
                    key={option.key}
                    label={trans(`HeatmapFilter.${option.text}`)}
                    name="type"
                    value={option.value}
                    onChange={handleChangeLocalHeatmapType}
                    checked={localHeatmapType === option.value}
                />
            )
        });
    }, [localHeatmapType, handleChangeLocalHeatmapType, trans]);

    const renderCheckBoxInputs = useCallback(({ minName, maxName, postText }) =>
    {
        const maxVal = localFilter[maxName];
        const minVal = localFilter[minName];

        // const isEqualTo = (maxVal === minVal);

        const handleValueChange = (e, { value, name }) =>
        {
            let numValue = Number(value);

            if (numValue < 0)
            {
                numValue = 0
            }

            handleChangeLocalFilter(e, { value: numValue, name });
        }

        return (
            <>
                <CheckWithInputItem preText={trans("AccordionFilterSidebar.A_minimum_of")} postText={postText} name={minName} onChange={handleValueChange} isChecked={minVal !== 0} value={minVal} />
                <CheckWithInputItem preText={trans("AccordionFilterSidebar.A_maximum_of")} postText={postText} name={maxName} onChange={handleValueChange} isChecked={maxVal !== 0} value={maxVal} />
            </>
        )


    }, [localFilter, handleChangeLocalFilter, trans]);

    const renderServiceProviders = useCallback(() =>
    {
        // for each service provider display checkbox
        // check heatmapFilter to see which providers are selected
        // on add -> add service provider to list
        // REMEMBER -> heatmapFilterSelector processes the filter to make it api friendly

        let { service_providers } = localHeatmapFilter;

        let serviceProviderOptions = filterValues.serviceProviders || [];

        // on check box click handler
        const handleOnSPLocalCheckboxClick = (e, { name, checked }) =>
        {
            let newServiceProviders = JSON.parse(JSON.stringify(service_providers));
            if (checked)
            {
                newServiceProviders.push(name);
            }
            else
            {
                let idxOfName = newServiceProviders.indexOf(name);

                newServiceProviders.splice(idxOfName, 1);
            }

            handleChangeLocalHeatmapFilter(e, { value: newServiceProviders, name: "service_providers" });
        }

        // map service provider options to list of checkboxes
        let render = serviceProviderOptions.map((serviceProvider) =>
        {
            return (
                <CheckboxGroup
                    key={serviceProvider}
                    checked={service_providers.includes(serviceProvider)}
                    name={serviceProvider}
                    label={serviceProvider}
                    onChange={handleOnSPLocalCheckboxClick} />
            )
        });

        return render;

    }, [filterValues, localHeatmapFilter, handleChangeLocalHeatmapFilter]);

    const renderScreenResolutions = useCallback(() =>
    {
        // get processed filter from filter values (mapping)
        // display checkbox list
        // on click add to localHeatmapFilter
        // REMEMBER -> heatmapFilterSelector processes the filter to make it api friendly

        const { screen_resolutions } = localHeatmapFilter;

        let screenResolutionOptions = Object.keys(filterValues.screenResolutions || {});

        // on check box click handler
        const handleOnSRLocalCheckboxClick = (e, { name, checked }) =>
        {
            let newScreenResolutions = JSON.parse(JSON.stringify(screen_resolutions));
            if (checked)
            {
                newScreenResolutions.push(name);
            }
            else
            {
                let idxOfName = newScreenResolutions.indexOf(name);

                newScreenResolutions.splice(idxOfName, 1);
            }

            handleChangeLocalHeatmapFilter(e, { value: newScreenResolutions, name: "screen_resolutions" });
        }

        let render = screenResolutionOptions.map((screenResolution) =>
        {
            return (
                <CheckboxGroup
                    key={screenResolution}
                    checked={screen_resolutions.includes(screenResolution)}
                    name={screenResolution}
                    label={screenResolution}
                    onChange={handleOnSRLocalCheckboxClick} />
            )
        });

        return render;

    }, [filterValues, localHeatmapFilter, handleChangeLocalHeatmapFilter]);

    return (
        <div className={`filterSidebarFit${heatMapSidebar ? " filterSidebarHeatmap" : ""}`}>
            <div className="filterAligner">
                <Header className="headerFilter" content={trans("HeatmapFilter.Advanced_Filter_Options")} />
            </div>

            <div className="filterAlignerBottom">
                <Header className="headerFilter">
                    <Button disabled={!isLocalChanges} className="heatmapFilterSaveButton" primary floated="right" content={trans("AccordionFilterSidebar.Save")} onClick={handleSaveLocalFilter} />
                </Header>
            </div>


            <Scrollbars className="filterBarScroll" autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`${scrollSize || "calc(100vh - 215px)"}`}>

                <div className="filterAligner extraPaddingBottom">

                    {/* DEVICE TYPE OPTIONS */}
                    <AccordionSection
                        title={trans("AccordionFilterSidebar.Device_Type")}>
                        {/* RADIO BUTTONS ANDROID IOS ALL */}
                        {
                            renderDeviceTypeOptions()
                        }
                    </AccordionSection>

                    {/* DWELL VS PASS BY OPTIONS */}
                    <AccordionSection
                        title={trans("AccordionFilterSidebar.Dwell_vs_Pass-by")}>
                        {renderDwellPassbyOptions()}
                    </AccordionSection>

                    {/* HEATMAP TYPE OPTIONS */}
                    {
                        (showHeatmapType) && (
                            <AccordionSection
                                title={trans("HeatmapFilter.Heatmap_Type_")}>
                                {renderTypeOptions()}
                            </AccordionSection>

                        )
                    }

                    {/* SESSION TIME OPTIONS */}
                    <AccordionSection
                        title={trans("AccordionFilterSidebar.App_Session_Time")}>
                        {renderCheckBoxInputs({ minName: "sessionLengthMin", maxName: "sessionLengthMax", postText: trans("AccordionFilterSidebar.min") })}
                        {
                            (filterErrors?.sessionLengthMin) && (
                                <ErrorLabel content={trans("AccordionFilterSidebar.Minimum_session_length_must_be_less_than")} />
                            )
                        }
                    </AccordionSection>

                    {/* NUMBER OF SESSIONS OPTIONS */}
                    <AccordionSection
                        title={trans("AccordionFilterSidebar.Number_of_Visits")}>
                        {renderCheckBoxInputs({ minName: "numOfSessionsMin", maxName: "numOfSessionsMax", postText: trans("AccordionFilterSidebar.visit") })}
                        {
                            (filterErrors?.numOfSessionsMin) && (
                                <ErrorLabel content={trans("AccordionFilterSidebar.Minimum_number_of_sessions_must_be_less_")} />
                            )
                        }
                    </AccordionSection>

                    {/* SERVICE PROVIDER OPTIONS */}
                    {
                        (Array.isArray(filterValues?.serviceProviders) && filterValues.serviceProviders.length > 0) && (
                            <AccordionSection
                                title={trans("AccordionFilterSidebar.Device_Service_Providers")}>
                                {renderServiceProviders()}
                            </AccordionSection>
                        )
                    }

                    {/* SCREEN RESOLUTION OPTIONS */}
                    {
                        (Array.isArray(filterValues?.screenResolutions) && filterValues.screenResolutions.length > 0) && (

                            <AccordionSection
                                title={trans("AccordionFilterSidebar.Device_Screen_Resolution")}>
                                {renderScreenResolutions()}
                            </AccordionSection>
                        )
                    }

                </div>
            </Scrollbars>

        </div>
    );
}

const AccordionSection = ({ title, children }) =>
{
    return (
        <>
            <div className="filterSection">
                <h6 className="h6">{title}</h6>
                {children}
                {/* <Button primary floated="right" content={trans("AccordionFilterSidebar.Save")} onClick={onSave} /> */}
            </div>
        </>
    );
}

const CheckWithInputItem = ({ preText, postText, isChecked, name, value, onChange }) =>
{
    return (
        <div className="radioFilterCheck">
            <CheckboxGroup checked={isChecked} label={preText} />
            {/* <Label basic>{preText}</Label> */}
            <Input labelPosition="right" type="number" placeholder="0" name={name} value={value} onChange={onChange} label={postText} />
            {/* <Label>{postText}</Label> */}
        </div >
    );
}