export const EXPORT_TO_CSV_DATA_TYPES = {
    RAW_DATA: "Raw Data",
    PROCESSED_DATA: "Processed Data",
};

export const DATA_HANDLER_FUNCTION_NAMES = {
    DISABLE_EXPORT_BUTTON_FN: "disableExportButtonFn",
    CHECK_FOR_NO_DATA_FROM_SUBSCRIBER_FN: "checkForNoDataFromSubscriberFn", // = "hasNoCsvData",
};
export const DEFAULT_SEARCH_FILTER = "";


export const JSON_TO_CSV_OPTIONS = { complete: true };

export const CSV_ROOT_FOLDER_NAME_ = "MAPSTED_ANALYTICS_";
export const DOWNLOADABLE_FILE_NAME = "mapsted-analytics-data-dump";

export const EXPORTCSV_WIDGET_ITEMS_PER_PAGE_OPTIONS = [
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 4, text: "4", value: 4 },
    { key: 6, text: "6", value: 6 },
    { key: 8, text: "8", value: 8 },
    { key: 10, text: "10", value: 10 },
    { key: 15, text: "15", value: 15 },
];

export const DASHBOARD_WIDGETS_FULL_NAME_TEXT = {
    ZonGeoContainer: "Which areas of your property receive the most traffic",
    NewsVsRepeatContainer: "How many visits are new vs repeat",
    HeatmapWidget: "See where visitors spend the most time using heatmaps",
    ZoneTrafficWidgetContainer: "What are the most popular peak times for each zone in your property",
    CategoryContainer: "What is the most searched and visited category for your property",
    CommonlyUsedDevicesContainer: "What is the most commonly used device in your property",
    ScreenResolutionContainer: "What is the most common screen resolution among your visitors",
    ServiceProviderContainer: "What is the most common service provider used by your visitors",
    AppUsageVsTimeWidget: "On average, how often do users open your app",
    DownloadsVsTimeWidget: "How are consumers responding to your brand and marketing strategy",
    UniqueSessionsVsTimeWidget: "Total Number of Web Application Users",
    TopSearchesWidget: "What people search in the app",
    SessionDurationVsTimeWidget: "What is the average length of time users spend interacting with your app",
    SearchedConversionRateWidget: "What is your conversion rate",
    StoreAndCategorySearchesWidget: "What was the most searched category and store in your property",
    RetentionRateWidget: "What percentage of users continue using your app over a specific period of time",
    // content: "View the number of visitors to your most popular venues",
    // content: "See how your visitors interact with your property"
};
export const ENGAGEMENT_MOBILE_NUMBER_WIDGET_DATA_KEY_VALUE = {
    totalDownloads: "Downloads",
    averageSessionLength: "Average App Session Length",
    averageMonthlyUsers: "Monthly Active Users",
    averageInterval: "Average App Session Interval"

};

export const QUICK_SNAPSHOT_WIDGET_DATA_KEY_VALUE = {
    activeZoneGeofences: "Live Spots", // for boost
    activeZoneGeofencesByLevel: "Live Spots", // for tags
    popularZoneGeofences: "Popular Zone Geofence",
    floorCrowdednesses: "Floor Crowdedness",
    topPaths: "Top Paths",
};
