import React from "react";
import "./toogle.css"; // Import your CSS file for styling

const ToggleButton = ({ isChecked, setIsChecked }) => {
    const handleToggle = React.useCallback(() => {
        setIsChecked((prev) => !prev);
    }, [isChecked]);

    return (
        <div className="toogleContentBox">
            <label className={`toggle-button ${isChecked ? "active" : ""}`}>
                <input
                    type="radio"
                    name="toggle"
                    data-testid="ToggleButton_input"
                    checked={isChecked}
                    onClick={() => handleToggle()}
                    onChange={() => { }}
                />
                <span className="slider"></span>
            </label>
        </div>
    );
};

export default ToggleButton;
