const { EntityType, ObstacleEntityType, PointOfInterestType, StructureEntityType, BoundaryEntityType, TransitionType } = require("./entityTypes");
const { DEFAULT_LANGUAGE_CODE } = require("./map.constants");
const cloneDeep = require("lodash.clonedeep");
const { LinkPriorityMultiplier } = require("./node.constants");
// const { Style } = require("ol/style");

const PROPERTY_LAYER_STYLES = [
    {
        layerName: "Layer: -1",
        layerIdx: -1,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Base",
        layerIdx: 1,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Pathway",
        layerIdx: 2,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Structures",
        layerIdx: 3,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Obstacles",
        layerIdx: 4,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Transitions",
        layerIdx: 5,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 10,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
];

const BUILDING_LAYER_STYLES = [
    {
        layerName: "Layer: -1",
        layerIdx: -1,
        spatialIndex: false,
        overlayLayer: false,
        // minZoomLevel: 14,
        // maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Base",
        layerIdx: 1,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Pathway",
        layerIdx: 2,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Structures",
        layerIdx: 3,
        spatialIndex: true,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Obstacles",
        layerIdx: 4,
        spatialIndex: false,
        overlayLayer: true,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
    {
        layerName: "Transitions",
        layerIdx: 5,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    },
];

const PROPERTY_LAYER_STYLES_EDITOR = [
    ...PROPERTY_LAYER_STYLES,
    {
        layerName: "POIs",
        layerIdx: 6,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    }];
const BUILDING_LAYER_STYLES_EDITOR = [
    ...BUILDING_LAYER_STYLES,
    {
        layerName: "POIs",
        layerIdx: 6,
        spatialIndex: false,
        overlayLayer: false,
        minZoomLevel: 14,
        maxZoomLevel: 24,
        defaultOpacity: 1,
        customOpacityPerZoomLevel: []
    }];

exports.BOUNDING_BOX_STYLE = {
    stroke: {
        color: "#3399CC",
        width: 1
    }
};

const TransitionBoundingBoxStyleHelper = (transitionType) =>
{
    return {
        fill: {
            color: `rgba(${exports.TRANSITION_TYPE_COLORS[transitionType]}, .2)`
        },
        stroke: {
            color: `rgba(${exports.TRANSITION_TYPE_COLORS[transitionType]}, 1)`,
            width: 3
        },
    };
};

exports.TRANSITION_TYPE_COLORS = {
    "Selected": "0, 0, 255",
    [TransitionType.UNKNOWN_TRANSITION]: "255, 0, 0",
    [TransitionType.ENTRANCE_EXIT]: "145, 184, 255",
    [TransitionType.STAIRS]: "127, 255, 212",
    [TransitionType.ESCALATOR]: "223, 255, 0",
    [TransitionType.ELEVATOR]: "34, 139, 34",
    [TransitionType.SUBWAY_STATION]: "255, 191, 0",
    [TransitionType.TRAIN_STATION]: "204, 85, 02",
    [TransitionType.BUS_STOP]: "255, 127, 80",
    [TransitionType.SHUTTLE]: "192, 64, 0",
    [TransitionType.GATE]: "72, 50, 72",
    [TransitionType.ACCESSIBLE_RAMP]: "100, 130, 146",
    [TransitionType.CONVEYOR_BELT]: "93, 63, 211",
    [TransitionType.STEPS]: "207, 159, 255",
};
exports.TRANSITION_BOUNDING_BOX_STYLE = {
    "Selected": TransitionBoundingBoxStyleHelper("Selected"),
    [TransitionType.UNKNOWN_TRANSITION]: TransitionBoundingBoxStyleHelper(TransitionType.UNKNOWN_TRANSITION),
    [TransitionType.ENTRANCE_EXIT]: TransitionBoundingBoxStyleHelper(TransitionType.ENTRANCE_EXIT),
    [TransitionType.STAIRS]: TransitionBoundingBoxStyleHelper(TransitionType.STAIRS),
    [TransitionType.ESCALATOR]: TransitionBoundingBoxStyleHelper(TransitionType.ESCALATOR),
    [TransitionType.ELEVATOR]: TransitionBoundingBoxStyleHelper(TransitionType.ELEVATOR),
    [TransitionType.SUBWAY_STATION]: TransitionBoundingBoxStyleHelper(TransitionType.SUBWAY_STATION),
    [TransitionType.TRAIN_STATION]: TransitionBoundingBoxStyleHelper(TransitionType.TRAIN_STATION),
    [TransitionType.BUS_STOP]: TransitionBoundingBoxStyleHelper(TransitionType.BUS_STOP),
    [TransitionType.SHUTTLE]: TransitionBoundingBoxStyleHelper(TransitionType.SHUTTLE),
    [TransitionType.GATE]: TransitionBoundingBoxStyleHelper(TransitionType.GATE),
    [TransitionType.ACCESSIBLE_RAMP]: TransitionBoundingBoxStyleHelper(TransitionType.ACCESSIBLE_RAMP),
    [TransitionType.CONVEYOR_BELT]: TransitionBoundingBoxStyleHelper(TransitionType.CONVEYOR_BELT),
    [TransitionType.STEPS]: TransitionBoundingBoxStyleHelper(TransitionType.STEPS),
};

/**
 * Generates a legend for the transition types.
 *
 * @return {Array} An array of objects containing the label and color of each transition type.
 */
exports.GET_TRANSITION_TYPE_LEGEND = () =>
{
    return Object.keys(TransitionType).map((key) =>
    {
        return {
            label: key,
            color: `rgb(${exports.TRANSITION_TYPE_COLORS[TransitionType[key]]})`,
        };
    });
};

exports.STYLE_TYPES = {
    CLASSIC: "classic",
    DARK: "dark",
    BLANK: "blank",
    EDITOR: "editor",
};

exports.styleClassicNodes = {
    node: {
        stroke: {
            color: "rgb(128,128,128)",
            width: 2,
        },
        fill: {
            color: "rgba(128,128,128,.99)",
        },
    },
    exitEntranceNode: {
        stroke: {
            color: "#F15944",
            width: 2,
        },
        fill: {
            color: "#F15944",
        },
    },
    nodeLink: {
        stroke: {
            color: "#000000",
            width: 1,
            lineDash: [2, 5]
        },
        fill: {
            color: "#F15944",
        }
    }
};

const editorPOIStyle = {
    name: "Unknown POI",
    polygon: {
        default: {

        },
        selected: {
            fill: {
                color: "blue"
            },
            stroke: {
                color: "blue",
                width: 2
            },
        }
    },
    layerIdx: 6
};

exports.NODE_LINK_STYLE = (color) =>
{
    return {
        stroke: {
            color: color,
            width: 1,
        },
        fill: {
            color: color,
        }
    };
};

exports.NODE_LINK_COLORS = {
    [LinkPriorityMultiplier.VeryHigh]: "#63abff",
    [LinkPriorityMultiplier.High]: "#4dcd32",
    [LinkPriorityMultiplier.Normal]: "#000000",
    [LinkPriorityMultiplier.Low]: "#FFB763",
    [LinkPriorityMultiplier.VeryLow]: "#DC4D01",
    [LinkPriorityMultiplier.Blocked]: "#FF0000",
};

exports.GET_NODE_LINK_LEGEND = () =>
{
    return Object.keys(LinkPriorityMultiplier).map((key) =>
    {
        return {
            label: key,
            color: exports.NODE_LINK_COLORS[LinkPriorityMultiplier[key]],
        };
    });
};

exports.styleClassic = {
    property: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.UNKNOWN_BOUNDARY]: {
                name: "Base Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: -1
            },
            [BoundaryEntityType.PROPERTY_BOUNDARY]: {
                name: "Property Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: -1
            },
            [BoundaryEntityType.BUILDING_BOUNDARY]: {
                name: "Building Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: -1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 5
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#7BBA52"
                        },
                        stroke: {
                            color: "#7BBA52",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#AADAFF"
                        },
                        stroke: {
                            color: "#AADAFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.BUILDING]: {
                name: "Building",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#1B1871"
                        },
                        stroke: {
                            color: "#25219C",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#AB0062"
                        },
                        stroke: {
                            color: "#7D0048",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#F7A78A"
                        },
                        stroke: {
                            color: "#F2784B",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#EDEDED"
                        },
                        stroke: {
                            color: "#C9C9C9",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#F5E8D7",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#CCCBBF"
                        },
                        stroke: {
                            color: "#8A8984",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#FFFFFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF00"
                        },
                        stroke: {
                            color: "#FFFFFF00",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFCFBF"
                        },
                        stroke: {
                            color: "#FFB59C",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
        },
        layerStyles: PROPERTY_LAYER_STYLES,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 3
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            }
        },
    },
    building: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.FLOOR_BOUNDARY]: {
                name: "Floor Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: -1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#7BBA52"
                        },
                        stroke: {
                            color: "#7BBA52",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#AADAFF"
                        },
                        stroke: {
                            color: "#AADAFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#1B1871"
                        },
                        stroke: {
                            color: "#25219C",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#F5E8D7",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                }
            },
            layerIdx: 4,

            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#AB0062"
                        },
                        stroke: {
                            color: "#7D0048",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#F7A78A"
                        },
                        stroke: {
                            color: "#F2784B",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#EDEDED"
                        },
                        stroke: {
                            color: "#C9C9C9",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#CCCBBF"
                        },
                        stroke: {
                            color: "#8A8984",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#FFFFFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF00"
                        },
                        stroke: {
                            color: "#FFFFFF00",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFCFBF"
                        },
                        stroke: {
                            color: "#FFB59C",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
        },

        layerStyles: BUILDING_LAYER_STYLES,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 6
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            },
        },
    },

    tileLayer: {
        url: 'https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        projection: 'EPSG:3857',
        attributions:
            `
                  <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Voyager</a> |
                © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
            `
    },

    satelliteTileLayer: {
        projection: "EPSG:3857",
        url: "https://mt{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        attributions: `
                <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">OpenLayers</a> |
                © <a href="https://www.google.com/intl/en_US/help/terms_maps/" target="_blank" rel="noopener noreferrer">Google</a>
            `
    }
};

exports.styleDark = {
    property: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.UNKNOWN_BOUNDARY]: {
                name: "Base Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#000000",
                        },
                        stroke: {
                            color: "#000000",
                            width: 1
                        },
                    }
                },
                layerIdx: 1
            },
            [BoundaryEntityType.PROPERTY_BOUNDARY]: {
                name: "Property Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#000000",
                        },
                        stroke: {
                            color: "#000000",
                            width: 1
                        },
                    }
                },

                layerIdx: -1
            },
            [BoundaryEntityType.BUILDING_BOUNDARY]: {
                name: "Building Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#000000",
                        },
                        stroke: {
                            color: "#000000",
                            width: 1
                        },
                    }
                },
                layerIdx: -1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)",
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)",
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)",
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)",
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },

                layerIdx: 4
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)",
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 5
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)",
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)",
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(28, 124, 71, 1)"
                        },
                        stroke: {
                            color: "rgba(28, 124, 71, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(9, 22, 38, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(0, 154, 249, 1)"
                        },
                        stroke: {
                            color: "rgba(0, 154, 249, 0)",
                            width: 2
                        },
                        layerIdx: 4
                    }
                },
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.BUILDING]: {
                name: "Building",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(169, 169, 169, 1)",
                        },
                        stroke: {
                            color: "rgba(105, 105, 105, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(25, 66, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(5, 24, 25, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(25, 66, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(5, 24, 25, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(107, 105, 105, 1)"
                        },
                        stroke: {
                            color: "rgba(107, 105, 105, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(104, 137, 155, 1)",
                        },
                        stroke: {
                            color: "rgba(104, 137, 155, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)",
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    },
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)"
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    },
                },
                layerIdx: 5,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(24, 43, 70, 1)",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)",
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(167, 205, 242, 1)",
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(18, 30, 48, 1)"
                        },
                        stroke: {
                            color: "rgba(35, 43, 57, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(15, 20, 25, 1)",
                        },
                        stroke: {
                            color: "rgba(15, 20, 25, 0)",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(15, 20, 25, 1)"
                        },
                        stroke: {
                            color: "rgba(66, 69, 70, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(41, 99, 99, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(41, 99, 99, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(20, 83, 86, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(20, 83, 86, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(54, 121, 80, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(167, 205, 242, 1)",
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        }
                    },
                },
                layerIdx: 3
            },
        },
        nodeStyles: exports.styleClassicNodes,
        layerStyles: PROPERTY_LAYER_STYLES,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
                selected:
                {
                    fill: {
                        color: "rgba(167, 205, 242, 1)",
                    },
                    stroke: {
                        color: "rgba(9, 22, 38, 1)",
                        width: 2
                    }
                }
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "rgba(126,147,176,1)"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "rgba(0,0,0,1)",
                        width: .5
                    },
                    layerIdx: 3
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            },
        },
    },
    building: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.FLOOR_BOUNDARY]: {
                polygon: {
                    default: {
                        name: "Floor Boundary",
                        stroke: {
                            color: "rgba(66, 69, 70, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)",
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)"
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)"
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)"
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(70, 102, 125, 1)"
                        },
                        stroke: {
                            color: "rgba(70, 102, 125, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)"
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(33, 60, 64, 1)"
                        },
                        stroke: {
                            color: "rgba(33, 60, 64, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(28, 124, 71, 1)"
                        },
                        stroke: {
                            color: "rgba(28, 124, 71, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(9, 22, 38, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(0, 154, 249, 1)"
                        },
                        stroke: {
                            color: "rgba(0, 154, 249, 0)",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(25, 66, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(5, 24, 25, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(25, 66, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(5, 24, 25, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(107, 105, 105, 1)"
                        },
                        stroke: {
                            color: "rgba(107, 105, 105, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(104, 137, 155, 1)"
                        },
                        stroke: {
                            color: "rgba(104, 137, 155, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(15, 20, 25, 1)"
                        },
                        stroke: {
                            color: "rgba(15, 20, 25, 0) ",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(24, 43, 70, 1)",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(167, 205, 242, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(24, 43, 70, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(167, 205, 242, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        }
                    }

                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(18, 30, 48, 1)"
                        },
                        stroke: {
                            color: "rgba(35, 43, 57, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(15, 20, 25, 1)"
                        },
                        stroke: {
                            color: "rgba(15, 20, 25, 0)",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(15, 20, 25, 1)"
                        },
                        stroke: {
                            color: "rgba(66, 69, 70, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(41, 99, 99, 1) "
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(41, 99, 99, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(20, 83, 86, 1)",
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(20, 83, 86, 1)"
                        },
                        stroke: {
                            color: "rgba(8, 40, 40, 1)",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(54, 121, 80, 1)"
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        },

                    },
                    selected: {
                        fill: {
                            color: "rgba(167, 205, 242, 1)",
                        },
                        stroke: {
                            color: "rgba(9, 22, 38, 1)",
                            width: 2
                        }
                    },
                },
                layerIdx: 3
            }
        },
        nodeStyles: exports.styleClassicNodes,
        layerStyles: BUILDING_LAYER_STYLES,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
                selected:
                {
                    fill: {
                        color: "rgba(167, 205, 242, 1)",
                    },
                    stroke: {
                        color: "rgba(9, 22, 38, 1)",
                        width: 2
                    }
                }
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "rgba(126, 147, 176, 1)"
                    },
                    stroke: {
                        color: "rgba(0,0,0,1)",
                        width: .5
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    layerIdx: 6
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            },

        },
    },
    tileLayer: {
        url: 'https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png',
        projection: 'EPSG:3857',
        attributions:
            `
                  <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Dark All</a> |
                © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
            `
    }
};

exports.styleBlank = {
    property: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.UNKNOWN_BOUNDARY]: {

                name: "Base Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 1
            },
            [BoundaryEntityType.PROPERTY_BOUNDARY]: {
                name: "Property Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: -1
            },
            [BoundaryEntityType.BUILDING_BOUNDARY]: {
                name: "Building Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: -1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.BUILDING]: {
                name: "Building",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
        },
        layerStyles: PROPERTY_LAYER_STYLES,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: undefined,
                    stroke: undefined,
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 3
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            }
        },
    },
    building: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.FLOOR_BOUNDARY]: {
                name: "Floor Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {

                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI",
                layerIdx: -1
            }
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 4,

            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#F7A78A"
                        },
                        stroke: {
                            color: "#F2784B",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            }
        },
        layerStyles: BUILDING_LAYER_STYLES,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: undefined,
                    stroke: undefined,
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 6
                },
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            }
        },
    },
    tileLayer: {
        url: 'https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        projection: 'EPSG:3857',
        attributions:
            `
                  <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Voyager</a> |
                © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
            `
    }
};

exports.styleEditor = {
    property: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.UNKNOWN_BOUNDARY]: {
                name: "Base Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: 1
            },
            [BoundaryEntityType.PROPERTY_BOUNDARY]: {
                name: "Property Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: 1
            },
            [BoundaryEntityType.BUILDING_BOUNDARY]: {
                name: "Building Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: 1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 5
                        },
                    }
                },
                layerIdx: 4
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#7BBA52"
                        },
                        stroke: {
                            color: "#7BBA52",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#AADAFF"
                        },
                        stroke: {
                            color: "#AADAFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: editorPOIStyle
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.BUILDING]: {
                name: "Building",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#1B1871"
                        },
                        stroke: {
                            color: "#25219C",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#AB0062"
                        },
                        stroke: {
                            color: "#7D0048",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#F7A78A"
                        },
                        stroke: {
                            color: "#F2784B",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#EDEDED"
                        },
                        stroke: {
                            color: "#C9C9C9",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#F5E8D7",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#CCCBBF"
                        },
                        stroke: {
                            color: "#8A8984",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#FFFFFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF00"
                        },
                        stroke: {
                            color: "#FFFFFF00",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 2
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFCFBF"
                        },
                        stroke: {
                            color: "#FFB59C",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
        },
        layerStyles: PROPERTY_LAYER_STYLES_EDITOR,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 3
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            }
        },
    },
    building: {
        [EntityType.BOUNDARY]: {
            name: "Boundary",
            [BoundaryEntityType.FLOOR_BOUNDARY]: {
                name: "Floor Boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "rgba(114, 122, 255, .3)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    },
                    selected: {
                        fill: {
                            color: "rgba(114, 122, 255, .5)",
                        },
                        stroke: {
                            color: "rgba(114, 122, 255, 1)",
                            width: 1
                        },
                    }
                },
                layerIdx: 1
            }
        },
        [EntityType.OBSTACLE]: {
            name: "Obstacle",
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage",
                polygon: {
                    default: {
                        fill: {
                            color: "#9A8167"
                        },
                        stroke: {
                            color: "#8C755E",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation",
                polygon: {
                    default: {
                        fill: {
                            color: "#7BBA52"
                        },
                        stroke: {
                            color: "#7BBA52",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            },
            [ObstacleEntityType.WATER]: {
                name: "Water",
                polygon: {
                    default: {
                        fill: {
                            color: "#AADAFF"
                        },
                        stroke: {
                            color: "#AADAFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 4,
            }
        },
        [EntityType.POINT_OF_INTEREST]: {
            name: "Point of Interest",
            [PointOfInterestType.UNKNOWN_POI]: editorPOIStyle
        },
        [EntityType.STRUCTURE]: {
            name: "Structure",
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator",
                polygon: {
                    default: {
                        fill: {
                            color: "#1B1871"
                        },
                        stroke: {
                            color: "#25219C",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Door",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#F5E8D7",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                }
            },
            layerIdx: 4,

            [StructureEntityType.ESCALATOR]: {
                name: "Escalator",
                polygon: {
                    default: {
                        fill: {
                            color: "#AB0062"
                        },
                        stroke: {
                            color: "#7D0048",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit",
                polygon: {
                    default: {
                        fill: {
                            color: "#F7A78A"
                        },
                        stroke: {
                            color: "#F2784B",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening",
                polygon: {
                    default: {
                        fill: {
                            color: "#EDEDED"
                        },
                        stroke: {
                            color: "#C9C9C9",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway",
                polygon: {
                    default: {
                        fill: {
                            color: "#D6CDC4"
                        },
                        stroke: {
                            color: "#C2B4A5",
                            width: 2
                        },
                    }
                },
                layerIdx: 5,
            },
            [StructureEntityType.ROOM]: {
                name: "Room",
                polygon: {
                    default: {
                        fill: {
                            color: "#F5E8D7"
                        },
                        stroke: {
                            color: "#C9B8A1",
                            width: 2
                        },
                    },
                    selected: {
                        fill: {
                            color: "#E4CBAA"
                        },
                        stroke: {
                            color: "#BBA27F",
                            width: 2
                        }
                    }
                },
                layerIdx: 3,

            },
            [StructureEntityType.PARKING]: {
                name: "Parking",
                polygon: {
                    default: {
                        fill: {
                            color: "#CCCBBF"
                        },
                        stroke: {
                            color: "#8A8984",
                            width: 2
                        },
                    }
                },
                layerIdx: 3,
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF"
                        },
                        stroke: {
                            color: "#FFFFFF",
                            width: 2
                        },
                    }
                },
                layerIdx: 2,
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway inner boundary",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFFFFF00"
                        },
                        stroke: {
                            color: "#FFFFFF00",
                            width: 2
                        },
                    }
                },
                layerIdx: -1,
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFE4B3"
                        },
                        stroke: {
                            color: "#FFDB99",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.STEPS]: {
                name: "Steps",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFC75E"
                        },
                        stroke: {
                            color: "#E6AF48",
                            width: 2
                        },
                    }
                },
                layerIdx: 5
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washrooms",
                polygon: {
                    default: {
                        fill: {
                            color: "#FFCFBF"
                        },
                        stroke: {
                            color: "#FFB59C",
                            width: 2
                        },
                    }
                },
                layerIdx: 3
            },
        },

        layerStyles: BUILDING_LAYER_STYLES_EDITOR,
        nodeStyles: exports.styleClassicNodes,
        default: {
            polygon: {
                default: {
                    fill: {
                        color: '#ffffff66',
                    },
                    stroke: {
                        color: '#3399CC',
                        width: 1,
                    },
                },
            },
            text: {
                default: {
                    size: "11px",
                    fill: {
                        color: "#000000"
                    },
                    fontName: "Arial, sans-serif",
                    font: "bold 11px Arial, sans-serif",
                    stroke: {
                        color: "#FFFFFF",
                        width: 2
                    },
                    layerIdx: 6
                }
            },
            icon: {
                opacity: 0.9,
                scale: [0.4, 0.4],
            },
        },
    },

    tileLayer: {
        url: 'https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        projection: 'EPSG:3857',
        attributions:
            `
                  <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Voyager</a> |
                © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
            `
    }
};

let styleClassicEditor = {
    ...exports.styleEditor,
};


exports.styleClassicEditor = styleClassicEditor;

exports.MAP_ID_TEXT_STYLE = {
    size: "11px",
    fill: {
        color: "#f15944"
    },
    fontName: "Arial, sans-serif",
    font: "bold 11px Arial, sans-serif",
    stroke: {
        color: "#FFFFFF",
        width: 2
    }
};

exports.MAP_TEXT_STYLE = {
    size: "11px",
    fill: {
        color: "#E61A4C"
    },
    fontName: "Arial, sans-serif",
    font: "bold 11px Arial, sans-serif",
    stroke: {
        color: "#FFFFFF",
        width: 1
    }
};

exports.SELECTED_MAP_TEXT_STYLE = {

    size: "12px",
    fill: {
        color: "#5944f1",
    },
    fontName: "Arial, sans-serif",
    font: "bold 12px Arial, sans-serif",
    stroke: {
        color: "#FFFFFF",
        width: 1
    }
};

exports.STYLES = {
    [exports.STYLE_TYPES.CLASSIC]: exports.styleClassic,
    [exports.STYLE_TYPES.DARK]: exports.styleDark,
    [exports.STYLE_TYPES.BLANK]: exports.styleBlank,
    [exports.STYLE_TYPES.EDITOR]: exports.styleClassicEditor
};

exports.STYLE_OPTIONS = {
    DEFAULT: "default",
    SELECTED: "selected",
    VACANT: "vacant",
};

/**
 * Overwrites mapStyle with custom styles from the DB wherever available
 * @param {String} styleTemplateName - map style type from STYLE_TYPES
 * @param {Array} customStyles - array of custom styles from DB
 * @param {String} languageCode - language code
 *
 * @returns customMapStyle
 */
exports.getCustomMapStyle = (styleTemplateName, customStyles, languageCode = DEFAULT_LANGUAGE_CODE) =>
{
    if (!exports.STYLES[styleTemplateName] || !Array.isArray(customStyles))
    {
        return;
    }

    let customMapStyle = cloneDeep(exports.STYLES[styleTemplateName]);

    // overwrites polygon, or text styles if available
    // if needed this function can be updated to only look for styleOptions from custom styles for polygon and text
    const overwriteWithCustomStyle = (templateStyle, styleSettings) =>
    {
        // styleSettings can contain polygon or text settings
        return Object.assign(templateStyle, styleSettings);
    };

    // loop through custom styles
    customStyles.forEach((customStyle) =>
    {
        const { target, styleSettings } = customStyle;
        const { entityType, subEntityType, locationType } = target;

        if (entityType === -1 && subEntityType === -1)
        {
            customMapStyle[locationType].default = overwriteWithCustomStyle(
                customMapStyle[locationType].default,
                styleSettings[languageCode]);
        }
        else
        {
            customMapStyle[locationType][entityType][subEntityType] = overwriteWithCustomStyle(
                customMapStyle[locationType][entityType][subEntityType],
                styleSettings[languageCode]);
        }
    });

    return customMapStyle;
};

/**
 *
 * @param {*} style - property/building style
 * @param {STYLE_OPTIONS} - styleOption
 */
exports.getDefaultPolygonStyle = (style, styleOption) =>
{
    try
    {
        return style.default.polygon[styleOption];
    }
    catch (err)
    {
        return;
    }
};


exports.TILE_LAYERS_IDS = {
    "CARTO_LAYER": "cartoLayer",
    "SATELLITE_TILE_LAYER": "satellite-tile-layer"
};
