import { useCallback, useMemo } from "react";
import { useMapOverlaysQuery } from "../../_api/queries";
import { createSingleVectorLayerFromFeatures, getClusteredTextLayers, getFeatureOfMapOverlay } from "mapsted.maps/mapFunctions/mapOverlay";


const useMapOverlay = () =>
{
    const mapOverlayQuery = useMapOverlaysQuery();

    /**
     * Process raw query data to useable hash map from navigation floorId to array of map overlays
     */
    const floorIdToMapOverlaysHashMemo = useMemo(() =>
    {
        let floorIdToMapOverlayHash = {};

        const { isSuccess, data } = mapOverlayQuery;
        const queryData = data?.data;

        if (isSuccess && Array.isArray(queryData))
        {
            queryData.forEach((mapOverlay) =>
            {
                const { floorId } = mapOverlay;

                if (!floorIdToMapOverlayHash[floorId])
                {
                    floorIdToMapOverlayHash[floorId] = [];
                }

                floorIdToMapOverlayHash[floorId].push(mapOverlay);
            });
        }

        return floorIdToMapOverlayHash;
    }, [mapOverlayQuery.data]);


    /**
     * Create overlay layers for each floor 
     */
    const floorIdToMapOverlayLayersHashMemo = useMemo(() =>
    {
        let floorIdToMapOverlayLayersHashMemo = {};

        Object.keys(floorIdToMapOverlaysHashMemo).forEach((floorId) =>
        {
            let overlayDataArray = floorIdToMapOverlaysHashMemo[floorId];
            const mapOverlayFeatures = overlayDataArray.map((overlay) => getFeatureOfMapOverlay(overlay));
            const mapOverlayLayer = createSingleVectorLayerFromFeatures(mapOverlayFeatures);
            const mapOverlayTextLayers = getClusteredTextLayers(overlayDataArray);

            [mapOverlayLayer, ...mapOverlayTextLayers].forEach((layer) =>
            {
                let prevId = layer.get("layerId");
                layer.set("layerId", "overlay" + prevId);
            });

            floorIdToMapOverlayLayersHashMemo[floorId] = [mapOverlayLayer, ...mapOverlayTextLayers];
        });

        return floorIdToMapOverlayLayersHashMemo;
    }, [floorIdToMapOverlaysHashMemo]);

    /**
     * Assuming overlay layers have overlay in key name - entityLayers layerName -> layer hash
     */
    const handleUpdateOverlayLayersVisibility = useCallback((entityLayers, isVisible) =>
    {
        if (!entityLayers)
        {
            return;
        }

        let entityLayerNames = Object.keys(entityLayers);
        let overlayLayerNames = entityLayerNames.filter((layerName) => layerName.includes("overlay"));

        overlayLayerNames.forEach((overlayLayerName) =>
        {
            entityLayers[overlayLayerName].setVisible(isVisible);
        });

    }, []);

    /**
     * Assuming zoneGeofence layers have zoneGeofence in key name - entityLayers layerName -> layer hash
     */
    const handleUpdateZoneGeofenceLayersVisibility = useCallback((entityLayers, isVisible) =>
    {
        if (!entityLayers)
        {
            return;
        }

        let entityLayerNames = Object.keys(entityLayers);
        let overlayLayerNames = entityLayerNames.filter((layerName) => layerName.includes("zoneGeofence"));

        overlayLayerNames.forEach((overlayLayerName) =>
        {
            entityLayers[overlayLayerName].setVisible(isVisible);
        });

    }, []);

    return {
        floorIdToMapOverlayLayersHash: floorIdToMapOverlayLayersHashMemo,
        updateOverlayLayersVisibility: handleUpdateOverlayLayersVisibility,
        updateZoneGeofenceLayersVisibility: handleUpdateZoneGeofenceLayersVisibility
    };
}

export default useMapOverlay;