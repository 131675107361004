import { deepValue } from "mapsted.utils/objects";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "semantic-ui-react";
import { floorIdState, isHeatmapFullscreenState, levelsSelector } from "../../store/DashboardAtoms";
import { floorPaginiationNumberState } from "../../store/MapAtoms";
import { ButtonIcon } from "../common/buttonIcon/ButtonIcon";
import classNames from "classnames";

export const FloorsButtons = ({ onFloorChange, addAdditionalBtnProps }) =>
{
    const [floorId, setFloorId] = useRecoilState(floorIdState);
    const [paginationNum, setPaginationNum] = useRecoilState(floorPaginiationNumberState);
    const isHeatmapFullscreen = useRecoilValue(isHeatmapFullscreenState);
    // const setSelectedZones = useSetRecoilState(selectedZoneGeofenceHashState)
    const floors = useRecoilValue(levelsSelector);
    const [floorButtonThreshold, setFloorButtonThreshold] = useState(1); // how many floors to show above and below current florr

    React.useEffect(() =>
    {
        let paginationNum = deepValue(floors.find((floor) => floor.floorId === floorId), "floorNumber", 0);

        if (floors.length === 0)
        {
            // do nothing
        }
        // if there is no floor number 2 up to scroll up to
        if (floors.findIndex((floor) => floor.floorNumber === paginationNum + 1) === -1)
        {
            paginationNum--;
        }
        // if there is no floor number 2 down to scroll down to
        else if (floors.findIndex((floor) => floor.floorNumber === paginationNum - 1) === -1)
        {
            paginationNum++;
        }

        setPaginationNum(paginationNum);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [floorId]);

    useEffect(() =>
    {
        if (isHeatmapFullscreen)
        {
            setFloorButtonThreshold(2);
        }
        else
        {
            setFloorButtonThreshold(1);
        }
    }, [isHeatmapFullscreen]);

    const showUpPageinationUp = React.useMemo(() =>
    {
        if (floors.length <= 3)
        {
            return false;
        }

        let indexThreshold = paginationNum + floorButtonThreshold + 1;
        // if there is no floor number 2 up to scroll up to
        if (floors.findIndex((floor) => floor.floorNumber === indexThreshold) === -1)
        {
            return false;
        }

        return true;
    }, [floors, floorButtonThreshold, paginationNum]);

    const showUpPageinationDown = React.useMemo(() =>
    {
        // console.log(floors.length);
        if (floors.length <= (floorButtonThreshold * 2) + 1)
        {
            return false;
        }

        let indexThreshold = paginationNum - floorButtonThreshold - 1;
        // if there is no floor number 2 down to scroll down to
        if (floors.findIndex((floor) => floor.floorNumber === indexThreshold) === -1)
        {
            return false;
        }

        return true;
    }, [floors, floorButtonThreshold, paginationNum]);

    const handleChangeFloorId = React.useCallback((selectedFloorId) =>
    {
        if (floorId === selectedFloorId) return;

        setFloorId(selectedFloorId);
        // eslint-disable-next-line no-unused-expressions
        onFloorChange?.(selectedFloorId);
        //     const newFloorId = floorId;
        //     // when floor is changed zoneId's in state will break the mapComponents . so resetting to empty zones
        //    if(newFloorId!==floorId){
        //     setSelectedZones({});
        //    }
    }, [setFloorId, onFloorChange, floorId]);

    const handlePaginationChange = React.useCallback((change) =>
    {
        setPaginationNum(paginationNum + change);
    }, [paginationNum, setPaginationNum]);

    const renderFloorButtons = React.useCallback(() =>
    {
        if (!Array.isArray(floors))
        {
            return (<></>);
        }

        let renderFloors = [...floors];

        if (typeof addAdditionalBtnProps === 'function')
        {
            renderFloors = renderFloors.map((floor) => addAdditionalBtnProps(floor));
        }

        if (renderFloors.length > floorButtonThreshold * 2 + 1)
        {
            // get floor number
            renderFloors = renderFloors.filter((floor) => floor.floorNumber >= paginationNum - floorButtonThreshold && floor.floorNumber <= paginationNum + floorButtonThreshold);
        }

        renderFloors.sort((f1, f2) => f2.floorNumber - f1.floorNumber);

        return (
            <div className="floorButtonsGroup" >
                {
                    renderFloors.map(({ clickAllowed = true, title = '', additionalClasses = '', ...floor }) => <FloorButton
                        title={title}
                        additionalClasses={additionalClasses}
                        isSelected={floor.floorId === floorId}
                        key={floor.floorId}
                        longName={floor.longName}
                        shortName={floor.shortName}
                        floorId={floor.floorId}
                        onClick={clickAllowed && handleChangeFloorId}
                    />)
                }
            </div>
        );
    }, [floors, floorButtonThreshold, paginationNum, floorId, handleChangeFloorId, addAdditionalBtnProps]);

    return (
        <div className={`floorButtonsCover${showUpPageinationUp || showUpPageinationDown ? " navActionsCover" : ""}`}>

            {
                (showUpPageinationUp) && (
                    <ButtonIcon className="floorsToggle prev" icon="chevron-up" onClick={() => handlePaginationChange(1)} />
                )
            }
            {
                renderFloorButtons()
            }
            {
                (showUpPageinationDown) && (
                    <ButtonIcon className="floorsToggle next" icon="chevron-up" onClick={() => handlePaginationChange(-1)} />
                )
            }
        </div>
    );
};

const FloorButton = ({ additionalClasses, title, longName, shortName, isSelected, floorId, onClick, ...rest }) =>
{
    // console.log(isSelected, floorId, additionalClasses);

    const handleFloorButtonClick = React.useCallback(() =>
    {
        (onClick) && onClick(floorId);
    }, [onClick, floorId]);

    return (
        <React.Fragment>
            {/* {
				renderHoverLabel()
			} */}
            <Button
                {...rest}
                title={title}

                className={classNames(isSelected ? "active" : "", additionalClasses)}
                content={shortName}
                onClick={handleFloorButtonClick}
                data-hover-content={longName}
            />
        </React.Fragment>

    );
};
