import React from "react";
import { useRecoilValue } from "recoil";
import { insightsSelectedSessionUIDState } from "../../../store/InsightsAtoms";
import { usePropertyMapQuery } from "../../../_api/queries";
import { useTranslation } from "react-i18next";
import { InfoPopup } from "../../common/InfoPopup";
import { InsightsLowerBound, TrajectoryColor, TrajectoryThresholds } from "../../../_constants/insightsConstants";


export const InsightsDwellLegends = React.memo(function InsightsDwellLegends()
{
    const trans = useTranslation().t;

    return <div className="insightsLegendContainer">
        <div className="widgetsTooltipBox insightsLegend">
            <InfoPopup basic className="infoPopupleft" position="top left" content={trans("Insights.ToolTips.LEGEND")} />

            <div className="tooltipItemRow" >
                <span style={{ background: TrajectoryColor.GREEN }}></span>
                <span>&#8805;</span>{` ${InsightsLowerBound} seconds`}
            </div>
            <div className="tooltipItemRow" >
                <span style={{ background: TrajectoryColor.ORANGE }}></span>
                <span>&#8805;</span> {` ${TrajectoryThresholds.ORANGE} seconds`}
            </div>
            <div className="tooltipItemRow" >
                <span style={{ background: TrajectoryColor.RED }}></span>
                <span>&#8805;</span>{` ${TrajectoryThresholds.RED} seconds`}
            </div>
        </div>
    </div>;
});
export const InsightsActiveSessionUIDLegend = () =>
{
    const selectedUserSessionUID = useRecoilValue(insightsSelectedSessionUIDState);
    const mapDataQuery = usePropertyMapQuery();
    if (mapDataQuery.isSuccess)
    {
        return (
            <div>
                <div className="insightsSessionUIDContainer">
                    {selectedUserSessionUID}
                </div>
            </div>
        );
    }
};
