import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { ENGAGEMENT_MOBILE_NUMBER_WIDGET_DATA_KEY_VALUE } from "../../_constants/exportToCsvConstants";
import { convertNumberWidgetsDurationContentToMins, formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";
import { formatMinsToHHMMSS } from "../../_utils/date.utils";

export const ReportsOverviewDetail = ({ data, connectedPageName, injectProcessedData }) =>
{
    const trans = useTranslation().t;

    const sessionLengthValues = useMemo(() =>
    {
        if (!data)
        {
            return;
        }

        const { averageSessionLength } = data;

        let extra = [];

        for (let i = 0; i < averageSessionLength.extra.length; i++)
        {
            extra[i] = trans(`ReportsOverviewDetail.${averageSessionLength.extra[i]}`);
        }


        return { content: averageSessionLength.content, extra };

    }, [data, trans]);

    const sessionIntervalValues = useMemo(() =>
    {

        if (!data)
        {
            return;
        }

        const { averageInterval } = data;
        let content = [];
        let extra = [];

        if (!averageInterval)
        {
            extra.push(trans("ReportsOverviewDetail.Not_Enough_Data"));
            content.push("");
        }
        else if (averageInterval.weeks > 0)
        {
            content.push(averageInterval.weeks);
            extra.push(trans("ReportsOverviewDetail.wk"));

            if (averageInterval.days > 0)
            {
                content.push(averageInterval.days);
                if (averageInterval.days > 1)
                {
                    extra.push(trans("ReportsOverviewDetail.days"));
                }
                else
                {
                    extra.push(trans("ReportsOverviewDetail.day"));
                }
            }
        }
        else
        {
            content.push(averageInterval.days);
            if (averageInterval.days > 1)
            {
                extra.push(trans("ReportsOverviewDetail.days"));
            }
            else
            {
                extra.push(trans("ReportsOverviewDetail.day"));
            }

            if (averageInterval.hours > 0)
            {
                content.push(averageInterval.hours);
                extra.push(trans("ReportsOverviewDetail.hr"));
            }
        }

        return { content, extra };
    }, [data, trans]);

    useEffect(() =>
    {

        if (data)
        {
            for (const [key, value] of Object.entries(data))
            {
                let formatData = [];
                let keyName = ENGAGEMENT_MOBILE_NUMBER_WIDGET_DATA_KEY_VALUE[key].replaceAll(/\s/g, '');
                if (key === "averageSessionLength")
                {
                    let durationMins = convertNumberWidgetsDurationContentToMins(sessionLengthValues);
                    formatData = [{
                        ["AverageAppSessionLength"]: formatTimeStringsForCSV(sessionLengthValues?.content, sessionLengthValues?.extra), "DurationMins": durationMins,
                        "AverageDurationFormatted": formatMinsToHHMMSS(durationMins)
                    }];
                } else if (key === "averageInterval")
                {
                    let durationMins = convertNumberWidgetsDurationContentToMins(sessionIntervalValues);
                    formatData = [{
                        ["AverageAppSessionInterval"]: formatTimeStringsForCSV(sessionIntervalValues?.content, sessionIntervalValues?.extra), "DurationMins": durationMins,
                        "AverageDurationFormatted": formatMinsToHHMMSS(durationMins)
                    }];
                } else
                {
                    formatData = [{ [keyName]: key === "totalDownloads" ? (value || "N/A") : value }];
                }

                let exportPayload = {
                    name: ENGAGEMENT_MOBILE_NUMBER_WIDGET_DATA_KEY_VALUE[key],
                    data: formatData,
                    description: key
                };
                injectProcessedData(exportPayload);
            }
        }

        // exportPayload.forEach(({ name, data, description, injector }) =>
        // {
        //     injector && injector({ name, data, description });
        // });

    }, [data, connectedPageName]);

    const renderOverviewWidget = useCallback(() =>
    {
        return (
            <>
                <OverviewDetailBox
                    appOnly
                    max="190px"
                    popupContent={trans("ReportsOverviewDetail.Number_of_total_downloads_")}
                    icon="downloads"
                    content={data?.totalDownloads || "N/A"}
                    slogan={trans("ReportsOverviewDetail.Downloads")}
                />

                <OverviewDetailBox max="220px"
                    popupContent={trans("ReportsOverviewDetail.Average_monthly_visitors_for_the_selecte")}
                    icon="active-users"
                    content={data?.averageMonthlyUsers || 0}
                    slogan={trans("ReportsOverviewDetail.Monthly_Active_Users")}
                />

                <OverviewDetailBox max="180px"
                    popupContent={trans("ReportsOverviewDetail.Average_App_session_length_for_the_selec")}
                    icon="clock"
                    content={sessionLengthValues.content}
                    extra={sessionLengthValues.extra}
                    slogan={trans("ReportsOverviewDetail.App_Session_Length")}
                />

                <OverviewDetailBox max="200px"
                    popupContent={trans("ReportsOverviewDetail.Average_session_interval_for_the_selecte")}
                    icon="stopwatch"
                    content={sessionIntervalValues.content}
                    extra={sessionIntervalValues.extra}
                    slogan={trans("ReportsOverviewDetail.App_Session_Interval")}

                />
            </>
        );
    }, [data, sessionLengthValues, sessionIntervalValues, trans]);

    return (
        <div className="overviewDetail">
            {(data !== undefined)
                ? renderOverviewWidget()
                : OverviewWidgetLoading}
        </div>
    );
};

const OverviewWidgetLoading = (
    <>
        <OverviewDetailBoxLoading />

        <OverviewDetailBoxLoading />

        <OverviewDetailBoxLoading />

        <OverviewDetailBoxLoading />

    </>
);
