import { DASHBOARD_NUMBER_WIDGETS } from "./widgetFixers";
import { WIDGET_IDS } from "./widgets";

export const defaultGraphWidgets = [
    {
        id: WIDGET_IDS.ZoneGeofencePoiVisitsWidget,
    },
    {
        id: WIDGET_IDS.NewVsRepeatUsersWidget,
    },
    {
        id: WIDGET_IDS.HeatmapWidget,
    },
    {
        id: WIDGET_IDS.ZoneTrafficWidget,
    },
    {
        id: WIDGET_IDS.CommonlyUsedDevicesWidget,
    },

    {
        id: WIDGET_IDS.CategoryWidget,
    },

    {
        id: WIDGET_IDS.ScreenResolutionWidget,
    },
    {
        id: WIDGET_IDS.ServiceProviderWidget,
    },
];

export const defaultNumberWidgets = [
    {
        id: WIDGET_IDS.VisitorsNumberWidget,
    },
    {
        id: WIDGET_IDS.AverageVistTimeNumberWidget,
    },
    {
        id: WIDGET_IDS.NewUsersNumberWidget,
    },
    {
        id: WIDGET_IDS.CurrentCapacityNumberWidget,
    },
];
// total max number widgets that can be added
export const TOTAL_POSSIBLE_NUMBER_WIDGETS = DASHBOARD_NUMBER_WIDGETS.length;

export const DEFAULT_DASHBOARD = {
    _id: "1",
    name: "Default",
    widgets: defaultGraphWidgets,
    numberWidgets: defaultNumberWidgets,
    active: true,
};

export const CURETTED_DASHBOARDS = [DEFAULT_DASHBOARD];

export const CURETTED_DASHBOARDS_MAP = CURETTED_DASHBOARDS.reduce(
    (acc, ele, index) => ({ ...acc, [ele._id]: ele }),
    {}
);

export const API_KEYS = "/analytics/userprofile";

export const ROUTES = {
    PRIMARY: "", // this page will be dealing with default
    EDIT: ":dashboardId/edit",
    CREATE: "create",
    PREVIEW: ":dashboardId/preview", // // this page will be dealing with preview
};

export const MODES = {
    PRIMARY: "default",
    EDIT: "edit",
    CREATE: "create",
    PREVIEW: "preview",
};

export const CREATE_NEW_DASHBOARD_ID = null;

export const DEFAULT_DASHBOARD_NAME = "";

export const DEFAULT_SEARCH_FILTER_VALUE = "";

export const DEFAULT_WIDGETS_CHECKED = {
    // [WIDGET_IDS[key]] : true/false
};

export const TOTAL_WIDGETS_TO_SHOW_IN_A_PAGE = 10;
export const NO_WIDGET_FILTER = "ALL";

export const SUB_HEADER_ACTIONS = {
    CREATE_NEW_DASHBOARD: "CREATE_NEW_DASHBOARD",
    SAVE_ON_EDIT_MODE: "SAVE_ON_EDIT_MODE",
    CANCEL_EDITED_DASHBOARD: "CANCEL_EDITED_DASHBOARD",
    SHOW_AVAILABLE_DB: "SHOW_AVAILABLE_DB",
    GO_TO_EDIT_DASHBOARD: "GO_TO_EDIT_DASHBOARD",
    SET_PREVIEW_DASHBOARD_AS_PRIMARY: "SET_PREVIEW_DASHBOARD_AS_PRIMARY",
    TOGGLE_DASHBOARD_SELECTOR: "TOGGLE_DASHBOARD_SELECTOR",
    GO_BACK: "GO_BACK",
};

export const DASHBOARD_WIDGET_ACTIONS = {
    DELETE_GRAPH_WIDGET: "DELETE_GRAPH_WIDGET",
    DELETE_NUMBER_WIDGET: "DELETE_NUMBER_WIDGET",
    GRAPH_WIDGET_SORT_END: "GRAPH_WIDGET_SORT_END",
    GRAPH_WIDGET_SORT_START: "GRAPH_WIDGET_SORT_START",
    GRAPH_WIDGET_IN_MOVE: "GRAPH_WIDGET_IN_MOVE",
    NUMBER_WIDGET_SORT_END: "NUMBER_WIDGET_SORT_END",
    NUMBER_WIDGET_SORT_START: "NUMBER_WIDGET_SORT_START",
    NUMBER_WIDGET_IN_MOVE: "NUMBER_WIDGET_IN_MOVE",
};

export const DASHBOARD_CREATION_OPTIONS = {
    CREATE_BLANK: "CREATE_BLANK",
    IMPORT_FROM_EXISTING: "IMPORT_FROM_EXISTING",
    NO_SELECTION: "NO_SELECTION",
};

export const MODE_HEADER_DISPLAY_NAME = {
    [MODES.EDIT]: "Edit Mode",
    [MODES.CREATE]: "Create Mode",
    [MODES.PRIMARY]: "Primary",
    [MODES.PREVIEW]: "Preview Mode",
};

export const NO_WIDGET_INDEX_IN_SORT = null;

export const CREATE_CONFORMATION_MODAL_TIME_OUT = 3000;

export const DEFAULT_WIDGET_SEARCH_VALUE = "";

export const REPORT_WIDGETS_OBJ_FLAG = {
    [WIDGET_IDS.NumberOfTotalDownloadsNumberWidgets]: true,
    [WIDGET_IDS.AverageSessionIntervalNumberWidget]: true,
    [WIDGET_IDS.AverageMonthlyVisitorsNumberWidget]: true,
    [WIDGET_IDS.AverageAppSessionLengthNumberWidget]: true,
};


export const IMPORT_CONFORMATION_MODAL_TIME_OUT = 2000;
