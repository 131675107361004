import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSessions } from '../../../_api/queries';
import { processSessionDataToNumberWidgetDataItems } from '../../../_utils/webEngagement.utils';
import useCommon from '../../pages/useCommon';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const useWebEnagamentNumberWidget = () =>
{
	const sessionsQuery = useWebAnalyticsSessions();


	const { filters: { analyticsRequestFilter }, timeZone, trans, languageCode } = useCommon();
	const rawQuery = useMemo(() =>
	{
		const { isSuccess, data } = sessionsQuery;
		if (!!isSuccess)
		{
			return { ...sessionsQuery, data: data.currentIntervalData };
		}

		return sessionsQuery;

	}, [sessionsQuery]);
	const allNumberWidgetsProcessedData = useMemo(() =>
	{
		let processedData = {
			noOfVisitorsPercentage: 0,
			monthlyActiveVisitors: 0,
			monthlyAverageVisitTime: 0,
			pageVisitIntervals: 0,
			newVisitors: 0,
			changeInMonthAvgActiveUsers: 0,
			avgSessionTimeOfCurrentIntervalDuration: [],
			changeInSessionDurationPercentage: 0,
			totalVisitors: 0,
			avgSessionTimeUnits: [],
			avgVisitPageInterval: [],
			avgVisitPageIntervalUnits: [],
			changeInPageVisitInterval: 0,
			changeInPageIntervalPercentage: 0,
			changeInNewVisitors: 0
		};
		const { isSuccess, data } = sessionsQuery;

		if (!!isSuccess)
		{

			return processSessionDataToNumberWidgetDataItems(data, analyticsRequestFilter, timeZone, languageCode, trans);
		}

		return processedData;
	}, [sessionsQuery, trans, analyticsRequestFilter, timeZone, languageCode,]);


	return {
		allNumberWidgetsProcessedData,
		rawQuery,
		sessionsQuery,


	};
};

export default useWebEnagamentNumberWidget;