import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import serverApi from "../../_api/server.api";
import
{
    analyticsRequestFilterSelector,
    propertyIdState,
    buildingIdState,
    floorIdState,
    hasAccessToPropertySelector,
    isHeatmapFullscreenState,
    heatmapRequestFilterSelector,
    heatmapFilterState,
} from "../../store/DashboardAtoms";
import { environmentConstantsState, languageCodeState } from "../../store/AppAtoms";
import { PUBLIC_QUERY_KEYS, NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { processHeatmapData } from "mapsted.maps/utils/map.utils";
import { ButtonIcon } from "../common/buttonIcon/ButtonIcon";
import { PlaceholderBox, WidgetLoadingBoxFull } from "../common/placeholders/Placeholders";
import { DefaultBox } from "../dashboard/DefaultBox";
import { Heatmap } from "../map/Heatmap";
import { ErrorWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { createHeatmapPointsLayer } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { HEATMAP_OPTIONS } from "../../_constants/mapConstants";
import { useTranslation } from "react-i18next";
import { processMapDataQuery } from "../../_utils/map.utils";
import { AccordionFilterSidebar } from "../elements/heatmapFilter/AccordionFilterSidebar";
import { refetchQuery } from "../../_utils/query.utils";
import { useParams } from "react-router-dom";
import useCsvExportEventEmitter from "../common/ExportToCsv/useCsvExportEventEmitter";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { WIDGET_IDS } from "../../_constants/widgets";
import { getWidgetTitle } from "../../_utils/utils";
import { deepCopy } from "mapsted.utils/objects";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";

/**
 * If no params entered, heatmap will act as a widget tile
 * @param {Boolean} fitComponent - allows the heatmap widget to fit any size component given to it
 * @param {Boolean} showFilters - only used if fitComponent is true. Displays filters over map.
 */
export const HeatmapWidget = ({ fitComponent, showFilters, generatePdf, showDateRange, showExport, showHeading, injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const [isHeatmapFullscreen, setIsHeatmapFullscreen] = useRecoilState(isHeatmapFullscreenState);
    const params = useParams();
    const heatmapFilter = useRecoilValue(heatmapFilterState); // extra info that is not being sent to the api (ie, dwell vs passby)
    const languageCode = useRecoilValue(languageCodeState);

    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const heatmapRequestFilter = useRecoilValue(heatmapRequestFilterSelector); // api friendly  filter
    const propertyId = useRecoilValue(propertyIdState);
    const buildingId = useRecoilValue(buildingIdState);
    const floorId = useRecoilValue(floorIdState);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);
    const connectedPageName = useRecoilValue(connectedPageNameState);
    // const { injectRawData, injectProcessedData } = useExportDataConnector("Flows");

    // const NO_DATA_MESSAGE = trans("HeatmapWidget.There_is_no_heatmap_data_to_show");

    // Get heatmap data
    const heatmapQuery = useQuery(
        [
            NAV_ANALYTICS_QUERY_KEYS.HEATMAP,
            { analyticsRequestFilter, heatmapRequestFilter, floorId, environmentConstants },
        ],
        async () =>
        {
            if (!!analyticsRequestFilter)
            {

                let filter = { ...analyticsRequestFilter };
                filter = Object.assign(filter, { floorId, buildingId: buildingId || -1 }, heatmapRequestFilter);
                const response = await serverApi.getNavigationAnalyticsData(
                    filter,
                    NAV_ANALYTICS_QUERY_KEYS.HEATMAP,
                    environmentConstants
                );

                if (!response?.success)
                {
                    throw new Error("Heatmap query response was not ok");
                }

                if (response.data.heatmaps[0])
                {
                    let deepCopyReponseData = JSON.parse(JSON.stringify(response.data));

                    const { dwellHeatmap = [], passByHeatmap = [], pointSpacing } = deepCopyReponseData.heatmaps[0];

                    // let allHeatmapData = passByHeatmap.concat(dwellHeatmap);
                    // allHeatmapData = processHeatmapData(allHeatmapData);

                    const dwellHeatmapData = processHeatmapData(dwellHeatmap);
                    const passByHeatmapData = processHeatmapData(passByHeatmap);

                    let allHeatmapData = dwellHeatmapData.concat(passByHeatmapData);
                    return { allHeatmapData, dwellHeatmapData, passByHeatmapData, pointSpacing };
                }
            } else
            {
                throw new Error("Filter was not ok");
            }
        },
        {
            enabled: !!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );

    const mapDataQuery = useQuery(
        [PUBLIC_QUERY_KEYS.MAP_DATA, { propertyId, buildingId }],
        async () =>
        {
            if (!!propertyId)
            {
                const response = await serverApi.getMapData(propertyId, buildingId);

                if (!response.success)
                {
                    throw new Error("Bulk map data query response was not ok");
                }

                return response.data;
            } else
            {
                throw new Error("No propertyId  selected");
            }
        },
        {
            enabled: !!propertyId && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );

    const refetchHeatmapData = useCallback(() =>
    {
        refetchQuery(heatmapQuery);
    }, [heatmapQuery]);

    const processedMapData = useMemo(() =>
    {
        return processMapDataQuery(mapDataQuery, languageCode);
    }, [languageCode, mapDataQuery]);

    const processedHeatmapData = useMemo(() =>
    {
        const { isError, isLoading, data, isSuccess } = heatmapQuery;

        let heatmapLayer;
        let pointSpacing;

        if (isSuccess && data)
        {
            let { dwellVsPassby } = heatmapFilter;
            heatmapLayer = createHeatmapPointsLayer(data[dwellVsPassby], HEATMAP_OPTIONS);
            pointSpacing = data?.pointSpacing;
        }

        return { isError, isLoading, data, isSuccess, heatmapLayer, pointSpacing };
    }, [heatmapQuery.isSuccess, heatmapFilter]);


    useEffect(() =>
    {
        let preparedData = [];
        if (processedHeatmapData?.data)
        {
            preparedData = Object.entries(deepCopy(processedHeatmapData?.data)).map(([type, value], i) =>
            {
                let newValue = [];
                if (value.length)
                {
                    newValue = value.map((item) =>
                    {
                        return {
                            // item["Label"] = type
                            X: item.position?.[0],
                            Y: item.position?.[1],
                            NormalizedValue: item.count,
                            Label: type
                        };
                    });
                }

                return newValue;
            });
        }

        const payloads = [
            // {
            //     name: "Processed Map Data",
            //     data: processedMapData.data,
            //     description: "",
            //     inject: injectProcessedData
            // },
            {
                name: connectedPageName === "Dashboard" ? getWidgetTitle(trans, WIDGET_IDS.HeatmapWidget) : "Heatmap",
                data: preparedData.length && preparedData[0].length ? [...preparedData] : [],
                description: "",
                inject: injectProcessedData
            },
        ];

        payloads.forEach(({ name, data, description, inject }) =>
        {
            if (data)
            {
                const payload = { name, data, description };
                // console.log(payload);
                inject && inject(payload);
            }
        });
    }, [
        processedHeatmapData?.data, connectedPageName
    ]);

    const boxClassName = React.useMemo(() =>
    {
        // (!!heatmapQuery.isSuccess && !heatmapQuery.data)
        const { isSuccess, isError, data } = heatmapQuery;
        if (isError || (isSuccess && !data))
        {
            return "heatmapWidget hasMessage";
        } else
        {
            return "heatmapWidget";
        }
    }, [heatmapQuery]);

    /**
     *  RENDER MAP
     */
    const renderHeatmap = useCallback(
        () => (

            <DefaultBox
                includeDateRange={showDateRange}
                heading={showDateRange ? "Heatmap" : ""}
                includeExportOption={showDateRange}
                toolTip={trans("Show user's Heatmap")}
                generatePdf={generatePdf}
            >
                <Heatmap
                    mapData={processedMapData.data || {}}
                    heatmapLayer={processedHeatmapData?.heatmapLayer}
                    pointSpacing={processedHeatmapData?.pointSpacing}
                />
            </DefaultBox>

        ),
        [processedHeatmapData, processedMapData]
    );

    /**
     * RENDER MAXMIZED WIDGET
     */
    const renderHeatmapWidgetMaximized = React.useCallback(
        ({ queryIsSuccess, queryIsError }) =>
        {
            if (queryIsSuccess)
            {
                return (
                    <HeatmapWidgetMaximized onMinimizeClick={() => setIsHeatmapFullscreen(!isHeatmapFullscreen)}>
                        {renderHeatmap()}
                    </HeatmapWidgetMaximized>
                );
            } else if (queryIsError)
            {
                return (
                    <HeatmapWidgetMaximized>
                        <div style={{ paddingTop: "100px" }}>
                            <ErrorWidgetMessage onReloadClick={refetchHeatmapData} />
                        </div>
                    </HeatmapWidgetMaximized>
                );
            } else
            {
                return <div className={"heatMapWidgetMaximized"}>{HeatmapWidgetLoading()}</div>;
            }
        },
        [renderHeatmap, refetchHeatmapData, isHeatmapFullscreen, setIsHeatmapFullscreen]
    );

    /**
     * RENDER MINIMIZED WIDGET
     */
    const renderHeatmapWidgetMinimized = useCallback(
        ({ queryIsSuccess, queryIsError }) =>
        {
            // in params if we have dashboard id that means it is either edit/create/preview
            // for a quick fix we will not show full screen option for widget if dashboard is in edit/create/preview
            const { dashboardId = null } = params;
            if (queryIsSuccess)
            {
                return (
                    <>
                        {!dashboardId && (
                            <ButtonIcon
                                className="heatMapTrigger"
                                icon="maximize"
                                onClick={() => setIsHeatmapFullscreen(!isHeatmapFullscreen)}
                            />
                        )}
                        {renderHeatmap()}
                    </>
                );
            } else if (queryIsError)
            {
                return (
                    <div className="errorWidgetWrap">
                        <ErrorWidgetMessage onReloadClick={refetchHeatmapData} />
                    </div>
                );
            } else
            {
                return <div className={"heatmapWidgetMinimized"}>{HeatmapWidgetLoading()}</div>;
            }
        },
        [renderHeatmap, refetchHeatmapData, isHeatmapFullscreen, params, setIsHeatmapFullscreen]
    );

    /**
     * RENDER FIT HEATMAP
     */
    const renderHeatmapFitScreen = useCallback(
        ({ queryIsSuccess, queryIsError, data }) =>
        {
            if (queryIsSuccess)
            {
                // if (!data)
                // {
                //     return (<WidgetMessage isFloat customImageSrc={MAP_NO_DATA_IMG_SRC} customMessage={NO_DATA_MESSAGE} />)
                // }

                return (
                    <div className="heatMapWidgetFit">
                        {showFilters && <AccordionFilterSidebar showHeatmapType />}
                        {renderHeatmap()}
                    </div>
                );
            } else if (queryIsError)
            {
                return (
                    <div className="errorWidgetWrap">
                        <ErrorWidgetMessage onReloadClick={refetchHeatmapData} />
                    </div>
                );
            } else
            {
                return (
                    <WidgetLoadingBoxFull noSpace>
                        {trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}
                        <br /> {trans("Placeholders.Thank_you_for_your_patience_while_we_gat")}{" "}
                    </WidgetLoadingBoxFull>
                );
            }
        },
        [renderHeatmap, refetchHeatmapData, trans, showFilters]
    );

    // RENDER WIDGET
    const renderHeatmapWidget = React.useCallback(() =>
    {
        let queryIsSuccess = processedHeatmapData.isSuccess && processedMapData.isSuccess;
        let queryIsError = processedHeatmapData.isError || processedMapData.isError;

        if (fitComponent)
        {
            return renderHeatmapFitScreen({ queryIsSuccess, queryIsError, data: processedHeatmapData.data });
        } else if (isHeatmapFullscreen)
        {
            return renderHeatmapWidgetMaximized({ queryIsSuccess, queryIsError, data: processedHeatmapData.data });
        } else
        {
            return (
                <DefaultBox
                    className={boxClassName}
                    heading={trans("HeatmapWidget.See_where_visitors_spend_the_most_time_u")}
                >
                    {renderHeatmapWidgetMinimized({ queryIsSuccess, queryIsError, data: processedHeatmapData.data })}
                </DefaultBox>
            );
        }
    }, [
        processedHeatmapData,
        processedMapData,
        isHeatmapFullscreen,
        renderHeatmapWidgetMaximized,
        renderHeatmapWidgetMinimized,
        renderHeatmapFitScreen,
        boxClassName,
        fitComponent,
        trans,
    ]);

    return <React.Fragment>{renderHeatmapWidget()}</React.Fragment>;
};

const HeatmapWidgetLoading = () =>
{
    return (
        <>
            <PlaceholderBox height="100%" width="100%" />
        </>
    );
};

export const HeatmapWidgetMaximized = ({ children, onMinimizeClick }) =>
{
    return (
        <>
            <AccordionFilterSidebar showHeatmapType />
            <div className="heatMapWidgetMaximized">
                <ButtonIcon className="heatmapTrigger" icon="minimize" onClick={onMinimizeClick} />
                {children}
            </div>
        </>
    );
};
