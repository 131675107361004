import { DEFAULT_DASHBOARD, REPORT_WIDGETS_OBJ_FLAG, TOTAL_POSSIBLE_NUMBER_WIDGETS } from "../_constants/dashboard";
import { WIDGET_TYPES } from "../_constants/widgets";
import { searchStringInArray } from "./utils";

export const updateEntry = (currDBs, updatedEntry, activeDashboard) => {
    if (!!updatedEntry.active) {
        currDBs = {
            ...currDBs,
            [activeDashboard._id]: {
                ...activeDashboard,
                active: false,
            },
            [updatedEntry._id]: updatedEntry,
        };
        return currDBs;
    }
    return {
        ...currDBs,
        [updatedEntry._id]: updatedEntry,
    };
};

export const addNewEntry = (currDBs, newEntry, activeDashboard) => {
    if (!!newEntry.active) {
        currDBs = {
            ...currDBs,
            [activeDashboard._id]: {
                ...activeDashboard,
                active: false,
            },
            [newEntry._id]: newEntry,
        };
        return currDBs;
    }
    return {
        ...currDBs,
        [newEntry._id]: newEntry,
    };
};

export const deleteEntry = (curr, deletedEntry) => {
    const copyCurt = { ...curr };
    delete copyCurt[deletedEntry._id];
    return copyCurt;
};

export const parseApiDataToDashboard = (response) => {
    const { success, data: apiResult } = response;
    if (success && Array.isArray(apiResult) && !!apiResult[0] && !!apiResult[0]?.Data) {
        const data = JSON.parse(apiResult[0].Data);
        const formattedData = data.reduce(
            (acc, db) => {
                acc[db._id] = db;
                if (acc[db._id].active && acc[DEFAULT_DASHBOARD._id].active) {
                    acc[DEFAULT_DASHBOARD._id].active = false;
                }
                return acc;
            },
            { [DEFAULT_DASHBOARD._id]: DEFAULT_DASHBOARD }
        );
        return formattedData;
    }

    return { [DEFAULT_DASHBOARD._id]: DEFAULT_DASHBOARD };
};

export const getNumberWidgetsAndGraphWidgets = (widgets) => {
    return widgets.reduce(
        (acc, it, idx) => {
            if (it.type === WIDGET_TYPES.numberWidget) {
                if (acc["numberWidgets"].length < TOTAL_POSSIBLE_NUMBER_WIDGETS) {
                    acc["numberWidgets"].push({ id: it.id });
                }
                return acc;
            }
            acc["widgets"].push({ id: it.id });
            return acc;
        },
        { widgets: [], numberWidgets: [] }
    );
};

export const parseDBStateDataForApiRequest = ({
    userDashboardsMap,
    activeDashboard,
    primaryDashboard,
    makeActiveDashboardAsPrimary,
}) => {
    const copyCurt = JSON.parse(JSON.stringify(userDashboardsMap));
    delete copyCurt[DEFAULT_DASHBOARD._id];
    const { _id, numberWidgets, widgets, name } = activeDashboard;
    if (makeActiveDashboardAsPrimary) {
        if (primaryDashboard._id !== DEFAULT_DASHBOARD._id) {
            copyCurt[primaryDashboard._id].active = false;
        }

        copyCurt[activeDashboard._id] = {
            _id,
            name,
            widgets,
            numberWidgets,
            active: activeDashboard._id !== DEFAULT_DASHBOARD._id ? true : false,
        };

        return Object.values(copyCurt);
    }
    copyCurt[activeDashboard._id] = {
        _id,
        name,
        widgets,
        numberWidgets,
        active: false,
    };
    return Object.values(copyCurt);
};

export const parsePostApiDataToState = (response, oldState) => {
    const { success, data } = response;
    if (success) {
        return JSON.parse(JSON.stringify(data)).reduce(
            (acc, db) => {
                acc[db._id] = db;
                if (acc[db._id].active && acc[DEFAULT_DASHBOARD._id].active) {
                    acc[DEFAULT_DASHBOARD._id] = {
                        ...DEFAULT_DASHBOARD,
                        active: false,
                    };
                }
                return acc;
            },
            { [DEFAULT_DASHBOARD._id]: DEFAULT_DASHBOARD }
        );
    }
    return oldState;
};

export const parseDashboardStateToDeleteEntryForApiRequest = ({ userDashboardsMap, deleteEntryWithId }) => {
    const copyCurt = JSON.parse(JSON.stringify(userDashboardsMap));
    delete copyCurt[DEFAULT_DASHBOARD._id];
    delete copyCurt[deleteEntryWithId];
    return Object.values(copyCurt);
};

export const makeDeleteDisableDeleteOption = ({ _id, isPrimary }) => {
    if (isPrimary) {
        return true;
    }
    if (_id === DEFAULT_DASHBOARD._id) {
        return true;
    }

    return false;
};

export const searchStringInDashboardArray = (searchString, array) => {
    return searchStringInArray(
        searchString,
        array,
        (db, tm) => db?.name.toLowerCase().includes(tm.toLowerCase()) ?? false
    );
};

export const hasReportsWidgetsIn = (numberWidget) => {
    return numberWidget.reduce((acc, it) => acc || (REPORT_WIDGETS_OBJ_FLAG[it.id] ?? false), false);
};

export const getCssBasedOnDashboardName = ({ name, currentClsName = "" }) => {
    if (name.length > 20) {
        return `${currentClsName} fullWidth`;
    }

    return currentClsName;
};

export const searchForWidget = (searchString, array, trans) => {
    return searchStringInArray(searchString, array, (wd, tm) =>
        trans(wd?.title ?? "")
            .toLowerCase()
            .includes(tm.toLowerCase())
    );
};
