import React, { useEffect, useMemo } from "react";
import { paseApiDataToAverageVistTimeApiProps } from "../../_utils/utils";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { calculatePercentageOfChange } from "mapsted.utils/numbers";
import { formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { formatMinsToHHMMSS } from "../../_utils/date.utils";

const AverageVistTimeNumberWidget = ({ isSuccess, isError, dashboardSummaryCompare, trans, dashboardSummary, injectProcessedData }) =>
{
    const avgVisitTimeMins = useMemo(() => dashboardSummary?.avgVisitTimeMins, [dashboardSummary]);
    const averageVisitTime = paseApiDataToAverageVistTimeApiProps(trans, avgVisitTimeMins);
    const connectedPageName = useRecoilValue(connectedPageNameState);
    const percentageChange = calculatePercentageOfChange(dashboardSummary?.avgVisitTimeMins, dashboardSummaryCompare?.avgVisitTimeMins);

    useEffect(() =>
    {
        const payload = {
            name: "Average Visit Time",
            data: [
                {
                    "AverageVisitTime": formatTimeStringsForCSV(averageVisitTime.content, averageVisitTime.extra),
                    "DurationMins": avgVisitTimeMins,
                    "AverageVisitTimeFormatted": formatMinsToHHMMSS(avgVisitTimeMins),
                    "PercentageOfChange": percentageChange
                }
            ],
            description: "",
            inject: injectProcessedData
        };
        injectProcessedData && injectProcessedData(payload);
    }, [avgVisitTimeMins, connectedPageName]);
    if (isSuccess)
    {
        return (
            <OverviewDetailBox
                max="220px"
                popupContent={trans("DashboardOverviewWidget.Average_time_visitors_spent_on_your_prop")}
                icon="time"
                slogan={trans("DashboardOverviewWidget.Average_visit_time")}
                percentage={percentageChange}
                {...averageVisitTime}
            />
        );
    } else if (isError)
    {
        return (
            <OverviewDetailBox
                max="220px"
                popupContent={trans("DashboardOverviewWidget.Average_time_visitors_spent_on_your_prop")}
                icon="time"
                content="--"
                extra={trans("min")}
                slogan={trans("DashboardOverviewWidget.Average_visit_time")}
                percentage="--"
            />
        );
    }

    return <OverviewDetailBoxLoading />;
};

export { AverageVistTimeNumberWidget };
