import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import resources from "../resources.json";
import { LANGUAGES, LANGUAGES_WITH_TEST } from "../constants";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { environmentConstantsState, languageCodeState } from "../../store/AppAtoms";

import serverApi from "../../_api/server.api";
import "./LanguageDropdown.css";

const LanguageDropdown = ({ t: trans, i18n }) =>
{
    const setLanguageCode = useSetRecoilState(languageCodeState);
    const environmentConstants = useRecoilValue(environmentConstantsState);

    useEffect(() =>
    {
        if (serverApi.languageCode)
        {
            changeLanguage(serverApi.languageCode);
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    let AVAILABLE_LANGUAGES = environmentConstants?.SHOW_TEST_LANGUAGE
        ? LANGUAGES_WITH_TEST
        : LANGUAGES;

    const languageOptions = Object.keys(resources).map((lang) =>
    {
        if (Object.keys(AVAILABLE_LANGUAGES).includes(lang))
        {
            const langObj = AVAILABLE_LANGUAGES[lang];
            return ({
                icon: <i className={`${langObj.flag} flag`}></i>,
                text: langObj.title,
                value: langObj.value,
                key: langObj.value,
                // description: <Transition visible={false}><Button onClick={(e) => console.log("MAGICAL")}>Set as default <Icon name="check" /></Button></Transition>
                // TODO: Get default language hovering working in the dropdown
            });
        }
        else
        {
            return null;
        }
    }).filter((val) => val != null);

    const changeLanguage = (lang) =>
    {
        i18n.changeLanguage(lang);
        serverApi.languageCode = lang;
        setLanguageCode(lang);
    }

    return (
        <Dropdown button pointing
            className="languageDropdown"
            icon="angle down"
            onChange={(e, { value }) => changeLanguage(value)}
            header={trans("select language label")}
            options={languageOptions}
            text={i18n.language?.toUpperCase()}
            value={i18n.language}
        >
        </Dropdown>
    );
};

export default withTranslation()(LanguageDropdown);
