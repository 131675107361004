import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SESSION_DURATION_LINE_CHART_OPTIONS } from "../../_constants/chartConstants";
import { formatDurationHMS, getDateGroupAndFormat, processSessionDurationVsTimeWidget } from "../../_utils/chart.utils";
import { convertMinsTo_HHMMSS, formatMinsToHHMMSS } from "../../_utils/date.utils";
import { refetchQuery } from "../../_utils/query.utils";
import { analyticsRequestFilterSelector, activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { LineChart } from "../charts/LineChart";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetPercentageText } from "../common/PercentagePara";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { useEngagementOverviewQuery } from "../../_api/queries";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { formatDateValuesFromChartToCSV, formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";

export const SessionDurationVsTimeWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const { analyticsRequestFilter, } = useRecoilValue(analyticsRequestFilterSelector);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);

    // query  engagement/overview data with current and compare
    const engagementOverviewQuery = useEngagementOverviewQuery();

    // render widget/loading/error
    const processedWidgetData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data: queryData, refetch } = engagementOverviewQuery;
        const { data, compareData } = queryData || {};

        let processedData;

        // process the data
        if (!!isSuccess && data)
        {
            const dateRange = {
                startDate: new Date(analyticsRequestFilter.startTimeUTC),
                endDate: new Date(analyticsRequestFilter.endTimeUTC),
            };

            processedData = processSessionDurationVsTimeWidget({ data, compareData, dateRange, timeZone });
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [engagementOverviewQuery, analyticsRequestFilter, timeZone]);

    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data)
        {
            return [];
        };
        const { lineData, averageDurationFormatted, percentageOfChange } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return lineData?.flatMap(item =>
            item?.data?.map((point) => {
                let formattedStr = formatDurationHMS(point.y);
                return {
                    Date: formatDateValuesFromChartToCSV(point.x , dateGroupFormat),
                    AverageDurationFormatted: convertMinsTo_HHMMSS(point.y),
                    AverageDurationMins: point.y,
                    // percentageOfChange,
                    // content: averageDurationFormatted?.content || "",
                    // format: averageDurationFormatted?.extra || ""}
                }
            })
        );
    }, [processedWidgetData?.data, connectedPageName, analyticsRequestFilter]);
    
    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "What is the average length of time users spend interacting with your app",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "What is the average length of time users spend interacting with your app",
            //     data: engagementOverviewQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport]);

    const customSliceTooltip = React.useCallback(
        ({ slice }) => (
            <div className="widgetsTooltipBox">
                <strong>{slice.points[0].data.x}</strong>
                {
                    // each points data ie. "[point.color] 120 New Visits"
                    slice.points.map((point) => (
                        <div className="tooltipItemRow" key={point.id}>
                            <span style={{ background: point.borderColor }}></span>
                            {point.data.label} {trans("SessionDurationVsTimeWidget.Average_Duration")} &nbsp;
                            <strong>{convertMinsTo_HHMMSS(point.data.y)}</strong>
                        </div>
                    ))
                }
            </div>
        ),
        [trans]
    );

    const renderAverageDurationFormatted = useCallback(
        (averageDurationFormatted) =>
        {
            if (!averageDurationFormatted)
            {
                return <></>;
            }

            const { extra, content } = averageDurationFormatted;

            return content.map((duration, i) => (
                <strong key={duration + extra[i]}>
                    {" "}
                    {duration} {trans(`SessionDurationVsTimeWidget.${extra[i]}`)}
                </strong>
            ));
        },
        [trans]
    );

    const renderSessionDurationVsTimeWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedWidgetData;

        if (isSuccess)
        {
            const { lineData, averageDurationFormatted, percentageOfChange } = data;

            if (!lineData)
            {
                return <NoDataWidgetMessage />;
            }

            return (
                <React.Fragment>
                    <p className="para gap0">
                        {trans("SessionDurationVsTimeWidget.Users_spent")}{" "}
                        {renderAverageDurationFormatted(averageDurationFormatted)},{" "}
                        {trans("SessionDurationVsTimeWidget.on_average__interacting_with_your_app_in")}
                    </p>
                    <WidgetPercentageText percentage={percentageOfChange} />

                    <div className="ChartWrapper">
                        <LineChart
                            data={lineData}
                            options={SESSION_DURATION_LINE_CHART_OPTIONS}
                            customSliceTooltip={customSliceTooltip}
                        />
                    </div>
                </React.Fragment>
            );
        }
        // if error
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedWidgetData)} />;
        }
        else
        {
            return WidgetLoadingBoxReports();
        }
    }, [processedWidgetData, customSliceTooltip, renderAverageDurationFormatted, trans]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={trans("SessionDurationVsTimeWidget.What_is_the_average_length_of_time_users")}
        >
            {renderSessionDurationVsTimeWidget()}
        </DefaultBox>
    );
};
