import React, { useEffect } from "react";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { convertNumberWidgetsDurationContentToMins, formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { formatMinsToHHMMSS } from "../../_utils/date.utils";
const AverageAppSessionLengthNumberWidget = ({ processedWidgetData, sessionLengthValues, injectProcessedData, trans }) =>
{
    const { isSuccess, data } = processedWidgetData;
    const connectedPageName = useRecoilValue(connectedPageNameState);
    useEffect(() =>
    {
        let durationMins = convertNumberWidgetsDurationContentToMins(sessionLengthValues);
        const payload = {
            name: "Average App Session Length",
            data: [{
                "AverageAppSessionLength": formatTimeStringsForCSV(sessionLengthValues?.content, sessionLengthValues?.extra),
                "DurationMins": durationMins,
                "AverageDurationFormatted": formatMinsToHHMMSS(durationMins)
            }],
            description: "",
            inject: injectProcessedData
        };
        injectProcessedData && injectProcessedData(payload);
    }, [sessionLengthValues, connectedPageName]);
    if (!!isSuccess && data !== undefined)
    {
        return (
            <OverviewDetailBox
                max="180px"
                popupContent={trans("ReportsOverviewDetail.Average_App_session_length_for_the_selec")}
                icon="clock"
                content={sessionLengthValues.content}
                extra={sessionLengthValues.extra}
                slogan={trans("ReportsOverviewDetail.App_Session_Length")}
            />
        );
    }
    return <OverviewDetailBoxLoading />;
};

export { AverageAppSessionLengthNumberWidget };
