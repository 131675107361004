import React from "react";
import { Placeholder } from "semantic-ui-react";
import "./Placeholders.css";

export const PlaceholdersCover = () =>
{
    return (
        <>
            <div className="devideMargin">
                <PlaceholderCircular width="200px" height="200px" />
            </div>
            <div className="devideMargin">
                <PlaceholderBox width="200px" height="200px" />
            </div>
            <div className="devideMargin">
                <PlaceholderPara />
            </div>
        </>
    );
}

export const PlaceholderCircular = ({ width, height }) =>
{
    return (
        <div className="placeholderCircular" style={{ width: width, height: height }}>
            <Placeholder className="placeholderCover">
                <Placeholder.Image />
            </Placeholder>
        </div>

    );
}

export const PlaceholderBox = ({ width, height, margin, children, noSpace }) =>
{
    return (
        <div className={`placeholderBox${noSpace ? " noSpace" : ""}`} style={{ width: width, height: height, margin: margin }}>
            <Placeholder className="placeholderCover">
                {children && <div className="children">{children}</div>}
                <Placeholder.Image />
            </Placeholder>
        </div>

    );
}

export const PlaceholderPara = ({ width, height, margin }) =>
{
    return (
        <div className="placeholderBox" style={{ width: width, height: height, margin: margin }}>
            <Placeholder className="placeholderCover">
                <Placeholder.Paragraph>
                    <Placeholder.Line />
                </Placeholder.Paragraph>
            </Placeholder>
        </div>

    );
}

// 

export const WidgetLoadingBoxSmall = () =>
{
    return (
        <>
            <PlaceholderPara height="" margin="0 0 10px" />
            <PlaceholderBox height="250px" />
        </>
    )
}



export const WidgetLoadingBoxMedium = () =>
{
    return (
        <>
            <PlaceholderPara margin="10px 0" height="16px" />
            <PlaceholderBox height="414px" />
        </>
    )
}

export const WidgetLoadingBoxReports = () =>
{
    return (
        <>
            <PlaceholderPara margin="10px 0" height="16px" />
            <PlaceholderBox height="265px" />
        </>
    )
}

export const WidgetLoadingBoxReportsLarge = () =>
{
    return (
        <>
            <PlaceholderPara margin="10px 0" height="16px" />
            <PlaceholderBox height="620px" />
        </>
    )
}

export const WidgetLoadingBoxLarge = () =>
{
    return (
        <PlaceholderBox height="800px" />
    )
}

export const WidgetLoadingBoxFull = ({ children, noSpace }) =>
{
    return (
        <PlaceholderBox noSpace={noSpace} width="100%" height="100%">
            {children}
        </PlaceholderBox>
    )
}