import React, { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { OccupancyBarChartOptions as OccupancyBarChartOptionsConstants } from "../../../_constants/chartConstants";
import { activeTimeZoneSelectorState } from "../../../store/DashboardAtoms";
import { BarChart } from "../../charts/BarChart";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import { WidgetLoadingBoxFull } from "../../common/placeholders/Placeholders";
import { DefaultBox } from "../../dashboard/DefaultBox";
import useOccupancyAnalyticsBar from "./useOccupancyAnalyticsBar";
import { convertMinsTo_HHMMSS } from "../../../_utils/date.utils";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { getWidgetTitle } from "../../../_utils/utils";

/**
 * @deprecated This component is no longer recommended for re-useable this component has been used in multiple places that has been complicating custom logic's .
 * @param {*} param0
 * @returns
 */
export const RenderOccupancyChart = ({
    processedHourlyAverageZoneGeofenceTraffic,
    findZoneGeofenceLabelById,
    zoneGeofenceColorMap,
    trans,
    OccupancyBarChartOptions = OccupancyBarChartOptionsConstants,
    onBarClick = undefined,
    dontUseDefaultTick = false,
    axisLeft = {},
    axisBottom = {},
    legends = [],
    legendLabel = undefined,
    margin = {},
    animate,
    customToolTipText,
    CustomLabelChildren = null,
    customToolTipValueFormatter,
    customToolTipAdditionalTextFn

}) =>
{

    const { isError, isSuccess, data } = processedHourlyAverageZoneGeofenceTraffic;
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);

    const syncOccupancyBarChartOptionsWithProps = {
        ...OccupancyBarChartOptions,
        axisLeft: {
            ...OccupancyBarChartOptions.axisLeft,
            ...axisLeft
        },
        axisBottom: {
            ...OccupancyBarChartOptions.axisBottom,
            ...axisBottom
        },
        legends: !CustomLabelChildren ? [...OccupancyBarChartOptions.legends, ...legends] : [],
        margin: { ...OccupancyBarChartOptions.margin, ...margin },
        legendLabel,
        animate

    };

    const customTick = React.useCallback(
        (tick) =>
        {
            if (tick.tickIndex % 4 === 0 && tick.tickIndex !== 0)
            {
                return (
                    <g transform={`translate(${tick.x},${tick.y + 10})`}>
                        <text
                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {tick.value}
                            {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                        </text>
                    </g>
                );
            }
            else
            {
                return <></>;
            }
        },
        []
    );

    const customTooltip = React.useCallback(
        ({ indexValue, value, color, id /*index, color*/ }) => (
            <div className="tooltipItemRow tooltipItemRowDevice">

                <span style={{ background: color }} />

                {/* zone geofence name */}
                <strong>{findZoneGeofenceLabelById(id)}</strong>

                {/* time   */}
                {indexValue} {timeZone.id}:

                {/* value recorded at time  */}
                <strong>{!!customToolTipValueFormatter && typeof customToolTipValueFormatter === "function" ? customToolTipValueFormatter(value) : value}</strong>{" "}

                {
                    !!customToolTipAdditionalTextFn && typeof customToolTipAdditionalTextFn === "function" ? customToolTipAdditionalTextFn(value) : (customToolTipText)
                        ? customToolTipText
                        : convertMinsTo_HHMMSS(value)

                }
            </div>
        ),
        //eslint-disable-next-line
        [trans, customToolTipText, timeZone, customToolTipAdditionalTextFn, findZoneGeofenceLabelById, customToolTipValueFormatter]
    );

    if (isSuccess && data && zoneGeofenceColorMap)
    {
        const { barChartData, barChartKeys, key } = data;

        if (barChartKeys.length === 0)
        {
            return (
                <div className="errorWidgetWrap">
                    <NoDataWidgetMessage />
                </div>
            );
        }

        return (
            <div className="OccupancyChartContainer">
                <BarChart
                    onClick={onBarClick}
                    key={key}
                    data={barChartData}
                    keys={barChartKeys}
                    index="hour"
                    customTick={dontUseDefaultTick ? customTick : undefined}
                    options={{
                        colors: (bar) => zoneGeofenceColorMap[findZoneGeofenceLabelById(bar.id)],
                        ...syncOccupancyBarChartOptionsWithProps,

                    }}
                    customTooltip={customTooltip}
                    CustomLabelChildren={CustomLabelChildren}
                />
            </div>
        );
    }
    else if (isError)
    {
        return (
            <div className="errorWidgetWrap">
                <ErrorWidgetMessage />
            </div>
        );
    }
    else
    {
        return (
            <WidgetLoadingBoxFull>
                {trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}
                <br /> {trans("Placeholders.Thank_you_for_your_patience_while_we_gat")}{" "}
            </WidgetLoadingBoxFull>
        );
    }
};
const OccupancyAnalyticsBarContainer = (props) =>
{
    const {
        processedHourlyAverageZoneGeofenceTraffic,
        trans,
        zoneGeofenceColorMap,
        findZoneGeofenceLabelById,
    } = useOccupancyAnalyticsBar(props);

    const formattedHourlyVisitsCsvData = useMemo(() =>
    {
        const { data } = processedHourlyAverageZoneGeofenceTraffic;
        if (!Array.isArray(data?.barChartData) || data.barChartData.length === 0)
        {
            return [];
        };

        //const headers = ['Hour', ...data.barChartKeys.map(key => findZoneGeofenceLabelById(key))];
        //headers will be keys(hour,zoneName)
        const rows = data.barChartData.map(entry =>
        {
            const rowObject = { Hour: entry.hour };
            data.barChartKeys.forEach(key =>
            {
                rowObject[findZoneGeofenceLabelById(key)] = entry[key] || 0;
            });
            return rowObject;
        });

        return rows;
    }, [processedHourlyAverageZoneGeofenceTraffic?.data]);

    useEffect(() =>
    {
        let hasData = false; // flag to check if the timestamps actually holds zonegeofence info
        if (formattedHourlyVisitsCsvData.length && Object.keys(formattedHourlyVisitsCsvData[0]).length > 1)
        {
            hasData = true;
        }
        const payload =
        {
            name: "Hourly visits by zone geofence",
            data: hasData ? formattedHourlyVisitsCsvData : [],
            description: "",
        };
        props.injectProcessedData && props.injectProcessedData(payload);

    }, [formattedHourlyVisitsCsvData]);

    const customTooltip = React.useCallback(
        ({ indexValue, value, color, id /*index, color*/ }) => (
            <div className="tooltipItemRow tooltipItemRowDevice">
                <span style={{ background: color }} /> <strong>{findZoneGeofenceLabelById(id)}</strong> {indexValue}
                : <strong>{value}</strong>{" "}
            </div>
        ),
        [trans, findZoneGeofenceLabelById]
    );
    return (
        <DefaultBox
            heading={trans("OccupancyAnalytics.Hourly_Visits")}
            independent
            className="OccupancyChartBoxContainer"
            toolTip={trans("OccupancyAnalytics.Displays_the_time_and_number_of_visits_e")}
            includeDateRange={false}
            includeExportOption={false}

        >
            <div className="hourlyvisitZoneGeofence">
                <RenderOccupancyChart
                    {...{
                        processedHourlyAverageZoneGeofenceTraffic,
                        customTooltip,
                        findZoneGeofenceLabelById,
                        zoneGeofenceColorMap,
                        trans,
                    }}
                />
            </div>
        </DefaultBox>
    );
};

export default OccupancyAnalyticsBarContainer;
