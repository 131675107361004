import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsDestinations } from '../../../_api/queries';
import { processDestinationDataForTopDestinationWidget } from '../../../_utils/chart.utils';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const UseTop20DestinationsWidget = () =>
{
	const destinationQuery = useWebAnalyticsDestinations();

	const processedWidgetData = useMemo(() =>
	{
		let processedData = { pieData: [], maxVisitsCount: 0, maxDestinationName: "" };
		const { isSuccess, data: queryData } = destinationQuery;
		// process the data
		if (!!isSuccess && queryData)
		{
			const { currentIntervalData: data } = queryData || {};
			processedData = processDestinationDataForTopDestinationWidget(data, 20);

		}

		return processedData;
	}, [destinationQuery]);


	return { destinationQuery, processedWidgetData };

};

export default UseTop20DestinationsWidget;