import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { OverviewDetailBox } from "../common/overviewDetailBox/OverviewDetailBox";

const CurrentCapcityNumberWidget = ({ isSuccess, isError, trans }) =>
{
    // useEffect(() =>
    // {
    //     const payload = {
    //         name: "Average Visit Time",
    //         data: {
    //             "AverageVisitTime": averageVisitTime,
    //             "PercentageChange": percentageChange
    //         },
    //         description: "",
    //         inject: injectProcessedData
    //     };
    //     injectProcessedData(payload);
    // }, [avgVisitTimeMins]);
    if (isSuccess)
    {
        return (
            <OverviewDetailBox
                isDisabled
                max="200px"
                popupContent={
                    <>
                        {trans("DashboardOverviewWidget.To_learn_more_about_Current_Capacity_num")}
                        <Button
                            className="button-anchor"
                            onClick={() => window.open("https://mapsted.com")}
                            content={"https://mapsted.com"}
                        />
                    </>
                }
                icon="capacity"
                content="--"
                slogan={trans("DashboardOverviewWidget.Current_capacity")}
            />
        );
    } else if (isError)
    {
        return (
            <OverviewDetailBox
                isDisabled
                max="200px"
                popupContent={
                    <>
                        {trans("DashboardOverviewWidget.To_learn_more_about_Current_Capacity_num")}{" "}
                        <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">
                            https://mapsted.com/corp
                        </a>
                    </>
                }
                icon="capacity"
                content="--"
                slogan={trans("DashboardOverviewWidget.Current_capacity")}
            />
        );
    }

    return (
        <OverviewDetailBox
            isDisabled
            max="200px"
            popupContent={
                <>
                    {trans("DashboardOverviewWidget.To_learn_more_about_Current_Capacity_num")}{" "}
                    <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">
                        https://mapsted.com/corp
                    </a>
                </>
            }
            icon="capacity"
            content="--"
            slogan={trans("DashboardOverviewWidget.Current_capacity")}
        />
    );
};

export { CurrentCapcityNumberWidget };
