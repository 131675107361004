export const TrajectoryColor = {
    "GREEN": "rgba(75,202,110,.5)",
    "ORANGE": "rgba(50, 168, 82, .8)",
    "RED": "rgba(41,138,67,1)",
    "BLUE": "rgb(108,117,184)",
    "BLANK": "rgba(0,0,0,0)",
    "CHART_BLANK": "rgba(220,220,220, .4)",
};

export const SelectedTrajectoryColor = {
    "GREEN": "rgb(75,202,110)",
    "ORANGE": "rgb(50, 168, 82)",
    "RED": "rgb(41,138,67)",
    "BLANK": "rgb(180,180,180)",
};

export const TrajectoryThresholds = {
    "GREEN": 10,
    "ORANGE": 30,
    "RED": 60
};

export const InsightsLowerBound = 10;

export const LineDash = [.5, 5];

// list of user emails only to be shown on mapsted admin accounts
export const AdminEmails = [
    "mapstedtest@pepsico.com"
];

export const InsightsMinDate = new Date("August 1, 2023");

export const InsightsDistanceSignificantDigit = 1;

export const InsightsXTickDistance = 10;
export const InsightsXTickDuration = 2;

export const InsightsTickNumOfTickThreshold = 100; // do not modify (set at 100 x-axis ticks), this is a condition in a while

export const InsightsOverlayOffsetLarge = [-90, -50];

export const InsightsOverlayOffsetSmall = [-105, -50];

export const InsightsToolTips = {
    ZONE_GEOFENCE_TABLE: "Track Zone Geofence durations in this interactive table. Click a row to select the corresponding Zone Geofence on the map.",
    SESSION_TABLE: "Explore session details and specific visits with this table, providing an alternate method for data navigation and analysis.",
    DURATION: "This component displays the visit's total time duration.",
    TOTAL_DISTANCE: "This component displays the total distance covered during a visit.",
    START: "This component displays the start time of the visit.",
    END: "This component displays the end time of the visit.",
    MAP: `This map provides a clear visualization of a visit's trajectory within a property,
     including color-coded dwell points that represent varying durations.
      Hovering over these points on the map reveals the time spent at each location,
       while hovering over the chart synchronizes with the map to highlight the corresponding point,
        offering a comprehensive and interactive experience for analyzing the visit.`,
    LEGEND: "The map's legend provides a visual key to interpret dwell points, with three distinct markers.",
    DISTANCE_CHART: "Visualize time spent at each point on the route with this line chart, enabling a deeper understanding of the correlation between delta duration and cumulative distance.",
    DURATION_CHART: "Visualize time spent at each point on the route with this line chart, enabling a deeper understanding of the correlation between delta duration and cumulative duration.",
};

export const InsightsChartDescription =
{
    Distance: "Displaying delta duration over cumulative distance",
    Duration: "Displaying delta duration over cumulative duration"
};


export const InsightsSessionStatusToIconHash =
{
    UNKNOWN: "black",
    COMPLETED: "green",
    COMPLETED_WITH_ERRORS: "yellow",
    ONGOING: "blue",
    ONGOING_WITH_ERRORS: "purple",
    EMPTY: "yellow",
    INVALID: "red",
    // MERGED: "purple",
    MERGED_ONGOING: "purple",
    TIMEDOUT: "yellow",
    DB_ERROR: "purple",
};

export const InsightsSessionStatusToReadableText = Object.keys(InsightsSessionStatusToIconHash).reduce((acc, key) =>
{
    acc[key] = `${key}`;

    return acc;
}, {});


export const TRAJECTORY_STATUSES_ENUM = Object.freeze({
    UNKNOWN: "UNKNOWN",
    SUCCESS: "SUCCESS",
    NO_STEPS: "NO_STEPS",
    RECONSTRUCTION_ALGO_FAILED: "RECONSTRUCTION_ALGO_FAILED",
    INCOMPLETE_DATA_IN_BUILDING: "INCOMPLETE_DATA_IN_BUILDING",
    ERROR: "ERROR",
    BUILDING_VALIDATION_FAILED: "BUILDING_VALIDATION_FAILED",
    TIMESTAMPS_PROCESSING_FAILED: "TIMESTAMPS_PROCESSING_FAILED",
    RECONSTRUCTION_TIMED_OUT: "RECONSTRUCTION_TIMED_OUT",
    NO_VALID_DATA_FOR_RECONSTRUCTION: "NO_VALID_DATA_FOR_RECONSTRUCTION",
    FAILED_TO_RETRIEVE_ZONEGEOFENCES: "FAILED_TO_RETRIEVE_ZONEGEOFENCES",
    RECONSTRUCTION_TIMED_OUT_WITHOUT_SOLUTION: "RECONSTRUCTION_TIMED_OUT_WITHOUT_SOLUTION",

});

export const InsightsTrajectoryStatusToIcon = Object.freeze({
    [TRAJECTORY_STATUSES_ENUM.UNKNOWN]: "black",
    [TRAJECTORY_STATUSES_ENUM.SUCCESS]: "green",
    [TRAJECTORY_STATUSES_ENUM.NO_STEPS]: "red",
    [TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_ALGO_FAILED]: "red",
    [TRAJECTORY_STATUSES_ENUM.INCOMPLETE_DATA_IN_BUILDING]: "yellow",
    [TRAJECTORY_STATUSES_ENUM.ERROR]: "red",
    [TRAJECTORY_STATUSES_ENUM.BUILDING_VALIDATION_FAILED]: "red",
    [TRAJECTORY_STATUSES_ENUM.TIMESTAMPS_PROCESSING_FAILED]: "red",
    [TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_TIMED_OUT]: "yellow",
    [TRAJECTORY_STATUSES_ENUM.NO_VALID_DATA_FOR_RECONSTRUCTION]: "red",
    [TRAJECTORY_STATUSES_ENUM.FAILED_TO_RETRIEVE_ZONEGEOFENCES]: "red",
    [TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_TIMED_OUT_WITHOUT_SOLUTION]: "red",
    // SUCCESS_LOW_CONFIDENCE: "green",
    // ISSUE_WITH_ENTRANCE_EXIT: "red",
    // ISSUE_WITH_SIMPLIFICATION_ALGO: "red",
    // ISSUE_WITH_SHORTEST_PATH: "red",
    // ISSUE_WITH_RECONSTRUCTION_ALGO: "red",
    // PARTIAL_DATA: "yellow",
});



export const NO_TRAJECTORY_DATA_STATUSES = Object.freeze([
    TRAJECTORY_STATUSES_ENUM.ERROR,
    TRAJECTORY_STATUSES_ENUM.NO_STEPS,
    TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_ALGO_FAILED,
    TRAJECTORY_STATUSES_ENUM.BUILDING_VALIDATION_FAILED,
    TRAJECTORY_STATUSES_ENUM.TIMESTAMPS_PROCESSING_FAILED,
    TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_TIMED_OUT_WITHOUT_SOLUTION,
    TRAJECTORY_STATUSES_ENUM.NO_VALID_DATA_FOR_RECONSTRUCTION,
    TRAJECTORY_STATUSES_ENUM.FAILED_TO_RETRIEVE_ZONEGEOFENCES,
    TRAJECTORY_STATUSES_ENUM.RECONSTRUCTION_TIMED_OUT_WITHOUT_SOLUTION
]);

export const InsightsTrajectoryStatusToReadableText = Object.keys(InsightsTrajectoryStatusToIcon).reduce((acc, key) =>
{
    acc[key] = `${key}`;

    return acc;
}, {});


export const greenColorOpacityVariants = ["rgba(41,138,67,.85)", "rgb(75,202,110,.8)", "rgba(75,202,110,.4)", "rgba(75,202,110,.2)"];
