import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Image } from "semantic-ui-react";
import "./WidgetMessage.css";

export const WidgetMessage = ({ isVertical, isError, isFloat, customMessage, customTitle, customImageSrc, onReloadClick }) =>
{
    const trans = useTranslation().t;

    const displayedTitle = useMemo(() =>
    {
        if (customTitle)
        {
            return customTitle;
        }

        return (isError)
            ? trans("WidgetMessage.An_error_occured_")
            : trans("WidgetMessage.No_data_found_");
    }, [isError, customTitle, trans]);

    const displayedMessage = useMemo(() =>
    {
        if (customMessage)
        {
            return customMessage;
        }

        return (isError)
            ? trans("WidgetMessage.Please_try_again_later_")
            : trans("WidgetMessage.Please_check_back_later_");

    }, [trans, customMessage, isError]);

    const displayedImageSource = useMemo(() =>
    {
        if (customImageSrc)
        {
            return customImageSrc;
        }

        return `/img/icon-${isError ? "error" : "syncing"}-info.svg`;

    }, [isError, customImageSrc]);

    return (
        <div className={`widgetMessage${isError ? " hasError" : ""}${isVertical ? " verticalMessage" : ""}${isFloat ? " isFloat" : ""}`}>

            <Image as="span" src={displayedImageSource} />

            <p>
                <strong>{displayedTitle}</strong>
                {displayedMessage}
            </p>

            {
                (onReloadClick) && (
                    <Button primary content={trans("WidgetMessage.Reload")} onClick={onReloadClick} />
                )
            }
        </div>
    );
}

export const NoDataWidgetMessage = ({ isVertical = true, customMessage, customTitle }) =>
{
    return (
        <WidgetMessage isVertical={isVertical} customMessage={customMessage} customTitle={customTitle} />
    )
}

export const ErrorWidgetMessage = ({ onReloadClick, isVertical = true, }) =>
{
    return (
        <WidgetMessage isVertical={isVertical} isError onReloadClick={onReloadClick} />
    )
}

export const NotAvailableWidgetMessage = () =>
{
    const trans = useTranslation().t;

    return (
        <WidgetMessage isVertical customTitle={trans("WidgetMessage.Please_check_back_later_")} customMessage={trans("WidgetMessage.Please_check_back_later_")} />
    )
}

//examples

/*
    <WidgetMessage /> syncing
    <WidgetMessage isVertical/> syncing vertical

    <WidgetMessage isError /> Error
    <WidgetMessage isError isVertical/> Error vertical
*/
