import React from "react";

const ZoneTrafficInfoText = ({ data, trans }) => {
    const { overallPeakHourTime, overallPeakHourZoneLabel } = data;
    return (
        <p className="para">
            {!!overallPeakHourTime && overallPeakHourZoneLabel && (
                <>
                    <strong>{overallPeakHourTime} </strong>
                    
                    {trans("ZoneTrafficWidget.is_the_most_popular_time_to_visit")}{" "}
                    <strong>{overallPeakHourZoneLabel}</strong> {trans("ZoneTrafficWidget.among_your_visitors")}
                </>
            )}
        </p>
    );
};

export default ZoneTrafficInfoText;
