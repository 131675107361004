import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { environmentConstantsState } from "../../store/AppAtoms";
import { allTimeFilterSelector, analyticsRequestFilterSelector, hasAccessToPropertySelector, activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetPercentageText } from "../common/PercentagePara";
import { useQuery } from "react-query";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { downloadVsTimeWidgetHelper, getDateGroupAndFormat, processDownloadsVsTimeWidget } from "../../_utils/chart.utils";
import { LineChart } from "../charts/LineChart";
import { APP_USAGE_PER_DAY_LINE_CHART_OPTIONS } from "../../_constants/chartConstants";
import serverApi from "../../_api/server.api";
import { AppOnlyTooltip } from "../common/AppOnlyTooltip";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { formatDateValuesFromChartToCSV } from "../../_utils/exportToCsvUtils";

export const DownloadsVsTimeWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const analyticsRequestFilterAllTime = useRecoilValue(allTimeFilterSelector);
    const { analyticsRequestFilter, analyticsRequestCompareFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);

    // query  general/totalDownloads data with current and compare
    const appDownloadsQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS, { analyticsRequestFilterAllTime, analyticsRequestCompareFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {

            const result = await serverApi.getNavigationAnalyticsData({}, NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS, environmentConstants);

            if (!result?.success)
            {
                throw new Error(`${NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS} response was not ok`);
            }


            return result?.data?.appDownloadRecords;
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });

    // render widget/loading/error
    // COMMENTED OUT TO USE WHEN COMPARE DATA IS AVAILABLE
    // const processedWidgetData = useMemo(() =>
    // {
    //     const { isLoading, isSuccess, isError, data: queryData } = appDownloadsQuery;
    //     const { data, compareData } = queryData || {};

    //     let processedData;

    //     // process the data
    //     if (!!isSuccess && data)
    //     {
    //         const dateRange = {
    //             startDate: new Date(analyticsRequestFilter.startTimeUTC),
    //             endDate: new Date(analyticsRequestFilter.endTimeUTC)
    //         }

    //         processedData = processDownloadsVsTimeWidget({ data, compareData, dateRange, timeZone });
    //     }

    //     return { isLoading, isSuccess, isError, data: processedData };
    // }, [appDownloadsQuery]);

    const processedWidgetData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data: queryData, refetch } = appDownloadsQuery;

        let processedData;

        // process the data
        if (!!isSuccess && queryData)
        {
            const dateRange = {
                startDate: new Date(analyticsRequestFilter.startTimeUTC),
                endDate: new Date(analyticsRequestFilter.endTimeUTC)
            };

            const compareDateRange = {
                startDate: new Date(analyticsRequestCompareFilter.startTimeUTC),
                endDate: new Date(analyticsRequestCompareFilter.endTimeUTC)
            };

            const { data, compareData } = downloadVsTimeWidgetHelper({ data: queryData, compareDateRange, dateRange });

            processedData = processDownloadsVsTimeWidget({ data, compareData, compareDateRange, dateRange, timeZone });
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [appDownloadsQuery?.data, analyticsRequestFilter, timeZone, analyticsRequestCompareFilter]);


    const processedDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data)
        {
            return [];
        };
        const { lineData, percentageOfChange, totalDownloads } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return lineData?.flatMap(item =>
            item?.data?.map(point => ({
                Date: formatDateValuesFromChartToCSV(point.x, dateGroupFormat),
                Downloads: point.y,
                // avgSessionsOpensPerUser,
                // percentageOfChange,
            }))
        );
    }, [processedWidgetData?.data, connectedPageName, analyticsRequestFilter]);

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "How are consumers responding to your brand and marketing strategy",
                data: processedDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "How are consumers responding to your brand and marketing strategy",
            //     data: appDownloadsQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];
        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedDataForExport]);


    const customSliceTooltip = React.useCallback(({ slice }) => (
        <div className="widgetsTooltipBox">
            <strong>
                {slice.points[0].data.x}
            </strong>
            {
                // each points data ie. "[point.color] 120 New Visits"
                slice.points.map((point) => (
                    <div className="tooltipItemRow" key={point.id}>
                        <span style={{ background: point.borderColor }}></span>
                        {point.data.label} {trans("DownloadsVsTimeWidget.downloads")}
                        <strong>{`${point.data.y}`}</strong>
                    </div>
                ))
            }
        </div>
    ), [trans]);

    const renderDownloadsVsTimeWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedWidgetData;

        if (isSuccess && data)
        {
            const { lineData, totalDownloads, percentageOfChange } = data;

            if (!lineData)
            {
                return <NoDataWidgetMessage />;
            }

            return (
                // change to template
                <React.Fragment>
                    <p className="para gap0"> <strong> {totalDownloads} </strong> {trans("DownloadsVsTimeWidget.Unique_Users_downloaded_your_app_in_the_")} </p>
                    <WidgetPercentageText percentage={percentageOfChange} />
                    {/* <Image as="span" src="/img/chart-reports-users.svg" alt="" /> */}
                    <div className="ChartWrapper">
                        <LineChart
                            data={lineData}
                            options={APP_USAGE_PER_DAY_LINE_CHART_OPTIONS}
                            customSliceTooltip={customSliceTooltip}
                        />
                    </div>
                </React.Fragment>
            );
        }
        // if error
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedWidgetData)} />;
        }
        else
        {
            return WidgetLoadingBoxReports();
        }
    }, [processedWidgetData, customSliceTooltip, trans]);


    return (
        <DefaultBox className="alignedWidget" heading={trans("DownloadsVsTimeWidget.How_are_consumers_responding_to_your_bra")} extraHead={<AppOnlyTooltip />}>
            {
                renderDownloadsVsTimeWidget()
            }
        </DefaultBox>
    );
};
