/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Modal, Button, Image } from "semantic-ui-react";

export const PermissionModal = ({ onLogout, onRedirectToHub }) =>
{
    return (
        <Modal open className="modalWelcome">
            <Modal.Header></Modal.Header>
            <Modal.Content>
                <Image src="/img/logo-symbol.svg" as="span" />
                <p>You are not authorized to use this product. <br />
                Please contact your plan administrator to renew your license or purchase a new one.</p>
            </Modal.Content>

            <Modal.Actions>
                <Button primary color="orange" content="Log out" onClick={onLogout} />
                <Button primary color="orange" content="Redirect to Hub" onClick={()=>onRedirectToHub()} />
            </Modal.Actions>
        </Modal>
    );
}