import React from "react";
import { PIE_CHART_ARC_LINK_CHAR_LIMIT, SERVICE_PROVIDER_OPTIONS } from "../../../_constants/chartConstants";
import { PieChart } from "../../charts/PieChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import ServiceProviderInfoText from "./ServiceProviderInfo.text";

const ServiceProviderPieChart = ({ data, trans }) => {
    const { maxServiceProvider, serviceProviderPieData } = data;

    const customTooltip = React.useCallback(
        ({ datum }) => {
            const { id, value, color, data } = datum;
            return (
                <div className="tooltipItemRow tooltipItemProvider">
                    <span style={{ background: color }} /> {id}: <strong>{value}</strong>
                    {Number(value) === 1
                        ? trans("ServiceProviderWidget.User")
                        : trans("ServiceProviderWidget.Users")} - <strong>{data.percentage}%</strong>
                </div>
            );
        },
        [trans]
    );

    const customArcLinkLabel = React.useCallback((data) => {
        let { label } = data;

        if (label.length > PIE_CHART_ARC_LINK_CHAR_LIMIT) {
            return label.substring(0, PIE_CHART_ARC_LINK_CHAR_LIMIT) + "...";
        } else {
            return label;
        }
    }, []);

    if (serviceProviderPieData.length === 0) {
        return <NoDataWidgetMessage />;
    }
    return (
        <>
            <ServiceProviderInfoText {...{ maxServiceProvider, trans }} />
            <div className="UsedDevicesWidget">
                <PieChart
                    data={serviceProviderPieData}
                    options={SERVICE_PROVIDER_OPTIONS}
                    customArcLinkLabel={customArcLinkLabel}
                    customTooltip={customTooltip}
                />
            </div>
        </>
    );
};

export default ServiceProviderPieChart;
