import { toMercatorFromArray } from "mapsted.maps/utils/map.utils";
import { deepValue } from "mapsted.utils/objects";
import { atom, selector } from "recoil";
import { propertyIdState, propertyInfoMapState } from "./DashboardAtoms";


export const entityLayersState = atom({
    key: "entityLayersState",
    default: [],
    dangerouslyAllowMutability: true,
});

export const heatmapLayerState = atom({
    key: "heatmapLayerState",
    default: undefined,
    dangerouslyAllowMutability: true,

});

export const boostDeviceInfoLayerState = atom({
    key: "boostDeviceInfoLayerState",
    default: undefined,
    dangerouslyAllowMutability: true,

});

export const zoomLevelState = atom({
    key: "zoomLevelState",
    default: 4
});

export const pointSpacingState = atom({
    key: "pointSpacingState",
    default: undefined,
});

export const floorPaginiationNumberState = atom({
    key: "floorPaginiationNumberState",
    default: 0
});

export const selectedUserState = atom({
    key: "selectedUserState",
    default: undefined,
    dangerouslyAllowMutability: true,
});

export const selectedInteractionState = atom({
    key: "selectedInteractionState",
    default: undefined,
    dangerouslyAllowMutability: true,
});

export const mapOptionsState = atom({
    key: "mapOptionsState",
    default: {
        names: {
            name: "names",
            value: true,
            styles: {
                offset: [-18, 75],
            }
        },
        overlays: {
            name: "overlays",
            value: false,
            styles: {
                offset: [-18, 133],
            }
        }
    }
});

export const zoneGeofenceMapOptionsState = atom({
    key: "zoneGeofenceMapOptionsState",
    default: {
        names: {
            name: "names",
            value: true,
            styles: {
                offset: [-18, 75],
            }
        },
        overlays: {
            name: "overlays",
            value: false,
            styles: {
                offset: [-18, 133],
            }
        },
        zoneGeofence: {
            name: "zoneGeofence",
            value: true,
            styles: {
                offset: [-18, 147],
            }
        }
    }
});


export const heatMapOptionsState = atom({
    key: "heatmapOptionsState",
    default: {
        names: {
            name: "names",
            value: true,
            styles: {
                offset: [-18, 75],
            }
        },
        overlays: {
            name: "overlays",
            value: false,
            styles: {
                offset: [-18, 130],
            }
        },
        style: {
            name: "style",
            value: true,
            styles: {
                offset: [-18, 108],
            }
        }
    }
});

export const deviceMapOptionState = atom({
    key: "deviceMapOptionState",
    default: {
        names: {
            name: "names",
            value: true,
            styles: {
                offset: [-18, 75],
            }
        },
        overlays: {
            name: "overlays",
            value: false,
            styles: {
                offset: [-18, 133],
            }
        },
    }
});

export const selectedZoneGeofenceHashState = atom({
    key: "selectedZoneGeofenceHashState",
    default: {},
    dangerouslyAllowMutability: true
});

export const mapDataState = atom({
    key: "mapDataState",
    default: {}
});

export const zoneGeofenceColorAndTemplateInfoState = atom({
    key: "zoneGeofenceColorAndTemplateInfoState",
    default: {}
});

/**
 * @summary Returns a map of zonegeofenceHash with color in most of the boost and tags modules and in other places zonegeofencecolors are connected to other ui elements like bar charts etc....
 *
 *
 * @example {
 * [zonegoefenceLabelName]:color
 * }
 */
export const zoneGeofenceToColorMapSelector = selector({
    key: "zoneGeofenceToColorMapSelector",
    get: ({ get }) =>
    {
        const zoneGeofenceToColorMap = get(zoneGeofenceColorAndTemplateInfoState);
        const zoneGeofenceToColorMapValues = zoneGeofenceToColorMap && Object.entries(zoneGeofenceToColorMap).reduce((acc, [key, value]) =>
        {
            acc[key] = value.color;
            return acc;
        }, {}) || {};
        return zoneGeofenceToColorMapValues;
    }
});

export const zoneGeofenceActiveHistoryState = atom({
    key: "zoneGeofenceActiveHistoryState",
    default: null
});

export const propertyCentroidMercatorSelector = selector({
    key: "propertyCentroidMercatorSelector",
    get: ({ get }) =>
    {
        const propertyId = get(propertyIdState);
        const propertyInfoMap = get(propertyInfoMapState);

        let centroid = deepValue(propertyInfoMap, `${propertyId}.centroid.coordinates`, undefined);

        if (centroid)
        {
            centroid = toMercatorFromArray(centroid);
        }
        return centroid;
    }
});


export const selectedPointsState = atom({
    key: "selectedPointsState",
    default: {}
});


export const isZoneMapLoadedState = atom({
    key: "isZoneMapLoadedState",
    default: false
});

export const mapOverlaysState = atom({
    key: "mapOverlaysState",
    default: undefined
});

export const hoveredPointIdState = atom({
    key: "hoveredPointIdState",
    default: undefined,
});

export const activeHoverPointFeatureState = atom({
    key: "activeHoverFeaturePoint",
    default: null

});

export const zoneGeoFenceMapToggleLayersVisibilityState = atom({
    key: "zoneGeoFenceMapToggleLayersVisibilityState",
    default: {}
});


export const tagIdsWithDataState = atom({
    key: "tagIdsWithDataState",
    default: []
});
