import { DateTime } from "luxon";
import { roundWithPrecision } from "mapsted.utils/numbers";
import { PI_CHART_THRESHOLD_PERCENTAGE_CONSTANTS } from "../_constants/chartConstants";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import serverApi from "../_api/server.api";
import { ALL_WIDGETS } from "../_constants/widgetFixers";

export const processFilterValues = (filterValues = {}) =>
{
    let { screenResolutions } = filterValues;
    //screenResolutions to mapping `${width}x${height}`:{...info}
    if (Array.isArray(screenResolutions))
    {
        let screenResolutionMap = {};

        screenResolutions.forEach((screenResolution) =>
        {
            const { screenPxWidth, screenPxHeight } = screenResolution;

            // ignore any 0 values
            if (screenPxHeight === 0 || screenPxWidth === 0)
            {
                return;
            }

            let key = `${screenPxWidth} x ${screenPxHeight}`;

            screenResolutionMap[key] = screenResolution;
        });

        filterValues.screenResolutions = screenResolutionMap;
    }

    return filterValues;
};

/**
 * Calculates the percentage of value over sum
 * @param {*} value
 * @param {*} sum
 * @returns {Number}
 */
export const calcualtePercentage = (value, sum) =>
{
    if (value === 0)
    {
        return 0;
    }

    return roundWithPrecision((value / sum) * 100);
};

/**
 * Returns whether or not filter is included in string.
 * @param {String} string
 * @param {String} filter
 */
export const doesStringIncludeFilter = (string, filter) => string.toLowerCase().includes(filter.toLowerCase());

export const filterToUrlParams = (filter, apiKey) =>
{
    filter.Api_key = apiKey;

    let urlPrams = "?";

    Object.keys(filter).forEach((param) =>
    {
        if (filter[param])
        {
            urlPrams += `${param}=${filter[param]}&`;
        }
    });

    urlPrams = urlPrams.slice(0, -1);

    return urlPrams;
};

export const sortDropdownCaseInsensitive = (
    options,
    key,
    selectedOption,
    selectedOptionKey
) =>
{
    if (!Array.isArray(options))
    {
        return options;
    }

    return options.sort((o1, o2) =>
    {
        // put selected option on top if selected item exists
        if (!!selectedOption && !!selectedOptionKey)
        {
            if (o1[selectedOptionKey] === selectedOption)
            {
                return -1;
            }
            else if (o2[selectedOptionKey] === selectedOption)
            {
                return +1;
            }
        }

        // if neither option is selected, sort alphabetically
        return o1[key].toLowerCase().localeCompare(o2[key].toLowerCase());
    });
};

/**
 * Takes number of minutes and returns {hours, mins, seconds}
 *
 * @param {*} mins
 */
export const breakDownMinsToHMS = (mins) =>
{
    let result = { hours: 0, mins: 0, seconds: 0 };
    if (mins >= 60)
    {
        let avgVisitTimeHours = Math.floor(mins / 60);
        result.hours = avgVisitTimeHours;

        mins = mins - avgVisitTimeHours * 60;
    }

    let remainder = mins % 1;
    let seconds = remainder ? Math.round(60 * remainder) : 0;

    mins = Math.floor(mins);

    result.mins = mins;
    result.seconds = seconds;

    return result;
};

/**
 * Takes number of hours and returns {weeks, days, hours}
 * @param {*} hours
 */
export const breakDownHoursToWDH = (hours) =>
{
    let result = { weeks: 0, days: 0, hours: 0 };

    let days = hours / 24;

    if (days > 0)
    {
        let weeks = days / 7;

        if (weeks > 0)
        {
            result.weeks = Math.floor(weeks);

            days = days - result.weeks * 7;
        }

        result.days = Math.floor(days);
        hours = hours - result.days * 24;
    }

    result.hours = Math.floor(hours);

    return result;
};
/**
 *
 * @param {Object} trans
 * @param {number} avgVisitTimeMins
 * @returns {
        content: avgVisitTimeContent,
        extra: avgVisitTimeExtra,
    }
 */
export const paseApiDataToAverageVistTimeApiProps = (
    trans,
    avgVisitTimeMins
) =>
{
    let avgVisitTimeContent;
    let avgVisitTimeExtra;
    if (avgVisitTimeMins >= 60)
    {
        let avgVisitTimeHours = Math.floor(avgVisitTimeMins / 60);
        avgVisitTimeMins = avgVisitTimeMins - avgVisitTimeHours * 60;

        avgVisitTimeContent = [avgVisitTimeHours, Math.round(avgVisitTimeMins)];
        avgVisitTimeExtra = [
            trans("DashboardOverviewWidget.hr"),
            trans("DashboardOverviewWidget.min"),
        ];
    }
    else
    {
        let remainder = avgVisitTimeMins % 1;
        let avgVisitTimeSecs = remainder ? Math.round(60 * remainder) : 0;

        avgVisitTimeMins = Math.floor(avgVisitTimeMins);

        avgVisitTimeContent = [avgVisitTimeMins, avgVisitTimeSecs];
        avgVisitTimeExtra = [
            trans("DashboardOverviewWidget.min"),
            trans("DashboardOverviewWidget.sec"),
        ];
    }

    return {
        content: avgVisitTimeContent,
        extra: avgVisitTimeExtra,
    };
};

export const convertSearchParamsToObject = (
    searchParams,
    valueParserFn = undefined
) =>
{
    if (valueParserFn)
    {
        return Object.entries(Object.fromEntries([...searchParams])).map(
            ([key, value]) => valueParserFn(key, value)
        );
    }

    return Object.fromEntries([...searchParams]);
};

export const searchStringInArray = (searchString, array, searchFunction) =>
{
    const terms = searchString.split(" ");
    if (terms.length === 0)
    {
        return array;
    }

    if (searchFunction)
    {
        return array.filter((item) => terms.every((tm) => searchFunction(item, tm)));
    }

    return array.filter((o) => terms.every((tm) => o.toLowerCase().includes(tm.toLowerCase())));
};

/**
 *
 * @param {Array} array
 * @returns
 */
export const shuffleArray = (array) =>
{
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0)
    {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
};

/**
 *
 * @param {*} mins
 * @param {*} languageCode
 * @returns {Object} {
        value,
        units
    }
 */
export const getDurationInReadAbleUnits = (
    mins = 0,
    languageCode = "en-US"
) =>
{
    const shortTimeValues = {
        minutes: "mins",
        hours: "hr",
        days: "days",
        weeks: "wk",
        months: "mnth",
        years: "yr",
    };
    let units = [];

    const dummyDateTime = DateTime.now({ local: languageCode }).plus({
        minutes: mins,
    });

    const duration = DateTime.now()
        .plus({ minutes: mins })
        .diff(DateTime.now());
    const diffInMins = duration.as("minutes");
    units = ["minutes"];

    if (diffInMins >= 60)
    {
        const diffInHours = duration.as("hours");
        units.push("hours");
        if (diffInHours >= 24)
        {
            const diffInDays = duration.as("days");
            units.push("days");
            if (diffInDays >= 7)
            {
                units.push("weeks");
                const diffInWeeks = duration.as("weeks");
                if (diffInWeeks >= 4)
                {
                    const diffInMonths = duration.as("months");
                    units.push("months");
                    if (diffInMonths >= 12)
                    {
                        units.push("years");
                    }
                }
            }
        }
    }

    const dateTimeObj = dummyDateTime
        .diff(DateTime.now(), units, { local: languageCode })
        .toObject();

    return {
        value: Object.values(dateTimeObj).map((v) => roundWithPrecision(v, 0)),
        units: Object.keys(dateTimeObj).map((v) => shortTimeValues[v]),
    };
};

/**
 *
 * @param {Number} value
 * @param {String} languageCode
 * @returns
 */
export const getNumberToAbbreviatedNumberByLang = (
    value = 0,
    languageCode = "en-US"
) => Intl.NumberFormat(languageCode, {
    notation: "compact",
    maximumFractionDigits: 1,
}).format(value);

/**
 *
 * @param {Array} data
 * @param {string} valueKey
 * @param {Number} total
 * @param {Number} threshold
 * @returns {filteredData, skippedItems, skippedItemsTotal,skippedItemsPercentage}
 */
export const prepareFilteredDataWithPercentageThreshold = (
    data = [],
    valueKey,
    total,
    threshold = PI_CHART_THRESHOLD_PERCENTAGE_CONSTANTS
) =>
{
    let skippedItemsTotal = 0;
    let filteredData = [];
    let skippedItems = [];
    let preparedTotal = total;
    if (data?.length > 0)
    {
        if (preparedTotal === undefined || preparedTotal === 0)
        {
            preparedTotal = data.reduce((acc, i) => acc + +i[valueKey], 0);
        }

        data.forEach((item) =>
        {
            const percentage = calcualtePercentage(
                item[valueKey],
                preparedTotal
            );
            if (percentage >= threshold)
            {
                filteredData.push({
                    ...item,
                    percentage: roundWithPrecision(percentage),
                });
            }
            else
            {
                skippedItemsTotal += Number(item[valueKey]);
                skippedItems.push({
                    ...item,
                    percentage: roundWithPrecision(percentage),
                });
            }
        });
    }

    return {
        filteredData,
        skippedItems,
        skippedItemsTotal,
        skippedItemsPercentage: preparedTotal
            ? roundWithPrecision(
                calcualtePercentage(skippedItemsTotal, preparedTotal)
            )
            : 0,
    };
};

// From http://www.tannerhelland.com/4435/convert-temperature-rgb-algorithm-code/
// Start with a temperature, in Kelvin, somewhere between 1000 and 40000
/**
 *
 * @param {Number} kelvin
 * @returns {r:Number,g:Number,b:Number}
 */
export const colorTemperatureToRGB = (kelvin) =>
{
    var temp = kelvin / 100;

    var red, green, blue;

    if (temp <= 66)
    {
        red = 255;

        green = temp;
        green = 99.4708025861 * Math.log(green) - 161.1195681661;

        if (temp <= 19)
        {
            blue = 0;
        }
        else
        {
            blue = temp - 10;
            blue = 138.5177312231 * Math.log(blue) - 305.0447927307;
        }
    }
    else
    {
        red = temp - 60;
        red = 329.698727446 * Math.pow(red, -0.1332047592);

        green = temp - 60;
        green = 288.1221695283 * Math.pow(green, -0.0755148492);

        blue = 255;
    }

    return {
        r: clamp(red, 0, 255),
        g: clamp(green, 0, 255),
        b: clamp(blue, 0, 255),
    };
};

export const clamp = (x, min, max) =>
{
    if (x < min)
    {
        return min;
    }
    if (x > max)
    {
        return max;
    }

    return x;
};

/**
 *
 * @param {import("react").JSXElementConstructor} jsx
 * @returns {string}
 */
export const convertJSX_TO_HTMLString = (jsx) =>
{
    const div = document.createElement("div");
    const root = createRoot.bind(React)(div);
    flushSync(() =>
    {
        root.render(jsx);
    });

    return div.innerHTML;
};

export const createData64LinkForSVG = (string) => "data:image/svg+xml;utf8," + string;

/**
 *
 * @param {import("react").JSXElementConstructor} jsx
 * @returns {string}
 */
export const convertJSX_SVG_TO_data64Link = (jsx) => createData64LinkForSVG(convertJSX_TO_HTMLString(jsx));

/**
 *
 * @param {Element Ref} ref   --> takes the entire element to get captured in screenshot
 */
export const generatePdf = (ref, page) =>
{
    if (ref.current)
    {
        const standardHeight = 1080;
        const standardWidth = 1920;
        let htmlWidth = standardWidth;
        let htmlHeight = standardHeight;

        let isPortrait;

        switch (page)
        {
            case "dashboard": {
                // portrait
                htmlHeight = standardHeight * 2;
                isPortrait = true;
                break;
            }
            case "reports": {
                // portrait
                htmlHeight = standardHeight * 4;

                isPortrait = true;
                break;
            }
            case "engagement": {
                // portrait
                htmlHeight = standardHeight * 2;
                isPortrait = true;
                break;
            }
            default: {
                // landscape
                htmlWidth = standardWidth;
                htmlHeight = standardHeight;
                isPortrait = false;
                break;
            }
        }

        // calculating the orientation dynamically instead of a3 or a4
        const doc = new jsPDF(isPortrait ? "p" : "l", "px", [
            htmlWidth,
            htmlHeight,
        ]);

        const pageCount = Math.ceil(htmlHeight / htmlHeight) - 1;

        html2canvas(ref.current, {
            allowTaint: false,
            width: htmlWidth,
            height: htmlHeight,
            onclone: (doc, html) =>
            {
                if (!isPortrait)
                {
                    html.style.height = `${standardHeight}px`;
                    html.style.width = `${standardWidth}px`;
                }
            },
        }).then((canvas) =>
        {
            const image = canvas.toDataURL("image/png", 1.0);
            doc.addImage(image, "png", 15, 15, htmlWidth, htmlHeight);
            // add the new page if html content is more then standard pdf page height
            for (let i = 1; i <= pageCount; i++)
            {
                doc.addPage();
                doc.addImage(
                    image,
                    "png",
                    15,
                    isPortrait ? -(htmlHeight * i) + 15 : 15,
                    htmlWidth,
                    htmlHeight
                );
            }

            doc.save("mapsted_analytics_view.pdf");
        });
    }
};

export const celsiusToFahrenheit = (celsius) =>
{
    const fahrenheit = (celsius * 9) / 5 + 32;
    return fahrenheit;
};

/**
 * Retrieves the long name of a building based on the given property ID and building ID.
 *
 * @param {string} propertyId - The ID of the property.
 * @param {string} buildingId - The ID of the building.
 * @param {object} propertyInfoMap - The map containing property information.
 * @return {string} The long name of the building.
 */
export const getBuildingName = (propertyId, buildingId, propertyInfoMap) => propertyInfoMap[propertyId]?.buildings[buildingId]?.longName;




// Function to save data to localStorage
export const saveToLocalStorage = (keyName, data) =>
{
    // Convert the array of objects to a JSON string
    const jsonString = JSON.stringify(data);

    // Save the JSON string to localStorage with a specific key.
    localStorage.setItem(keyName, jsonString);

};

export const getFromLocalStorage = (keyName) =>
{
    // Retrieve the JSON string from localStorage with the specific key.
    const jsonString = localStorage.getItem(keyName);

    // Parse the JSON string back to an array of objects
    const data = JSON.parse(jsonString);

    return data;
};

/**
 * @deprecated don't use this method dont clear entire data be specific what you want to clear do it from server api
 */
export const clearLocalStorageItem = () =>
{
    const dashbaordData = serverApi.dashboardData;
    const timeZOne = serverApi.activePropertyTimeZone;
    // const languageCode = serverApi.languageCode;
    localStorage.clear();
    // serverApi.languageCode = languageCode;
    serverApi.dashboardData = dashbaordData;
    serverApi.activePropertyTimeZone = timeZOne;
};

/**
 *
 * Removes any whitespace
   Capitalizes first letter
 * @param {String} name
 * @returns String
 */
export const formatName = (name) =>
{
    name = name.trim();

    if (!name)
    {
        return undefined;
    }

    //https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript/31278078
    return name.toLowerCase().replace(/\b(\s\w|^\w)/g, function (txt)
    {
        return txt.toUpperCase();
    });
};

/**
 * Check if the input object is empty.
 *
 * @param {Object} obj - The object to be checked.
 * @return {boolean} Returns true if the object is empty, false otherwise.
 */
export const isEmptyObject = (obj) => Object.entries(obj).length === 0;

/**
 * 
 * @param {String} id 
 * @returns {string} Gives the updated name with no special char for Dashboard widget's title
 */
export const getWidgetTitle = (t, id) => 
{
    let title = ALL_WIDGETS.find(widget => widget.id === id).title;
    return t(title).replace(/[^\w\s]/gi, '');
};
