import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { companyTeamsState, selectedTeamPropertyListState, selectedTeamState } from "../../../store/AppAtoms";
import { useTranslation } from "react-i18next";
import { DropdownPopup } from "../subheader/DropdownPopup";

export const TeamsDropdown = () => {

    const { t } = useTranslation();
    const companyTeams = useRecoilValue(companyTeamsState);
    const [selectedTeam, setSelectedTeam] = useRecoilState(selectedTeamState);
    const setSelectedTeamPropertyList = useSetRecoilState(selectedTeamPropertyListState);

    /**
     * 
     */
    const companyTeamsOptions = useMemo(() =>
    {
        let options = [];
        
        if (Array.isArray(companyTeams))
        {
            companyTeams.forEach((team) =>
            {
                options.push({
                    key: team.id,
                    text: team.name,
                    value: team.id
                });
            });
        }

        return options;
    },[companyTeams]);

    const handleSelectedTeamChange = useCallback((team) =>
    {
        if (selectedTeam === team)
        {
            team = undefined;
        }
        setSelectedTeam(team);
    },[selectedTeam, setSelectedTeam]);

    useEffect( () =>
    {
        let propertyList = undefined;
        
        if (selectedTeam && Array.isArray(companyTeams))
        {
            let team = companyTeams.find(t => t.id === selectedTeam);
            if (team?.properties)
            {
                propertyList = team?.properties.map((property)=> property.id); 
            }
        }

        setSelectedTeamPropertyList(propertyList);
    },[selectedTeam,companyTeams, setSelectedTeamPropertyList]);

    const renderTeamsDropdown = useCallback(() =>
    {
        if  (companyTeamsOptions.length === 0)
        {
            return (<></>);
        }

        return (
            <DropdownPopup
                placeholder={t("Words.Search_Teams")}
                noOptionSelectedText={"No Team Selected"}
                options={companyTeamsOptions}
                value={selectedTeam}
                onChange={handleSelectedTeamChange}
            />
        )
    },[companyTeamsOptions,selectedTeam, handleSelectedTeamChange,t]);

    return (
    
        <div className="userSessionDropdown">
            <p>{t("Words.Teams")}:</p>
            {renderTeamsDropdown()}
        </div>
    );
};
    