import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Checkbox, Header, Image, Input, Tab } from "semantic-ui-react";
import { ModalLayout } from "../modalLayout/ModalLayout";
import Translation from "../translation/Translation";
import useCsvExportEventEmitter from "./useCsvExportEventEmitter";
import { capitalize } from "lodash";
import { PaginationBar } from "../../pages/flows/userExplorer/PaginationBar";
import useExportDataPaginator from "./useExportDataPaginator";
import { WidgetMessage } from "../WidgetMessage/WidgetMessage";
import "./exportCsv.css";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { activePageDataExportHandlersState } from "../../../store/ExportToCsvAtoms";
export const CsvWidgetsBasedOnDataType = ({ dataItemsHash, onClick, dataType, thumbnailUrl = "" }) =>
{
    const trans = useTranslation().t;
    const activePageDataExportHandlersValue = useRecoilValue(activePageDataExportHandlersState);

    const renderCards = useCallback(() =>
    {

        if (dataItemsHash?.length > 0)
        {
            return dataItemsHash.map((widget) =>
            {

                return (
                    <Card
                        key={widget.id}
                        title={trans(widget.name)}
                        onClick={(e) => onClick(e, dataType, { name: widget.name, isSelected: !widget.isSelected })}
                    >
                        <Card.Content >
                            <Checkbox
                                checked={widget.isSelected}
                                onChange={(e) => onClick(e, dataType, { name: widget.name, isSelected: !widget.isSelected })}
                            />
                            {thumbnailUrl && <Image floated="left" size="medium" src={thumbnailUrl} />}
                            <Card.Description floated="right" className="flex">
                                <div className="text-ellipsis" >
                                    <Translation text={widget.name} />
                                </div>
                                {widget?.isWidgetDataEmpty && <Translation text="no data" />}
                            </Card.Description>
                        </Card.Content>
                    </Card>
                );
            });
        }

        return <WidgetMessage />;

    }, [dataItemsHash]);

    return (
        <div className="scroll">
            <Card.Group>
                {renderCards()}
            </Card.Group>
        </div>
    );
};

const CsvExportWidgetSelectorModal = (props) =>
{
    const { t } = useTranslation();
    const { onToggleDataSelectionModal, isDataSelectionModalOpen, isUserHasSelectedAnyWidgetToExportData, onSelectOrUnSelect, widgetSearchFilter, onSearch, onExportData, widgetsToDisplay, selectAll, allWidgetsChecked, debouncedWidgetSearchFilter } = useCsvExportEventEmitter();

    const { paginatedData, paginationHelper, activePage, itemsPerPage, handleItemsPerPageChange, handlePageChange, setActiveTabIndex } = useExportDataPaginator(widgetsToDisplay, debouncedWidgetSearchFilter);

    const { hasWidgets, isCheckboxDisabled, shouldShowUnselectAll } = useMemo(() =>
    {
        // Extract entries from widgetsToDisplay
        const entries = Object.entries(widgetsToDisplay);
        const hasEntries = entries.length > 0;

        // Extract first widget keys if entries exist
        const firstKeys = hasEntries ? Object.keys(entries[0][1]) : [];
        const hasKeys = firstKeys.length > 0;

        return {
            widgetsToDisplayEntries: entries,
            hasWidgets: hasEntries,
            firstWidgetKeys: firstKeys,
            hasFirstWidgetKeys: hasKeys,
            isCheckboxDisabled: !hasEntries || !hasKeys,
            shouldShowUnselectAll: allWidgetsChecked && hasEntries && hasKeys
        };
    }, [widgetsToDisplay, allWidgetsChecked]);


    const renderPagginationBar = useCallback(() =>
    {
        return (
            <PaginationBar
                totalPages={paginationHelper?.totalPages || 1}
                activePage={activePage}
                itemsPerPage={itemsPerPage}
                totalItems={paginationHelper?.totalItems}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                isUserExplorer={false}
            />
        );
    }, [activePage, itemsPerPage, paginationHelper]);

    const panes = useMemo(() =>
    {
        return paginatedData.map(([dataType, dataItemsHash]) =>
        {
            return {
                menuItem: dataType,
                render: () =>
                {
                    return <CsvWidgetsBasedOnDataType dataType={dataType} dataItemsHash={dataItemsHash} onClick={onSelectOrUnSelect} />;
                }
            };
        });
    }, [paginatedData]);
    const renderWidgetsDataTyepeTab = useCallback(() =>
    {
        return (
            <Tab className="linkedTabs widgetTabs"
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                onTabChange={(e, data) =>
                {
                    setActiveTabIndex(data.activeIndex);
                }}
            />
        );
    }, [panes]);


    return (
        <ModalLayout
            className="selectWidget fixedHeight"
            closeIcon={<Button className="closeBtn" />}
            open={isDataSelectionModalOpen}
            onOpen={(e) => onToggleDataSelectionModal(e, true)}
            scrolling={true}
            onClose={(e) => onToggleDataSelectionModal(e, false)}
            heading={t("Export_To_Csv.modal.Export_Widget_Data_To_CSV")}
            actions={
                <>
                    {
                        paginatedData.length > 0 && renderPagginationBar()

                    }
                    <Button primary floated="right" content={t("Export_To_Csv.modal.Export")} onClick={onExportData} disabled={!isUserHasSelectedAnyWidgetToExportData} />
                </>
            }
        >
            <div className="infoSeperators">
                <Header className="headerWidgetModal" content={t("Export_To_Csv.modal.Select_Type_Of_Data")} />
                <Input
                    className="widgetSearch"
                    icon="search"
                    placeholder={t("Export_To_Csv.modal.Search_Widgets")}
                    value={widgetSearchFilter}
                    onChange={(e) => onSearch(e)}
                />
            </div>
            <Checkbox
                disabled={isCheckboxDisabled}
                className="csvModalCheckbox"
                label={shouldShowUnselectAll ? "Unselect All" : "Select All"}
                checked={shouldShowUnselectAll}
                onChange={() => selectAll(!allWidgetsChecked)}
            />
            {renderWidgetsDataTyepeTab()}
            {(!widgetsToDisplay || !hasWidgets) && <WidgetMessage />}


        </ModalLayout >
    );
};

export default CsvExportWidgetSelectorModal;
