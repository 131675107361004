import React, { useContext, useEffect, useMemo } from 'react';
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader';
import Translation from '../../common/translation/Translation';
import { DefaultBox } from '../../dashboard/DefaultBox';
import UseNewVsRepeatWebEngamenet from './UseNewVsRepeatWebEngamenet';
import NewVsRepeatLineChart from '../NewVsRepeatUsersWidget/NewVsRepeatLine.chart';
import { WebOnlyTooltip } from '../../common/AppOnlyTooltip';
import { EngagementContext } from '../../pages/engagement/EngagementContext';
import { useRecoilState } from 'recoil';
import { connectedPageNameState } from '../../../store/ExportToCsvAtoms';
import { getDateGroupAndFormat } from '../../../_utils/chart.utils';
import { formatDateValuesFromChartToCSV } from '../../../_utils/exportToCsvUtils';

const CustomTicks = ({ data }) => (tick) =>
{
    if (data.length > 20 && tick.tickIndex > 0)
    {
        if (tick.tickIndex % 2 === 0 && tick.tickIndex !== 0)
        {
            return (
                <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
                    {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
                    <text
                        transform="rotate(45)"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {tick.value}
                        {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                    </text>
                </g>
            );
        } else return <></>;

    } else
    {
        return <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
            {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
            <text
                transform="rotate(45)"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fontSize: 10,
                }}
            >
                {tick.value}
                {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
            </text>
        </g>;
    }
};

const InfoText = ({ data, trans }) =>
{
    return (
        <div className="flexibleRow">
            {/* TODO */}
            <p className="para">
                <strong>{data.returningUsersPercentage}%</strong>{" "}
                <Translation text="Widgets.WebEngagementNewVsRepeatWidget.info_text_repeat_visit" />
            </p>
            <p className="para">
                <strong>{data.newUsersPercentage}%</strong>{" "}
                <Translation text="Widgets.WebEngagementNewVsRepeatWidget.info_text_new_visit" />
            </p>
        </div>
    );
};
const Container = ({ injectProcessedData }) =>
{
    const { sessionsQuery, processedWidgetData, analyticsRequestFilter } = UseNewVsRepeatWebEngamenet();
    const connectedPageName = useRecoilState(connectedPageNameState);

    const processedChartDataForExport = useMemo(() =>
    {

        if (!processedWidgetData.data)
        {
            return [];
        }
        const { chartData, newUsersPercentage, returningUsersPercentage } = processedWidgetData.data;

        if (!chartData?.length || (!newUsersPercentage && !returningUsersPercentage))
        {
            return [];
        }

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return chartData.map(item =>
        {
            const obj = item.data.reduce((acc, point) =>
            {
                acc[formatDateValuesFromChartToCSV(point.x, dateGroupFormat)] = point.y;
                return acc;
            }, {});

            return [`${item.label}Visits`, obj];
        });
    }, [processedWidgetData.data, connectedPageName, analyticsRequestFilter]);


    useEffect(() =>
    {

        const exportPayload = [
            {
                name: "Percentage Of New Vs. Repeat Visitors",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //   name: "Percentage Of New Vs. Repeat Visitors",
            //   data: sessionsQuery?.data,
            //   description: "",
            //   injector: injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={<Translation text="Widgets.WebEngagementNewVsRepeatWidget.title" />}
            webIcon={true}
        >
            <ConditionalRenderLoadingErrorNoDataChildren
                rawQuery={sessionsQuery}
            >
                <NewVsRepeatLineChart {...processedWidgetData} CustomTicks={CustomTicks(processedWidgetData)} infoText={<InfoText  {...processedWidgetData} />} />
            </ConditionalRenderLoadingErrorNoDataChildren>

        </DefaultBox>
    );
};

export default Container;