

export const DEFAULT_FILTER = {
    behaviour: undefined,
    platform: "all",
    sessionLengthMin: 0,
    sessionLengthMax: 0,
    numOfSessionsMin: 0,
    numOfSessionsMax: 0,
    // zonesVisited: undefined,
    // source: undefined,
}

export const FILTER_NAMES = {
    BEHAVIOUR: "behaviour",
    PLATFORM: "platform",
    SESSION_LENGTH_MIN: "sessionLengthMin",
    SESSION_LENGTH_MAX: "sessionLengthMax",
    NUM_OF_SESSIONS_MIN: "numOfSessionsMin",
    NUM_OF_SESSIONS_MAX: "numOfSessionsMax",
    // ZONES_VISITED: "zonesVisited",
    // SOURCE: "source",
};

export const FILTER_OPTIONS = {
    BEHAVIOUR: [
        {
            key: "All",
            text: "All",
            value: undefined,
        },
        {
            key: "New",
            text: "New",
            value: "1",
        },
        {
            key: "Repeat",
            text: "Repeat",
            value: "2",
        },
    ],
    PLATFORM: [
        {
            key: "All",
            text: "All",
            value: "all",
        },
        {
            key: "Android",
            text: "Android",
            value: "android",
        },
        {
            key: "iOS",
            text: "iOS",
            value: "ios",
        },
        {
            key: "Boost",
            text: "Boost",
            value: "Boost",
        }

    ],

    ZONES_VISITED: [
        {
            key: "New",
            text: "New",
            value: "New",
        },
        {
            key: "Repeat",
            text: "Repeat",
            value: "Repeat",
        }
    ],

    SOURCE: [
        {
            key: "New",
            text: "New",
            value: "New",
        },
        {
            key: "Repeat",
            text: "Repeat",
            value: "Repeat",
        }
    ],
};

export const HEATMAP_FILTER_OPTIONS =
{
    DWELL_VS_PASSBY: [
        {
            key: "all",
            text: "All",
            value: "allHeatmapData",
        },
        {
            key: "dwell",
            text: "Dwell",
            value: "dwellHeatmapData",
        },
        {
            key: "Passby",
            text: "Pass-by",
            value: "passByHeatmapData",
        }
    ],

    TYPE: [
        {
            key: "frequency",
            text: "Frequency",
            value: 0,
        },
        {
            key: "time",
            text: "Time",
            value: 1,
        }
    ]
};

export const DEFAULT_HEATMAP_FILTER = {
    service_providers: [],
    screen_resolutions: [],
    dwellVsPassby: HEATMAP_FILTER_OPTIONS.DWELL_VS_PASSBY[0].value,
}

export const DEFAULT_HEATMAP_TYPE = 0;