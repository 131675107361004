import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import serverApi from "../../_api/server.api";
import { environmentConstantsState } from "../../store/AppAtoms";
import { analyticsRequestFilterSelector, hasAccessToPropertySelector } from "../../store/DashboardAtoms";
import { DefaultBox } from '../common/DefaultBox';
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { WidgetLoadingBoxReportsLarge } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { processPoiCategorySunburstWidget } from "../../_utils/chart.utils";
import { SunburstChart } from "../charts/SunburstChart";
import { POI_CATEGORY_SEARCHES_CHART_OPTIONS } from "../../_constants/chartConstants";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";

export const StoreAndCategorySearchesWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);

    const [sunburstDrilldownData, setSunburstDrilldownData] = useState(undefined);

    // query  engagement/searchRecords 
    const searchRecordsQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD, { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {
            const result = await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD, environmentConstants);

            if (!result?.success)
            {
                throw new Error(`${NAV_ANALYTICS_QUERY_KEYS.ENGAGEMENT_SEARCH_RECORD} response was not ok`);
            }

            return result.data;
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });

    // render widget/loading/error

    useEffect(() =>
    {
        setSunburstDrilldownData(undefined);
    }, [searchRecordsQuery.data]);

    const processedStoreAndCategorySearchData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data, refetch } = searchRecordsQuery;

        let processedData;

        if (!!data)
        {
            processedData = processPoiCategorySunburstWidget(data);

        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [searchRecordsQuery]);

    const processedExportData = useMemo(() => {
        const sunburstData = processedStoreAndCategorySearchData?.data?.sunburstChartData;
    
        if (!sunburstData) {
            return [];
        }

        // flatten and map data in a single step
        return sunburstData.children.flatMap(({ name: Categories, children, totalSearches }) =>
            children.map(({ name: Stores, searches: Searches }) => ({
                Categories,
                // CategorySearchPercentage: roundWithPrecision((totalSearches / sunburstData.total) * 100, 2),
                Stores,
                Searches,
                // StoreSearchPercentage: roundWithPrecision((Searches / sunburstData.total) * 100, 2)
            }))
        );
    }, [processedStoreAndCategorySearchData?.data, connectedPageName]);

    useEffect(() =>
    {

        const exportPayload = [
            {
                name: "What was the most searched category and store in your property",
                data: processedExportData,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "What was the most searched category and store in your property",
            //     data: searchRecordsQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedExportData]);

    const handleSunburstClick = React.useCallback((e) =>
    {
        const findObjectInternal = (data, id) => data.find(layer => layer.id === id);

        const flattenInternal = (data) =>
            data.reduce((acc, item) =>
            {
                if (item.children)
                {
                    return [...acc, item, ...flattenInternal(item.children)];
                }

                return [...acc, item];
            }, []);

        let data = processedStoreAndCategorySearchData.data.sunburstChartData;

        if (!!sunburstDrilldownData)
        {
            data = sunburstDrilldownData;
        }

        const flattenedObject = flattenInternal(data.children);
        const foundObject = findObjectInternal(flattenedObject, e.id);

        if (foundObject && foundObject.children)
        {
            setSunburstDrilldownData(foundObject);
        }
    }, [sunburstDrilldownData, processedStoreAndCategorySearchData, setSunburstDrilldownData]);

    const getCustomLabel = React.useCallback((e) =>
    {
        return e?.data?.name;
    }, []);

    const renderCustomTooltip = React.useCallback((e) =>
    {
        return (
            <div className="tooltipItemRow poiWidget">
                <span style={{ background: e.color }} /> <strong>{`${e.data.name} - ${e.formattedValue}`}</strong>
            </div>
        );
    }, []);

    const renderStoreAndCategorySearchesWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedStoreAndCategorySearchData;

        if (isSuccess)
        {
            const { sunburstChartData, mostSearchedCategory, mostSearchedPOI } = data;

            if (!sunburstChartData || sunburstChartData?.children?.length === 0)
            {
                return <NoDataWidgetMessage />;
            }

            return (
                <React.Fragment>
                    <div className="flexibleRow gapBetween">
                        {
                            (mostSearchedCategory.name && !mostSearchedCategory.isTie) && (
                                <p className="para"><strong>{mostSearchedCategory.name}</strong> {trans("StoreAndCategorySearchesWidget.was_the_most_searched_category_in_the_se")}</p>
                            )
                        }
                        {
                            (mostSearchedPOI.name && !mostSearchedPOI.isTie) && (
                                <p className="para"><strong>{mostSearchedPOI.name}</strong> {trans("StoreAndCategorySearchesWidget.was_the_most_searched_store_in_the_selec")}</p>
                            )
                        }

                    </div>
                    <div style={{ height: 550, width: "100%", float: "left" }}>
                        <SunburstChart
                            data={sunburstDrilldownData || sunburstChartData}
                            options={POI_CATEGORY_SEARCHES_CHART_OPTIONS}
                            customLabel={getCustomLabel}
                            customTooltip={renderCustomTooltip}
                            onClick={handleSunburstClick}
                        />
                    </div>

                    <p className="para-under flexible-under">
                        {
                            (!!sunburstDrilldownData) && (
                                <React.Fragment>
                                    <Button className="resetButton" content={trans("StoreAndCategorySearchesWidget.Reset")} onClick={() => setSunburstDrilldownData(undefined)} />
                                    <span>
                                        {trans("StoreAndCategorySearchesWidget.Zoomed_in_from")}<strong>{sunburstDrilldownData.parentName}</strong>
                                    </span>
                                </React.Fragment>
                            )
                        }
                    </p>

                </React.Fragment>
            );
        }
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedStoreAndCategorySearchData)} />;
        }
        // if loading
        else
        {
            return WidgetLoadingBoxReportsLarge();
        }

    }, [processedStoreAndCategorySearchData, getCustomLabel, renderCustomTooltip, handleSunburstClick, setSunburstDrilldownData, sunburstDrilldownData, trans]);

    return (
        <DefaultBox className="alignedWidget" heading={trans("StoreAndCategorySearchesWidget.What_was_the_most_searched_category_and_")}>
            {
                renderStoreAndCategorySearchesWidget()
            }
        </DefaultBox>
    );
};