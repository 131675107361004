import { createZoneGeofenceLayers } from "mapsted.maps/mapFunctions/publicVectorLayers";
import React, { useCallback, useEffect, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useOverlayTemplateQuery, usePropertyMapQuery, useZoneGeofenceGeneralRawQuery } from "../../../_api/queries";
import
{
    createZoneGeofenceStylesAndTemplate,
    mapPropertyZoneGeofence,
    processMapDataQuery,
    processZonePropertyGeneralData
} from "../../../_utils/map.utils";
import { languageCodeState } from "../../../store/AppAtoms";
import
{
    buildingIdState,
    dateRangeState,
    floorIdState,
    historyKeysHavingDataForActiveFloorState,
    propertyIdState,
    propertyTimeZoneSelectorState
} from "../../../store/DashboardAtoms";
import { selectedUserState, selectedZoneGeofenceHashState, zoneGeofenceActiveHistoryState, zoneGeofenceColorAndTemplateInfoState } from "../../../store/MapAtoms";
import { ErrorWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import { DropdownForm } from "../../common/dropdownForm/DropdownForm";
import { WidgetLoadingBoxFull } from "../../common/placeholders/Placeholders";
import { ZoneGeofenceMap } from "../ZoneGeofenceMap";

/**
 * zoneGeofenceHistoryOverwriteTimeStamp_ms - optional value that when passed, automatically selects the zone geofence history that the time stamp is within
 * this will also remove the option to select zone geofence history from the dropdown.
 */
export const ZoneGeofenceTrajectoryMapComponent = ({
    positionLayers,
    showNoDataOnHistoryDropDown = false,
    zoneGeofenceHistoryOverwriteTimeStamp_ms,
    rotateMap,
    isHistoryDropdownVisible = true,
    trajectoryMapLayer,
    addAdditionalBtnProps,
    onFloorChangeFromMap
}) =>
{
    const trans = useTranslation().t;
    const [zoneHistory, setZoneHistory] = useRecoilState(zoneGeofenceActiveHistoryState);
    const [zoneGeofenceColorsAndTemplateInfo, setZonesColorAndTemplateHash] = useRecoilState(zoneGeofenceColorAndTemplateInfoState);
    const propertyId = useRecoilValue(propertyIdState);
    const buildingId = useRecoilValue(buildingIdState);
    const floorId = useRecoilValue(floorIdState);
    const languageCode = useRecoilValue(languageCodeState);
    const setSelectedUser = useSetRecoilState(selectedUserState);
    const dateRange = useRecoilValue(dateRangeState);
    const propertyTimeZoneId = useRecoilValue(propertyTimeZoneSelectorState);
    const historyKeysHavingDataForActiveFloor = useRecoilValue(historyKeysHavingDataForActiveFloorState);
    const [selectedZoneGeofenceHash, setSelectedZoneGeofenceHash] = useRecoilState(selectedZoneGeofenceHashState);


    /**
     * On mount
     */
    useEffect(
        () =>
        {
            setSelectedUser(undefined);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /***  QUERY CALLS ***/

    // Query to get plotting information for map entities
    const mapDataQuery = usePropertyMapQuery();

    // Query to get plotting information for zone geofences

    const propertyGeneralZoneGeofenceQuery = useZoneGeofenceGeneralRawQuery();
    const overlayTemplateQuery = useOverlayTemplateQuery();

    const processedZoneGeneralData = useMemo(() =>
    {
        if (propertyGeneralZoneGeofenceQuery.isSuccess && overlayTemplateQuery.isSuccess)
        {
            const { data } = propertyGeneralZoneGeofenceQuery;
            const { data: overlayTemplatesHash } = overlayTemplateQuery;
            const processedResponse = processZonePropertyGeneralData(
                data,
                dateRange,
                { id: propertyTimeZoneId, name: propertyTimeZoneId }
            );

            // creating zoneGeofenceColorsAndTemplateInfo for zonGeofence
            let zoneGeofenceColorsAndTemplateInfo = createZoneGeofenceStylesAndTemplate(overlayTemplatesHash, processedResponse.zoneGeofenceMap);



            return { ...processedResponse, zoneGeofenceColorsAndTemplateInfo };
        }
        else
        {
            return {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyTimeZoneId, propertyGeneralZoneGeofenceQuery.dataUpdatedAt, dateRange, overlayTemplateQuery.dataUpdatedAt]);


    /***************************************/
    /*** ZONE GEOFENCE COLOR MAP HANDLER ***/
    /***************************************/

    useEffect(
        () =>
        {
            const zoneGeofenceMap = processedZoneGeneralData?.zoneGeofenceMap;
            const activeHistoryOption = processedZoneGeneralData?.activeHistoryOption;
            if (!zoneGeofenceMap)
            {
                setZonesColorAndTemplateHash(undefined);
                setZoneHistory(null);

            }
            else
            {
                // let colorMap = zonezoneStyleHash ? zonezoneStyleHash : propertyGeneralZoneGeofenceQuery?.data?.zoneGeofenceColorsAndTemplateInfo;
                let zoneGeofenceColorsAndTemplateInfo = processedZoneGeneralData?.zoneGeofenceColorsAndTemplateInfo;
                // console.log(zoneGeofenceColorsAndTemplateInfo);
                setZonesColorAndTemplateHash(zoneGeofenceColorsAndTemplateInfo);

                setZoneHistory((prev) =>
                {
                    const prevHistoryKey = prev?.value;
                    const zoneGeofenceHistories = processedZoneGeneralData.editingHistories;
                    const zoneHistoryOptionsMap = processedZoneGeneralData.zoneHistoryOptionsMap;
                    // if overwrite is passed, find zone geofence history that the time stamp is included in

                    if (zoneGeofenceHistoryOverwriteTimeStamp_ms !== undefined)
                    {
                        let updateOption;
                        // loop through history to find the correct history;;
                        if (Array.isArray(zoneGeofenceHistories))
                        {
                            let mapKey;
                            for (let i = 0; i < zoneGeofenceHistories.length; i++)
                            {
                                const zoneGeofenceHistoryI = zoneGeofenceHistories[i];
                                if (zoneGeofenceHistoryI.activeFromTimestamp.unixTime_ms <= zoneGeofenceHistoryOverwriteTimeStamp_ms
                                    && zoneGeofenceHistoryI.activeUntilTimestamp.unixTime_ms >= zoneGeofenceHistoryOverwriteTimeStamp_ms)
                                {
                                    mapKey = zoneGeofenceHistoryI.mapKey;
                                    break;
                                }
                            }

                            updateOption = zoneHistoryOptionsMap?.[mapKey];
                        }

                        return updateOption;
                    }
                    else
                    {
                        if (prevHistoryKey)
                        {
                            const updatedOption = zoneHistoryOptionsMap[prevHistoryKey];
                            if (updatedOption)
                            {
                                return updatedOption;
                            }
                        }
                        return activeHistoryOption;
                    }

                });


            }
        },
        // eslint-disable-next-line
        [processedZoneGeneralData, zoneGeofenceHistoryOverwriteTimeStamp_ms]
    );


    const propertyZoneGeofenceQuery = useMemo(() =>
    {
        if (propertyGeneralZoneGeofenceQuery.isSuccess && !!zoneHistory)
        {
            const { propertyId } = processedZoneGeneralData;

            return {
                ...propertyGeneralZoneGeofenceQuery,
                dataUpdatedAt: Date.now(),
                data: {
                    ...propertyGeneralZoneGeofenceQuery.data,
                    propertyId,
                    propertyZoneGeofences: {
                        propertyId,
                        zoneGeofenceMap: zoneHistory.data
                    },
                    zoneGeofenceOccupancy: {}
                }

            };

        }

        return propertyGeneralZoneGeofenceQuery;


    }, [propertyGeneralZoneGeofenceQuery.dataUpdatedAt, zoneHistory, processedZoneGeneralData]);
    /*************************/
    /*** PROCESS FUNCTIONS ***/
    /*************************/

    const processedMapData = useMemo(() => processMapDataQuery(mapDataQuery, languageCode), [languageCode, mapDataQuery.dataUpdatedAt]);

    /*** LAYER CREATION ***/
    const zoneGeofenceLayers = React.useMemo(() =>
    {
        let zoneGeofenceLayers = undefined;

        if (!!propertyZoneGeofenceQuery.isSuccess && zoneGeofenceColorsAndTemplateInfo && (propertyZoneGeofenceQuery?.data?.propertyZoneGeofences?.zoneGeofenceMap ?? undefined))
        {
            // get the zone geofence layer for the selected floorId
            const { data } = propertyZoneGeofenceQuery;

            // map the zone geofence data
            const zoneGeofenceMap = mapPropertyZoneGeofence({
                propertyZoneGeofences: data.propertyZoneGeofences,
                zoneGeofenceColorsAndTemplateInfo,
            });

            let zoneFloorGeofenceData = zoneGeofenceMap?.[propertyId]?.[buildingId || -1]?.[floorId || -1];
            // eslint-disable-next-line
            let selectedZoneGeofenceIds = Object.keys(selectedZoneGeofenceHash).filter(zoneId => !!zoneFloorGeofenceData?.find(({ zoneGeofenceId }) => zoneGeofenceId == zoneId) && !!selectedZoneGeofenceHash[zoneId]);

            const { vectorLayers } = createZoneGeofenceLayers(zoneFloorGeofenceData, {
                selectedZoneGeofenceIds,
            });

            zoneGeofenceLayers = vectorLayers;
        }

        return zoneGeofenceLayers;
    }, [
        propertyZoneGeofenceQuery.dataUpdatedAt,
        selectedZoneGeofenceHash,
        zoneGeofenceColorsAndTemplateInfo,
        propertyId,
        buildingId,
        floorId,
    ]);

    const componentState = useMemo(() =>
    {
        const { isSuccess: processedMapData_isSuccess, isError: processedMapData_isError, data } = processedMapData;

        if (
            processedMapData_isSuccess && (!!data?.property || !!data?.building)
        )
        {
            return { isSuccess: true };
        }
        else if (
            processedMapData_isError
        )
        {
            return { isError: true };
        }
        else
        {
            return { isLoading: true };
        }
    }, [processedMapData]);

    /**
     * On zone geofence selection
     */
    const handleSelectZoneGeofence = useCallback(({ zoneGeofenceId }) =>
    {
        setSelectedZoneGeofenceHash((oldValue) =>
        {
            if (oldValue[zoneGeofenceId])
            {
                return {};
            }

            return { [zoneGeofenceId]: true };
        });

    }, [setSelectedZoneGeofenceHash]);

    const handleHistoryOptionChange = useCallback((e, { value }) =>
    {
        const newZoneData = propertyGeneralZoneGeofenceQuery?.data?.zoneHistoryOptionsMap?.[value] ?? undefined;
        if (newZoneData)
        {
            setZoneHistory(newZoneData);
        }
    }, [propertyGeneralZoneGeofenceQuery, setZoneHistory]);

    /*** RENDER FUNCTIONS ***/
    const renderMap = useCallback(() =>
    {
        const { isSuccess, isError } = componentState;

        if (isSuccess)
        {
            return (
                <ZoneGeofenceMap
                    {...{
                        addAdditionalBtnProps,
                        onFloorChangeFromMap
                    }}
                    mapData={processedMapData.data}
                    positionLayers={positionLayers}
                    zoneGeofenceLayers={zoneGeofenceLayers}
                    onSelectZoneGeofence={handleSelectZoneGeofence}
                    rotateMap={rotateMap}
                    trajectoryMapLayer={trajectoryMapLayer}
                />
            );
        }
        else if (isError)
        {
            return (
                <div className="errorWidgetWrap">
                    <ErrorWidgetMessage />
                </div>
            );
        }
        else
        {
            return (
                <WidgetLoadingBoxFull>
                    {trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}
                    <br /> {trans("Placeholders.Thank_you_for_your_patience_while_we_gat")}{" "}
                </WidgetLoadingBoxFull>
            );
        }
    }, [
        trans,
        processedMapData,
        zoneGeofenceLayers,
        floorId,
        positionLayers,
        trajectoryMapLayer,
        addAdditionalBtnProps,
        onFloorChangeFromMap
    ]);

    const renderZoneGeofenceOptions = useCallback(() =>
    {
        const options = Object.values(window.structuredClone(zoneHistory?.zoneHistoryOptionsMap ?? {})).map((h) =>
        {
            if (showNoDataOnHistoryDropDown && !historyKeysHavingDataForActiveFloor.includes(h.key))
            {
                h.text = `${h.key} ${trans("Words.No_Data")}`;
            }

            return h;
        });
        if (zoneHistory && isHistoryDropdownVisible && options.length > 1)
        {

            let text = zoneHistory?.key;

            if (showNoDataOnHistoryDropDown
                && !historyKeysHavingDataForActiveFloor.includes(zoneHistory?.key))
            {
                text = `${zoneHistory?.key} ${trans("Words.No_Data")}`;
            }

            return (
                <DropdownForm className="dropdownZoneHistory" key={zoneHistory.key} value={zoneHistory.value} text={text} options={options} onChange={handleHistoryOptionChange} />
            );
        }
    }, [isHistoryDropdownVisible,
        handleHistoryOptionChange,
        showNoDataOnHistoryDropDown,
        zoneHistory,
        historyKeysHavingDataForActiveFloor]);


    return <React.Fragment>
        {renderZoneGeofenceOptions()}
        {renderMap()}
    </React.Fragment>;
};
