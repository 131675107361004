import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

function Translation({ text }) {
  const trans = useTranslation().t;
  return <>{trans(text)}</>;
}

Translation.prototype = {
  text: PropTypes.string.isRequired,
};

export default Translation;
