/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */


import React from "react";
import { useSetState } from "ahooks";
import AppContext from "./AppContext";
import serverAPI, { silentLoginToken } from "../_api/server.api";
import { TOKEN_REFRESHMENT_TIME_INTERVAL } from "../_constants/constants";
import { useSetRecoilState } from "recoil";
import { companyTeamsState, environmentConstantsState } from "./AppAtoms";

export const AppProvider = ({ children }) =>
{
    const [state, setState] = useSetState({
        userInfo: {},
        validatingToken: true,
        tokenRefreshmentInterval: undefined,
        hubUrl: undefined,
        permission: false,
        // hasProperties: false,
        // termsAndConditions: false,
        // notifications: [],
        // notificationsUntilDate: undefined,
    });

    const setEnvironmentConstants = useSetRecoilState(environmentConstantsState);
    const setCompanyTeams = useSetRecoilState(companyTeamsState);

    // on mount
    React.useEffect(() =>
    {
        const userToken = silentLoginToken();

        if (!userToken)
        {
            console.log("no user token");
            validateToken();
        }
        else
        {
            console.log("silent login");
            silentLogin(userToken);
        }

        tokenRefreshment();

        // on unmount
        return () =>
        {
            clearTokenRefreshment();
        };
    },
        // eslint-disable-next-line 
        []);

    const logout = async () => 
    {
        clearTokenRefreshment();

        serverAPI.clear();

        setState({
            userInfo: {},
        });

        serverAPI.getHubUrl().then((HUB_URL) => window.location = (`${ HUB_URL }/login?cb=${ window.location.href }`));
    };

    const silentLogin = async (userToken, inTheBackground = false) => 
    {
        if (!inTheBackground)
        {
            serverAPI.clear();
            setState({ validatingToken: true });
        }

        try
        {
            const userData = await serverAPI.silentLogin(userToken);

            const token = userData && serverAPI.token;

            setState({ userInfo: serverAPI.userData.user.userInfo });
            initUserSettings(token, token && serverAPI.userData.user);
        }
        catch (err)
        {
            console.log("Error", err);
            throw err;
        }
    };

    const tokenRefreshment = () =>
    {
        const tokenRefreshmentInterval = setInterval(async () =>
        {
            try
            {
                const userToken = serverAPI.token;
                await serverAPI.validateToken(userToken);

                setState({ userInfo: serverAPI.userData.user.userInfo });
            }
            catch (err)
            {
                // Failed to refresh token
                logout();
            }

        }, TOKEN_REFRESHMENT_TIME_INTERVAL);

        setState({ tokenRefreshmentInterval });
    };

    const clearTokenRefreshment = () =>
    {
        const { tokenRefreshmentInterval } = state;

        (!!tokenRefreshmentInterval) && clearInterval(tokenRefreshmentInterval);
    };

    const validateToken = async () => 
    {
        let token = serverAPI.token;
        setState({ validatingToken: true });

        return new Promise((resolve, reject) => 
        {
            serverAPI.validateToken(token)
                .then(isValid =>
                {
                    if (isValid)
                    {
                        const newToken = serverAPI.token;
                        initUserSettings(token, token && serverAPI.userData.user);

                        resolve(newToken);
                    }
                    else
                    {
                        initUserSettings();
                        reject("invalid token");
                    }
                })
                .catch(err =>
                {
                    initUserSettings();
                    reject(err);
                });
        });
    };

    const initUserSettings = async (token = undefined, user = undefined) => 
    {
        if (token)
        {
            try
            {
                // Check if user accepted terms and conditions   
                const environmentConstants = await serverAPI.getEnvironmentConstants();
                const companyTeams = await serverAPI.getCompanyTeams();

                setEnvironmentConstants(environmentConstants.data);
                setCompanyTeams(companyTeams.data);

                console.log({companyTeams});

                setState({
                    userInfo: user.userInfo,
                    validatingToken: false,
                    permission: !!Object.keys(environmentConstants?.data ?? {}).length,
                });
            }
            catch (err)
            {
                console.log("initUserSettings: ", err);
            }
        }
        else
        {
            serverAPI.clear();

            setState({
                userInfo: {},
            });

            serverAPI.getHubUrl().then((HUB_URL) => window.location.href = `${ HUB_URL }?cb=${ window.location.href }`);
        }
    };

    const userRedirect = (targetURL, target = undefined) =>
    {
        console.log(targetURL);
        const token = serverAPI.token;
        if (token)
        {

            if (targetURL.startsWith("/"))
            {
                return window.location = targetURL;
            }

            if (target)
            {
                window.open(targetURL, target);
            }
            else if (target === undefined)
            {
                window.location = `${ targetURL }?t=${ token }`;
            }
            else
            {
                window.location = `${ targetURL }`;
            }
        }
    };

    const goToHub = (route = "/") =>
    {
        serverAPI.getHubUrl().then((HUB_URL) => window.open(`${ HUB_URL }${ route }`, "hub"));
    };

    return (
        <AppContext.Provider value={ {
            state: state,
            logout: logout,
            settings: () => goToHub("/settings"),
            userRedirect: userRedirect,
            redirectToHub: goToHub,
        } }>
            <>

                { children }
            </>
        </AppContext.Provider>
    );
};

export default AppProvider;