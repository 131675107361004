/*
Comments To be Removed Later

This component will update the live session info in the session table along with visually stating the live data's updated time

APPROACH
We will grab the previous session data and store it
We will rerender the component every 20 sec and fetch the live data and merge it with session data
On receiving live data,we will track and use the recent live data updated time

**/

import React, { useCallback } from "react";
import { LastUpdatedAt } from "../../common/LastUpdatedAt";
import useInsights from "./useInsights";
import { Popup } from "semantic-ui-react";

const InsightsLiveDataComponent = ({ isOverFlow, width }) =>
{
    const { shouldIncludeLiveData, dataUpdatedAt } = useInsights();  //// we will check if the user selects "Today(Current Moment Time)" in the DateRange

    // It will update the UI when the live data is fetched
    const renderLiveClock = useCallback(() =>
    {
        let dataUpdatedTime;

        if (!shouldIncludeLiveData)
        {
            return null;
        }

        if (dataUpdatedAt)
        {
            dataUpdatedTime = new Date(dataUpdatedAt);
        }
        else
        {
            dataUpdatedTime = new Date();
        }

        return <Popup
            basic
            className="tooltipPopup"
            on="hover"
            position="top left"
            offset={[-120, 40]}
            trigger={<span className="live"><img src={"/img/icon-status-blue.svg"} alt="" /></span>}
            content={<LastUpdatedAt dataUpdatedAt={dataUpdatedTime} />}
        />;
    }, [dataUpdatedAt, shouldIncludeLiveData]);
    return <>{renderLiveClock()}</ >;
};

export default InsightsLiveDataComponent;
