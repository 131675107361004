import { dateRangeToUTCStrings } from "./date.luxon.utils";

export const refetchQuery = (query) =>
{
    if (query?.refetch)
    {
        query.refetch();
    }
};

const { FILTER_NAMES } = require("../_constants/filterConstants");

/**
 * Takes in all params needed for a navigation analytics request and returns an object
 * containing navRequest and navCompareRequest.
 * @param {} param0
 * @param {Number} param0.propertyId
 * @param {Number} param0.buildingId
 * @param {Number} param0.floorId
 * @param {Object} param0.filter
 * @param {Object} param0.dateRange
 * @param {boolean} param0.considerTimeInDateRange
 * @returns {Object} { analyticsRequestFilter, analyticsRequestCompareFilter };
 */
export const getAnalyticsRequestFilter = ({ propertyId, buildingId, filter, dateRange, timeZone, considerTimeInDateRange }) =>
{

    let { startTimeUTC, endTimeUTC, compareStartTimeUTC, compareEndTimeUTC } = dateRangeToUTCStrings({ ...dateRange, considerTimeInDateRange }, timeZone);

    let platform = filter[FILTER_NAMES.PLATFORM];

    // nav api only takes "ios" or "android"... for "all" deviceType is expected to be undefined
    if (platform === "all")
    {
        platform = undefined;
    }

    if (!!startTimeUTC && !!endTimeUTC && propertyId)
    {
        let analyticsRequestFilter = {
            propertyId: Number(propertyId),
            buildingId: buildingId ? Number(buildingId) : undefined,
            behaviour: filter[FILTER_NAMES.BEHAVIOUR],
            deviceType: platform,
            startTimeUTC: startTimeUTC,
            endTimeUTC: endTimeUTC,
            maxSessionMinutes: filter[FILTER_NAMES.SESSION_LENGTH_MAX] || undefined,
            minSessionMinutes: filter[FILTER_NAMES.SESSION_LENGTH_MIN] || undefined,
            maxNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MAX] || undefined,
            minNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MIN] || undefined,
        };

        let analyticsRequestCompareFilter = {
            propertyId: Number(propertyId),
            buildingId: buildingId ? Number(buildingId) : undefined,
            behaviour: filter[FILTER_NAMES.BEHAVIOUR],
            deviceType: platform,
            startTimeUTC: compareStartTimeUTC,
            endTimeUTC: compareEndTimeUTC,
            maxSessionMins: filter[FILTER_NAMES.SESSION_LENGTH_MAX] || undefined,
            minSessionMins: filter[FILTER_NAMES.SESSION_LENGTH_MIN] || undefined,
            maxNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MAX] || undefined,
            minNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MIN] || undefined,
        };

        return { analyticsRequestFilter, analyticsRequestCompareFilter };
    }

    return { analyticsRequestFilter: {}, analyticsRequestCompareFilter: {} };
};

/**
 * @param {} param0
 * @param {Number} param0.propertyId
 * @param {Number} param0.buildingId
 * @param {Number} param0.floorId
 * @param {Object} param0.filter
 * @returns {Object} analyticsRequestFilter, analyticsRequestCompareFilter
 */
export const getAnalyticsRequestFilterAllTime = ({ propertyId, buildingId, filter }) =>
{

    let platform = filter[FILTER_NAMES.PLATFORM];

    // nav api only takes "ios" or "android"... for "all" deviceType is expected to be undefined
    if (platform === "all")
    {
        platform = undefined;
    }

    if (propertyId)
    {
        let analyticsRequestFilter = {
            propertyId: Number(propertyId),
            buildingId: Number(buildingId),
            behaviour: filter[FILTER_NAMES.BEHAVIOUR],
            deviceType: platform,
            maxSessionMinutes: filter[FILTER_NAMES.SESSION_LENGTH_MAX] || undefined,
            minSessionMinutes: filter[FILTER_NAMES.SESSION_LENGTH_MIN] || undefined,
            maxNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MAX] || undefined,
            minNumberOfSessions: filter[FILTER_NAMES.NUM_OF_SESSIONS_MIN] || undefined,
        };

        return analyticsRequestFilter;
    }

    return;
};
