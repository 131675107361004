import React from "react";
import { Image, Popup } from "semantic-ui-react";

export const InfoPopup = ({basic, max, content, position, className}) =>
{
    return (
        <Popup basic={basic} className={`infoPopup ${className}`} style={{maxWidth : max}} hoverable
            trigger={<Image as="span" className="infoButton" src="/img/icon-question.svg" />} 
            position={position}
            >
            {content}
        </Popup>
    );
}