import React, { useContext, useEffect } from 'react';
import { PIE_CHART_ARC_LINK_CHAR_LIMIT, SERVICE_PROVIDER_OPTIONS } from '../../../_constants/chartConstants';
import { PieChart } from '../../charts/PieChart';
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader';
import Translation from '../../common/translation/Translation';
import { DefaultBox } from '../../dashboard/DefaultBox';
import UseTop20DestinationsWidget from './UseTop20DestinationsWidget';

const customTooltip = React.memo(
	({ datum }) =>
	{
		const { value, color, data } = datum;
		return (
			<div className="tooltipItemRow tooltipItemProvider">
				<span style={{ background: color }} /> {data.label}: <strong>{value}</strong>
				{Number(value) === 1
					? <Translation text={("Widgets.WebEngagementTop20DestinationsWidget.Visit")} />
					: <> <Translation text={("Widgets.WebEngagementTop20DestinationsWidget.Visits")} /></>
				}
			</div>
		);
	},

);



const Top20DestinationsWidgetContainer = ({ injectProcessedData }) =>
{
	const { destinationQuery, processedWidgetData } = UseTop20DestinationsWidget();

	useEffect(() =>
	{
		if(!processedWidgetData)
		{
			return;
		}

		const preparedData = processedWidgetData?.pieData?.map(({id, label, value, percentage})=>{
			return {
				Destination: id,
                Visits: value,
                // percentage,
			}
		})

		const exportPayload = [
			{
				name: "Top 20 Destinations",
				data: preparedData || [],
				description: "",
				injector: injectProcessedData
			},
			//   {
			// 	name: "Top 20 Destinations",
			// 	data: destinationQuery?.data,
			// 	description: "",
			// 	injector: injectRawData
			//   },
		];

		exportPayload.forEach(({ name, data, description, injector }) =>
		{
			injector && injector({ name, data, description });
		});

	}, [processedWidgetData]);

	return (
		<DefaultBox
			className="alignedWidget"
			heading={<Translation text="Widgets.WebEngagementTop20DestinationsWidget.title" />}
			webIcon={true}
		>
			<ConditionalRenderLoadingErrorNoDataChildren
				rawQuery={destinationQuery}
			>
				<>

					<p className="para">

						<React.Fragment>
							{/* <strong>{ processedWidgetData.maxDestinationName }</strong>{ " " } */}
							<Translation text="Widgets.WebEngagementTop20DestinationsWidget.description" />
						</React.Fragment>

					</p>
					<div className="UsedDevicesWidget">
						<PieChart
							data={processedWidgetData.pieData}
							options={SERVICE_PROVIDER_OPTIONS}
							customArcLinkLabel={(data) =>
							{
								let { label } = data;

								if (label.length > PIE_CHART_ARC_LINK_CHAR_LIMIT)
								{
									return label.substring(0, PIE_CHART_ARC_LINK_CHAR_LIMIT) + "...";
								} else
								{
									return label;
								}
							}}
							customTooltip={customTooltip}
						/>
					</div>

				</>

			</ConditionalRenderLoadingErrorNoDataChildren>

		</DefaultBox>
	);
};

export default Top20DestinationsWidgetContainer;