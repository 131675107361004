import React from "react";

const ScreenResolutionInfoText = ({ trans, maxResolution }) => {
    return (
        <p className="para">
            {!!maxResolution && (
                <React.Fragment>
                    <strong>{maxResolution}</strong>{" "}
                    {trans("ScreenResolutionWidget.is_the_most_common_screen_resolution_use")}
                </React.Fragment>
            )}
        </p>
    );
};

export default ScreenResolutionInfoText;
