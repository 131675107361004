import React from "react";
import { NEW_VS_RETURNING_USER_CHART_OPTIONS } from "../../../_constants/chartConstants";
import { LineChart } from "../../charts/LineChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import NewVsRepeatInfoText from "./NewVsRepeatInfo.text";

function NewVsRepeatLineChart(props)
{
    const { trans, data, infoText } = props;
    const customSliceTooltip = React.useCallback(
        ({ slice }, timeZone) =>
        {
            return (
                <div className="widgetsTooltipBox">
                    <strong>
                        { slice.points[0].data.x } <small>{ ` ${ timeZone }` }</small>
                    </strong>
                    {
                        // each points data ie. "[point.color] 120 New Visits"
                        slice.points.map((point) => (
                            <div className="tooltipItemRow" key={ point.id }>
                                <span style={ { background: point.borderColor } }></span>
                                { trans(`NewVsRepeatUsersWidget.${ point.data.label }`) }{ " " }
                                { trans("NewVsRepeatUsersWidget.Visits") }
                                <strong>{ `${ point.data.y }` }</strong>
                            </div>
                        ))
                    }
                </div>
            );
        },
        [trans]
    );

    const customLegendFormat = React.useCallback(
        (values) =>
        {
            values.forEach((value) =>
            {
                value.label = trans(`NewVsRepeatUsersWidget.${ value.id }`);
            });
            return values;
        },
        [trans]
    );

    if (data.newUsersPercentage === 0 && data.returningUsersPercentage === 0)
    {
        return <NoDataWidgetMessage />;
    }

    return (
        <div>
            { infoText ? infoText : <NewVsRepeatInfoText { ...props } /> }
            {/* <Image as="span" src="/img/chart-repeat.svg" alt="" className="chartDefault" /> */ }
            <div style={ { height: "460px" } }>
                <LineChart
                    data={ data.chartData }
                    options={ NEW_VS_RETURNING_USER_CHART_OPTIONS }
                    customSliceTooltip={ (e) => customSliceTooltip(e, data.timeZone) }
                    customLegendFormat={ customLegendFormat }
                />
            </div>
        </div>
    );
}

export default NewVsRepeatLineChart;
