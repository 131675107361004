import React from "react";
import { Header } from "semantic-ui-react";

export const DefaultBox = ({ heading, children, independent, extraHead, className }) =>
{
    return (
        <div className={`defaultBox${independent ? " independent" : ""}${className ? " " + className : ""}`}>
            <Header content={heading} />
            {extraHead && extraHead}
            <div className="bodyDefaultBox">
                {children}
            </div>
        </div>
    );
}