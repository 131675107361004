import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { activePageDataExportHandlersState, connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { EXPORT_TO_CSV_DATA_TYPES } from "../../../_constants/exportToCsvConstants";
import { addDataFromAInjectorToState, getDefaultStateOfActivePageDataExport } from "../../../_utils/exportToCsvUtils";
/**
 * Custom hook for connecting a page to the export data state.
 * @param {string} connectedPageName - The name of the connected page.
 * @returns {Object} - Object with functions to add data to the export state.
 */
const useExportDataConnector = (connectedPageName) =>
{
    const setActivePageDataExportHandlers = useSetRecoilState(activePageDataExportHandlersState);
    const setActiveConnectedPageName = useSetRecoilState(connectedPageNameState);

    // Reset the export state when the hook is initialized
    useEffect(() =>
    {
        setActivePageDataExportHandlers(getDefaultStateOfActivePageDataExport());
    }, []);

    // Update the connected page name when it changes
    useEffect(() =>
    {
        setActiveConnectedPageName(connectedPageName);
    }, [connectedPageName]);

    /**
   * Adds raw data to the export handler state.
   * @param {Object} data - The data to add.
   * @param {string} data.name - The name of the data. //i.e injector name
   * @param {string} data.description - The description of the data.
   * @param {Object} data.data - The data to add. // data to be used for export
   * @param {Object} data.meta - The meta data to add.
   */
    const injectRawData = useCallback(({ name, description, data, meta }) =>
    {
        setActivePageDataExportHandlers((prevState) => addDataFromAInjectorToState(EXPORT_TO_CSV_DATA_TYPES.RAW_DATA, prevState, { name, description, data, meta }));
    }, []);

    /**
   * Adds processed data to the export handler state.
   * @param {Object} data - The data to add.
   * @param {string} data.name - The name of the data. //i.e injector name
   * @param {string} data.description - The description of the data.
  * @param {Object} data.data - The data to add. // data to be used for export
   * @param {Object} data.meta - The meta data to add.
   */
    const injectProcessedData = useCallback(({ name, description, data, meta }) =>
    {
        setActivePageDataExportHandlers((prevState) => addDataFromAInjectorToState(EXPORT_TO_CSV_DATA_TYPES.PROCESSED_DATA, prevState, { name, description, data, meta }));
    }, []);

    return {
        injectRawData,
        injectProcessedData,
    };
};
export default useExportDataConnector;
