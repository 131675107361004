import React, { memo, useContext, useEffect, useMemo } from "react";
import { UNIQUE_SESSION_BAR_CHART_OPTIONS } from "../../../_constants/chartConstants";
import { BarChart } from "../../charts/BarChart";
import { ConditionalRenderLoadingErrorNoDataChildren } from "../../common/ConditinalLoader";
import { WidgetPercentageText } from "../../common/PercentagePara";
import Translation from "../../common/translation/Translation";
import { DefaultBox } from "../../dashboard/DefaultBox";
import UseAverageUsersVsTimeWebEngagement from "./UseAverageUsersVsTimeWebEngamenet";
import { AppOnlyTooltip, WebOnlyTooltip } from "../../common/AppOnlyTooltip";
import { EngagementContext } from "../../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { formatDateValuesFromChartToCSV } from "../../../_utils/exportToCsvUtils";
import { getDateGroupAndFormat } from "../../../_utils/chart.utils";

const CustomTicks =
    ({ data }) =>
        (tick) =>
        {
            if (data.length > 14 && tick.tickIndex > 0)
            {
                if (tick.tickIndex % 2 === 0 && tick.tickIndex !== 0)
                {
                    return (
                        <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
                            {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
                            <text
                                transform="rotate(45)"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                style={{
                                    fontSize: 10,
                                }}
                            >
                                {tick.value}
                                {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                            </text>
                        </g>
                    );
                } else return <></>;
            } else
            {
                return (
                    <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
                        {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
                        <text
                            transform="rotate(45)"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {tick.value}
                            {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                        </text>
                    </g>
                );
            }
        };

const CustomTooltip = memo((dataDp) =>
{
    const { indexValue, value, color } = dataDp;
    return (
        <div className="tooltipItemRow tooltipItemRowDevice">
            <span style={{ background: color }} /> {indexValue}:{" "}
            <strong>{value}</strong>
            {Number(value) === 1 ? (
                <Translation text={"UniqueSessionsVsTimeWidget.User"} />
            ) : (
                <Translation text={"UniqueSessionsVsTimeWidget.Users"} />
            )}
        </div>
    );
});

const RenderAvgUserBarChart = memo(({ data }) =>
{
    const { barData, totalUniqueUsers, percentageOfChange } = data ?? {
        barData: [],
        totalUniqueUsers: 0,
        percentageOfChange: 0,
    };
    return (
        <>
            <p className="para gap0">
                <strong>{totalUniqueUsers}</strong>{" "}
                <Translation
                    text={
                        "UniqueSessionsVsTimeWidget.users_opened_your_app_in_the_selected_ti"
                    }
                />
            </p>
            <WidgetPercentageText percentage={percentageOfChange} />
            <div className="ChartWrapper">
                <BarChart
                    data={barData}
                    index="label"
                    keys={["value"]}
                    options={{
                        ...UNIQUE_SESSION_BAR_CHART_OPTIONS,
                        margin: { bottom: 48, left: 40, right: 30, top: 5 },
                    }}
                    customTooltip={CustomTooltip}
                    customTick={CustomTicks({ data: barData })}
                // customAxisLeft={{ renderTick: customTickLeft }}
                // customTooltip={customTooltip}
                />
            </div>
        </>
    );
});

const Container = ({ injectProcessedData }) =>
{
    const { sessionsQuery, processedWidgetData, analyticsRequestFilter } =
        UseAverageUsersVsTimeWebEngagement();
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data)
        {
            return;
        };
        const { barData, totalUniqueUsers, percentageOfChange } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        const transformedBarData = barData?.map(item => ({
            Date: formatDateValuesFromChartToCSV(item.label, dateGroupFormat),
            Users: item.value,
        })
        );
        return transformedBarData;
    }, [processedWidgetData?.data, analyticsRequestFilter, connectedPageName]);

    useEffect(() =>
    {

        const exportPayload = [
            {
                name: "Total Number of Web Application Users",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //   name: "Total Number of Web Application Users",
            //   data: sessionsQuery?.data,
            //   description: "",
            //   injector: injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={
                <Translation text="Widgets.WebEngagementAvgUserSessionWidget.title" />
            }
            webIcon={true}


        >
            <ConditionalRenderLoadingErrorNoDataChildren
                rawQuery={sessionsQuery}
            >
                <RenderAvgUserBarChart {...processedWidgetData} />
            </ConditionalRenderLoadingErrorNoDataChildren>
        </DefaultBox>
    );
};

export default Container;
