import React from "react";
import { useRecoilValue } from "recoil";
import { activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { DateTime } from "luxon";
import { convertALocalDateTimeToZoneDateTime } from "../../_utils/date.luxon.utils";
import Translation from "./translation/Translation";
import classNames from "classnames";

export const LastUpdatedAt = ({ dataUpdatedAt, className }) =>
{
    const activeTimeZone = useRecoilValue(activeTimeZoneSelectorState);
    if (!dataUpdatedAt)
    {
        return null;
    }
    // logic to convertingCurrentJs time to activeTimeZone
    const localDateTime = DateTime.fromJSDate(new Date(dataUpdatedAt));
    let activeTimeStamp = convertALocalDateTimeToZoneDateTime(localDateTime, activeTimeZone.id).toFormat("hh:mm a ");
    let tz = activeTimeZone?.id;

    return (
        <div className={classNames(className)} >
            <span className={classNames("updatedTime")} >
                <Translation text={("AlertsOverviewHead.Updated_At")} /> {activeTimeStamp} {tz} &nbsp; &nbsp;
            </span>
        </div >
    );
};
