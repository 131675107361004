import { atom } from "recoil";

export const insightsSelectedUserEmailState = atom({
    key: "insightsSelectedUserEmailState",
    default: undefined,
});

export const insightsSessionUserUIDState = atom({
    key: "insightsSessionUserUID",
    default: undefined,
});

export const insightsSelectedSessionUIDState = atom({
    key: "insightsSelectedSessionUIDState",
    default: undefined
});

export const insightsSelectedSessionIdxState = atom({
    key: "insightsSelectedSessionIdxState",
    default: undefined
});

export const insightsSelectedUserIdArrayState = atom({
    key: "insightsSelectedUserIdArrayState",
    default: undefined,
});

export const shouldFetchInsightsTrajectory = atom({
    key: "shouldFetchInsightsTrajectory",
    default: false
});
