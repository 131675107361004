export const WIDGETS = {
    DASHBOARD_SUMMARY: "DashboardSummary",
    USER_VS_TIME: "UsersVsTimeWidget",
    HARDWARE: "HardwareWidget",
    HEATMAP: "HeatmapWidget",
    VISITOR_INTERACTION: "VisitorInteractionWidget",
};

export const DEFAULT_WIDGETS = [WIDGETS.DASHBOARD_SUMMARY, WIDGETS.USER_VS_TIME, WIDGETS.HARDWARE, WIDGETS.HEATMAP];

// this constants gonna be our fixers for widget api's

export const WIDGET_TYPES = {
    numberWidget: "numberWidget",
    graphWidget: "graphWidget",
    overviewWidget: "overviewWidget",
};
export const WIDGET_IDS = {
    CategoryWidget: "CategoryWidget",
    AppUsageVsTimeWidget: "AppUsageVsTimeWidget",
    CommonlyUsedDevicesWidget: "CommonlyUsedDevicesWidget",
    DashboardOverviewWidget: "DashboardOverviewWidget",
    DownloadsVsTimeWidget: "DownloadsVsTimeWidget",
    EngagementOverviewWidget: "EngagementOverviewWidget",
    NewVsRepeatUsersWidget: "NewVsRepeatUsersWidget",
    HeatmapWidget: "HeatmapWidget",
    NumberOfZoneVisitsPerSessionWidget: "NumberOfZoneVisitsPerSessionWidget",
    RetentionRateWidget: "RetentionRateWidget",
    SearchedConversionRateWidget: "SearchedConversionRateWidget",
    ScreenResolutionWidget: "ScreenResolutionWidget",
    ServiceProviderWidget: "ServiceProviderWidget",
    SessionDurationVsTimeWidget: "SessionDurationVsTimeWidget",
    StoreAndCategorySearchesWidget: "StoreAndCategorySearchesWidget",
    TopSearchesWidget: "TopSearchesWidget",
    UniqueSessionsVsTimeWidget: "UniqueSessionsVsTimeWidget",
    VisitorFlowWidget: "VisitorFlowWidget",
    WeatherAnalyticsWidget: "WeatherAnalyticsWidget",
    ZoneGeofencePoiVisitsWidget: "ZoneGeofencePoiVisitsWidget",
    ZoneTrafficWidget: "ZoneTrafficWidget",
    CurrentCapacityNumberWidget: "CurrentCapacityNumberWidget",
    NewUsersNumberWidget: "NewUsersNumberWidget",
    AverageVistTimeNumberWidget: "AverageVistTimeNumberWidget",
    VisitorsNumberWidget: "VisitorsNumberWidget",
    OccupancyAnalyticsBarWidget: "OccupancyAnalyticsBarWidget",
    NumberOfTotalDownloadsNumberWidgets: "NumberOfTotalDownloadsNumberWidgets",
    AverageSessionIntervalNumberWidget: "AverageSessionIntervalNumberWidget",
    AverageAppSessionLengthNumberWidget: "AverageAppSessionLengthNumberWidget",
    AverageMonthlyVisitorsNumberWidget: "AverageMonthlyVisitorsNumberWidget",
};



