import React from "react";
import { Header } from "semantic-ui-react";
import { InfoPopup } from "../common/InfoPopup";
import PropTypes from "prop-types";
import ToggleButton from "../common/toogleButton/ToogleButton";
import { WebOnlyTooltip } from "../common/AppOnlyTooltip";
import { DateRangePopup } from "../common/dateRangePopup/DateRangePopup";
import { ExportButton } from "../common/ExportButton";
import useCsvExportEventEmitter from "../common/ExportToCsv/useCsvExportEventEmitter";

export const DefaultBoxPropTypes = {
    heading: PropTypes.string,
    extraHead: PropTypes.string,
    subHead: PropTypes.string,
    toolTip: PropTypes.string,
    bxCls: PropTypes.string,
    independent: PropTypes.string,
};

export const DefaultBox = ({
    heading,
    extraHead,
    subHead,
    children,
    independent,
    toolTip,
    className,
    toggle,
    isChecked,
    setIsChecked,
    webIcon,
    includeDateRange = false,
    includeExportOption = false,
    generatePdf
}) =>
{
    const exportHandlers = useCsvExportEventEmitter();
    return (
        <div
            className={`defaultBox${independent ? " independent" : ""}${className ? " " + className : ""
                }`}
        >
            {heading && (
                <Header>
                    {heading}


                    {subHead && <span className="subHeader">{subHead}</span>}
                    {toggle && (
                        <ToggleButton
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                        />
                    )}

                    {toolTip && (
                        <div className="defaultBoxInfoPopUp"><InfoPopup
                            className="headerInfoPopup"
                            content={toolTip}
                            position="top right"
                            basic
                        /></div>
                    )}
                    {extraHead && extraHead}
                    {includeDateRange && (
                        <DateRangePopup />
                    )}
                    {includeExportOption && (
                        <ExportButton generatePdf={generatePdf} onExportToCSV={() => exportHandlers.onToggleDataSelectionModal(null, true)} />
                    )}

                </Header>
            )}
            {webIcon && <WebOnlyTooltip position={"bottom left"} />}

            <div className="bodyDefaultBox">{children}</div>
        </div>
    );
};

DefaultBox.prototype = DefaultBoxPropTypes;
