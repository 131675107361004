import React, { memo } from "react";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "./WidgetMessage/WidgetMessage";
import { WidgetLoadingBoxReports } from "./placeholders/Placeholders";

export const ConditionalRenderLoadingErrorNoDataChildren = memo(({ rawQuery, children }) =>
{
	const { isLoading, isIdle, isSuccess, data = [], isError, refetch } = rawQuery;
	if (isLoading || isIdle)
	{
		return <WidgetLoadingBoxReports />
	}
	if (isError)
	{
		return <ErrorWidgetMessage onReloadClick={refetch} />
	}
	if (isSuccess && !Object.keys(data?.currentIntervalData || {}).length)
	{
		return <NoDataWidgetMessage />
	}

	return children
})