import React, { memo, useEffect } from "react";
import { NumberWidgetConditionalLoader } from "../../common/NumberWidgetCommon";
import { OverviewDetailBox } from "../../common/overviewDetailBox/OverviewDetailBox";
import Translation from "../../common/translation/Translation";
import useWebEnagamentNumberWidget from "./useWebEnagamentNumberWidget";
import { convertNumberWidgetsDurationContentToMins, formatTimeStringsForCSV } from "../../../_utils/exportToCsvUtils";
import { useRecoilValue } from "recoil";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { formatMinsToHHMMSS } from "../../../_utils/date.utils";




export const MonthlyAverageVisitTimeNumberWidgetWebEngagement = memo(({ injectProcessedData }) =>
{

    const { rawQuery, allNumberWidgetsProcessedData } = useWebEnagamentNumberWidget();
    const connectedPageName = useRecoilValue(connectedPageNameState);
    const { avgSessionTimeOfCurrentIntervalDuration, avgSessionTimeUnits, changeInSessionDurationPercentage } = allNumberWidgetsProcessedData;
    useEffect(() =>
    {
        let durationMins = convertNumberWidgetsDurationContentToMins({ content: avgSessionTimeOfCurrentIntervalDuration, extra: avgSessionTimeUnits });
        const payload =
        {
            name: "Average Visits Time",
            data: [{
                "AverageVisitsTime": avgSessionTimeOfCurrentIntervalDuration.length && formatTimeStringsForCSV(avgSessionTimeOfCurrentIntervalDuration, avgSessionTimeUnits),
                "DurationMins": durationMins,
                "AverageDurationFormatted": formatMinsToHHMMSS(durationMins),
                "PercentageOfChange": changeInSessionDurationPercentage && changeInSessionDurationPercentage,
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);
    }, [avgSessionTimeOfCurrentIntervalDuration, avgSessionTimeUnits, changeInSessionDurationPercentage, connectedPageName]);

    return <>
        <NumberWidgetConditionalLoader
            rawQuery={rawQuery}
            errorChildren={<OverviewDetailBox
                appOnly={false}
                max="220px"
                popupContent={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.popupContent" />}
                icon="time"
                content={""}
                percentage={0}
                slogan={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.slogan" />}
            />}
            noDataChildren={
                <OverviewDetailBox
                    appOnly={false}
                    max="220px"
                    popupContent={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.popupContent" />}
                    icon="time"
                    content={""}
                    percentage={0}
                    slogan={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.slogan" />}
                />
            }

        >
            <OverviewDetailBox
                appOnly={false}
                webOnly={true}
                max="220px"
                popupContent={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.popupContent" />}
                icon="time"
                content={avgSessionTimeOfCurrentIntervalDuration}
                extra={avgSessionTimeUnits}
                percentage={changeInSessionDurationPercentage}
                slogan={<Translation text="Widgets.MonthlyAverageVisitTimeNumberWidgetWebEngagement.slogan" />}
            />
        </NumberWidgetConditionalLoader>



    </>;
});