import React from "react";
import {
    AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT,
    BAR_CHART_AXIS_LEFT_TEXT_OPTIONS,
    SCREEN_RESOLUTION_PROVIDER_CHART_OPTIONS,
} from "../../../_constants/chartConstants";
import { BarChart } from "../../charts/BarChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import ScreenResolutionInfoText from "./ScreenResolutionInfo.text";

const ScreenResolutionBarChart = ({ data, trans }) => {
    const { maxResolution, resolutionBarData } = data;

    const customTick = React.useCallback((tick) => {
        if (tick.value % 1 === 0) {
            return (
                <g transform={`translate(${tick.x},${tick.y + 10})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        } else {
            return <></>;
        }
    }, []);

    const customTickLeft = (tick, e) => {
        if (!resolutionBarData) {
            return;
        }

        let screenResolution = resolutionBarData.find((x) => x.id === tick.value)?.resolution;

        if (screenResolution) {
            let renderedTick =
                screenResolution.length > AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT ? (
                    <tspan>
                        {screenResolution.substring(0, AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT) + "..."}
                        <title>{screenResolution}</title>
                    </tspan>
                ) : (
                    screenResolution
                );

            return (
                <g transform={`translate(${tick.x},${tick.y})`}>
                    <line x1="0" x2="-5" y1="0" y2="0" style={{ stroke: "rgb(119, 119, 119)", strokeWidth: "1" }} />

                    <text {...BAR_CHART_AXIS_LEFT_TEXT_OPTIONS}>{renderedTick}</text>
                </g>
            );
        }

        return <></>;
    };

    const customTooltip = React.useCallback(
        (e) => {
            const { value, color, data } = e;

            const { resolution } = data;

            return (
                <div className="tooltipItemRow tooltipItemRowDevice">
                    <span style={{ background: color }} /> {resolution}: <strong>{value}</strong>{" "}
                    {Number(value) === 1 ? trans("ScreenResolutionWidget.User") : trans("ScreenResolutionWidget.Users")}
                </div>
            );
        },
        [trans]
    );

    if (resolutionBarData.length === 0) {
        // no data
        return <NoDataWidgetMessage />;
    } else {
        return (
            <React.Fragment>
                <ScreenResolutionInfoText {...{ maxResolution, trans }} />

                <div className="UsedDevicesWidget">
                    <BarChart
                        data={resolutionBarData.slice(-6)}
                        index="id"
                        keys={["users"]}
                        options={SCREEN_RESOLUTION_PROVIDER_CHART_OPTIONS}
                        customTick={customTick}
                        customAxisLeft={{ renderTick: customTickLeft }}
                        customTooltip={customTooltip}
                    />
                </div>
            </React.Fragment>
        );
    }
};

export default ScreenResolutionBarChart;
