import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import serverApi from '../../../_api/server.api';
import
{
    NAV_ANALYTICS_QUERY_KEYS,
    QUERY_OPTIONS
} from "../../../_constants/queryConstants";
import { processZoneTraffic } from "../../../_utils/chart.utils";
import useCommon from "../../pages/useCommon";

const useZoneTraffic = () =>
{
    const common = useCommon();

    const { environmentConstants, filters, hasAccessToProperty, timeZone } = common;
    const { analyticsRequestFilter } = filters;

    const zoneTrafficRawQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.ZONE_TRAFFIC, { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {
            const result = await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.ZONE_TRAFFIC, environmentConstants);

            if (!result?.success)
            {
                throw new Error("Zone traffic query response was not ok");
            }

            return result.data;
        }
        else
        {
            throw new Error("Filter was not ok");
        }

    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });

    const processedZoneTraffic = useMemo(() =>
    {
        if (zoneTrafficRawQuery.isSuccess)
        {
            const { data } = zoneTrafficRawQuery;
            return {
                ...zoneTrafficRawQuery,
                data: processZoneTraffic({ data, timeZone })
            };
        }
        return zoneTrafficRawQuery;
    }, [timeZone, zoneTrafficRawQuery]);



    const onError = useCallback((e) =>
    {
        processedZoneTraffic.refetch();
    }, [processedZoneTraffic]);

    const boxClassName = React.useMemo(() =>
    {
        if (processedZoneTraffic.isError || (!!processedZoneTraffic.isSuccess && !processedZoneTraffic.data?.zoneTrafficArray?.length > 0))
        {
            return "hasMessage";
        }
        else
        {
            return "";
        }

    }, [processedZoneTraffic]);

    return {
        ...common,
        ...processedZoneTraffic,
        boxClassName,
        onError
    };
};

export default useZoneTraffic;