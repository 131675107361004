import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Container, Image, Menu } from "semantic-ui-react";
import { BASE_ROUTES, REPORTS_ROUTES } from "../../_constants/constants";
import LanguageDropdown from "../../_intl/languageDropdown/LanguageDropdown";
import AppContext from "../../store/AppContext";
import "./Header.css";
import { socketConnectedState } from "../../store/LiveAtoms";

const pages = [
    { title: "DASHBOARD", NavLink: "/" },
    { title: "ENGAGEMENT", NavLink: "/engagement" },
    // { title: "REPORTS", NavLink: "/reports" },
    // { title: "ALERTS", NavLink: "/alerts", sup: "LIVE" },
    { title: "FLOWS", NavLink: "/flows", },
    { title: "IoT", NavLink: BASE_ROUTES.IOT, },
    { title: "INSIGHTS", NavLink: "/insights" },
    { title: "LIVE", NavLink: "/live", },
];

export const HeaderBar = () =>
{
    const location = useLocation();
    const trans = useTranslation().t;
    const socketConnected = useRecoilValue(socketConnectedState);
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate();
    const handleLogoClicked = useCallback(() =>
    {
        navigate("/");
    }, [navigate]);

    return (
        <Menu secondary className="header-bar">
            <Container fluid>
                <MenuLink icon="logo.svg" onClick={handleLogoClicked} />
                {
                    pages.map((page, index) =>
                    {
                        let active = (page.NavLink === location.pathname);

                        // 
                        if (page.title !== "DASHBOARD")
                        {
                            active = location.pathname.toLowerCase().includes(page.title.toLowerCase());
                        }

                        // if (page.title === "ALERTS")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.ALERTS);
                        // }
                        // else if (page.title === "INSIGHTS")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.INSIDES);
                        // }
                        // else if (page.title === "FLOWS")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.FLOWS);
                        // }
                        // else if (page.title === "ENGAGEMENT")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.ENGAGEMENT);
                        // }
                        // else if (page.title === "BOOST")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.BOOST);
                        // }
                        // else if (page.title === "TAG")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.TAG);
                        // }
                        // else if (page.title === "LIVE")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.LIVE);
                        // }
                        // else if (page.title === "IoT")
                        // {
                        //     active = location.pathname.includes(BASE_ROUTES.IOT);
                        // }
                        return (
                            <Menu.Item key={index} className={(active) ? "active" : ""}>
                                <NavLink to={page.NavLink} >
                                    {trans(`HeaderBar.${page.title}`)}
                                    {
                                        page.sup && <span className={socketConnected ? "" : "grey"}>{trans(`HeaderBar.${page.sup}`)}</span>
                                    }
                                </NavLink>
                            </Menu.Item>
                        );
                    })
                }
                <Menu.Menu position="right">
                    <LanguageDropdown />
                    {/* <MenuLink icon="icon-notifications.svg">
                        <span>3</span>
                    </MenuLink> */}
                    <MenuLink icon="icon-settings.svg" onClick={appContext.settings} />
                    <MenuLink icon="icon-logout.svg" onClick={appContext.logout} />
                </Menu.Menu>
            </Container>
        </Menu>
    );
};

const MenuLink = ({ className, icon, img, children, onClick }) =>
{
    return (
        <Menu.Item as="a" href="#" className={className ? className : ""} onClick={onClick}>
            {children}
            {
                icon && (<img src={`/img/${icon}`} alt="" />)
            }
            {
                img && (<Image as="span" src={`/img/${img}`} alt="" />)
            }
        </Menu.Item>
    );
};