import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Container, Ref } from "semantic-ui-react";
import { INSIDES_DATE_TYPES } from "../../../_constants/constants";
import
{
    InsightsMinDate,
} from "../../../_constants/insightsConstants";
import { DateRangePopup } from "../../common/dateRangePopup/DateRangePopup";
import { DropdownForm } from "../../common/dropdownForm/DropdownForm";
import { ExportButton } from "../../common/ExportButton";
import { InfoPopup } from "../../common/InfoPopup";
import { DropdownPopup } from "../../common/subheader/DropdownPopup";
import { SubheaderPropertyPopup } from "../../common/subheader/SubehaderPropertyPopup";
import { TeamsDropdown } from "../../common/teamsDropdown/TeamsDropdown";
import useCommon from "../useCommon";
import InsightsLiveDataComponent from "./InsightsLiveDataComponent";
import useInsights from "./useInsights";
import { getUniqVisitId } from "../../../_utils/insights.utils";
export const InsightsSubheader = ({ generatePdf, isTodayDateAllowed = true }) =>
{
    const { buildingId, } = useCommon();
    const t = useTranslation().t;


    const {
        userDropdownOptions,
        propertyIdsThatHaveDataForSelectedUserDateRange,
        userPropertySessionDropdownOptions,
        selectedUserSessionUID,
        selectedSessionUserIdx,
        selectedUserEmail,
        handleSelectedUserChange,
        handleSelectedSessionUIDChange,
        handleChangeSelectedSession,
        propertiesUsersQuery,
        exportHandlers
    } = useInsights();

    const containerRef = React.useRef();

    /**
     * Display user dropdown options
     */
    const renderUsersDropdown = useCallback(() =>
    {
        if (userDropdownOptions.length > 0)
        {
            return (
                <div className="userSessionDropdown">
                    <p>{t("Insights.Users")}:</p>
                    <DropdownPopup
                        sortOptions={false}
                        placeholder={t("Words.Search_Users")}
                        options={userDropdownOptions}
                        value={selectedUserEmail}
                        onChange={handleSelectedUserChange}
                    />
                </div>
            );
        }
        else if (propertiesUsersQuery.isLoading)
        {
            return (
                <div className="userSessionDropdown">
                    {t("Insights.Loading_Users")}
                </div>
            );
        }
        else
        {
            return (
                <div className="userSessionDropdown">
                    {t("Insights.Could_Not_Find_Users")}
                </div>
            );
        }
    }, [userDropdownOptions, selectedUserEmail, handleSelectedUserChange, propertiesUsersQuery.isLoading]);

    /**
     * Display available sessions
     */
    const renderUserSessionsDropdown = useCallback(() =>
    {
        if (userPropertySessionDropdownOptions.length > 0)
        {

            return (
                <div className="userSessionDropdown">
                    <p>{t("Insights.Visits")}:</p>
                    <DropdownForm
                        options={userPropertySessionDropdownOptions}
                        value={getUniqVisitId({
                            sessionUID: selectedUserSessionUID,
                            sessionIdx: selectedSessionUserIdx
                        })}
                        onChange={handleSelectedSessionUIDChange}
                    />
                </div>
            );
        }
    }, [
        userPropertySessionDropdownOptions,
        selectedUserSessionUID,
        handleSelectedSessionUIDChange,
        selectedSessionUserIdx
    ]);

    /**
     * display available properties
     * display only selected building for now
     */
    const renderPropertyDropdown = useCallback(() =>
    {

        if (propertyIdsThatHaveDataForSelectedUserDateRange.length > 0)
        {
            return (
                <SubheaderPropertyPopup
                    handleChangeSelectedSession={handleChangeSelectedSession}
                    isOnlyBuildingView={true}
                    options={{
                        customPropertyList:
                            propertyIdsThatHaveDataForSelectedUserDateRange,
                        // customBuildingList:[buildingId],
                    }}
                />
            );
        }
    }, [
        propertyIdsThatHaveDataForSelectedUserDateRange,
        buildingId,
    ]);

    return (
        <div className={"subheader"} >

            <Ref innerRef={containerRef}>
                <Container className="subHeader-con">


                    {/* Date range */}
                    <Ref>
                        <>
                            <DateRangePopup
                                isTodayDateAllowed={isTodayDateAllowed}
                                minDate={InsightsMinDate}
                                dateTypes={INSIDES_DATE_TYPES}
                                forceSetDateTypeFromProps
                            />

                            <TeamsDropdown />

                            {/* user dropdown */}
                            {renderUsersDropdown()}

                            {/* property dropdown */}
                            <div className="insights-property-dwn">
                                {renderPropertyDropdown()}
                            </div>

                            {/* sessions dropdown */}
                            {renderUserSessionsDropdown()}
                        </>
                    </Ref>

                    {/**live data updated time display */}

                    <div className="live-wrapper">
                        <InsightsLiveDataComponent />
                    </div>
                    <div className="rightCorner extraPadding">
                        <div className="info-button-padding-right ">
                            <InfoPopup
                                basic
                                className="infoPopupLeftWrap"
                                position="top left"
                                content={t("Insights.ToolTips.MAP")}
                            />
                        </div>
                        <ExportButton
                            generatePdf={generatePdf}
                            onExportToCSV={() => exportHandlers.onToggleDataSelectionModal(null, true)} />
                    </div>
                </Container>
            </Ref>
        </div>
    );
};
