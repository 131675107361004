import { changeMapCenterWithBoundaryPolygon, changeMapCenterWithGpsCoords } from "mapsted.maps/mapFunctions/interactions";
import { cartoLayer } from "mapsted.maps/mapFunctions/plotting";
import { createEntityVectorLayers, setMapLayers, } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { MouseInteraction } from "mapsted.maps/utils/interactionTemplates";
import { MAP_THEMES, MapConstants } from "mapsted.maps/utils/map.constants";
import { getOlMapRotationAngleUsingBoundaryPolygon, toMercatorFromArray } from "mapsted.maps/utils/map.utils";
import { deepValue } from "mapsted.utils/objects";
import { Map, Overlay, View } from "ol";
import { Attribution, defaults as defaultControls } from "ol/control";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LIVE_ROUTES } from "../../_constants/constants";
import { InsightsOverlayOffsetLarge, InsightsOverlayOffsetSmall } from "../../_constants/insightsConstants";
import { checkPlottingLevelsIdsAreDifferent } from "../../_utils/map.utils";
import { buildingIdState, floorIdState, levelsSelector, propertyIdState } from "../../store/DashboardAtoms";
import { entityLayersState, hoveredPointIdState, isZoneMapLoadedState, selectedUserState, selectedZoneGeofenceHashState, zoneGeofenceMapOptionsState, zoneGeoFenceMapToggleLayersVisibilityState, } from "../../store/MapAtoms";
import { FloorsButtons } from "./FloorButtons";
import "./Map.css";
import { MapOptionToggles } from "./MapOptionToggles";
import useMapOverlay from "./useMapOverlay";

export const ZoneGeofenceMap = ({ mapData, zoneGeofenceLayers, trajectoryMapLayer, positionLayers, onSelectZoneGeofence, rotateMap, addAdditionalBtnProps,
    onFloorChangeFromMap }) =>
{
    const mapRef = useRef(null);
    const popupRef = useRef(null);
    const trans = useTranslation().t;
    const [entityLayers, setEntityLayers] = useRecoilState(entityLayersState);
    const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
    const [mapOptions, setMapOptions] = useRecoilState(zoneGeofenceMapOptionsState);
    const propertyId = useRecoilValue(propertyIdState);
    const buildingId = useRecoilValue(buildingIdState);
    const floorId = useRecoilValue(floorIdState);
    const levels = useRecoilValue(levelsSelector);
    const selectedZoneGeofenceHash = useRecoilValue(selectedZoneGeofenceHashState);
    const hoveredPointId = useRecoilValue(hoveredPointIdState);
    const [olMap, setOlMap] = useState(undefined);
    const [popupOverlay, setPopupOverlay] = useState(undefined);
    const setIsZoneMapLoaded = useSetRecoilState(isZoneMapLoadedState);
    const { floorIdToMapOverlayLayersHash, updateOverlayLayersVisibility, updateZoneGeofenceLayersVisibility } = useMapOverlay();
    const zoneGeoFenceMapToggleLayersVisibilityStateHash = useRecoilValue(zoneGeoFenceMapToggleLayersVisibilityState);

    const [localPBFId, setLocalPBFId] = useState({ propertyId: undefined, buildingId: undefined, floorId: undefined });

    const selectedUserRef = useRef(selectedUser);

    const tileLayer = useMemo(() =>
    {
        const tileStyle = deepValue(mapData, "style.tileLayer", undefined);
        return cartoLayer(tileStyle, { trans, transLookup: "Attributions" });
    }, [mapData, trans]);


    /**
    * On Mount
    */

    React.useEffect(() =>
    {
        // Map set up
        const attribution = new Attribution({
            collapsible: true
        });

        const newOlMap = new Map({
            target: null,
            layers: [tileLayer],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: new View({
                center: [0, 0],
                zoom: 4,
                maxZoom: MapConstants.MAX_ZOOM,
                minZoom: MapConstants.MIN_ZOOM,
            }),
        });

        let newPopupOverlay = new Overlay({
            element: popupRef.current,
            autoPan: false,
            offset: [-90, -50],
            positioning: "top-center",
        });

        newOlMap.setTarget(mapRef.current);

        newOlMap.addOverlay(newPopupOverlay);
        newOlMap.addInteraction(MouseInteraction({ olMap, handleEvent: (e) => handleMouseUpEvent(e, newOlMap) }));

        //HOVER
        newOlMap.on("pointermove", function (e)
        {
            let selected = selectedUserRef.current;

            newOlMap.forEachFeatureAtPixel(e.pixel, function (feature)
            {
                let hoverText = feature.get("hoverText");
                let position = feature.get("position");

                if (hoverText)
                {
                    setSelectedUser({ userUID: hoverText, position });
                }
                else if (hoverText === undefined && selected !== null)
                {
                    //   selected.setStyle(undefined);
                    //   selected = null;
                    setSelectedUser(undefined);
                }

                return true;
            });
        });

        setOlMap(newOlMap);
        setPopupOverlay(newPopupOverlay);
        // this is need we have provided a functionally out side of the map that will update some data in map
        // this state variable can be used to stop that functionality when map is not loaded
        setIsZoneMapLoaded(true);

        return () =>
        {
            setIsZoneMapLoaded(false);
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    /**
     * ALAN NOTE:There is no need to make a call back here, we can get the rotation when we draw the map and apply it then
     * this is causing performance issues. Remove commented code after reading
    * on map rotation angle change
    */
    // React.useEffect(() =>
    // {
    //     if (!olMap)
    //     {
    //         return
    //     }
    //     olMap.getView().setRotation(insightsMapRotationAngle)
    // }, [insightsMapRotationAngle, olMap])

    /**
    * on (mapData, floorId, olMap) change -> draw new entity layers and pan to plot
    */
    React.useEffect(() =>
    {
        const { property, building, style, imageBaseUrl } = mapData;
        const mapOverlayLayers = floorIdToMapOverlayLayersHash[floorId];

        let boundaryPolygon;

        if (!olMap)
        {
            return;
        }

        if (!!building && !!floorId)
        {
            if (!building.floors[floorId])
            {
                return;
            }
            // get map layers
            if (!building.floors[floorId])
            {
                return;
            }
            const { entityLayers, imageLayers, boundaryPolygon: buildingBoundaryPolygon } = createEntityVectorLayers({ entities: building.floors[floorId], style: style.building }, imageBaseUrl, { theme: MAP_THEMES.CLASSIC });

            boundaryPolygon = buildingBoundaryPolygon;

            // draw new map layers
            drawEntityLayers(entityLayers, imageLayers, zoneGeofenceLayers, positionLayers, mapOverlayLayers);
        }
        else if (!!property && !!olMap)
        {
            // get map layers
            const { entityLayers, imageLayers, boundaryPolygon: propertyBoundaryPolygon } = createEntityVectorLayers({ entities: property.entities, style: style.property }, imageBaseUrl, { theme: MAP_THEMES.CLASSIC });

            boundaryPolygon = propertyBoundaryPolygon;

            // draw new map layers
            drawEntityLayers(entityLayers, imageLayers, zoneGeofenceLayers, positionLayers, mapOverlayLayers);
        }

        // Check Weather to enable map rotaion based on props received from Insights
        let rotation = 0;
        if (rotateMap)
        {
            rotation = getOlMapRotationAngleUsingBoundaryPolygon(trajectoryMapLayer, boundaryPolygon, { options: { mercator: false } });

        }

        // change map boundary
        if (selectedUser?.view === LIVE_ROUTES.MAP && !!selectedUser?.position && selectedUser.buildingId === buildingId && selectedUser.floorId === floorId)
        {

            changeMapCenterWithGpsCoords({ olMap, coordinates: selectedUser.position, zoom: 20, rotation });
        }
        else
        {
            let currentMapRotation = olMap.getView().getRotation();
            let selectedZoneGeofenceIds = Object.keys(selectedZoneGeofenceHash);
            // changes map center if plotting levels are different, if map recenter is needed from selected zone, or if current map rotation is zero but we have a map rotation.
            if ((checkPlottingLevelsIdsAreDifferent(localPBFId, { propertyId, buildingId, floorId })) || selectedZoneGeofenceHash[selectedZoneGeofenceIds[0]]?.isMapRecenterNeeded || (currentMapRotation == 0 && rotation !== 0))
            {
                changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon: boundaryPolygon, padding: MapConstants.FIT_PADDING_MAP_DATA, rotation });
            }
        }

        setLocalPBFId({ propertyId: propertyId, buildingId: buildingId, floorId: floorId });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapData, floorId, zoneGeofenceLayers, positionLayers, olMap, floorIdToMapOverlayLayersHash,]);

    /**
     * on (selectedUser, olMap) change -> pan to selected user
     */
    React.useEffect(() =>
    {
        if (selectedUser?.view === LIVE_ROUTES.MAP && !!selectedUser?.position && selectedUser.propertyId === propertyId && selectedUser.floorId === floorId && olMap)
        {
            olMap.getView().animate({ duration: MapConstants.ANIMATION_DURATION, center: toMercatorFromArray(selectedUser.position) });
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedUser, olMap]);


    // On names toggle -> show/hide text layer
    React.useEffect(() =>
    {
        let namesOptions = mapOptions.names;

        if (entityLayers?.[MapConstants.TEXT_LAYER])
        {
            entityLayers[MapConstants.TEXT_LAYER].setVisible(namesOptions.value);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapOptions.names]);

    // On map overlay toggle
    React.useEffect(() =>
    {
        let overlayOptions = mapOptions.overlays;

        updateOverlayLayersVisibility(entityLayers, overlayOptions.value);

    }, [mapOptions.overlays]);

    // ZoneGeoFence Visibility toggle Effect
    React.useEffect(() =>
    {
        const zoneGeofenceMapOptions = mapOptions.zoneGeofence;
        updateZoneGeofenceLayersVisibility(entityLayers, zoneGeofenceMapOptions.value);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapOptions.zoneGeofence]);

    // watcher for hover feature, expects each feature to have "defaultStyle", "selectedStyle"
    React.useEffect(() =>
    {
        if (Array.isArray(positionLayers))
        {
            for (let i = 0; i < positionLayers.length; i++)
            {
                let layerSource = positionLayers[i].getSource();

                // loop through features to search for selected
                layerSource.getFeatures().forEach((feature) =>
                {
                    let defaultStyle = feature.get("defaultStyle");
                    let selectedStyle = feature.get("selectedStyle");
                    let defaultGeometry = feature.get("defaultGeometry");
                    let selectedGeometry = feature.get("selectedGeometry");

                    if (defaultStyle)
                    {
                        if (feature.getId() == hoveredPointId)
                        {
                            feature.setStyle(selectedStyle);
                            (selectedGeometry) && feature.setGeometry(selectedGeometry);
                        }
                        else
                        {
                            feature.setStyle(defaultStyle);
                            (defaultGeometry) && feature.setGeometry(defaultGeometry);
                        }
                    }
                });
            }
        }

    }, [hoveredPointId]);

    /**
     * Adds entity/category/image layers to map
     * @param {Array} newEntityLayers
     * @param {Array} imageLayers
     * @param {Array} zoneGeofenceLayers
     */
    const drawEntityLayers = (newEntityLayers, imageLayers, zoneGeofenceLayers, positionLayers, mapOverlayLayers) =>
    {
        let layersToAdd = [tileLayer];

        Object.keys(newEntityLayers).forEach((layerId) =>
        {
            if (layerId !== MapConstants.HIDDEN_LAYER)
            {
                const layer = newEntityLayers[layerId];

                if (layerId === MapConstants.TEXT_LAYER)
                {
                    layer.setVisible(mapOptions?.names?.value);
                }

                layersToAdd.push(layer);
            }
        });

        if (Array.isArray(mapOverlayLayers))
        {
            mapOverlayLayers.forEach((mapOverlayLayer, i) =>
            {
                mapOverlayLayer.setVisible(mapOptions?.overlays.value);
                layersToAdd.push(mapOverlayLayer);
                newEntityLayers[`overlay ${i}`] = mapOverlayLayer;
            });
        }

        if (imageLayers)
        {
            Object.keys(imageLayers).forEach((imageLayerId) =>
            {
                const layer = imageLayers[imageLayerId];
                layersToAdd.push(layer);
                newEntityLayers[imageLayerId] = layer;
            });
        }

        if (Array.isArray(zoneGeofenceLayers))
        {
            zoneGeofenceLayers.forEach((zgLayer, i) =>
            {
                // set zg layer based off of state
                zgLayer.setVisible(mapOptions?.zoneGeofence?.value);

                layersToAdd.push(zgLayer);
                newEntityLayers[`zoneGeofence ${i}`] = zgLayer;
            });
        }

        if (positionLayers)
        {
            if (Array.isArray(positionLayers))
            {
                positionLayers.forEach((layer) =>
                {
                    layersToAdd.push(layer);
                });
            }
            else
            {
                layersToAdd.push(positionLayers);
            }
        }

        setMapLayers(olMap, layersToAdd);
        setEntityLayers(newEntityLayers);
    };


    // toggle visibility of layers
    useEffect(() =>
    {
        let layersToToggleIds = Object.keys(zoneGeoFenceMapToggleLayersVisibilityStateHash);
        if (olMap && layersToToggleIds.length)
        {
            olMap.getLayers().forEach((layer) =>
            {
                if (layersToToggleIds.includes(layer.get("id")))
                {
                    const visibilityValue = zoneGeoFenceMapToggleLayersVisibilityStateHash[layer.get("id")];
                    layer.setVisible(visibilityValue);
                }
            });
        }
    }, [olMap, zoneGeoFenceMapToggleLayersVisibilityStateHash]);

    const handleFloorChange = React.useCallback((floorId) =>
    {
        setSelectedUser(undefined);
        if (typeof onFloorChangeFromMap === "function")
        {
            onFloorChangeFromMap(floorId);
        }
    }, [setSelectedUser, onFloorChangeFromMap]);

    const handleMouseUpEvent = useRecoilCallback(({ snapshot }) => async (e, olMap) =>
    {
        const selectedUserSnapshot = await snapshot.getPromise(selectedUserState);

        if (e.type === "click")
        {
            let { position, zoneGeofence, feature, } = getSelectedDataFromClick({ pointerEvent: e, olMap });

            if (!!position && position.userUID !== selectedUserSnapshot?.userUID)
            {
                let selectedUser = { ...position };
                selectedUser.view = LIVE_ROUTES.MAP;
                setSelectedUser(selectedUser);
            }
            else if (!position)
            {
                setSelectedUser(undefined);
            }

            if (zoneGeofence)
            {
                // return an object contain feature and all zoneGeofence info
                (onSelectZoneGeofence) && onSelectZoneGeofence(Object.assign({ feature }, zoneGeofence));
            }
        }

        return true;
    });

    const getSelectedDataFromClick = ({ pointerEvent, olMap, options = {} }) =>
    {
        let position;
        let zoneGeofence;
        let feature;

        olMap.forEachFeatureAtPixel(pointerEvent.pixel, (selectedFeature, layer) =>
        {
            let connectedFeature = selectedFeature.get("features")?.[0];

            if (connectedFeature)
            {
                connectedFeature = connectedFeature.get("connectedFeature");
                selectedFeature = connectedFeature;
            }

            feature = selectedFeature;

            position = feature.get("position");
            zoneGeofence = feature.get("zoneGeofence");

            return true;
        }, options);

        return { position, zoneGeofence, feature };
    };

    const renderFloorButtons = React.useCallback(() =>
    {
        if (Array.isArray(levels))
        {
            return (
                <FloorsButtons onFloorChange={handleFloorChange} {...{
                    addAdditionalBtnProps,

                }} />
            );
        }
        else
        {
            return (<React.Fragment />);
        }

    }, [levels,
        handleFloorChange,
        addAdditionalBtnProps,
        onFloorChangeFromMap]);

    const renderPopup = React.useCallback(() =>
    {
        if (selectedUser)
        {
            const popupPosition = (selectedUser?.position && toMercatorFromArray(selectedUser.position));
            const popupContent = selectedUser?.userUID;
            popupOverlay && popupOverlay.setPosition(popupPosition);

            if (popupContent?.length < 7)
            {
                (popupOverlay) && popupOverlay.setOffset(InsightsOverlayOffsetSmall);
            }
            else
            {
                (popupOverlay) && popupOverlay.setOffset(InsightsOverlayOffsetLarge);
            }

            return (
                <div className="ol-dwell-point-popup">
                    {popupContent}
                </div>
            );
        }
        else
        {
            (popupOverlay) && popupOverlay.setPosition(undefined);
            return (<React.Fragment />);
        }
    }, [selectedUser, popupOverlay]);


    return (
        <>
            <div className="mapContainer" ref={mapRef}>
                <div ref={popupRef}>
                    {
                        renderPopup()
                    }
                </div>
                {/* Anything that displays over the map element should be sent through children prop */}
                {!!olMap && (
                    <>
                        <MapOptionToggles mapOptions={mapOptions} setMapOptions={setMapOptions} />
                        {renderFloorButtons()}

                    </>
                )}

            </div>

        </>
    );
};
