import React from 'react';
import { useTranslation } from 'react-i18next';
import "./ErrorLabel.css";


export const ErrorLabel = ({ header, gap, content, children }) =>
{
    const trans = useTranslation().t;

    return (
        <div className={`error-box${gap ? " error-box-web" : ""}`} style={{ margin: gap }}>
            {
                header ? header : <div className="header">{trans("UserExplorerView.Error")}</div>
            }
            {content}
            {children}
        </div>
    )
}