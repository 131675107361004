import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import serverApi from '../../../_api/server.api';
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../../_constants/queryConstants";
import { processUsersVsTimeLineData } from "../../../_utils/chart.utils";
import useCommon from "../../pages/useCommon";
import { ALL_WIDGETS_MAP } from "../../../_constants/widgetFixers";

const useNewVsRepeat = () =>
{
    const common = useCommon();
    const { environmentConstants, filters, hasAccessToProperty, timeZone, dateRange } = common;
    const { analyticsRequestFilter } = filters;
    const newVsRepeatRawQuery = useQuery(
        [NAV_ANALYTICS_QUERY_KEYS.USER_VS_TIME, { analyticsRequestFilter, environmentConstants }],
        async () =>
        {
            if (!!analyticsRequestFilter && environmentConstants)
            {
                let result = await serverApi.getNavigationAnalyticsData(
                    analyticsRequestFilter,
                    NAV_ANALYTICS_QUERY_KEYS.USER_VS_TIME,
                    environmentConstants
                );

                if (!result?.success)
                {
                    throw new Error("User vs time response was not ok");
                }

                return result.data;

            } else
            {
                throw new Error("Filter was not ok");
            }
        },
        {
            enabled: !!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );
    const processedUsersVsTimeData = useMemo(() =>
    {
        if (newVsRepeatRawQuery.isSuccess)
        {
            const { data } = newVsRepeatRawQuery;
            const { returningUsers, newUsers } = data;

            return {
                ...newVsRepeatRawQuery,
                data: processUsersVsTimeLineData({
                    returningUsers,
                    newUsers,
                    dateRange,
                    timeZone,
                })
            };
        }
        return newVsRepeatRawQuery;
    }, [dateRange, timeZone, newVsRepeatRawQuery, ALL_WIDGETS_MAP]);


    const boxClassName = React.useMemo(() =>
    {
        if (
            processedUsersVsTimeData.isError ||
            (!!processedUsersVsTimeData.isSuccess &&
                processedUsersVsTimeData.data.newUsersPercentage === 0 &&
                processedUsersVsTimeData.data.returningUsersPercentage === 0)
        )
        {
            return "hasMessage";
        } else
        {
            return "";
        }
    }, [processedUsersVsTimeData]);
    const onError = useCallback(() =>
    {
        processedUsersVsTimeData.refetch();
    }, [processedUsersVsTimeData]);

    return {
        ...common,
        ...processedUsersVsTimeData,
        boxClassName,
        onError,
    };
};

export default useNewVsRepeat;
