import PropTypes from "prop-types";
import React from "react";
import { Button } from "semantic-ui-react";
import { POI_VISITS_CHART_OPTIONS } from "../../../_constants/chartConstants";
import { SunburstChart } from "../../charts/SunburstChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import ZoneGeoInfoText from "./ZoneGeoInfo.text";

function ZoneGeoSunburstChart({
    sunburstChartData,
    sunburstDrilldownData,
    handleSunburstClick,
    trans,
    onSunburstReset,
}) {
    const renderCustomTooltip = React.useCallback((e) => {
        return (
            <div className="tooltipItemRow poiWidget">
                <span style={{ background: e.color }} /> <strong>{`${e.data.name} - ${e.formattedValue}`}</strong>
            </div>
        );
    }, []);

    const getCustomLabel = React.useCallback((e) => {
        return e?.data?.name;
    }, []);

    if (!sunburstChartData || sunburstChartData.children.length === 0) {
        return <NoDataWidgetMessage />;
    } else {
        const cur = {
            sunburstChartData,
            sunburstDrilldownData,
            handleSunburstClick,
        };
        return (
            <div className="zoneBodyCover">
                <ZoneGeoInfoText {...{ trans }} {...cur} />
                <div style={{ height: 460 }}>
                    <SunburstChart
                        data={sunburstDrilldownData || sunburstChartData}
                        options={POI_VISITS_CHART_OPTIONS}
                        customTooltip={renderCustomTooltip}
                        customLabel={getCustomLabel}
                        onClick={handleSunburstClick}
                    />
                </div>
                <p className="para-under flexible-under">
                    {!!sunburstDrilldownData && (
                        <React.Fragment>
                            <Button
                                className="resetButton"
                                content={trans("ZoneGeofencePoiVisitsWidget.Reset")}
                                onClick={onSunburstReset}
                            />
                            <span>
                                {trans("ZoneGeofencePoiVisitsWidget._Zoomed_in_from_")}
                                <strong>{sunburstDrilldownData.parentName}</strong>
                            </span>
                        </React.Fragment>
                    )}
                </p>
            </div>
        );
    }
}

ZoneGeoSunburstChart.prototype = {
    sunburstDrilldownData: PropTypes.array.isRequired,
    sunburstChartData: PropTypes.array.isRequired,
    handleSunburstClick: PropTypes.func.isRequired,
};

export default ZoneGeoSunburstChart;
