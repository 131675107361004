const { deepValue } = require("mapsted.utils/objects");
const { DEFAULT_LANGUAGE_CODE } = require("./map.constants");

const languageCodeProperties = ["longName", "shortName", "description", "keyWords", "coverImages", "iconImage", "website", "textStyle", "iconStyle", "style", "iconDark", "iconLight",];
const mapOverlayLanguageCodeProperties = ["name", "toolTipText"];
const dynamicMapLayerLanguageCodeProperties = ["dataStreamName"];
const automationRuleLanguageCodeProperties = ["textLabel", "textPopup"];
const zoomLevelLanguageCodeProperties = ["textLabel"];

const languageCodePropertiesCategory = ["name"];

exports.extractLangaugeCodeFromPropertiesBulk = (bulkData, languageCode) =>
{
    let { property, buildings, categories, mapOverlays, dynamicMapLayers } = bulkData;
    const imageBaseUrl = bulkData.imageBaseUrl;

    property = exports.extractLanguageCodeFromInfoEntityObject(property, languageCode);

    if (buildings)
    {
        const buildingIds = Object.keys(buildings);

        buildingIds.forEach((id) =>
        {
            let building = exports.extractLanguageCodeFromInfoEntityObject(buildings[id], languageCode);

            buildings[id] = exports.mapBuildingFloors(building);
        });
    }

    if (categories)
    {
        categories = exports.extractLanguageCodeFromCategoryResult(categories, languageCode);
    }

    if (mapOverlays)
    {
        mapOverlays = exports.extractLanguageCodeFromMapOverlayResult(mapOverlays, languageCode);
    }

    if (dynamicMapLayers)
    {
        dynamicMapLayers = exports.extractLanguageCodeFromDynamicMapLayerResult(dynamicMapLayers, languageCode);
    }

    return { property, buildings, categories, imageBaseUrl, mapOverlays, dynamicMapLayers };
};

/**
 * Turns building.entities into a mapping of floor id -> entity list in building.floors.
 * deletes building.entities.
 * @param {} building
 */
exports.mapBuildingFloors = (building) =>
{
    let floorEntities = {};

    if (building.entities)
    {
        building.entities.forEach((entity) =>
        {
            const floorId = entity.floorId;

            if (Array.isArray(floorEntities[floorId]))
            {
                floorEntities[floorId].push(entity);
            }
            else
            {
                floorEntities[floorId] = [entity];
            }
        });
    }

    delete building.entities;

    building.floors = floorEntities;

    return building;
};

exports.extractLanguageCodeFromInfoEntityObject = (object, languageCode) =>
{
    object.info = exports.extractLanguageCodeFromEntity(object.info, languageCode);

    if (!!object.info.floorList && Array.isArray(object.info.floorList))
    {
        object.info.floorList.forEach((floor, i) =>
        {
            object.info.floorList[i] = exports.extractLanguageCodeFromEntity(floor, languageCode);
        });
    }

    if (object.entities)
    {
        object.entities.forEach((entity, i) =>
        {
            object.entities[i] = exports.extractLanguageCodeFromEntity(entity, languageCode);
        });
    }

    return object;
};

exports.extractLanguageCodeFromCategoryResult = (categoryResult, languageCode) =>
{
    // category.categories
    // category.roots

    if (Array.isArray(categoryResult.categories))
    {
        categoryResult.categories.forEach((category, i) =>
        {
            categoryResult.categories[i] = exports.extractLanguageCodeFromCategory(category, languageCode);
        });
    }

    if (Array.isArray(categoryResult.roots))
    {
        categoryResult.roots.forEach((category, i) =>
        {
            categoryResult.roots[i] = exports.extractLanguageCodeFromCategory(category, languageCode);
        });
    }

    return categoryResult;
};

exports.extractLanguageCodeFromMapOverlayResult = (mapOverlayResult, languageCode) =>
{
    extractedMapOverlays = [];

    if (Array.isArray(mapOverlayResult))
    {
        mapOverlayResult.forEach((mapOverlay, i) =>
        {
            extractedMapOverlays[i] = exports.extractLanguageCodeFromMapOverlay(mapOverlay, languageCode);
        });
    }

    return extractedMapOverlays;
};

exports.extractLanguageCodeFromDynamicMapLayerResult = (dynamicMapLayerResult, languageCode) =>
{
    extractedDynamicMapLayers = [];

    if (Array.isArray(dynamicMapLayerResult))
    {
        dynamicMapLayerResult.forEach((dynamicMapLayer, i) =>
        {
            extractedDynamicMapLayers[i] = exports.extractLanguageCodeFromDynamicMapLayer(dynamicMapLayer, languageCode);
        });
    }

    return extractedDynamicMapLayers;
};

exports.extractLanguageCodeFromMapOverlay = (mapOverlay, languageCode) =>
{
    if (mapOverlay)
    {
        for (let key of Object.keys(mapOverlay))
        {
            if (mapOverlayLanguageCodeProperties.includes(key))
            {
                getLanguageCodeFromObject(mapOverlay, key, languageCode);
            }
        }

        if (mapOverlay.dynamicOverlaySettings.enabled)
        {
            extractLanguageCodeFromZoomLevels(mapOverlay.dynamicOverlaySettings.zoomLevels, languageCode);
        }
    }

    return mapOverlay;
};

const extractLanguageCodeFromZoomLevels = (zoomLevels, languageCode) =>
{

    Array.isArray(zoomLevels) && zoomLevels.forEach((zoomLevel) =>
    {
        for (let key of Object.keys(zoomLevel))
        {
            if (zoomLevelLanguageCodeProperties.includes(key))
            {
                getLanguageCodeFromObject(zoomLevel, key, languageCode);
            }
        }
    });
};

exports.extractLanguageCodeFromDynamicMapLayer = (dynamicMapLayer, languageCode) =>
{
    if (dynamicMapLayer)
    {
        for (let key of Object.keys(dynamicMapLayer))
        {
            if (dynamicMapLayerLanguageCodeProperties.includes(key))
            {
                getLanguageCodeFromObject(dynamicMapLayer, key, languageCode);
            }
        }

        dynamicMapLayer.automationRules.forEach((automationRule) =>
        {
            exports.extractLanguageCodeFromAutomationRule(automationRule, languageCode);
        });
    }

    return dynamicMapLayer;
};

exports.extractLanguageCodeFromAutomationRule = (automationRule, languageCode) =>
{
    if (automationRule)
    {
        for (let key of Object.keys(automationRule))
        {
            if (automationRuleLanguageCodeProperties.includes(key))
            {
                getLanguageCodeFromObject(automationRule, key, languageCode);
            }
        }
    }
}

exports.extractLanguageCodeFromEntity = (entity, languageCode) =>
{
    if (entity)
    {
        for (const key of Object.keys(entity))
        {
            if (languageCodeProperties.includes(key))
            {
                getLanguageCodeFromObject(entity, key, languageCode);
            }
        }
    }

    return entity;
};

exports.extractLanguageCodeFromCategory = (category, languageCode) =>
{
    if (category)
    {
        for (const key of Object.keys(category))
        {
            if (languageCodePropertiesCategory.includes(key))
            {
                getLanguageCodeFromObject(category, key, languageCode);
            }
        }
    }

    return category;
};

const getLanguageCodeFromObject = (object, key, languageCode) =>
{
    if (Object)
    {
        let data = deepValue(object, `${key}.${languageCode}`, undefined);

        if (!data)
        {
            data = deepValue(object, `${key}.${DEFAULT_LANGUAGE_CODE}`, undefined);
        }

        object[key] = data;
    }
};

exports.getAllBuildingFloorEntityNames = (building) =>
{
    let buildingCopy = JSON.parse(JSON.stringify(building));

    let entities = [];
    let entityNames = [];

    if (buildingCopy.floors)
    {
        Object.values(buildingCopy.floors).forEach((floor) =>
        {
            entities.push(...floor);
        });

        entities.forEach((entity) =>
        {
            if (!entityNames.includes(entity.longName))
            {
                entityNames.push(entity.longName);
            }
        });
    }

    return entityNames;
};

/**
 * Returns a list of entityIds that match the given entityType and subEntityType
 * @param {Array} entities
 * @param {Number} entityType
 * @param {Number} subEntityType
 *
 * @returns {Map<Number, Object>} mapping of entityId to entity feature
 */
exports.getEntitiesByType = (entities, entityType, subEntityType) =>
{
    let selectedEntities = {};

    if (Array.isArray(entities))
    {
        entities.forEach((entity) =>
        {
            if (entity.entityType === entityType && entity.subEntityType === subEntityType)
            {
                selectedEntities[entity.entityId] = entity;
            }
        });
    }

    return selectedEntities;
};

/**
 * Returns a list of entityIds that match the given set of names
 * @param {Array} entities
 * @param {Set} nameSet
 *
 * @returns {Map<Number, Object>} mapping of entityId to entity feature
 */
exports.getEntitiesByNameSet = (entities, nameSet) =>
{
    let selectedEntities = {};

    if (Array.isArray(entities))
    {
        entities.forEach((entity) =>
        {
            if (nameSet.has(entity.longName) || nameSet.has(entity.shortName))
            {
                selectedEntities[entity.entityId] = entity;
            }
        });
    }

    return selectedEntities;
};

/**
 * Transforms mapOverlays to new format for multilayers update
 * @param {Array<Object>} mapOverlays 
 */
exports.transformMapOverlaysToNewFormat = (mapOverlays) =>
{
    if (Array.isArray(mapOverlays))
    {
        mapOverlays.forEach((mapOverlay) =>
        {
            // text color field not present in prev overlays
            if (!mapOverlay.textColor)
            {
                mapOverlay.textColor = "#000000"
            }

            let dynamicOverlaySettings = mapOverlay.dynamicOverlaySettings;
            if (dynamicOverlaySettings.enabled)
            {
                if (dynamicOverlaySettings.startZoom && dynamicOverlaySettings.endZoom)
                {
                    let zoomLevels = [];
                    zoomLevels.push(dynamicOverlaySettings.startZoom);
                    zoomLevels.push(dynamicOverlaySettings.endZoom);

                    updateZoomLevelTextLabelToDefault(zoomLevels[0]);
                    updateZoomLevelTextLabelToDefault(zoomLevels[1]);

                    dynamicOverlaySettings.zoomLevels = zoomLevels;
                }
            }
            else
            {
                dynamicOverlaySettings.zoomLevels = [];
            }
            delete dynamicOverlaySettings.startZoom;
            delete dynamicOverlaySettings.endZoom;
        });
    }
};

const updateZoomLevelTextLabelToDefault = (zoomLevel) =>
{
    if (zoomLevel)
    {
        zoomLevel.overrideGlobalTextLabel = false;
        zoomLevel.textLabel = { [DEFAULT_LANGUAGE_CODE]: "" };
    }
};

const DEFAULT_ZOOM_SETTINGS = {
    "fillOpacity": 0.5,
    "borderFillOpacity": 0.5,
    "textOpacity": 1
};

/**
 * Transforms mapOverlays to old format before multilayers update
 * @param {Array<Object>} mapOverlays 
 */
exports.transformMapOverlaysToOldFormat = (mapOverlays) =>
{
    if (Array.isArray(mapOverlays))
    {
        mapOverlays.forEach((mapOverlay) =>
        {
            // text color field not present in prev overlays
            if (!mapOverlay.textColor) 
            {
                mapOverlay.textColor = "#000000"
            }
            let dynamicOverlaySettings = mapOverlay.dynamicOverlaySettings;
            if (dynamicOverlaySettings.enabled)
            {
                if (dynamicOverlaySettings.zoomLevels
                    && dynamicOverlaySettings.zoomLevels.length)
                {
                    let startZoom = dynamicOverlaySettings.zoomLevels[0];
                    let endZoom = dynamicOverlaySettings.zoomLevels[dynamicOverlaySettings.zoomLevels.length - 1];

                    delete startZoom.overrideGlobalTextLabel;
                    delete startZoom.textLabel;

                    delete endZoom.overrideGlobalTextLabel;
                    delete endZoom.textLabel;

                    dynamicOverlaySettings.startZoom = startZoom;
                    dynamicOverlaySettings.endZoom = endZoom;
                }
            }
            else
            {
                dynamicOverlaySettings.startZoom = { ...DEFAULT_ZOOM_SETTINGS };
                dynamicOverlaySettings.endZoom = { ...DEFAULT_ZOOM_SETTINGS }
            }
            delete dynamicOverlaySettings.zoomLevels;
        });
    }
};
