/* eslint-disable indent */
import { isToday } from "date-fns";
import moment from "moment";
import { createDateTimeZoneDateFromJSDate, getLatestJsDateBasedOnTimeZone, isDateTimeToday } from "../../../../_utils/date.luxon.utils";

function validMoments(startMoment, endMoment)
{
	return startMoment.isValid() && endMoment.isValid();
}

function validRange(startMoment, endMoment, sameIsValid)
{
	if (startMoment.isSame(endMoment))
	{
		if (!sameIsValid)
		{
			return "equal";
		}
		else
		{
			return null;
		}
	}
	return startMoment.isBefore(endMoment) ? "lesser" : "greater";
}

function generateTimeIncrement(minIncrementProp)
{
	// Create an array of all possible times that can be selected
	const minuteIncrement = 60 / minIncrementProp;
	let timeArray = [];
	for (let i = 0; i < 24; i++)
	{
		for (let j = 0; j < minuteIncrement; j++)
		{
			const time = {
				value: ("0" + i).slice(-2) + ("0" + j * minIncrementProp).slice(-2),
				HH: ("0" + i).slice(-2),
				MM: ("0" + j * minIncrementProp).slice(-2),
				hh:
					i === 0
						? "12"
						: (i === 12 ? "12" : i > 12 ? "0" + (i - 12) : "0" + i).slice(-2),
				mm: ("0" + j * minIncrementProp).slice(-2),
				active: true,
				period: i >= 12 ? "PM" : "AM"
			};
			timeArray.push(time);
		}
	}
	return timeArray;
}

function calculateRoundedTimeValue(moment, minIncrementProp)
{
	// If we receive a moment value, find nearest time increment
	const roundedTime =
		Math.round((moment.hour() * 60 + moment.minutes()) / minIncrementProp)
		* minIncrementProp;

	return (
		("0" + Math.floor(roundedTime / 60)).slice(-2)
		+ ("0" + roundedTime % 60).slice(-2)
	);
}

export function generateTimeObjects(props)
{
	let startTimeMoment,
		endTimeMoment,
		startTimeIncrement,
		endTimeIncrement,
		startTimeValue,
		endTimeValue,
		error;

	let startMomentObject = moment(props.startMoment);
	let endMomentObject = moment(props.endMoment);

	// Check if two moment objects are valid
	if (validMoments(startMomentObject, endMomentObject))
	{
		startTimeMoment = startMomentObject.set("seconds", 0);
		endTimeMoment = endMomentObject.set("seconds", 0);
	}
	else
	{
		startTimeMoment = moment().set("hour", 8);
		endTimeMoment = moment().set("hour", 10);
	}
	startTimeValue = calculateRoundedTimeValue(
		startTimeMoment,
		props.minuteIncrement
	);
	endTimeValue = calculateRoundedTimeValue(
		endTimeMoment,
		props.minuteIncrement
	);

	// Set our moment objects hours and minutes to the rounded time value
	startMomentObject.set("hour", parseInt(startTimeValue.substring(0, 2)));
	startMomentObject.set("minutes", parseInt(startTimeValue.substring(2, 4)));
	startMomentObject.set("seconds", 0);
	endMomentObject.set("hour", parseInt(endTimeValue.substring(0, 2)));
	endMomentObject.set("minutes", parseInt(endTimeValue.substring(2, 4)));
	endMomentObject.set("seconds", 0);

	// Confirm if start and end times are valid ranges
	const validity = validRange(
		startTimeMoment,
		endTimeMoment,
		props.sameIsValid
	);
	if (!props.sameIsValid)
	{
		if (validity === "equal")
		{
			error = props.equalTimeError;
		}
		else if (validity === "greater")
		{
			error = props.endTimeError;
		}
		else
		{
			error = null;
		}
	}
	else if (validity === "greater")
	{
		error = props.endTimeError;
	}

	// Calculate time increments
	startTimeIncrement = generateTimeIncrement(props.minuteIncrement);
	endTimeIncrement = generateTimeIncrement(props.minuteIncrement);



	if (endMomentObject.diff(startMomentObject, "days") <= 1)
	{

		const currentDate = moment(getLatestJsDateBasedOnTimeZone(props.timeZone));
		const startDateTime = createDateTimeZoneDateFromJSDate(new Date(startMomentObject), props.timeZone);
		const endDateTime = createDateTimeZoneDateFromJSDate(new Date(endMomentObject), props.timeZone);
		if (isDateTimeToday(startDateTime, props.timeZone) && isDateTimeToday(endDateTime, props.timeZone))
		{
			let currentMomentValue = calculateRoundedTimeValue(currentDate, props.minuteIncrement);


			let currentSelectAbleValue = calculateRoundedTimeValue(moment(manipulateTimeObject(currentDate, currentMomentValue)), props.minuteIncrement);
			startTimeIncrement = startTimeIncrement.filter((({ value }) => value < currentSelectAbleValue));

			const hourIn24Format = currentSelectAbleValue.substring(0, 2);

			let period = hourIn24Format < 12 ? "AM" : "PM";
			let _12Hr = hourIn24Format;
			let _reamingMins = currentSelectAbleValue.substring(2, 4);
			if (period === "PM")
			{
				_12Hr = (_12Hr - 12);
				if (_12Hr === 0)
				{
					_12Hr = "12";
				}
				else if (_12Hr < 10)
				{
					_12Hr = "0" + _12Hr;
				}
			}


			const possibleLastInterVal = {
				value: currentSelectAbleValue,
				HH: hourIn24Format,
				MM: _reamingMins,
				hh: _12Hr,
				mm: _reamingMins,
				active: true,
				period: period,

			};
			endTimeIncrement = [...startTimeIncrement.filter(({ value }) => value > startTimeValue), possibleLastInterVal];

			if (endTimeIncrement.length === 1 && startTimeIncrement.length === 0)
			{
				const hourIn24Format = currentMomentValue.substring(0, 2);

				let period = hourIn24Format < 12 ? "AM" : "PM";
				let _12Hr = hourIn24Format;
				let _reamingMins = currentMomentValue.substring(2, 4);
				if (period === "PM")
				{
					_12Hr = (_12Hr - 12);
					if (_12Hr === 0)
					{
						_12Hr = "12";
					}
					else if (_12Hr < 10)
					{
						_12Hr = "0" + _12Hr;
					}
				}
				startTimeIncrement = [
					{
						value: currentMomentValue,
						HH: hourIn24Format,
						MM: _reamingMins,
						hh: _12Hr,
						mm: _reamingMins,
						active: true,
						period: period,

					}
				];
			}
		}
		else
		{
			// start date should have alway a range lessThan possible range

			// rounding logic skips 2359 value and goes for 2400
			//2400->ui it will show 00 00 and  so we are adding last entry manualy
			const _2359 = {
				value: "2400",
				HH: "24",
				MM: "00",
				hh: "11",
				mm: "59",
				active: true,
				period: "PM"
			};
			endTimeIncrement = [...startTimeIncrement.filter((data) =>
			{
				const { value } = data;
				return value > startTimeValue;
			}), _2359];
		}
	}

	// Return times back to the select object
	return {
		startTimeIncrement,
		endTimeIncrement,
		startTimeValue,
		endTimeValue,
		error
	};
}

export function manipulateTimeObject(momentObject, newTimeValue)
{
	let time = moment(momentObject);
	time.set("hour", parseInt(newTimeValue.substring(0, 2)));
	time.set("minutes", parseInt(newTimeValue.substring(2, 4)));
	time.set("seconds", 0);
	return time.toDate();
}
