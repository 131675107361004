export const validateWidgetFilter = (filter) =>
{
    const { numOfSessionsMax, numOfSessionsMin, sessionLengthMax, sessionLengthMin } = filter;


    let filterErrors = {};

    if (numOfSessionsMax !== 0 && numOfSessionsMin >= numOfSessionsMax)
    {
        filterErrors.numOfSessionsMin = true;
        filterErrors.numOfSessionsMax = true;
    }

    if (sessionLengthMax !== 0 && sessionLengthMin >= sessionLengthMax)
    {
        filterErrors.sessionLengthMin = true;
        filterErrors.sessionLengthMax = true;
    }

    if (Object.keys(filterErrors).length === 0)
    {
        filterErrors = undefined;
    }

    return filterErrors;
}
