import React, { memo } from 'react'
import { WEB_ANA_CATEGORY_STORE_OPTIONS } from '../../../_constants/chartConstants'
import { SunburstChart } from '../../charts/SunburstChart'
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader'
import Translation from '../../common/translation/Translation'
import { DefaultBox } from '../../dashboard/DefaultBox'
import useWebEngagementCategoryAndStoreSunBarChart from './useWebEngagementCategoryAndStoreSunBarChart'
import { roundWithPrecision } from 'mapsted.utils/numbers'


const CustomTooltip = memo((e) =>
{
    return (
        <div className="tooltipItemRow poiWidget">
            <span style={{ background: e.color }} /> <strong>{
                `${e.data.id} - ${roundWithPrecision(e.percentage)}% - ${e.value}`}
                <Translation text={"Widgets.WebEngagementCategoryAndStoreSunBarChart" + e.value === 1 ? "Search" : "Searches"} />
            </strong>
        </div>
    )
})
const WebEngagementCategoryAndStoreSunBarChartContainer = () =>
{
    const { searchesQuery, processedWidgetData } = useWebEngagementCategoryAndStoreSunBarChart();
    return <DefaultBox
        className="alignedWidget"
        heading={<Translation text="Widgets.WebEngagementCategoryAndStoreSunBarChart.title" />}

    >
        <ConditionalRenderLoadingErrorNoDataChildren rawQuery={searchesQuery}>
            <p className="para gap0">
                <strong>  {processedWidgetData.mostSearchedName + " "} </strong>
                <Translation text={("Widgets.WebEngagementCategoryAndStoreSunBarChart.was_the_most_searched")} />
                &nbsp;
                <Translation text={`Widgets.WebEngagementCategoryAndStoreSunBarChart.${processedWidgetData.mostSearchedType}`} />
                &nbsp;
                <Translation text={("Widgets.WebEngagementCategoryAndStoreSunBarChart.in_the_selected_time_period")} />
            </p>
            <div className='ChartWrapper'>
                <SunburstChart

                    data={processedWidgetData.chartData}
                    options={WEB_ANA_CATEGORY_STORE_OPTIONS}
                    customTooltip={CustomTooltip}
                    customLabel={e =>
                    {
                        return `${e.id} (${roundWithPrecision(e.percentage)}%)`
                    }
                    }
                // enableArcLabels
                />
            </div>
        </ConditionalRenderLoadingErrorNoDataChildren>

    </DefaultBox>
}

export default WebEngagementCategoryAndStoreSunBarChartContainer