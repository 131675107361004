import React, { memo } from "react";
import { AverageVisitIntervalNumberWidgetWebEngagement } from "./AverageVisitIntervalNumberWidgetWebEngagementContainer";
import { MonthlyActiveVisitorsNumberWidgetWebEngagement } from "./MonthlyActiveVisitorsNumberWidgetWebEngagementContainer";
import { MonthlyAverageVisitTimeNumberWidgetWebEngagement } from "./MonthlyAverageVisitTimeNumberWidgetWebEngagementContainer";
import { NewVisitsNumberWidgetWebEngagement } from "./NewVisitsNumberWidgetWebEngagementContainer";
import { NoOfVisitorsNumberWidgetWebEngagement } from "./NoOfVisitorsNumberWidgetWebEngagementContainer";
import { NumberWidgetsWrapper } from "../../common/NumberWidgetCommon";

export
{
    NoOfVisitorsNumberWidgetWebEngagement,
    MonthlyActiveVisitorsNumberWidgetWebEngagement,
    MonthlyAverageVisitTimeNumberWidgetWebEngagement,
    AverageVisitIntervalNumberWidgetWebEngagement,
    NewVisitsNumberWidgetWebEngagement
};

export const WebEngagementViewGroupedNumberWidgets = memo(({ injectProcessedData }) => <NumberWidgetsWrapper clsName="engagementWidgetRow">
    <NoOfVisitorsNumberWidgetWebEngagement injectProcessedData={injectProcessedData} />
    <MonthlyActiveVisitorsNumberWidgetWebEngagement injectProcessedData={injectProcessedData} />
    <MonthlyAverageVisitTimeNumberWidgetWebEngagement injectProcessedData={injectProcessedData} />
    {/* <AverageVisitIntervalNumberWidgetWebEngagement /> */}
    <NewVisitsNumberWidgetWebEngagement injectProcessedData={injectProcessedData} />
</NumberWidgetsWrapper>);