import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AppContext from "../store/AppContext";
import AppProvider from "../store/AppProvider";
import { Loader } from "./elements/loader";
import Mapsted from "./elements/mapsted/Mapsted";
import { HeaderBar } from "./layout/Header";
import { PermissionModal } from "./popups/PermissionModal";

import AnalyticsWrapper from "../store/AnalyticsWrapper";
import { BASE_ROUTES } from "../_constants/constants";
import InsightsContainer from "./pages/insights/InsightsContainer";
import CsvExportWidgetSelectorModal from "./common/ExportToCsv/CsvExportWidgetSelectorModal";

//######################################//
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Engagement = React.lazy(() => import("./pages/engagement"));
const Flows = React.lazy(() => import("./pages/flows"));
const Iot = React.lazy(() => import("./pages/iot"));
const Live = React.lazy(() => import("./pages/live"));

const queryClient = new QueryClient();

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={Loader({ active: true })}>
        <Component />
    </Suspense>
);

const App = () => (
    <>
        <Router>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <AppProvider>
                        <AppContext.Consumer>
                            {(context) =>
                            {
                                if (context.state.validatingToken)
                                {
                                    return <Mapsted />;
                                }
                                else if (!context.state.permission)
                                {
                                    return (
                                        <PermissionModal
                                            onLogout={context.logout}
                                            onRedirectToHub={
                                                context.redirectToHub
                                            }
                                        />
                                    );
                                }

                                return (
                                    <>
                                        <HeaderBar />
                                        <CsvExportWidgetSelectorModal />

                                        <div className="bodyWrapper">
                                            {/* add/Remove directionUpdate for rtl (language change view) after bodyWrapper */}
                                            <AnalyticsWrapper>
                                                <Routes>
                                                    <Route
                                                        path={BASE_ROUTES.HOME}
                                                        element={
                                                            <LoadComponent
                                                                component={
                                                                    Dashboard
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={BASE_ROUTES.ENGAGEMENT + "/*"}
                                                        element={
                                                            <LoadComponent
                                                                component={
                                                                    Engagement
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path={
                                                            BASE_ROUTES.INSIDES
                                                        }
                                                        element={<InsightsContainer />}
                                                    />
                                                    <Route
                                                        path={BASE_ROUTES.FLOWS + "/*"}
                                                        element={
                                                            <LoadComponent
                                                                component={
                                                                    Flows
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={BASE_ROUTES.IOT + "/*"}
                                                        element={
                                                            <LoadComponent
                                                                component={
                                                                    Iot
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path={BASE_ROUTES.LIVE + "/*"}
                                                        element={
                                                            <LoadComponent
                                                                component={
                                                                    Live
                                                                }
                                                            />
                                                        }
                                                    />
                                                </Routes>
                                            </AnalyticsWrapper>
                                        </div>
                                    </>
                                );
                            }}
                        </AppContext.Consumer>
                    </AppProvider>
                </RecoilRoot>
            </QueryClientProvider>
        </Router>
    </>
);

export default App;
