import React, { useCallback } from "react";

import { useRecoilValue } from "recoil";
import { activeTimeZoneSelectorState } from "../../../store/DashboardAtoms";
import SessionTableDropDown from "./SessionTableDropDown";
import { Popup } from "semantic-ui-react";
import { InsightsSessionStatusToIconHash, InsightsSessionStatusToReadableText } from "../../../_constants/insightsConstants";
import Translation from "../../common/translation/Translation";

const InsightsSessionTableView = ({
    data,
    onSessionClick,
    onPropertyVisitClick,
    dropdown,
}) =>
{
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);

    /**
     * returns if session row is currently selected
     */
    const isSessionRowSelected = useCallback((sessionRow) =>
    {
        // console.log("sessionRow?.sessionUID", sessionRow?.sessionUID, "dropdown?.session?.sessionUID", dropdown?.session?.sessionUID, sessionRow, dropdown);
        return sessionRow?.sessionUID === dropdown?.session?.sessionUID;
    }, [dropdown]);

    /**
     * returns the status icon for the passed session
     */
    const getSessionStatusIconFromSession = useCallback((session) =>
    {
        const status = session.sessionStatus;

        const iconColor = InsightsSessionStatusToIconHash[status];

        return `/img/icon-status-${iconColor}.svg`;
    }, []);

    /**
     * on session row click handler
     */
    const handleOnSessionRowClick = useCallback((session) =>
    {
        (onSessionClick) && onSessionClick(session);
    }, [onSessionClick]);

    const handlePropertyVisitClick = useCallback((propertyVisit) =>
    {
        (onPropertyVisitClick) && onPropertyVisitClick(propertyVisit);
    }, [onPropertyVisitClick]);

    const renderPropertyVisitChildTable = useCallback((sessionRow) =>
    {
        if (isSessionRowSelected(sessionRow))
        {
            if (sessionRow.propertyVisits.length === 0)
            {
                return (
                    <div className="dataTablebox dataTableboxInsights dataTableSession">
                        <div className="tableboxItemRow">
                            <div className="tableboxItemColumn pname"><strong>{<Translation text="Insights.No_property_visits_found" />}</strong></div>
                        </div>
                    </div>
                );

            }
            else
            {
                return (
                    <SessionTableDropDown
                        dropdown={dropdown}
                        timeZone={timeZone}
                        onClick={handlePropertyVisitClick}
                    />
                );
            }

        }
    }, [dropdown, timeZone, handlePropertyVisitClick, isSessionRowSelected]);
    /**
     * renders session table if data is available
     */
    const renderSessionTable = useCallback(() =>
    {
        if (data)
        {
            return (
                data.map((sessionRow, idx) => (
                    <div key={idx} className="sessionList">
                        <div
                            className={`tableboxItemRow sessionsRow sessionList${isSessionRowSelected(sessionRow) ? "selected" : ""}`}
                            key={`${sessionRow.sessionUID} ${idx}`}
                            onClick={() => handleOnSessionRowClick(sessionRow)}
                        >
                            <div className="tableboxItemColumn">
                                <span>{sessionRow.sessionUID}</span>
                            </div>
                            <div className="tableboxItemColumn">
                                <span>
                                    {sessionRow.duration}
                                </span>
                            </div>
                            <div className="tableboxItemColumn">
                                <span>
                                    {sessionRow.sessionStartTime}
                                </span>
                            </div>
                            <div className="tableboxItemColumn">
                                <span className="timezoneText">
                                    {sessionRow.sessionEndTime}
                                </span>
                            </div>
                            <div className="tableboxItemColumn centerColumn">
                                <Popup
                                    trigger={<img src={getSessionStatusIconFromSession(sessionRow)} alt="" />} // for other icons change the color = yellow, green
                                    basic
                                    className="infoPopup"
                                    position="top right"
                                    offset={[10, 10]}
                                    content={<Translation text={`Insights.SessionStatus.${InsightsSessionStatusToReadableText[sessionRow.sessionStatus]}`} />} />
                                {/* {sessionRow.sessionStatus} */}
                            </div>
                        </div>

                        {/* child visit rows  */}
                        {
                            renderPropertyVisitChildTable(sessionRow)
                        }
                    </div>
                ))
            );
        }
    }, [data, dropdown, timeZone, isSessionRowSelected]);

    return (
        <div className="dataTablebox dataTableboxInsights dataTableSession">
            <div className="tableboxItemRow">
                <div className="tableboxItemColumn">
                    <strong><Translation text="Insights.SessionId" /></strong>
                </div>
                <div className="tableboxItemColumn">
                    <strong>{<Translation text="Insights.Duration" />}</strong>
                </div>
                <div className="tableboxItemColumn">
                    <strong><Translation text="Insights.Start" /></strong>
                </div>
                <div className="tableboxItemColumn">
                    <strong><Translation text="Insights.End" /></strong>
                </div>
                <div className="tableboxItemColumn">
                    <strong><Translation text="Insights.Status" /></strong>
                </div>
            </div>

            {/* session table */}
            {renderSessionTable()}
        </div>
    );
};

export default InsightsSessionTableView;
