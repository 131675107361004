import React from "react";
import { Image, Icon } from "semantic-ui-react";
import { AppOnlyTooltip, WebOnlyTooltip } from "../AppOnlyTooltip";
import { InfoPopup } from "../InfoPopup";
import { PlaceholderBox } from "../placeholders/Placeholders";
import "./OverviewDetailBox.css";

export const OverviewDetailBox = ({ popupContent, icon, content, extra, slogan, percentage, max, isDisabled, appOnly, webOnly }) =>
{
    const renderPercentage = React.useCallback(() =>
    {
        const arrow = percentage > 0 ? "up" : "down";

        // if no diffrence 
        if (percentage === 0)
        {
            return (<></>)
        }
        // if percentage of change is number
        else if (!isNaN(percentage))
        {
            return (
                <div className={`percentageDetailBox ${arrow}`}>
                    {Math.abs(percentage)}%
                    <Icon name={`arrow ${arrow}`} />
                </div>
            )
        }
        // if percentage of change is not a number 
        else 
        {
            return (
                <div className={`percentageDetailBox`}>
                    {percentage}
                </div>
            )
        }

    }, [percentage]);

    const renderContent = React.useCallback(() =>
    {
        if (Array.isArray(content))
        {
            return content.map((c, i) =>
            {
                return (
                    <b key={`${c} ${extra[i] || i}`}>
                        {c}
                        {
                            extra[i] && (<span>{extra[i]}{" "}</span>)
                        }
                    </b>
                )
            })
        }
        else 
        {
            return (
                <b>
                    {content}
                    {
                        extra && (<span>{extra}</span>)
                    }
                </b>
            )
        }
    }, [content, extra]);

    return (
        <div className={`overviewDetailBox${isDisabled ? " disabled" : ""}`}>
            <InfoPopup className="infoPopupOverview" content={popupContent} max={max} position="bottom right" />
            {appOnly && <AppOnlyTooltip />}
            {webOnly && <WebOnlyTooltip />}
            <Image src={`/img/icon-${icon}.svg`} alt="" data-testid={"img"} />
            <div className="contentDetailBox">
                {renderContent()}
                <p>{slogan}</p>
            </div>
            {
                renderPercentage()
            }
        </div>
    );
}

export const OverviewDetailBoxLoading = () =>
{
    return (
        <div className="overviewDetailBox">
            <PlaceholderBox width="100%" height="55px" />
        </div>
    );
}