import React from 'react';

const ColorTagIcon = ({ color = "#667581" }) =>
{
    return <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 25 25" version="1.1">
        <g>
            <path style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: color,
                fillOpacity: 1
            }}
                d="M 12.5 25 C 5.59375 25 0 19.40625 0 12.5 C 0 5.59375 5.59375 0 12.5 0 C 19.40625 0 25 5.59375 25 12.5 C 25 19.40625 19.40625 25 12.5 25 Z M 12.5 25 " />
            <path className="cls-1" d="M5.68412,13v-.72885l.221-.0181c.7641-.05812.947-.1896.98514-.22866.02573-.02668.1572-.202.1572-.99276V4.07755H6.47918A2.45938,2.45938,0,0,0,5.002,4.36909a2.26179,2.26179,0,0,0-.56259,1.35957l-.03811.19626H3.6038L3.70384,3h8.59994l.09242,2.92492h-.78411l-.02144-.09527a2.81746,2.81746,0,0,0-.58832-1.46532c-.14719-.13148-.52305-.28678-1.481-.28678H8.9606v7.12272c0,.67835.13815.8184.16578.83936a2.09154,2.09154,0,0,0,1.00943.21342l.223.0181V13Z" transform="translate(5.375 2.5)" />
        </g>
    </svg>;
};

export default ColorTagIcon;


export const ActiveTagIcon = ({ color = "#667581" }) =>
{

    return <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
        <g>
            <path style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: color,
                fillOpacity: 1
            }}
                d="M 12.5 25 C 5.59375 25 0 19.40625 0 12.5 C 0 5.59375 5.59375 0 12.5 0 C 19.40625 0 25 5.59375 25 12.5 C 25 19.40625 19.40625 25 12.5 25 Z M 12.5 25 " />
            <path style={{
                stroke: "none",
                fillRule: "nonzero",
                fill: "rgb(100%,100%,100%)",
                fillOpacity: 1
            }}
                d="M 12.734375 18.335938 C 12.296875 18.335938 11.875 18.164062 11.566406 17.851562 L 6.828125 13.121094 C 6.726562 13.015625 6.664062 12.875 6.664062 12.730469 L 6.664062 7.210938 C 6.667969 6.910156 6.914062 6.664062 7.214844 6.664062 L 12.734375 6.664062 C 12.878906 6.664062 13.015625 6.722656 13.121094 6.824219 L 17.855469 11.5625 C 18.492188 12.210938 18.492188 13.25 17.855469 13.898438 L 13.898438 17.851562 C 13.589844 18.164062 13.171875 18.335938 12.734375 18.335938 Z M 7.765625 12.507812 L 12.34375 17.078125 C 12.558594 17.289062 12.90625 17.289062 13.125 17.078125 L 17.078125 13.121094 C 17.292969 12.90625 17.292969 12.554688 17.078125 12.339844 L 12.5 7.761719 L 7.765625 7.761719 L 7.765625 12.511719 Z M 9.945312 10.855469 C 9.4375 10.855469 9.027344 10.445312 9.027344 9.9375 C 9.027344 9.429688 9.4375 9.023438 9.945312 9.023438 C 10.449219 9.023438 10.859375 9.429688 10.859375 9.9375 C 10.859375 10.445312 10.449219 10.855469 9.945312 10.855469 Z M 9.945312 10.855469 " />
        </g>
    </svg>;
};


export const ActiveTagInfoIcon = ({ color = "black" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
        <circle cx="12.5" cy="12.5" r="11" fill="none" stroke={color} strokeWidth="2" />
        <path className="cls-1" d="M5.68412,13v-.72885l.221-.0181c.7641-.05812.947-.1896.98514-.22866.02573-.02668.1572-.202.1572-.99276V4.07755H6.47918A2.45938,2.45938,0,0,0,5.002,4.36909a2.26179,2.26179,0,0,0-.56259,1.35957l-.03811.19626H3.6038L3.70384,3h8.59994l.09242,2.92492h-.78411l-.02144-.09527a2.81746,2.81746,0,0,0-.58832-1.46532c-.14719-.13148-.52305-.28678-1.481-.28678H8.9606v7.12272c0,.67835.13815.8184.16578.83936a2.09154,2.09154,0,0,0,1.00943.21342l.223.0181V13Z" transform="translate(5.375 2.5)" />
    </svg>
);
