import React from "react";
import { ResponsiveFunnel } from "@nivo/funnel"
import { useMotionConfig, useTheme } from "@nivo/core";
import { useSpring, animated } from "react-spring"

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
export const FunnelChart = ({ data, options = {} }) =>
{

    const chartOptions = {
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
        valueFormat: ">-.2s",
        colors: options.colors || { scheme: "spectral" },
        layers: ["separators", "parts", Labels, "annotations"],
        borderWidth: 20,
        enableBeforeSeparators: false,
        enableAfterSeparators: false,
        currentPartSizeExtension: 10,
        currentBorderWidth: 40,
        labelColor: "#ffffff",
        motionConfig: "wobbly",
        interpolation: "linear",
    }

    return (
        <ResponsiveFunnel
            data={data}
            {...chartOptions}
        />
    )
}

const Labels = (props) => 
{
    return props.parts.map((part) => <PartLabel key={part.data.id} part={part} />)
}

export const PartLabel = ({ part }) =>
{
    const theme = useTheme()
    const { animate, config: motionConfig } = useMotionConfig()

    const animatedProps = useSpring({
        transform: `translate(${part.x}, ${part.y})`,
        color: part.labelColor,
        config: motionConfig,
        immediate: !animate,
    })

    return (
        <animated.g transform={animatedProps.transform}>
            <animated.text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    ...theme.labels.text,
                    fill: animatedProps.color,
                    pointerEvents: "none",
                }}>
                {part.data.label}
            </animated.text>
        </animated.g>
    )
}
