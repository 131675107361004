

// const gradient_1 = [
//     "rgb(255, 255, 204)",
//     "rgb(255, 237, 160)",
//     "rgb(254, 217, 118)",
//     "rgb(254, 178, 76)",
//     "rgb(253, 141, 60)",
//     "rgb(252, 78, 42)",
//     "rgb(277, 26, 28)",
//     "rgb(189, 0, 38)",
//     "rgb(128, 0, 38)",
// ]

// TODO MOVE TO NODEJS LIB HEATMAP CONSTANTS
const gradient_2 = [
    "rgb(255, 255, 204)",
    "rgb(254, 217, 118)",
    "rgb(253, 141, 60)",
    "rgb(277, 26, 28)",
];


// const gradient_3 = [
//     "rgb(255, 237, 160)",
//     "rgb(254, 178, 76)",
//     "rgb(252, 78, 42)",
//     "rgb(189, 0, 38)",

// ]

// const gradient_4 = [
//     "rgb(239, 255, 107)",
//     "rgb(255, 237, 160)",
//     "rgb(254, 178, 76)",
//     "rgb(227, 26, 28)",
// ]


export const HEATMAP_OPTIONS = {
    opacity: 0.8,
    gradient: gradient_2
};

export const MAX_ZOOM = 10;

export const ANIMATED_PATH_OPTIONS = {
    continous: false,
    rotation: true,
    speed: 0.05,
    easing: "inAndOut"
};

export const TRAJECTORY_MAP_PADDING = [280, 40, 40, 40];


export const DEFAULT_ZONE_COLOR_WITH_TEMPLATE = "hsla(220, 62.4%, 50%, 1)";
