import React, { useMemo, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Map, View } from "ol";
import { defaults as defaultControls, Attribution } from "ol/control";
import
{
    createEntityVectorLayers,
    setHeatmapRadiusAndBlurOnMapRender,
    setMapLayers,
} from "mapsted.maps/mapFunctions/publicVectorLayers";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import { MapConstants, MAP_THEMES } from "mapsted.maps/utils/map.constants";
import { deepValue } from "mapsted.utils/objects";
import { cartoLayer } from "mapsted.maps/mapFunctions/plotting";
import { FloorsButtons } from "./FloorButtons";
import { MapOptionToggles } from "./MapOptionToggles";
import { floorIdState, levelsSelector } from "../../store/DashboardAtoms";
import
{
    entityLayersState,
    heatmapLayerState,
    propertyCentroidMercatorSelector,
    zoomLevelState,
    heatMapOptionsState,
} from "../../store/MapAtoms";
import { Translation, useTranslation } from "react-i18next";

import { STYLES, STYLE_TYPES } from "mapsted.maps/utils/defualtStyles";
import "./Map.css";
import useMapOverlay from "./useMapOverlay";

export const HeatMapScale = () =>
{
    let trans = useTranslation().t;
    return (<div className="heatMapInfo">
        <div className="hmileft">
            <div className="hmi">
                <span style={{ height: "55%", backgroundColor: "#800026" }}></span>
                <span style={{ height: "45%", backgroundColor: "#bd0026" }}></span>
            </div>
            <div className="hmi">
                <span style={{ height: "10%", backgroundColor: "#bd0026" }}></span>
                <span style={{ height: "55%", backgroundColor: "#e31a1c" }}></span>
                <span style={{ height: "35%", backgroundColor: "#fc4e2a" }}></span>
            </div>
            <div className="hmi">
                <span style={{ height: "20%", backgroundColor: "#fc4e2a" }}></span>
                <span style={{ height: "55%", backgroundColor: "#fd8d3c" }}></span>
                <span style={{ height: "25%", backgroundColor: "#feb24c" }}></span>
            </div>
            <div className="hmi">
                <span style={{ height: "35%", backgroundColor: "#feb24c" }}></span>
                <span style={{ height: "55%", backgroundColor: "#fed976" }}></span>
                <span style={{ height: "10%", backgroundColor: "#ffeda0" }}></span>
            </div>
            <div className="hmi">
                <span style={{ height: "45%", backgroundColor: "#ffeda0" }}></span>
                <span style={{ height: "55%", backgroundColor: "#ffffcc" }}></span>
            </div>
        </div>
        <div className="hmiright">
            <span>{trans("Boost_Analytics.infoText.High")}</span>
            <span>{trans("Boost_Analytics.infoText.Mid")}</span>
            <span>{trans("Boost_Analytics.infoText.Low")}</span>
        </div>
    </div>);
};
export const Heatmap = ({ mapData, pointSpacing, heatmapLayer, showHeatMapScale = false }) =>
{
    const mapRef = React.useRef(null);

    const trans = useTranslation().t;

    const [entityLayers, setEntityLayers] = useRecoilState(entityLayersState);
    const [mapOptions, setMapOptions] = useRecoilState(heatMapOptionsState);
    const setHeatmapLayerAtom = useSetRecoilState(heatmapLayerState);
    const setZoomLevel = useSetRecoilState(zoomLevelState);

    const floorId = useRecoilValue(floorIdState);
    const levels = useRecoilValue(levelsSelector);

    const [olMap, setOlMap] = useState(undefined);
    const [mapIds, setMapIds] = useState(undefined);

    const { floorIdToMapOverlayLayersHash, updateOverlayLayersVisibility } = useMapOverlay();

    const tileLayer = useMemo(() =>
    {
        const tileStyle = deepValue(mapData, "style.tileLayer", undefined);
        return cartoLayer(tileStyle, { trans, transLookup: "Attributions" });
    }, [mapData, trans]);

    /**
     * On Mount
     */
    React.useEffect(
        () =>
        {
            // Map set up
            const attribution = new Attribution({
                collapsible: true,
            });

            const newOlMap = new Map({
                target: null,
                layers: [tileLayer],
                controls: defaultControls({
                    attribution: false,
                    zoom: false,
                    rotate: false,
                }).extend([attribution]),
                view: new View({
                    center: [0, 0],
                    zoom: 4,
                    maxZoom: MapConstants.MAX_ZOOM,
                    minZoom: MapConstants.MIN_ZOOM,
                }),
            });

            newOlMap.setTarget(mapRef.current);

            newOlMap.on("postrender", async (e) => onMapRender(e, newOlMap));

            setOlMap(newOlMap);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * on (mapData, floorId, olMap) change -> draw new entity layers, pan to plot
     */
    React.useEffect(
        () =>
        {
            const { propertyId: mapPropertyId, buildingId: mapBuildingId, floorId: mapFloorId } = mapData;

            if (
                mapPropertyId &&
                mapPropertyId === mapIds?.propertyId &&
                mapBuildingId === mapIds?.buildingId &&
                mapFloorId === mapIds?.floorId
            )
            {
                return;
            }

            onMapDataUpdate();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapOptions, mapData, olMap]
    );

    React.useEffect(() =>
    {
        onMapDataUpdate();
    }, [floorIdToMapOverlayLayersHash]);

    React.useEffect(() =>
    {
        drawHeatmapLayer(heatmapLayer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heatmapLayer, olMap]);

    React.useEffect(() =>
    {
        onMapDataUpdate();
        // eslint-disable-next-line
    }, [mapOptions]);

    /**
     * On mapOptions.names toggle -> show/hide text layer
     */
    React.useEffect(
        () =>
        {
            let namesOptions = mapOptions.names;

            if (entityLayers?.[MapConstants.TEXT_LAYER])
            {
                entityLayers[MapConstants.TEXT_LAYER].setVisible(namesOptions.value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapOptions.names]
    );

    React.useEffect(() =>
    {
        let overlayOptions = mapOptions.overlays;

        updateOverlayLayersVisibility(entityLayers, overlayOptions.value);

    }, [mapOptions.overlays]);

    /**
     * On mapOptions.style toggle -> apply/
     */
    // React.useEffect(
    //     () => {
    //         let deafultStyleOption = mapOptions.names;

    //         // todo change style
    //     },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [mapOptions.style]
    // );

    const onMapDataUpdate = () =>
    {
        const {
            building,
            property,
            // style,
            imageBaseUrl,
            propertyId: mapPropertyId,
            buildingId: mapBuildingId,
            floorId: mapFloorId,
        } = mapData;

        const mapOverlayLayers = floorIdToMapOverlayLayersHash[floorId];
        let customStyle = mapOptions.style.value ? STYLES[STYLE_TYPES.CLASSIC] : STYLES[STYLE_TYPES.BLANK];

        //floor view
        if (!!building && !!floorId && !!olMap)
        {
            // get map layers
            let { entityLayers, imageLayers, boundaryPolygon } = createEntityVectorLayers(
                {
                    entities: building.floors[floorId],
                    style: customStyle.building
                },
                imageBaseUrl,
                { theme: MAP_THEMES.CLASSIC }
            );

            // draw new map layers
            drawEntityLayers(entityLayers, imageLayers, heatmapLayer, mapOverlayLayers);

            // change map boundary
            changeMapCenterWithBoundaryPolygon({
                olMap,
                boundaryPolygon: boundaryPolygon,
                padding: MapConstants.FIT_PADDING_MAP_DATA,
            });
            setMapIds({ propertyId: mapPropertyId, buildingId: mapBuildingId, floorId: mapFloorId });
        }
        // property view
        else if (!!property && !!olMap)
        {
            // get map layers
            const { entityLayers, imageLayers, boundaryPolygon } = createEntityVectorLayers(
                { entities: property.entities, style: customStyle.property },
                imageBaseUrl,
                { theme: MAP_THEMES.CLASSIC }
            );

            // draw new map layers
            drawEntityLayers(entityLayers, imageLayers, heatmapLayer, mapOverlayLayers);

            // change map boundary
            changeMapCenterWithBoundaryPolygon({
                olMap,
                boundaryPolygon: boundaryPolygon,
                padding: MapConstants.FIT_PADDING_MAP_DATA,
            });
            setMapIds({ propertyId: mapPropertyId, buildingId: mapBuildingId, floorId: mapFloorId });
        }
    };

    // on map render -> update heatmap radius and blurr size to be constant through many zoom levels
    const onMapRender = useRecoilCallback(({ snapshot }) => async (e, olMap) =>
    {
        // point spacing needs to be in atom to be pulled from snapshot
        const zoomLevelSnapshot = await snapshot.getPromise(zoomLevelState);

        let newZoom = olMap.getView().getZoom();

        // on zoom change
        if (zoomLevelSnapshot !== newZoom)
        {
            const heatmapLayerSnapshot = await snapshot.getPromise(heatmapLayerState);
            const propertyCentroid = (await snapshot.getPromise(propertyCentroidMercatorSelector)) || [0, 0];


            // if no heatmap layer
            if (!heatmapLayerSnapshot)
            {
                return;
            }

            // set atom zoom level state
            setZoomLevel(newZoom);

            setHeatmapRadiusAndBlurOnMapRender({
                heatmapLayer: heatmapLayerSnapshot,
                olMap,
                centroidMercator: propertyCentroid,
                radius: pointSpacing,
            });
        }
    });

    /**
     * Adds newEntityLayers imageLayers to map
     * @param {*} newEntityLayers
     * @param {*} imageLayers
     */
    const drawEntityLayers = (newEntityLayers, imageLayers, heatmapLayer, mapOverlayLayers) =>
    {
        let layersToAdd = [tileLayer];

        Object.keys(newEntityLayers).forEach((layerId) =>
        {
            if (layerId !== MapConstants.HIDDEN_LAYER)
            {
                const layer = newEntityLayers[layerId];

                if (layerId === MapConstants.TEXT_LAYER)
                {
                    // heatmap layer pushed before text layer
                    if (heatmapLayer)
                    {
                        layersToAdd.push(heatmapLayer);
                    }

                    layer.setVisible(mapOptions?.names?.value);
                    layer.setZIndex(1001);
                }

                layersToAdd.push(layer);
            }
        });

        if (Array.isArray(mapOverlayLayers))
        {
            mapOverlayLayers.forEach((mapOverlayLayer, i) =>
            {
                mapOverlayLayer.setVisible(mapOptions?.overlays.value);
                layersToAdd.push(mapOverlayLayer);
                newEntityLayers[`overlay ${i}`] = mapOverlayLayer;
            });
        }

        if (!!imageLayers)
        {
            Object.keys(imageLayers).forEach((imageLayerId) =>
            {
                const layer = imageLayers[imageLayerId];
                layersToAdd.push(layer);
                newEntityLayers[imageLayerId] = layer;
            });
        }

        setMapLayers(olMap, layersToAdd);
        setEntityLayers(newEntityLayers);
        if (!!heatmapLayer)
        {
            setupHeatmapLayer(heatmapLayer);
        }
    };

    const drawHeatmapLayer = (heatmapLayer) =>
    {
        if (olMap)
        {
            // get layers from olmap
            let layers = olMap.getLayerGroup().getLayers();

            // look for any existing heatmap layers
            layers.forEach((layer) =>
            {
                if (layer?.get("id") === "HeatmapLayer")
                {
                    // remove heatmap layer from layer collection
                    layers.remove(layer);

                    // manually disponse of heatmap renderer as it was not doing so on its own
                    if (layer?.renderer_)
                    {
                        layer.renderer_.dispose();
                        delete layer.renderer_;
                    }
                }
            });

            // add new heatmap layer to map
            if (!!heatmapLayer)
            {
                olMap.addLayer(heatmapLayer);
                setupHeatmapLayer(heatmapLayer);
            }
        }
    };

    /**
     * Adds heatmap layer to map
     * @param {*} heatmapLayer
     */
    const setupHeatmapLayer = useRecoilCallback(({ snapshot }) => async (heatmapLayer) =>
    {
        if (!!heatmapLayer)
        {
            const propertyCentroid = (await snapshot.getPromise(propertyCentroidMercatorSelector)) || [0, 0];

            setHeatmapRadiusAndBlurOnMapRender({
                heatmapLayer: heatmapLayer,
                olMap,
                centroidMercator: propertyCentroid,
                radius: pointSpacing,
            });
            olMap.changed();
            setHeatmapLayerAtom(heatmapLayer);
        }
    });

    // function to render floor buttons on map
    const renderFloorButtons = React.useCallback(() =>
    {
        if (!!Array.isArray(levels))
        {
            return <FloorsButtons />;
        }
    }, [levels]);

    return (
        <div className="mapContainer" ref={mapRef}>
            {/* Anything that displays over the map element should be sent through children prop */}
            {!!olMap && (
                <>
                    <MapOptionToggles mapOptions={mapOptions} setMapOptions={setMapOptions} />
                    {showHeatMapScale && <HeatMapScale />}
                    {renderFloorButtons()}
                </>
            )}
        </div>
    );
};
