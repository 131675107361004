export const BLUES =
{
    default: "#727AFF",
    light1: "#8E94FB",
    light2: "#8E94FB",

    categoryShade1: "#737AFA",
    categoryShade2: "#858BFB",
    categoryShade3: "#999EFB",
    categoryShade4: "#A3A9FC",
    categoryShade5: "#B9BBFD",
    categoryShade6: "#CCCFFD",
}
export const GREENS =
{
    default: "#29AD73",
    light1: "#53BD8F",
    light2: "#53BD8F",

    categoryShade1: "#29AD73",
    categoryShade2: "#2FC685",
    categoryShade3: "#39D08F",
    categoryShade4: "#49D498",
    categoryShade5: "#5AD8A1",
    categoryShade6: "#6ADCAB",
}


export const ORANGES =
{
    default: "#F15944",
    light1: "#F37A69",
    light2: "#F37A69",
};


export const GREYS =
{
    default: "#667581",

    categoryShade1: "#969EB9",
    categoryShade2: "#878EAD",
    categoryShade3: "#7981A4",
    categoryShade4: "#6B749A",
    categoryShade5: "#61698D",
    categoryShade6: "#575F7E",
};

export const PURPLES =
{
    default: "#AD74FA",
};


export const PEAK_TIMES_COLORS = [
    BLUES.categoryShade6,
    BLUES.light2,
];

export const CATEGORY_COLORS = [
    GREENS.light1,
    BLUES.light1,
];

export const DEVICES_COLORS = [
    GREYS.categoryShade6,
    GREYS.categoryShade5,
    GREYS.categoryShade4,
    GREYS.categoryShade3,
    GREYS.categoryShade2,
    GREYS.categoryShade1,

];

export const COLORS_LIST_1 = [
    GREYS.default,
    BLUES.default,
    GREENS.default,
    ORANGES.default,
    PURPLES.default,
    BLUES.light1,
    GREENS.light1,
    ORANGES.light1,
    BLUES.light2,
    GREENS.light2,
    ORANGES.light2
];

export const COLORS_LIST_2 = [
    BLUES.light2,
    GREENS.light2,
    ORANGES.light2,
    PURPLES.default,
];

export const NOT_IN_ZONE_GEOFENCE_COLOR = "rgba(241, 89, 68,1)";


// export const ZONE_GEOFENCE_DEFAULT_STYLES = [
//     {
//         stroke: {
//             color: "rgba(0,0,175,1)",
//             width: 2
//         },
//         fill: {
//             color: "rgba(0,0,175,0.1)",
//         },
//         color: "rgba(0,0,175,1)",
//     }, {
//         stroke: {
//             color: "rgba(0,175, 0,1)",
//             width: 2
//         },
//         fill: {
//             color: "rgba(0,175,0,0.1)",
//         },
//         color: "rgba(0,175, 0,1)",
//     }, {
//         stroke: {
//             color: "rgba(175,0,0,1)",
//             width: 2
//         },
//         fill: {
//             color: "rgba(175,0,0,0.1)",
//         },
//         color: "rgba(175,0,0,1)",
//     }
// ];





