
import React from "react";
import { ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.


export const PieChart = ({ customLegendChildren = null, data, options = {}, customArcLinkLabel, customTooltip, onClick, id = "id", value = "value" }) =>
{
    const chartOptions = {
        margin: options.margin || { top: 40, right: 20, bottom: 20, left: 20 },
        sortByValue: options.sortByValue || true,
        innerRadius: 0.5,
        padAngle: 1,
        cornerRadius: 3,
        activeOuterRadiusOffset: 8,
        colors: options.colors || { scheme: 'paired' },
        borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
        arcLinkLabelsSkipAngle: 10,
        // arcLinkLabelsTextColor: "#333333",
        arcLinkLabelsThickness: 2,
        arcLinkLabelsColor: { from: 'color' },
        enableArcLinkLabels: options.enableArcLinkLabels,
        tooltip: customTooltip,
        arcLinkLabel: customArcLinkLabel,
        enableArcLabels: options.enableArcLabels,
        arcLabelsSkipAngle: 6,
        arcLabelsTextColor: { from: 'color', modifiers: [['darker', 2]] },
        isInteractive: true,
        legends: options?.legends ?? undefined,
        legendLabel: options?.legendLabel,
    };


    if (!!customLegendChildren)
    {
        chartOptions.legends = undefined;
    }

    return <>
        <ResponsivePie
            data={data}
            {...chartOptions}
            onClick={onClick}
            id={id}
            value={value}
        />
        {customLegendChildren}
    </>;
}; 