import React, { memo } from "react";
import { NumberWidgetConditionalLoader } from "../../common/NumberWidgetCommon";
import { OverviewDetailBox } from "../../common/overviewDetailBox/OverviewDetailBox";
import Translation from "../../common/translation/Translation";
import useWebEnagamentNumberWidget from "./useWebEnagamentNumberWidget";

export const AverageVisitIntervalNumberWidgetWebEngagement = memo(() =>
{

    const { changeInPageVisitInterval, avgVisitPageInterval, avgVisitPageIntervalUnits, rawQuery } = useWebEnagamentNumberWidget();

    return <>
        <NumberWidgetConditionalLoader
            rawQuery={ rawQuery }
            errorChildren={ <OverviewDetailBox
                appOnly
                max="220px"
                popupContent={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.popupContent" /> }
                icon="widget-pie"
                content={ "--" }

                percentage={ 0 }
                slogan={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.slogan" /> }
            /> }
            noDataChildren={
                <OverviewDetailBox
                    appOnly
                    max="220px"
                    popupContent={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.popupContent" /> }
                    icon="widget-pie"
                    content={ "--" }

                    percentage={ 0 }
                    slogan={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.slogan" /> }
                />
            }

        >
            <OverviewDetailBox
                appOnly
                max="220px"
                popupContent={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.popupContent" /> }
                icon="widget-pie"
                content={ ["", ...avgVisitPageInterval] }
                extra={ [<Translation text={ "Words.every" } />, ...avgVisitPageIntervalUnits] }
                percentage={ changeInPageVisitInterval }
                slogan={ <Translation text="Widgets.AverageVisitIntervalNumberWidgetWebEngagement.slogan" /> }
            />
        </NumberWidgetConditionalLoader>



    </>
})