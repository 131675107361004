import React, { useCallback, useState } from "react";
import useInsights from "./useInsights";
import { LineChart } from "../../charts/LineChart";
import { INSIDES_LINE_CHART_OPTIONS, INSIDES_LINE_CHART_OPTIONS_DURATION } from "../../../_constants/chartConstants";
import { TrajectoryThresholds } from "../../../_constants/insightsConstants";
import { ErrorWidgetMessage, WidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import { convertSecondsTo_MMSS } from "../../../_utils/date.utils";
import { InfoPopup } from "../../common/InfoPopup";
import { useRecoilState } from "recoil";
import { hoveredPointIdState } from "../../../store/MapAtoms";
import { useTranslation } from "react-i18next";


export const InsightsChart = () =>
{
    const trajectory = useInsights();
    const [displayedChartDataToggle, setDisplayedChartDataToggle] = useState(false);
    const [hoveredPointId, setHoveredPointId] = useRecoilState(hoveredPointIdState);
    const trans = useTranslation().t;

    const { isLoading, isError, isIdle, data } = trajectory.userSessionTrajectoryQueryCall;

    /**
     * Toggle boolean displayedChartData. Handles which data set is to be displayed on the chart.
     */
    const handleDisplayedChartDataToggle = useCallback(() =>
    {
        setDisplayedChartDataToggle((oldValue) => !oldValue);
    }, [setDisplayedChartDataToggle]);

    /**
     * chart onMouseLeave callback
     */
    const handleMouseLeaveChart = useCallback(() =>
    {
        if (hoveredPointId)
        {
            setHoveredPointId(undefined);
        }
    }, [hoveredPointId, setHoveredPointId]);

    /**
     * Render function for tooltip passed to chart component
     */
    const customSliceTooltip = React.useCallback(({ slice }) =>
    {
        let point = slice.points.find(point => point.id.includes("trajectory"));

        // set hovered point ID if it is different from prev hover point id
        let pointId = point?.data?.id;
        if (pointId !== hoveredPointId)
        {
            setHoveredPointId(pointId);
        }

        let total_timeSpent_s = Math.floor(point.data.y * 60);
        let timeSpent = convertSecondsTo_MMSS(total_timeSpent_s);

        let color = TrajectoryThresholds.GREEN;

        // if (total_timeSpent_s > TrajectoryThresholds.RED)
        // {
        //     color = TrajectoryColor.RED;
        // }
        // else if (total_timeSpent_s > TrajectoryThresholds.ORANGE)
        // {
        //     color = TrajectoryColor.ORANGE;
        // }
        // else if (total_timeSpent_s > TrajectoryThresholds.GREEN)
        // {
        //     color = TrajectoryColor.GREEN;
        // }

        let displayedUnit;

        if (displayedChartDataToggle)
        {
            displayedUnit = <strong> {convertSecondsTo_MMSS(point.data.x * 60)} </strong>;
        }
        else
        {
            displayedUnit = <strong>{point.data.x} <small>{trans("Words.meters")}</small></strong>;
        }

        return (
            <div className="widgetsTooltipBox">
                {displayedUnit}
                {/* <div className="tooltipItemRow" key={point.id}>
                    <span style={{ background: color }}></span>
                    {timeSpent}
                </div> */}
            </div>
        );
    }, [displayedChartDataToggle, hoveredPointId, setHoveredPointId, trans]);
    /**
     * Renders the displayed chart.
     * The data set shown is determined by boolean displayedChartData
     */
    const renderChartData = useCallback(() =>
    {
        if (trajectory.trajectoryChartData)
        {
            let chartData = trajectory?.trajectoryChartData?.durationChart;
            let chartOptions = INSIDES_LINE_CHART_OPTIONS_DURATION;
            let customXTickValues = trajectory.trajectoryChartData.customXTickValuesDuration;

            if (!displayedChartDataToggle)
            {
                chartData = trajectory?.trajectoryChartData?.distanceChart;
                chartOptions = INSIDES_LINE_CHART_OPTIONS;
                customXTickValues = trajectory.trajectoryChartData.customXTickValuesDistance;
            }

            return (
                <LineChart
                    data={chartData}
                    options={chartOptions}
                    customSliceTooltip={customSliceTooltip}
                    customAxisTickValues={customXTickValues}
                    onMouseLeave={handleMouseLeaveChart}
                />);
        }
    }, [customSliceTooltip, displayedChartDataToggle, trajectory?.trajectoryChartData, handleMouseLeaveChart]);

    if (isLoading || isIdle)
    {
        return (
            <div className="insightsChartWrap">
                <div className="insightsLoadingMessage">
                    <WidgetMessage customTitle="Loading..." customMessage="Our software has a lot of great data to process!" />
                </div>
            </div>
        );
    }
    else if (isError)
    {
        return (
            <div className="insightsChartWrap insightsChartWrapError">
                <ErrorWidgetMessage onReloadClick={trajectory.handleRefreshTrajectoryQuery} />
            </div>
        );
    }
    else if (!data)
    {
        return (
            <div className="insightsChartWrap">
                <div className="insightsLoadingMessage">
                    <WidgetMessage />
                </div>
            </div>
        );
    }
    else if (trajectory?.trajectoryChartData?.hasData)
    {
        return (
            <div className="insightsChartWrap">
                <div className="insightsChartbox">
                    <div className="insightsChartPopup">

                        <div className="insightsChartItem">
                            {trans("Insights.Duration")}
                            <div className="rightCorner">
                                <InfoPopup basic className="infoPopupleft" position="top right" content={trans("Insights.ToolTips.DURATION")} />
                            </div>
                            <strong>{trajectory.trajectoryChartData.timeSpent}</strong>
                        </div>

                        <div className="insightsChartItem">
                            {trans("Insights.Total_Distance")}
                            <div className="rightCorner">
                                <InfoPopup basic className="infoPopupleft" position="top right" content={trans("Insights.ToolTips.TOTAL_DISTANCE")} />
                            </div>
                            <strong>{trajectory.trajectoryChartData.distance}</strong>
                        </div>

                        <div className="insightsChartItem">
                            {trans("Insights.Start")}
                            <div className="rightCorner">
                                <InfoPopup basic className="infoPopupleft" position="top right" content={trans("Insights.ToolTips.START")} />
                            </div>
                            <span>{trajectory.trajectoryChartData.startTime}</span>
                        </div>

                        <div className="insightsChartItem">
                            {trans("Insights.End")}
                            <div className="rightCorner">
                                <InfoPopup basic className="infoPopupleft" position="top right" content={trans("Insights.ToolTips.END")} />
                            </div>
                            <span>{trajectory.trajectoryChartData.endTime}</span>
                        </div>
                    </div>

                    <div className="insightsChartWrapper" onMouseOut={handleMouseLeaveChart}>
                        {renderChartData()}
                    </div>
                    <div className="rightCorner">
                        <InfoPopup basic className="infoPopupleft" position="top left" content={trans("Insights.ToolTips.DISTANCE_CHART")} />
                        {/* <div>
                            <Popup basic className="infoPopupleft"
                                trigger={
                                    <div className="toggleTooltipInsight">
                                        <Radio
                                            toggle
                                            onChange={handleDisplayedChartDataToggle}
                                            checked={displayedChartDataToggle}
                                        /></div>}
                                position="top left"
                                content={`${displayedChartDataToggle ? trans("Insights.Duration") : trans("Insights.Distance")}`} />
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }

    return null;
};
