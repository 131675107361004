import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSessions } from '../../../_api/queries';
import { processWebAnalyticsSessionDurationVsTimeWidget } from '../../../_utils/chart.utils';
import useCommon from '../../pages/useCommon';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const UseAverageSessionVsTimeWebEngagement = () =>
{
	const { timeZone, filters: { analyticsRequestFilter } } = useCommon();


	const sessionsQuery = useWebAnalyticsSessions();

	const processedWidgetData = useMemo(() =>
	{
		const { isSuccess, data: queryData } = sessionsQuery;


		let processedData = { lineData: [], averageDurationFormatted: [], percentageOfChange: "" };

		// process the data
		if (!!isSuccess && queryData)
		{
			const { currentIntervalData: data, previousIntervalData: compareData } = queryData || {};
			const dateRange = {
				startDate: new Date(analyticsRequestFilter.startTimeUTC),
				endDate: new Date(analyticsRequestFilter.endTimeUTC),
			};

			processedData = processWebAnalyticsSessionDurationVsTimeWidget({ data, compareData, dateRange, timeZone });
		}

		return { ...sessionsQuery, data: processedData };
	}, [sessionsQuery, timeZone, analyticsRequestFilter]);


	return { sessionsQuery, processedWidgetData, analyticsRequestFilter };

};

export default UseAverageSessionVsTimeWebEngagement;