import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import serverApi from "../../_api/server.api";
import { environmentConstantsState } from "../../store/AppAtoms";
import { allTimeFilterSelector, hasAccessToPropertySelector } from "../../store/DashboardAtoms";
import { useQuery } from "react-query";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { ReportsOverviewDetail } from "../reports/ReportsOverviewDetail";
import { processEngagementOverviewWidget } from "../../_utils/chart.utils";
import { useAllTimeEngagementOverviewQuery } from "../../_api/queries";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { ENGAGEMENT_MOBILE_NUMBER_WIDGET_DATA_KEY_VALUE } from "../../_constants/exportToCsvConstants";
import { formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";

/**
 * This component shows data for all time regardless of what is selected in date range
 */
export const EngagementOverviewWidget = ({ injectProcessedData }) =>
{
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const allTimeAnalyticsRequestFilter = useRecoilValue(allTimeFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);


    const engagementOverviewQuery = useAllTimeEngagementOverviewQuery();

    // add downloads
    const appDownloadsQuery = useQuery(["All Time Downloads", NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS, { allTimeAnalyticsRequestFilter, environmentConstants }], async () =>
    {
        if (allTimeAnalyticsRequestFilter)
        {
            const result = await serverApi.getNavigationAnalyticsData(allTimeAnalyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS, environmentConstants);

            const data = result?.data?.appDownloadRecords || [];

            if (!result?.success)
            {
                throw new Error(`${NAV_ANALYTICS_QUERY_KEYS.GENERAL_TOTAL_DOWNLOADS} response was not ok`);
            }
            return data;
        }
    }, {
        enabled: (!!allTimeAnalyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });

    // render widget/loading/error

    const processedWidgetData = useMemo(() =>
    {
        const isLoading = engagementOverviewQuery.isLoading || appDownloadsQuery.isLoading;
        const isError = engagementOverviewQuery.isError || appDownloadsQuery.isError;
        const isSuccess = engagementOverviewQuery.isSuccess && appDownloadsQuery.isSuccess;


        let processedData;

        // process the data
        if (!!isSuccess)
        {
            processedData = processEngagementOverviewWidget({ engagementOverviewData: engagementOverviewQuery.data, appDownloadsData: appDownloadsQuery.data });
        }

        return { isLoading, isSuccess, isError, data: processedData };
    }, [engagementOverviewQuery?.data, appDownloadsQuery?.data]);



    const renderEngagmenSummaryWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedWidgetData;

        return (
            // change to template
            <ReportsOverviewDetail isError={isError} isSuccess={isSuccess} data={data} connectedPageName={connectedPageName} injectProcessedData={injectProcessedData} />
        );

    }, [processedWidgetData, connectedPageName]);


    return (
        <React.Fragment>
            {
                renderEngagmenSummaryWidget()
            }
        </React.Fragment>
    );
};