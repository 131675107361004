import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import
{
    environmentConstantsState,
    languageCodeState,
} from "../../store/AppAtoms";
import
{
    analyticsRequestFilterSelector,
    buildingIdState,
    considerTimeInDateRangeState,
    dateRangeState,
    floorIdState,
    hasAccessToPropertySelector,
    levelsSelector,
    propertyIdState,
    propertyTimeZoneSelectorState,
    activeTimeZoneSelectorState,
    usersPropertiesSelector,
} from "../../store/DashboardAtoms";

const useCommon = () =>
{
    const languageCode = useRecoilValue(languageCodeState);
    const trans = useTranslation().t;
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);
    const usersProperties = useRecoilValue(usersPropertiesSelector);
    const propertyId = useRecoilValue(propertyIdState);
    const buildingId = useRecoilValue(buildingIdState);
    const filters = useRecoilValue(analyticsRequestFilterSelector);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    const floorId = useRecoilValue(floorIdState);
    const [considerTimeInDateRange, setConsiderTimeInDateRange] = useRecoilState(
        considerTimeInDateRangeState
    );
    const [dateRange, setDateRange] = useRecoilState(dateRangeState);
    const levels = useRecoilValue(levelsSelector);

    const propertyTimeZoneId = useRecoilValue(propertyTimeZoneSelectorState);
    return {
        dateRange,
        languageCode,
        trans,
        environmentConstants,
        hasAccessToProperty,
        propertyId,
        buildingId,
        filters,
        timeZone,
        floorId,
        setDateRange,
        considerTimeInDateRange,
        usersProperties,
        levels,
        setConsiderTimeInDateRange,
        propertyTimeZone: { id: propertyTimeZoneId, name: propertyTimeZoneId }
    };
};

export default useCommon;
