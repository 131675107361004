import { ResponsiveBar } from "@nivo/bar";
import React from "react";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
export const BarChart = ({
    data,
    keys = [],
    index,
    customAxisLeft,
    options = {},
    customTooltip,
    customTick,
    customLegendFormat,
    valueFormat,
    onClick = (e) =>
    {
        console.error("NO handler");
        console.log(e);
    },
    CustomLabelChildren = null
}) =>
{
    const chartOptions = {
        margin: options?.margin || { top: 20, right: 20, bottom: 20, left: 20 },
        padding: 0.3,
        layout: options?.layout,
        indexScale: { type: "band", round: true },
        colors: options?.colors,
        colorBy: options?.colorBy || "index",
        tooltip: customTooltip,
        valueScale: options.valueScale,
        borderColor: { from: "color", modifiers: [["darker", 1.6]] },
        axisTop: null,
        axisRight: null,
        axisBottom: {
            renderTick: customTick,
            tickSize: options?.axisBottom?.tickSize || 0,
            tickPadding: options?.axisBottom?.tickPadding || 0,
            tickRotation: options?.axisBottom?.tickRotation || 0,
            legend: options?.axisBottom?.legend || "",
            legendPosition: options?.axisBottom?.legendPosition || "middle",
            legendOffset: options?.axisBottom?.legendOffset || 32,
        },
        axisLeft: customAxisLeft || options?.axisLeft,
        enableGridY: options?.enableGridY,
        enableGridX: options?.enableGridX,
        enableLabel: options?.enableLabel ?? false,
        label: options?.label,
        labelSkipWidth: 12,
        labelSkipHeight: 12,
        labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
        legends: !CustomLabelChildren ? options?.legends : [],
        legendLabel: options?.legendLabel,
        animate: options?.animate ?? true,
        motionStiffness: 90,
        motionDamping: 15,
        theme: {
            axis: {
                ticks: {
                    "text": {
                        "fontSize": data.length < 45 ? 11 : 6,
                    }
                }
            }
        }
    };

    return <>
        <ResponsiveBar onClick={onClick} data={data} keys={keys} indexBy={index} {...chartOptions} />
        {CustomLabelChildren}
    </>;


};
