const tinyColor = require("tinycolor2");
const { DEFAULT_LANGUAGE_CODE } = require("../utils/map.constants");

exports.getFillSettings = (style) =>
{
    const color = tinyColor(style.fillColor);
    color.setAlpha(style.fillOpacity);

    return {
        color: color.toRgbString()
    };
};

exports.getStrokeSettings = (style) =>
{
    const color = tinyColor(style.strokeColor);
    color.setAlpha(style.strokeOpacity);

    return {
        color: color.toRgbString(),
        width: style.strokeWidth
    };
};

exports.getTextFontSettings = ({ size, fontName }) =>
{
    return `bold ${size}px ${fontName}`;
};

exports.groupStylesOnLocation = (styles, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let groupedStyles = {
        property: {},
        building: {}
    };

    styles.forEach((style) =>
    {
        const target = style.target;

        const styleSettings = style.styleSettings[lang];

        if (!groupedStyles[target.locationType][target.entityType])
        {
            groupedStyles[target.locationType][target.entityType] = {};
        }

        groupedStyles[target.locationType][target.entityType][target.subEntityType] = {
            polygon: styleSettings.polygon,
            text: styleSettings.text,
        }
    });

    return groupedStyles;
};

exports.getFillObjFromFields = ({ fillColor, fillOpacity }) =>
{
    const color = tinyColor(fillColor);
    color.setAlpha(fillOpacity);

    return {
        color: color.toRgbString()
    };
};

exports.getStrokeObjFromFields = ({ strokeColor, strokeOpacity, strokeWidth }) =>
{
    const color = tinyColor(strokeColor);
    color.setAlpha(strokeOpacity);

    return {
        color: color.toRgbString(),
        width: strokeWidth
    };
};

exports.getFillFields = (fill) =>
{
    const color = tinyColor(fill.color);
    const fillOpacity = color.getAlpha();

    return {
        fillColor: color.toHexString(),
        fillOpacity
    };
};

exports.getStrokeFields = (stroke) =>
{
    const color = tinyColor(stroke.color);
    const strokeOpacity = color.getAlpha();

    return {
        strokeColor: color.toHexString(),
        strokeOpacity: strokeOpacity,
        strokeWidth: stroke.width
    };
}