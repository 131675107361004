import { useCallback, useEffect, useMemo, useState } from "react";
import { deepCopy } from "mapsted.utils/objects";
import { EXPORTCSV_WIDGET_ITEMS_PER_PAGE_OPTIONS } from "../../../_constants/exportToCsvConstants";
import { useRecoilValue } from "recoil";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";

const useExportDataPaginator = (widgetsToDisplay = {}, debouncedWidgetSearchFilter) =>
{

    const [activePage, setActivePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(EXPORTCSV_WIDGET_ITEMS_PER_PAGE_OPTIONS[4].value);
    const connectedPageName = useRecoilValue(connectedPageNameState);


    // Index of currently selected Tab 
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    useEffect(() =>
    {
        setActivePage(1);
        /* "debouncedWidgetSearchFilter - rerenders when search is triggered"  */    
    }, [connectedPageName, debouncedWidgetSearchFilter]);

    const widgets = useMemo(() =>
    {
        return Object.entries(widgetsToDisplay);
    }, [widgetsToDisplay]);

    /* get widgets for current selected Tab */
    const totalWidgetsToDisplayArray = useMemo(() =>
    {
        return widgets?.length > 0 ? Object.values(widgets?.[activeTabIndex]?.[1] || {}) : [];
    }, [widgets, activeTabIndex]);

    /* Pagination logic */
    const paginatedData = useMemo(() =>
    {
        let idxStart = (activePage - 1) * itemsPerPage;
        let idxEnd = activePage * itemsPerPage;
        return totalWidgetsToDisplayArray.slice(idxStart, idxEnd);
    }, [totalWidgetsToDisplayArray, activePage, itemsPerPage]);

    /* modify existing object to have new paginated data */
    if (widgets[activeTabIndex])
    {
        widgets[activeTabIndex][1] = paginatedData;
    }

    /* calculate totalPages and Items */
    const paginationHelper = useMemo(() =>
    {
        let numberOfWidgets = totalWidgetsToDisplayArray.length;
        return {
            totalPages: Math.ceil(numberOfWidgets / itemsPerPage),
            totalItems: numberOfWidgets,
        };
    }, [totalWidgetsToDisplayArray, itemsPerPage]);

    /* updates states to update per-page item count */
    const handleItemsPerPageChange = useCallback(
        (e, { value }) =>
        {
            setActivePage(1);
            setItemsPerPage(value);
        },
        [setItemsPerPage, setActivePage]
    );

    /* handle page no. changes */
    const handlePageChange = useCallback(
        (e, { activePage }) =>
        {
            setActivePage(activePage);
        },
        [setActivePage]
    );

    return {
        paginatedData: deepCopy(widgets),
        paginationHelper,
        activePage,
        itemsPerPage,
        handleItemsPerPageChange,
        handlePageChange,
        activeTabIndex,
        setActiveTabIndex,
        setActivePage
    };
};

export default useExportDataPaginator;