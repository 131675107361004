// eslint-disable-next-line
import
{
    DateTime,
    Duration,
} from "luxon";
import { UTC_FORMAT } from "./date.utils";
/**
 * Important note
 * any references to luxon's date object should be written as "...dateTime"
 * any references to JS date object should be written as "...date"
 */

/**
 * takes date range and returns utc strings for anayltics filters
 * @param {Date} param0.startDate
 * @param {Date} param0.endDate
 * @param {Boolean} param0.considerTimeInDateRange
 * @param {Object} timeZone
 * @returns
 */
export const dateRangeToUTCStrings = (
    { startDate, endDate, considerTimeInDateRange },
    timeZone
) =>
{
    // console.log("has a time zone-->....", timeZone);
    if (!!startDate && !!endDate)
    {
        // if we are considering custom times then we need to set whatever time the user picked to the property's timezone without converting the time
        // else we use UTC for all system times
        if (timeZone?.id)
        {
            timeZone = timeZone.id;
        }
        else
        {
            timeZone = "UTC";
        }

        // 10pm (locale TZ) -> 10pm (propertyTZ)
        let startDateTime = createDateTimeZoneDateFromJSDate(
            startDate,
            timeZone
        );
        let endDateTime = createDateTimeZoneDateFromJSDate(endDate, timeZone);


        // make sure that end date is ahead of start date
        // not sure if this is still needed
        if (endDate < startDate)
        {
            endDateTime = startDateTime.endOf("day");
            if (isDateTimeToday(endDateTime, timeZone))
            {
                // some issues with nav apis ("date is in the future")
                endDateTime = createDateTimeZoneDateFromJSDate(
                    new Date(),
                    timeZone
                );
            }
        }

        // if we are not using custom times we want to set
        if (!considerTimeInDateRange)
        {
            // set startDate hour to 0
            startDateTime = startDateTime.startOf("day");

            // if end date is current date, the last possible time it can be is the current time in its time zone
            // else set to end of day.
            if (isDateTimeToday(endDateTime, timeZone))
            {
                // some issues with nav apis ("date is in the future")
                endDateTime = createDateTimeZoneDateFromJSDate(
                    getLatestJsDateBasedOnTimeZone(timeZone),
                    timeZone
                );
            }
            else
            {
                endDateTime = endDateTime.endOf("day");
            }
        }
        else
        {
            //// we subtract 1 second from end time for a correct API response.
            const currentTime = createDateTimeZoneDateFromJSDate(
                getLatestJsDateBasedOnTimeZone(timeZone),
                timeZone
            ).minus({
                second: 1
            });

            //this condition is mostly gets triggered when the date range gor altered by zoneGeofence history
            endDateTime = (endDateTime > currentTime) ? currentTime : endDateTime;
        }


        // get diff days for compare start date
        let diffDays = endDateTime.diff(startDateTime).as("days") + 1;

        let compareStartDateTime = startDateTime.plus({ days: -diffDays });
        // get all utc strings for filter
        let startTimeUTC = formatDateTimeToUTCString(startDateTime);
        let endTimeUTC = formatDateTimeToUTCString(endDateTime);
        let compareStartTimeUTC =
            formatDateTimeToUTCString(compareStartDateTime);

        return {
            startTimeUTC,
            endTimeUTC,
            compareStartTimeUTC,
            compareEndTimeUTC: startTimeUTC,
        };
    }
    else
    {
        // console.error("dateRangeToDateTime: date range does not contain 2 dates in the format 'startDate - endDate'.");
        return {};
    }
};

/**
 * Given a javascript date and a time zone id, this function creates a DateTime object in that timezone.
 * For example, if a user selects 2023-07-01T10:00 in their local timezone, and you want to represent this
 * date in a different timezone then you can use this function to create a DateTime object in that timezone.
 *
 * @param {Date} date - javascript date to be converted to DateTime in the timezone selected
 * @param {string} timeZoneId - the id of the timezone to be used
 * @return {DateTime} DateTime in the selected timezone
 */
export const fromJsDateToDateTime = (date, timeZoneId) => DateTime.fromJSDate(date, { zone: timeZoneId });


/**
 * Given date time, create the same time in the timezone selected without converting time
 * this method solves the issue of ignoring a users locale date when selecting custom times for their viewed property
 * @param {Date} date - js date
 * @param {String} timeZone - time zone id
 * @returns {DateTime} zonedDateTime
 */
export const createDateTimeZoneDateFromJSDate = (date, timeZone) =>
{
    if (!date)
    {
        return;
    }

    // set fixed offset to avoid odd DST rules for luxon
    // i.e. we always want the selected time to reflect current DST
    // Get the fixed offset in minutes (positive or negative)
    // const fixedOffsetMinutes = DateTime.now().setZone(timeZone).offset;

    // // Convert the offset to hours and minutes
    // const hours = Math.abs(Math.floor(fixedOffsetMinutes / 60));
    // const minutes = Math.abs(fixedOffsetMinutes % 60);

    // // Determine the sign of the offset (e.g., + or -)
    // const sign = fixedOffsetMinutes >= 0 ? '+' : '-';

    // // Construct the fixed offset string
    // const fixedOffset = `UTC${ sign }${ hours.toString().padStart(2, '0') }:${ minutes.toString().padStart(2, '0') }`;

    // create date from object, this gives us total control of what the date time will look like
    let zonedDateTime = DateTime.fromObject(
        {
            year: date.getFullYear(),
            month: date.getMonth() + 1, // js get month is from 0-11
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds(),

            //Fixed offset logic is failing when we have decimal -> UTC+7.5/UTC+07:30 so using IANA timezone
        }, { zone: timeZone }
    );

    // console.log(zonedDateTime.offset,DateTime.now().setZone(getLocalTimeZoneName()).offset,zonedDateTime.toISO())

    return zonedDateTime;
};

/**
 * Checks if a given DateTime object represents today's date in the specified time zone.
 *
 * @param {DateTime} dateTime - The DateTime object to check.
 * @param {string} [timeZone="UTC"] - The ID of the time zone to use. Defaults to "UTC".
 * @return {boolean} True if the given DateTime object represents today's date in the specified time zone, false otherwise.
 *
 * @example
 * // get current time in UTC
 * const now = DateTime.now().setZone("UTC");
 *
 * // create a new DateTime object for a different time zone
 * const differentTimeZone = DateTime.fromObject({ year: 2022, month: 1, day: 1 }, { zone: "America/Los_Angeles" });
 *
 * // check if differentTimeZone is today in UTC
 * const isToday = isDateTimeToday(differentTimeZone, "UTC");
 */
export const isDateTimeToday = (dateTime, timeZone = "UTC") =>
{
    if (!dateTime)
    {
        return false;
    }

    const now = DateTime.now().setZone(timeZone);

    // luxon hasSame("day") checks if the calendar day, month, and year of two DateTime objects are the same
    // so it effectively checks if two dates are in the same calendar day regardless of time zone
    if (dateTime.hasSame(now, "day"))
    {
        return true;
    }

    return false;
};

/**
 * Returns the current date and time in the specified time zone.
 *
 * @param {string} [timeZoneId="UTC"] - The ID of the time zone to convert to. Defaults to "UTC".
 * @return {DateTime} The current date and time in the specified time zone.
 */
export const getCurrentDateTimeInTimeZone = (timeZoneId = "UTC") =>
{
    const timeZoneNow = DateTime.now().setZone(timeZoneId);

    return timeZoneNow;
};


/**
 *
 * @param {DateTime} dateTime
 * @returns {String} iso string of utc converted dateTime
 */
export const formatDateTimeToUTCString = (dateTime) =>
{
    let utcDateTime = dateTime.setZone("UTC");

    return utcDateTime.toISO();
};


/**
 * if latest available time in timeZone is 5pm this will return a JS date object with the time 5pm
 * @param {Object} timeZone // {id, name}
 * @returns {Date} JS Date
 */
export const getLatestDateInTimeZone = (timeZone) =>
{
    // note we want to make sure it doesn't convert back to users local machine time
    let dateTime = DateTime.now().setZone(timeZone.id);

    // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
    let localeTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let date = DateTime.fromObject(
        {
            year: dateTime.year,
            month: dateTime.month,
            day: dateTime.day,
            hour: dateTime.hour,
            minute: dateTime.minute,
            second: dateTime.second,
            millisecond: dateTime.millisecond
        },
        { zone: localeTimeZone }
    ).toJSDate();

    return date;
};

/**
 * @deprecated we add zone histories in zonegeofenceRequestFilter try to use that
 * returns the date range & isHistoryDateConsidered that should be queried request data while using zoneGeofence history
 * @param {*} dateRange
 * @param {*} zoneHistory
 */
export const getZoneGeofenceHistoryQueriedDateRange = (
    dateRange,
    zoneHistory,
    timeZoneId
) =>
{
    if (!zoneHistory?.historyDateRange || !dateRange)
    {
        return;
    }



    //converting to timezoneId if time localtimezone is 10AM -> this will return 10AM +/- timezone off set value
    let queriedStartDateTime = createDateTimeZoneDateFromJSDate(dateRange.startDate, timeZoneId);
    let queriedEndDateTime = createDateTimeZoneDateFromJSDate(dateRange.endDate, timeZoneId);

    //return a DateTimeObject of timeZoneId
    let historyStartDateTime = createDateTimeFormMilliSeconds(
        zoneHistory?.historyRangeUnix_ms?.start_unix_ms,
        timeZoneId
    );

    // we subtract 1 second from end time for a correct API response.
    // as of time of writting , if we query to the exact end date we begin to get data for the next version
    // the above was confirmed with R&D team
    let historyEndDateTime = createDateTimeFormMilliSeconds(
        zoneHistory?.historyRangeUnix_ms?.end_unix_ms - 1,
        timeZoneId
    );

    // start time = max (queried start, zone history start)
    // if history start time was after query, we want to use that instead
    let startDate =
        queriedStartDateTime < historyStartDateTime
            ? historyStartDateTime
            : queriedStartDateTime;

    // end time = min (queried endm zone history end)
    // if history end time was before query, we want to use that instead
    let endDate =
        queriedEndDateTime < historyEndDateTime
            ? queriedEndDateTime
            : historyEndDateTime;

    let isHistoryDateConsidered = false;
    // if start date is same as history start date and end date is same as history end date
    if (startDate === historyStartDateTime || endDate === historyEndDateTime)
    {
        isHistoryDateConsidered = true;
    }
    startDate = startDate.toJSDate();
    endDate = endDate.toJSDate();

    return { startDate, endDate, isHistoryDateConsidered };
};


/**
 *  return a new DateTime with zoneId as timeZone
 * @param {DateTime}  currentDateTime
 * @param {string} timeZoneId
 *
 * @return {DateTime}
 */

export const convertALocalDateTimeToZoneDateTime = (
    currentDateTime,
    timeZoneId
) => DateTime.fromISO(currentDateTime.toISO(), { zone: timeZoneId });

/**
 * @param {string} timeZoneId
 *
 * @returns {string}
 */
export const getAbbreviatedNamedOffset = (timeZoneId) =>
{
    const ZoneCurrentTime = DateTime.fromISO(DateTime.now().toISO(), {
        zone: timeZoneId,
    });
    return ZoneCurrentTime.toFormat("ZZZZ");
};

/**
 * @param {number} minutes
 *
 * @returns {string} ${ roundedMins } min || ${ duration.hours } hr ${ roundedMins }
 */
export const convertMinsToHrsAndMins = (minutes) =>
{
    const duration = Duration.fromObject({ minutes })
        .shiftTo("hours", "minutes")
        .toObject();
    const roundedMins = Math.floor(duration.minutes);

    if (duration.hours > 0)
    {
        return `${duration.hours} hr ${roundedMins} min`;
    }

    return `${roundedMins} min`;

    // return `${ duration.hour } hr ${ duration.minute } min`
};

/**
 * @param {Object} {StartAt:"UTC DATE STRING",EndAt:"UTC DATE STRING"}
 * @param {DurationUnit} diffIn
 *
 * @returns {} ${ roundedMins } }
 */
export const getDurationFromUTC = ({ StartAt, EndAt }, diffIn = "minute") => DateTime.fromISO(EndAt).diff(DateTime.fromISO(StartAt)).as(diffIn);

/**
 * @param {Array} data
 * @param {String} startDateKey
 * @param {String} endDateKey
 * @param {DurationUnit} diffIn
 * @returns {number} returns sum of all the durations of statDateKey and endDateKey based on diffIn param
 */
export const getSumOfDurationOfUTCFields = (
    data,
    startDateKey = "StartAt",
    endDateKey = "EndAt",
    diffIn = "minute"
) =>
{
    if (!data?.length)
    {
        return 0;
    }

    return data.reduce(
        (acc, field) => acc
            + +getDurationFromUTC(
                { StartAt: field[startDateKey], EndAt: field[endDateKey] },
                diffIn
            ),
        0
    );
};



/**
 * Formats a given DateTime object into a readable string format with the specified time zone.
 *
 * @param {DateTime} syncedDateTime - The DateTime object to format.
 * @param {string} timeZoneId - The ID of the time zone to include in the formatted string.
 * @returns {string} A formatted string representing the DateTime in a readable format with the time zone.
 */
export const formatDateTimeToReadAbleTimeFormatWithTimeZone = (syncedDateTime, timeZoneId) =>
{
    let formattedDateTime = `${syncedDateTime.toFormat(
        UTC_FORMAT
    )}  ${syncedDateTime.toLocaleString(DateTime.TIME_SIMPLE)} (${timeZoneId})`;

    return formattedDateTime;
};

/**
 *
 * @param {Number} seconds
 * @param {string} timeZoneId
 * @returns {string} formattedDateTime
 */
export const convertUnixSecondsToReadAbleTimeFormatWithTimeZone = (
    seconds,
    timeZoneId
) =>
{
    const localDateTime = DateTime.fromSeconds(seconds);
    const syncedDateTime = convertALocalDateTimeToZoneDateTime(
        localDateTime,
        timeZoneId
    );
    // const  abbreviatedNamedOffset = getAbbreviatedNamedOffset(timeZone.id)
    return formatDateTimeToReadAbleTimeFormatWithTimeZone(syncedDateTime, timeZoneId);
};

/**
 *
 * @param {Number} seconds
 * @param {string} timeZoneId
 * @returns
 */
export const convertUnixSecToUTC = (seconds, timeZoneId) =>
{
    const localDateTime = DateTime.fromSeconds(seconds);
    const syncedDateTime = convertALocalDateTimeToZoneDateTime(
        localDateTime,
        timeZoneId
    );
    return syncedDateTime.toUTC();
};

export const getLocalTimeZoneName = () =>
{
    const dt = DateTime.fromJSDate(new Date()).setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    return dt.zoneName;
};
export const getUTCTimeZoneName = () => "UTC";

export const DIFFERENT_DATE_FORMATS = {
    localTimeFormatId: "localTimeFormatId",
    UTCTimeFormatId: "UTCTimeFormatId",
    propertyTimeZoneId: "propertyTimeZoneId"
};

/**
 * converts js Date object to zonedDateTime
 * @param {Date} date -> js date object
 * @param {string} timeZoneId -> timeZoneId
 * @returns {DateTime}
 */
export const convertLocalTimeZoneJSDateToZonedDateTime = (date, timeZoneId) =>
{

    //creating DateTimeObject with localTimeZoneId
    const localDateTime = DateTime.fromJSDate(date, { zone: getLocalTimeZoneName() });

    const convertedDateTime = localDateTime.setZone(timeZoneId);
    return convertedDateTime;
};


/**
 *
 * @param {Number} milliSeconds
 * @param {*} timeZoneId
 * @returns
 */
export const createDateTimeFormMilliSeconds = (milliSeconds, timeZoneId) => DateTime.fromMillis(milliSeconds, { zone: timeZoneId });

/**
 * Calculate the number of days between a given UTC start and end date.
 *
 * @param {Object} endTimeUTC - the end time in UTC
 * @param {Object} startTimeUTC - the start time in UTC
 * @return {number} the number of days between the start and end date
 */
export const getNumberOfDayFromUTCDateRange = ({ endTimeUTC, startTimeUTC }) =>
{
    const startDateTime = DateTime.fromISO(startTimeUTC);
    const endDateTime = DateTime.fromISO(endTimeUTC);
    return endDateTime.diff(startDateTime, "days").days;
};


/**
 * Converts a DateTime object to a local JavaScript Date object without changing the time.
 *
 * @param {DateTime} dateTime - The DateTime object to convert.
 * @return {Date} The JavaScript Date object with the same date and time as the input DateTime object, but adjusted to the local time zone.
 */
export const convertDateTimeToLocalJSDateWithoutChangingTime = (dateTime) =>
{
    let nowTime = DateTime.now();

    let nowIsoTime = nowTime.toISO();

    const offsetString = nowIsoTime.slice(-6);

    // Adding local offset so our JS clock remains in sync with the time zone
    const isoString = dateTime.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
    const localISOString = isoString.split(" ").join("T") + offsetString;
    return new Date(localISOString);
};

/**
 * Get the latest JavaScript date based on the given time zone.
 * This function first gets the current date and time in the UTC time zone,
 * then sets the time zone to the provided time zone and returns the
 * JavaScript date object converted to the local time zone without changing the time.
 *
 * @param {string} timeZone - The time zone ID.
 * @return {Date} The latest JavaScript date based on the given time zone.
 */
export const getLatestJsDateBasedOnTimeZone = (timeZone) =>
{
    // Get the current date and time in the UTC time zone
    let dateTimeNow = DateTime.now().setZone("UTC");

    // Set the time zone to the provided time zone
    dateTimeNow = dateTimeNow.setZone(timeZone);

    // Convert the DateTime object to a local JavaScript Date object
    // without changing the time.
    return convertDateTimeToLocalJSDateWithoutChangingTime(dateTimeNow);
};

/**
 * Get the latest Luxon date based on the given time zone.
 * This function first gets the current date and time in the UTC time zone,
 * then sets the time zone to the provided time zone and returns
 *
 * @param {string} timeZone - The time zone ID.
 * @return {Date} The latest JavaScript date based on the given time zone.
 */
export const getLatestDateTimeBasedOnTimeZone = (timeZone) =>
{
    // Get the current date and time in the UTC time zone
    let dateTimeNow = DateTime.now().setZone("UTC");

    // Set the time zone to the provided time zone
    dateTimeNow = dateTimeNow.setZone(timeZone);

    // Convert the DateTime object to a local JavaScript Date object
    // without changing the time.
    return dateTimeNow;
};
