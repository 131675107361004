import Proptypes from "prop-types";
import React from "react";
import {
    AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT,
    BAR_CHART_AXIS_LEFT_TEXT_OPTIONS,
    CATEGORY_BAR_CHART_OPTIONS,
} from "../../../_constants/chartConstants";
import { BarChart } from "../../charts/BarChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import CategoryWidgetInfoText from "./CategoryInfo.text";

const CategoryWidgetBarChart = ({ data, trans }) => {
    const customTooltip = React.useCallback(
        ({ indexValue, value, color, id /*index, color*/ }) => {
            return (
                <div className="tooltipItemRow tooltipItemRowDevice">
                    <span style={{ background: color }} /> {indexValue}: <strong>{value}</strong>{" "}
                    {trans(`CategoryWidget.${id}`)}
                </div>
            );
        },
        [trans]
    );

    const customLegendFormat = React.useCallback((value) => {
        return value;
    }, []);

    const customTickLeft = (tick) => {
        let renderedTick =
            tick.value.length > AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT ? (
                <tspan>
                    {tick.value.substring(0, AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT) + "..."}
                    <title>{tick.value}</title>
                </tspan>
            ) : (
                tick.value
            );

        return (
            <g transform={`translate(${tick.x},${tick.y})`}>
                <line x1="0" x2="-5" y1="0" y2="0" style={{ stroke: "rgb(119, 119, 119)", strokeWidth: "1" }} />

                <text {...BAR_CHART_AXIS_LEFT_TEXT_OPTIONS}>{renderedTick}</text>
            </g>
        );
    };

    const customTick = React.useCallback((tick) => {
        if (tick.value % 1 === 0) {
            return (
                <g transform={`translate(${tick.x},${tick.y + 10})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}>
                        {tick.value}
                    </text>
                </g>
            );
        } else {
            return <></>;
        }
    }, []);

    if (!data || (data && data.categoryBarData.length === 0)) {
        return <NoDataWidgetMessage />;
    }
    return (
        <>
            <CategoryWidgetInfoText {...data} trans={trans} />
            <div className="UsedDevicesWidget">
                <BarChart
                    data={data.categoryBarData.slice(-6)}
                    index="category"
                    keys={["Searched", "Visited"]}
                    options={CATEGORY_BAR_CHART_OPTIONS}
                    customTick={customTick}
                    customAxisLeft={{ renderTick: customTickLeft }}
                    customTooltip={customTooltip}
                    customLegendFormat={customLegendFormat}
                />
            </div>
        </>
    );
};

CategoryWidgetBarChart.propTypes = {
    data: (Proptypes.object | undefined).isRequired,
    trans: Proptypes.func,
};

export default CategoryWidgetBarChart;
