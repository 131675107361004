import React, { memo, useContext, useEffect } from 'react';
import { WEB_ENGAGEMENT_TOP20SEARCHES_WIDGET_OPTIONS } from '../../../_constants/chartConstants';
import CirclePackingChart from '../../charts/CirclePackingChart';
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader';
import Translation from '../../common/translation/Translation';
import { DefaultBox } from '../../dashboard/DefaultBox';
import useWebEngagementTop20SearchesWidget from './useWebEngagementTop20SearchesWidget';
import { roundWithPrecision } from 'mapsted.utils/numbers';


const CustomTooltip = memo(({ id, color, percentage, value }) =>
{
	return (
		<div className="tooltipItemRow tooltipItemRowDevice web-eng-search-tooltip">
			<span style={{ background: color }} />
			<div>
				{id}:
			</div>
			<div>
				<strong>{value + " "} <Translation text="Widgets.WebEngagementTop20SearchesWidget.Searches" /></strong>-<strong>{roundWithPrecision(percentage)} %</strong>
			</div>
		</div>);
});


const Container = ({ injectProcessedData }) =>
{

	const { searchesQuery, processedWidgetData } = useWebEngagementTop20SearchesWidget();
	useEffect(() =>
	{
		if (!processedWidgetData)
		{ 
			return;
		}

		const modifiedData = processedWidgetData?.chartData?.children?.flatMap((item) => {
			return item?.children?.map(({ label, value, color }) => {
				return {
					Keywords: label, Volume: value
				}
			})
		})
		
		const exportPayload = [
			{
				name: "Top 20 Searches",
				data: modifiedData,
				description: "",
				injector: injectProcessedData
			},
			//   {
			// 	name: "Top 20 Searches",
			// 	data: searchesQuery?.data,
			// 	description: "",
			// 	injector: injectRawData
			//   },
		];

		exportPayload.forEach(({ name, data, description, injector }) =>
		{
			injector && injector({ name, data, description });
		});

	}, [processedWidgetData]);

	return (
		<DefaultBox
			className="alignedWidget"
			heading={<Translation text="Widgets.WebEngagementTop20SearchesWidget.title" />}
			webIcon={true}

		>
			<ConditionalRenderLoadingErrorNoDataChildren rawQuery={searchesQuery} >
				<p className="para gap0">
					<strong>{processedWidgetData.headMaxSearchNames + ", "}</strong>
					{processedWidgetData.tailMaxSearchNames}
				</p>
				<div className={`ChartWrapper overflowHide`}>
					<div className={`${processedWidgetData.allSortedSearchItems.length > 5 ? "circleChartHeighWrapper" : "ChartWrapper"}`}>
						<CirclePackingChart
							data={processedWidgetData.chartData}
							options={WEB_ENGAGEMENT_TOP20SEARCHES_WIDGET_OPTIONS}
							customToolTip={CustomTooltip}
						/>
					</div>
				</div>

			</ConditionalRenderLoadingErrorNoDataChildren>
		</DefaultBox>
	);
};

export default Container;