import React, { useEffect } from "react";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const NumberOfTotalDownloadsNumberWidgets = ({ processedWidgetData, trans, injectProcessedData }) =>
{
    const { isSuccess, data } = processedWidgetData;
    const connectedPageName = useRecoilValue(connectedPageNameState);
    useEffect(() =>
    {
        const payload = {
            name: "Downloads",
            data: [{
                "Downloads": data?.totalDownloads || 0
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);
    }, [data?.totalDownloads, connectedPageName]);
    if (!!isSuccess)
    {
        return (
            <OverviewDetailBox
                appOnly
                max="190px"
                popupContent={trans("ReportsOverviewDetail.Number_of_total_downloads_")}
                icon="downloads"
                content={data?.totalDownloads || 0}
                slogan={trans("ReportsOverviewDetail.Downloads")}
            />
        );
    }
    return <OverviewDetailBoxLoading />;
};

export { NumberOfTotalDownloadsNumberWidgets };
