import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import serverApi from '../../../_api/server.api';
import
{
    NAV_ANALYTICS_QUERY_KEYS,
    QUERY_OPTIONS
} from "../../../_constants/queryConstants";
import { processScreenResolutionWidget } from "../../../_utils/chart.utils";

import useCommon from "../../pages/useCommon";

const useScreenResolution = () =>
{
    const common = useCommon();

    const { environmentConstants, filters, hasAccessToProperty } =
        common;
    const { analyticsRequestFilter } = filters;

    const screenResolutionQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.SCREEN_RESOLUTION, { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {
            const result = await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.SCREEN_RESOLUTION, environmentConstants);

            if (!result?.success)
            {
                throw new Error("Screen resolution response was not ok");
            }

            return processScreenResolutionWidget(result.data);
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });


    const boxClassName = React.useMemo(() =>
    {
        // if query is in error state or does not have data, 
        if (screenResolutionQuery.isError || (!!screenResolutionQuery.isSuccess && screenResolutionQuery.data.resolutionBarData.length === 0))
        {
            return "hasMessage";
        }
        else
        {
            return "";
        }

    }, [screenResolutionQuery]);

    const onError = useCallback(() =>
    {
        screenResolutionQuery.refetch();
    }, [screenResolutionQuery]);


    return {
        ...common, ...screenResolutionQuery, boxClassName, onError
    };
};

export default useScreenResolution;