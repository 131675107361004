import React, { useContext, useEffect, useMemo } from 'react';
import { convertMinsTo_HHMMSS } from '../../../_utils/date.utils';
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader';
import Translation from '../../common/translation/Translation';
import { DefaultBox } from '../../dashboard/DefaultBox';
import ZoneTrafficBarChart from '../ZoneTrafficWidget/ZoneTrafficBar.chart';
import { ZoneTableWrapper as PeakHourTableWrapper, RenderZoneTrafficColItemView, ZoneTrafficRowWrapper } from '../ZoneTrafficWidget/ZoneTrafficTable.view';
import useWebEngagementPeakHourVsDay from './useWebEngagementPeakHourVsDay';
import { WebOnlyTooltip } from '../../common/AppOnlyTooltip';
import { EngagementContext } from '../../pages/engagement/EngagementContext';

const Container = ({ injectProcessedData }) =>
{
    const { sessionsQuery, processedWidgetData, trans } = useWebEngagementPeakHourVsDay();

    const processedExportData = useMemo(() => {

        if (!processedWidgetData?.tableData?.length)
        {
            return [];
        };
        return processedWidgetData.tableData.flatMap(item => 
        {   
            let PeakHour = [];
            let PeakHourMaxVisits = 0;
            item.peakHours.forEach((peakHour, indx) => {
                if(peakHour.maxVisits){
                    // PeakHourMaxVisits.push(peakHour.maxVisits);
                    if(peakHour.maxVisits > PeakHourMaxVisits)
                    {
                        PeakHourMaxVisits = peakHour.maxVisits;
                    }
                    PeakHour.push(peakHour.time);
                }
            });

            return {
                "Days": item.dayName,
                "PercentageOfVisit": item.visitPercentage,
                "AverageVisitTimeFormatted": convertMinsTo_HHMMSS(item.avgTime),
                "AverageVisitTimeMinutes": item.avgTime,
                "PeakHour": PeakHour.join(","),
                // "TimeZoneId": peakHour.timeZoneId,
                // "Visits": peakHour.visit,
                "PeakHourMaxVisits": PeakHourMaxVisits || "",
            };
        });
    }, [processedWidgetData]);
    

    useEffect(() =>
    {

        const exportPayload = [
            {
                name: "Weekly Visitor Analytics By Day",
                data: processedExportData,
                description: "",
                injector: injectProcessedData
            },
            // {
            //   name: "Weekly Visitor Analytics By Day",
            //   data: sessionsQuery?.data,
            //   description: "",
            //   injector: injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });
    }, [processedExportData]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={<Translation text="Widgets.WebEngagementPeakHourWidget.title" />}
            webIcon={true}
        >
            <ConditionalRenderLoadingErrorNoDataChildren rawQuery={sessionsQuery}>
                <p className="para">
                    {processedWidgetData.overallPeakHourWeekDayName} &nbsp;
                    <Translation text="Words.at" />  &nbsp;
                    {processedWidgetData.overallPeakHourTime}
                    <Translation text="Widgets.WebEngagementPeakHourWidget.info_text" />

                </p>

                <PeakHourTableWrapper>
                    <ZoneTrafficRowWrapper>
                        <RenderZoneTrafficColItemView
                            content={<Translation text="Widgets.WebEngagementPeakHourWidget.table_col_names.DAYS" />}
                        />
                        <RenderZoneTrafficColItemView
                            content={<Translation text="Widgets.WebEngagementPeakHourWidget.table_col_names.%_OF_VISITS" />}
                        />
                        <RenderZoneTrafficColItemView
                            content={<Translation text="Widgets.WebEngagementPeakHourWidget.table_col_names.AVG_VISIT_TIME" />}
                        />
                        <RenderZoneTrafficColItemView
                            content={<Translation text="Widgets.WebEngagementPeakHourWidget.table_col_names.PEAK_HOUR" />}
                        />
                    </ZoneTrafficRowWrapper>
                    {
                        processedWidgetData.tableData.map(({ dayName, visitPercentage, avgTime, peakHours, keys }) => <ZoneTrafficRowWrapper key={"web-peak" + dayName}>
                            <RenderZoneTrafficColItemView
                                content={dayName}
                            />
                            <RenderZoneTrafficColItemView
                                content={visitPercentage}
                            />
                            <RenderZoneTrafficColItemView
                                content={convertMinsTo_HHMMSS(avgTime)}
                            />
                            <RenderZoneTrafficColItemView
                                content={<ZoneTrafficBarChart
                                    keys={keys}
                                    chartData={peakHours}
                                    trans={trans} />}
                            />


                        </ZoneTrafficRowWrapper>)
                    }

                </PeakHourTableWrapper>
            </ConditionalRenderLoadingErrorNoDataChildren>
        </DefaultBox>
    );
};

export default Container;