import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import useInsights from "./useInsights";
import { selectedZoneGeofenceHashState } from "../../../store/MapAtoms";
import { useRecoilState } from "recoil";
import
{
    NoDataWidgetMessage,
    WidgetMessage,
} from "../../common/WidgetMessage/WidgetMessage";
import { InfoPopup } from "../../common/InfoPopup";
import { InsightsToolTips } from "../../../_constants/insightsConstants";
import InsightsZoneGeofence from "./InsightsZoneGeofence";
import InsightsSessionTableView from "./InsightsSessionTable";
import { useTranslation } from "react-i18next";

export const InsightsTable = () =>
{
    const { dwellTimeForActiveTrajectoryZones, trajectoryTableData, selectedUserSessionUID, isZoneGeofenceTableDataLoading, isSessionTableDataLoading, sessionsTableData, handleChangeSelectedSession } = useInsights();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("ZoneGeofence");
    const [sessionDropDown, setSessionDropDown] = useState({
        session: {},
        open: false,
    });
    const trans = useTranslation().t;

    const [selectedZoneGeofenceHash, setSelectedZoneGeofenceHash] = useRecoilState(selectedZoneGeofenceHashState);

    React.useEffect(() =>
    {
        setSelectedZoneGeofenceHash({});
    }, [trajectoryTableData?.id]);

    React.useEffect(() =>
    {
        setSelectedZoneGeofenceHash({});
    }, [sessionsTableData?.id]);

    useEffect(() =>
    {
        // only trigger on outside zg select | side bar is closed
        if (
            selectedZoneGeofenceHash
            && Object.keys(selectedZoneGeofenceHash).length > 0
            && !isOpen
        )
        {
            setIsOpen(true);
        }

        if (
            selectedZoneGeofenceHash
            && Object.keys(selectedZoneGeofenceHash).length > 0
        )
        {
            setSelectedItem("ZoneGeofence");
        }

    }, [selectedZoneGeofenceHash]);


    useEffect(() =>
    {
        const session = sessionsTableData?.find((session) => session.sessionUID === selectedUserSessionUID);

        if (session)
        {
            setSessionDropDown({ session, open: true });
        } else
        {
            setSessionDropDown({ session: {}, open: false });
        }

    }, [selectedUserSessionUID, sessionsTableData]);

    const handleLeftBarToggle = useCallback(() =>
    {
        setIsOpen((prevValue) => !prevValue);
    }, [setIsOpen]);

    /**
     * handles click events from zone geofence table rows select's and unselect's zones
     */
    const handleZoneGeofenceItemClicked = useCallback(
        (zoneGeofenceId) =>
        {
            setSelectedZoneGeofenceHash((prev) => (prev[zoneGeofenceId] ? {} : { [zoneGeofenceId]: true }));
        },
        [setSelectedZoneGeofenceHash, sessionDropDown]
    );

    /**
     * handles click events from session table rows
     */
    const handleSessionItemClicked = useCallback((selectedSession) =>
    {
        if (selectedSession)
        {
            setSessionDropDown((prevState) =>
            {
                if (prevState?.session?.sessionUID === selectedSession?.sessionUID)
                {
                    return undefined;
                }
                else
                {

                    return {
                        // toggles between dropdown item if it is selected
                        session: selectedSession,
                        open:
                            selectedSession.sessionUID
                                === prevState?.session?.sessionUID
                                ? !prevState.open
                                : true, // Toggle the open state
                    };
                }
            });
        }
    }, [setSessionDropDown]);

    const handlePropertyVisitClicked = useCallback(({ userUID, sessionIdx, sessionUID, propertyId }) =>
    {
        const selectedSession = {
            userUID, sessionIdx, sessionUID, propertyId
        };
        handleChangeSelectedSession(selectedSession);
    }, [handleChangeSelectedSession]);

    /**
     * changes table view
     */
    const handleTableViewChange = useCallback((view) =>
    {
        setSelectedItem(view);
    });

    /**
     * renders table toggler
     */
    const renderInsightsTableViewToggler = useCallback(() => (
        <div className="rectangle-toggler">
            <div
                className={
                    selectedItem == "ZoneGeofence"
                        ? "rectangle-toggler-item active"
                        : "rectangle-toggler-item"
                }
                onClick={() => handleTableViewChange("ZoneGeofence")}
            >
                {trans("Insights.ZoneGeofence")}
            </div>
            <div
                className={
                    selectedItem == "sessions"
                        ? "rectangle-toggler-item active"
                        : "rectangle-toggler-item"
                }
                onClick={() => handleTableViewChange("sessions")}
            >
                {trans("Insights.Sessions")}
            </div>
        </div>
    ), [selectedItem]);

    /**
     * renders loading message
     */
    const renderLoadingMessage = useCallback(() => (
        <div className="dataTablebox dataTableboxInsights">
            <WidgetMessage
                customTitle={trans("Insights.Loading")}
                customMessage={trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}
            />
        </div>
    ), []);

    /**
     * renders no data message
     */
    const renderNoDataMessage = useCallback(() => (
        <div className="dataTablebox dataTableboxInsights">
            <NoDataWidgetMessage isVertical={false} />
        </div>
    ), []);

    const renderZoneGeofenceTableData = useCallback(() =>
    {
        if (isZoneGeofenceTableDataLoading)
        {
            return (renderLoadingMessage());
        }
        else if (!dwellTimeForActiveTrajectoryZones?.length)
        {
            return (renderNoDataMessage());
        }

        return (
            <InsightsZoneGeofence
                data={dwellTimeForActiveTrajectoryZones}
                selectedZoneGeofenceHash={selectedZoneGeofenceHash}
                onClick={handleZoneGeofenceItemClicked}
            />
        );
    }, [isZoneGeofenceTableDataLoading, dwellTimeForActiveTrajectoryZones, selectedZoneGeofenceHash, handleZoneGeofenceItemClicked]);

    /**
     * renders session table
     */
    const renderSessionTableData = useCallback(() =>
    {
        if (isSessionTableDataLoading)
        {
            return (renderLoadingMessage());
        }
        else if (!sessionsTableData || sessionsTableData?.length === 0)
        {
            return (
                <div className="dataTablebox dataTableboxInsights dataTableSession">
                    {renderNoDataMessage()}
                </div>
            );
        }

        return (
            <InsightsSessionTableView
                data={sessionsTableData}
                onSessionClick={handleSessionItemClicked}
                onPropertyVisitClick={handlePropertyVisitClicked}
                dropdown={sessionDropDown}
            />
        );
    }, [handleSessionItemClicked, handlePropertyVisitClicked, sessionsTableData, sessionDropDown, isSessionTableDataLoading]);

    /**
     * Renders tooltip for the table component
     */
    const renderTableToolTip = useCallback(() =>
    {
        let content = InsightsToolTips.ZONE_GEOFENCE_TABLE;

        if (selectedItem !== "ZoneGeofence")
        {
            content = InsightsToolTips.SESSION_TABLE;
        }

        return (
            <InfoPopup
                basic
                className="infoPopupRight"
                position="top right"
                content={trans("Insights.ToolTips.SESSION_TABLE")}
            />
        );
    }, [selectedItem]);

    /**
     *  renders table data
     * */
    const renderTable = useCallback(() =>
    {
        if (isOpen)
        {
            return (
                <div
                    className={
                        selectedItem === "ZoneGeofence"
                            ? "sidebarInsights"
                            : "sidebarInsights sidebarInsightsSessionsWhenSession"
                    }
                >
                    <div className="sessionInsightsCover">
                        {renderInsightsTableViewToggler()}
                        {renderTableToolTip()}
                    </div>
                    <div className="dataTablebox dataTableboxInsights">
                        {
                            selectedItem == "ZoneGeofence" ? (
                                // RENDER ZONE GEOFENCE TABLE
                                <div>{renderZoneGeofenceTableData()}</div>
                            ) : (
                                // RENDER SESSION DATA TABLE
                                <div>{renderSessionTableData()}</div>
                            )}
                    </div>
                </div>
            );
        }
    }, [
        isOpen,
        trajectoryTableData?.id,
        selectedZoneGeofenceHash,
        handleZoneGeofenceItemClicked,
        selectedItem,
        renderZoneGeofenceTableData,
        renderSessionTableData,
    ]);

    return (
        <div
            className={
                selectedItem === "ZoneGeofence"
                    ? "insightsLeftbar"
                    : "insightsLeftbarWhenSessions"
            }
        >
            <Button
                className={`buttonIcon triggerToggleInsights${isOpen ? " active" : ""
                    }`}
                onClick={handleLeftBarToggle}
            >
                <Icon name="table" />
                <Icon name={`chevron ${isOpen ? "left" : "right"}`} />{" "}
                {/* chevron text should be left when it's opened */}
            </Button>
            {renderTable()}
        </div>
    );
};
