import React from "react";
import { ResponsiveSankey } from "@nivo/sankey";

export const SankyChart = ({ data, customLinkTooltip, customNodeTooltip, options = {} }) =>
{
    const chartOptions = {
        margin: { top: 20, right: 20, bottom: 20, left: 20 },
        align: "justify",
        colors: options.colors || { scheme: "paired" },
        nodeOpacity: 1,
        nodeThickness: 18,
        nodeInnerPadding: 6,
        nodeSpacing: 21,
        nodeBorderWidth: 0,
        nodeBorderColor: { from: "color", modifiers: [["darker", "0.4"]] },
        linkOpacity: 0.45,
        linkHoverOthersOpacity: 0.1,
        linkBlendMode: "normal",
        enableLinkGradient: true,
        labelPosition: "inside",
        labelOrientation: "vertical",
        labelPadding: 15,
        allowOverlap: false,
        label: (node) => node.labelConcat,
        linkTooltip: customLinkTooltip,
        nodeTooltip: customNodeTooltip,
        labelTextColor: { from: "color", modifiers: [["darker", 1]] },
    }
    return (
        <ResponsiveSankey
            data={data}
            {...chartOptions}
        />
    )
}