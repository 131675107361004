import React from "react";
import { ZONE_TRAFFIC_CHART_OPTIONS } from "../../../_constants/chartConstants";
import { BarChart } from "../../charts/BarChart";

const ZoneTrafficBarChart = ({ trans, chartData, keys = ["users", "maxUsers"], customToolTipText = undefined }) =>
{
    const customTick = React.useCallback(
        (tick) =>
        {
            if (tick.tickIndex % 4 === 0 && tick.tickIndex !== 0)
            {
                return (
                    <g transform={`translate(${tick.x},${tick.y + 10})`}>
                        <text
                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fontSize: 10,
                            }}
                        >
                            {tick.value}
                            {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                        </text>
                    </g>
                );
            } else
            {
                return <></>;
            }
        },
        []
    );

    const customTooltip = React.useCallback(
        ({ indexValue, value, data, color }) =>
        {
            return (
                <div className="tooltipWidgetRowCover">
                    <div className="tooltipItemRow tooltipItemRowDevice">

                        <span style={{ background: color }} />
                        {/* Time of day */}
                        {
                            indexValue + " " + data.timeZoneId + ":"
                        }

                        {/* value count */}
                        <strong>
                            {value + " "}
                        </strong>

                        <>  {
                            (customToolTipText)
                                ? customToolTipText
                                : Number(value) === 1
                                    ? trans("ZoneTrafficWidget.Visit")
                                    : trans("ZoneTrafficWidget.Visits")
                        }
                        </>


                    </div>

                </div>
            );
        },
        [trans, customToolTipText]
    );

    return (
        <div style={{ height: 50 }}>
            <BarChart
                data={chartData}
                index="time"
                keys={keys}
                customTooltip={customTooltip}
                customTick={customTick}
                options={ZONE_TRAFFIC_CHART_OPTIONS}
            />
        </div>
    );
};

export default ZoneTrafficBarChart;
