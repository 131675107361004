import { roundWithPrecision } from "mapsted.utils/numbers";
import { convertDateTimeToLocalJSDateWithoutChangingTime, fromJsDateToDateTime } from "./date.luxon.utils";

/**
 * Returns an array of date ranges, where each date range is a combination of a date range from zoneHistoryOptionsMap and activeDateRange.
 * The resulting date ranges have their start dates and end dates adjusted to match the start and end dates of the corresponding date range from zoneHistoryOptionsMap.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.zoneHistoryOptionsMap - A map of zone history options. Each value in the map represents a date range.
 * @param {Object} params.activeDateRange - The active date range.
 * @param {Date} params.activeDateRange.startDate - The start date of the active date range.
 * @param {Date} params.activeDateRange.endDate - The end date of the active date range.
 * @return {Array} An array of date ranges, where each date range is a combination of a date range from zoneHistoryOptionsMap and activeDateRange.
 */
export const getAllPossibleDateRangesFromZoneHistoryOptionsAndActiveDateRange = (activeZoneHistory, activeDateRange, timeZoneId) =>
{
    if (!activeZoneHistory)
    {
        return activeDateRange;
    }
    const { zoneHistoryOptionsMap = {} } = activeZoneHistory;
    const dateRangesFromHistory = Object.values(zoneHistoryOptionsMap);

    if (dateRangesFromHistory.length)
    {
        // Get an array of date ranges from zoneHistoryOptionsMap, sorted by start date, and map each date range to an object with startDate and endDate properties.
        // Sort the date ranges by start date.
        let preParedDateRanges = dateRangesFromHistory.sort((a, b) => a.historyRangeUnix_ms.start_unix_ms - b.historyRangeUnix_ms.start_unix_ms)
            // Map each date range to an object with startDate and endDate properties.
            .map((option) =>
            {
                const { historyRangeUnix_ms: oHistoryRangeUnix_ms, key, zoneGeofencesIds } = option;
                const historyRangeUnix_ms = window.structuredClone(oHistoryRangeUnix_ms);

                let startDate = new Date(historyRangeUnix_ms.start_unix_ms);
                let endDate = new Date(historyRangeUnix_ms.end_unix_ms);
                if (startDate < activeDateRange.startDate)
                {
                    startDate = activeDateRange.startDate;

                    historyRangeUnix_ms.start_unix_ms = startDate.getTime();
                }

                if (endDate > activeDateRange.endDate)
                {
                    endDate = activeDateRange.endDate;
                    historyRangeUnix_ms.end_unix_ms = endDate.getTime();
                }
                startDate = new Date(historyRangeUnix_ms.start_unix_ms);
                endDate = new Date(historyRangeUnix_ms.end_unix_ms);

                return {
                    ...historyRangeUnix_ms,
                    zoneHistoryMapKey: key,
                    considerTimeInDateRange: true,
                    historyDropDownOverrateMs: historyRangeUnix_ms.start_unix_ms,
                    startDate, // Set the start date of the date range.
                    endDate, // Set the end date of the date range.
                    zoneGeofencesIds
                };
            });

        return preParedDateRanges;
    }

};
/**
 * This function checks if the given two date ranges overlap each other.
 *
 * @param {Object} r1 - The first date range.
 * @param {Date} r1.startDate - The start date of the first date range.
 * @param {Date} r1.endDate - The end date of the first date range.
 * @param {Object} r2 - The second date range.
 * @param {Date} r2.startDate - The start date of the second date range.
 * @param {Date} r2.endDate - The end date of the second date range.
 *
 * @return {boolean} Returns true if the two date ranges overlap each other, otherwise false.
 */
export const checkHistoryIsPossible = (r1, r2) =>
{
    // If the end date of the first date range is less than the start date of the second date range,
    // or the start date of the first date range is greater than the end date of the second date range,
    // then they don't overlap.
    if (r1.endDate < r2.startDate || r1.startDate > r2.endDate)
    {
        return false;
    }
    // Otherwise they overlap.
    return true;
};
/**
 * 
 * @param {*} zoneTrafficFlowMap 
 * @param {*} numberOfDays 
 * @param {*} isBoost --> should be passed true if the util is used on "Traffic Flow Boost"
 * @returns 
 */
export const createZonesFromToPathMapping = (zoneTrafficFlowMap, numberOfDays, isBoost = false) =>
{
    const zones = Object.values(zoneTrafficFlowMap);

    // Create an array to hold the final "from-to" path mappings
    const fromToMapping = zones.map(({ zoneGeofenceId: fromZoneId, zoneLabel: fromLabel, toZoneGeofence }) =>
    {
        const mapping = {
            from: fromLabel,
        };
        zones.forEach(({ zoneGeofenceId: toZoneId, zoneLabel: toLabel }) =>
        {
            let toValue = null;
            if (isBoost)
            {
                toValue = toZoneGeofence[toZoneId] ? Math.ceil(roundWithPrecision(toZoneGeofence[toZoneId] / numberOfDays, 2)) : 0;
            } else
            {

                toValue = toZoneGeofence[toZoneId] || 0;
            }

            // add a dynamic key for each "to" zone in the mapping
            // assings null if no path exists
            mapping[toLabel] = toValue;
        });

        return mapping;
    });

    return fromToMapping;
};
