import React, { useEffect, useMemo } from "react";
import useCategoryWidget from "./useCategory";

import { WidgetLoadingBoxSmall } from "../../common/placeholders/Placeholders";
import WidgetView from "../Widget.view";
import CategoryWidgetBarChart from "./CategoryBar.chart";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { useTranslation } from "react-i18next";
import { getWidgetTitle } from "../../../_utils/utils";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const CategoryContainer = ({ injectProcessedData }) =>
{
    const catWidget = useCategoryWidget();
    const { t } = useTranslation();
    const { data } = catWidget;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const processedExportData = useMemo(()=>{
        if(!data)  
        {
            return [];
        }

        return data?.categoryBarData.map(
            ({ category, Searched, Visited }) => {
                return { Category: category, Searched, Visited }
            }
        )
    },[data, connectedPageName])

    useEffect(() =>
    {
        
        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.CategoryWidget),
            data: processedExportData,
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [processedExportData]);

    return (
        <WidgetView
            {...catWidget}
            LoadingComponent={WidgetLoadingBoxSmall}
            heading={catWidget.trans("CategoryWidget.What_is_the_most_searched_and_visited_ca")}>
            <CategoryWidgetBarChart {...catWidget} />
        </WidgetView>
    );
};

export default CategoryContainer;
