import React from 'react'
import { ResponsiveCirclePacking } from '@nivo/circle-packing'

const CirclePackingChart = ({ data, options = {}, customToolTip = undefined }) =>
{
    return (
        <ResponsiveCirclePacking data={ data }{ ...options } tooltip={ customToolTip } />
    )
}

export default CirclePackingChart