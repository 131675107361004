import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";
import "./ButtonIcon.css";
import Translation from "../translation/Translation";

export const ButtonIcon = ({
    circle,
    color,
    positive,
    size,
    className,
    gap,
    whiteSpace,
    icon,
    iconName,
    content,
    ref,
    onClick,
    title,
    active,
    disabled,
    name,
}) => (
    <Button
        name={name}
        title={title}
        active={active}
        disabled={disabled}
        basic
        circular={circle}
        color={color}
        positive={positive}
        size={size}
        className={`buttonIcon${className ? " " + className : ""}`}
        style={{ marginBottom: gap, whiteSpace: whiteSpace && "nowrap" }}
        ref={ref}
        onClick={onClick}
    >
        {icon && <img src={`/img/icon-${icon}.svg`} alt="" style={{ marginRight: !content && "0" }} />}
        {iconName && <Icon name={iconName} />}

        {content}
    </Button>
);

export const ButtonLink = ({ content, onClick }) => <Button className="buttonLink" content={content} onClick={onClick} />;

export const ButtonBasic = ({ className, name, isActive, icon, content, iconName, basic, disabled, onClick, isDeprecating = false }) => (
    <Button
        basic={basic}
        disabled={disabled}
        name={name}
        className={`${className ? " " + className : ""}${isActive ? "active" : ""}`}
        onClick={onClick}
    >
        {isDeprecating && <Popup basic lassName={`infoPopup ${className}`} style={{ width: "250px", maxWidth: "250px", background: "rgb(253, 140, 87,1)" }} hoverable
            trigger={<div className="deprecatingIcon">D</div>}
            position="top right"
        >
            <Translation text={'UserExplorerDetail.User_Explorer_Deprecating_Message'} />
        </Popup>}
        {icon && <img src={`/img/icon-${icon}.svg`} alt="" style={{ marginRight: !content && "0" }} />}
        {content}
        {iconName && <Icon name={iconName} />}
    </Button>
);

export const LinkBasic = ({ className, isActive, icon, content, iconName, basic, to }) => <Link to={to}>{ButtonBasic({ className, isActive, icon, content, iconName, basic })}</Link>;
