import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { deepValue } from "mapsted.utils/objects";
import { atom, selector } from "recoil";
import { v4 as uuid_v4 } from "uuid";
import serverApi from "../_api/server.api";
import { DEFAULT_DATE_TYPE } from "../_constants/constants";
import { DEFAULT_DASHBOARD } from "../_constants/dashboard";
import { DEFAULT_FILTER, DEFAULT_HEATMAP_FILTER } from "../_constants/filterConstants";
import { DEFAULT_WIDGETS } from "../_constants/widgets";
import { DIFFERENT_DATE_FORMATS, getZoneGeofenceHistoryQueriedDateRange } from "../_utils/date.luxon.utils";
import { getDateRange, getRetentionDateRange } from "../_utils/date.utils";
import { getAnalyticsRequestFilter, getAnalyticsRequestFilterAllTime } from "../_utils/query.utils";
import { getAllPossibleDateRangesFromZoneHistoryOptionsAndActiveDateRange } from "../_utils/zoneGeofence.utils";
import { zoneGeofenceActiveHistoryState } from "./MapAtoms";
/** ATOMS **/
export const propertyInfoMapState = atom({
    key: "propertyInfoMapState",
    default: undefined,
});

export const propertyIdState = atom({
    key: "propertyIdState",
    default: selector({
        key: "propertyIdState/Defualt",
        get: () => serverApi.dashboardData?.propertyId,
    }),
});

export const buildingIdState = atom({
    key: "buildingIdState",
    default: selector({
        key: "buildingIdState/Defualt",
        get: () => serverApi.dashboardData?.buildingId,
    }),
});

export const floorIdState = atom({
    key: "floorIdState",
    default: selector({
        key: "floorIdState/Defualt",
        get: () => serverApi.dashboardData?.floorId,
    }),
});

export const filterState = atom({
    key: "filterState",
    default: DEFAULT_FILTER,
});

export const isFilterOpenState = atom({
    key: "isFilterOpenState",
    default: false,
});

export const heatmapFilterState = atom({
    key: "heatmapFilterState",
    default: DEFAULT_HEATMAP_FILTER,
});

export const dateTypeState = atom({
    key: "dateTypeState",
    default: DEFAULT_DATE_TYPE,
});

export const dateRangeState = atom({
    key: "dateRangeState",
    default: selector({
        key: "dateRangeState/Defualt",
        get: ({ get }) =>
        {
            let propertyTimeZoneId = get(propertyTimeZoneSelectorState);
            return getDateRange(DEFAULT_DATE_TYPE, false, propertyTimeZoneId);
        },
    }),
});

export const considerTimeInDateRangeState = atom({
    key: "considerTimeInDateRangeState",
    default: false,
});

export const selectedWidgetsState = atom({
    key: "selectedWidgetsState",
    default: DEFAULT_WIDGETS,
});

export const isHeatmapFullscreenState = atom({
    key: "isHeatmapFullscreenState",
    default: false,
});

export const filterValuesState = atom({
    key: "filterValuesState",
    default: {},
});

export const heatmapTypeState = atom({
    key: "heatmapTypeState",
    default: 0,
});

/**
 * selector should be used for all the zoneGeofence dependent components  dataRequests
 * And has a logic to handle dateRange logic based on zoneGeofence history date Range
 *
 * selector returns a object containing request filter dependent on zoneGeofence history
 */
export const zoneGeofenceRequestFilterSelector = selector({
    key: "zoneGeofenceRequestFilterSelector",
    get: ({ get }) =>
    {
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);
        const filter = get(filterState);
        const queriedDateRange = get(dateRangeState);
        const selectedZoneGeofenceHistory = get(zoneGeofenceActiveHistoryState);
        let considerTimeInDateRange = get(considerTimeInDateRangeState);
        let propertyTimeZoneId = get(propertyTimeZoneSelectorState);

        let { isHistoryDateConsidered, ...dateRange } = getZoneGeofenceHistoryQueriedDateRange(queriedDateRange, selectedZoneGeofenceHistory, propertyTimeZoneId) || queriedDateRange;
        // if isHistoryDateConsidered is true then considerTimeInDateRange will be true
        if (isHistoryDateConsidered)
        {
            considerTimeInDateRange = true;
        }

        const { analyticsRequestFilter, analyticsRequestCompareFilter } = getAnalyticsRequestFilter({
            propertyId,
            buildingId,
            filter,
            dateRange,
            timeZone: { id: propertyTimeZoneId, name: propertyTimeZoneId },
            considerTimeInDateRange,
        });


        const dateRangeBreakDownWithByZoneHistory = getAllPossibleDateRangesFromZoneHistoryOptionsAndActiveDateRange(selectedZoneGeofenceHistory ?? {}, queriedDateRange, propertyTimeZoneId);


        return { zoneGeofenceRequestFilter: analyticsRequestFilter, zoneGeofenceRequestCompareFilter: analyticsRequestCompareFilter, dateRangeBreakDownWithByZoneHistory };
    },
});

/** SELECTORS **/

/**
 * Creates an object containing the request filter
 */
export const analyticsRequestFilterSelector = selector({
    key: "analyticsRequestFilter",
    get: ({ get }) =>
    {
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);
        const filter = get(filterState);
        const queriedDateRange = get(dateRangeState);
        let considerTimeInDateRange = get(considerTimeInDateRangeState);
        let propertyTimeZoneId = get(propertyTimeZoneSelectorState);

        const { analyticsRequestFilter, analyticsRequestCompareFilter } = getAnalyticsRequestFilter({
            propertyId,
            buildingId,
            filter,
            dateRange: queriedDateRange,
            timeZone: { id: propertyTimeZoneId, name: propertyTimeZoneId },
            considerTimeInDateRange,
        });


        return { analyticsRequestFilter, analyticsRequestCompareFilter };
    },
});

/**
 * selector returns timeZoneId of selected property or selected building
 */
export const propertyTimeZoneSelectorState = selector({
    key: "propertyTimeZoneSelectorState",
    get: ({ get }) =>
    {

        const propertyInfoMap = get(propertyInfoMapState);
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);

        let timeZone = undefined;

        if (buildingId)
        {
            timeZone = deepValue(propertyInfoMap, `${propertyId}.buildings.${buildingId}.timeZone`, undefined);
        }

        if (!timeZone && propertyId)
        {
            timeZone = deepValue(propertyInfoMap, `${propertyId}.timeZone`, undefined);
        }

        if (timeZone === undefined)
        {
            timeZone = { id: serverApi.activePropertyTimeZone, name: serverApi.activePropertyTimeZone };
        }

        return timeZone.id;
    }
});


export const historyKeysHavingDataForActiveFloorState = atom({
    key: "historyKeysWithDataForActiveFloorState",
    default: [],
});


export const activeZoneHistoryByFloorDataState = selector({
    key: "activeZoneHistoryByFloorDataState",
    get: ({ get }) =>
    {
        let result = {};
        const floorId = get(floorIdState);
        const buildingId = get(buildingIdState);
        const zoneHistory = get(zoneGeofenceActiveHistoryState);
        if (zoneHistory?.data)
        {
            let currentActiveAllFloorsData = zoneHistory.data;
            let zoneDataByFloor = Object.values(currentActiveAllFloorsData).reduce((acc, zone) =>
            {
                if (zone)
                {
                    if (zone.floorId === floorId)
                    {
                        acc[zone.zoneGeofenceId] = zone;
                    }
                }

                return acc;
            }, {});

            result = { ...zoneHistory, data: zoneDataByFloor, id: uuid_v4(), floorId, buildingId };
        }

        return result;
    }
});

/**
 * Creates an object containing the request filter
 */
export const analyticsRequestFilterWithoutBuildingIdSelector = selector({
    key: "analyticsRequestFilterWithoutBuildingIdSelector",
    get: ({ get }) =>
    {

        const propertyId = get(propertyIdState);
        const filter = get(filterState);
        const queriedDateRange = get(dateRangeState);
        const selectedZoneGeofenceHistory = get(zoneGeofenceActiveHistoryState);
        let considerTimeInDateRange = get(considerTimeInDateRangeState);
        let propertyTimeZoneId = get(propertyTimeZoneSelectorState);

        const { analyticsRequestFilter, analyticsRequestCompareFilter } = getAnalyticsRequestFilter({
            propertyId,
            filter,
            dateRange: queriedDateRange,
            timeZone: { id: propertyTimeZoneId, name: propertyTimeZoneId },
            considerTimeInDateRange
        });


        return { analyticsRequestFilter, analyticsRequestCompareFilter };
    },
});

/**
 * Creates an object containing the request filter
 */
export const retentionRateFilterSelector = selector({
    key: "retentionRateFilterSelector",
    get: ({ get }) =>
    {
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);
        const filter = get(filterState);
        const dateRange = getRetentionDateRange();
        const propertyTimeZoneId = get(propertyTimeZoneSelectorState);
        const timeZone = { id: propertyTimeZoneId, name: propertyTimeZoneId };

        const { analyticsRequestFilter: retentionRateRequestFilter } = getAnalyticsRequestFilter({
            propertyId,
            buildingId,
            filter,
            dateRange,
            timeZone,
        });

        return retentionRateRequestFilter;
    },
});

/**
 * Creates an object containing the request filter
 */
export const allTimeFilterSelector = selector({
    key: "allTimeFilterSelector",
    get: ({ get }) =>
    {
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);
        const filter = get(filterState);
        const dateRange = getRetentionDateRange();
        const propertyTimeZoneId = get(propertyTimeZoneSelectorState);
        const timeZone = { id: propertyTimeZoneId, name: propertyTimeZoneId };


        const allTimeAnalyticsRequestFilter = getAnalyticsRequestFilterAllTime({
            propertyId,
            buildingId,
            filter,
            dateRange,
            timeZone,
        });

        return allTimeAnalyticsRequestFilter;
    },
});

export const heatmapRequestFilterSelector = selector({
    key: "heatmapRequestFilterSelector",
    get: ({ get }) =>
    {
        let heatmapFilter = get(heatmapFilterState);
        let filterValues = get(filterValuesState);
        let heatmapType = get(heatmapTypeState);

        const service_providers = heatmapFilter.service_providers.toString();

        // service_providers to string seperated by commas
        // screen_resolution to screen_height / screen_width strings seperated by commas

        let screen_widths = [];
        let screen_heights = [];

        heatmapFilter.screen_resolutions.forEach((screenResolution) =>
        {
            let screenResolutionData = filterValues.screenResolutions[screenResolution];

            const { screenPxWidth, screenPxHeight } = screenResolutionData;
            screen_widths.push(screenPxWidth);
            screen_heights.push(screenPxHeight);
        });

        screen_widths = screen_widths.toString();
        screen_heights = screen_heights.toString();

        return { service_providers, screen_widths, screen_heights, type: heatmapType };
    },
});

export const selectedPropertyInfoSelector = selector({
    key: "selectedPropertyInfoSelector",
    get: ({ get }) =>
    {
        let propertyInfoMap = get(propertyInfoMapState);
        let propertyId = get(propertyIdState);

        if (!propertyId || !propertyInfoMap)
        {
            return undefined;
        }

        const selectedProperty = propertyInfoMap?.[propertyId];

        return selectedProperty;
    },
});

/**
 * returns an array of the current building's levels using default language code
 */
export const levelsSelector = selector({
    key: "levelsSelector",
    get: ({ get }) =>
    {
        const propertyInfoMap = get(propertyInfoMapState);
        const propertyId = get(propertyIdState);
        const buildingId = get(buildingIdState);

        if (!propertyId || !buildingId || !propertyInfoMap)
        {
            return undefined;
        }

        let levels = deepValue(propertyInfoMap, `${propertyId}.buildings.${buildingId}.levels`, []).map(
            ({ ...level }) =>
            {
                if (typeof level.longName !== "string")
                {
                    level.longName = level.longName[DEFAULT_LANGUAGE_CODE];
                }
                if (typeof level.shortName !== "string")
                {
                    level.shortName = level.shortName[DEFAULT_LANGUAGE_CODE];
                }

                return level;
            }
        );

        return levels;
    },
});

export const numberOfFilterOptionsSelector = selector({
    key: "numberOfFilterOptionsSelector",
    get: ({ get }) =>
    {
        const filter = get(filterState);

        let count = 0;

        Object.keys(filter).forEach((filterOption) =>
        {
            if (filterOption)
            {
                count += 1;
            }
        });

        return count;
    },
});

/**
 * Returns the time zone object of the currently active time zone.
 * By default, the active time zone is set as the property time zone.
 * For updating the time zone based on the UI interaction of the time zone selection widget.
 * @returns {Object} An object with properties {id, name, key}.
 *  - id (string): The id of the currently active time zone.
 *  - name (string): The name of the currently active time zone.
 *  - key (string): The key of the currently active time zone, which is set to DIFFERENT_DATE_FORMATS.propertyTimeZoneId.
 */
export const activeTimeZoneSelectorState = atom({
    key: "activeTimeZoneSelectorState",
    default: selector({
        key: "activeTimeZoneSelectorState/default",
        get: ({ get }) =>
        {
            const propertyTimeZoneId = get(propertyTimeZoneSelectorState);
            return { id: propertyTimeZoneId, name: propertyTimeZoneId, key: DIFFERENT_DATE_FORMATS.propertyTimeZoneId };
        },
    })
});


export const hasAccessToPropertySelector = selector({
    key: "hasAccessToPropertySelector",
    get: ({ get }) =>
    {
        const propertyInfoMap = get(propertyInfoMapState);
        const propertyId = get(propertyIdState);

        return !!deepValue(propertyInfoMap, `${propertyId}`, undefined);
    },
});

export const usersPropertiesSelector = selector({
    key: "usersPropertiesSelector",
    get: ({ get }) =>
    {
        const propertyInfoMap = get(propertyInfoMapState);

        let propertyIds = [];

        if (propertyInfoMap)
        {
            propertyIds = Object.keys(propertyInfoMap);
        }

        return { key: uuid_v4(), propertyIds };
    }
});

export const userDashboardsState = atom({
    key: "userDashboardsState",
    default: selector({
        key: "userDashboardsState/Default",
        get: () => serverApi.getDashboards(), // getDashboards
    }),
});

export const dashboardSelectorModalState = atom({
    key: "dashboardSelectorModalState",
    default: false,
});

export const widgetSelectorModalState = atom({
    key: "widgetSelectorModalState",
    default: false,
});

export const primaryDashboardState = selector({
    key: "primaryDashboard",
    get: ({ get }) =>
    {
        const userDashBoardMap = get(userDashboardsState);
        return Object.values(userDashBoardMap).find((db) => db.active) ?? DEFAULT_DASHBOARD;
    },
});

export const widgetSelectorFilterState = atom({
    key: "widgetSelectorFilterState",
    default: "ALL",
});

export const widgetFilterActivePageState = atom({
    key: "widgetFilterActivePageState",
    default: 1,
});


// export const dashboardModeState = atom({
//     key: 'dashboardModeState',
//     default: MODES.PRIMARY
// })
