import React, { useEffect, useMemo } from "react";
import { WidgetLoadingBoxMedium } from "../../common/placeholders/Placeholders";
import WidgetView from "../Widget.view";
import NewVsRepeatLineChart from "./NewVsRepeatLine.chart";
import useNewVsRepeat from "./useNewVsRepeat";
import { getWidgetTitle } from "../../../_utils/utils";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { useTranslation } from "react-i18next";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { getDateGroupAndFormat } from "../../../_utils/chart.utils";
import { formatDateValuesFromChartToCSV } from "../../../_utils/exportToCsvUtils";

function NewsVsRepeatContainer({ injectProcessedData })
{
    const newVsRepeat = useNewVsRepeat();
    const { t } = useTranslation();
    const { data, filters } = newVsRepeat;
    const { analyticsRequestFilter } = filters;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const processedExportData = useMemo(() =>
    {
        if (!data)
        {
            return [];
        }
        const { chartData, newUsersPercentage, returningUsersPercentage } = data;

        if (!chartData?.length || (!newUsersPercentage && !returningUsersPercentage))
        {
            return [];
        }

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return chartData.map(item =>
        {
            const obj = item.data.reduce((acc, point) =>
            {
                acc[formatDateValuesFromChartToCSV(point.x, dateGroupFormat)] = point.y;
                return acc;
            }, {});

            return [`${item.label}Visits`, obj];
        });
    }, [data, connectedPageName, analyticsRequestFilter]);

    useEffect(() =>
    {
        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.NewVsRepeatUsersWidget),
            data: processedExportData,
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [processedExportData]);

    return (
        <WidgetView
            {...newVsRepeat}
            LoadingComponent={WidgetLoadingBoxMedium}
            heading={newVsRepeat.trans("NewVsRepeatUsersWidget.How_many_visits_are_new_vs__repeat?")}
        >
            <NewVsRepeatLineChart {...newVsRepeat} />
        </WidgetView>
    );
}

export default NewsVsRepeatContainer;
