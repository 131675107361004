import React, { memo, useEffect } from "react";
import { NumberWidgetConditionalLoader } from "../../common/NumberWidgetCommon";
import { OverviewDetailBox } from "../../common/overviewDetailBox/OverviewDetailBox";
import Translation from "../../common/translation/Translation";
import useWebEnagamentNumberWidget from "./useWebEnagamentNumberWidget";



export const NewVisitsNumberWidgetWebEngagement = memo(({ injectProcessedData }) =>
{
    const { rawQuery, allNumberWidgetsProcessedData } = useWebEnagamentNumberWidget();
    const { newVisitors, changeInNewVisitors } = allNumberWidgetsProcessedData;

    useEffect(() =>
    {
        const payload =
        {
            name: "New Visitors",
            data: [{
                "NewVisitors": newVisitors,
                "PercentageOfChange": changeInNewVisitors
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);
    }, [allNumberWidgetsProcessedData]);

    return <>
        <NumberWidgetConditionalLoader
            rawQuery={rawQuery}
            errorChildren={<OverviewDetailBox
                appOnly={false}
                max="220px"
                popupContent={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.popupContent" />}
                icon="new-users"
                content={"--"}
                percentage={0}
                slogan={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.slogan" />}
            />}
            noDataChildren={
                <OverviewDetailBox
                    appOnly={false}
                    max="220px"
                    popupContent={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.popupContent" />}
                    icon="new-users"
                    content={"--"}
                    percentage={0}
                    slogan={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.slogan" />}
                />
            }


        >
            <OverviewDetailBox
                appOnly={false}
                webOnly={true}
                max="220px"
                popupContent={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.popupContent" />}
                icon="new-users"
                content={newVisitors}
                percentage={changeInNewVisitors}
                slogan={<Translation text="Widgets.NewVisitsNumberWidgetWebEngagement.slogan" />}
            />
        </NumberWidgetConditionalLoader>



    </>;
});