import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { createEntityGeometry, createPointStyle, createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { extractValueFromPosition } from "mapsted.maps/utils/map.utils";
import { Feature } from "ol";
import { v4 as uuid_v4 } from "uuid";
import { BOOST_COMFORT_TEMP_CONSTANTS, BOOST_COMFORT_TEMP_DANGEROUS_LOWER_LIMIT, BOOST_COMFORT_TEMP_GREAT_DISCOMFORT_LOWER_LIMIT, BOOST_COMFORT_TEMP_HEAT_STROKE_IMMINENT_LOWER_LIMIT, BOOST_COMFORT_TEMP_SOME_DISCOMFORT_LOWER_LIMIT, BOOST_DEVICE_INFO_TABS, BOOST_NO_DATA_WIDGET_PROPS_BY_TAB_NAME, BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX } from "../_constants/boostConstants";
import { LineDash, TrajectoryColor, greenColorOpacityVariants } from "../_constants/insightsConstants";
import ColorTagIcon from "../components/reports/boostTagAnalytics/ColorTagIcon";
import { convertALocalDateTimeToZoneDateTime, convertUnixSecToUTC, convertUnixSecondsToReadAbleTimeFormatWithTimeZone } from "./date.luxon.utils";
import
{
    UTC_FORMAT,
    formatDate, formatTime,
    unixSecondsToTimeZoneDate
} from "./date.utils";
import { createListOfColors } from "./misc.utils";
import { convertJSX_SVG_TO_data64Link, prepareFilteredDataWithPercentageThreshold } from "./utils";

export const boostTrajectoryStyle = ({
    color,
    lineDash,
    radius = 5,
    strokeColor,
}) =>
{
    return {
        stroke: {
            color: color,
            width: 2,
            lineDash,
        },
        fill: {
            color: color,
        },
        shape: {
            // points: 4,
            // radius: 6,
            // radius2: 0,
            // angle: Math.PI / 4,
        },
        radius,
    };
};

export const parseFilterDataToApi = ({
    propertyId,
    buildingId,
    floorId,
    dataAggregationValue,
    heatmapType,
    startTimeUTC,
    endTimeUTC,
    considerTimeInDateRange,
    dateRange,
    timeZone,
}) =>
{
    let request = {
        PropertyId: propertyId,
        BuildingId: buildingId,
        FloorId: floorId,
        HeatmapType: heatmapType,
        DataAggregation: dataAggregationValue,
        StartTimeUTC: startTimeUTC,
        EndTimeUTC: endTimeUTC,
    };

    // get interval start hour and end hour if used
    // if (considerTimeInDateRange)
    // {
    //     let startDate = createDateTimeZoneDateFromJSDate(dateRange.startDate, timeZone.id);
    //     let endDate = createDateTimeZoneDateFromJSDate(dateRange.endDate, timeZone.id);
    //     const intervals = getRoundedIntervalsFromDateRange({ startDate: startDate.toJSDate(), endDate: endDate.toJSDate() });
    //     request.IntervalStartUTCHour = intervals.IntervalStartUTCHour;
    //     request.IntervalEndUTCHour = intervals.IntervalEndUTCHour;
    // }

    return request;
};

export const parseBoostHeatMapApiData = (response) =>
{
    let parsedHeatmapData = {
        heatmap: [],
        pointSpacing: 0,
        kernelBuffer: 0,
    };

    if (response.data)
    {
        if (Array.isArray(response.data.heatmaps) && response.data.heatmaps.length > 0)
        {
            const heatMaps = response.data.heatmaps;
            const heatMapDataByEachInterval = [];

            parsedHeatmapData.pointSpacing = heatMaps[0].pointSpacing;
            parsedHeatmapData.kernelBuffer = heatMaps[0].kernelBuffer;

            heatMaps.forEach((entry) =>
            {
                let heatmapPoints = [];
                entry.heatmap.forEach((heatmapPoint) =>
                {
                    heatmapPoints.push(heatmapPoint);
                });
                heatMapDataByEachInterval.push(heatmapPoints);
            });

            // allHeatmapPoints.forEach((heatmapPoint) => {
            //     let pId = createUniqueHeatmapPointId(heatmapPoint);

            //     if (heatmapPointMap[pId]) {
            //         let pValue = extractValueFromPosition(heatmapPoint);
            //         heatmapPointMap[pId][2] += pValue;
            //     } else {
            //         heatmapPointMap[pId] = heatmapPoint;
            //     }
            // });

            // parsedHeatmapData.heatmap = Object.values(heatmapPointMap);
            // parsedHeatmapData.heatmap = heatMapDataByEachInterval;
        }
    }
    return parsedHeatmapData;
};

/**
 * @param {Array} heatmaps - array of aggregated heatmaps
 */
export const getIntervalHeatmapGlobalMaxValue = (heatmaps) =>
{
    let globalMax = 0;

    if (!Array.isArray(heatmaps))
    {
        return globalMax;
    }

    heatmaps.forEach((heatmapData) =>
    {
        let { heatmap } = heatmapData;
        heatmap = heatmap.sort((a, b) => extractValueFromPosition(b) - extractValueFromPosition(a));
        let localMax = extractValueFromPosition(heatmap[0]);

        if (localMax > globalMax)
        {
            globalMax = localMax;
        }
    });

    return globalMax;
};

export const createUniqueHeatmapPointId = (heatmapPoint) => `${heatmapPoint[0]} - ${heatmapPoint[1]}`;

export const formatHeatmapIntervalDate = (heatmapInterval, timeZone, trans) =>
{
    if (!heatmapInterval)
    {
        return trans("WidgetMessage.No_data_found_");
    }
    const { start_UnixTime_s, end_UnixTime_s } = heatmapInterval;
    let startTime = unixSecondsToTimeZoneDate(start_UnixTime_s, timeZone);
    let endTime = unixSecondsToTimeZoneDate(end_UnixTime_s, timeZone);
    let formattedStartTime = `${formatDate(startTime)} ${formatTime(startTime)}`;
    let formattedEndTime = `${formatDate(endTime)} ${formatTime(endTime)}`;

    return `${formattedStartTime} - ${formattedEndTime} ${timeZone?.id ? "(" + timeZone.id + ")" : ""}`;
};

export const getFeatureFromZoneLayerData = (zoneGeofenceArray) =>
{
    let res = {};
    if (!Array.isArray(zoneGeofenceArray))
    {
        return {};
    }

    zoneGeofenceArray.forEach((zoneGeofence) =>
    {
        let { boundary, zoneGeofenceId, label } = zoneGeofence;
        let geometry = createEntityGeometry(boundary);
        let feature = new Feature({
            geometry: geometry,
            id: zoneGeofenceId,
            label: label,
            zoneGeofence: zoneGeofence,
        });

        res[zoneGeofenceId] = { zoneGeofenceId, feature };
    });

    return res;
};


/**
 * @param {Array} data
 * @param {Object} timeZone
 *
 * @returns {tagData,categories}
 *
 */
export const processBoostTagDataByBuildingAndFloor = (data = [], timeZone) =>
{
    function getUniqueCategory(curr)
    {
        return (curr?.tag_category ?? "").trim().length === 0 ? "Unknown Category" : curr.tag_category.trim();
    }
    let preparedData = {};

    if (!data.length)
    {
        return preparedData;
    }

    const categories = data.reduce((acc, curr) =>
    {

        const category = getUniqueCategory(curr);

        if (!acc[category])
        {
            acc[category] = { category };
        }


        return acc;
    }, {});

    const categoriesKeys = Object.keys(categories);

    const colors = createListOfColors(categoriesKeys.length);

    categoriesKeys.forEach((ck, i) =>
    {
        categories[ck].color = colors[i];
    });

    const result = data.reduce((acc, curr, idx) =>
    {

        const { building_id: buildingId, floor_id: floorId } = curr;

        curr.uid = curr.tag_id;
        const localDateTime = DateTime.fromSeconds(curr.last_update_unixtime_s);
        const syncedPropertyDateTime = convertALocalDateTimeToZoneDateTime(localDateTime, timeZone.id);
        // const  abbreviatedNamedOffset = getAbbreviatedNamedOffset(timeZone.id)
        curr.formattedDateTime = `${syncedPropertyDateTime.toFormat(UTC_FORMAT)}  ${syncedPropertyDateTime.toLocaleString(DateTime.TIME_SIMPLE)} (${timeZone.id})`;
        curr.battery_status = capitalize(curr.battery_status);
        curr.status = capitalize(curr.status);



        curr.tag_category = getUniqueCategory(curr);

        const color = categories[curr.tag_category].color;

        curr.tag_label = (curr?.tag_label ?? "").trim().length === 0 ? "Unknown Tag" : curr.tag_label;

        curr.fileUrl = convertJSX_SVG_TO_data64Link(ColorTagIcon({ color }));
        curr.activeIcon = convertJSX_SVG_TO_data64Link(ColorTagIcon({ color }));
        curr.color = color;
        curr.tag_category_color = color;
        // trimming t he USB Connected / USB notConnected => Connected/ NotConnected
        // curr.usb_status = capitalize(usbStatus.substring(4, usbStatus.length));
        if (!acc[buildingId])
        {
            acc = { ...acc, [buildingId]: { [floorId]: [curr] } };
            return acc;
        }
        else
        {
            if (!acc[buildingId][floorId])
            {
                acc[buildingId] = { ...acc[buildingId], [floorId]: [curr] };
                return acc;
            }
            acc[buildingId][floorId].push(curr);
        }
        return acc;
    }, preparedData);

    return { tagData: result, categories };
};


/**
 *
 * @param {*} data
 * @param {*} timeZone
 * @param {*} nonActiveIconKey -> this is non-active-Iconkey used inside createPointLayerWithCustomIcons utils for rendering the icons on the PointMapComponent
 * @param {*} activeIconKey -> this active-IconKey used inside createPointLayerWithCustomIcons utils for rendering the icons on the PointMapComponent
 * @returns
 */
export const processBoostDeviceDataByBuildingAndFloor = (data = [], timeZone, nonActiveIconKey, activeIconKey) =>
{
    let preparedData = {};

    if (!data.length)
    {
        return preparedData;
    }

    const result = data.reduce((acc, curr, idx) =>
    {

        const { building_id: buildingId, floor_id: floorId, latitude, longitude } = curr;

        curr.uid = longitude + "" + latitude;
        const localDateTime = DateTime.fromSeconds(curr.last_update_unixtime_s);
        const syncedPropertyDateTime = convertALocalDateTimeToZoneDateTime(localDateTime, timeZone.id);
        // const  abbreviatedNamedOffset = getAbbreviatedNamedOffset(timeZone.id)
        curr.formattedDateTime = `${syncedPropertyDateTime.toFormat(UTC_FORMAT)}  ${syncedPropertyDateTime.toLocaleString(DateTime.TIME_SIMPLE)} (${timeZone.id})`;
        curr.battery_status = capitalize(curr.battery_status);
        curr.status = capitalize(curr.status);
        const usbStatus = "" + curr.usb_status;
        curr.nonActiveIconKey = nonActiveIconKey;
        curr.activeIconKey = activeIconKey;
        // trimming the USB Connected / USB notConnected => Connected/ NotConnected
        curr.usb_status = capitalize(usbStatus.substring(4, usbStatus.length));
        if (!acc[buildingId])
        {
            acc = { ...acc, [buildingId]: { [floorId]: [curr] } };
            return acc;
        }
        else
        {
            if (!acc[buildingId][floorId])
            {
                acc[buildingId] = { ...acc[buildingId], [floorId]: [curr] };
                return acc;
            }
            acc[buildingId][floorId].push(curr);
        }
        return acc;
    }, preparedData);

    return result;
};
/**
 *
 * @param {zoneGeofenceTrafficFlows} zoneGeofenceTrafficFlows
 * @returns {data[buildingId][floorId][]}
 */
export const processTrafficFlowsDeviceDataByBuildingAndFloor = ({ zoneGeofenceTrafficFlows }) => zoneGeofenceTrafficFlows.reduce((acc, curr, idx) =>
{

    const { buildingId, floorId } = curr;

    if (!acc[buildingId])
    {
        acc = { ...acc, [buildingId]: { [floorId]: [curr] } };
        return acc;
    }
    else
    {
        if (!acc[buildingId][floorId])
        {
            acc[buildingId] = { ...acc[buildingId], [floorId]: [curr] };
            return acc;
        }
        acc[buildingId][floorId].push(curr);
    }
    return acc;
}, {});

/**
 *
 * @param {zoneGeofenceTrafficFlows} zoneGeofenceTrafficFlows
 * @returns {data[buildingId][floorId][]}
 */
export const processBoostTrafficFlowsDeviceDataByBuildingAndFloor = ({ zoneGeofenceTrafficFlows }, tag) =>
{
    const zoneMapData = Object.entries(zoneGeofenceTrafficFlows ?? {}).reduce((acc, [tagId, zoneArray]) =>
    {
        if (tag && tag !== tagId.toUpperCase())
        {
            return acc;
        }
        zoneArray.forEach(((zone) =>
        {
            const { zoneGeofenceId, fromZoneGeofence, toZoneGeofence, flowsPerHour } = zone;
            if (!acc[zoneGeofenceId])
            {
                acc[zoneGeofenceId] = zone;
                const flowsPerHourMap = {};
                flowsPerHour.forEach((item) =>
                {
                    flowsPerHourMap[item.hourUTC] = item;
                });
                acc[zoneGeofenceId].flowsPerHourMap = flowsPerHourMap;

            }
            else
            {
                //merging fromZoneGeofence tag data to zoneGeofence
                Object.entries(fromZoneGeofence).forEach(([zoneId, visitCount]) =>
                {
                    if (!acc[zoneGeofenceId].fromZoneGeofence[zoneId])
                    {
                        acc[zoneGeofenceId].fromZoneGeofence[zoneId] = visitCount;
                    }
                    else
                    {
                        const prevTagZoneVisitCount = 0 + +acc[zoneGeofenceId].fromZoneGeofence[zoneId];
                        acc[zoneGeofenceId].fromZoneGeofence[zoneId] = prevTagZoneVisitCount + visitCount;

                    }

                });
                //merging toZoneGeofence tag data to zoneGeofence
                Object.entries(toZoneGeofence).forEach(([zoneId, visitCount]) =>
                {

                    if (!acc[zoneGeofenceId].toZoneGeofence[zoneId])
                    {
                        acc[zoneGeofenceId].toZoneGeofence[zoneId] = visitCount;
                    }
                    else
                    {
                        const prevTagZoneVisitCount = 0 + +acc[zoneGeofenceId].toZoneGeofence[zoneId];
                        acc[zoneGeofenceId].toZoneGeofence[zoneId] = prevTagZoneVisitCount + visitCount;

                    }

                });

                //merging flowsPerHour tag data to zoneGeofence
                flowsPerHour.forEach((flowPerHour) =>
                {
                    const { hourUTC, fromZoneGeofence: flowPerHourFromZoneGeofence, toZoneGeofence: flowPerHourToZoneGeofence } = flowPerHour;
                    const prevFlowPerHour = acc[zoneGeofenceId].flowsPerHourMap[hourUTC] || { hourUTC, fromZoneGeofence: {}, toZoneGeofence: {} };
                    Object.entries(flowPerHourFromZoneGeofence).forEach(([zoneId, visitCount]) =>
                    {
                        if (!prevFlowPerHour.fromZoneGeofence[zoneId])
                        {
                            prevFlowPerHour.fromZoneGeofence[zoneId] = visitCount;
                        }
                        else
                        {
                            const prevTagZoneVisitCount = 0 + +prevFlowPerHour.fromZoneGeofence[zoneId];
                            prevFlowPerHour.fromZoneGeofence[zoneId] = prevTagZoneVisitCount + visitCount;
                        }
                    });

                    Object.entries(flowPerHourToZoneGeofence).forEach(([zoneId, visitCount]) =>
                    {
                        if (!prevFlowPerHour.toZoneGeofence[zoneId])
                        {
                            prevFlowPerHour.toZoneGeofence[zoneId] = visitCount;
                        }
                        else
                        {
                            const prevTagZoneVisitCount = 0 + +prevFlowPerHour.toZoneGeofence[zoneId];
                            prevFlowPerHour.toZoneGeofence[zoneId] = prevTagZoneVisitCount + visitCount;
                        }
                    });

                    acc[zoneGeofenceId].flowsPerHourMap[hourUTC] = prevFlowPerHour;

                });






            }


        }));



        return acc;


    }, {});

    return Object.values(zoneMapData).reduce((acc, zone, idx) =>
    {

        const { buildingId, floorId } = zone;
        //creating flowsPerHour array from mergedTagsFlowsPerHourMap
        zone.flowsPerHour = Object.values(zone.flowsPerHourMap);
        if (!acc[buildingId])
        {
            acc = { ...acc, [buildingId]: { [floorId]: [zone] } };
            return acc;
        }
        else
        {
            if (!acc[buildingId][floorId])
            {
                acc[buildingId] = { ...acc[buildingId], [floorId]: [zone] };
                return acc;
            }
            acc[buildingId][floorId].push(zone);
        }
        return acc;
    }, {});




};


// export const processBoostTrafficFlowTagsDataByTagIdAndZoneHistory = (groupedDataByZoneHistory, tagId) =>
// {

//     const groupedDataByTagId = {
//         floorsHash: {
//             zoneHistoryHash: {
//                 allTagsDataFlow,



//             },
//         }
//     };
// };


/**
 *
 * @param1 {Object} topZoneItem
 * {
 *  userCount: 1,
 *  zoneLabel : "label"
 *  color:"red"
 * }
 *
 *  @param2  {Number} currentToZoneTotal
 *
 *
 *
 * @returns {string}
 */
export const getTopZoneDisplayText = (topZoneItem, currentToZoneTotal) =>
{

    if (currentToZoneTotal)
    {
        return `${Math.round((topZoneItem.userCount * 100) / currentToZoneTotal)} %`;
    }
    return "0 %";
};

/**
 * @param {String} tabName
 * @returns {Object} {
 *      isBoostActive,
        isTagsActive,
        isSensorActive,
        noDataProps}
 *
 */
export const getBoostDeviceTabFlagsAndNoDataProps = (tabName) =>
{
    let isBoostActive = false;
    let isTagsActive = false;
    let isSensorActive = false;
    let noDataProps = {
        customTitle: "WidgetMessage.No_data_found_",
        customMessage: "WidgetMessage.Please_check_back_later_"
    };

    // eslint-disable-next-line
    switch (tabName)
    {
        case BOOST_DEVICE_INFO_TABS.BOOSTS: {
            isBoostActive = true;
            noDataProps = BOOST_NO_DATA_WIDGET_PROPS_BY_TAB_NAME[tabName];

            break;
        }

        case BOOST_DEVICE_INFO_TABS.TAGS: {
            isTagsActive = true;
            noDataProps = BOOST_NO_DATA_WIDGET_PROPS_BY_TAB_NAME[tabName];

            break;
        }
        case BOOST_DEVICE_INFO_TABS.SENSORS: {
            isSensorActive = true;
            noDataProps = BOOST_NO_DATA_WIDGET_PROPS_BY_TAB_NAME[tabName];

            break;
        }
    }

    return {
        isBoostActive,
        isTagsActive,
        isSensorActive,
        noDataProps
    };

};

/**
 * @param {string} floorName
 *
 * @returns {string}
 */
export const getShortFloorName = (floorName) =>
{
    const trimmedName = floorName.trim();
    return `${("" + trimmedName?.[0] ?? "NA").toUpperCase()}${trimmedName[trimmedName.length - 1]}`;
};

/**
 * @param {*} apiData
 * @param {*} historyData
 * @returns {string}
 */
export const syncZoneTrafficFlowDataWithHistory = (apiData, historyData) =>
{
    if (!!Object.keys(historyData).length && apiData?.zoneGeofenceTraffic)
    {
        let zoneGeofenceTraffic = [];
        zoneGeofenceTraffic = apiData.zoneGeofenceTraffic.filter(({ zoneGeofenceId }) => !!historyData[zoneGeofenceId]);
        return {
            ...apiData,
            zoneGeofenceTraffic

        };

    }

    return apiData;

};

/**
 * @param {Array} levels
 * @param {Array} responses
 *
 * @returns {*}
 */
export const processGroupedZoneBuildingData = (levels, responses) =>
{
    const indexToFloorId = {};

    let buildingTotalUser = 0;
    if (!levels.length || !responses.length)
    {
        return {};
    }

    let levelMaps = levels.reduce((acc, { floorId }, idx) =>
    {
        acc[floorId] = { zoneGeofenceTraffic: [] };
        indexToFloorId[idx] = floorId;
        return acc;
    }, {});
    responses.forEach(({ data }) =>
    {
        const { zoneGeofenceTraffic } = data;
        zoneGeofenceTraffic.forEach(({ userCount }) =>
        {
            buildingTotalUser = buildingTotalUser + +userCount;
        });
    });
    responses.forEach(({ data }, responseIdx) =>
    {
        const { zoneGeofenceTraffic } = data;
        const normalizedZoneGeofenceTraffic = [];
        zoneGeofenceTraffic.forEach(({ userCount, usersPerHour }, idx) =>
        {
            const normalizedUsersPerHour = [];
            usersPerHour.forEach((perHrData) =>
            {
                normalizedUsersPerHour.push({
                    ...perHrData,
                    userCountPercentage: (perHrData.userCount / buildingTotalUser) || 0
                });


            });
            normalizedZoneGeofenceTraffic.push({
                ...zoneGeofenceTraffic[idx],
                userCountPercentage: (userCount / buildingTotalUser) || 0,
                usersPerHour: normalizedUsersPerHour
            });

        });
        const floorId = indexToFloorId[responseIdx];
        levelMaps[floorId] = { zoneGeofenceTraffic: normalizedZoneGeofenceTraffic };
    });


    return levelMaps;

};


/**
 *
 * @param {Object} data
 * @returns {Object}
 */
export const processBoostNetworkData = (data) =>
{

    let { aggregatedNetworkVisits = [], hardwareVendorsVisits = [] } = data;

    if (aggregatedNetworkVisits.length === 0)
    {
        return data;
    }
    aggregatedNetworkVisits = aggregatedNetworkVisits.filter((a) => a.networkName !== "Unknown Vendor").sort((a, b) => a.networkName.localeCompare(b.networkName));


    let { filteredData, skippedItemsPercentage } = prepareFilteredDataWithPercentageThreshold(hardwareVendorsVisits, "count");

    //add Others option in pie when skippedItemsPercentage > 0
    if (skippedItemsPercentage > 0)
    {
        filteredData.push({
            name: "Others",
            percentage: skippedItemsPercentage
        });
    }


    filteredData = createListOfColors(filteredData.length).map(((c, i) => ({
        ...filteredData[i],
        name: `${filteredData[i].name} (${filteredData[i].percentage}%)`,
        toolTipTitle: filteredData[i].name,
        color: c
    })));

    return {
        ...data,
        aggregatedNetworkVisits,
        hardwareVendorsVisits,
        pieData: {
            id: "name",
            value: "percentage",
            data: filteredData,
            legendStaticProps: {
                legendMeta: {
                    labelKey: "name",
                    colorKey: "color",
                },
                legends: filteredData
            }
        }
    };

};


/**
 *
 * @param {Object} humidexRange
 * @returns {Object}
 */
export const getColorIconBasedOnHumidexRange = (humidexRange) =>
{

    if (humidexRange > BOOST_COMFORT_TEMP_HEAT_STROKE_IMMINENT_LOWER_LIMIT)
    {
        return BOOST_COMFORT_TEMP_CONSTANTS.HEAT_STROKE_IMMINENT;
    }

    if (humidexRange <= BOOST_COMFORT_TEMP_HEAT_STROKE_IMMINENT_LOWER_LIMIT && humidexRange > BOOST_COMFORT_TEMP_DANGEROUS_LOWER_LIMIT)
    {
        return BOOST_COMFORT_TEMP_CONSTANTS.DANGEROUS;
    }

    if (humidexRange <= BOOST_COMFORT_TEMP_DANGEROUS_LOWER_LIMIT && humidexRange >= BOOST_COMFORT_TEMP_GREAT_DISCOMFORT_LOWER_LIMIT)
    {
        return BOOST_COMFORT_TEMP_CONSTANTS.GREAT_DISCOMFORT;
    }

    if (humidexRange < BOOST_COMFORT_TEMP_GREAT_DISCOMFORT_LOWER_LIMIT && humidexRange >= BOOST_COMFORT_TEMP_SOME_DISCOMFORT_LOWER_LIMIT)
    {

        return BOOST_COMFORT_TEMP_CONSTANTS.SOME_DISCOMFORT;
    }

    return BOOST_COMFORT_TEMP_CONSTANTS.NO_DISCOMFORT;

};

/**
 *
 * @param {Object} filters
 * @param {Object} rowData
 * @returns
 */
export const checkRecordMatchesWithFilter = (filters, rowData) =>
{
    if (!rowData)
    {
        return;
    }
    //const { statusFilterValue, searchFilterValue, batteryStatusFilterValue, categoryFilterValue,zoneFilterValue } = filters;
    // const { tag_category, zone_geofence_id, battery_status, status} = rowData;

    const { searchFilterValue, ...rest } = filters;

    const filterKeysToValuedKeys = {
        categoryFilterValue: "tag_category",
        batteryStatusFilterValue: "battery_status",
        statusFilterValue: "status",
        zoneFilterValue: "zone_geofence_id"
    };

    const shouldConsiderRecord = Object.entries(rest).reduce((acc, [fKey, fValue]) =>
    {
        if (fValue !== "All" && (typeof rowData[filterKeysToValuedKeys[fKey]] === "string" ? rowData[filterKeysToValuedKeys[fKey]].toLowerCase() !== (fValue).toLowerCase() : typeof rowData[filterKeysToValuedKeys[fKey]] === "number" && rowData[filterKeysToValuedKeys[fKey]] !== fValue))
        {
            return false;
        }

        return acc && true;
    }, true);

    const terms = searchFilterValue.split(" ");

    if (terms.length === 0)
    {
        return shouldConsiderRecord;
    }

    return shouldConsiderRecord && (terms.every((tm) => rowData.tag_id.toLowerCase().includes(tm.toLowerCase())) || terms.every((tm) => rowData.tag_label.toLowerCase().includes(tm.toLowerCase())));
};

/**
 *
 * @param {string} batteryStatus
 * @returns {string}
 */
export const getBatteryIcon = (batteryStatus) => ({
    "mid battery": "/img/icon-battery-mid.svg",
    "low battery": "/img/icon-battery-low.svg",
    "full battery": "/img/icon-battery-full.svg",
}[batteryStatus.toLowerCase()]);

/**
 *
 * @param {Object} data
 * @param {string} timeZoneId
 * @returns {Object} - { propertyId, [bleTagId]: {[buildingId]:{[floodId]:segments[]}} }
 */
export const parseTrajectoryAPIData = ({ data, timeZoneId }) =>
{
    const { bleTagId, segments, propertyId } = data;

    if (segments.length)
    {
        segments.sort((a, b) => b.startTime.unixTime_ms - a.startTime.unixTime_ms);

        const preparedData = segments.reduce((acc, segData) =>
        {

            const { buildingId, floorId, floorName, segmentId } = segData;


            const { latitude, longitude } = segData.buildingCentroid;
            segData.buildingLongLat = [longitude, latitude];

            let trajectoryLineLayer = createVectorLayer(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.COMPLETE_TRAJECTORY_LINE + segmentId);
            trajectoryLineLayer.setVisible(false);
            let trajectoryLineSource = trajectoryLineLayer.getSource();
            let trajectoryPointLayer = createVectorLayer(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.COMPLETE_POINTS + segmentId);
            trajectoryPointLayer.setVisible(false);
            let trajectoryPointSource = trajectoryPointLayer.getSource();
            let lineStringCoords = [];
            let prev1 = [];
            let prev2 = [];
            let prev3 = [];
            let layersHash = {};
            layersHash[trajectoryLineLayer.get("id")] = trajectoryLineLayer;
            layersHash[trajectoryPointLayer.get("id")] = trajectoryPointLayer;
            segData.layersHash = layersHash;
            segData.activeZones = new Set();
            segData.subSegments.sort((a, b) => a.startTime.unixTime_ms - b.startTime.unixTime_ms);
            segData.subSegments.forEach((subSeg, subsegmentId) =>
            {
                const { zoneGeofenceId, zoneGeofenceName } = subSeg;
                segData.activeZones.add(zoneGeofenceId);
                subSeg.formattedStartTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(subSeg.startTime.unixTime_ms / 1000, timeZoneId);
                subSeg.formattedEndTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(subSeg.endTime.unixTime_ms / 1000, timeZoneId);
                subSeg.trajectory.sort(([, , aTimeStamp], [, , bTimeStamp]) => aTimeStamp - bTimeStamp);
                subSeg.displayName = `${zoneGeofenceId === -1 ? "Unknown" : capitalize(zoneGeofenceName)}`;
                let curTrajectoryLineLayer = createVectorLayer(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.SUB_SEGMENT_LINE + segmentId + "" + subsegmentId);
                let curTrajectoryLineSource = curTrajectoryLineLayer.getSource();
                let curTrajectoryPointLayer = createVectorLayer(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.SUB_SEGMENT_POINTS + segmentId + "" + subsegmentId);
                curTrajectoryLineLayer.setVisible(false);
                curTrajectoryPointLayer.setVisible(false);
                let curTrajectoryPointSource = curTrajectoryPointLayer.getSource();
                layersHash[curTrajectoryLineLayer.get("id")] = curTrajectoryLineLayer;
                layersHash[curTrajectoryPointLayer.get("id")] = curTrajectoryPointLayer;

                let currentTrajectoryCoordinates = [];
                let prevTimeStamp_s;
                subSeg.trajectory.forEach((t, idx) =>
                {
                    const [long, lat, unix_time_s] = t;
                    const point = [long, lat];
                    let coordinates = point;
                    let color = TrajectoryColor.RED;

                    currentTrajectoryCoordinates.push(coordinates);
                    let shape = {
                        type: ShapeTypes.CIRCLE,
                        coordinates: coordinates,
                    };
                    let featureId = uuid_v4();
                    let style = createPointStyle(boostTrajectoryStyle({ color }));
                    let geometry = createEntityGeometry(shape);


                    //Create feature
                    let feature = new Feature({
                        // id: featureId,
                        color: color,
                        zIndex: 1,
                        geometry
                    });
                    //feature.bind(feature)
                    feature.setId(featureId);
                    feature.setStyle(style);
                    trajectoryPointSource.addFeature(feature);
                    lineStringCoords.push(coordinates);
                    prevTimeStamp_s = unix_time_s;
                });
                const prevthreeSubSegmentsTrajectoryCoordinates = [[...(prev3.length ? prev3 : [])], [...(prev2.length ? prev2 : [])], [...(prev1.length ? prev1 : [])], [...currentTrajectoryCoordinates]];
                const shape = {
                    type: ShapeTypes.LINE_STRING,
                    coordinates: prevthreeSubSegmentsTrajectoryCoordinates.flat(),
                };
                const geometry = createEntityGeometry(shape);
                const style = createPointStyle(boostTrajectoryStyle({ color: TrajectoryColor.BLUE, lineDash: LineDash }));
                const feature = new Feature({
                    geometry: geometry,
                    zIndex: 0,
                });
                feature.setId("current trajectory line string" + uuid_v4());
                feature.setStyle(style);
                curTrajectoryLineSource.addFeature(feature);
                prevthreeSubSegmentsTrajectoryCoordinates.forEach((coordinates, index) =>
                {
                    coordinates.forEach((coordinate) =>
                    {
                        let opacityVariant = greenColorOpacityVariants[(prevthreeSubSegmentsTrajectoryCoordinates.length - 1) - index];
                        const shape = {
                            type: ShapeTypes.CIRCLE,
                            coordinates: coordinate,
                        };
                        const featureId = uuid_v4();
                        const style = createPointStyle(boostTrajectoryStyle({ color: opacityVariant }));
                        const geometry = createEntityGeometry(shape);


                        //Create feature
                        let feature = new Feature({
                            // id: featureId,
                            color: opacityVariant,
                            zIndex: 1,
                            geometry
                        });
                        //feature.bind(feature)
                        feature.setId(featureId);
                        feature.setStyle(style);
                        curTrajectoryPointSource.addFeature(feature);

                    });
                });
                subSeg.currentTrajectoryLayer = [curTrajectoryLineLayer, curTrajectoryPointLayer];
                prev3 = prev2;
                prev2 = prev1;
                prev1 = [...currentTrajectoryCoordinates];
            });
            //converting set to array
            segData.activeZones = [...segData.activeZones];


            // create and add line string feature to map
            const shape = {
                type: ShapeTypes.LINE_STRING,
                coordinates: lineStringCoords,
            };

            const geometry = createEntityGeometry(shape);
            const style = createPointStyle(boostTrajectoryStyle({ color: TrajectoryColor.BLUE, lineDash: LineDash }));

            const feature = new Feature({
                geometry: geometry,
                zIndex: 0,
            });
            //feature.bind(feature);
            feature.setId("trajectory line string" + segmentId);
            feature.setStyle(style);

            trajectoryLineSource.addFeature(feature);
            segData.trajectoryLayers = [trajectoryLineLayer, trajectoryPointLayer];


            const formattedStartTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(segData.startTime.unixTime_ms / 1000, timeZoneId);
            const formattedEndTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(segData.endTime.unixTime_ms / 1000, timeZoneId);
            segData.formattedStartTime = formattedStartTime;
            segData.formattedEndTime = formattedEndTime;
            segData.startUTC = convertUnixSecToUTC(segData.startTime.unixTime_ms / 1000, timeZoneId);
            segData.endUTC = convertUnixSecToUTC(segData.endTime.unixTime_ms / 1000, timeZoneId);

            // preparing segment display name
            if (segData.subSegments.length === 1)
            {
                segData.displayName = `${segData.subSegments[0].displayName}`;
            }
            else if (segData.subSegments.length > 1)
            {
                const totalSubSegments = segData.subSegments.length;
                segData.displayName = `${segData.subSegments[0].displayName} → ${segData.subSegments[totalSubSegments - 1].displayName}`;
            }
            else
            {
                segData.displayName = capitalize(floorName);
            }

            if (!acc[buildingId])
            {
                acc[buildingId] = [segData];
                return acc;
            }

            acc[buildingId].push(segData);
            return acc;

        }, {});

        return { propertyId, [bleTagId]: preparedData };

    }

    return { propertyId, [bleTagId]: {} };
};


/**
 *
 * @param {Object} response
 *
 * @returns {Object}- {[floorId]: {zoneGeofenceTraffic} }
 */
export const processTagZoneGeofence = ({ zoneGeofenceTraffic }, tag) =>
{


    const zoneFloorMap = {};
    let totalBuildingUserCount = 0;
    let totalBuildingAverageDwell = 0;

    // merging tagsVisit->zoneGeofenceData to zoneGeofence visit
    Object.keys(zoneGeofenceTraffic).forEach((tagKey) =>
    {
        if (tag && tag !== tagKey.toUpperCase())
        {
            return;
        }
        zoneGeofenceTraffic[tagKey].forEach((zone) =>
        {
            const { floorId, zoneGeofenceId, usersPerHour } = zone;
            //zoneFloorMap doesn't contain floor or zoneGeofence id
            if (!zoneFloorMap?.[floorId]?.[zoneGeofenceId])
            {
                //if floor is present that means their in no zonegeofence
                if (zoneFloorMap[floorId])
                {
                    //adding new zonegeofence to zoneFloorMap while keeping track of previous zoneFloorMap
                    zoneFloorMap[floorId] = {
                        //previous floor mapdata
                        ...zoneFloorMap[floorId],
                        //adding new zonegeofence
                        [zoneGeofenceId]: {
                            //this spread is needed our api data has some meta info about zonegeofence like
                            //zoneLabel property id floorId zoneGeofenceId
                            ...zone,
                            usersPerHourMap: new Map(),
                            userCount: 0,
                            averageDwellMinutes: 0,
                        }
                    };
                }
                else
                {   //we don't have any floor data in previous zoneFloorMap
                    //adding floor and zonegeofence
                    zoneFloorMap[floorId] = {
                        [zoneGeofenceId]: {
                            //this spread is needed our api data has some meta info about zonegeofence like
                            //zoneLabel property id floorId zoneGeofenceId
                            ...zone,
                            usersPerHourMap: new Map(),
                            userCount: 0,
                            averageDwellMinutes: 0,
                        }
                    };
                }
                const usersPerHourMap = zoneFloorMap[floorId][zoneGeofenceId].usersPerHourMap;
                //preparing dummy 24 hr utc hour data
                for (let i = 0; i < 24; i++)
                {
                    usersPerHourMap.set(i, {
                        hourUTC: i,
                        averageDwellMinutes: 0,
                        userCount: 0,
                        countOfHourData: 0,
                        sumOfDwellMinutes: 0
                    });
                }
                zoneFloorMap[floorId][zoneGeofenceId].usersPerHourMap = usersPerHourMap;
            }
            const usersPerHourMap = zoneFloorMap[floorId][zoneGeofenceId].usersPerHourMap;
            let zoneTotalUsers = zoneFloorMap[floorId][zoneGeofenceId].userCount;
            //preparing UTC hour data for zonegeofence from TagData
            usersPerHour.forEach((hourData) =>
            {
                const { hourUTC, userCount, averageDwellMinutes } = hourData;
                const prevData = usersPerHourMap.get(hourUTC);
                prevData.userCount += userCount;
                prevData.sumOfDwellMinutes += averageDwellMinutes;
                zoneTotalUsers += userCount;
                totalBuildingUserCount += userCount;
                totalBuildingAverageDwell += averageDwellMinutes;
                prevData.countOfHourData++;
            });
            zoneFloorMap[floorId][zoneGeofenceId].usersPerHourMap = usersPerHourMap;
            zoneFloorMap[floorId][zoneGeofenceId].userCount = zoneTotalUsers;

        });

    });
    //preparing the result object by floor -> mapping [floorID] : { zoneGeofenceTraffic }
    const result = {};

    Object.entries(zoneFloorMap).forEach(([floorId, floorData]) =>
    {
        let zoneGeofenceTraffic = [];
        Object.values(floorData).forEach((zoneData) =>
        {
            const {
                usersPerHourMap,
                userCount,
            } = zoneData;
            const usersPerHour = [...usersPerHourMap.values()].map((hd) => ({
                ...hd,
                //user percentage based on building
                userCountPercentage: hd.userCount / totalBuildingUserCount,
                //calculating average dwell mins based on tagData
                averageDwellMinutes: hd.countOfHourData > 0 ? hd.sumOfDwellMinutes / hd.countOfHourData : 0.0
            }));
            zoneData.userCountPercentage = userCount / totalBuildingUserCount;
            zoneData.usersPerHour = usersPerHour;
            //calculating averageDwellMinutes based on tagData
            zoneData.averageDwellMinutes = usersPerHour.reduce((acc, hd) => acc + +hd.averageDwellMinutes, 0);
            zoneGeofenceTraffic.push(zoneData);
        });
        result[floorId] = { zoneGeofenceTraffic };
    }, {});



    return result;



};


/**
 *
 * @param {propertyId} param0.propertyId
 * @param {propertyId} param0.buildingId
 * @param {propertyId} param0.bleTagsHeatmaps -?> {[floorId]:{tagId,floorId,heatmaps:{start_UnixTime_s,end_UnixTime_s,heatmap:[]}}}
 *
 * @returns {} -> {[floorId]:{propertyId, buildingId,floorId , heatmaps: [{start_UnixTime_s,end_UnixTime_s,pointSpacing,kernelBuffer,heatmap:[[-93.645728480800486, 42.001813208849448,0.10526315867900848]]}] }}
 */

export const processTagsHeatMapBuildingDataAndMergeTagsDataAndGroupedByFloor = ({ propertyId, buildingId, bleTagsHeatmaps }) =>
{
    let heatMapGroupedByFloor = {};

    if (bleTagsHeatmaps)
    {
        //nav team has grouped tag data by floor
        heatMapGroupedByFloor = Object.entries(bleTagsHeatmaps)
            .reduce((acc, [floorId, tagsHeatMaps]) =>
            {
                const tagsHeatMapsByTagIdHash = {};
                const mergedTagsHeatMapsByTimeData = tagsHeatMaps.reduce((acc, tagHeatmapData) =>
                {
                    const { heatmaps } = tagHeatmapData;
                    tagsHeatMapsByTagIdHash[tagHeatmapData.tagId] = tagHeatmapData;
                    heatmaps.forEach((timeInnervatedHeatmap, idx) =>
                    {
                        const { start_UnixTime_s, end_UnixTime_s, heatmap, ...rest } = timeInnervatedHeatmap;
                        const uniqueId = start_UnixTime_s + " " + end_UnixTime_s;
                        if (!acc[uniqueId])
                        {
                            acc[uniqueId] = { start_UnixTime_s, end_UnixTime_s, heatmap: [], ...rest };
                        }
                        const preTimeFrameHeatMap = acc[uniqueId];
                        preTimeFrameHeatMap.heatmap = preTimeFrameHeatMap.heatmap.concat([...heatmap]);
                    });
                    tagsHeatMapsByTagIdHash[tagHeatmapData.tagId].heatmaps.sort((a, b) => a.start_UnixTime_s - b.start_UnixTime_s);

                    return acc;
                }, {});
                acc[floorId] = { propertyId, buildingId, floorId, heatmaps: Object.values(mergedTagsHeatMapsByTimeData), tagsHeatMapsByTagIdHash };

                return acc;

            }, heatMapGroupedByFloor);

        // loop through heatmaps by floorId and sort heatmap array by start_UnixTime_s
        Object.keys(heatMapGroupedByFloor).forEach((floorId) =>
        {
            heatMapGroupedByFloor[floorId].heatmaps.sort((a, b) => a.start_UnixTime_s - b.start_UnixTime_s);
        });


    }


    return heatMapGroupedByFloor;

};


export const toggleBoostTagTrajectoryLayersVisibility = (segments, currentSegmentInfo, prevSegmentInfo) =>
{
    const { activeSegmentIndex, activeSubSegmentIndex, floorId } = currentSegmentInfo;
    if (!Array.isArray(segments) || !segments.length) return {};
    //console.log(segments, currentSegmentInfo, prevSegmentInfo);




    const { activeSegmentIndex: prevActiveSegmentIndex, activeSubSegmentIndex: prevActiveSubSegmentIndex } = prevSegmentInfo ?? {};


    let visibilityHash = segments.reduce((acc, segment) =>
    {
        const { layersHash } = segment;
        Object.keys(layersHash).forEach(key => acc[key] = false);
        return acc;
    }, {});

    if (activeSegmentIndex >= 0)
    {
        const { segmentId: activeSegmentId } = segments[activeSegmentIndex];
        if (floorId == segments[activeSegmentIndex].floorId)
        {
            if (activeSubSegmentIndex >= 0)
            {
                visibilityHash[(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.SUB_SEGMENT_LINE + activeSegmentId + "" + activeSubSegmentIndex)] = true;
                visibilityHash[(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.SUB_SEGMENT_POINTS + activeSegmentId + "" + activeSubSegmentIndex)] = true;

            }
            else
            {
                visibilityHash[(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.COMPLETE_TRAJECTORY_LINE + activeSegmentId)] = true;
                visibilityHash[(BOOST_TAGS_TRAJECTORY_LAYERS_PRE_FIX.COMPLETE_POINTS + activeSegmentId)] = true;
            }
        }
    }

    return visibilityHash;



};
