import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Popup, Radio } from "semantic-ui-react";

export const MapOptionToggles = ({ mapOptions, setMapOptions, }) =>
{
    const trans = useTranslation().t;

    const handleOptionsChange = useCallback(({ name, value }) =>
    {
        let newMapOptions = JSON.parse(JSON.stringify(mapOptions));

        if (!!newMapOptions?.[name])
        {
            newMapOptions[name].value = value;
        }

        setMapOptions(newMapOptions);

    }, [mapOptions, setMapOptions,]);

    const renderContent = useCallback((option) =>
    {
        if (option.name === "names")
        {
            return trans("Boost_Analytics.infoText.Text");
        }
        else if (option.name === "style")
        {
            return trans("Boost_Analytics.infoText.Map_Styles");
        }
        else if (option.name === "overlays")
        {
            return trans("Boost_Analytics.infoText.Map_Overlays");
        }
        else if (option.name === "zoneGeofence")
        {
            return trans("Boost_Analytics.infoText.Zone_Geofence");
        }
    }, []);

    const displayPopUp = React.useCallback((optionsList) =>
    {
        return (
            optionsList?.map((option) =>

                <div key={option.name} className={`${option.name}`}>
                    <Popup className="tooltipPopup positionPopup"
                        offset={option?.styles?.offset ?? [-18, 130]}
                        trigger={<div className="toggletooltipCover"> <MapOptionToggle
                            value={option.value}
                            key={option.name}
                            name={option.name}
                            onChange={handleOptionsChange}
                        /></div>}
                        position="left center"
                        content={`${option.value ? trans("Boost_Analytics.infoText.Hide") : trans("Boost_Analytics.infoText.Show")} ${renderContent(option)}`} />
                </div>)
        );

    }, [mapOptions]);

    const renderMapOptionsToggles = React.useCallback(() =>
    {
        let optionsList = Object.values(mapOptions);

        return (
            <div className="toggleSliderCover">

                {
                    displayPopUp(optionsList) // for toggling Text and Overlays
                }

            </div>
        );
    }, [mapOptions, handleOptionsChange]);

    return (
        <div className="toggleSliderBox">
            {
                renderMapOptionsToggles()
            }
        </div>
    );
};

const MapOptionToggle = ({ value, name, onChange }) =>
{
    const handleOptionChange = React.useCallback((e, { name, checked }) =>
    {
        (onChange) && onChange({ value: checked, name });
    }, [onChange,]);

    return (
        <React.Fragment>
            <Radio
                toggle
                label={name}
                name={name}
                onChange={handleOptionChange}
                checked={value}
            />
        </React.Fragment>
    );
};
