import { capitalize } from "lodash";
import { FILTER_NAMES } from "../_constants/filterConstants";
import { ADJUSTED_SEARCH_TYPES_FOR_UI, SEARCH_TYPES_OF_DB } from "../_constants/webEngagementConstants";
import { getDurationFromUTC, getSumOfDurationOfUTCFields } from "./date.luxon.utils";
import { getDurationInReadAbleUnits } from "./utils";
import { roundWithPrecision } from "mapsted.utils/numbers";
import { deepCopy } from "mapsted.utils/objects";



/**
 * 
 * @param {*} param0.propertyId
 * @param {*} param0.startTimeUTC
 * @param {*} param0.endTimeUTC
 * @returns 
 */

export const analyticsGlobalFiltersToWebEnagamentFilters = ({ propertyId, startTimeUTC, endTimeUTC, deviceType, maxSessionMins, minSessionMins, minNumberOfSessions, maxNumberOfSessions }) => ({ propertyId, startTimeUTC, endTimeUTC, deviceType, maxSessionMins, minSessionMins, minNumberOfSessions, maxNumberOfSessions });
/**
 * 
 * @param {String} param0.DeviceCreatedAt || "UTC TIME STRING"
 * @param {String} param0.StartAt || "UTC TIME STRING"
 * 
 * @returns {boolean}
 * 
 */
export const isNewUser = ({ DeviceCreatedAt, StartAt }) =>
{
    const diffInDeviceCreatedAndUserSessionInSecs = getDurationFromUTC({ StartAt: DeviceCreatedAt, EndAt: StartAt }, "seconds");

    return diffInDeviceCreatedAndUserSessionInSecs <= 1;

};


export const groupSessionsAndGetNewUsersCountRepeatUsersCount = (currentIntervalData = []) =>
{
    if (!currentIntervalData.length)
    {
        return {

            newUsersCount: 0,
            repeatUsersCount: 0,
            sumOfRepeatIntervalsDurationInMins: 0
        };
    }
    let lastUserVisitedTime = {};

    let deepCopyCurrentIntervalData = deepCopy(currentIntervalData);

    return deepCopyCurrentIntervalData.sort((a, b) => getDurationFromUTC({ StartAt: a.StartAt, EndAt: b.StartAt })).reduce((acc, session) =>
    {

        const { DeviceCreatedAt, StartAt, DeviceId } = session;
        const lastedVisitedTimeValue = lastUserVisitedTime[DeviceId] ?? DeviceCreatedAt;
        if (isNewUser(session))
        {
            acc.newUsersCount += 1;

        } else
        {
            acc.repeatUsersCount += 1;
            acc.sumOfRepeatIntervalsDurationInMins += getDurationFromUTC({ StartAt: lastedVisitedTimeValue, EndAt: StartAt }, "minutes");

        }

        lastUserVisitedTime[DeviceId] = StartAt;

        return acc;

    },
        {

            newUsersCount: 0,
            repeatUsersCount: 0,
            sumOfRepeatIntervalsDurationInMins: 0
        });
};

export const getAdjustedSearchTypesForUi = (dbSearchType) =>
{

    if (dbSearchType === SEARCH_TYPES_OF_DB.POINT_OF_INTEREST || dbSearchType === SEARCH_TYPES_OF_DB.ENTITY)
    {
        return ADJUSTED_SEARCH_TYPES_FOR_UI.STORE;
    }
    return ADJUSTED_SEARCH_TYPES_FOR_UI[dbSearchType];
};


export const processSessionDataToNumberWidgetDataItems = ({ currentIntervalData, previousIntervalData }, { startTimeUTC, endTimeUTC }, timeZone, languageCode, trans) =>
{

    if (!currentIntervalData.length)
    {
        return {
            noOfVisitorsPercentage: 0,
            monthlyActiveVisitors: 0,
            monthlyAverageVisitTime: 0,
            pageVisitIntervals: 0,
            newVisitors: 0,
            changeInMonthAvgActiveUsers: 0,
            avgSessionTimeOfCurrentIntervalDuration: [],
            changeInSessionDurationPercentage: 0,
            totalVisitors: 0,
            avgSessionTimeUnits: [],
            avgVisitPageInterval: [],
            avgVisitPageIntervalUnits: [],
            changeInPageVisitInterval: 0,
            changeInPageIntervalPercentage: 0,
            changeInNewVisitors: 0
        };
    }

    const totalCurrentSessions = currentIntervalData.length;
    const totalPreviousSessions = previousIntervalData.length;

    let noOfVisitorsPercentage = totalPreviousSessions > 0 ? (totalCurrentSessions - totalPreviousSessions) * 100 / totalPreviousSessions : 100;
    let currentIntervalSessionDurationSumInMins = getSumOfDurationOfUTCFields(currentIntervalData);
    let prevIntervalSessionDurationSumInMins = getSumOfDurationOfUTCFields(previousIntervalData);
    let avgSessionTimeOfCurrentIntervalDurationInMins = roundWithPrecision(currentIntervalSessionDurationSumInMins / totalCurrentSessions);
    let changeInSessionDurationPercentage = prevIntervalSessionDurationSumInMins > 0 ? roundWithPrecision(100 * (currentIntervalSessionDurationSumInMins - prevIntervalSessionDurationSumInMins) / prevIntervalSessionDurationSumInMins) : 100;

    let formattedAverageVisitsTime = getDurationInReadAbleUnits(avgSessionTimeOfCurrentIntervalDurationInMins, languageCode);
    let avgSessionTimeOfCurrentIntervalDuration = formattedAverageVisitsTime.value;
    let avgSessionTimeUnits = formattedAverageVisitsTime.units;

    const { newUsersCount, repeatUsersCount, sumOfRepeatIntervalsDurationInMins } = groupSessionsAndGetNewUsersCountRepeatUsersCount(currentIntervalData);
    const prevIntervalGroupedData = groupSessionsAndGetNewUsersCountRepeatUsersCount(previousIntervalData);
    const { value: avgVisitPageInterval, units: avgVisitPageIntervalUnits } = getDurationInReadAbleUnits(sumOfRepeatIntervalsDurationInMins / totalCurrentSessions);
    const changeInPageVisitInterval = prevIntervalGroupedData.sumOfRepeatIntervalsDurationInMins > 0 ? roundWithPrecision((prevIntervalGroupedData.sumOfRepeatIntervalsDurationInMins - sumOfRepeatIntervalsDurationInMins) * 100 / sumOfRepeatIntervalsDurationInMins) : 100;

    let changeInMonthAvgActiveUsers = prevIntervalGroupedData.repeatUsersCount > 0 ? ((repeatUsersCount - prevIntervalGroupedData.repeatUsersCount) * 100 / prevIntervalGroupedData.repeatUsersCount) : 100;

    const newVisitors = newUsersCount;
    const changeInNewVisitors = prevIntervalGroupedData.newUsersCount > 0 ? roundWithPrecision(100 * (newUsersCount - prevIntervalGroupedData.newUsersCount) / prevIntervalGroupedData.newUsersCount) : 100;

    return {
        noOfVisitorsPercentage: roundWithPrecision(noOfVisitorsPercentage),
        totalVisitors: roundWithPrecision(totalCurrentSessions),
        monthlyActiveVisitors: repeatUsersCount,
        changeInMonthAvgActiveUsers: roundWithPrecision(changeInMonthAvgActiveUsers, 0),
        changeInSessionDurationPercentage: roundWithPrecision(changeInSessionDurationPercentage),
        avgSessionTimeOfCurrentIntervalDuration,
        avgSessionTimeUnits,
        newUsersCount,
        repeatUsersCount,
        sumOfRepeatIntervalsDurationInMins,
        avgVisitPageInterval: [avgVisitPageInterval[0]],
        avgVisitPageIntervalUnits: [avgVisitPageIntervalUnits[0]],
        changeInPageVisitInterval,
        newVisitors,
        changeInNewVisitors
    };



};


export const processWebEngagementFilterMeta = ({ deviceTypes, sessionMeta }) =>
{
    const processedSessionMeta = Object.entries(sessionMeta).reduce((acc, [key, value]) =>
    {
        acc[key] = Number(value);
        return acc;
    }, {});
    const preparedResponse = {
        ...processedSessionMeta,
        [FILTER_NAMES.PLATFORM]: [{
            "key": "All",
            "text": "All",
            "value": "all"
        }]
    };

    // preparing the data for ui-dropdown 
    // name should be capitalize and name string should be present in resource.json for lang support  
    preparedResponse[FILTER_NAMES.PLATFORM].push(...deviceTypes.map(dType => (
        {
            id: dType.OS,
            key: dType.OS,
            name: capitalize(dType.OS),
            value: dType.OS, text: (dType.OS)
        }
    )
    )
    );

    return preparedResponse;
};