import React from "react";
import { convertSecondsTo_MMSS } from "../../../_utils/date.utils";
import Translation from "../../common/translation/Translation";

const InsightsZoneGeofence = ({ data, selectedZoneGeofenceHash, onClick }) => (
    <>
        <div className="tableboxItemRow">
            <div className="tableboxItemColumn">
                <strong><Translation text="Insights.Name" /></strong>
            </div>
            <div className="tableboxItemColumn ctableColumn">
                <strong><Translation text="Insights.Duration" /></strong>
            </div>
        </div>
        {data.map(({ label, timeSpent_s, zoneGeofenceId }) => (
            <div
                className={`tableboxItemRow ${selectedZoneGeofenceHash
                    && selectedZoneGeofenceHash[zoneGeofenceId]
                    ? "selected"
                    : ""
                    }`}
                key={zoneGeofenceId}
                onClick={() => onClick(zoneGeofenceId)}
            >
                <div className="tableboxItemColumn">
                    <span>{label}</span>
                </div>
                <div className="tableboxItemColumn ctableColumn">
                    {convertSecondsTo_MMSS(timeSpent_s)}
                </div>
            </div>
        ))}
    </>
);

export default InsightsZoneGeofence;
