import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

export const Loader = ({ active, animation = true }) => {
    const trans = useTranslation().t;
    return (
        <div className="loading-container" hidden={!active}>
            {animation && <img src="/img/loading-m.gif" alt={trans("index.Loading___")} />}
        </div>
    );
};

/* <div className="loading-screen" hidden={active} >
    <div className="loading-comp">
        <img src="/images/loading-m.gif" alt="Loading..." />
        <span>Loading Mapsted Maps...</span>
    </div>
    <div className="loading-footer">
        powered by <img src="/images/logo-loading.png" />
    </div>
</div>     */
