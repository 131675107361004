import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsDestinations } from '../../../_api/queries';
import { processDestinationDataToDestinationsByTime } from '../../../_utils/chart.utils';
import useCommon from '../../pages/useCommon';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const useDestinationBarChart = () => 
{
  const destinationQuery = useWebAnalyticsDestinations();

  const { timeZone, dateRange } = useCommon();

  const processedWidgetData = useMemo(() => 
  {
    let processedData = {
      barChart: {
        data: [],
        keys: [],
        key: "",
        index: "time",
        legendLabel: () => { },
        colors: () => { }
      },
      maxDestinationCount: 0,
      maxDestinationTime: ""
    };
    const { isSuccess, data: queryData } = destinationQuery;
    // process the data
    if (!!isSuccess && queryData) 
    {
      const { currentIntervalData: data } = queryData || {};
      processedData = processDestinationDataToDestinationsByTime(data, dateRange, timeZone);

    }

    return processedData;
  }, [destinationQuery, timeZone, dateRange]);

  const rawQuery = useMemo(() => 
  {
    return {
      ...destinationQuery, data: {
        currentIntervalData: processedWidgetData.barChart.data
      }
    };
  }, [processedWidgetData, destinationQuery]);


  return { rawQuery, destinationQuery, processedWidgetData };
};

export default useDestinationBarChart;