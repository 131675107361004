

import { useContext } from "react";
import { InsightsContext } from "./InsightsContext";



const useInsights = () => useContext(InsightsContext);



export default useInsights;
