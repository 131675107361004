import React from "react";
import { Modal, Button } from "semantic-ui-react";
import "./ModalLayout.css";

export const ModalLayout = ({ dimmer, size, scrolling, trigger, className, isRounded, goBack, heading, actions, children, open, onClose, onGoBack, closeBlue }) =>
{
    return (
        <Modal
            data-testid={"modal-client-id"}
            dimmer={dimmer}
            size={size}
            trigger={trigger}
            closeIcon={<Button className="closeBtn" />}
            className={`modalLayout${isRounded ? " roundedModal":""}${className ? " "+className:""}`}
            open={open}
            onClose={onClose}>
            {heading &&
                <div className="aligner">
                    <Modal.Header>
                        {heading}
                    </Modal.Header>
                </div>
            }
            <Modal.Content scrolling={scrolling}>
                {children}
            </Modal.Content>
            {actions &&
                <Modal.Actions>
                    {actions}
                </Modal.Actions>
            }
        </Modal>
    );
}