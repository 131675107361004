import React, { useEffect } from "react";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { DASHBOARD_WIDGETS_INFO_TEXT } from "../../_constants/exportToCsvConstants";
import { convertNumberWidgetsDurationContentToMins, formatTimeStringsForCSV } from "../../_utils/exportToCsvUtils";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { formatMinsToHHMMSS } from "../../_utils/date.utils";
const AverageSessionIntervalNumberWidget = ({ processedWidgetData, sessionIntervalValues, injectProcessedData, trans }) =>
{
    const { isSuccess, data } = processedWidgetData;
    const connectedPageName = useRecoilValue(connectedPageNameState);
    useEffect(() =>
    {
        let durationMins = convertNumberWidgetsDurationContentToMins(sessionIntervalValues);
        const payload = {
            name: "Average App Session Interval",
            data: [{
                "AverageAppSessionInterval": formatTimeStringsForCSV(sessionIntervalValues?.content, sessionIntervalValues?.extra),
                "DurationMins": durationMins,
                "AverageDurationFormatted": formatMinsToHHMMSS(durationMins)
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);
    }, [sessionIntervalValues, connectedPageName]);

    if (!!isSuccess && data !== undefined)
    {
        return (
            <OverviewDetailBox
                max="200px"
                popupContent={trans("ReportsOverviewDetail.Average_session_interval_for_the_selecte")}
                icon="stopwatch"
                content={sessionIntervalValues.content}
                extra={sessionIntervalValues.extra}
                slogan={trans("ReportsOverviewDetail.App_Session_Interval")}
            />
        );
    }
    return <OverviewDetailBoxLoading />;
};

export { AverageSessionIntervalNumberWidget };
