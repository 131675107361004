import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { retentionRateFilterSelector, activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetLoadingBoxReportsLarge } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import Scrollbars from "react-custom-scrollbars";
import { processRetentionRateWidget } from "../../_utils/chart.utils";
import { LineChart } from "../charts/LineChart";
import { RETENTION_RATE_LINE_CHART_OPTIONS } from "../../_constants/chartConstants";
import { GREENS } from "../../_constants/colors";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import { useRetentionRateEngagementOverviewQuery } from "../../_api/queries";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";

export const RetentionRateWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const [selectedRetentionDataIDX, setSelectedRetentionDataIDX] = useState(0);
    const retentionRateRequestFilter = useRecoilValue(retentionRateFilterSelector);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);

    const retentionRateQuery = useRetentionRateEngagementOverviewQuery();

    const handleChangeSelectedRetentionIDX = useCallback((idx) =>
    {
        setSelectedRetentionDataIDX(idx);
    }, [setSelectedRetentionDataIDX]);

    // watches for new data and resets retention rate idx
    useEffect(() => 
    {
        if (retentionRateQuery.isSuccess)
        {
            setSelectedRetentionDataIDX(0);
        }
    }, [retentionRateQuery.isSuccess]);

    // render widget/loading/error
    const processedRetentionRateWidgetData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data, refetch } = retentionRateQuery;

        let processedData;

        // process the data
        if (!!isSuccess && data)
        {
            const dateRange = {
                startDate: new Date(retentionRateRequestFilter.startTimeUTC),
                endDate: new Date(retentionRateRequestFilter.endTimeUTC)
            };

            processedData = processRetentionRateWidget({ data, dateRange, timeZone, trans });
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };

    }, [retentionRateQuery?.data, retentionRateRequestFilter, timeZone, trans]);
    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedRetentionRateWidgetData?.data)
        {
            return;
        };
        const { weeklyRetentionArray } = processedRetentionRateWidgetData?.data;

        return weeklyRetentionArray.reduce((result, week) =>
        {
            if(week?.weeklyRetentionRate?.length > 0)
            {
                let weekObj= {
                    Week : week.formattedWeek,
                    Users : week.uniqueUsersCount
                }
                //
                for (let i = 0; i <= 11; i++)
                {
                    weekObj[`Week ${i + 1}`] = `${ week?.weeklyRetentionRate[i]?.toString() ? week?.weeklyRetentionRate[i] + "%" : " "  }`;
                }
                result.push(weekObj);
            }
            return result;
            
        },[]);
    }, [processedRetentionRateWidgetData?.data, connectedPageName]);

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "What percentage of users continue using your app over a specific period of time",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "What percentage of users continue using your app over a specific period of time",
            //     data: retentionRateQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });


    }, [processedChartDataForExport]);

    const customSliceTooltip = React.useCallback(({ slice }) => (
        <div className="widgetsTooltipBox">
            <strong>
                {slice.points[0].data.x}
            </strong>
            {
                // each points data ie. "[point.color] 120 New Visits"
                slice.points.map((point) => (
                    <div key={point.id}>

                        <div className="tooltipItemRow" key={point.id + "retention rate"}>
                            <span style={{ background: point.borderColor }}></span>
                            {point.data.label} {trans("RetentionRateWidget.Retention_Rate")}
                            <strong>{`${point.data.y}%`}</strong>
                        </div>

                        <div className="tooltipItemRow" key={point.id + "user count"}>
                            <span style={{ background: point.borderColor }}></span>
                            {point.data.label} {trans("RetentionRateWidget.User_Count")}
                            <strong>{`${point.data.userCount}`}</strong>
                        </div>

                    </div>
                ))
            }

        </div>
    ), [trans]);

    const renderRetentionRateWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedRetentionRateWidgetData;

        if (isSuccess && data)
        {
            const { weeklyRetentionArray, day30Retention, day90Retention } = data;
            return (
                <React.Fragment>
                    <p className="para">{trans("RetentionRateWidget._Your_app_has_a_30-day_Retention_Rate_of")} <strong>{day30Retention}%</strong> {trans("RetentionRateWidget._and_a_90-day_Retention_Rate_of_")} <strong>{day90Retention}%</strong> {trans("RetentionRateWidget._among_your_users")}</p>
                    <div className="ChartWrapper">
                        <LineChart
                            data={weeklyRetentionArray[selectedRetentionDataIDX].lineData}
                            options={RETENTION_RATE_LINE_CHART_OPTIONS}
                            axisLeftMaxValue={100}
                            customSliceTooltip={customSliceTooltip}
                        />
                    </div>

                    <UserRetentionTable weeklyRetentionArray={weeklyRetentionArray} selectedRetentionDataIDX={selectedRetentionDataIDX} onRetentionRowClick={handleChangeSelectedRetentionIDX} />
                </React.Fragment>
            );
        }
        // if error
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedRetentionRateWidgetData)} />;
        }
        else
        {
            return WidgetLoadingBoxReportsLarge();
        }
    }, [processedRetentionRateWidgetData, selectedRetentionDataIDX, customSliceTooltip, handleChangeSelectedRetentionIDX, trans]);


    return (
        <DefaultBox className="alignedWidget" heading={trans("RetentionRateWidget.What_percentage_of_users_continue_using_")}>
            {
                renderRetentionRateWidget()
            }
        </DefaultBox>
    );
};

//
const UserRetentionTable = ({ weeklyRetentionArray, selectedRetentionDataIDX, onRetentionRowClick }) => (
    <div className="userRetentionTableCover">
        <div className="userRetentionTable" style={{ direction: "ltr" }}>
            <Scrollbars className="tableScroll" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"290px"} >
                <div className="retentionTableCover">
                    <UserRetentionRowHead />

                    {
                        weeklyRetentionArray.reduce((result, retentionData, i) =>
                        {
                            const { formattedDate, weeklyRetentionRate } = retentionData;
                            if (weeklyRetentionRate.length > 0)
                            {
                                result.push(
                                    <UserRetentionRow key={formattedDate} isSelected={i === selectedRetentionDataIDX} retentionData={retentionData} onRetentionRowClick={() => onRetentionRowClick(i)} />
                                );
                            }
                            return result;
                        }, [])
                    }

                </div>
            </Scrollbars>
        </div>
    </div>
);

const UserRetentionRowHead = () =>
{
    const trans = useTranslation().t;
    const renderHeaderColumns = useCallback(() =>
    {
        let headerColumns = [];
        for (let i = 1; i <= 12; i++)
        {
            headerColumns.push(
                <UserRetentionColumn key={`header ${i}`} content={trans("RetentionRateWidget.WEEK") + ` ${i}`} />
            );
        }

        return headerColumns;
    }, [trans]);

    return (
        <div className="userRetentionRow">
            <UserRetentionColumn content={trans("RetentionRateWidget.Week")} />
            {renderHeaderColumns()}
        </div>
    );
};



const UserRetentionRow = ({ retentionData, isSelected, onRetentionRowClick }) =>
{
    const trans = useTranslation().t;
    const { weeklyRetentionRate, uniqueUsersCount, formattedWeek } = retentionData;

    const renderColumns = useCallback(() =>
    {
        let columns = [];

        for (let i = 0; i < 12; i++)
        {
            let retentionRate = weeklyRetentionRate[i];
            let content = "";
            let color;

            if (retentionRate !== undefined)
            {
                content = `${retentionRate}%`;

                if (retentionRate >= 80)
                {
                    color = GREENS.categoryShade1;
                }
                else if (retentionRate >= 60)
                {
                    color = GREENS.categoryShade2;

                }
                else if (retentionRate >= 40)
                {
                    color = GREENS.categoryShade3;

                }
                else if (retentionRate >= 20)
                {
                    color = GREENS.categoryShade4;
                }
                else
                {
                    color = GREENS.categoryShade5;

                }
            }

            columns.push(
                <UserRetentionColumn key={`${formattedWeek} ${i}`} content={content} color={color} />
            );
        }

        return columns;
    }, [weeklyRetentionRate, formattedWeek]);

    return (
        <div className="userRetentionRow">
            <UserRetentionColumn className={isSelected ? " active" : ""} onClick={onRetentionRowClick}>
                {
                    (isSelected)
                        ? (
                            <b>{formattedWeek}
                                <span>{uniqueUsersCount} {trans("RetentionRateWidget.users")}</span>
                            </b>
                        )
                        : (
                            <p>{formattedWeek}
                                <span>{uniqueUsersCount} {trans("RetentionRateWidget.users")}</span>
                            </p>
                        )
                }

            </UserRetentionColumn>
            {
                renderColumns()
            }
        </div>
    );
};

const UserRetentionColumn = ({ className, content, children, color, onClick }) => (
    <div className={`userRetentionColumn${className ? " " + className : ""}`} style={{ backgroundColor: color, cursor: onClick ? "pointer" : "default" }} onClick={onClick}>
        {content}
        {children}
    </div>
);
