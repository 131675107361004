import React from "react";

const ServiceProviderInfoText = ({ maxServiceProvider, trans }) => {
    return (
        <p className="para">
            {!!maxServiceProvider && (
                <React.Fragment>
                    <strong>{maxServiceProvider}</strong>{" "}
                    {trans("ServiceProviderWidget.is_the_most_commonly_used_service_provid")}
                </React.Fragment>
            )}
        </p>
    );
};

export default ServiceProviderInfoText;
