import React, { memo } from 'react'
import { OverviewDetailBoxLoading } from './overviewDetailBox/OverviewDetailBox';

export const NumberWidgetConditionalLoader = memo(({ rawQuery, children, errorChildren, noDataChildren }) =>
{
    const { isError, isSuccess, data } = rawQuery;
    if (isSuccess && !!data && !!data.length)
    {
        return children
    }
    if (isSuccess && !!data && !data.length)
    {
        return noDataChildren
    }
    if (!!isError)
    {
        return errorChildren
    }
    return <OverviewDetailBoxLoading />
})


export const NumberWidgetsWrapper = memo(({ children, clsName = "" }) => <div className={ `overviewDetail ${ clsName }` }>

    { children }
</div>)

