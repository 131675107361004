import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import serverApi from '../../../_api/server.api';
import
{
  NAV_ANALYTICS_QUERY_KEYS,
  QUERY_OPTIONS
} from "../../../_constants/queryConstants";
import { processZoneGeofencePoiVisits } from "../../../_utils/chart.utils";
import useCommon from "../../pages/useCommon";


const useZoneGeo = () =>
{
  const common = useCommon();
  const { environmentConstants, filters, hasAccessToProperty } = common;
  const { analyticsRequestFilter } = filters;

  const zoneGeofencePoiVisitsQuery = useQuery(
    [
      NAV_ANALYTICS_QUERY_KEYS.ZONE_GEOFENCES,
      { analyticsRequestFilter, environmentConstants },
    ],
    async () =>
    {
      if (!!analyticsRequestFilter && environmentConstants)
      {
        const result = await serverApi.getNavigationAnalyticsData(
          analyticsRequestFilter,
          NAV_ANALYTICS_QUERY_KEYS.ZONE_GEOFENCES,
          environmentConstants
        );

        if (!result?.success)
        {
          throw new Error("Zone geofence poi visits was not ok");
        }

        return processZoneGeofencePoiVisits(
          result.data,
          !analyticsRequestFilter.buildingId
        );
      } else
      {
        throw new Error("Filter was not ok");
      }
    },
    {
      enabled:
        !!analyticsRequestFilter &&
        !!environmentConstants &&
        hasAccessToProperty,
      ...QUERY_OPTIONS,
    }
  );

  const [sunburstDrilldownData, setSunburstDrilldownData] = useState(undefined);
  useEffect(() =>
  {
    setSunburstDrilldownData(undefined);
  }, [zoneGeofencePoiVisitsQuery.data]);

  const handleSunburstClick = useCallback(
    (e) =>
    {
      const findObjectInternal = (data, id) =>
        data.find((layer) => layer.id === id);

      const flattenInternal = (data) =>
        data.reduce((acc, item) =>
        {
          if (item.children)
          {
            return [...acc, item, ...flattenInternal(item.children)];
          }
          return [...acc, item];
        }, []);

      let data = zoneGeofencePoiVisitsQuery.data.sunburstChartData;

      if (!!sunburstDrilldownData)
      {
        data = sunburstDrilldownData;
      }

      const flattenedObject = flattenInternal(data.children);
      const foundObject = findObjectInternal(flattenedObject, e.id);

      if (foundObject && foundObject.children)
      {
        setSunburstDrilldownData(foundObject);
      }
    },
    [
      sunburstDrilldownData,
      zoneGeofencePoiVisitsQuery,
      setSunburstDrilldownData,
    ]
  );

  const boxClassName = useMemo(() =>
  {
    if (
      zoneGeofencePoiVisitsQuery.isError ||
      (!!zoneGeofencePoiVisitsQuery.isSuccess &&
        !zoneGeofencePoiVisitsQuery.data?.sunburstChartData?.children?.length >
        0)
    )
    {
      return "hasMessage";
    } else
    {
      return "";
    }
  }, [zoneGeofencePoiVisitsQuery]);

  const onSunburstReset = useCallback(() =>
  {
    setSunburstDrilldownData(undefined);
  }, []);

  const onError = useCallback(() =>
  {
    zoneGeofencePoiVisitsQuery.refetch();
  }, [zoneGeofencePoiVisitsQuery]);
  return {
    ...zoneGeofencePoiVisitsQuery,
    ...common,
    boxClassName,
    handleSunburstClick,
    onSunburstReset,
    onError,
    sunburstDrilldownData,
  };
};


export default useZoneGeo;