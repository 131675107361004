import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, Icon } from "semantic-ui-react";
import { USER_EXPLORER_ITEMS_PER_PAGE_OPTIONS } from "../../../../_constants/constants";
import { DropdownForm } from "../../../common/dropdownForm/DropdownForm";
import "./PaginationBar.css";
import { EXPORTCSV_WIDGET_ITEMS_PER_PAGE_OPTIONS } from "../../../../_constants/exportToCsvConstants";

export const PaginationBar = ({ className, totalPages, totalItems, activePage, onPageChange, onItemsPerPageChange, itemsPerPage, isUserExplorer = true }) =>
{
    const trans = useTranslation().t;
    const showingMin = useMemo(() =>
    {
        return ((activePage - 1) * itemsPerPage) + 1;
    }, [activePage, itemsPerPage]);

    const showingMax = useMemo(() =>
    {
        let max = (activePage * itemsPerPage);

        if (max > totalItems)
        {
            max = totalItems;
        }

        return max;
    }, [activePage, itemsPerPage, totalItems]);

    return (
        <div className={`paginationBar${className ? " " + className : ""}`}>

            <DropdownForm options={isUserExplorer ? USER_EXPLORER_ITEMS_PER_PAGE_OPTIONS : EXPORTCSV_WIDGET_ITEMS_PER_PAGE_OPTIONS} onChange={onItemsPerPageChange} value={itemsPerPage} />
            <Pagination
                className="paginationDashboard"
                boundaryRange={3}
                firstItem={null}
                lastItem={null}
                siblingRange={0}
                totalPages={totalPages}
                activePage={activePage}
                onPageChange={onPageChange}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
            <div className="displayText">
                <span>{trans("PaginationBar.Showing")} <strong>{showingMin} - {showingMax}</strong> {trans("PaginationBar.of")} {totalItems}</span>
            </div>
        </div>
    );
};