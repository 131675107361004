import React, { useEffect, useMemo } from "react";
import { WidgetLoadingBoxMedium } from "../../common/placeholders/Placeholders";
import WidgetView from "../Widget.view";
import useZoneGeo from "./useZoneGeo";
import ZoneGeoSunburstChart from "./ZoneGeoSunburst.chart";
import { useTranslation } from "react-i18next";
import { WIDGET_IDS } from "../../../_constants/widgets";
import { getWidgetTitle } from "../../../_utils/utils";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";
import { roundWithPrecision } from "mapsted.utils/numbers";

const ZonGeoContainer = ({ injectProcessedData }) =>
{
    const zoneWidget = useZoneGeo();
    const { t } = useTranslation();
    const connectedPageName = useRecoilValue(connectedPageNameState);
    const { data } = zoneWidget;

    const processedExportData = useMemo(() => {
        if (!data) {
            return [];
        }

        const sunburstChart = data?.sunburstChartData?.children || [];

        // flattening all nested childrens
        const modifiedExportData = sunburstChart.flatMap((propertyChild) => {
            const floorData = propertyChild?.children?.flatMap((zone) => {
                return zone?.children?.flatMap((room) => {

                    if (room?.children?.length > 0) {
                        // case when property and building both are selected.
                        return room?.children?.map(({ name: roomName, visits }) => ({
                            Building: propertyChild.name,
                            Floor: zone.name,
                            ZoneGeofence: room.name,
                            Room: roomName,
                            Visits: visits,
                            // VisitPercentage: roundWithPrecision((visits / data.totalCount) * 100, 2)
                        }));
                    } else {
                        // case wehn only property is selected from the dropdown.
                        return {
                            Floor: propertyChild.name,
                            ZoneGeofence: zone.name,
                            Room: room.name,
                            Visits: room.visits,
                            // VisitPercentage: roundWithPrecision((room.visits / data.totalCount) * 100, 2)
                        }
                    }

                });
            });

            return floorData;
        });

        return modifiedExportData;
    }, [data, connectedPageName]);

    useEffect(() => {

        const payload =
        {
            name: getWidgetTitle(t, WIDGET_IDS.ZoneGeofencePoiVisitsWidget),
            data: processedExportData,
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [processedExportData]);

    return (
        <WidgetView
            {...zoneWidget}
            LoadingComponent={WidgetLoadingBoxMedium}
            heading={zoneWidget.trans("ZoneGeofencePoiVisitsWidget.Which_areas_of_your_property_receive_the")}
        >
            <ZoneGeoSunburstChart {...zoneWidget} {...zoneWidget?.data} />
        </WidgetView>
    );
};

export default ZonGeoContainer;
