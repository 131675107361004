import React from "react";
import Proptypes from "prop-types";

function ZoneGeoInfoText({ trans, mostVisitedPoi, mostVisitedPoiPercentage }) {
    return (
        <p className="para">
            {mostVisitedPoi && (
                <React.Fragment>
                    {mostVisitedPoiPercentage}% {trans("ZoneGeofencePoiVisitsWidget.of_traffic_went_to")}{" "}
                    <strong>{mostVisitedPoi}</strong> {trans("ZoneGeofencePoiVisitsWidget.in_the_selected_time_period")}
                </React.Fragment>
            )}
        </p>
    );
}
ZoneGeoInfoText.prototype = {
    trans: Proptypes.func.isRequired,
    mostVisitedPoi: (Proptypes.string | Proptypes.number).isRequired,
    mostVisitedPoiPercentage: (Proptypes.string | Proptypes.number).isRequired,
};

export default ZoneGeoInfoText;
