import React from 'react';
import Proptypes from 'prop-types';
const CategoryInfoText = ({ mostVisitedAndSearchedCategory, trans }) => {
    return (
        <p className="para">
            {!!mostVisitedAndSearchedCategory && (
                <>
                    <strong>{mostVisitedAndSearchedCategory}</strong>{' '}
                    {trans('CategoryWidget.is_the_most_searched_and_visited_categor')}
                </>
            )}
        </p>
    );
};

CategoryInfoText.prototype = {
    mostVisitedAndSearchedCategory: Proptypes.string.isRequired,
};

export default CategoryInfoText;
