import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import serverApi from "../../../_api/server.api";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../../_constants/queryConstants";
import { processServiceProviderWidget } from "../../../_utils/chart.utils";
import useCommon from "../../pages/useCommon";

const useServiceProvider = () =>
{
    const common = useCommon();

    const { environmentConstants, filters, hasAccessToProperty } = common;
    const { analyticsRequestFilter } = filters;

    const serviceProviderQuery = useQuery(
        [NAV_ANALYTICS_QUERY_KEYS.SERVICE_PROVIDER, { analyticsRequestFilter, environmentConstants }],
        async () =>
        {
            if (!!analyticsRequestFilter && environmentConstants)
            {
                const result = await serverApi.getNavigationAnalyticsData(
                    analyticsRequestFilter,
                    NAV_ANALYTICS_QUERY_KEYS.SERVICE_PROVIDER,
                    environmentConstants
                );

                if (!result?.success)
                {
                    throw new Error("Service provider response was not ok");
                }

                return processServiceProviderWidget(result.data);
            } else
            {
                throw new Error("Filter was not ok");
            }
        },
        {
            enabled: !!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty,
            ...QUERY_OPTIONS,
        }
    );


    const boxClassName = React.useMemo(() =>
    {
        // if query is in error state or does not have data,

        if (
            serviceProviderQuery.isError ||
            (!!serviceProviderQuery.isSuccess && serviceProviderQuery?.data?.serviceProviderPieData?.length === 0)
        )
        {
            return "hasMessage";
        } else
        {
            return "";
        }
    }, [serviceProviderQuery]);

    const onError = useCallback(() =>
    {
        serviceProviderQuery.refetch();
    }, [serviceProviderQuery]);

    return {
        ...common,
        ...serviceProviderQuery,
        onError,
        boxClassName,
    };
};

export default useServiceProvider;
