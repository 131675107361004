import React from "react";
import { createRoot } from 'react-dom/client';

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import App from "./components/App";

import "fomantic-ui-css/semantic.min.css";
import "./mapsted.ui.css";
import "./index.css";
import "./responsive.css";
import "react-date-range/dist/styles.css"; // main style file (react date range)
import "react-date-range/dist/theme/default.css"; // theme css file (react date range)
import "./_intl/i18n";
import "./components/pages/dashboard/Dashboard.css";
import "./components/elements/heatmapFilter/HeatmapFilter.css";

dayjs.extend(timezone);

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)
