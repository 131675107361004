export const LANGUAGES = {
    en: { flag: "uk", title: "English", value: "en" },
    pt: { flag: "br", title: "Português (Brasil)", value: "pt" },
    fr: { flag: "fr", title: "Français", value: "fr" },
    nl: { flag: "nl", title: "Dutch", value: "nl" },
    ja: { flag: "jp", title: "日本語", value: "ja" },
    es: { flag: "es", title: "Español", value: "es" },
    // he: {flag: "ivrit", title: "עברית", value: "he"}
};

export const LANGUAGES_WITH_TEST = {
    ...LANGUAGES,
    gg: { flag: "bm", title: "Gobbledygook", value: "gg" }
};


// if (process.env.NODE_ENV !== "production")
// {
//     LANGUAGES["gg"] = { flag: "bm", title: "Gobbledygook", value: "gg" };
// }

export const LANGUAGE_OPTIONS = Object.values(LANGUAGES).map(({ value, title }) => ({ text: title, value, key: value }));

/**
 * @param obj {{}}
 * @param fieldsToCheck {string[]}
 * @returns {any[]}
 */
function getLanguages(obj, fieldsToCheck)
{
    const languages = new Set();
    fieldsToCheck.forEach((field) =>
    {
        const value = obj?.[field];
        if (typeof value === "object" && !Array.isArray(value) && value != null)
        {
            Object.entries(value).forEach(([language, value]) => value && languages.add(language));
        }
    });
    return Array.from(languages);
}

export const getBuildingLanguages = (building) =>
    getLanguages(building, ["longName", "shortName", "description", "website", "keywords", "coverImages", "iconImage", "lightIcon", "darkIcon", "mobileSettings"]);

export const getEntityLabelLanguages = (entityLabel) =>
    getLanguages(entityLabel, ["longName", "shortName", "description", "website", "keywords", "coverImages", "iconImage", "lightIcon", "darkIcon"]);
