import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { analyticsRequestFilterSelector, hasAccessToPropertySelector } from "../../store/DashboardAtoms";
import { environmentConstantsState } from "../../store/AppAtoms";
import serverApi from "../../_api/server.api";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { processZoneFlows } from "../../_utils/chart.utils";
import { SankyChart } from "../charts/SankyChart";
import { WidgetLoadingBoxFull, WidgetLoadingBoxLarge } from "../common/placeholders/Placeholders";
import { DefaultBox } from "../dashboard/DefaultBox";
import { ErrorWidgetMessage, NoDataWidgetMessage, WidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { useTranslation } from "react-i18next";
import { zoneGeofenceToColorMapSelector } from "../../store/MapAtoms";
import { refetchQuery } from "../../_utils/query.utils";
import { ButtonIcon } from "../common/buttonIcon/ButtonIcon";

const SANKY_TOTAL_HEIGHT = 800;

/**
 * If controlled component = false, widget displays top three visited flows.
 *
 * If controlled copmponent = true, widget displays flows based off of selected zone geofence and selected filter.
 * @param {Boolean} controlledComponent
 * @param {Object} selectedZoneGeofenceHash - hash maping zoneGeofenceId -> zoneGeofenceInfo
 * @param {List} filters
 */
export const VisitorFlowWidget = ({ controlledComponent, selectedZoneGeofenceHash, onRemoveSelectedZoneGeofence, injectProcessedData }) =>
{
    const trans = useTranslation().t;

    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);
    const zoneGeofenceColorMap = useRecoilValue(zoneGeofenceToColorMapSelector);


    const zoneFlowsQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.ZONE_FLOWS, { analyticsRequestFilter, environmentConstants, selectedZoneGeofenceHash }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {
            const trajectoryLengthWeight = 0.25; // CONST for now (lower = priortize count());

            let result;
            let filter;

            let selectedZoneGeofenceIds = (selectedZoneGeofenceHash)
                ? Object.keys(selectedZoneGeofenceHash)
                : undefined;

            if (selectedZoneGeofenceIds)
            {
                filter = Object.assign({ trajectoryLengthWeight, selectedZoneGeofenceIds: selectedZoneGeofenceIds.toString() }, analyticsRequestFilter);
                // result = await serverApi.getNavigationAnalyticsDataLocal(filter, NAV_ANALYTICS_QUERY_KEYS.ZONE_FLOWS, environmentConstants);
            }
            else
            {
                filter = Object.assign({ trajectoryLengthWeight }, analyticsRequestFilter);
            }

            result = await serverApi.getNavigationAnalyticsData(filter, NAV_ANALYTICS_QUERY_KEYS.ZONE_FLOWS, environmentConstants);

            if (!result?.success)
            {
                throw new Error("Visitor flow response was not ok");
            }

            return processZoneFlows(result.data, selectedZoneGeofenceIds);
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });
    const extractedNodesToExport = useMemo(() =>
    {
        if (!Object.keys(selectedZoneGeofenceHash).length === 0) 
        {
            return [];
        }
        const nodes = zoneFlowsQuery?.data?.flatMap(({ nodes, links, visits }) =>
            links.map(({ source, target, value }) =>
            {
                const sourceNode = nodes.find(node => node.id === source);
                const targetNode = nodes.find(node => node.id === target);

                return {
                    Path: `${sourceNode ? sourceNode.name : source} -> ${targetNode ? targetNode.name : target} `,
                    PathVisits: value
                };
            }));
        return nodes;
    }, [zoneFlowsQuery?.data, selectedZoneGeofenceHash]);

    useEffect(() =>
    {
        const frequentlyUsedPaths = {
            name: "Frequently Used Path",
            data: extractedNodesToExport,
            description: "",
            injector: injectProcessedData
        };
        if (injectProcessedData)
        {
            injectProcessedData(frequentlyUsedPaths);
        }
    }, [extractedNodesToExport]);

    const customLinkTooltip = React.useCallback(({ link }) =>
    {
        return (
            <div className="tooltipItemRow" key={`${link.source.id} - ${link.target.id}`}>
                <span style={{ background: link.source.color }}></span>
                {` ${link.source.displayName} -> ${link.target.displayName} `}
                <span style={{ background: link.target.color }}></span>
                &nbsp;
                <strong>{link.value}</strong>{` ${link.value === 1 ? trans("VisitorFlowWidget.visit") : trans("VisitorFlowWidget.visits")}`}
            </div>
        );
    }, [trans]);

    const customNodeTooltip = React.useCallback((e) =>
    {
        return (
            <div className="tooltipItemRow" key={`${e.id}`}>
                <span style={{ background: e.color }}></span>
                {` ${e.displayName}`}
            </div>
        );
    }, []);

    const boxClassName = React.useMemo(() =>
    {
        if (zoneFlowsQuery.isError || (!!zoneFlowsQuery.isSuccess && !zoneFlowsQuery.data.length > 0))
        {
            return "hasMessage";
        }
        else
        {
            return "";
        }

    }, [zoneFlowsQuery]);

    // RENDER FUNCTIONS

    // render sanky chart
    const renderSankyChart = React.useCallback(({ data, height, key, zoneGeofenceColorMap }) =>
    {
        let colors;

        if (zoneGeofenceColorMap)
        {
            colors = (sanky) => zoneGeofenceColorMap[sanky.name];
        }

        return (
            <div style={{ height: height }} key={`sankydata-${key}`}>
                {
                    <SankyChart
                        data={data}
                        customLinkTooltip={customLinkTooltip}
                        customNodeTooltip={customNodeTooltip}
                        options={{
                            colors
                        }} />
                }
            </div>
        );
    }, [customLinkTooltip, customNodeTooltip]);

    const renderVisitorFlowWidget = React.useCallback(() =>
    {
        if (zoneFlowsQuery.isSuccess)
        {
            if (zoneFlowsQuery.data.length > 0)
            {
                let sankyDataList = [...zoneFlowsQuery.data].splice(0, 3);

                let totalDisplayedVisitCount = 0;
                sankyDataList.forEach(sanky => totalDisplayedVisitCount += sanky.visits);

                return (
                    <div style={{ height: 800 }}>
                        {
                            sankyDataList.map((sankyData, i) =>
                            {
                                // dynamicly set height of each sanky graph
                                const percentageAllowed = sankyData.visits / totalDisplayedVisitCount;
                                const height = Math.floor(SANKY_TOTAL_HEIGHT * percentageAllowed);
                                return renderSankyChart({ data: sankyData, height, key: i });
                            })
                        }
                    </div>
                );
            }
            else
            {
                return <div className="errorWidgetWrap"><NoDataWidgetMessage /></div>;
            }
        }
        else if (zoneFlowsQuery.isError)
        {
            return <div className="errorWidgetWrap"><ErrorWidgetMessage onReloadClick={() => refetchQuery(zoneFlowsQuery)} /></div>;

        }
        else
        {
            return WidgetLoadingBoxLarge();
        }
    }, [zoneFlowsQuery, renderSankyChart]);

    const renderVisitorFlowControlled = React.useCallback(() =>
    {
        const { isSuccess, isError, data } = zoneFlowsQuery;

        if (isSuccess && data && zoneGeofenceColorMap)
        {
            // if data but no selected show "select zone geofence"
            if (!selectedZoneGeofenceHash || Object.keys(selectedZoneGeofenceHash).length === 0)
            {
                return (
                    <WidgetMessage customImageSrc="/img/userflow-nodata.svg" customMessage={trans("VisitorFlowWidget.Please_select_a_zone_geofence")} />
                );
            }
            else
            {
                if (data.length > 0)
                {
                    let sankyDataList = [...data];

                    return (
                        <React.Fragment >
                            {/* <strong> {`Showing Flows That Include "${label}"`} </strong> */}
                            {
                                sankyDataList.map((sankyData, i) =>
                                {
                                    const height = Math.min(500, sankyData.visits * 100);
                                    return renderSankyChart({ data: sankyData, height, key: i, zoneGeofenceColorMap });
                                })
                            }
                        </React.Fragment>
                    );
                }
                else
                {
                    return (
                        <div className="errorWidgetWrap"><WidgetMessage customMessage={trans("VisitorFlowWidget.Hmm____looks_like_no_paths_were_found_fo")} /></div>
                    );
                }
            }
        }
        else if (isError)
        {
            return <div className="errorWidgetWrap"><ErrorWidgetMessage /></div>;
        }
        else
        {
            return <WidgetLoadingBoxFull>{trans("Placeholders.Our_software_has_a_lot_of_great_data_to_")}<br /> {trans("Placeholders.Thank_you_for_your_patience_while_we_gat")} </WidgetLoadingBoxFull>;
        }
    }, [trans, zoneFlowsQuery, selectedZoneGeofenceHash, zoneGeofenceColorMap, renderSankyChart]);

    const renderSelectedZoneGeofenceSubHeader = useCallback((selectedZoneGeofenceLabels) =>
    {
        const handleRemoveSelectedZoneGeofence = (zoneGeofenceLabel) =>
        {
            const selectedZoneGeofence = Object.values(selectedZoneGeofenceHash).find((zoneGeofenceData) => zoneGeofenceData.label === zoneGeofenceLabel);

            (selectedZoneGeofence && onRemoveSelectedZoneGeofence) && onRemoveSelectedZoneGeofence(selectedZoneGeofence);
        };

        return selectedZoneGeofenceLabels.map((zoneGeofenceLabel) =>
            <div className="tagLabel">
                {zoneGeofenceLabel}
                <ButtonIcon icon="cross-red" onClick={() => handleRemoveSelectedZoneGeofence(zoneGeofenceLabel)} />
            </div>
        );

    }, [selectedZoneGeofenceHash, onRemoveSelectedZoneGeofence]);

    const renderVisitorFlow = useCallback(() =>
    {
        if (controlledComponent)
        {
            let selectedZoneGeofenceLabels = Object.values(selectedZoneGeofenceHash).map((zoneGeofence) => zoneGeofence.label);

            return (
                <DefaultBox
                    independent
                    className="PathAnalyticsChartBoxContainer"
                    heading={trans("VisitorFlowWidget.Frequently_Used_Paths")}
                    subHead={renderSelectedZoneGeofenceSubHeader(selectedZoneGeofenceLabels)}
                    toolTip={trans("VisitorFlowWidget.Displays_user_flows_containing_the_selec")}
                >
                    <div className="paraLabel">
                        {renderVisitorFlowControlled()}
                    </div>
                </DefaultBox>
            );
        }
        else
        {
            return (
                <DefaultBox className={boxClassName} independent heading={trans("VisitorFlowWidget.See_how_your_visitors_interact_with_your")}>
                    {renderVisitorFlowWidget()}
                </DefaultBox>
            );
        }
    }, [controlledComponent, renderVisitorFlowControlled, selectedZoneGeofenceHash, renderVisitorFlowWidget, renderSelectedZoneGeofenceSubHeader, boxClassName, trans]);



    return (
        <React.Fragment>
            {renderVisitorFlow()}
        </React.Fragment>
    );
};
