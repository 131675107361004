export const TOKEN_REFRESHMENT_TIME_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours

export const DATE_FORMAT = "MMM D, YYYY";

export const DATE_TYPES = {
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    LAST_WEEK: "Last 7 Days",
    LAST_30_DAYS: "Last 30 Days",
    LAST_60_DAYS: "Last 60 Days",
    LAST_YEAR: "Last Year",
    CUSTOM: "Custom Range",
    ONEHOUR: "Last 1 Hour",
    TWOHOURS: "Last 2 Hours",
    THREEHOURS: "Last 3 Hours",
    THIS_MONTH: "This Month",
};

export const DEFUALT_DATE_TYPES = [
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_30_DAYS,
    DATE_TYPES.LAST_YEAR,
    DATE_TYPES.CUSTOM,
];

export const INSIDES_DATE_TYPES = [
    DATE_TYPES.TODAY,
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_30_DAYS,
    DATE_TYPES.CUSTOM,
];

export const DATE_TYPES_WITH_TODAY = [
    DATE_TYPES.TODAY,
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_30_DAYS,
    DATE_TYPES.LAST_YEAR,
    DATE_TYPES.CUSTOM,
];

export const DATE_TYPES_FOR_LAST_THREE_HOURS = [
    DATE_TYPES.ONEHOUR,
    DATE_TYPES.TWOHOURS,
    DATE_TYPES.THREEHOURS,
    DATE_TYPES.CUSTOM
];

export const DATE_TYPES_FOR_LAST_ONE_WEEK = [
    DATE_TYPES.TODAY,
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.CUSTOM
];

export const DATE_TYPES_FOR_LAST_60_DAYS_WITHOUT_TODAY = [
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_60_DAYS,
    DATE_TYPES.CUSTOM
];

export const DATE_TYPES_FOR_LAST_60_DAYS = [
    DATE_TYPES.TODAY,
    DATE_TYPES.YESTERDAY,
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_60_DAYS,
    DATE_TYPES.CUSTOM
];
export const DATE_TYPES_NO_YESTERDAY = [
    DATE_TYPES.LAST_WEEK,
    DATE_TYPES.LAST_30_DAYS,
    DATE_TYPES.LAST_YEAR,
    DATE_TYPES.CUSTOM,
];


export const DATA_AGGREGATIONS = {
    EVERY_TWO_MINUTES: "EveryTwoMinutes",
    EVERY_HOUR: "EveryHour",
    EVERY_DAY: "EveryDay",
};

export const DEFAULT_DATE_TYPE = DATE_TYPES.LAST_30_DAYS;

export const DASHBOARD_DATA = {
    PROPERTY_ID: "propertyId",
    BUILDING_ID: "buildingId",
    FLOOR_ID: "floorId",
};

export const DATE_GROUP_FORMAT = {
    HOURS: "h aa",
    DAYS: "MMM d",
    MONTHS: "MMM yyyy",
    WEEKS: "I yyy", //format = week number of the year
};

export const DATE_GROUP_RANGE = {
    HOURS: { hours: 1 },
    WEEKS: { weeks: 1 },
    DAYS: { days: 1 },
    MONTHS: { months: 1 },
};


// alert/LIVE child routes
export const LIVE_ROUTES = {
    CALENDAR: "",
    MAP: "/map",
    HEATMAP: "/heatmap",
};

//Engagement Routes
export const ENGAGEMENT_ROUTES = {
    MOBILE: "mobile",
    WEB: "web"
};

//reports child routes
export const REPORTS_ROUTES = {
    ENGAGEMENT: "",
    ENGAGEMENT_WEB: "engagement-web",
    USER_EXPLORER: "user-explorer",
    USER_FLOW: "user-flow",
    TRACE: "trace",
    TRACE_CHART: "trace-chart",
    BOOST_ANALYTICS: "boost-analytics",
    BOOST_TAG_ANALYTICS: "tag-analytics"
};

export const IOT_ROUTES = {
    HOME: "/iot",
    FLOW: "flow",
    TAGS: "tags",
    BADGE: "badge"
};
export const USER_FLOW_ROUTES = {
    HEATMAP: "heatmap",
    PATH: "path",
    OCCUPANCY: "occupancy",
    WEATHER: "weather",
    VISITOR_COUNT: "visitor-count",
    HOURLY_VISITS: "hourly-visits",
    TRACE: "trace",
    TRACE_CHART: "trace-chart",
    USER_EXPLORER: "user-explorer"
};

export const BOOST_TAG_ROUTES = {
    TAG_INFO: "/iot/tags",
    TAG_TRAJECTORIES: "tag-trajectories",
    TRAFFIC_FLOW: "traffic-flow",
    TAGS_HEATMAP: "tags-heatmap",
    ZONE_GEOFENCE_VISITS: "zoneGeofence-visits",
    QUICK_SNAPSHOT: "quick-snapshot",
};

export const BOOST_ROUTES = {
    INFO_BOOST: "/iot/flow",
    HEAT_MAP_BOOST: "heat-map",
    ZONE_INFO_BOOST: "zone-info",
    NETWORK_BOOST: "network",
    TRAFFIC_FLOW: "traffic-flow",
    QUICK_SNAPSHOT: "quick-snapshot",
    COMFORT: "comfort"

};

export const ALERTS_TITLES = {
    ["/live" + LIVE_ROUTES.CALENDAR]: "Table_View",
    ["/live" + LIVE_ROUTES.MAP]: "Map_View",
    ["/live" + LIVE_ROUTES.HEATMAP]: "Heatmap_View",
};

export const ALERTS_EVENT_TYPES = {
    CHECK_IN: "Check-in",
    OFF_SITE: "Off-site",
    CHECK_OUT: "Check-out",
    EVENT: "event",
};

export const USER_EXPLORER_DISPLAYED_VALUES = {
    USER_UID: "userUID",
    NUM_OF_SESSIONS: "numberOfSessions",
    AVG_TIME_FORMATTED: "avgSessionTimeFormatted",
    TOTAL_TIME_FORMATTED: "totalTimeSpentFormatted",
    AVG_TIME_MINS: "avgSessionTimeInMinutes",
    TOTAL_TIME_MINS: "totalTimeSpentInMinutes",
};

export const USER_EXPLORER_ITEMS_PER_PAGE_OPTIONS = [
    { key: 10, text: "10", value: 10 },
    { key: 20, text: "20", value: 20 },
    { key: 50, text: "50", value: 50 },
];

export const DEVICE_TYPE = {
    TYPE_ANDROID: "Android",
    TYPE_IOS: "IOS",
};
export const DEFUALT_LANGUAGE_CODE = "en";
export const FALLBACK_LANGUAGE_CODE = "en";

export const MAP_NO_DATA_IMG_SRC = "/img/map-nodata-boxes.svg";

export const INTERACTION_TYPES = {
    DWELL: 0,
    PASS_BY: 1,
};


export const DECIMAL_PRECESSION = 1;

export const ZONE_POI_CHART_THRESHOLD = .5;

export const BASE_ROUTES = {
    HOME: "/*",
    REPORTS: "reports",
    ALERTS: "alerts",
    INSIDES: "insights",
    ENGAGEMENT: "engagement",
    FLOWS: "flows",
    BOOST: "boost",
    TAG: "tags",
    LIVE: "live",
    EXTRA: "extra",
    IOT: "iot"

};

export const HideBoostOrTagsSubHeaderNavigation = Object.values(REPORTS_ROUTES);