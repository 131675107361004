import React from "react";
import { PlaceholderBox, PlaceholderPara } from "../../common/placeholders/Placeholders";

function ZoneLoadingView()
{
    return (
        <>
            <PlaceholderPara margin="10px 0" height="16px" />
            <PlaceholderBox height="414px" />
        </>
    );
}

export default ZoneLoadingView;
