import { saveAs } from "file-saver";
import jsonexport from "jsonexport";
import { deepCopy } from "mapsted.utils/objects";
import { EXPORT_TO_CSV_DATA_TYPES } from "../_constants/exportToCsvConstants";
import { DATE_GROUP_FORMAT } from "../_constants/constants";
import { i18Format } from "./date.utils";
const JSZip = require("jszip");

/**
 * Returns the default state for active page data export.
 * @returns {Object} - The default state.
 */
export const getDefaultStateOfActivePageDataExport = () => ({
    [EXPORT_TO_CSV_DATA_TYPES.PROCESSED_DATA]: {},
    [EXPORT_TO_CSV_DATA_TYPES.RAW_DATA]: {},
});

/**
 * Checks if any widget data has been selected by the user for export.
 * @param {Object} activePageDataHash - The data received from connected pages.
 * @returns {boolean} - True if any widget data has been selected, false otherwise.
 */
export const hasUserSelectedAtLeastOneItemToExport = (activePageDataHash) =>
{
    if (!activePageDataHash && typeof activePageDataHash !== "object") return false;
    // For each data injector
    return Object.values(activePageDataHash).some(
        // Check if any injector data has been selected
        (dataFromDataInjectors) => Object.values(dataFromDataInjectors).some(
            // Check if the data has been selected
            (injectorData) => injectorData.isSelected
        )
    );
};

/**
 * Adds data to the state from the injector.
 * @param {"rawData"|"processedData"} dataTypeName - The data type.
 * @param {Object} prevState - The current state.
 * @param {Object} data - The data to be added.
 * @param {string} data.name - The name of the widget.
 * @param {any} data.data - The data to be added.
 * @param {any} data.meta - Meta data.
 * @param {string} [data.description] - Short info about the data.
 * @returns {Object} - The added data.
 */
export const addDataFromAInjectorToState = (dataTypeName, prevState, { name: dataSourceName, data, description, meta }) =>
{
    const newState = deepCopy(prevState);
    Object.assign(newState[dataTypeName], { ...newState[dataTypeName], [dataSourceName]: { id: dataSourceName, name: dataSourceName, data, description, meta, isSelected: false } });
    return newState;
};

/**
 * Retrieves all selected user items from the active page data grouped by data types.
 * @param {Object} allPageItemsGroupedByDataTypes - The page data grouped by data types.
 * @returns {Object} - The active items in the data groups.
 */
export const getAllUserSelectedItemsHashGroupedByDataTypeFromActivePage = (allPageItemsGroupedByDataTypes) =>
{
    const allDataTypesEntries = Object.entries(allPageItemsGroupedByDataTypes);
    const allActiveItemsInDataGroups = {};

    allDataTypesEntries.forEach(([dataType, dataItemsHash]) =>
    {
        allActiveItemsInDataGroups[dataType] = {};
        const currentDataTypeItemsHash = allActiveItemsInDataGroups[dataType];

        Object.entries(dataItemsHash).forEach(([dataInjectorName, dataItem]) =>
        {
            if (dataItem.isSelected)
            {
                currentDataTypeItemsHash[dataInjectorName] = dataItem;
            }
        });
    });

    return allActiveItemsInDataGroups;
};

/**
 * Generates a zip file containing all selected user items from the active page data.
 * Each item is saved as a separate CSV file.
 *
 * @param {Object} args - The arguments for generating the zip file.
 * @param {Object} args.allUserSelectedDataItemsHashGroupedByDataTypes - The selected items grouped by data types.
 * @param {string} [args.fileTypeToExportData="csv"] - The file type to export the data. Default is "csv".
 * @return {Promise<Blob>} - A promise that resolves to the generated zip file.
//  */


// const convertToCSV = (data) =>
// {
//     if (!data.length) return '';
//     // const headerSet = new Set() 
//     const headers = Object.keys(data[0]).reduce((acc, key) =>
//     {
//         if (Array.isArray(data[0][key]))
//         {
//             const nestedHeaders = Object.keys(data[0][key][0]).map(subKey => `${key}_${subKey}`);
//             console.log(nestedHeaders);
//             // headerSet.set(`${key}_${subKey}`)
//             return [...acc, ...nestedHeaders];
//         }
//         return [...acc, key];
//     }, []);

//     const rows = data.map(record =>
//     {
//         return headers.map(header =>
//         {
//             const [key, subKey] = header.split('_');
//             if (subKey)
//             {

//                 return record[key].map(item => item[subKey]).join(';');
//             }
//             return record[key] || '';
//         }).join(',');
//     });

//     return [headers.join(','), ...rows].join('\n');
// };

export const preparedZippedContentFromAllActiveItems = async ({
    allUserSelectedDataItemsHashGroupedByDataTypes,
    fileTypeToExportData = "csv",
}) =>
{
    const zip = new JSZip();
    const promises = [];

    // Iterate over each data group
    Object.entries(allUserSelectedDataItemsHashGroupedByDataTypes)
        .filter(([dataGroupName, dataItemsHash]) => Object.keys(dataItemsHash).length > 0)
        .forEach(([dataGroupName, dataItemHash]) =>
        {
            const groupFolder = zip.folder(dataGroupName);

            // Iterate over each item in the data group
            Object.entries(dataItemHash).forEach(([injectorName, dataItem]) =>
            {
                if (dataItem.data)
                {
                    const promise = jsonexport(dataItem.data, { tab: injectorName }).then((csvData) =>
                    {
                        // prefixing the CSV data with \uFEFF, which is the UTF-8 Byte Order Mark (BOM)
                        // supports characters from various languages, including Arabic
                        const csvBlob = new Blob([`\uFEFF${csvData}`], { type: "text/csv;charset=utf-8" });

                        groupFolder.file(`${injectorName}.${fileTypeToExportData}`, csvBlob, { binary: true });
                    });
                    promises.push(promise);

                    // const csvData = convertToCSV(dataItem.data);

                    // Create a file in the group folder with the CSV data
                    // groupFolder.file(`${injectorName}.${fileTypeToExportData}`, csvData, { binary: true });
                }
            });
        });

    await Promise.all(promises);
    try
    {
        const content = await zip.generateAsync({ type: "blob" });
        return content;
    } catch (err)
    {
        console.error("Error generating zip file:", err);
        throw err;
    }
};

/**
 * Saves the provided content as a downloadable file.
 *
 * @param {Blob} content - The content to be saved as a file.
 * @param {string} fileName - The name of the file.
 */
export const onDownloadAfterDataPreparing = (content, fileName) =>
{
    saveAs(content, `${fileName}.zip`);
};

/**
 * Updates the selection of a data item.
 * @param {Object} state - The state.
 * @param {string} activeTabName - The active tab name.
 * @param {Object} dataItem - The data item.
 * @param {string} dataItem.name - The name of the data item.
 * @param {boolean} dataItem.isSelected - The selection status of the data item.
 * @returns {Object} - The updated state.
 */
export const updateSelectionOfDataItem = (state, activeTabName, name, isSelected) =>
{
    const clonedState = deepCopy(state);
    clonedState[activeTabName][name].isSelected = isSelected;
    return clonedState;
};

/**
 * Returns an object containing data grouped by data type,
 * where data has actual values.
 *
 * @param {Object} allDataGroupedByDataTypes - Data grouped by data type.
 * @returns {Object} - Data grouped by data type, where data has actual values.
 */
export const getDataGroupedByDataTypeWhichHasData = (allDataGroupedByDataTypes) =>
{
    const result = {};

    for (const dataType in allDataGroupedByDataTypes)
    {
        const dataItemsHash = allDataGroupedByDataTypes[dataType];

        if (dataItemsHash && Object.keys(dataItemsHash).length > 0)
        {
            result[dataType] = {};

            for (const dataInjectorName in dataItemsHash)
            {
                const dataItem = deepCopy(dataItemsHash[dataInjectorName]);
                dataItem.isWidgetDataEmpty = true;

                if (
                    (Array.isArray(dataItem.data) && dataItem.data.length > 0) || // Check if it's a non-empty array
                    (typeof dataItem.data === 'object' && dataItem?.data && Object.keys(dataItem.data).length > 0) // Check if it's a non-empty object
                )
                {
                    dataItem.isWidgetDataEmpty = false;
                    result[dataType][dataInjectorName] = dataItem;
                }

                // if (dataItem.data && Object.keys(dataItem.data).length > 0)
                // {
                // }
            }
        }
    }

    return result;
};

/**
 * Formats data/time values for CSV.
 * 
 * @param {Array<number>|string} content - Numeric values representing parts of the data.
 * @param {Array<string>|string} extra - Strings for hour, minute, and second.
 * @returns {string} - Formatted date string as displayed on the UI.
 */
export const formatTimeStringsForCSV = (content, extra) =>
{
    if (Array.isArray(content))
    {
        return content.map((c, i) =>
        {
            return `${c || 0} ${extra[i] && extra[i]} `;
        }).join("");
    }
    else
    {
        return `${content || 0} ${extra && extra} `;
    }
};


/**
 * Formats given date based on the specified date group format only for DAYS.
 * adds year to the date.
 *
 * If the `dateGroupFormat` is set to `DATE_GROUP_FORMAT.DAYS`, the function adjusts the 
 * date's year to the current year and ensures that the date is not in the future. 
 * If the date falls in the future after adjustment (which can happen for dates like 
 * those in January or February), it subtracts a year. The date is then formatted 
 * to the "d MMM yyyy" format (e.g., "5 Jan 2024" for "Jan 5").
 *
 * @param {Date | string} theDate - The date to be formatted. Can be a Date object or a date string.
 * @param {string} dateGroupFormat - The format type for grouping the date. 
 *                                    If it equals `DATE_GROUP_FORMAT.DAYS`, the date will be adjusted and formatted.
 * @returns {string} - The formatted date string.
 */
export const formatDateValuesFromChartToCSV = (theDate, dateGroupFormat) =>
{
    let formattedDate = theDate;
    if (dateGroupFormat === DATE_GROUP_FORMAT.DAYS)
    {
        let tempDate = new Date(formattedDate);
        let currentDate = new Date();

        // Adjust the date to current year.
        tempDate.setFullYear(currentDate.getFullYear());


        // If tempDate is in the future, subtract 1 year
        // DATE_GROUP_FORMAT.DAYS is valid for 60 days
        // below code handles edge cases for dates in January, February, and December. (when daterange selected is betwn 2 diff years)
        if (tempDate > currentDate)
        {
            tempDate.setFullYear(tempDate.getFullYear() - 1);
        }

        formattedDate = i18Format(tempDate, "d MMM yyyy");
    }
    return formattedDate;
};
/**
 * 
 * @param {*} obj - contains 'content' and 'extra' property which has duration data
 * @returns {Number} -Duration in mins
*/
export const convertNumberWidgetsDurationContentToMins = (obj) =>
{
    if (!obj)
    {
        return "N/A";
    }
    // key value pair for tracking minutes for perticular time content
    // handling both singular and plural case for all time instances
    const timeUnits = {
        day: 1440,
        days: 1440,
        hr: 60,
        hrs: 60,
        min: 1,
        mins: 1,
        sec: 1 / 60,
        secs: 1 / 60
    };

    let totalMinutes = 0;

    // Iterate over the content and extra arrays
    for (let i = 0; i < obj.content.length; i++)
    {
        const value = obj.content[i];
        const unit = obj.extra[i];

        // Add to totalMinutes based on the corresponding unit
        if (timeUnits[unit])
        {
            totalMinutes += value * timeUnits[unit];
        }
    }

    return totalMinutes;
};
