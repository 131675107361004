import { addDays, addMilliseconds, addMinutes, differenceInCalendarDays, differenceInMinutes, endOfWeek, getHours, subWeeks } from "date-fns";
import { subMinutes } from "date-fns/esm";
import { deepCopy, deepValue } from "mapsted.utils/objects";
import { ALERTS_EVENT_TYPES, LIVE_ROUTES } from "../_constants/constants";
import { formatLiveTimeCalendar, formatTime, mostRecentAvailableDate, unixMilliSecondsToTimeZoneDate } from "./date.utils";
import { convertLocalTimeZoneJSDateToZonedDateTime, formatDateTimeToReadAbleTimeFormatWithTimeZone } from "./date.luxon.utils";


/**
 * Creates a mapping from userId to calender io events
 * r
 * @param {Object} liveCalendarData
 * @param {Object} timeZone
 * @param {Object} propertyInfoMap
 * @returns
 */
export const processAlertsCalendarView = (liveCalendarData, timeZone, propertyInfoMap, trans) =>
{
    const getDateFromTimeInternal = (time) => unixMilliSecondsToTimeZoneDate(time.unixTime_ms, timeZone);

    let userEventsMap = {};
    let users = [];
    const OFFSITE_DURATION_THRESHOLD = 30;
    const { start: alertsStart } = getAlertsDateRange(true, timeZone.id);

    if (liveCalendarData)
    {
        const zoneGeofenceMap = liveCalendarData?.propertyZoneGeofences?.zoneGeofenceMap;
        let userPositionCalendars = liveCalendarData?.userPositionCalendars || {};

        Object.values(userPositionCalendars).forEach((userCalendarArray) =>
        {
            let userEvents = [];
            let userUID = userCalendarArray[0].userUID;
            let userAvgEventStartTime = 0;
            let eventCount = 0;

            let previousDate = undefined;
            let deepCopyUserCalendarArray = deepCopy(userCalendarArray);
            userCalendarArray = deepCopyUserCalendarArray.sort((a, b) => a.startTime.unixTime_ms - b.startTime.unixTime_ms);

            // filter out any events recieved before the start date
            userCalendarArray = userCalendarArray.filter((event) =>
            {
                let eventDate = getDateFromTimeInternal(event.startTime);

                return differenceInCalendarDays(eventDate, alertsStart) >= 0;
            });



            // loop through user events
            userCalendarArray.forEach((userEventData) =>
            {
                const { startTime, endTime, zoneGeofenceId, propertyId, buildingId, floorId, } = userEventData;

                const zoneGeofenceName = deepValue(zoneGeofenceMap, `${zoneGeofenceId}.label`, "N/A");
                const durationMins = differenceInMinutes(getDateFromTimeInternal(endTime), getDateFromTimeInternal(startTime));
                const start = formatLiveTimeCalendar(startTime.unixTime_ms, timeZone?.id || "UTC");
                let end = formatLiveTimeCalendar(endTime.unixTime_ms, timeZone?.id || "UTC");

                // used for avg event time
                userAvgEventStartTime += getHours(start);
                eventCount++;

                end = addMilliseconds(end, 1);

                let formattedTime = `${formatTime(start)} - ${formatTime(end)} ${timeZone?.id || "UTC"}`;

                // processing lv 2 (check for time away between daily visits, first visit of the day, last visit of the day)
                if (!previousDate)
                {
                    userEvents.push(createCheckinEvent(start, timeZone));
                }
                else
                {
                    // diffrent day
                    if (differenceInCalendarDays(start, previousDate) >= 1)
                    {
                        // create checkout event (previousDate)
                        userEvents.push(createCheckoutEvent(previousDate, timeZone));

                        // create checkin event (start)
                        userEvents.push(createCheckinEvent(start, timeZone));
                    }
                    // same day
                    else if (differenceInMinutes(start, previousDate) >= OFFSITE_DURATION_THRESHOLD)
                    {
                        // create off site event
                        userEvents.push(createOffSiteEvent(previousDate, start, timeZone, trans));
                    }
                }

                let event = {
                    zoneGeofence: "",
                    title: zoneGeofenceName,
                    start: start,
                    end: end,
                    duration: durationMins,
                    durationText: `${durationMins || "< 1"} ${trans("AlertsCalendarView.mins")}`,
                    formattedTime: formattedTime,
                    eventType: ALERTS_EVENT_TYPES.EVENT
                };


                if (propertyInfoMap)
                {
                    let property = propertyInfoMap[propertyId];
                    let building = property?.buildings[buildingId];
                    let floor = building?.levels.find((f) => f.floorId === floorId);

                    let propertyName = property?.longName;
                    let buildingName = building?.longName;
                    let floorName = floor?.longName;

                    Object.assign(event, { propertyName, buildingName, floorName });
                }

                previousDate = end;
                userEvents.push(event);
            });

            // for the last event, create checkout event for last event (previousDate) if the last event was not today
            if (differenceInCalendarDays(new Date(), previousDate,) > 0)
            {
                userEvents.push(createCheckoutEvent(previousDate, timeZone));
            }

            // processing lv 3 smoothing (removed)
            // smoothUserCalendarEvents(userEvents, timeZone)


            if (userAvgEventStartTime)
            {
                userAvgEventStartTime = Math.floor(userAvgEventStartTime / eventCount);

                if (userAvgEventStartTime >= 3)
                {
                    userAvgEventStartTime -= 3;
                }

            }
            userEventsMap[userUID] = { events: userEvents, scrollHour: userAvgEventStartTime };

            // create user for user list
            let user = { ...userCalendarArray[0] };
            user.endTime = userCalendarArray[userCalendarArray.length - 1].endTime;
            user.updatedUnixTime_s = userEvents.reduce((acc, { durationMins, start = new Date(0), end = new Date(0) }) =>
            {
                return new Date(Math.max(acc, start, end));
            }, new Date(userEvents[0].start)).getTime() / 1000;
            user.view = LIVE_ROUTES.CALENDAR;
            users.push(user);
        });
    }

    // process users

    const userDeviceInfos = liveCalendarData?.userDeviceInfos || [];
    mapUserDeviceInfosToUserList(users, userDeviceInfos);

    let usersObject = {
        users,
        nameKey: "userUID"
    };

    return { userEventsMap, usersObject };
};

export const getAlertsDateRange = (isTodayDateAllowed = true, timeZoneid) =>
{
    let start;
    let end;

    end = endOfWeek(mostRecentAvailableDate(timeZoneid, isTodayDateAllowed));
    start = addDays(subWeeks(end, 2), 1);

    return { start, end };
};

/**
 * Attaches additional user device info to the user list
 * @param {*} users
 * @param {*} userDeviceInfos
 */
export const mapUserDeviceInfosToUserList = (users = [], userDeviceInfos = []) =>
{
    users.forEach((user, i) =>
    {
        const userUID = user.userUID;
        const useInfos = userDeviceInfos.filter((userDeviceInfo) => userDeviceInfo.userUID === userUID);
        const userInfo = useInfos[0];

        if (userInfo)
        {

            let model = userInfo.marketName || `${userInfo.manufacturer} - ${userInfo.model}`;
            users[i].phoneSystem = userInfo.deviceType;
            users[i].phoneModel = model;
        }
    });
};

// /**
//  *
//  * @param {Array} userCalendarEvents
//  */
// const smoothUserCalendarEvents = (userCalendarEvents, timeZone) =>
// {
//     const DURATION_THRESHOLD = 10;
//     let smoothingObject = undefined;
//     // duration, eventList, ZoneGeofenceMap, eventIdxList

//     userCalendarEvents.forEach((calendarEvent, i) =>
//     {
//         let lastSmoothingEvent = smoothingObject?.eventList?.[smoothingObject?.eventList?.length - 1];

//         if (!!smoothingObject && calendarEvent.eventType === ALERTS_EVENT_TYPES.EVENT && differenceInMinutes(calendarEvent.start, lastSmoothingEvent.start) < DURATION_THRESHOLD)
//         {

//             smoothingObject.duration += calendarEvent.duration;
//             smoothingObject.eventList.push(calendarEvent);
//             smoothingObject.eventIdxList.push(i);

//             if (!!smoothingObject.zoneGeofenceMap[calendarEvent.title])
//             {
//                 smoothingObject.zoneGeofenceMap[calendarEvent.title].duration += calendarEvent.duration;
//             }
//             else
//             {
//                 smoothingObject.zoneGeofenceMap[calendarEvent.title] = { duration: calendarEvent.duration, title: calendarEvent.title };
//             }

//             if (smoothingObject.duration >= DURATION_THRESHOLD)
//             {
//                 createEventFromSmoothingObject(smoothingObject, userCalendarEvents, timeZone);
//                 smoothingObject = undefined;
//             }
//         }
//         else
//         {
//             // clear smoothing object if it exists
//             createEventFromSmoothingObject(smoothingObject, userCalendarEvents, timeZone);
//             smoothingObject = undefined;

//             // add calendar event
//             if (calendarEvent.eventType === ALERTS_EVENT_TYPES.EVENT)
//             {
//                 if (calendarEvent.duration < DURATION_THRESHOLD)
//                 {
//                     smoothingObject = {
//                         duration: calendarEvent.duration,
//                         eventList: [calendarEvent],
//                         eventIdxList: [i],
//                         zoneGeofenceMap: { [calendarEvent.title]: { duration: calendarEvent.duration, title: calendarEvent.title } }
//                     }
//                 }
//             }
//         }
//     })

//     // clear smoothing object if it exists
//     createEventFromSmoothingObject(smoothingObject, userCalendarEvents, timeZone);
// }

// const createEventFromSmoothingObject = (smoothingObject, userEventList, timeZone, trans) =>
// {
//     // if no smoothing object or event list to smooth is 1 or less, return and do nothing.
//     if (!smoothingObject || smoothingObject.eventList.length <= 1)
//     {
//         return;
//     }

//     const { eventList, eventIdxList, zoneGeofenceMap } = smoothingObject

//     let sortedZoneDurations = Object.values(zoneGeofenceMap).sort((a, b) => b.duration - a.duration);

//     // create smoothed event
//     let start = eventList[0].start;
//     let end = eventList[eventList.length - 1].end;

//     let durationMins = differenceInMinutes(end, start);
//     let formattedTime = `${formatTime(start)} - ${formatTime(end)} ${timeZone?.id || "UTC"}`;

//     let smoothedEvent = {
//         zoneGeofence: "",
//         title: sortedZoneDurations[0].title,
//         duration: durationMins,
//         durationText: `${durationMins} ${trans("AlertsCalendarView.mins")}`,
//         start: start,
//         end: end,
//         formattedTime: formattedTime,
//         eventType: ALERTS_EVENT_TYPES.EVENT,
//     }

//     return userEventList.splice(eventIdxList[0], eventIdxList.length, smoothedEvent);
// }

const createCheckinEvent = (date, timeZone) =>
{
    // create checkin event (start)
    const checkinEvent = {
        eventType: ALERTS_EVENT_TYPES.CHECK_IN,
        zoneGeofence: "",
        title: ALERTS_EVENT_TYPES.CHECK_IN,
        start: subMinutes(date, 10),
        end: date,
        formattedTime: `${formatTime(date)} ${timeZone?.id || "UTC"}`,
        eventOriginalTimeStamp: date,
    };

    return checkinEvent;
};

const createCheckoutEvent = (date, timeZone) =>
{
    const checkoutEvent = {
        eventType: ALERTS_EVENT_TYPES.CHECK_OUT,
        zoneGeofence: "",
        title: ALERTS_EVENT_TYPES.CHECK_OUT,
        start: addMinutes(date, 10),
        end: addMinutes(date, 11),
        formattedTime: `${formatTime(date)} ${timeZone?.id || "UTC"}`,
        eventOriginalTimeStamp: date
    };

    return checkoutEvent;
};

/**
 * Converts start and end times of an event into a readable format with time zone details.
 *
 * @param {Object} param0 - The event details.
 * @param {Date} param0.start - The start time of the event.
 * @param {Date} param0.end - The end time of the event.
 * @param {string} param0.eventType - The type of event.
 * @param {Object} timeZone - The time zone information.
 * @param {string} timeZone.id - The ID of the time zone.
 * @returns {Object} An object containing formatted start and end times.
 */
export const cleanUnWantedCalenderEventDataForCSV = ({ start, end, eventType, eventOriginalTimeStamp }, timeZone) =>
{
    const result = {
        StartTime: formatDateTimeToReadAbleTimeFormatWithTimeZone(convertLocalTimeZoneJSDateToZonedDateTime(start), timeZone.id),
        EndTime: formatDateTimeToReadAbleTimeFormatWithTimeZone(convertLocalTimeZoneJSDateToZonedDateTime(end), timeZone.id)
    };
    if ([ALERTS_EVENT_TYPES.CHECK_IN, ALERTS_EVENT_TYPES.CHECK_OUT].includes(eventType))
    {
        result.StartTime = formatDateTimeToReadAbleTimeFormatWithTimeZone(convertLocalTimeZoneJSDateToZonedDateTime(eventOriginalTimeStamp), timeZone.id);
        result.EndTime = result.StartTime;
        result.DurationFormatted = "";
        result.DurationText = "";
    }

    return result;

};

const createOffSiteEvent = (startDate, endDate, timeZone, trans) =>
{
    const durationMins = differenceInMinutes(endDate, startDate);
    const offsiteEvent = {
        eventType: ALERTS_EVENT_TYPES.OFF_SITE,
        zoneGeofence: "",
        title: ALERTS_EVENT_TYPES.OFF_SITE,
        isOffSite: true,
        start: startDate,
        end: endDate,
        duration: differenceInMinutes(endDate, startDate),
        durationText: `${durationMins} ${trans("AlertsCalendarView.mins")}`,
        formattedTime: `${formatTime(startDate)} - ${formatTime(endDate)} ${timeZone?.id || "UTC"}`,
        formattedTimeEvent: `${formatTime(startDate)} - ${formatTime(endDate)}`
    };

    return offsiteEvent;
};
