import React, { memo, useContext, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { UNIQUE_SESSION_BAR_CHART_OPTIONS } from "../../../_constants/chartConstants";
import { activeTimeZoneSelectorState, analyticsRequestFilterSelector } from "../../../store/DashboardAtoms";
import { BarChart } from "../../charts/BarChart";
import { ConditionalRenderLoadingErrorNoDataChildren } from "../../common/ConditinalLoader";
import Translation from "../../common/translation/Translation";
import { DefaultBox } from "../../dashboard/DefaultBox";
import useDestinationBarChart from "./useMaxDestination";
import { EngagementContext } from "../../pages/engagement/EngagementContext";
import { getDateGroupAndFormat } from "../../../_utils/chart.utils";
import { formatDateValuesFromChartToCSV } from "../../../_utils/exportToCsvUtils";



const CustomTooltip = memo(({ indexValue, value, color, id }) =>
{
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    return (
        <div className="tooltipItemRow tooltipItemRowDevice">
            <span style={{ background: color }} /> {indexValue + " " + timeZone.id}: <strong>{`${value}`}</strong>
            <span>
                {
                    (Number(value) === 1)
                        ? <Translation text={"Widgets.WebEngagementBarChartDestinationWidget.destination"} />
                        : <Translation text={"Widgets.WebEngagementBarChartDestinationWidget.destinations"} />
                } </span>
        </div>
    );
});

const CustomTicks = ({ data }) => (tick) =>
{
    if (data.length > 20 && tick.tickIndex > 0)
    {
        if (tick.tickIndex % 2 === 0 && tick.tickIndex !== 0)
        {
            return (
                <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
                    {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
                    <text
                        transform="rotate(45)"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {tick.value}
                        {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
                    </text>
                </g>
            );
        }
        else return <></>;

    }
    else
    {
        return <g transform={`translate(${tick.x + 8},${tick.y + 20})`}>
            {/* <line x1="0" x2="0" y1="0" y2="5" style="stroke: rgb(119, 119, 119); stroke-width: 1;"></line> */}
            <text
                transform="rotate(45)"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    fontSize: 10,
                }}
            >
                {tick.value}
                {/* {showInPercentage ? (tick.value * 100).toFixed(2) : tick.value} */}
            </text>
        </g>;
    }
};
const Container = ({ injectProcessedData }) =>
{
    const { rawQuery, processedWidgetData } = useDestinationBarChart();
    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);

    const processedExportData = useMemo(() => {
        if (!rawQuery?.data?.currentIntervalData?.length) 
        {
            return [];
        }

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        const modObj = rawQuery.data.currentIntervalData.reduce((acc, { time, value }) => {
            acc[formatDateValuesFromChartToCSV(time, dateGroupFormat)] = value;
            return acc;
        }, { Label: "Destinations" });
    
        return [modObj];
    }, [rawQuery?.data,analyticsRequestFilter ]);
    

    useEffect(() =>
    {
        

        const exportPayload = [
            {
                name: "Most Popular Destination Saved To Itinerary",
                data: processedExportData,
                description: "",
                injector: injectProcessedData
            },
            // {
            //   name: "Most Popular Destination Saved To Itinerary",
            //   data: destinationQuery?.data,
            //   description: "",
            //   injector: injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedExportData]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={<Translation text="Widgets.WebEngagementBarChartDestinationWidget.title" />}
            webIcon={true}
        >
            <ConditionalRenderLoadingErrorNoDataChildren rawQuery={rawQuery} >
                <p className="para gap0">
                    <Translation text={("Widgets.WebEngagementBarChartDestinationWidget.A_Maximum_of")} />
                    <strong>  {processedWidgetData.maxDestinationCount + " "} </strong>
                    <Translation text={("Widgets.WebEngagementBarChartDestinationWidget.destination_were_added_on_to_itinerary_at")} />
                    <strong>  {processedWidgetData.maxDestinationTime}</strong>
                </p>
                <div className="height460">
                    <BarChart
                        {...processedWidgetData.barChart}
                        customTick={CustomTicks(processedWidgetData.barChart)}
                        options={{
                            ...UNIQUE_SESSION_BAR_CHART_OPTIONS,
                            ...processedWidgetData.barChart,
                            margin: { top: 10, right: 20, bottom: 45, left: 30 },
                            // legends: [
                            //     {
                            //         dataFrom: "keys",
                            //         anchor: 'bottom-right',
                            //         direction: 'column',
                            //         justify: false,
                            //         translateX: 120,
                            //         translateY: 0,
                            //         itemsSpacing: 2,
                            //         itemWidth: 100,
                            //         itemHeight: 20,
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 0.85,
                            //         symbolSize: 20,
                            //     }
                            // ],
                            animate: true,
                        }}
                        customTooltip={CustomTooltip}
                    />
                </div>


            </ConditionalRenderLoadingErrorNoDataChildren>
        </DefaultBox>
    );
};

export default Container;
