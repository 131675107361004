import { geoJsonToTurfPolygon, isPointsInPolygon } from "mapsted.maps/mapFunctions/features";
import { addFeatureToLayer, createEntityGeometry, createIconFeature, createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { extractLanguageCodeFromInfoEntityObject } from "mapsted.maps/utils/publicMetaData";
import { deepValue } from "mapsted.utils/objects";
import { Icon, Style } from "ol/style";
import { DEFAULT } from "../_constants/boostConstants";
import { NOT_IN_ZONE_GEOFENCE_COLOR } from "../_constants/colors";
import { convertDateTimeToLocalJSDateWithoutChangingTime, fromJsDateToDateTime } from "./date.luxon.utils";
import { dateRangeToString } from "./date.utils";
import { createMapOfZoneGeofenceLabelsToDefaultColorAndNullTemplate, createMapOfZonegeofenceToRandomColors, createStyleWithColor, hexToHsla } from "./misc.utils";
import { checkHistoryIsPossible } from "./zoneGeofence.utils";
/**
 * Maps property zone geofences to their respective properties, buildings, and floors.
 * Assigns color to each zone, unique per floor.
 * Adds color and template information to zone data.
 * Optionally includes visit count information.
 *
 * @param {Object} param0 - Object containing property zone geofences, visit map, and zone geofence colors and template info
 * @param {Object} param0.propertyZoneGeofences - Map of property zone geofences
 * @param {Object} param0.visitMap - Map of visit counts
 * @param {Object} param0.zoneGeofenceColorsAndTemplateInfo - Object containing zone geofence colors and templates
 * @returns {Object} - Map of property zone geofences by property ID, building ID, and floor ID with style and template information
 */
export const mapPropertyZoneGeofence = ({ propertyZoneGeofences, visitMap, zoneGeofenceColorsAndTemplateInfo = {} }) =>
{
    /**
     * propertyId:
     * {
     *     buildingId:
     *     {
     *         floorId:
     *         [
     *             {
     *                 ...zoneGeofenceData,
     *                 style,
     *             }
     *         ]
     *     }
     * }
     */
    let { zoneGeofenceMap } = propertyZoneGeofences;

    let propertyZoneGeofenceMap = {};

    Object.values(zoneGeofenceMap).forEach((zoneGeofence, i) =>
    {
        if (zoneGeofence)
        {
            let { propertyId, buildingId, floorId, label } = zoneGeofence;

            // create mapping to floor id if not already created
            if (!propertyZoneGeofenceMap[propertyId])
            {
                propertyZoneGeofenceMap[propertyId] = {};
            }

            if (!propertyZoneGeofenceMap[propertyId][buildingId])
            {
                propertyZoneGeofenceMap[propertyId][buildingId] = {};
            }

            if (!propertyZoneGeofenceMap[propertyId][buildingId][floorId])
            {
                propertyZoneGeofenceMap[propertyId][buildingId][floorId] = [];
            }

            // add color to zoneGeofence
            const zoneGeofenceColor = (zoneGeofenceColorsAndTemplateInfo[label]?.color);
            let zoneGeofenceData = { ...zoneGeofence };
            if (zoneGeofenceColor)
            {
                zoneGeofenceData.style = createStyleWithColor(zoneGeofenceColor);
                zoneGeofenceData.template = zoneGeofenceColorsAndTemplateInfo[label]?.template;
            }

            // add zoneGeofence to map

            // attach visit count if provided
            if (visitMap)
            {
                zoneGeofenceData.visitCount = visitMap[label] || 0;
            }

            propertyZoneGeofenceMap[propertyId][buildingId][floorId].push(zoneGeofenceData);
        }

    });

    return propertyZoneGeofenceMap;
};
/**
 * Connects zone gefoence ids and color to positions.
 * @param {*} param0
 * @param {Object} param0.zoneGeofenceMap - list of zoneGeofences
 * @param {Array} param0.positions - list of positions
 */
export const linkZoneGeofenceToPositions = ({ zoneGeofenceMap, positions }) =>
{
    positions.forEach((position) =>
    {

        let points = [position.position];

        let foundZoneGeofence = deepValue(zoneGeofenceMap, `${position.propertyId}.${position.buildingId}.${position.floorId}`, []).find((zoneGeofence) => isPointsInPolygon(points, geoJsonToTurfPolygon(zoneGeofence.boundary)));


        position.zoneGeofenceId = foundZoneGeofence?.zoneGeofenceId;
        position.zoneGeofenceColor = foundZoneGeofence?.style?.color;
    });
};

export const createPositionsLayer = (positions) =>
{
    let positionsLayer = createVectorLayer("PositionsLayer");

    const createCustomIconStyle = (color) => new Style({
        image: new Icon({
            color: color || NOT_IN_ZONE_GEOFENCE_COLOR,
            crossOrigin: "Anonymous",
            anchor: [.5, 39],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: "/img/pin-point.svg",
        }),
    });

    positions.forEach((position) =>
    {
        let positionStyle = createCustomIconStyle(position.zoneGeofenceColor);

        const geometry = createEntityGeometry({ type: ShapeTypes.POINT, coordinates: position.position, });

        const iconFeature = createIconFeature({ iconStyle: positionStyle, geometry, name: position.userUID, options: { position } });

        addFeatureToLayer(positionsLayer, iconFeature);
    });

    return positionsLayer;
};

export const createAndSetPositionSource = (positions, positionsLayer) =>
{

    const createCustomIconStyle = (color) => new Style({
        image: new Icon({
            color: color || NOT_IN_ZONE_GEOFENCE_COLOR,
            crossOrigin: "Anonymous",
            anchor: [.5, 39],
            anchorXUnits: "fraction",
            anchorYUnits: "pixels",
            src: "/img/pin-point.svg",
        }),
    });

    positions.forEach((position) =>
    {
        let positionStyle = createCustomIconStyle(position.zoneGeofenceColor);

        const geometry = createEntityGeometry({ type: ShapeTypes.POINT, coordinates: position.position, });

        const iconFeature = createIconFeature({ iconStyle: positionStyle, geometry, name: position.userUID, options: { position } });

        addFeatureToLayer(positionsLayer, iconFeature);
    });

    return positionsLayer;
};

export const processMapDataQuery = (mapDataQuery, languageCode, options = { isBulk: false }) =>
{
    const { data, isError, isLoading, isSuccess } = mapDataQuery;

    let processedData = undefined;

    if (isSuccess && data)
    {
        if (options.isBulk)
        {
            processedData = getProcessedBulkMapData(data, languageCode);
        }
        else
        {
            processedData = getProcessedMapData(data, languageCode);
        }
    }

    return { data: processedData, isError, isLoading, isSuccess };
};

export const getProcessedMapData = (mapData = {}, languageCode) =>
{
    const { imageBaseUrl, style } = mapData;
    let processedMapData = { imageBaseUrl, style };

    if (mapData.property)
    {
        processedMapData.property = getProcessedPropertyLevelMapData(JSON.parse(JSON.stringify(mapData.property)), languageCode);
    }
    else
    {
        processedMapData.building = getProcessedBuildingData(JSON.parse(JSON.stringify(mapData.building)), languageCode);
    }

    return processedMapData;
};

export const getProcessedBulkMapData = (mapData = {}, languageCode) =>
{
    const { imageBaseUrl, style } = mapData;
    let processedMapData = { imageBaseUrl, style, property: undefined, buildingHash: {} };

    processedMapData.property = getProcessedPropertyLevelMapData(JSON.parse(JSON.stringify(mapData.property)), languageCode);

    // loop through buildingIds
    Object.keys(mapData.buildingHash).forEach((buildingId) =>
    {
        processedMapData.buildingHash[buildingId] = getProcessedBuildingData(JSON.parse(JSON.stringify(mapData.buildingHash[buildingId])), languageCode);
    });

    return processedMapData;
};

export const getProcessedBuildingData = (building, languageCode = DEFAULT_LANGUAGE_CODE) =>
{
    if (!building?.info)
    {
        return;
    }

    let processedBuilding = extractLanguageCodeFromInfoEntityObject(building, languageCode);
    let floors = {};

    // create floor map
    processedBuilding.entities.forEach((e) =>
    {
        if (floors[e.floorId])
        {
            floors[e.floorId].push(e);
        }
        else
        {
            floors[e.floorId] = [e];
        }
    });

    processedBuilding.floors = floors;
    return processedBuilding;
};

export const getProcessedPropertyLevelMapData = (property, languageCode = DEFAULT_LANGUAGE_CODE) =>
{

    let processedProperty = extractLanguageCodeFromInfoEntityObject(property, languageCode);

    return processedProperty;
};
/**
 * Processes zone property general data and creates a list of possible zone edit history options.
 *
 * @param {Object} param0 - The parameters for the function.
 * @param {string} param0.propertyId - The ID of the property.
 * @param {Array} param0.editingHistories - The array of editing histories.
 * @param {Object} param0.zoneGeofenceMap - The map of zone geofences.
 * @param {Object} dateRange - The date range.
 * @param {string} timeZone - The time zone.
 * @param {number} [minimumActiveZoneHistoryTimeInMilliSeconds=60000] - The minimum active time in milliseconds (default is 1 minute).
 * @returns {Object} - The processed zone property general data.
 */
export const processZonePropertyGeneralData = ({ propertyId, editingHistories, zoneGeofenceMap }, dateRange, timeZone, minimumActiveZoneHistoryTimeInMilliSeconds = 1 * 60 * 1000) =>
{
    let zoneHistoryOptionsMap = {};
    let activeHistory = undefined;
    let possibleHistoryDateRange = {};

    if (editingHistories.length)
    {
        editingHistories.filter((editHistory) =>
        {
            // Subtract 1 second from the end time to get a correct API response.
            // As of the time of writing, if we query to the exact end date, we begin to get data for the next version.
            // The above was confirmed with R&D team.
            Object.assign(editHistory, { activeUntilTimestamp: { unixTime_ms: editHistory.activeUntilTimestamp.unixTime_ms - 1000 } });

            const { activeFromTimestamp, activeUntilTimestamp, } = editHistory;
            // Filter out invalid zone geofence history. Difference between activeFromTimestamp and activeUntilTimestamp should be at least minimumActiveZoneHistoryTimeInMilliSeconds value
            return activeUntilTimestamp.unixTime_ms - activeFromTimestamp.unixTime_ms >= minimumActiveZoneHistoryTimeInMilliSeconds;
        }).forEach((editHistory) =>
        {
            const { zoneGeofencesIds, activeFromTimestamp, activeUntilTimestamp, isActiveNow } = editHistory;

            const hStartDateTime = fromJsDateToDateTime(new Date(activeFromTimestamp.unixTime_ms), timeZone.id);
            const hEndDateTime = fromJsDateToDateTime(new Date(activeUntilTimestamp.unixTime_ms), timeZone.id);



            let historyFromDate = convertDateTimeToLocalJSDateWithoutChangingTime(hStartDateTime, timeZone.id);

            let historyTilDate = convertDateTimeToLocalJSDateWithoutChangingTime(hEndDateTime, timeZone.id);


            //pre-pare the time zone label based on propert's time not by local time
            let labelText = dateRangeToString({ startDate: historyFromDate, endDate: historyTilDate });
            const currentHistoryRange = { startDate: historyFromDate, endDate: historyTilDate };

            if (checkHistoryIsPossible(currentHistoryRange, dateRange))
            {
                let preparedDataZoneData = zoneGeofencesIds.reduce((acc, zoneId) =>
                {
                    acc[zoneId] = zoneGeofenceMap[zoneId];
                    return acc;
                }, {});

                if (!possibleHistoryDateRange?.startDate)
                {
                    possibleHistoryDateRange = currentHistoryRange;
                }

                if (currentHistoryRange.startDate < possibleHistoryDateRange.startDate && currentHistoryRange.endDate > possibleHistoryDateRange.endDate)
                {
                    possibleHistoryDateRange = currentHistoryRange;
                }

                if (isActiveNow)
                {
                    activeHistory = labelText;
                }

                zoneHistoryOptionsMap[labelText] = {
                    text: labelText,
                    value: labelText,
                    key: labelText,
                    data: preparedDataZoneData,
                    historyDateRange: currentHistoryRange,
                    historyRangeUnix_ms: { start_unix_ms: activeFromTimestamp.unixTime_ms, end_unix_ms: activeUntilTimestamp.unixTime_ms },
                    zoneGeofencesIds

                };

                editHistory.mapKey = labelText;
            }

        });

        if (!activeHistory)
        {
            activeHistory = dateRangeToString(possibleHistoryDateRange);
        }

    }
    else
    {
        const labelText = DEFAULT;
        activeHistory = labelText;
        zoneHistoryOptionsMap[labelText] = { text: labelText, value: labelText, key: labelText, data: zoneGeofenceMap, historyDateRange: dateRange, historyRangeUnix_ms: { start_unix_ms: Number(dateRange.startDate), end_unix_ms: Number(dateRange.endDate) } };
    }

    Object.values(zoneHistoryOptionsMap).forEach((zoneHistoryOption) =>
    {
        zoneHistoryOption.zoneHistoryOptionsMap = zoneHistoryOptionsMap;
    });

    return {
        propertyId,
        editingHistories,
        zoneGeofenceMap,
        zoneHistoryOptionsMap,
        activeHistoryOption: zoneHistoryOptionsMap[activeHistory]
    };
};

/**
 * function to check if plotting levels are different.
 * each passed object should have propertyId, buildingId, floorId in its properties
 * @param {Object} newPlottingLevelIds - {propertyId, buildingId, floorId}
 * @param {Object} previousPlottingLevelIds - {propertyId, buildingId, floorId}
 * @returns {boolean} - True if the plotting level IDs are different, false otherwise.
 */
export const checkPlottingLevelsIdsAreDifferent = (plottingLevelIds1, plottingLevelIds2) => plottingLevelIds1?.propertyId !== plottingLevelIds2?.propertyId
    || plottingLevelIds1?.buildingId !== plottingLevelIds2?.buildingId
    || plottingLevelIds1?.floorId !== plottingLevelIds2?.floorId;

/**
 * This function creates the styles for the zonegeofences based on the template settings.
 * If a zonegeofence does not have a template setting, it will be styled with a default color.
 * @param {Object} templateHash - A map of template IDs to template settings.
 * @param {Object} zoneGeoFenceMap - A map of zonegeofence IDs to zonegeofence objects.
 * @returns {Object} - A map of zonegeofence IDs to style objects, where each style object is structured as follows:
 * @example
 *
 * templateHash = {
 *    templateId:{templateId, color: hslaColor, lineColor, defaultFillOpacity, defaultBorderFillOpacity},
 *    templateId2:{ templateId,color: hslaColor, lineColor, defaultFillOpacity, defaultBorderFillOpacity}
 * }
 *
 * zoneGeoFenceMap = {
 *   zoneGeofenceId:{metadata:{templateId:"ddsldk"}},
 *   zoneGeofenceId:{metadata:{templateId:"ddsldk"}},
 }
 *
 * createZoneGeofenceStylesAndTemplate(templateHash,zoneGeoFenceMap)
 *
 *
 * returns {
 *    "zoneGeofenceLabel": {
 *        color: "hslaColor", // HSLA color code for fill
 *        template : Object || null;
 *    },
 *    ...
 * }
 */
export const createZoneGeofenceStylesAndTemplate = (templateHash, zoneGeoFenceMap) =>
{
    // Extract zone geofence objects from the map
    const zoneGeofenceList = Object.values(zoneGeoFenceMap);
    // Apply a default grey color fill and border with higher opacity for all zone geofences
    const preparedZoneStylesFromTemplate = createMapOfZoneGeofenceLabelsToDefaultColorAndNullTemplate(zoneGeofenceList);
    // Create a gradient color map for all zone geofences
    const zoneMapWithOnlyColor = createMapOfZonegeofenceToRandomColors(zoneGeofenceList);

    // Stores zone geofences that have custom styles based on templates
    const zoneWithTemplateStyles = {};

    // Iterate over each zone geofence to apply template styles if available
    zoneGeofenceList.forEach((zoneGeofence) =>
    {
        const { label, metadata } = zoneGeofence;
        // Check if the zone geofence has a template and apply the template styles
        if (metadata?.templateId && templateHash[metadata.templateId])
        {
            const template = templateHash[metadata.templateId];
            // Extract styling properties from the template
            const { color } = template;
            // Apply the template styles to the zone geofence
            preparedZoneStylesFromTemplate[label] = {
                color: hexToHsla("" + color, 1),
                template,
            };
            // Mark the zone geofence as styled by a template
            zoneWithTemplateStyles[label] = true;
        }
    });

    //If no zonegeofences have template-based styles, return default styles based on a random color map
    //i believe this is needed because if no template data is available, use our legacy logic showing all the  zonegeofences with a default color. may confuses the user because we previously used to show zonegeofences with random color mapping;
    if (!Object.keys(zoneWithTemplateStyles).length)
    {
        return Object.entries(zoneMapWithOnlyColor).reduce((acc, [key, color]) =>
        {
            acc[key] = {
                color,
                template: null
            };
            return acc;
        }, {});
    }

    // Return the styles map for zonegeofences with template-based styles
    return preparedZoneStylesFromTemplate;
};
