import React from "react";
import {
    AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT,
    BAR_CHART_AXIS_LEFT_TEXT_OPTIONS,
    COMMONLY_USED_DEVICES_CHART_OPTIONS,
} from "../../../_constants/chartConstants";
import { BarChart } from "../../charts/BarChart";
import { NoDataWidgetMessage } from "../../common/WidgetMessage/WidgetMessage";
import CommonlyUsedDeviceInfoText from "./CommonlyUsedDeviceInfo.text";

const CommonlyUsedDevicesBarChart = ({ data, trans }) => {
    const customTickLeft = React.useCallback((tick) => {
        let renderedTick =
            tick.value.length > AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT ? (
                <tspan>
                    {tick.value.substring(0, AXIS_LEFT_TEXT_WRAP_CHAR_LIMIT) + "..."}
                    <title>{tick.value}</title>
                </tspan>
            ) : (
                tick.value
            );

        return (
            <g transform={`translate(${tick.x},${tick.y})`}>
                <line x1="0" x2="-5" y1="0" y2="0" style={{ stroke: "rgb(119, 119, 119)", strokeWidth: "1" }} />

                <text {...BAR_CHART_AXIS_LEFT_TEXT_OPTIONS}>{renderedTick}</text>
            </g>
        );
    }, []);

    const customTooltip = React.useCallback(
        ({ indexValue, value, color /*index, color*/ }) => {
            return (
                <div className="tooltipItemRow tooltipItemRowDevice">
                    <span style={{ background: color }} /> {indexValue}: <strong>{value}</strong>{" "}
                    {Number(value) === 1
                        ? trans("CommonlyUsedDevicesWidget.User")
                        : trans("CommonlyUsedDevicesWidget.Users")}
                </div>
            );
        },
        [trans]
    );

    const customTick = React.useCallback((tick) => {
        if (tick.value % 1 === 0) {
            return (
                <g transform={`translate(${tick.x},${tick.y + 10})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            fontSize: 10,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        } else {
            return <></>;
        }
    }, []);

    const { mostUsedModel, commonlyUsedDeviceBarData } = data;

    if (data && commonlyUsedDeviceBarData.length === 0) {
        return <NoDataWidgetMessage />;
    }

    return (
        <React.Fragment>
            <CommonlyUsedDeviceInfoText {...{ mostUsedModel, trans }} />

            <div className="UsedDevicesWidget">
                <BarChart
                    data={commonlyUsedDeviceBarData.slice(-6)}
                    index="modelName"
                    keys={["Users"]}
                    customAxisLeft={{ renderTick: customTickLeft }}
                    options={COMMONLY_USED_DEVICES_CHART_OPTIONS}
                    customTick={customTick}
                    customTooltip={customTooltip}
                    margin={{ left: 140 }}
                />
            </div>
        </React.Fragment>
    );
};

export default CommonlyUsedDevicesBarChart;
