import React, { useEffect } from "react";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { calculatePercentageOfChange } from "mapsted.utils/numbers";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const VisitorsNumberWidget = ({ isSuccess, isError, trans, dashboardSummary, dashboardSummaryCompare, injectProcessedData }) =>
{
    let changedPercentage = calculatePercentageOfChange(dashboardSummary?.numberUsers, dashboardSummaryCompare?.numberUsers);
    const connectedPageName = useRecoilValue(connectedPageNameState);
    useEffect(() =>
    {
        const payload = {
            name: "Visits",
            data: [{
                "Visits": dashboardSummary?.numberUsers,
                "PercentageOfChange": changedPercentage
            }],
            description: "",
            inject: injectProcessedData
        };

        injectProcessedData && injectProcessedData(payload);
    }, [dashboardSummary?.numberUsers,
    dashboardSummaryCompare?.numberUsers , connectedPageName]);
    if (isSuccess)
    {
        return (
            <>
                <OverviewDetailBox
                    max="190px"
                    popupContent={trans("DashboardOverviewWidget.Number_of_total_visitors_in_the_selected")}
                    icon="visitors"
                    content={dashboardSummary.numberUsers}
                    slogan={trans("DashboardOverviewWidget.Visitors")}
                    percentage={changedPercentage}
                />
            </>
        );
    } else if (isError)
    {
        return (
            <>
                <OverviewDetailBox
                    max="190px"
                    popupContent={trans("DashboardOverviewWidget.Number_of_total_visitors_in_the_selected")}
                    icon="visitors"
                    content="--"
                    slogan={trans("DashboardOverviewWidget.Visitors")}
                />
            </>
        );
    }

    return <OverviewDetailBoxLoading />;
};

export { VisitorsNumberWidget };
