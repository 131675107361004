import React from 'react';
import { Popup } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

export const AppOnlyTooltip = () =>
{
    const trans = useTranslation().t;
    return (
        <Popup
            className="appOnlyTooltip"
            basic
            trigger={<img className="triggerAppTooltip" src="/img/icon-app-only.svg" alt="" />}
            on="hover"
            size="small"
            position="bottom center"
            content={trans("AppOnlyTooltip.This_widget_shows_data_across_the_entire")} />
    );
}
export const WebOnlyTooltip = ({ position }) =>
{
    const trans = useTranslation().t;
    return (
        <Popup
            className="appOnlyTooltip webOnlyTooltip"
            basic
            trigger={
                <img
                    className="triggerWebTooltip"
                    src="/img/icon-web-only.svg"
                    alt=""
                />
            }
            on="hover"
            size="small"
            position="bottom left"
            content={trans(
                "WebOnlyTooltip.This_widget_shows_data_across_the_entire"
            )}
        />
    );
};
