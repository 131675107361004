import { atom } from "recoil";
import { getDefaultStateOfActivePageDataExport } from "../_utils/exportToCsvUtils";
import { DEFAULT_SEARCH_FILTER } from "../_constants/exportToCsvConstants";


export const isDataExportModalStateIsOpenState = atom({
    key: "isDataExportModalStateIsOpenState",
    default: false,
});

export const activePageDataExportHandlersState = atom({
    key: "activePageDataExportHandlersState",
    default: getDefaultStateOfActivePageDataExport()
});

export const connectedPageNameState = atom({
    key: "connectedPageNameState",
    default: "Mapsted Analytics",
});

export const searchForDataItemWidgetState = atom({
    key: "searchForDataItemWidgetState",
    default: DEFAULT_SEARCH_FILTER,
});