import React, { useCallback, useMemo } from "react";
import { InsightsContext } from "./InsightsContext";
import { InsightsOutline } from "./InsightsOutline";
import useInsightsCommon from "./useInsightsCommon";
import '../../common/subheader/Subheader.css';
import '../../common/overviewHead/OverviewHead.css';
import '../boost/boostAnalytics.css';
import useCsvExportEventEmitter from "../../common/ExportToCsv/useCsvExportEventEmitter";
import useExportDataConnector from "../../common/ExportToCsv/useExportDataConnector";
import { formatMinsToHHMMSS } from "../../../_utils/date.utils";
import { convertMinsToHrsAndMins, convertUnixSecondsToReadAbleTimeFormatWithTimeZone } from "../../../_utils/date.luxon.utils";
import { InsightsSessionStatusToReadableText, InsightsTrajectoryStatusToReadableText } from "../../../_constants/insightsConstants";
import { useTranslation } from "react-i18next";


// this approach  will make use we only have one instance of the useInsightsCommon in the app
//reduces re-rendering and improves performance
const InsightsContainer = () =>
{
    const trans = useTranslation().t;
    const insightsData = useInsightsCommon();
    const exportHandlers = useCsvExportEventEmitter();
    const { injectProcessedData } = useExportDataConnector("Insights");

    const {
        sessionsTableData,
        trajectoryChartData,
        processedTrajectoryData,
        timeZone,
        dwellTimeForActiveTrajectoryZones,
    } = insightsData;

    const formattedTrajectoryChartData = useMemo(() =>
    {
        const { timeSpent, startTime, endTime, distance, distanceChart, durationChart, timeSpent_m } = trajectoryChartData;
        return {
            timeSpent,
            timeSpent_m,
            startTime,
            endTime,
            distance,
            distanceChart: distanceChart.map((item) => ({ data: item.data })),
            durationChart: durationChart.map((item) => ({ data: item.data })),
        };
    }, [trajectoryChartData]);

    // Memoize formattedTrajectoryLayerData
    const formattedTrajectoryLayerData = useMemo(() =>
    {
        return processedTrajectoryData?.data.map(({ deltaTime_s, selectedColor, trajectoryPoint }) => ({
            x: trajectoryPoint.longitude,
            y: trajectoryPoint.latitude,
            //TimeStamp: convertUnixSecondsToReadAbleTimeFormatWithTimeZone(deltaTime_s, propertyTimeZone.id), // removing timestamp for  new insights  api updates
        }));
    }, [processedTrajectoryData]);

    // Memoize modifiedSessionTableData
    const modifiedSessionTableData = useMemo(() =>
    {
        return sessionsTableData.map((session) =>
        {
            const { sessionUID, sessionStartTime, sessionEndTime, sessionStatus, duration, propertyVisits } = session;
            const subVisits = propertyVisits.map((visit) =>
            {
                const { sessionUID, startTime, buildingName, trajectoryReconstructionStatus, duration_min } = visit;
                return {
                    SessionId: sessionUID,
                    BuildingName: buildingName,
                    Duration: convertMinsToHrsAndMins(duration_min),
                    StartTime: convertUnixSecondsToReadAbleTimeFormatWithTimeZone(startTime / 1000, timeZone.id),
                    EndTime: convertUnixSecondsToReadAbleTimeFormatWithTimeZone((startTime / 1000) + (duration_min * 60), timeZone.id),
                    Status: trans(`Insights.TrajectoryStatus.${InsightsTrajectoryStatusToReadableText[trajectoryReconstructionStatus]}`),
                };
            });

            const sessionData = {
                SessionId: sessionUID,
                BuildingName: null,
                Duration: duration,
                StartTime: sessionStartTime,
                EndTime: sessionEndTime,
                Status: sessionStatus ? trans(`Insights.SessionStatus.${InsightsSessionStatusToReadableText[sessionStatus]}`) : null,
            };

            return [sessionData, ...subVisits];
        });
    }, [sessionsTableData, timeZone.id]);


    const durationTrajectories = useMemo(() =>
    {
        return [formattedTrajectoryChartData.durationChart[0]]?.flatMap((item) =>
        {
            let isNull = item.data.every((data) => data.x == 0);
            if (isNull)
            {
                return [];
            };
            return item.data.map((data) => ({
                CumulativeMinsAcrossTrajectory: data.x,
                DurationsMins: data.y,
                DurationFormatted: formatMinsToHHMMSS(data.y),
            }));
        });

    }, [formattedTrajectoryChartData.durationChart]);

    const distanceTrajectories = useMemo(() =>
    {
        return [formattedTrajectoryChartData.distanceChart[0]]?.flatMap((item) =>
        {
            let isNull = item.data.every((data) => data.x == 0);
            if (isNull)
            {
                return [];
            };
            return item.data.map((data) => ({
                CumulativeMetersAcrossTrajectory: data.x,
                DurationMins: data.y,
                DurationFormatted: formatMinsToHHMMSS(data.y),
            }));
        });
    }, [formattedTrajectoryChartData.distanceChart]);

    const trajectoryData = useMemo(() =>
    {
        const { timeSpent, distance, startTime, endTime, timeSpent_m } = formattedTrajectoryChartData;
        return {
            Duration: timeSpent,
            DurationFormatted: formatMinsToHHMMSS(timeSpent_m),
            TotalDistance: distance,
            StartTime: startTime,
            EndTime: endTime,
        };
    }, [formattedTrajectoryChartData]);
    const formattedTrajectoryTableData = useMemo(() =>
    {
        let data = [];
        if (dwellTimeForActiveTrajectoryZones?.length)
        {
            data = dwellTimeForActiveTrajectoryZones.map(({ label, timeSpent_s }) => ({
                ZoneGeofence: label,
                DurationFormatted: formatMinsToHHMMSS(timeSpent_s / 60),
                DurationMins: timeSpent_s / 60,
            }));
        }
        return data;
    }, [dwellTimeForActiveTrajectoryZones]);

    // Creates an array of iterable data sets for the widgets that has data
    const preparedPayload = useCallback(() =>
    {
        return [
            {
                name: 'Zone Geofence Visits',
                data: formattedTrajectoryTableData,
                description:
                    'Shows the average daily number of visits and dwell time per each zone-geofence. When no Tag is selected, the aggregated data is shown.',
                inject: injectProcessedData
            },
            {
                name: 'Sessions Information',
                data: modifiedSessionTableData,
                description: 'Shows the average sessions per each zone-geofence. When no Tag is selected, the aggregated data is shown.',
                inject: injectProcessedData,
            },
            {
                name: 'Processed Trajectory Data',
                data: formattedTrajectoryLayerData,
                description: 'Shows the processed trajectory data. When no Tag is selected, the aggregated data is shown.',
                inject: injectProcessedData,
            },

            {
                name: 'Trajectory Info',
                data: trajectoryData.Duration ? [trajectoryData] : [],
                description: '',
                inject: injectProcessedData,
            },
            // {
            //     name: 'Cumulative duration along the projected trajectory (min)',
            //     data: durationTrajectories,
            //     description: '',
            //     inject: injectProcessedData,
            // },
            {
                name: 'Cumulative distance along the projected trajectory (meter)',
                data: distanceTrajectories,
                description: '',
                inject: injectProcessedData,
            },
        ];
    }, [
        formattedTrajectoryTableData,
        modifiedSessionTableData,
        formattedTrajectoryLayerData,
        trajectoryData,
        durationTrajectories,
        distanceTrajectories,
    ]);

    // Takes the data from the available widgets when called preparedPayload and maps through them
    React.useEffect(() =>
    {
        if (!exportHandlers.isDataSelectionModalOpen)
        {
            return;
        };

        const payloads = preparedPayload();
        payloads.forEach(({ name, data, description, inject }) =>
        {
            if (data)
            {
                const payload = { name, data, description };
                inject && inject(payload);
            }
        });
    }, [exportHandlers.isDataSelectionModalOpen, preparedPayload]);

    return <InsightsContext.Provider
        value={{
            ...insightsData,
            exportHandlers
        }}>

        <InsightsOutline />
    </InsightsContext.Provider>;
};

export default InsightsContainer;
