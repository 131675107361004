import { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useQueryWithZoneHistoryDateRangeBreakDown } from "../../../_api/queries";
import serverApi from "../../../_api/server.api";
import { OccupancyBarChartOptions as OccupancyBarChartOptionsConstants } from "../../../_constants/chartConstants";
import { NAV_ANALYTICS_QUERY_KEYS } from "../../../_constants/queryConstants";
import { processZoneGeofenceOccupancy } from "../../../_utils/chart.utils";
import { dateRangeToUTCStrings } from "../../../_utils/date.luxon.utils";
import { activeZoneHistoryByFloorDataState, historyKeysHavingDataForActiveFloorState } from "../../../store/DashboardAtoms";
import { zoneGeofenceActiveHistoryState, zoneGeofenceToColorMapSelector } from "../../../store/MapAtoms";

import useCommon from "../../pages/useCommon";

const hasData = ({ zoneGeofenceTraffic }, floorId) =>
{
    if (!zoneGeofenceTraffic?.length) return false;

    if (!floorId) return false;

    return zoneGeofenceTraffic.some((z) => z.floorId === floorId && z.userCount > 0);
};
function useOccupancyAnalyticsBar({
    apiCacheKey = "UserFlow",
    apiKey = NAV_ANALYTICS_QUERY_KEYS.ZONE_TRAFFIC,
    apiHandler = serverApi.getNavigationAnalyticsData,

})
{
    const {

        trans,
        environmentConstants,
        timeZone,
        floorId,
        propertyId,
        buildingId,
        propertyTimeZone
    } = useCommon();
    const zoneGeofenceColorMap = useRecoilValue(zoneGeofenceToColorMapSelector);
    const zoneFloorHistory = useRecoilValue(activeZoneHistoryByFloorDataState);
    const setHistoryKeysHavingDataForActiveFloor = useSetRecoilState(historyKeysHavingDataForActiveFloorState);
    const setZoneHistory = useSetRecoilState(zoneGeofenceActiveHistoryState);

    // query for zone geofence visits
    // pull zone geofence visit data
    const { queryKeys, queryFn } = useMemo(() =>
    {
        const queryKeys = [environmentConstants, apiCacheKey, apiKey, propertyId,
            buildingId, propertyTimeZone.id];
        let filter = { propertyId, buildingId };

        const queryFn = async (dateRangeHash) =>
        {

            const { startDate, endDate, considerTimeInDateRange, zoneGeofencesIds } = dateRangeHash;
            const dateRange = {
                startDate, endDate
            };
            filter = {
                ...filter,
                ...dateRangeToUTCStrings({
                    ...dateRange,
                    considerTimeInDateRange
                }, { id: propertyTimeZone.id, name: propertyTimeZone.id }),
            };

            const response = await apiHandler(filter, apiKey, environmentConstants);
            if (response.success)
            {
                return { zoneGeofenceTraffic: response.data.zoneGeofenceTraffic.filter((d) => zoneGeofencesIds?.includes(d.zoneGeofenceId)) };
            }
            else
            {
                return { zoneGeofenceTraffic: [] };
            }
        };


        return {
            queryKeys,
            queryFn,

        };
    }, [environmentConstants, apiCacheKey, apiKey, propertyId,
        buildingId, propertyTimeZone.id]);

    //calling the above query with dateRangebreakdown based on by zone history....
    const query = useQueryWithZoneHistoryDateRangeBreakDown(queryFn, queryKeys);

    //loading the zone geofence traffic based on zone history
    const zoneGeofenceTrafficQuery = useMemo(() =>
    {
        let updatedQuery = { ...query, data: { zoneGeofenceTraffic: [] } };
        if (query.isSuccess && zoneFloorHistory)
        {
            const dataToUpdate = query.data?.[zoneFloorHistory.key]?.responseData;
            let data = {};
            const zoneKeysHash = zoneFloorHistory?.data ?? {};
            data.zoneGeofenceTraffic = dataToUpdate.zoneGeofenceTraffic.filter((zone) => !!zoneKeysHash[zone.zoneGeofenceId]);
            return {
                ...updatedQuery,
                data: data,
                dataUpdatedAt: Date.now()
            };
        }

        return updatedQuery;

    }, [query.dataUpdatedAt, zoneFloorHistory]);


    //setting zone history based on  available data
    useEffect(() =>
    {

        if (query.isSuccess)
        {
            let historyKeysWithData = [];
            //this will give data sorted in latest date order
            const queryDataBreakDownByZoneHistory = Object.entries(query.data).reverse();

            if (queryDataBreakDownByZoneHistory.length > 1)
            {
                queryDataBreakDownByZoneHistory.forEach(([key, { responseData }]) =>
                {
                    // console.log(responseData, floorId, zoneFloorHistory);
                    if (hasData(responseData, floorId))
                    {
                        historyKeysWithData.push(key);
                    }
                });

                // Here we are checking if there is any data available for the floor and setting the zone history accordingly
                // 1. We first check if there are any history keys that has data for the floor
                // 2. If there is any, we set the history keys that has data for the floor
                // 3. Then we check if the current zone history is available for the key with data, if it is we set it
                // 4. Else, we leave the current zone history as it is
                if (historyKeysWithData.length)
                {
                    //gives the latest history key
                    const key = historyKeysWithData[0];

                    // Update the history keys that have data for the active floor
                    setHistoryKeysHavingDataForActiveFloor(historyKeysWithData);

                    // Update the zone history if it is available for the key with data
                    setZoneHistory((prev) =>
                    {
                        // This block checks if the current zone history is available for the key with data.
                        //
                        // It first checks if the `prev` (previous state of `setZoneHistory`) exists and if it has a `zoneHistoryOptionsMap` property.
                        // If it does, it means that there is a history available.
                        //
                        // Then, it checks if the key with data (`key`) is present in the `zoneHistoryOptionsMap`.
                        // This step ensures that we are not setting a history that is not meant to be set.
                        //
                        // If the key is present, it means that the history is available and it sets it as the new state of `setZoneHistory`.
                        if (prev && prev.zoneHistoryOptionsMap?.[key] && !historyKeysWithData.includes(prev.key))
                        {
                            const { zoneHistoryOptionsMap } = prev;

                            // If it is available, set it
                            return zoneHistoryOptionsMap[key];
                        }

                        // If it is not available, keep the current zone history
                        return prev;
                    });
                }

            }


        }

    }, [query.dataUpdatedAt, floorId]);

    const processedHourlyAverageZoneGeofenceTraffic = useMemo(() =>
    {
        const { isSuccess, isLoading, data, isError } = zoneGeofenceTrafficQuery;

        let processedData = {};

        if (isSuccess && data)
        {
            processedData = processZoneGeofenceOccupancy({ data, timeZone, floorId: floorId || -1 });
        }

        return { isSuccess, isLoading, isError, data: processedData };

        // TODO Refresh on language string change
    }, [zoneGeofenceTrafficQuery.dataUpdatedAt, floorId, timeZone]);



    const findZoneGeofenceLabelById = useCallback(
        (zoneGeofenceId) =>
        {
            const { data } = zoneGeofenceTrafficQuery;

            if (!data)
            {
                return;
            }

            let zoneGeofence = data.zoneGeofenceTraffic.find((x) => x.zoneGeofenceId === zoneGeofenceId);

            return zoneGeofence?.zoneLabel;
        },
        [zoneGeofenceTrafficQuery]
    );

    return {
        processedHourlyAverageZoneGeofenceTraffic,
        trans,
        zoneGeofenceTrafficQuery,
        zoneGeofenceColorMap,
        findZoneGeofenceLabelById,
        OccupancyBarChartOptions: OccupancyBarChartOptionsConstants,
    };
}

export default useOccupancyAnalyticsBar;
