import React, { useCallback, useMemo } from "react";
import { ResponsiveLine } from '@nivo/line'

export const LineChart = ({ data, options = {}, customSliceTooltip, axisLeftMaxValue, customLegendFormat, customPointSymbol, customAxisTickValues = undefined }) =>
{
    const filteredTickValues = useMemo(() =>
    {
        if (!!customAxisTickValues)
        {
            return customAxisTickValues
        }
        const xLabels = data?.[0]?.data ?? []

        if (xLabels.length > 14)
        {
            return xLabels.map((d, index) => index === 0 || index % 2 === 0 ? d.x : "")
        }
        return xLabels.map(((d, index) => d.x))


    }, [data, customAxisTickValues]);

    const chartOptions =
    {
        margin: options?.margin || { top: 20, right: 40, bottom: 100, left: 40 },
        xScale: { type: options.xScaleType || 'point' },
        yScale: { type: 'linear', min: 0, max: axisLeftMaxValue || 'auto', stacked: false, reverse: false },
        yFormat: " >-.2f",
        curve: options?.curve || 'monotoneX',
        colors: options?.colors,
        enableSlices: (!!customSliceTooltip) && "x",
        sliceTooltip: customSliceTooltip,
        pointSymbol: customPointSymbol,
        axisTop: null,
        axisRight: null,
        axisBottom: {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: options?.axisBottom?.legend, // x axis title
            legendOffset: options?.axisBottom?.legendOffset || 36,
            legendPosition: 'middle',
            tickValues: filteredTickValues
        },
        axisLeft: {
            orient: 'left',
            tickValues: options?.axisLeft?.tickValues,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: options?.axisLeft?.legend, // y axis title
            legendOffset: options?.left?.legendOffset || -40,
            legendPosition: 'middle'
        },
        gridYValues: options?.gridYValues,
        pointSize: options?.pointSize || 10,
        pointColor: options?.pointColor || { theme: 'background' },
        pointBorderWidth: 2,
        pointBorderColor: { from: 'serieColor' },
        enablePointLabel: options?.enablePointLabel,
        pointLabelYOffset: -12,
        useMesh: true,
        enableGridX: options?.enableGridX,
        enablePoints: options?.enablePoints,
        enableArea: options?.enableArea,
        legends: options?.legends || [
            {
                anchor: 'bottom',
                data: customLegendFormat ? customLegendFormat(options?.legendData) : options?.legendData,
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 65,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
            }
        ],
    }


    return (
        <ResponsiveLine
            data={data}
            {...chartOptions}
        />
    )
}

