export const floorPlanAcceptableFileFormats = "image/x-png,image/png,image/jpeg,.dwg,image/svg+xml,image/svg,application/pdf";
export const acceptableImageFileFormats = "image/x-png,image/png,image/jpeg,image/svg+xml,image/svg,image/gif";
export const acceptableLogoFileFormats = "image/x-png,image/png,image/jpeg,image/svg+xml,image/svg";

export const MAX_GALLERY_LENGTH = 9;

export const DEFAULT_LANGUAGE_CODE = "en";

export const SUPER_ADMIN_ROLE_ID = 1;

