import { atom } from "recoil";
import { DEFUALT_LANGUAGE_CODE } from "../_constants/constants";

export const environmentConstantsState = atom({
    key: "environmentConstantsState",
    default: undefined,
});

export const languageCodeState = atom({
    key: "languageCodeState",
    default: DEFUALT_LANGUAGE_CODE
});

export const companyTeamsState = atom({
    key: "companyTeams",
    default: undefined,
});

export const selectedTeamState = atom ({
    key: "selectedTeamState",
    default: undefined
});

export const selectedTeamPropertyListState = atom ({
    key: "selectedTeamPropertyListState",
    default: undefined
});