import React from "react";

const NewVsRepeatInfoText = ({ data, trans }) => {
    return (
        <div className="flexibleRow">
            {/* TODO */}
            <p className="para">
                <strong>{data.returningUsersPercentage}%</strong>{" "}
                {trans("NewVsRepeatUsersWidget.were_repeat_visits_in_the_selected_time_")}
            </p>
            <p className="para">
                <strong>{data.newUsersPercentage}%</strong>{" "}
                {trans("NewVsRepeatUsersWidget.were_new_visits_in_the_selected_time_per")}
            </p>
        </div>
    );
};

export default NewVsRepeatInfoText;
