import React from "react";
import { useQuery, } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getDefaultFloorIdFromPublicBuilding } from "mapsted.maps/utils/map.utils";
import serverApi from "../_api/server.api";
import { Loader } from "../components/elements/loader";
import { buildingIdState, filterValuesState, floorIdState, propertyIdState, propertyInfoMapState } from "./DashboardAtoms";
import { DASHBOARD_DATA } from "../_constants/constants";
import { processFilterValues } from "../_utils/utils";
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../_constants/queryConstants";
import { environmentConstantsState } from "./AppAtoms";


/**
 * The purpose of this wrapper is to handle any global api calls for data
 * that is needed for all pages that fall under this wrapper.
 * 
 * Page loading gif can also be handled here
 */
const AnalyticsWrapper = (props) =>
{
    const setPropertyInfoMap = useSetRecoilState(propertyInfoMapState);
    const [propertyId, setPropertyId] = useRecoilState(propertyIdState);
    const [buildingId, setBuildingId] = useRecoilState(buildingIdState);
    const [floorId, setFloorId] = useRecoilState(floorIdState);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const setFilterValues = useSetRecoilState(filterValuesState);

    /**
     * On mount, pull users properties and save in recoil state.
     * //TODO handle language string on front end
     */
    const propertyInfoMapQuery = useQuery("getPropertyInfoMap", async () =>
    {
        const data = await serverApi.getProperties({ languageCode: "en" });
        // get api key 

        // put promise list 
        if (!!data)
        {
            // update atom state
            handleOnAnalyticsMount(data.propertyInfoMap);
            return data.propertyInfoMap;
        }
    }, QUERY_OPTIONS);

    /**
     * Fetch filter values on property / building change
     */
    const filterValuesQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.FILTER_VALUES, { propertyId, buildingId, environmentConstants }], async () =>
    {
        if (!!propertyId && !!environmentConstants)
        {
            const result = await serverApi.getNavigationAnalyticsData({ propertyId, buildingId }, NAV_ANALYTICS_QUERY_KEYS.FILTER_VALUES, environmentConstants);

            if (!result?.success)
            {
                throw new Error("filter max values query was not ok");
            }


            return processFilterValues(result.data);
        }
        else
        {
            throw new Error("Filter was not ok");
        }

    }, {
        enabled: (!!propertyId && !!environmentConstants),
        ...QUERY_OPTIONS
    });

    const handleOnAnalyticsMount = (propertyInfoMap) =>
    {
        //get default propertyId/buildingId/floorId if it does not exist in propertyInfoMap
        let newPropertyId = -1;
        let newBuildingId = -1;
        let newFloorId = -1;

        if (!propertyInfoMap[propertyId])
        {
            const propertyIds = Object.keys(propertyInfoMap);

            newPropertyId = (Array.isArray(propertyIds) && propertyIds.length > 0) && propertyIds[0];

            // check if the property has buildings
            if (newPropertyId && propertyInfoMap[newPropertyId].buildings.length > 0)
            {
                // get default building
                newBuildingId = Object.keys(propertyInfoMap[newPropertyId].buildings)[0];

                let building = propertyInfoMap[newPropertyId].buildings[newBuildingId];

                // get default floorId
                newFloorId = getDefaultFloorIdFromPublicBuilding(building);
            }

            setPropertyId(Number(newPropertyId));
            setBuildingId(Number(newBuildingId));
            setFloorId(Number(newFloorId));
        }

        setPropertyInfoMap(propertyInfoMap);
    }

    const updateDashboardData = (data) =>
    {
        Object.keys(data).forEach((key) =>
        {
            serverApi.handleUpdateDashboardData(key, data[key]);
        });
    }

    /** USE EFFECTS UPDATES FUNCTIONS **/

    /**
     * Update local state when propertyId or buildingId changes
     */
    React.useEffect(() =>
    {
        let newBuildingId = buildingId;
        let newFloorId = floorId;

        if (newBuildingId === -1)
        {
            newBuildingId = undefined;
        }

        if (newFloorId === -1)
        {
            newFloorId = undefined;
        }

        updateDashboardData({
            [DASHBOARD_DATA.PROPERTY_ID]: propertyId,
            [DASHBOARD_DATA.BUILDING_ID]: newBuildingId,
            [DASHBOARD_DATA.FLOOR_ID]: newFloorId
        });

    }, [propertyId, buildingId, floorId, setBuildingId]);

    /**
     * On filter values query change
     */
    React.useEffect(() =>
    {
        if (filterValuesQuery.isSuccess)
        {
            setFilterValues(filterValuesQuery.data);
        }
    }, [filterValuesQuery, setFilterValues]);


    return (
        <React.Fragment>
            {
                (propertyInfoMapQuery.isLoading) &&
                <Loader active={true} />
            }
            {props.children}
        </React.Fragment>
    )
}

export default AnalyticsWrapper;