import React, { memo } from 'react'
import { PIE_CHART_ARC_LINK_CHAR_LIMIT, SESSION_SOUCRE_PIE_CHART_OPTIONS } from '../../../_constants/chartConstants'
import { PieChart } from '../../charts/PieChart'
import { ConditionalRenderLoadingErrorNoDataChildren } from '../../common/ConditinalLoader'
import Translation from '../../common/translation/Translation'
import { DefaultBox } from '../../dashboard/DefaultBox'
import UseQRvsLinkPieChartWidget from './UseQRvsLinkPieChartWidget'



const CustomTooltip = memo(
	({ datum }) =>
	{
		const { id, value, color, data } = datum;
		return (
			<div className="tooltipItemRow tooltipItemProvider">
				<span style={ { background: color } } /> { id }: <strong>{ value }</strong>
				{ Number(value) === 1
					? <Translation text={ ("Widgets.WebEngagementQRvsLinkPieChartWidget.Visit") } />
					: <><Translation text={ ("Widgets.WebEngagementQRvsLinkPieChartWidget.Visits") } /> - <strong>{ data.percentage }%</strong></>
				}
			</div>
		);
	},
)

const CustomArcLinkLabel = memo((data) =>
{
	let { label } = data;

	if (label.length > PIE_CHART_ARC_LINK_CHAR_LIMIT)
	{
		return label.substring(0, PIE_CHART_ARC_LINK_CHAR_LIMIT) + "...";
	} else
	{
		return label;
	}
});

const Container = () =>
{
	const { rawQuery, processedWidgetData } = UseQRvsLinkPieChartWidget()

	return (
		<DefaultBox
			className="alignedWidget"
			heading={ <Translation text="Widgets.WebEngagementQRvsLinkPieChartWidget.title" /> }
		>
			<ConditionalRenderLoadingErrorNoDataChildren
				rawQuery={rawQuery}
			>
				<div className="ChartWrapper">
					<PieChart
						data={ processedWidgetData.data }
						options={ SESSION_SOUCRE_PIE_CHART_OPTIONS }
						customArcLinkLabel={ CustomArcLinkLabel }
						customTooltip={ CustomTooltip }
					/>
				</div>
			</ConditionalRenderLoadingErrorNoDataChildren>

		</DefaultBox>
	)
}

export default Container