import axios from "axios";
import { API_KEYS as DASHBOARD_API_KEY } from "../_constants/dashboard";
import { parseApiDataToDashboard } from "../_utils/dashboard.utils";
import { filterToUrlParams } from "../_utils/utils";

export const silentLoginToken = (remove = true) =>
{
    var searchParams = new URLSearchParams(window.location.search);
    const userToken = searchParams.get("t");
    if (userToken && remove)
    {
        searchParams.delete("t");
        if (window.history.replaceState)
        {
            let params = searchParams.toString();
            if (params.length > 0) params = `?${params}`;
            window.history.replaceState(
                {},
                null,
                `${window.location.origin}${params}`
            );
        }
    }

    return userToken;
};

class storage
{
    static local = "localStorage";
    static session = "sessionStorage";

    //localStorage, sessionStorage
    constructor(storageType)
    {
        this.storage = window[storageType];
    }

    clear(key)
    {
        this.storage.removeItem(key);
    }

    readJSON(key)
    {
        let data = this.storage.getItem(key) || undefined;
        return data ? JSON.parse(data) : undefined;
    }

    writeJSON(key, data)
    {
        // console.log("writeJSON -> ", key, data)
        this.storage.setItem(key, JSON.stringify(data));
    }
}

export class webAPI
{
    localStorage = new storage(storage.local);
    sessionStorage = new storage(storage.session);

    clear()
    {
        this.sessionStorage.clear("user-data");
    }

    get token()
    {
        const userData = this.userData;
        return userData ? userData.token : undefined;
    }

    set token(token)
    {
        const userData = this.userData;

        userData.token = token;

        this.userData = userData;
    }

    //#region localStorage
    get userData()
    {
        return this.sessionStorage.readJSON("user-data");
    }

    set userData(value)
    {
        this.sessionStorage.writeJSON("user-data", value);
    }

    createHeaders(authorization, hasBody = true)
    {
        let headers = { "Content-Type": "application/json; charset=utf-8" };

        if (authorization === true)
            headers["Authorization"] = "Bearer " + this.token;

        return headers;
    }

    async get(url, authorization)
    {
        let json = {};
        let headers = this.createHeaders(authorization);
        const response = await fetch(url, { headers: headers });
        if (response.status === 200)
        {
            json = await response.json();
        }

        return json;
    }

    async put(url, data, authorization)
    {
        return this.sendData(url, "PUT", data, authorization);
    }

    async post(url, data, authorization)
    {
        return this.sendData(url, "POST", data, authorization);
    }

    /**
     * Delete a specific object
     * @param {String} url
     * @param {Boolean} authorization
     */
    async delete(url, authorization)
    {
        return this.sendData(url, "DELETE", undefined, authorization);
    }

    async sendData(url, method, data, authorization, resultMethod)
    {
        const fetchMethod = method.toUpperCase();

        let headers = this.createHeaders(authorization);
        // "Content-Type": "application/x-www-form-urlencoded"
        let response = await fetch(url, {
            method: fetchMethod, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: headers,
            redirect: "follow", // manual, *follow, err
            referrer: "no-referrer", // no-referrer, *client
            body: fetchMethod !== "GET" && JSON.stringify(data), // body data type must match "Content-Type" header
        });

        let result = {};
        if (response.status === 200)
        {
            result =
                resultMethod && typeof response[resultMethod] === "function"
                    ? await response[resultMethod]()
                    : await response.json();
        }

        return result;
    }

    /**
     * Send File(s) to server
     * @param {string} url
     * @param {object} formData
     * @param {boolean} authorization
     * @param {function} callback not in use
     * @returns Promise
     *
     * @example
     * 	let formData = new FormData();
     *  formData.append(filename, base64 file);
     *  sendFile(<api endpoint>, formData, true);
     */
    async sendFile(url, formData, authorization, callback)
    {
        return new Promise((resolve, reject) =>
        {
            try
            {
                let request = new XMLHttpRequest();

                request.open("POST", url);

                // request.onprogress = callback;
                // request.onloadstart = callback;
                // request.upload.onprogress = callback;

                request.onload = () =>
                {
                    if (request.status === 200)
                    {
                        callback
                            && callback(
                                request.response
                                    ? JSON.parse(request.response)
                                    : {}
                            );
                    }
                    else
                    {
                        callback
                            && callback({
                                success: false,
                                status: request.status,
                            });
                    }
                };

                authorization
                    && request.setRequestHeader(
                        "Authorization",
                        `Bearer ${this.token}`
                    );

                request.send(formData);
            }
            catch (err)
            {
                reject(err);
            }
        });
    }
}

class serverAPI extends webAPI
{
    get dashboardData()
    {
        return this.localStorage.readJSON("dashboard-data");
    }

    set dashboardData(value)
    {
        this.localStorage.writeJSON("dashboard-data", value);
    }

    get languageCode()
    {
        return this.localStorage.readJSON("language-code");
    }

    set languageCode(value)
    {
        this.localStorage.writeJSON("language-code", value);
    }

    set activePropertyTimeZone(timeZone)
    {

        this.localStorage.writeJSON("property-time-zone", { id: timeZone });
    }

    get activePropertyTimeZone()
    {
        return this.localStorage.readJSON("property-time-zone")?.id ?? "UTC";
    }

    async doesUserHaveAnalyticsPermission()
    {
        try
        {
            const { success } = await this.get("/api/v1/permission", true);
            return success;
        }
        catch (err)
        {
            console.log("permission", err);
            throw err;
        }
    }

    async validateToken()
    {
        return new Promise((resolve, reject) =>
        {
            if (this.token)
            {
                this.post("/api/v1/token", { token: this.token }, false)
                    .then((response) =>
                    {
                        if (!response.token)
                        {
                            reject("token is missing");
                        }
                        else
                        {
                            this.token = response.token;
                            resolve(true);
                        }
                    })
                    .catch((err) => reject(err));
            }
            else
            {
                reject("no token");
            }
        });
    }

    async silentLogin(token)
    {
        return new Promise((resolve, reject) =>
        {
            this.post("/api/v1/silent-login", { token: token }, false)
                .then((userData) =>
                {
                    console.log("silent login ", userData);

                    resolve((this.userData = userData));
                })
                .catch((err) =>
                {
                    console.log("silent login err", err);
                    reject(err);
                });
        });
    }

    /**
     * Updates dashbaord data stored in browser.
     * @param {String} prop - Property to update, "propertyId" "buildingId" "floorId"
     * @param {String} value - New value for the property given.
     */
    handleUpdateDashboardData = (prop, value) =>
    {
        let dashboardData = this.dashboardData;

        if (dashboardData)
        {
            dashboardData[prop] = value;
        }
        else
        {
            dashboardData = { [prop]: value };
        }

        this.dashboardData = dashboardData;
    };

    getHubUrl()
    {
        return this.get("/api/v1/hubUrl").then((response) => response.data);
    }

    async getEnvironmentConstants()
    {
        try
        {
            const result = await this.get(
                "/api/v1/permission/environmentConstants",
                true
            );
            return result;
        }
        catch (err)
        {
            console.log("get environment constants");
        }
    }

    async getCompanyTeams()
    {
        try
        {
            const result = await this.get("/api/v1/permission/teams", true);

            return result;
        }
        catch (err)
        {
            console.log("get company teams err:", err);
        }
    }

    async getDashboards()
    {
        try
        {
            const result = await this.get("/api/v1" + DASHBOARD_API_KEY, true);
            return parseApiDataToDashboard(result);
        }
        catch (err)
        {
            console.log(`get ${DASHBOARD_API_KEY} err:  ${err}`);
        }
    }

    async updateDashboards(dashboards)
    {
        try
        {
            const result = await this.post(
                "/api/v1" + DASHBOARD_API_KEY,
                dashboards,
                true
            );
            return result;
        }
        catch (err)
        {
            console.log(`post ${DASHBOARD_API_KEY} err:  ${err}`);
        }
    }

    async getProperties({ languageCode })
    {
        try
        {
            const result = await this.get(
                `/api/v1/properties?languageCode=${languageCode}`,
                true
            );

            return result;
        }
        catch (err)
        {
            console.log("get properties err:", err);
        }
    }

    async getMapData(propertyId, buildingId)
    {
        try
        {
            const result = await this.get(
                `/api/v1/properties/mapData/${propertyId}/${buildingId}`,
                true
            );
            return result;
        }
        catch (err)
        {
            console.log("get heatmap err:", err);
        }
    }

    async getMapOverlays(propertyId, buildingId, floorId)
    {
        try
        {
            const result = await this.get(
                `/api/v1/properties/mapOverlays/${propertyId}/${buildingId}/${floorId}`,
                true
            );

            return { success: !!result.data, data: result.data };
        }
        catch (err)
        {
            console.log(`getMapOverlays err:  ${err}`);
        }
    }
    /**
     * Retrieves overlay templates for a specific property.
     * @param {string} propertyId - The ID of the property.
     * @returns {Object} An object containing success status and data.
     */
    async getOverlayTemplates(propertyId)
    {
        try
        {
            const result = await this.get(`/api/v1/properties/overlayTemplates/${propertyId}`, true);

            return { success: !!result.data, data: result.data };
        }
        catch (err)
        {
            console.log(`getTemplates err:  ${err}`);
            return { success: false, data: null };
        }
    }

    async getBulkMapData(propertyId, buildingIdArray)
    {
        try
        {
            const buildingIds = buildingIdArray.join();
            const result = await this.get(
                `/api/v1/properties/bulkMapData/${propertyId}/${buildingIds}`,
                true
            );
            return result;
        }
        catch (err)
        {
            console.log("get bulk map data err");
        }
    }

    async getWebEngagementData(filter, apiName, environmentConstants)
    {
        try
        {
            const urlParams = filterToUrlParams(
                { ...filter },
                environmentConstants.NAVIGATION_ANALYTICS_API_KEY
            );

            const result = await this.get(
                `/api/v1/${apiName}${urlParams}`,
                true
            );

            return { success: !!result, data: result };
        }
        catch (err)
        {
            console.log("WebEngagement api not ok", err);
        }
    }

    formatDateString(dateString)
    {
        const parsedDate = new Date(dateString);

        // Step 2: Format the date components
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
        const day = String(parsedDate.getDate()).padStart(2, "0");

        // Combine the components into the desired format
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    /**
     *
     * @param {Array} location - [latt, long]
     * @param {Object} dateRange - {startDate, endDate}
     * @returns daily weather data for the location and date range provided
     */
    async findDaysDifference(start, end)
    {
        let difference = new Date(end) - new Date(start);
        return difference / (1000 * 60 * 60 * 24);
    }
    async getWeatherData(location, dateRange)
    {
        try
        {
            let { startDate, endDate } = dateRange;
            let difference = await this.findDaysDifference(startDate, endDate);
            let startTime = this.formatDateString(startDate);
            let endTime = this.formatDateString(endDate);
            const result = await this.get(
                `/api/v1/weather/weather-info/?lng=${location[0]}&lat=${location[1]
                }&start=${startTime}&end=${endTime}&diff=${Math.ceil(
                    difference
                )}`,
                true
            );

            return result;
        }
        catch (err)
        {
            console.log(`get weather data err"  ${err}`);
        }
    }

    async getNavigationAnalyticsData(filter, apiName, environmentConstants)
    {
        try
        {
            const urlParams = filterToUrlParams(
                { ...filter },
                environmentConstants.NAVIGATION_ANALYTICS_API_KEY
            );

            const result = await axios
                .get(
                    `${environmentConstants.NAVIGATION_API}/analytics/${apiName}/${urlParams}`
                );
            return { success: !!result.data, data: result.data };
        }
        catch (err)
        {


            console.error(`Post Analytics Navigation Data err ----   ${apiName} --- error`, err);

            return { success: false, data: null };
        }
    }



    async getNavigationAnalyticsDataForAlerts(filter, apiName, environmentConstants)
    {
        try
        {
            const urlParams = filterToUrlParams(
                { ...filter },
                environmentConstants.NAVIGATION_ANALYTICS_API_KEY
            );

            const { data } = await axios
                .get(
                    `${environmentConstants.NAVIGATION_API}/analytics/${apiName}/${urlParams}`
                );
            return { success: !!data, data };
        }
        catch (err)
        {


            console.error(`Post Analytics Navigation Data err ----   ${apiName} --- error`, err);

            return { success: false, data: null };
        }
    }

    async postNavigationAnalyticsData(body = {}, apiName, environmentConstants)
    {
        try
        {
            body.api_key = environmentConstants.NAVIGATION_ANALYTICS_API_KEY;
            const result = await axios.post(`${environmentConstants.NAVIGATION_API}/analytics/${apiName}`, body);

            return { success: !!result?.data, data: result?.data };
        }
        catch (err)
        {
            console.error(`Post Analytics Navigation Data err ---- ${apiName} ----`, err);
            return { success: false, data: null };
        }
    }


    async getNavigationAnalyticsBoostData(filter, apiName, environmentConstants)
    {
        try
        {
            const urlParams = filterToUrlParams({ ...filter }, environmentConstants.NAVIGATION_ANALYTICS_API_KEY);

            const result = await axios
                .get(
                    `${environmentConstants.NAVIGATION_API}/analytics/${apiName}${urlParams}`
                );

            return { success: !!result.data, data: result.data };
        }
        catch (err)
        {
            console.error(`get ${apiName} err:  ${err}`);
            return { success: false, data: null };
        }
    }

    // // used for testing only, calls local navigation
    // async getNavigationAnalyticsDataLocal (filter, widget, environmentConstants)
    // {
    //     try
    //     {
    //         const urlParams = filterToUrlParams({ ...filter }, environmentConstants.NAVIGATION_ANALYTICS_API_KEY);

    //         const result = await axios
    //             .get(`${environmentConstants.NAVIGATION_API_LOCAL}/analytics/${widget}/${urlParams}`);

    //         return { success: !!result.data, data: result.data };
    //     }
    //     catch (err)
    //     {
    //         console.log(`get ${widget} err:  ${err}`);
    //     }
    // }

    async postTagsInfoUpdateData(body = {}, apiName, environmentConstants)
    {
        try
        {
            body.api_key = environmentConstants.NAVIGATION_ANALYTICS_API_KEY;
            const result = await axios
                .post(`${environmentConstants.NAVIGATION_API}/${apiName}`, body);
            return { success: true, data: result };
        }
        catch (err)
        {
            console.error(`post ${apiName} error`, err);
            return { success: false, data: null };
        }
    }




}

export default new serverAPI();
