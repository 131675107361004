import
{
    add,
    addHours,
    differenceInCalendarMonths,
    differenceInHours, endOfWeek,
    startOfDay,
    startOfWeek
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { capitalize } from 'lodash';
import { DateTime, Info } from 'luxon';
import
{
    calculatePercentageOfChange,
    roundWithPrecision,
} from 'mapsted.utils/numbers';
import { v4 as uuid_v4 } from 'uuid';
import
{
    MAX_LENGTH_DESTINATION_WIDGET_LABEL,
    NEW_VS_RETURNING_USER_IDS,
    WEATHER_CODE_HASH_MAP,
    WEB_ENGAGEMENT__SESSION_APP,
    WEB_ENGAGEMENT__SESSION_SOURCE,
} from '../_constants/chartConstants';
import
{
    DATE_GROUP_FORMAT,
    DATE_GROUP_RANGE,
    ZONE_POI_CHART_THRESHOLD
} from '../_constants/constants';
import
{
    formatDate,
    formatDatesInObjectList_UnixSeconds,
    formatMinsToHHMMSS, getDateRangeDiffrence,
    getFormattedDates,
    i18Format,
    unixSecondsToTimeZoneDate
} from './date.utils';
import { createListOfColors } from './misc.utils';
import
{
    breakDownHoursToWDH,
    breakDownMinsToHMS,
    calcualtePercentage,
    prepareFilteredDataWithPercentageThreshold,
    shuffleArray,
} from './utils';
import { getAdjustedSearchTypesForUi } from './webEngagement.utils';
import { deepCopy } from 'mapsted.utils/objects';

const BAR_CHART_KEY = uuid_v4();

/*
 *   processing functions for widgets, and chart data.
 */

/**
 *
 * @param {*} object
 * @param {*} object.returningUsers
 * @param {*} object.newUsers
 * @param {*} object.propertyId - for chart ID
 * @param {*} object.dateRange
 * @returns {Object}  {newUsersPercentage, returningUsersPercentage, chartData}
 */
export const processUsersVsTimeLineData = ({
    returningUsers,
    newUsers,
    dateRange,
    timeZone,
}) =>
{
    if (!Array.isArray(returningUsers) && !Array.isArray(newUsers))
    {
        return;
    }

    // GET DATE RANGE CONSTANT DATA TO HELP CREATE MAP
    const { startDate, endDate } = dateRange;

    let returningUserMap = new Map();
    let newUserMap = new Map();
    let avgUserMap = new Map();

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    // CREATE DATE MAP
    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    if (
        dateGroupFormat === DATE_GROUP_FORMAT.MONTHS ||
        dateGroupFormat === DATE_GROUP_FORMAT.DAYS
    )
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formatedDate = i18Format(date, dateGroupFormat);

        returningUserMap.set(formatedDate, {
            label: 'Repeat',
            x: formatedDate,
            y: 0,
        });

        newUserMap.set(formatedDate, {
            label: 'New',
            x: formatedDate,
            y: 0,
        });

        avgUserMap.set(formatedDate, {
            label: 'Average',
            x: formatedDate,
            y: 0,
        });

        date = add(date, dateGroupRange);
    }

    // CREATE MAP FOR NEW AND RETURNING USERS
    const dateKey = 'startTime';

    const newUserFormatedDates = getFormattedDates(
        newUsers,
        dateKey,
        dateGroupFormat,
        timeZone
    );
    const returningUserFormatedDates = getFormattedDates(
        returningUsers,
        dateKey,
        dateGroupFormat,
        timeZone
    );

    newUserMap = mapUserFormatedDates(newUserFormatedDates, newUserMap);
    returningUserMap = mapUserFormatedDates(
        returningUserFormatedDates,
        returningUserMap
    );

    // GET PERCENTAGES
    const totalUsers =
        newUserFormatedDates.length + returningUserFormatedDates.length;
    const newUsersPercentage = calcualtePercentage(
        newUserFormatedDates.length,
        totalUsers
    );
    const returningUsersPercentage = calcualtePercentage(
        returningUserFormatedDates.length,
        totalUsers
    );

    const avgUserCount = roundWithPrecision(totalUsers / (rangeGroupDif * 2));

    // CREATE AVG MAP
    for (let key of avgUserMap.keys())
    {
        let mapValue = avgUserMap.get(key);
        mapValue.y = avgUserCount;
        avgUserMap.set(key, mapValue);
    }

    // CREATE LINE DATA
    const newUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.NEW + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.NEW,
        data: Array.from(newUserMap.values()),
    };

    const returningUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.REPEAT + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.REPEAT,
        data: Array.from(returningUserMap.values()),
    };

    const avgUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.AVERAGE + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.AVERAGE,
        data: Array.from(avgUserMap.values()),
    };

    return {
        newUsersPercentage,
        returningUsersPercentage,
        chartData: [avgUserLineData, returningUserLineData, newUserLineData],
        timeZone: timeZone?.id || 'UTC',
    };
};

/**
 *
 * @param {*} modelCounts
 * @returns  {Object} { commonlyUsedDeviceBarData, mostUsedModel }
 */
export const processCommonlyUsedDevices = ({ modelCounts }) =>
{
    let commonlyUsedDeviceBarData = [];
    let mostUsedModel = {};

    if (Array.isArray(modelCounts))
    {
        // sort in ascending order so that it displays correctly on bar chart
        modelCounts.sort((a, b) => a.userCount - b.userCount);

        let userCountTotal = 0;

        // create bar chart data
        modelCounts.forEach((modelCount) =>
        {
            if (modelCount.modelName)
            {
                commonlyUsedDeviceBarData.push({
                    modelName: modelCount.modelName,
                    Users: modelCount.userCount,
                });
            }

            userCountTotal += modelCount.userCount;
        });

        // get most used model for extra info display
        modelCounts = modelCounts.filter((model) => model.modelName.length > 0);
        mostUsedModel = { ...modelCounts[modelCounts.length - 1] };

        // calculate percentage of users using the most used model
        mostUsedModel.percentageOfUsers = calcualtePercentage(
            mostUsedModel.userCount,
            userCountTotal
        );

        if (
            modelCounts.length > 1 &&
            modelCounts[modelCounts.length - 2].userCount ===
            mostUsedModel.userCount
        )
        {
            mostUsedModel = undefined;
        }
    }

    return { commonlyUsedDeviceBarData, mostUsedModel };
};

/**
 * Processing trajectoryTress to a list of plottable sanky chart data that can be used.
 * @param {*} object - response from nav api
 * @param {*} object.zoneFlows
 */
export const processZoneFlows = (
    { trajectoryTrees },
    selectedZoneGeofenceIds
) =>
{
    let sankyChartDataList = [];

    const createSankyChartDataRecursive = ({
        sankyNodes = [],
        sankyLinks = [],
        trajectoryTree,
        parent,
        chartIdx,
    }) =>
    {
        // if tree is empty return
        if (!trajectoryTree)
        {
            return { sankyNodes, sankyLinks };
        }

        let zoneGeofenceLabel = trajectoryTree.zoneGeofenceLabel;
        let zoneGeofenceLabelConcat =
            zoneGeofenceLabel.length > 10
                ? zoneGeofenceLabel.slice(0, 7) + '...'
                : zoneGeofenceLabel;
        let zoneGeofenceId = `${trajectoryTree.zoneGeofenceId.toString()}-${chartIdx.toString()}`;

        // create id for nodes
        if (sankyNodes.findIndex((n) => n.id === zoneGeofenceId) === -1)
        {
            let labelConcat = '';
            let displayName = '';

            // if selected add checkmark next to displayed names
            if (
                selectedZoneGeofenceIds &&
                selectedZoneGeofenceIds.includes(
                    `${trajectoryTree.zoneGeofenceId}`
                )
            )
            {
                labelConcat = `${String.fromCharCode(parseInt(0x2705))} `;
                displayName = `${String.fromCharCode(parseInt(0x2705))} `;
            }

            labelConcat += zoneGeofenceLabelConcat;
            displayName += zoneGeofenceLabel;

            sankyNodes.push({
                id: zoneGeofenceId,
                name: zoneGeofenceLabel,
                labelConcat: labelConcat,
                displayName: displayName,
            });
        }

        // if parent create a link using parent id with parent count
        if (!!parent)
        {
            const source = `${parent.zoneGeofenceId.toString()}-${chartIdx.toString()}`;
            const target = zoneGeofenceId;
            const value = trajectoryTree.visitCount;

            const existingLinkIdx = sankyLinks.findIndex(
                (link) => link.source === source && link.target === target
            );

            // if a link exists add value to it
            if (existingLinkIdx > -1)
            {
                sankyLinks[existingLinkIdx].value += value;
            }
            // else create a new link
            else
            {
                const sankyLink = {
                    source: `${parent.zoneGeofenceId.toString()}-${chartIdx.toString()}`,
                    target: zoneGeofenceId,
                    value: trajectoryTree.visitCount,
                };

                sankyLinks.push(sankyLink);
            }
        }

        // traverse the tree and add sankyNodes and sankyLinks for each child
        trajectoryTree.nodes.forEach((node) =>
        {
            const { sankyNodes: childSankyNodes, sankyLinks: childSankyLinks } =
                createSankyChartDataRecursive({
                    sankyNodes,
                    sankyLinks,
                    trajectoryTree: node,
                    parent: trajectoryTree,
                    chartIdx,
                });

            sankyNodes = childSankyNodes;
            sankyLinks = childSankyLinks;
        });

        return { sankyNodes, sankyLinks };
    };

    trajectoryTrees.forEach((trajectoryTree, chartIdx) =>
    {
        const visits = trajectoryTree.visitCount;
        const { sankyNodes: nodes, sankyLinks: links } =
            createSankyChartDataRecursive({ trajectoryTree, chartIdx });

        sankyChartDataList.push({ nodes, links, visits });
    });

    return sankyChartDataList;
};

/**
 * process zone traffic data to fit the widget.
 * Time shifts chart data according to timeZone populated from property or building
 * @param {*} data
 * @param {*} timeZone
 * @returns
 */
export const processZoneTraffic = ({
    data,
    timeZone,
    syncMaxDwellMinutes = false,
}) =>
{
    let zoneGeofenceTraffic = JSON.parse(
        JSON.stringify(data.zoneGeofenceTraffic)
    );

    // sort the array from highest userCount to lowest
    let overallPeakHourMax = 0;
    let overallPeakHourZoneLabel = '';
    let overallPeakHourTime = '';
    let tiedPeakHour = false;

    if (Array.isArray(zoneGeofenceTraffic))
    {
        const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;
        const dateGroupRange = DATE_GROUP_RANGE.HOURS;

        // sort from highest userCount to lowest userCount
        zoneGeofenceTraffic.sort(
            (zoneA, zoneB) => zoneB.userCount - zoneA.userCount
        );

        zoneGeofenceTraffic.forEach((zone) =>
        {
            // create chart data
            const { usersPerHour } = zone;
            let maxDwellIndexs = [];
            let maxDwellMinutes = 0.0;
            let usersPerHourArray = [];
            let usersPerHourMap = {};
            let peakHourIdxArray = []; //  there can be multiple peak hours
            let peakHourValue = 0;

            // for each, format date and add to map
            usersPerHour.forEach((hourData) =>
            {
                let date = startOfDay(new Date()); // using local time right now
                date = addHours(date, hourData.hourUTC, date);

                let utcDate = zonedTimeToUtc(date, 'UTC');

                let timeZoneDate = utcToZonedTime(
                    utcDate,
                    timeZone?.id || 'UTC'
                );

                let formatedDate = i18Format(timeZoneDate, dateGroupFormat);
                usersPerHourMap[formatedDate] = {
                    userCount: hourData.userCount,
                    percentage: !!hourData.userCount
                        ? hourData.userCount / zone.userCount
                        : 0,
                    averageDwellMinutes:
                        0 +
                        +roundWithPrecision(
                            parseFloat(hourData?.averageDwellMinutes ?? 0)
                        ),
                };
            });

            // create bar chart data for each hour of the day
            let date = new Date(0, 0, 0);

            for (let i = 0; i < 24; i++)
            {
                let formatedDate = i18Format(date, dateGroupFormat);
                let { userCount, percentage, averageDwellMinutes } =
                    usersPerHourMap[formatedDate] ?? {
                        userCount: 0,
                        percentage: 0,
                        averageDwellMinutes: 0.0,
                    };

                usersPerHourArray.push({
                    time: formatedDate,
                    users: userCount,
                    timeZoneId: timeZone?.id || 'UTC',
                    maxUsers: 0,
                    maxPercentage: 0,
                    averageDwellMinutes: averageDwellMinutes,
                    maxAverageDwellMinutes: 0,
                    percentage: roundWithPrecision(percentage * 100),
                });

                if (userCount > peakHourValue)
                {
                    peakHourValue = userCount;
                    peakHourIdxArray = [i];
                } else if (userCount === peakHourValue)
                {
                    peakHourIdxArray.push(i);
                }

                if (syncMaxDwellMinutes)
                {
                    if (averageDwellMinutes > maxDwellMinutes)
                    {
                        maxDwellIndexs = [];
                        maxDwellMinutes = averageDwellMinutes;
                    }
                    if (maxDwellMinutes === averageDwellMinutes)
                    {
                        maxDwellIndexs.push(i);
                    }
                }
                date = add(date, dateGroupRange);
            }

            // set peak hour for each in the array
            if (peakHourIdxArray.length > 0)
            {
                peakHourIdxArray.forEach((peakHourIdx) =>
                {
                    let maxUsers = usersPerHourArray[peakHourIdx].users;
                    let maxPercentage =
                        usersPerHourArray[peakHourIdx].percentage;
                    usersPerHourArray[peakHourIdx].maxUsers = maxUsers;
                    usersPerHourArray[peakHourIdx].maxPercentage =
                        maxPercentage;
                    usersPerHourArray[peakHourIdx].percentage = 0;
                    usersPerHourArray[peakHourIdx].users = 0;
                    // TODO possible support for multiple zones to be shown in the text section of this widget
                    if (maxUsers > overallPeakHourMax)
                    {
                        overallPeakHourZoneLabel = zone.zoneLabel;
                        overallPeakHourTime =
                            usersPerHourArray[peakHourIdxArray[0]].time;
                        overallPeakHourMax = maxUsers;

                        if (peakHourIdxArray.length > 1)
                        {
                            tiedPeakHour = true;
                        } else
                        {
                            tiedPeakHour = false;
                        }
                    } else if (maxUsers === overallPeakHourMax)
                    {
                        tiedPeakHour = true;
                    }
                });
            }

            if (!!syncMaxDwellMinutes && !!maxDwellIndexs.length)
            {
                maxDwellIndexs.forEach((peakMaxDwellIndex) =>
                {
                    let current = usersPerHourArray[peakMaxDwellIndex];
                    const { averageDwellMinutes } = current;
                    if (maxDwellMinutes === averageDwellMinutes)
                    {
                        current.maxAverageDwellMinutes = averageDwellMinutes;
                        current.averageDwellMinutes = 0;
                    }
                });
            }

            // add bar chart data to zone
            zone.chartData = usersPerHourArray;
            zone.averageDwellMinutes = roundWithPrecision(
                zone.averageDwellMinutes
            );
        });
    }

    if (tiedPeakHour)
    {
        overallPeakHourTime = undefined;
        overallPeakHourZoneLabel = undefined;
    }

    return {
        zoneTrafficArray: zoneGeofenceTraffic,
        overallPeakHourTime,
        overallPeakHourZoneLabel,
    };
};

export const boostProcessZoneTraffic = ({
    data,
    timeZone,
    syncMaxDwellMinutes = false,
}) =>
{
    let zoneGeofenceTraffic = JSON.parse(
        JSON.stringify(data.zoneGeofenceTraffic)
    );

    // sort the array from highest userCount to lowest
    let overallPeakHourMax = 0;
    let overallPeakHourZoneLabel = '';
    let overallPeakHourTime = '';
    let tiedPeakHour = false;

    if (Array.isArray(zoneGeofenceTraffic))
    {
        const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;
        const dateGroupRange = DATE_GROUP_RANGE.HOURS;

        // sort from highest userCount to lowest userCount
        zoneGeofenceTraffic.sort(
            (zoneA, zoneB) => zoneB.userCount - zoneA.userCount
        );

        zoneGeofenceTraffic.forEach((zone) =>
        {
            // create chart data
            const { usersPerHour } = zone;
            let maxDwellIndexs = [];
            let maxDwellMinutes = 0.0;
            let usersPerHourArray = [];
            let usersPerHourMap = {};
            let peakHourIdxArray = []; //  there can be multiple peak hours
            let peakHourValue = 0;

            // for each, format date and add to map
            usersPerHour.forEach((hourData) =>
            {
                let date = startOfDay(new Date()); // using local time right now
                date = addHours(date, hourData.hourUTC, date);

                let utcDate = zonedTimeToUtc(date, 'UTC');

                let timeZoneDate = utcToZonedTime(
                    utcDate,
                    timeZone?.id || 'UTC'
                );

                let formatedDate = i18Format(timeZoneDate, dateGroupFormat);
                usersPerHourMap[formatedDate] = {
                    userCount: hourData.userCount,
                    percentage: hourData.userCountPercentage,
                    averageDwellMinutes:
                        0 +
                        +roundWithPrecision(
                            parseFloat(hourData?.averageDwellMinutes ?? 0)
                        ),
                };
            });

            // create bar chart data for each hour of the day
            let date = new Date(0, 0, 0);

            for (let i = 0; i < 24; i++)
            {
                let formatedDate = i18Format(date, dateGroupFormat);
                let { userCount, percentage, averageDwellMinutes } =
                    usersPerHourMap[formatedDate] ?? {
                        userCount: 0,
                        percentage: 0,
                        averageDwellMinutes: 0.0,
                    };

                usersPerHourArray.push({
                    time: formatedDate,
                    users: userCount,
                    timeZoneId: timeZone?.id || 'UTC',
                    maxUsers: 0,
                    maxPercentage: 0,
                    averageDwellMinutes: averageDwellMinutes,
                    maxAverageDwellMinutes: 0,
                    percentage: roundWithPrecision(percentage * 100),
                });

                if (userCount > peakHourValue)
                {
                    peakHourValue = userCount;
                    peakHourIdxArray = [i];
                } else if (userCount === peakHourValue)
                {
                    peakHourIdxArray.push(i);
                }

                if (syncMaxDwellMinutes)
                {
                    if (averageDwellMinutes > maxDwellMinutes)
                    {
                        maxDwellIndexs = [];
                        maxDwellMinutes = averageDwellMinutes;
                    }
                    if (maxDwellMinutes === averageDwellMinutes)
                    {
                        maxDwellIndexs.push(i);
                    }
                }
                date = add(date, dateGroupRange);
            }

            // set peak hour for each in the array
            if (peakHourIdxArray.length > 0)
            {
                peakHourIdxArray.forEach((peakHourIdx) =>
                {
                    let maxUsers = usersPerHourArray[peakHourIdx].users;
                    let maxPercentage =
                        usersPerHourArray[peakHourIdx].percentage;
                    usersPerHourArray[peakHourIdx].maxUsers = maxUsers;
                    usersPerHourArray[peakHourIdx].maxPercentage =
                        maxPercentage;
                    usersPerHourArray[peakHourIdx].percentage = 0;
                    usersPerHourArray[peakHourIdx].users = 0;
                    // TODO possible support for multiple zones to be shown in the text section of this widget
                    if (maxUsers > overallPeakHourMax)
                    {
                        overallPeakHourZoneLabel = zone.zoneLabel;
                        overallPeakHourTime =
                            usersPerHourArray[peakHourIdxArray[0]].time;
                        overallPeakHourMax = maxUsers;

                        if (peakHourIdxArray.length > 1)
                        {
                            tiedPeakHour = true;
                        } else
                        {
                            tiedPeakHour = false;
                        }
                    } else if (maxUsers === overallPeakHourMax)
                    {
                        tiedPeakHour = true;
                    }
                });
            }

            if (!!syncMaxDwellMinutes && !!maxDwellIndexs.length)
            {
                maxDwellIndexs.forEach((peakMaxDwellIndex) =>
                {
                    let current = usersPerHourArray[peakMaxDwellIndex];
                    const { averageDwellMinutes } = current;
                    if (maxDwellMinutes === averageDwellMinutes)
                    {
                        current.maxAverageDwellMinutes = averageDwellMinutes;
                        current.averageDwellMinutes = 0;
                    }
                });
            }

            // add bar chart data to zone
            zone.chartData = usersPerHourArray;
            zone.averageDwellMinutes = roundWithPrecision(
                zone.averageDwellMinutes
            );
        });
    }

    if (tiedPeakHour)
    {
        overallPeakHourTime = undefined;
        overallPeakHourZoneLabel = undefined;
    }

    return {
        zoneTrafficArray: zoneGeofenceTraffic,
        overallPeakHourTime,
        overallPeakHourZoneLabel,
    };
};

export const processsNumberOfZoneVisitsPerSession = ({
    numberOfZoneGeofencesPerSession,
}) =>
{
    // 0
    // 1-3
    // 4-5
    // 6+
    const MAP_KEYS = {
        NONE: {
            key: '0 Zones',
            max: 0,
        },
        ONE_THREE: {
            key: '1 - 3 Zones',
            min: 1,
            max: 3,
        },
        FOUR_SIX: {
            key: '4 - 6 Zones',
            min: 4,
            max: 6,
        },
        SEVEN_TEN: {
            key: '7 - 10 Zones',
            min: 7,
            max: 10,
        },
        ELEVEN_THIRTEEN: {
            key: '11 - 13 Zones',
            min: 11,
            max: 13,
        },
        FOURTEEN_SIXTEEN: {
            key: '14 - 16 Zones',
            min: 14,
            max: 16,
        },
        SEVENTEEN_TWENTY: {
            key: '17 - 20 Zones',
            min: 17,
            max: 20,
        },
        TWENTYONE_PLUS: {
            key: '21+ Zones',
            min: 21,
        },
    };

    // Initialize map
    let numberOfZoneVisitsPerSessionMap = {};

    Object.values(MAP_KEYS).forEach((option) =>
    {
        numberOfZoneVisitsPerSessionMap[option.key] = 0;
    });

    let totalNumberOfSessions = 0;

    if (Array.isArray(numberOfZoneGeofencesPerSession))
    {
        numberOfZoneGeofencesPerSession.forEach((data) =>
        {
            const { numberZoneGeofences, numberSessions } = data;

            let option = Object.values(MAP_KEYS).find((option) =>
            {
                if (option.max === 0 && numberZoneGeofences === 0)
                {
                    return true;
                } else if (!option.max && numberZoneGeofences >= option.min)
                {
                    return true;
                } else if (
                    numberZoneGeofences >= option.min &&
                    numberZoneGeofences <= option.max
                )
                {
                    return true;
                }

                return false;
            });

            if (!!option)
            {
                numberOfZoneVisitsPerSessionMap[option.key] += numberSessions;
                totalNumberOfSessions += numberSessions;
            }
        });
    }

    return totalNumberOfSessions > 0 && numberOfZoneVisitsPerSessionMap;
};

export const processZoneGeofencePoiVisits = (
    propertyPoiVisitsPerZoneGeofence,
    isPropertyLevel
) =>
{
    let sunburstChartData = {
        id: 'propertyPoiVisits',
        name: 'propertyPoiVisits',
        children: [],
    };

    let maxVisitName = '';
    let maxVisitCount = 0;
    let totalCount = 0;

    let isTie = false;

    if (!propertyPoiVisitsPerZoneGeofence)
    {
        return sunburstChartData;
    }

    totalCount =
        propertyPoiVisitsPerZoneGeofence.buildingPoiVisitsPerZoneGeofence.reduce(
            (acc, b) => acc + +b.buildingVisitCount,
            0
        );

    // for each layer create children
    propertyPoiVisitsPerZoneGeofence.buildingPoiVisitsPerZoneGeofence.forEach(
        (buildingPoiVisit) =>
        {
            // TODO add building data on property level view
            let buildingChild;

            if (isPropertyLevel)
            {
                buildingChild = {
                    id: `building - ${buildingPoiVisit.buildingId}`,
                    name: buildingPoiVisit.buildingName,
                    parentName: buildingPoiVisit.buildingName,
                    children: [],
                };
            }

            let buildingParentName = buildingPoiVisit.buildingName;

            buildingPoiVisit.floorPoiVisitsPerZoneGeofence.forEach(
                (floorPoiVisit) =>
                {
                    let floorParentName =
                        buildingParentName + ` -> ${floorPoiVisit.floorName}`;

                    let floorChild = {
                        id: `floor - ${floorPoiVisit.floorId}`,
                        name: floorPoiVisit.floorName,
                        parentName: floorParentName,
                        children: [],
                    };

                    floorPoiVisit.zoneGeofencePoiVisits.forEach(
                        (zonePoiVisit) =>
                        {
                            let zoneParentName =
                                floorParentName +
                                ` -> ${zonePoiVisit.zoneGeofenceLabel}`;

                            let zoneChild = {
                                id: `${floorChild.id} - ${zonePoiVisit.zoneGeofenceLabel}`,
                                name: zonePoiVisit.zoneGeofenceLabel,
                                parentName: zoneParentName,
                                children: [],
                            };

                            const {
                                filteredData: filteredZonePoiVisitPoiVisits,
                                skippedItemsTotal,
                            } = prepareFilteredDataWithPercentageThreshold(
                                zonePoiVisit.poiVisits,
                                'poiVisitCount',
                                totalCount,
                                ZONE_POI_CHART_THRESHOLD
                            );

                            if (skippedItemsTotal > 0)
                            {
                                filteredZonePoiVisitPoiVisits.push({
                                    poiName: 'Others',
                                    poiVisitCount: skippedItemsTotal,
                                });
                            }

                            filteredZonePoiVisitPoiVisits.forEach(
                                (poiVisit) =>
                                {
                                    let poiChild = {
                                        id: `${zoneChild.id} - ${poiVisit.poiName}`,
                                        name: poiVisit.poiName,
                                        visits: poiVisit.poiVisitCount,
                                    };

                                    if (
                                        poiVisit.poiVisitCount > maxVisitCount
                                    )
                                    {
                                        maxVisitCount = poiVisit.poiVisitCount;
                                        maxVisitName = poiVisit.poiName;
                                        isTie = false;
                                    } else if (
                                        poiVisit.poiVisitCount === maxVisitCount
                                    )
                                    {
                                        isTie = true;
                                    }

                                    zoneChild.children.push(poiChild);
                                }
                            );

                            floorChild.children.push(zoneChild);
                        }
                    );

                    if (isPropertyLevel)
                    {
                        buildingChild.children.push(floorChild);
                    } else
                    {
                        sunburstChartData.children.push(floorChild);
                    }
                }
            );

            if (isPropertyLevel)
            {
                sunburstChartData.children.push(buildingChild);
            }
        }
    );

    let mostVisitedPoiPercentage = 0;

    if (!isTie && totalCount !== 0)
    {
        mostVisitedPoiPercentage = calcualtePercentage(
            maxVisitCount,
            totalCount
        );
    }

    return {
        sunburstChartData,
        mostVisitedPoi: !isTie && maxVisitName,
        mostVisitedPoiPercentage,
        totalCount,
    };
};

export const processCategoryWidget = ({ categorySearchedVsVisitedRecords }) =>
{
    let categoryBarData = [];
    let bestCategory = undefined;

    if (
        Array.isArray(categorySearchedVsVisitedRecords) &&
        categorySearchedVsVisitedRecords.length > 0
    )
    {
        let combineDuplicatesRecersiveInternal = (
            array,
            processedArray = []
        ) =>
        {
            if (array.length === 0)
            {
                return processedArray;
            }

            let poppedRecord = array.pop();

            // get filterd arrays
            let duplicateArray = array.filter(
                (record) => record.category === poppedRecord.category
            );
            let filteredArray = array.filter(
                (record) => record.category !== poppedRecord.category
            );

            // combine duplicate values
            duplicateArray.forEach((record) =>
            {
                poppedRecord.numSearched += record.numSearched;
                poppedRecord.numVisited += record.numVisited;
            });

            // add popped record to processed record
            processedArray.push(poppedRecord);

            return combineDuplicatesRecersiveInternal(
                filteredArray,
                processedArray
            );
        };

        let processedRecords = combineDuplicatesRecersiveInternal(
            JSON.parse(JSON.stringify(categorySearchedVsVisitedRecords))
        );

        // sort in ascending order so that it displays correctly on bar chart
        processedRecords.sort((a, b) =>
        {
            let sortValue =
                a.numSearched + a.numVisited - (b.numSearched + b.numVisited);

            // if tie sort by visited
            if (sortValue === 0)
            {
                sortValue = a.numVisited - b.numVisited;
            }

            return sortValue;
        });

        // get most visited and searched
        bestCategory = processedRecords[processedRecords.length - 1];

        let secondBestCategory = processedRecords[processedRecords.length - 2];

        // if there is a tie don't show text
        if (
            secondBestCategory &&
            bestCategory.numVisited + bestCategory.numSearched ===
            secondBestCategory.numVisited + secondBestCategory.numSearched
        )
        {
            bestCategory = undefined;
        }

        // get bar data
        processedRecords.forEach((record) =>
        {
            const { category, numSearched, numVisited } = record;

            categoryBarData.push({
                category,
                Searched: numSearched,
                Visited: numVisited,
            });
        });
    }

    return {
        categoryBarData,
        mostVisitedAndSearchedCategory: bestCategory?.category,
    };
};

/**
 * Takes raw screen resolution data and formats it to nivo bar data
 * @param {*} object - result from screen resolution widget api
 * @param {*} object.screenResolutions - array of screen resolution data
 */
export const processScreenResolutionWidget = ({ screenResolutions }) =>
{
    // for each screen resolution, create y axis "width x height" and x axis count




    let resolutionBarData = [];
    let maxResolution = undefined;
    let maxCount = 0;
    let isTie = false;
    const resoultionUniqeKey = ({ screenPxWidth, screenPxHeight }) => `${screenPxWidth} x ${screenPxHeight}`;


    if (screenResolutions?.length)
    {


        const processedRescoultions = screenResolutions.reduce((acc, screenResolution) =>
        {
            const key = resoultionUniqeKey(screenResolution);

            if (!acc[key])
            {
                acc[key] = screenResolution;
            } else
            {
                acc[key].count += screenResolution.count;
            }
            return acc;
        }, {});

        screenResolutions = Object.values(processedRescoultions);

        screenResolutions = screenResolutions.sort((a, b) => a.count - b.count);

        screenResolutions.forEach((screenResolution) =>
        {
            const { count } = screenResolution;
            const resolutionName = resoultionUniqeKey(screenResolution);

            resolutionBarData.push({
                id: uuid_v4(),
                resolution: resolutionName,
                users: count,
            });

            if (count > maxCount)
            {
                maxCount = count;
                maxResolution = resolutionName;
                isTie = false;
            } else if (count === maxCount)
            {
                isTie = true;
            }
        });
    }

    return { resolutionBarData, maxResolution: !isTie && maxResolution };
};

export const processServiceProviderWidget = ({ serviceProviders }) =>
{
    let serviceProviderPieData = [];

    let maxServiceProvider = undefined;
    let maxCount = 0;
    let isTie = 0;
    let totalCount = 0;

    serviceProviders.forEach((sp) => (totalCount += sp.count));

    const { filteredData, skippedItemsTotal } =
        prepareFilteredDataWithPercentageThreshold(
            serviceProviders,
            'count',
            totalCount
        );
    if (skippedItemsTotal > 0)
    {
        filteredData.push({
            serviceProvider: 'Others',
            count: skippedItemsTotal,
        });
    }

    // find unkown
    filteredData.forEach((sp) =>
    {
        let { serviceProvider, count } = sp;

        if (serviceProvider === '')
        {
            serviceProvider = 'Unknown Provider';
        }

        serviceProviderPieData.push({
            id: serviceProvider,
            label: serviceProvider,
            value: count,
            percentage: calcualtePercentage(count, totalCount),
        });

        if (count > maxCount)
        {
            maxServiceProvider = serviceProvider;
            maxCount = count;
            isTie = false;
        } else if (count === maxCount)
        {
            isTie = true;
        }
    });

    return {
        maxServiceProvider: !isTie && maxServiceProvider,
        serviceProviderPieData,
    };
};

/**
 * @param {Object} parm0 -  containing information needed to process the data for the widget
 * @param {Array} param0.data
 * @param {Array} param0.compareData
 * @param {Object} param0.dateRange - containg startTime and endTime
 * @param {Object} param0.timeZone - containting id (time zone id)
 */
export const processUniqueSessionsVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    if (data.length === 0)
    {
        return {};
    }

    let barData = [];
    let userMap = {}; // used for total unique users
    let compareUserMap = {};

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            label: formattedDate,
            value: 0,
            userHash: {}, // hash of unique users
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    let formattedData = formatDatesInObjectList_UnixSeconds(
        data,
        'startUnixTime_s',
        'formattedStartTime',
        dateGroupFormat,
        timeZone
    );

    formattedData.forEach((session) =>
    {
        let key = session.formattedStartTime;
        let userUID = session.userUID;

        let mapValue = dateMap.get(key);

        userMap[userUID] = true;

        // if user did not already had a session in this time period
        if (mapValue && !mapValue.userHash[userUID])
        {
            mapValue.userHash[userUID] = true;
            mapValue.value++;

            dateMap.set(key, mapValue);
        }
    });

    compareData.forEach((session) =>
    {
        let userUID = session.userUID;
        compareUserMap[userUID] = true;
    });

    // process mapiterator into array with non zero values
    barData = Array.from(dateMap.values()).filter(obj => obj.value !== 0);
    let totalUniqueUsers = Object.keys(userMap).length;
    let compareTotalUniqueUsers = Object.keys(compareUserMap).length;

    let percentageOfChange = calculatePercentageOfChange(
        totalUniqueUsers,
        compareTotalUniqueUsers
    );

    return { barData, totalUniqueUsers, percentageOfChange };
};

/**
 * @param {Object} parm0 -  containing information needed to process the data for the widget
 * @param {Array} param0.data
 * @param {Array} param0.compareData
 * @param {Object} param0.dateRange - containg startTime and endTime
 * @param {Object} param0.timeZone - containting id (time zone id)
 */
export const processSessionDurationVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    if (data.length === 0)
    {
        return {};
    }

    let lineData = [];
    let totalDurationMin = 0;
    let compareTotalDurationMin = 0;

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            x: formattedDate,
            y: 0, // avg duration mins
            totalUserSessions: 0, // total user sessions
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    formatDatesInObjectList_UnixSeconds(
        data,
        'startUnixTime_s',
        'formattedStartTime',
        dateGroupFormat,
        timeZone
    );

    data.forEach((session) =>
    {
        let key = session.formattedStartTime;
        let durationMin = session.durationMin;

        let mapValue = dateMap.get(key);

        if (mapValue)
        {
            // if user did not already had a session in this time period

            totalDurationMin += durationMin;

            mapValue.y += durationMin;
            mapValue.totalUserSessions++;

            dateMap.set(key, mapValue);
        }
    });

    compareData.forEach((session) =>
    {
        compareTotalDurationMin += session.durationMin;
    });

    lineData = Array.from(dateMap.values());

    lineData.forEach((data) =>
    {
        if (data.y !== 0)
        {
            data.y = data.y / data.totalUserSessions;
        }

        delete data.totalUserSessions;
    });

    lineData = [
        {
            id: 'Average Duration' + uuid_v4(),
            data: lineData,
        },
    ];

    let averageDuration = totalDurationMin / data.length;
    let compareAverageDuration = compareTotalDurationMin / compareData.length;

    let percentageOfChange = calculatePercentageOfChange(
        averageDuration,
        compareAverageDuration
    );

    let averageDurationFormatted = formatDurationHMS(averageDuration);

    return { lineData, averageDurationFormatted, percentageOfChange };
};

/**
 * @param {Object} parm0 -  containing information needed to process the data for the widget
 * @param {Array} param0.data
 * @param {Array} param0.compareData
 * @param {Object} param0.dateRange - containg startTime and endTime
 * @param {Object} param0.timeZone - containting id (time zone id)
 */
export const processAppUsageVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    if (data.length === 0)
    {
        return {};
    }

    let lineData = [];

    // to mark unique users
    let userHash = {};
    let compareUserHash = {};

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            x: formattedDate,
            y: 0, // avg duration mins
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    formatDatesInObjectList_UnixSeconds(
        data,
        'startUnixTime_s',
        'formattedStartTime',
        dateGroupFormat,
        timeZone
    );

    data.forEach((session) =>
    {
        let key = session.formattedStartTime;

        let mapValue = dateMap.get(key);

        // if user did not already had a session in this time period

        if (mapValue)
        {
            mapValue.y++;

            dateMap.set(key, mapValue);

            userHash[session.userUID] = true;
        }
    });

    compareData.forEach((session) =>
    {
        compareUserHash[session.userUID] = true;
    });

    const avgSessionsOpensPerUser =
        Math.round(data.length / Object.keys(userHash).length) || 0;
    const compareAvgSessionOpensPerUser =
        Math.round(compareData.length / Object.keys(compareUserHash).length) ||
        0;
    const percentageOfChange = calculatePercentageOfChange(
        avgSessionsOpensPerUser,
        compareAvgSessionOpensPerUser
    );

    lineData = Array.from(dateMap.values());

    lineData = [
        {
            id: 'Total Sessions' + uuid_v4(),
            data: lineData,
        },
    ];

    return { lineData, avgSessionsOpensPerUser, percentageOfChange };
};

export const downloadVsTimeWidgetHelper = ({
    data,
    dateRange,
    compareDateRange,
}) =>
{
    // go through all time data and create two values
    // data with data from the date range
    // compareData with data from the compare date range

    let compareData = [];

    let unixStart = Math.floor(dateRange.startDate.getTime() / 1000);
    let unixEnd = Math.floor(dateRange.endDate.getTime() / 1000);

    let compareUnixStart = Math.floor(
        compareDateRange.startDate.getTime() / 1000
    );
    let compareUnixEnd = Math.floor(compareDateRange.endDate.getTime() / 1000);

    data.forEach((app) =>
    {
        let launchTimestampsUnixSec = [];
        let compareLaunchTimestampsUnixSec = [];

        let compareApp = {
            appName: app.appName,
        };

        app.launchUnixTime_s.forEach((timeStamp_s) =>
        {
            if (
                timeStamp_s >= compareUnixStart &&
                timeStamp_s <= compareUnixEnd
            )
            {
                compareLaunchTimestampsUnixSec.push(timeStamp_s);
            } else if (timeStamp_s >= unixStart && timeStamp_s <= unixEnd)
            {
                launchTimestampsUnixSec.push(timeStamp_s);
            }
        });

        app.launchUnixTime_s = launchTimestampsUnixSec;
        app.numberOfDownloads = launchTimestampsUnixSec.length;

        compareApp.launchUnixTime_s = compareLaunchTimestampsUnixSec;
        compareApp.numberOfDownloads = compareLaunchTimestampsUnixSec.length;
        compareData.push(compareApp);
    });

    return {
        data: data,
        compareData: compareData,
    };
};

export const processDownloadsVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    let downloadTimeStamps = [];
    let totalDownloads = 0;
    let totalCompareDownloads = 0;

    data.forEach((app) =>
    {
        downloadTimeStamps = downloadTimeStamps.concat(app.launchUnixTime_s);
        totalDownloads += app.numberOfDownloads;
    });

    if (totalDownloads === 0)
    {
        return {};
    }

    compareData.forEach((app) =>
    {
        totalCompareDownloads += app.numberOfDownloads;
    });

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            x: formattedDate,
            y: 0, // avg duration mins
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    downloadTimeStamps.forEach((timeStamp, i) =>
    {
        let date = unixSecondsToTimeZoneDate(timeStamp, timeZone?.id || 'UTC');

        downloadTimeStamps[i] = i18Format(date, dateGroupFormat);
    });

    downloadTimeStamps.forEach((timeStamp) =>
    {
        let key = timeStamp;

        let mapValue = dateMap.get(key);

        if (mapValue)
        {
            mapValue.y++;
            dateMap.set(key, mapValue);
        }
    });

    let lineData = Array.from(dateMap.values());

    lineData = [
        {
            id: 'Total Downloads' + uuid_v4(),
            data: lineData,
        },
    ];

    let percentageOfChange = calculatePercentageOfChange(
        totalDownloads,
        totalCompareDownloads
    );

    return { lineData, percentageOfChange, totalDownloads };
};

/**
 * Takes engagement overview data and creates weekly retention rates
 * @param {*} param0
 * @param {Array} param0.data
 * @param {Object} param0.dateRange
 * @param {Object} param0.timeZone
 * @returns
 */
export const processRetentionRateWidget = ({
    data,
    dateRange,
    timeZone,
    trans,
}) =>
{
    /*
        weeklyRetntionArray = [
            retentionData,
            retentionData,
            ...
        ]
        retentionData = {
            formattedDate,
            formattedWeek,
            uniqueUsers,
            uniqueUsersCount,
            lineData,
            weeklyRetentionRate,
            weeklyRetentionUsers
        }
    */
    const { startDate, endDate } = dateRange;

    const dateGroupFormat = DATE_GROUP_FORMAT.WEEKS;
    const dateGroupRange = DATE_GROUP_RANGE.WEEKS;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    let weeklyRetentionArray = [];

    let date = startDate;

    formatDatesInObjectList_UnixSeconds(
        data,
        'startUnixTime_s',
        'formattedStartTime',
        dateGroupFormat,
        timeZone
    );

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let startOfWeekDate = startOfWeek(date);
        let endOfWeekDate = endOfWeek(date);

        let formattedWeek = `${formatDate(startOfWeekDate)} - ${formatDate(
            endOfWeekDate
        )}`;
        let formattedDate = i18Format(date, dateGroupFormat);
        let retentionData = { formattedDate, formattedWeek };

        // find unique users for this weeks data
        let week0Users = data
            .filter((session) => session.formattedStartTime === formattedDate)
            .map((week0Session) => week0Session.userUID)
            .filter(
                (week0UserUID, i, self) => self.indexOf(week0UserUID) === i
            );

        retentionData.uniqueUsers = week0Users;
        retentionData.uniqueUsersCount = week0Users.length;

        // add retention data to weeklyRetentionArray
        weeklyRetentionArray.push(retentionData);

        date = add(date, dateGroupRange);
    }

    weeklyRetentionArray.forEach((retentionData, i) =>
    {
        let { uniqueUsersCount, uniqueUsers } = retentionData;

        let weeklyRetentionRate = [];
        let weeklyRetentionUsers = [];

        for (let j = i + 1; j < weeklyRetentionArray.length; j++)
        {
            if (uniqueUsersCount === 0)
            {
                weeklyRetentionRate.push(0);
                weeklyRetentionUsers.push(0);
            } else
            {
                let weeklyUsers = weeklyRetentionArray[j].uniqueUsers;
                let retentionUsers = weeklyUsers.filter((userUID) =>
                    uniqueUsers.includes(userUID)
                );
                let retentionUsersCount = retentionUsers.length;

                weeklyRetentionUsers.push(retentionUsersCount);

                if (retentionUsersCount === 0)
                {
                    weeklyRetentionRate.push(0);
                } else
                {
                    weeklyRetentionRate.push(
                        roundWithPrecision(
                            (retentionUsersCount / uniqueUsersCount) * 100
                        )
                    );
                }
            }
        }

        let chartData = weeklyRetentionRate.map((retentionRate, i) => ({
            x: trans('chart.Week') + ` ${i + 1}`,
            y: retentionRate,
            userCount: weeklyRetentionUsers[i],
        }));

        retentionData.lineData = [
            {
                id:
                    'WeeklyRetentionRate' +
                    retentionData.formattedWeek +
                    uuid_v4(),
                data: chartData,
            },
        ];

        retentionData.weeklyRetentionRate = weeklyRetentionRate;
        retentionData.weeklyRetentionUsers = weeklyRetentionUsers;
    });

    let day30Retention = weeklyRetentionArray[0]?.weeklyRetentionRate[3]; // week 4
    let day90Retention = weeklyRetentionArray[0]?.weeklyRetentionRate[11]; // week 12

    return { weeklyRetentionArray, day30Retention, day90Retention };
};

export const processPoiCategorySunburstWidget = ({ categorySearchRecords }) =>
{
    let sunburstChartData = {
        id: 'categoryPoiVisits',
        name: 'categoryPoiVisits',
        children: [],
        total: 0
    };

    if (!categorySearchRecords)
    {
        return { sunburstChartData };
    }

    let mostSearchedCategory = { count: 0, name: '', isTie: false };
    let mostSearchedPOI = { count: 0, name: '', isTie: false };

    // for each layer create children
    categorySearchRecords.forEach((categorySearchRecord) =>
    {
        const { categoryDirectlySearchCount, categoryName, poiSearchRecords } =
            categorySearchRecord;

        let categoryChild = {
            id: `category - ${categoryName}`,
            name: categoryName,
            parentName: categoryName,
            searches: categoryDirectlySearchCount,
            children: [],
        };

        // check category search count max total
        let categoryTotalSearchCount = categoryDirectlySearchCount;

        poiSearchRecords.forEach((poiSearchRecord) =>
        {
            const { poiName, poiSearchCount } = poiSearchRecord;

            let poiChild = {
                id: `poi - ${poiName}`,
                name: poiName,
                parentName: poiName,
                searches: poiSearchCount,
            };

            categoryTotalSearchCount += poiSearchCount;

            // check if most searched poi has been found
            if (poiSearchCount > mostSearchedPOI.count)
            {
                mostSearchedPOI = {
                    count: poiSearchCount,
                    name: poiName,
                    isTie: false,
                };
            } else if (poiSearchCount === mostSearchedPOI.count)
            {
                mostSearchedPOI.isTie = true;
            }

            categoryChild.children.push(poiChild);
        });

        // check if most search for category was found
        if (categoryTotalSearchCount > mostSearchedCategory.count)
        {
            mostSearchedCategory = {
                count: categoryTotalSearchCount,
                name: categoryName,
                isTie: false,
            };
        } else if (categoryTotalSearchCount === mostSearchedCategory.count)
        {
            mostSearchedCategory.isTie = true;
        }

        categoryChild.totalSearches = categoryTotalSearchCount;
        sunburstChartData.total += categoryTotalSearchCount;
        sunburstChartData.children.push(categoryChild);
    });

    return { sunburstChartData, mostSearchedCategory, mostSearchedPOI };
};

/**
 * Calculate the following for the engagement overview widget
 * Total Downloads, Monthly Active Users, Average App Session Length, App Session Interval
 * @param {*} param0
 */
export const processEngagementOverviewWidget = ({
    engagementOverviewData,
    appDownloadsData,
}) =>
{
    // total downloads
    // monthly active users (this month only?)
    // avg app session length
    // app session interval

    const sortedEngagementOverViewData = engagementOverviewData
        .sort((a, b) => a.startUnixTime_s - b.startUnixTime_s)
        .filter((data) => data.startUnixTime_s > 0);

    const calculateTotalDownloads = (data) =>
    {
        let totalDownloads = 0;

        data.forEach((appData) =>
        {
            totalDownloads += appData.numberOfDownloads;
        });

        return totalDownloads;
    };

    const calcualteAverageSessionLength = (data) =>
    {
        let averageSessionLength = 0;

        data.forEach((session) =>
        {
            averageSessionLength += session.durationMin;
        });

        if (averageSessionLength !== 0)
        {
            averageSessionLength = averageSessionLength / data.length;
        }

        return formatDurationHMS(averageSessionLength);
    };

    // assuming data is sorted by date ascending
    const calculateAverageMonthlyUsers = (data) =>
    {
        // questions, should we count months that we have no data for?

        if (data.length > 0)
        {
            let firstSessionStartDate = unixSecondsToTimeZoneDate(
                data[0].startUnixTime_s
            );

            let totalMonths = differenceInCalendarMonths(
                new Date(),
                firstSessionStartDate
            );




            if (totalMonths === 0)
            {
                // If there are no months, we have no data from which to calculate an average.
                // Therefore, we default to returning the total number of data points.
                return data.length;
            }
            else
            {
                // We calculate the average monthly users by normalizing the data length by the total months.
                // This is done by dividing the total data points by the total number of months.
                // The result is rounded to the nearest integer using Math.round().
                return Math.round(data.length / totalMonths);
            }

        }

        return 0;
    };

    // assuming data is sorted by date ascending
    const calculateAverageAppSessionInterval = (data) =>
    {
        let userUIDToSessionInterval = {};
        let userUIDWithMultipleSessions = [];
        // last session
        // numOfSessions
        // differenceInHours

        data.forEach((session) =>
        {
            const { userUID, startUnixTime_s } = session;
            const date = unixSecondsToTimeZoneDate(startUnixTime_s);

            if (userUIDToSessionInterval[userUID])
            {
                if (userUIDToSessionInterval[userUID].numOfSessions === 1)
                {
                    userUIDWithMultipleSessions.push(userUID);
                }

                let difOfHours = differenceInHours(
                    date,
                    userUIDToSessionInterval[userUID].lastSession
                );
                userUIDToSessionInterval[userUID].lastSession = date;
                userUIDToSessionInterval[userUID].numOfSessions++;
                userUIDToSessionInterval[userUID].totalHoursBetweenSessions +=
                    difOfHours;
            } else
            {
                userUIDToSessionInterval[userUID] = {
                    lastSession: date,
                    numOfSessions: 1,
                    totalHoursBetweenSessions: 0,
                };
            }
        });

        if (userUIDWithMultipleSessions.length > 0)
        {
            let totalAverageUserInterval = 0;

            userUIDWithMultipleSessions.forEach((userUID) =>
            {
                const { numOfSessions, totalHoursBetweenSessions } =
                    userUIDToSessionInterval[userUID];

                if (numOfSessions > 0)
                {
                    totalAverageUserInterval +=
                        totalHoursBetweenSessions / numOfSessions;
                }
            });

            return breakDownHoursToWDH(
                totalAverageUserInterval / userUIDWithMultipleSessions.length
            );
        } else
        {
            return undefined;
        }
    };

    const totalDownloads = calculateTotalDownloads(appDownloadsData);
    const averageMonthlyUsers = calculateAverageMonthlyUsers(
        sortedEngagementOverViewData
    );

    const averageInterval = calculateAverageAppSessionInterval(
        sortedEngagementOverViewData
    );
    const averageSessionLength = calcualteAverageSessionLength(
        sortedEngagementOverViewData
    );

    return {
        totalDownloads,
        averageSessionLength,
        averageMonthlyUsers,
        averageInterval,
    };
};

export const processCategoryConversionRateWidget = (
    { data, compareData },
    trans
) =>
{
    const getTotalSearchVsVisited = (categoryArray) =>
    {
        let numberOfSearches = 0;
        let numberOfVisits = 0;
        categoryArray.forEach((category) =>
        {
            numberOfSearches += category.numSearched;
            numberOfVisits += category.numVisited;
        });

        return { numberOfSearches, numberOfVisits };
    };

    let searchesVsVisits = getTotalSearchVsVisited(data);
    let compareSearchVsVisits = getTotalSearchVsVisited(compareData);

    let funnelChart = [];

    if (searchesVsVisits.numberOfSearches > 0)
    {
        funnelChart.push({
            id: 'searched' + uuid_v4(),
            value: searchesVsVisits.numberOfSearches,
            label: trans('SearchedConversionRateWidget.Searched'),
        });
    }

    if (searchesVsVisits.numberOfVisits > 0)
    {
        funnelChart.push({
            id: 'visited' + uuid_v4(),
            value: searchesVsVisits.numberOfVisits,
            label: trans('SearchedConversionRateWidget.Visited'),
        });
    }

    const percentageVisited = calcualtePercentage(
        searchesVsVisits.numberOfVisits,
        searchesVsVisits.numberOfSearches
    );
    const comparePercentageVisited = calcualtePercentage(
        compareSearchVsVisits.numberOfVisits,
        compareSearchVsVisits.numberOfSearches
    );
    const percentageOfChange = calculatePercentageOfChange(
        percentageVisited,
        comparePercentageVisited
    );

    return { funnelChart, percentageOfChange, percentageVisited };
};

export const processTopSearchedKeywordsWidget = ({ searchRecords }) =>
{
    const deepCopySearchRecords = deepCopy(searchRecords);
    const tableDataArray = deepCopySearchRecords.sort(
        (a, b) => b.searchCount - a.searchCount
    );
    let mostSearchedKeyword;

    if (tableDataArray.length > 0)
    {
        // if no tie
        if (tableDataArray[0]?.searchCount !== tableDataArray[1]?.searchCount)
        {
            mostSearchedKeyword = tableDataArray[0].searchedString;
        }
    }

    return { tableDataArray, mostSearchedKeyword };
};

/**
 * Formats duration with hours mins seconds
 * @param {*} durationMins
 * @returns {content, extra} - an object containing an array of content values and extra values of the same length.
 * extra can be "min", "hr", "sec"
 */
export const formatDurationHMS = (durationMins = 0) =>
{
    let averageDuration = breakDownMinsToHMS(durationMins);

    let content = [];
    let extra = [];

    if (!averageDuration)
    {
        content.push(0);
        extra.push('min');
    } else if (averageDuration.hours > 0)
    {
        content.push(averageDuration.hours);
        extra.push('hr');

        if (averageDuration.mins > 0)
        {
            content.push(averageDuration.mins);
            extra.push('min');
        }
    } else
    {
        content.push(averageDuration.mins);
        extra.push('min');

        if (averageDuration.seconds > 0)
        {
            content.push(averageDuration.seconds);
            extra.push('sec');
        }
    }

    return { content, extra };
};
/**
 * process zone traffic data to fit the widget.
 * Time shifts chart data according to timeZone populated from property or building
 * @param {*} data
 * @param {*} timeZone
 * @returns { barChartKeys, barChartData };
 */
export const processZoneGeofenceOccupancy = ({
    data,
    timeZone,
    floorId,
    showDataForZoneIds = {},
    checkShowDataForZoneIds = false,
    considerUserPercentage = false,
    considerAverageDwell = false,
}) =>
{
    const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;

    let zoneGeofenceTraffic = JSON.parse(
        JSON.stringify(data.zoneGeofenceTraffic)
    );
    zoneGeofenceTraffic = zoneGeofenceTraffic.sort((a, b) =>
        a.zoneLabel.localeCompare(b.zoneLabel)
    );

    let barChartDataMap = {};
    let barChartKeys = [];

    let formattedHoursOfDay = {};
    let tempDateForFormating = startOfDay(new Date());

    // get hours of day  list
    for (let i = 0; i < 24; i++)
    {
        let formattedHour = i18Format(tempDateForFormating, dateGroupFormat);

        formattedHoursOfDay[formattedHour] = true;

        barChartDataMap[formattedHour] = {
            hour: formattedHour,
        };

        tempDateForFormating = add(
            tempDateForFormating,
            DATE_GROUP_RANGE.HOURS
        );
    }

    if (Array.isArray(zoneGeofenceTraffic))
    {
        // sort from highest userCount to lowest userCount

        zoneGeofenceTraffic.forEach((zone) =>
        {
            // create chart data
            const {
                usersPerHour,
                floorId: zoneGeofenceFloorId,
                zoneGeofenceId,
            } = zone;

            if (floorId === zoneGeofenceFloorId || floorId === -1)
            {
                let hoursOfDay = { ...formattedHoursOfDay };

                const makeChartData = () =>
                {
                    barChartKeys.push(zoneGeofenceId);

                    // for each, format date and add to map
                    usersPerHour.forEach((hourData) =>
                    {
                        let date = startOfDay(new Date()); // using start of current date (12 am)
                        date = addHours(date, hourData.hourUTC, date);

                        let utcDate = zonedTimeToUtc(date, 'UTC');

                        let timeZoneDate = utcToZonedTime(
                            utcDate,
                            timeZone?.id || 'UTC'
                        );

                        let formattedHour = i18Format(
                            timeZoneDate,
                            dateGroupFormat
                        );

                        delete hoursOfDay[formattedHour];
                        // add zoneGeofence to barchart data
                        if (!!considerUserPercentage)
                        {
                            barChartDataMap[formattedHour][zoneGeofenceId] =
                                roundWithPrecision(
                                    hourData.userCountPercentage * 100
                                );
                        } else if (!!considerAverageDwell)
                        {
                            barChartDataMap[formattedHour][zoneGeofenceId] =
                                roundWithPrecision(
                                    hourData.averageDwellMinutes
                                );
                        } else
                        {
                            barChartDataMap[formattedHour][zoneGeofenceId] =
                                hourData.userCount;
                        }
                    });

                    Object.keys(hoursOfDay).forEach((formattedHour) =>
                    {
                        barChartDataMap[formattedHour][zoneGeofenceId] = 0;
                    });
                };

                if (!!checkShowDataForZoneIds)
                {
                    if (!!showDataForZoneIds[zoneGeofenceId])
                    {
                        makeChartData();
                    }
                } else
                {
                    makeChartData();
                }
            }
        });
    }

    let barChartData = [];

    Object.keys(formattedHoursOfDay).forEach((formattedHour) =>
    {
        let chartData = barChartDataMap[formattedHour];
        barChartData.push(chartData);
    });

    return { barChartKeys, barChartData, key: uuid_v4() };
};

/**
 * process the data from an API so it will be more readable and used efficiently
 *
 * @param {Object} data - raw response from an api
 * @param {String} formatString
 * @returns {Object} -has keys of weather properties as Array[Values]
 */

function arrangeDataFromAPI(weatherData, formatString, dateGroupFormat)
{
    let result = [];
    let data;
    if (formatString === 'hourly')
    {
        data = weatherData.hourly;
        //  if data has weather.hourly which has sum or mean
        for (let i = 0; i < data['time'].length; i++)
        {
            let current = {};
            if (data['time'][i] !== null)
            {
                current['weather_info'] = {
                    timeStamp: data['time'][i],
                    precipitation_sum: data['precipitation'][i],
                    rain_sum: data['rain'][i],
                    temperature_2m_mean: data['temperature_2m'][i],
                    weathercode: data['weathercode'][i],
                };
                result.push(current);
            }
        }
    } else
    {
        data = weatherData.daily;
        // adjusting length of data to be formatted according to the date range selected
        let iterationLength = (dateGroupFormat === "MMM d") ? (data["time"].length - 1) : (dateGroupFormat === "MMM yyyy") ? (data["time"].length - 60) : (data["time"].length - 1);

        // if data has weather.daily which has no sum or mean
        for (let i = 0; i < iterationLength; i++)
        {
            let current = {};


            if (data['time'][i] !== null)
            {
                current['weather_info'] = {
                    timeStamp: data['time'][i],
                    precipitation_sum: data['precipitation_sum'][i],
                    rain_sum: data['rain_sum'][i],
                    temperature_2m_mean: data['temperature_2m_mean'][i],
                    weathercode: data['weathercode'][i],
                };
                result.push(current);
            }
        }

        // Since there are null values for around past 10 days in archieve api,we are concatinating forecast api results as pastTwoMonthsData(property in api response)
        // 2 months cause if we are early in the month like 5 of oct,the sept month data will also be null for some month ending days
        if (weatherData.pastTwoMonthData)
        {
            let dailyDataForPastTwoMonth = weatherData.pastTwoMonthData.daily;
            for (let i = 0; i < dailyDataForPastTwoMonth['time'].length; i++)
            {
                let current = {};
                if (dailyDataForPastTwoMonth['time'][i] !== null)
                {
                    current['weather_info'] = {
                        timeStamp: dailyDataForPastTwoMonth['time'][i],
                        precipitation_sum: dailyDataForPastTwoMonth['precipitation_sum'][i],
                        rain_sum: dailyDataForPastTwoMonth['rain_sum'][i],
                        temperature_2m_mean: dailyDataForPastTwoMonth['temperature_2m_mean'][i],
                        weathercode: dailyDataForPastTwoMonth['weathercode'][i],
                    };
                    result.push(current);
                }
            }
        }
    }


    return result;
}

/**
 *
 * @param {Object[Weather Values]} data
 * @param {String} dateFormatType
 * @param {Number} difference
 * @returns {Array[Chunked Array[]]}  // to keep up with the skipping days or hours in the graph
 */
function formatWeatherDataForCurrentDateRange(
    data,
    dateFormatType,
    difference
)
{
    let chunkedArray = []; // individual array data for specified range

    if (dateFormatType === DATE_GROUP_FORMAT.MONTHS)
    {
        // for getting Yearly data
        let currentIteration = 1;

        for (let i = 0; i < data.length; i += 30)
        {
            let currentArray;
            if (data.length - 1 - currentIteration * 30 > 0)
            {
                currentArray = data.slice(i, i + 30);
                currentIteration++;
            } else
            {
                currentArray = data.slice(i, -1);
            }
            chunkedArray.push(currentArray);
        }
        // for getting past 7 days data
    } else if (dateFormatType === DATE_GROUP_FORMAT.DAYS)
    {
        let currentIteration = 1;

        for (let i = 0; i < data.length; i += 1)
        {
            let currentArray;
            if (data.length - 1 - currentIteration * 1 > 0)
            {
                currentArray = data.slice(i, i + 1);
                currentIteration++;
            } else
            {
                currentArray = data.slice(i, -1);
            }
            chunkedArray.push(currentArray);
        }
        // for getting Monthly data
    } else if (dateFormatType === DATE_GROUP_FORMAT.MONTHS && difference >= 28)
    {

        let currentIteration = 1;

        for (let i = 0; i < data.length; i += 1)
        {
            let currentArray;
            if (data.length - 1 - currentIteration * 1 > 0)
            {
                currentArray = data.slice(i, i + 1);
                currentIteration++;
            } else
            {
                currentArray = data.slice(i, -1);
            }
            chunkedArray.push(currentArray);
        }

        // for getting Hourly data
    } else if (dateFormatType === 'h aa' && difference === 23)
    {
        let currentIteration = 1;

        for (let i = 0; i < data.length; i += 1)
        {
            let currentArray;
            if (data.length - 1 - currentIteration * 2 > 0)
            {
                currentArray = data.slice(i, i + 1);
                currentIteration++;
            } else
            {
                currentArray = data.slice(i, -1);
            }
            chunkedArray.push(currentArray);
        }
    }

    return chunkedArray;
}
/**
 *
 * @param {Array} data
 * @returns
 */

function calculateAverage(data)
{
    if (data)
    {
        let totalEntries = data.length;
        let totalPrecipitation = 0;
        let totalRain = 0;
        let totalTemperature = 0;
        let totalweatherCode = 0;

        for (let entry of data)
        {
            let weatherInfo = entry.weather_info;
            totalPrecipitation += weatherInfo.precipitation_sum || 0;
            totalRain += weatherInfo.rain_sum || 0;
            totalTemperature += weatherInfo.temperature_2m_mean || 0;
            totalweatherCode += weatherInfo.weathercode || 0;
        }

        let averagePrecipitation = totalPrecipitation / totalEntries;
        let averageRain = totalRain / totalEntries;
        let averageTemperature = totalTemperature / totalEntries;

        // Finding the closest weather code
        let closestWeatherCode = null;
        let minDifference = Infinity;
        for (let code in WEATHER_CODE_HASH_MAP)
        {
            let difference = Math.abs(totalweatherCode / totalEntries - code);
            if (difference < minDifference)
            {
                minDifference = difference;
                closestWeatherCode = code;
            }
        }

        return {
            averagePrecipitation,
            averageRain,
            averageTemperature,
            weathercode: closestWeatherCode,
        };
    }
}
/**
 *
 * @param {String} startTime
 * @param {String} endTime
 * @returns
 */
function calculateDurationInMinutes(startTime, endTime)
{
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const diffInMilliseconds = endDate - startDate;

    // Convert the millisec difference to minutes
    const diffInMinutes = diffInMilliseconds / 1000 / 60;

    return diffInMinutes;
}
/**
 *
 * @param {Object} visitData
 * @param {Object} weatherData
 * @param {Date} dateRange
 * @returns {Object[data:Array[]]}
 */
export const processWeatherVisitsVsTime = (
    visitData,
    weatherData,
    dateRange
) =>
{
    const { startDate, endDate } = dateRange;
    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let processedWeatherInfo;
    if (weatherData.daily)
    {
        processedWeatherInfo = arrangeDataFromAPI(weatherData, 'daily', dateGroupFormat);
    } else
    {
        processedWeatherInfo = arrangeDataFromAPI(weatherData, 'hourly', dateGroupFormat);
    }

    let date = startDate;
    // get the difference Number for selected timestamp
    let rangeGroupDiffrence = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });


    let dateRangeFormatedData = formatWeatherDataForCurrentDateRange(
        processedWeatherInfo,
        dateGroupFormat,
        rangeGroupDiffrence
    );
    // this is a quick fix for rangeGroupDifference but still has issue with decrease in 1 for next render
    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        if (rangeGroupDiffrence == 11 || rangeGroupDiffrence == 12)
            rangeGroupDiffrence = 12;
    }


    // get formatted data
    let formattedData = {}; // our final mappable data for the Line Chart

    for (let i = 0; i <= rangeGroupDiffrence; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        formattedData[formattedDate] = {
            date: formattedDate,
            weather: calculateAverage(dateRangeFormatedData[i]),
            newVisits: 0,
            averageDuration: 0,
            y: 0,
            x: formattedDate,
            formattedAvgDur: formatMinsToHHMMSS(0)
        };

        date = add(date, dateGroupRange);
    }

    // add visit data to chart data
    const addVisitsToChartData = (visits, timeZone, isNew) =>
    {
        visits.forEach((visit) =>
        {
            const { startTime, endTime } = visit;
            let startDate = utcToZonedTime(startTime, timeZone?.id || 'UTC');
            let endDate = utcToZonedTime(endTime, timeZone?.id || 'UTC');

            let duration = calculateDurationInMinutes(startDate, endDate);

            let formattedDate = i18Format(startDate, dateGroupFormat);

            if (formattedData[formattedDate])
            {
                formattedData[formattedDate].averageDuration += duration;
                formattedData[formattedDate].y++;
                if (isNew)
                {
                    formattedData[formattedDate].newVisits++;
                }
                // formattedData[formattedDate].formattedAvgDur = formatMinsToHHMMSS(formattedData[formattedDate].averageDuration);
            }

        });
    };

    const { newUsers, returningUsers, timeZone } = visitData;
    addVisitsToChartData(newUsers, timeZone, true);
    addVisitsToChartData(returningUsers, timeZone, false);

    let chartData = Object.values(formattedData);

    // format all displayed values
    chartData.forEach((data) =>
    {
        if (data.averageDuration > 0)
        {
            data.averageDuration = data.averageDuration / data.y;
        }

        data.averageDurationFormatted = formatMinsToHHMMSS(
            data.averageDuration
        );

        // data.weather.averageTemp = `${ data.weather.averageTemp.toFixed(1) }°C`;
    });

    chartData = [
        {
            id: 'weather data' + uuid_v4(),
            label: 'Weather',
            data: chartData,
        },
    ];

    return { chartData };
};

/********* HELPER FUNCTIONS *********/

/**
 * Helper function to put formated dates into map
 * NOTE: formated dates must have a key in map
 * @param {*} formatedDates
 * @param {*} map
 * @returns {Map}
 */
const mapUserFormatedDates = (formatedDates, map) =>
{
    formatedDates.forEach((formatedDate) =>
    {
        let mapValue = map.get(formatedDate);
        mapValue.y += 1;

        map.set(formatedDate, mapValue);
    });

    return map;
};

/**
 *
 * @param {*} startDate
 * @param {*} endDate
 * @returns { dateGroupFormat, dateGroupRange }
 */
export const getDateGroupAndFormat = (startDate, endDate) =>
{
    const diffDays = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange: DATE_GROUP_RANGE.DAYS,
    });

    let dateGroupFormat = undefined;
    let dateGroupRange = {};

    if (diffDays === 0)
    {
        // add 1 hour to startDate until it is equal to endDate
        dateGroupFormat = DATE_GROUP_FORMAT.HOURS;
        dateGroupRange = DATE_GROUP_RANGE.HOURS;
    } else if (diffDays <= 60)
    {
        // add 1 day to startDate until it is equal to endDate
        dateGroupFormat = DATE_GROUP_FORMAT.DAYS;
        dateGroupRange = DATE_GROUP_RANGE.DAYS;
    } else
    {
        // add 1 month to startDate until it is equal to endDate
        dateGroupFormat = DATE_GROUP_FORMAT.MONTHS;
        dateGroupRange = DATE_GROUP_RANGE.MONTHS;
    }

    return { dateGroupFormat, dateGroupRange };
};

export const genarateBarChartDataFromTraficData = ({
    key,
    zoneTrafficArray = [],
}) =>
{
    let zoneBarChart = {};
    if (zoneTrafficArray.length === 0)
    {
        return { barChartKeys: [], barChartData: [] };
    }

    let timeObjet = {};

    zoneTrafficArray.forEach((item) =>
    {
        const { chartData = [], zoneGeofenceId } = item;
        zoneBarChart[zoneGeofenceId] = true;
        chartData.forEach((o) =>
        {
            const { time: hour, users } = o;
            timeObjet[hour] = {
                ...timeObjet[hour],
                hour,
                [zoneGeofenceId]: users,
            };
        });
    });

    let barChartKeys = Object.keys(zoneBarChart);
    let barChartData = Object.values(timeObjet).reduce((acc, o) =>
    {
        acc.push(o);
        return acc;
    }, []);

    return {
        barChartKeys,
        barChartData,
        key,
    };
};


/**
 * Generates bar chart data from boost traffic.
 *
 * Normalizes visit data by total number of users across the day.
 *
 * bar chart percentage should equal to the total percentage of ZG1 -> ZG2
 *
 * @param {Array} flowsPerHour - The flows per hour data.
 * @param {string} toZoneId - The ID of the destination zone.
 * @param {string} fromZoneId - The ID of the source zone.
 * @param {string} timeZone - The time zone information.
 * @return {Object} The bar chart data and keys.
 */
export const generateBarChartDataFromBoostTraffic = (
    flowsPerHour,
    toZoneId,
    fromZoneId,
    timeZone
) =>
{
    const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;

    let usersPerHourMap = {};
    let totalUsers = 0;
    let normalizedPercentageOfVisitsPerHourMap = {};

    if (!Array.isArray(flowsPerHour))
    {
        return {
            barChartData: Object.values(normalizedPercentageOfVisitsPerHourMap),
            barChartKeys: [toZoneId],
            key: BAR_CHART_KEY,
        };
    }

    // find total number of users across the day
    // also create visits map from the selected "from" zone to the selected zone
    flowsPerHour.forEach((hourData) =>
    {
        const { fromZoneGeofence } = hourData;
        const totalFrom = Object.entries(fromZoneGeofence).reduce((acc, [key, value]) => acc + value, 0);
        totalUsers += totalFrom;

        const userCountFromZG1toZG2 = fromZoneGeofence[fromZoneId] || 0;

        // get convert hour to timezone hour
        let date = startOfDay(new Date()); // using local time right now
        date = addHours(date, hourData.hourUTC, date);
        let utcDate = zonedTimeToUtc(date, 'UTC');
        let timeZoneDate = utcToZonedTime(utcDate, timeZone?.id || 'UTC');

        const timeZoneConvertedHour = timeZoneDate.getHours();

        usersPerHourMap[timeZoneConvertedHour] = userCountFromZG1toZG2;
    });

    // create output mapping with formatted time values and normalized percentages
    let tempDateForFormatting = startOfDay(new Date());

    for (let hourUTC = 0; hourUTC < 24; hourUTC++)
    {
        let formatedDate = i18Format(
            tempDateForFormatting,
            dateGroupFormat
        );

        let userCountFromZG1toZG2 = usersPerHourMap[hourUTC];
        let normalizedPercentageOfUsersPer24Hours = 0;

        // normalize value per total visits if exists
        if (userCountFromZG1toZG2)
        {
            normalizedPercentageOfUsersPer24Hours = userCountFromZG1toZG2 / totalUsers;
        }

        const normalizedPercentageOfVisitsPerHourData = {
            [toZoneId]: normalizedPercentageOfUsersPer24Hours,
            hour: formatedDate,
        };

        // add value to return map
        normalizedPercentageOfVisitsPerHourMap[hourUTC] = normalizedPercentageOfVisitsPerHourData;

        // add 1 hour to the temp date used for formatting
        tempDateForFormatting = add(
            tempDateForFormatting,
            DATE_GROUP_RANGE.HOURS
        );
    }

    return {
        barChartData: Object.values(normalizedPercentageOfVisitsPerHourMap),
        barChartKeys: [toZoneId],
        key: BAR_CHART_KEY,
    };
};


/**
 * Generates bar chart data from boost traffic.
 *
 * @param {Array} flowsPerHour - The flows per hour data.
 * @param {string} toZoneId - The ID of the destination zone.
 * @param {string} fromZoneId - The ID of the source zone.
 * @param {string} timeZone - The time zone information.
 * @return {Object} The bar chart data and keys.
 * @deprecated old logic
 */
export const generateBarChartDataFromBoostTrafficDEPRECATED = (
    flowsPerHour,
    toZoneId,
    fromZoneId,
    timeZone
) =>
{
    let usersPerHourMap = {};
    let totalUsers = 0;

    if (Array.isArray(flowsPerHour))
    {
        const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;

        flowsPerHour.forEach((hourData) =>
        {
            const { fromZoneGeofence } = hourData;
            //gives the total input user to from zone
            const totalFrom = Object.entries(fromZoneGeofence).reduce((acc, [key, value]) => acc + value, 0);

            let date = startOfDay(new Date()); // using local time right now
            date = addHours(date, hourData.hourUTC, date);

            let utcDate = zonedTimeToUtc(date, 'UTC');
            let timeZoneDate = utcToZonedTime(utcDate, timeZone?.id || 'UTC');
            let formatedDate = i18Format(timeZoneDate, dateGroupFormat);

            let currentUserCount = fromZoneGeofence[fromZoneId] ?? 0;
            const currentHour = timeZoneDate.getHours();
            //console\.log(totalUsers)
            totalUsers = totalUsers + +currentUserCount;

            //we are calculating the average  based on
            //if 100 people entered to from zone in 1 hour then
            //we calculate percentage of people who moved to toZone
            usersPerHourMap[hourData.hourUTC] = {
                [toZoneId]: totalFrom === 0 ? 0 : currentUserCount / totalFrom,
                hour: formatedDate,
            };
            //console\.log(usersPerHourMap, toZoneGeofence, toZoneGeofence[fromZoneId], formatedDate, currentUserCount, usersPerHourMap[formatedDate]);
        });

        //create bar chart data for each hour of the day
        let tempDateForFormatting = startOfDay(new Date());
        for (let hourUTC = 0; hourUTC < 24; hourUTC++)
        {
            let formatedDate = i18Format(
                tempDateForFormatting,
                dateGroupFormat
            );

            let perviousPreparedToZoneData = usersPerHourMap[hourUTC];

            let userCountPerHour = perviousPreparedToZoneData?.[toZoneId] ?? 0;
            const userCountPerHourData = {
                [toZoneId]: userCountPerHour,
                hour: formatedDate,
            };

            usersPerHourMap[hourUTC] = userCountPerHourData;
            tempDateForFormatting = add(
                tempDateForFormatting,
                DATE_GROUP_RANGE.HOURS
            );
        }
    }
    return {
        barChartData: Object.values(usersPerHourMap),
        barChartKeys: [toZoneId],
        key: BAR_CHART_KEY,
    };
};



/**
 * Generate bar chart data from boost tags traffic. bar chart data here  we will be return the data in visit counts
 *
 * @param {array} flowsPerHour - The array of flow data per hour
 * @param {string} toZoneId - The ID of the target zone
 * @param {string} fromZoneId - The ID of the source zone
 * @param {string} timeZone - The time zone for the data
 * @return {object} The bar chart data and keys
 */
export const generateBarChartDataFromBoostTagsTraffic = (
    flowsPerHour,
    toZoneId,
    fromZoneId,
    timeZone
) =>
{
    let usersPerHourMap = {};
    let totalUsers = 0;
    if (Array.isArray(flowsPerHour))
    {
        const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;

        flowsPerHour.forEach((hourData) =>
        {
            const { fromZoneGeofence } = hourData;
            let date = startOfDay(new Date()); // using local time right now
            date = addHours(date, hourData.hourUTC, date);
            let utcDate = zonedTimeToUtc(date, 'UTC');
            let timeZoneDate = utcToZonedTime(utcDate, timeZone?.id || 'UTC');
            let formatedDate = i18Format(timeZoneDate, dateGroupFormat);

            let currentUserCount = fromZoneGeofence[fromZoneId] ?? 0;
            const currentHour = timeZoneDate.getHours();
            //console\.log(totalUsers)
            totalUsers = totalUsers + +currentUserCount;
            usersPerHourMap[0 + +currentHour] = {
                [toZoneId]: currentUserCount,
                hour: formatedDate,
            };
            //console\.log(usersPerHourMap, toZoneGeofence, toZoneGeofence[fromZoneId], formatedDate, currentUserCount, usersPerHourMap[formatedDate]);
        });

        //console\.log(usersPerHourMap);

        //create bar chart data for each hour of the day
        let tempDateForFormatting = startOfDay(new Date());
        for (let hourUTC = 0; hourUTC < 24; hourUTC++)
        {
            let formatedDate = i18Format(
                tempDateForFormatting,
                dateGroupFormat
            );

            let perviousPreparedToZoneData = usersPerHourMap[hourUTC];

            let userCountPerHour = perviousPreparedToZoneData?.[toZoneId] ?? 0;
            const userCountPerHourData = {
                [toZoneId]: userCountPerHour,
                hour: formatedDate,
            };

            usersPerHourMap[hourUTC] = userCountPerHourData;
            tempDateForFormatting = add(
                tempDateForFormatting,
                DATE_GROUP_RANGE.HOURS
            );
        }
    }
    return {
        barChartData: Object.values(usersPerHourMap),
        barChartKeys: [toZoneId],
        key: BAR_CHART_KEY,
    };
};

/**
 * @param {Object} parm0 -  containing information needed to process the data for the widget
 * @param {Array} param0.data
 * @param {Array} param0.compareData
 * @param {Object} param0.dateRange - containg startTime and endTime
 * @param {Object} param0.timeZone - containting id (time zone id)
 */
export const processWebAnalyticsSessionDurationVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    if (data.length === 0)
    {
        return {};
    }

    let lineData = [];
    let totalDurationMin = 0;
    let compareTotalDurationMin = 0;

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            x: formattedDate,
            y: 0, // avg duration mins
            totalUserSessions: 0, // total user sessions
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    // formatDatesInObjectList_UnixSeconds(data, "startUnixTime_s", "formattedStartTime", dateGroupFormat, timeZone);

    data.forEach((session) =>
    {
        const sessionStartDateTime = DateTime.fromISO(session.StartAt, {
            zone: timeZone.id,
        });
        const sessionEndDateTime = DateTime.fromISO(session.EndAt, {
            zone: timeZone.id,
        });
        let formattedStartTime = i18Format(
            sessionStartDateTime.toJSDate(),
            dateGroupFormat
        );

        let key = formattedStartTime;
        let durationMin = sessionEndDateTime
            .diff(sessionStartDateTime)
            .as('minutes');

        let mapValue = dateMap.get(key);

        if (mapValue)
        {
            // if user did not already had a session in this time period

            totalDurationMin += durationMin;

            mapValue.y += durationMin;
            mapValue.totalUserSessions++;

            dateMap.set(key, mapValue);
        }
    });

    compareData.forEach((session) =>
    {
        const sessionStartDateTime = DateTime.fromISO(session.StartAt, {
            zone: timeZone.id,
        });
        const sessionEndDateTime = DateTime.fromISO(session.EndAt, {
            zone: timeZone.id,
        });
        let durationMin = sessionEndDateTime
            .diff(sessionStartDateTime)
            .as('minutes');
        compareTotalDurationMin += durationMin;
    });

    lineData = Array.from(dateMap.values());

    lineData.forEach((data) =>
    {
        if (data.y !== 0)
        {
            data.y = data.y / data.totalUserSessions;
        }

        delete data.totalUserSessions;
    });

    lineData = [
        {
            id: 'web Average Duration' + uuid_v4(),
            data: lineData,
        },
    ];

    let averageDuration = totalDurationMin / data.length;
    let compareAverageDuration = compareData.length
        ? compareTotalDurationMin / compareData.length
        : 0;

    let percentageOfChange = calculatePercentageOfChange(
        averageDuration,
        compareAverageDuration
    );

    let averageDurationFormatted = formatDurationHMS(averageDuration);

    return { lineData, averageDurationFormatted, percentageOfChange };
};

/**
 * @param {Object} parm0 -  containing information needed to process the data for the widget
 * @param {Array} param0.data
 * @param {Array} param0.compareData
 * @param {Object} param0.dateRange - containg startTime and endTime
 * @param {Object} param0.timeZone - containting id (time zone id)
 */
export const processWebAnalyticUsersVsTimeWidget = ({
    data,
    compareData,
    dateRange,
    timeZone,
}) =>
{
    if (data.length === 0)
    {
        return {};
    }

    let barData = [];
    let userMap = {}; // used for total unique users
    let compareUserMap = {};

    // get dynamic date range diff from time zone
    const { startDate, endDate } = dateRange;

    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );

    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    // create bar date group to value map

    let dateMap = new Map();

    if (dateGroupFormat === DATE_GROUP_FORMAT.MONTHS)
    {
        rangeGroupDif += 1;
    }

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formattedDate = i18Format(date, dateGroupFormat);

        dateMap.set(formattedDate, {
            label: formattedDate,
            value: 0,
            userHash: {}, // hash of unique users
        });

        date = add(date, dateGroupRange);
    }

    // process all start times to date group start time
    // formatDatesInObjectList_UnixSeconds(data, "startUnixTime_s", "formattedStartTime", dateGroupFormat, timeZone);

    data.forEach((session) =>
    {
        const sessionStartDateTime = DateTime.fromISO(session.StartAt, {
            zone: timeZone.id,
        });
        // const sessionEndDateTime = DateTime.fromISO(session.EndAt, { zone: timeZone.id })
        let formattedStartTime = i18Format(
            sessionStartDateTime.toJSDate(),
            dateGroupFormat
        );

        let key = formattedStartTime;
        let userUID = session.DeviceId;

        let mapValue = dateMap.get(key);

        userMap[userUID] = true;

        // if user did not already had a session in this time period
        if (mapValue && !mapValue.userHash[userUID])
        {
            mapValue.userHash[userUID] = true;
            mapValue.value++;

            dateMap.set(key, mapValue);
        }
    });

    compareData.forEach((session) =>
    {
        let userUID = session.DeviceId;
        compareUserMap[userUID] = true;
    });

    barData = Array.from(dateMap.values());

    let totalUniqueUsers = Object.keys(userMap).length;
    let compareTotalUniqueUsers = Object.keys(compareUserMap).length;

    let percentageOfChange = calculatePercentageOfChange(
        totalUniqueUsers,
        compareTotalUniqueUsers
    );

    return { barData, totalUniqueUsers, percentageOfChange };
};

// to do filtering the logic for new vs old users
/**
 * @param {Array} sessions
 * @param {Array} dateRange
 * @param {Object} timeZone

 * @returns {object} {
        newUsersPercentage,
        returningUsersPercentage,
        chartData: [avgUserLineData, returningUserLineData, newUserLineData],
        timeZone: timeZone?.id || "UTC",
    }
 * */
export const processNewVsRepeatWebAnalytics = (
    sessions,
    dateRange,
    timeZone
) =>
{
    if (!sessions.length)
    {
        return [];
    }

    const { startDate, endDate } = dateRange;
    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );
    let date = startDate;

    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    if (
        dateGroupFormat === DATE_GROUP_FORMAT.MONTHS ||
        dateGroupFormat === DATE_GROUP_FORMAT.DAYS
    )
    {
        rangeGroupDif += 1;
    }

    let returningUserMap = new Map();
    let newUserMap = new Map();
    let avgUserMap = new Map();

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formatedDate = i18Format(date, dateGroupFormat);

        returningUserMap.set(formatedDate, {
            label: NEW_VS_RETURNING_USER_IDS.REPEAT,
            x: formatedDate,
            y: 0,
        });

        newUserMap.set(formatedDate, {
            label: NEW_VS_RETURNING_USER_IDS.NEW,
            x: formatedDate,
            y: 0,
        });

        avgUserMap.set(formatedDate, {
            label: NEW_VS_RETURNING_USER_IDS.AVERAGE,
            x: formatedDate,
            y: 0,
        });

        date = add(date, dateGroupRange);
    }

    let totalNewUser = 0;
    let totalReturningUser = 0;

    sessions.forEach((session) =>
    {
        const sessionStartDateTime = DateTime.fromISO(session.StartAt, {
            zone: timeZone.id,
        });
        const deviceCreatedAtDateTime = DateTime.fromISO(
            session.DeviceCreatedAt,
            { zone: timeZone.id }
        );
        let formattedStartTime = i18Format(
            sessionStartDateTime.toJSDate(),
            dateGroupFormat
        );
        const diffOfSessionAndDeviceCreatedInSeconds = sessionStartDateTime
            .diff(deviceCreatedAtDateTime)
            .as('seconds');

        // splitting new users and returning users
        if (diffOfSessionAndDeviceCreatedInSeconds <= 1)
        {
            const newUserChartEntry = newUserMap.get(formattedStartTime);
            newUserChartEntry.y++;
            totalNewUser++;
        } else
        {
            const returningUserChartEntry =
                returningUserMap.get(formattedStartTime);
            returningUserChartEntry.y++;
            totalReturningUser++;
        }
    });

    const totalUserVisits = totalNewUser + totalReturningUser;
    const avgUserCount = roundWithPrecision(
        totalUserVisits / (rangeGroupDif * 2)
    );
    const newUsersPercentage = calcualtePercentage(
        totalNewUser,
        totalUserVisits
    );
    const returningUsersPercentage = calcualtePercentage(
        totalReturningUser,
        totalUserVisits
    );
    for (let key of avgUserMap.keys())
    {
        let mapValue = avgUserMap.get(key);
        mapValue.y = avgUserCount;
        avgUserMap.set(key, mapValue);
    }

    const newUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.NEW + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.NEW,
        data: Array.from(newUserMap.values()),
    };

    const returningUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.REPEAT + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.REPEAT,
        data: Array.from(returningUserMap.values()),
    };

    const avgUserLineData = {
        id: NEW_VS_RETURNING_USER_IDS.AVERAGE + uuid_v4(),
        label: NEW_VS_RETURNING_USER_IDS.AVERAGE,
        data: Array.from(avgUserMap.values()),
    };

    return {
        newUsersPercentage,
        returningUsersPercentage,
        chartData: [avgUserLineData, returningUserLineData, newUserLineData],
        timeZone: timeZone?.id || 'UTC',
    };
};

/**
 * @param {Array} sessions
 * @returns {object}
 * */
export const processQRvsLinkWebAnalytics = (sessions) =>
{
    const groupedDataBySource = {
        [WEB_ENGAGEMENT__SESSION_SOURCE.QRCODE]: {
            count: 0,
            app: {
                [WEB_ENGAGEMENT__SESSION_APP.KIOSK]: 0,
                [WEB_ENGAGEMENT__SESSION_APP.MOBILE]: 0,
                [WEB_ENGAGEMENT__SESSION_APP.WEB]: 0,
            },
        },
    };

    sessions.forEach(({ Source, App }) =>
    {
        if (Source === WEB_ENGAGEMENT__SESSION_SOURCE.QRCODE)
        {
            groupedDataBySource[Source].count++;
            groupedDataBySource[Source].app[App]++;
        }
    });

    const appType = groupedDataBySource[WEB_ENGAGEMENT__SESSION_SOURCE.QRCODE];

    if (appType.count === 0)
    {
        return [];
    }

    const webAndMobile =
        appType.app[WEB_ENGAGEMENT__SESSION_APP.WEB] +
        +appType.app[WEB_ENGAGEMENT__SESSION_APP.MOBILE];
    const webPercentage = webAndMobile
        ? roundWithPrecision((webAndMobile * 100) / appType.count)
        : 0;

    const kisokValue = appType.app[WEB_ENGAGEMENT__SESSION_APP.KIOSK];
    const kisokValuePercentage = kisokValue
        ? roundWithPrecision((kisokValue * 100) / appType.count)
        : 0;

    const colors = createListOfColors(2);
    return [
        {
            id: 'Web',
            label: 'Web',
            value: webAndMobile,
            percentage: webPercentage,
            color: colors[0],
        },
        {
            id: 'Kiosk',
            label: 'Kiosk',
            value: kisokValue,
            percentage: kisokValuePercentage,
            color: colors[1],
        },
    ];
};

/**
 * @param {Array} destinations
 * @param {Number} maxTop
 * @returns {object}
 * */
export const processDestinationDataForTopDestinationWidget = (
    destinations,
    maxTop = 20
) =>
{
    if (!destinations?.length)
    {
        return { pieData: [], maxVisitsCount: 0, maxDestinationName: '' };
    }
    let groupedByDestinationName = {};

    let destinationVisits = {};
    let allVisits = new Set();
    let processedPieData = [];
    groupedByDestinationName = destinations.reduce((acc, destination) =>
    {
        const uniqueKeyOfDestination =
            destination.Name + destination.ConnectedEntityId;
        if (!acc[uniqueKeyOfDestination])
        {
            acc[uniqueKeyOfDestination] = [];
            const uniqueSessionIds = new Set();
            destinationVisits[uniqueKeyOfDestination] = uniqueSessionIds;
        }

        acc[uniqueKeyOfDestination].push(destination);
        destinationVisits[uniqueKeyOfDestination].add(destination.SessionId);
        allVisits.add(destination.SessionId);
        return acc;
    }, {});

    const destinationsAndUsersEntries = Object.entries(destinationVisits);
    const colors = createListOfColors(destinationsAndUsersEntries.length);
    let maxDestinationName = '';
    let maxVisitsCount = 0;
    destinationsAndUsersEntries.forEach(
        ([uniqueKeyOfDestination, sessions], index) =>
        {
            const destinationName =
                groupedByDestinationName[uniqueKeyOfDestination][0].Name;


            const totalVisitsOfDestination = sessions.size;
            if (maxVisitsCount < totalVisitsOfDestination)
            {
                maxVisitsCount = totalVisitsOfDestination;
                maxDestinationName = destinationName;
            }
            processedPieData.push({
                id: uniqueKeyOfDestination,
                label:
                    MAX_LENGTH_DESTINATION_WIDGET_LABEL <=
                        destinationName.length
                        ? capitalize(destinationName)
                        : destinationName.substring(
                            0,
                            MAX_LENGTH_DESTINATION_WIDGET_LABEL
                        ),
                value: totalVisitsOfDestination,
                color: colors[index],
                percentage: roundWithPrecision(
                    (totalVisitsOfDestination * 100) / allVisits.size
                ),
            });
        }
    );
    processedPieData.sort((a, b) => b.value - a.value);
    if (processedPieData.length > maxTop)
    {
        processedPieData = processedPieData.slice(0, maxTop);
    }

    return { pieData: processedPieData, maxVisitsCount, maxDestinationName };
};

/**
 *
 * @param {Array} destinations
 * @param {Object} dateRange
 * @param {Object} timeZone
 *
 * @returns {Object}  { barChart: { data: [], keys: [], key: uuid_v4(), index, colors: () => { }, legendLabel: () => { } }, maxDestinationCount, maxDestinationTime }
 */
export const processDestinationDataToDestinationsByTime = (
    destinations,
    dateRange,
    timeZone
) =>
{
    let maxDestinationCount = 0;
    let maxDestinationTime = '';
    let index = 'time';
    const preparedData = {
        barChart: {
            data: [],
            keys: [],
            key: uuid_v4(),
            index,
            colors: () => { },
            legendLabel: () => { },
        },
        maxDestinationCount,
        maxDestinationTime,
    };
    if (!destinations?.length)
    {
        return preparedData;
    }

    const { startDate, endDate } = dateRange;
    let date = startDate;
    const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
        startDate,
        endDate
    );
    let rangeGroupDif = getDateRangeDiffrence({
        startDate,
        endDate,
        dateGroupRange,
    });

    if (
        dateGroupFormat === DATE_GROUP_FORMAT.MONTHS ||
        dateGroupFormat === DATE_GROUP_FORMAT.DAYS
    )
    {
        rangeGroupDif += 1;
    }

    let destinationsPerFormatedTimeMap = new Map();

    for (let i = 0; i <= rangeGroupDif; i++)
    {
        let formatedDate = i18Format(date, dateGroupFormat);
        destinationsPerFormatedTimeMap.set(formatedDate, {
            [index]: formatedDate,
            value: 0,
        });
        date = add(date, dateGroupRange);
    }

    const uniqueDestinationNameMapper = {};
    const uniqueDestinationValueMapper = {};

    destinations.forEach((destination) =>
    {
        const { CreatedAt, Name, ConnectedEntityId } = destination;

        const destinationCreatedAtDateTime = DateTime.fromISO(CreatedAt, {
            zone: timeZone.id,
        });
        const formatedTime = i18Format(
            destinationCreatedAtDateTime.toJSDate(),
            dateGroupFormat
        );

        const uniqueDestinationKey = Name + ConnectedEntityId;
        if (!uniqueDestinationNameMapper[uniqueDestinationKey])
        {
            uniqueDestinationNameMapper[uniqueDestinationKey] = Name;
        }
        // uniqueDestinationNameMapper[uniqueDestinationKey].value++;
        const uniqueDestinationLabel =
            uniqueDestinationNameMapper[uniqueDestinationKey];

        if (!uniqueDestinationValueMapper[uniqueDestinationLabel])
        {
            uniqueDestinationValueMapper[uniqueDestinationLabel] = 0;
        }
        uniqueDestinationValueMapper[uniqueDestinationLabel] += 1;

        const currentTimeStampPrevValue =
            destinationsPerFormatedTimeMap.get(formatedTime);
        const updatedTimeStampDesCount = currentTimeStampPrevValue.value + 1;

        // console.log(currentTimeStampPrevValue,formatedTime,destinationsPerFormatedTimeMap )
        const updateDestinationCount =
            1 + +(currentTimeStampPrevValue?.[uniqueDestinationLabel] ?? 0);
        destinationsPerFormatedTimeMap.set(formatedTime, {
            ...currentTimeStampPrevValue,
            value: updatedTimeStampDesCount,
            [uniqueDestinationLabel]: updateDestinationCount,
        });
        // currentTimeStampPrevValue = updateDestinationCount;

        if (maxDestinationCount < updatedTimeStampDesCount)
        {
            maxDestinationTime = currentTimeStampPrevValue[index];
            maxDestinationCount = updatedTimeStampDesCount;
        }
    });

    // const uniqueDestinations = Object.values(uniqueDestinationNameMapper);
    // const allDestinationsColors = uniqueDestinations.reduce((acc, destinationName, idx) =>
    // {
    //     acc[destinationName] = colors[idx]
    //     return acc;
    // }, {})

    return {
        barChart: {
            data: Array.from(destinationsPerFormatedTimeMap.values()),
            keys: ['value'],
            key: uuid_v4(),
            index,
            legendLabel: (e) =>
                `${e.id.length > 12 ? e.id.substring(0, 12) + '..' : e.id} (${uniqueDestinationValueMapper[e.id]
                })`,
        },
        maxDestinationCount,
        maxDestinationTime,
    };
};

/**
 *
 * @param {Array} sessions
 * @param {Object} timeZone
 * @param {Object} languageCode
 *
 * @returns {Object}   {
            tableData: preparedData,
            overallPeakHourTime,
            overallPeakHourWeekDayName,
            overallMaxVisitPercentage
        }
 */
export const processWebEngagementSessionDataToPeakHourVsDay = (
    sessions,
    timeZone,
    languageCode
) =>
{
    let preparedData = [];
    let overallPeakHourTime = 0;
    let overallPeakHourWeekDayName = '';
    let overallMaxVisitPercentage = 0;
    if (!sessions.length)
    {
        return {
            tableData: preparedData,
            overallPeakHourTime,
            overallPeakHourWeekDayName,
            overallMaxVisitPercentage,
        };
    }

    const weekdays = Info.weekdays().map((v, index) => index);
    const totalDaysInWeek = weekdays.length;
    const weekDaysPeakHoursMap = new Map();
    let tempDateForFormatting = new Date(0, 0, 0);
    const dateGroupFormat = DATE_GROUP_FORMAT.HOURS;
    const allHours = new Array(24);
    for (let hour = 0; hour < 24; hour++)
    {
        let formatedDate = i18Format(tempDateForFormatting, dateGroupFormat);
        allHours[hour] = {
            visit: 0,
            maxVisits: 0,
            time: formatedDate,
            timeZoneId: timeZone.id,
        };
        tempDateForFormatting = add(
            tempDateForFormatting,
            DATE_GROUP_RANGE.HOURS
        );
    }
    weekdays.forEach((day) =>
    {
        weekDaysPeakHoursMap.set(day, {
            dayName: Info.weekdays('long', { locale: languageCode })[day],
            visitPercentage: 0,
            avgTime: 0,
            peakHours: JSON.parse(JSON.stringify(allHours)),
            peakHourMaxVisitIdx: [],
            maxPeakVisits: 0,
            totalVisits: 0,
            totalTimeInMins: 0,
            keys: ['visit', 'maxVisits'],
        });
    });

    let overAllTotalVisits = sessions.length;

    const timeZoneId = timeZone?.id || 'UTC';

    sessions.forEach((session) =>
    {
        const { StartAt, EndAt } = session;

        const startDateTime = DateTime.fromISO(StartAt, { zone: timeZoneId });
        const endDateTime = DateTime.fromISO(EndAt, { zone: timeZoneId });
        const sessionDurationInMins = endDateTime
            .diff(startDateTime)
            .as('minutes');

        const hour = startDateTime.hour;
        const sessionGroupedByWeekDays = weekDaysPeakHoursMap.get(
            startDateTime.weekday % totalDaysInWeek
        );

        sessionGroupedByWeekDays.totalVisits++;
        sessionGroupedByWeekDays.totalTimeInMins += sessionDurationInMins;

        const currentHourData = sessionGroupedByWeekDays.peakHours[hour];
        const updatedCurrentHourVisits = currentHourData.visit + 1;
        currentHourData.visit = updatedCurrentHourVisits;

        if (updatedCurrentHourVisits > sessionGroupedByWeekDays.maxPeakVisits)
        {
            sessionGroupedByWeekDays.maxPeakVisits = updatedCurrentHourVisits;
            sessionGroupedByWeekDays.peakHourMaxVisitIdx = [hour];
        } else if (
            updatedCurrentHourVisits === sessionGroupedByWeekDays.maxPeakVisits
        )
        {
            sessionGroupedByWeekDays.peakHourMaxVisitIdx.push(hour);
        }
    });

    preparedData = Array.from(weekDaysPeakHoursMap.values()).map(
        (weekDayData) =>
        {
            const {
                totalVisits,
                totalTimeInMins,
                peakHourMaxVisitIdx,
                peakHours,
                maxPeakVisits,
                dayName,
            } = weekDayData;

            peakHourMaxVisitIdx.forEach((peakHrIdx) =>
            {
                peakHours[peakHrIdx].maxVisits = maxPeakVisits;
                peakHours[peakHrIdx].visit = 0;
            });

            let visitPercentage = 0;
            let avgTime = 0;

            if (totalVisits > 0)
            {
                visitPercentage = roundWithPrecision(
                    (totalVisits * 100) / overAllTotalVisits
                );
                avgTime = totalTimeInMins / totalVisits;
            }

            if (overallMaxVisitPercentage < visitPercentage)
            {
                overallMaxVisitPercentage = visitPercentage;
                overallPeakHourWeekDayName = dayName;
                overallPeakHourTime =
                    peakHours[peakHourMaxVisitIdx[0]]?.time ?? 'NA';
            }

            return {
                ...weekDayData,
                avgTime,
                visitPercentage,
            };
        }
    );

    return {
        tableData: preparedData,
        overallPeakHourTime,
        overallPeakHourWeekDayName,
        overallMaxVisitPercentage,
    };
};

/**
 *
 * @param {Array} searches
 * @param {Number} top
 *
 * @returns {Object}    { allSortedSearchItems: [], chartData: {}, headMaxSearchNames: "", tailMaxSearchNames: "" }
 */

export const processSearchDataForTopSearches = (searches, top = 20) =>
{
    let headMaxSearchNames = 0;
    let maxSearchedEntryValue = 0;
    let processedData = [];
    if (!searches.length)
    {
        return {
            allSortedSearchItems: [],
            chartData: {},
            headMaxSearchNames: '',
            tailMaxSearchNames: '',
        };
    }
    let total = 0;
    const groupedSearchData = searches.reduce((acc, search) =>
    {
        const { SelectedResult, Count } = search;
        const capitalizedSearchResult = capitalize(SelectedResult);
        if (!acc[capitalizedSearchResult])
        {
            acc[capitalizedSearchResult] = {
                id: capitalizedSearchResult,
                value: 0,
                label: capitalizedSearchResult,
            };
        }
        acc[capitalizedSearchResult].value += Count;

        if (maxSearchedEntryValue < acc[capitalizedSearchResult].value)
        {
            maxSearchedEntryValue = acc[capitalizedSearchResult].value;
            headMaxSearchNames = `${capitalizedSearchResult} (${acc[capitalizedSearchResult].value})`;
        } else if (
            maxSearchedEntryValue === acc[capitalizedSearchResult].value
        )
        {
            headMaxSearchNames += `, ${capitalizedSearchResult} (${acc[capitalizedSearchResult].value})`;
        }
        total += Count;
        return acc;
    }, {});

    let allUniqueSearches = Object.values(groupedSearchData).sort(
        (a, b) => b.value - a.value
    );

    let tailMaxSearchNames = '';
    // headMaxSearchNames = allUniqueSearches[0].label

    if (allUniqueSearches.length > top)
    {
        allUniqueSearches = allUniqueSearches.slice(0, top);
    }
    const colors = createListOfColors(allUniqueSearches.length + 1);
    processedData = allUniqueSearches.map((search, index) =>
    {
        search.color = colors[index];

        if (!headMaxSearchNames.includes(search.label))
        {
            tailMaxSearchNames += ` ${search.label} (${search.value})${index < allUniqueSearches.length - 1 ? ',' : ''
                }`;
        }
        return search;
    });

    processedData = shuffleArray(processedData);
    const middleIndex = Math.ceil(processedData.length / 2);
    const firstHalf = processedData.slice(0, middleIndex);
    const secondHalf = processedData.slice(middleIndex, processedData.length);

    return {
        headMaxSearchNames,
        tailMaxSearchNames,
        allSortedSearchItems: allUniqueSearches,
        totalSearches: total,
        chartData: {
            label: '',
            id: 'Property',
            color: 'hsla(360, 100%, 100%, 1)',
            children: [
                {
                    id: 'split1',
                    color: 'hsla(360, 100%, 100%, 1)',
                    children: firstHalf,
                },
                {
                    id: 'split2',
                    color: 'hsla(360, 100%, 100%, 1)',
                    children: secondHalf,
                },
            ],
            value: searches.length,
        },
    };
};

/**
 *
 * @param {Array} searches
 *
 * @returns {Array} {}
 */

export const processSearchDataToCategoryAndStore = (searches = []) =>
{
    let mostSearchedName = '';
    let mostSearchedCount = 0;
    let mostSearchedType = '';

    if (!searches?.length)
    {
        return {
            chartData: {},
            mostSearchedName,
            mostSearchedCount,
            mostSearchedType,
        };
    }

    const categoryAndStoreMap = new Map();
    const allSearchTypes = new Set();
    const allSearches = new Set();
    const adjustedTypesTotals = {};

    searches.forEach((search) =>
    {
        const { Type, SelectedResult } = search;

        const adjustedType = getAdjustedSearchTypesForUi(Type);

        allSearchTypes.add(adjustedType);

        allSearches.add(SelectedResult);

        let typeData = categoryAndStoreMap.get(adjustedType);

        if (!typeData)
        {
            adjustedTypesTotals[adjustedType] = 0;
            typeData = {};
        }
        const currentSearchData = typeData?.[SelectedResult] ?? {
            id: SelectedResult,
            value: 0,
        };

        const updatedSearchedCount = 1 + +currentSearchData?.value;
        adjustedTypesTotals[adjustedType] += updatedSearchedCount;
        currentSearchData.value = updatedSearchedCount;
        categoryAndStoreMap.set(adjustedType, {
            ...typeData,
            [SelectedResult]: currentSearchData,
        });

        if (mostSearchedCount < updatedSearchedCount)
        {
            mostSearchedCount = updatedSearchedCount;
            mostSearchedName = SelectedResult;
            mostSearchedType = adjustedType;
        }
    });

    const totalCount = Object.values(adjustedTypesTotals).reduce(
        (acc, v) => acc + +v,
        0
    );

    const preparedData = [...allSearchTypes].reduce((acc, type) =>
    {
        const searchHistoryBasedOnTypes = categoryAndStoreMap.get(type);

        const { filteredData, skippedItemsTotal } =
            prepareFilteredDataWithPercentageThreshold(
                Object.values(searchHistoryBasedOnTypes),
                'value',
                totalCount
            );

        filteredData.push({
            id: `Other ${capitalize(type)}s`,
            value: skippedItemsTotal,
        });

        acc.push({
            id: capitalize(type),
            children: filteredData,
        });
        return acc;
    }, []);

    return {
        chartData: {
            id: 'Property',
            children: preparedData,
        },
        mostSearchedName,
        mostSearchedCount,
        mostSearchedType,
    };
};
