import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSessions } from '../../../_api/queries';
import useCommon from '../../pages/useCommon';
import { processWebAnalyticUsersVsTimeWidget } from '../../../_utils/chart.utils';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const UseAverageUsersVsTimeWebEngagement = () =>
{
  const { timeZone, filters: { analyticsRequestFilter } } = useCommon();

  const sessionsQuery = useWebAnalyticsSessions();


  const processedWidgetData = useMemo(() =>
  {
    const { isSuccess, data: queryData } = sessionsQuery;


    let processedData = { barData: [], totalUniqueUsers: 0, percentageOfChange: "" };

    // process the data
    if (!!isSuccess && queryData)
    {
      const { currentIntervalData: data, previousIntervalData: compareData } = queryData || {};
      const dateRange = {
        startDate: new Date(analyticsRequestFilter.startTimeUTC),
        endDate: new Date(analyticsRequestFilter.endTimeUTC),
      };

      processedData = processWebAnalyticUsersVsTimeWidget({ data, compareData, dateRange, timeZone });
    }

    return { ...sessionsQuery, data: processedData };
  }, [sessionsQuery, timeZone, analyticsRequestFilter]);


  return { sessionsQuery, processedWidgetData, analyticsRequestFilter };

};

export default UseAverageUsersVsTimeWebEngagement;