import React, { useCallback, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { analyticsRequestFilterSelector, activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetPercentageText } from "../common/PercentagePara";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { getDateGroupAndFormat, processUniqueSessionsVsTimeWidget } from "../../_utils/chart.utils";
import { BarChart } from "../charts/BarChart";
import { UNIQUE_SESSION_BAR_CHART_OPTIONS } from "../../_constants/chartConstants";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import { useEngagementOverviewQuery } from "../../_api/queries";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { formatDateValuesFromChartToCSV } from "../../_utils/exportToCsvUtils";

export const UniqueSessionsVsTimeWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const { analyticsRequestFilter, } = useRecoilValue(analyticsRequestFilterSelector);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    const connectedPageName = useRecoilValue(connectedPageNameState);


    // query  engagement/overview data with current and compare
    const engagementOverviewQuery = useEngagementOverviewQuery();

    // render widget/loading/error
    const processedWidgetData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data: queryData, refetch } = engagementOverviewQuery;
        const { data, compareData } = queryData || {};
        let processedData;

        // process the data
        if (!!isSuccess && data)
        {
            const dateRange = {
                startDate: new Date(analyticsRequestFilter.startTimeUTC),
                endDate: new Date(analyticsRequestFilter.endTimeUTC)
            };

            processedData = processUniqueSessionsVsTimeWidget({ data, compareData, dateRange, timeZone });
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [engagementOverviewQuery]);

    const customTooltip = React.useCallback(({ indexValue, value, color /*index, color*/ }) => (
        <div className="tooltipItemRow tooltipItemRowDevice">
            <span style={{ background: color }} /> {indexValue}: <strong>{value}</strong> {(Number(value) === 1) ? trans("UniqueSessionsVsTimeWidget.User") : trans("UniqueSessionsVsTimeWidget.Users")}
        </div>
    ), [trans]);

    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data?.barData?.length)
        {
            return [];
        };
        // Destructure barData, totalUniqueUsers, and percentageOfChange from the input object
        const { barData, totalUniqueUsers, percentageOfChange } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return barData.map(item => ({
            Date: formatDateValuesFromChartToCSV(item.label, dateGroupFormat),
            Users: item.value,
            UserIds: Object.entries(item?.userHash ?? {}).filter(([key, value]) => !!value).map(([key, value]) => key).join(","),
        }));
    }, [processedWidgetData?.data, connectedPageName, analyticsRequestFilter]);

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "Total Number of Web Application Users",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport,]);

    const renderUniqueSessionsVsTimeWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedWidgetData;

        if (isSuccess)
        {
            const { barData, totalUniqueUsers, percentageOfChange } = data;

            if (!barData)
            {
                return <NoDataWidgetMessage />;
            }

            return (
                <React.Fragment>
                    <p className="para gap0"><strong>{totalUniqueUsers}</strong> {trans("UniqueSessionsVsTimeWidget.users_opened_your_app_in_the_selected_ti")}</p>
                    <WidgetPercentageText percentage={percentageOfChange} />
                    {/* <Image as="span" src="/img/chart-reports-interacted.svg" alt="" /> */}

                    <div className="ChartWrapper">
                        <BarChart
                            data={barData}
                            index="label"
                            keys={["value"]}
                            options={UNIQUE_SESSION_BAR_CHART_OPTIONS}
                            customTooltip={customTooltip}
                        // customTick={customTick}
                        // customAxisLeft={{ renderTick: customTickLeft }}
                        // customTooltip={customTooltip}
                        />
                    </div>
                </React.Fragment>
            );
        }
        // if error
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedWidgetData)} />;
        }
        else
        {
            return WidgetLoadingBoxReports();
        }
    }, [processedWidgetData, customTooltip, trans]);


    return (
        <DefaultBox className="alignedWidget" heading={trans("UniqueSessionsVsTimeWidget.How_many_users_interacted_with_your_app_")}>
            {
                renderUniqueSessionsVsTimeWidget()
            }
        </DefaultBox>
    );
};
