import React, { useEffect } from "react";

import { getDefaultFloorIdFromPublicBuilding } from "mapsted.maps/utils/map.utils";
import { deepValue } from "mapsted.utils/objects/index";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Input, Popup, } from "semantic-ui-react";
import { DEFAULT_FILTER, DEFAULT_HEATMAP_FILTER, DEFAULT_HEATMAP_TYPE } from "../../../_constants/filterConstants";
import { doesStringIncludeFilter, sortDropdownCaseInsensitive } from "../../../_utils/utils";
import { buildingIdState, filterState, floorIdState, heatmapFilterState, heatmapTypeState, propertyIdState, propertyInfoMapState } from "../../../store/DashboardAtoms";
import { ButtonIcon } from "../buttonIcon/ButtonIcon";
import { DropdownListItem } from "./DropdownListItem";

export const SubheaderPropertyPopup = ({ handleChangeSelectedSession, isBuildingIdByDefault = false, isOnlyBuildingView = false, options = { customPropertyList: null, customBuildingList: null } }) =>
{
    const trans = useTranslation().t;
    const [propertyId, setPropertyId] = useRecoilState(propertyIdState);
    const [buildingId, setBuildingId] = useRecoilState(buildingIdState);
    const propertyInfoMap = useRecoilValue(propertyInfoMapState);
    const setFloorId = useSetRecoilState(floorIdState);
    const setFilter = useSetRecoilState(filterState);
    const setHeatmapFilter = useSetRecoilState(heatmapFilterState);
    const setHeatmapType = useSetRecoilState(heatmapTypeState);

    const [localPropertyId, setLocalPropertyId] = React.useState(undefined);
    const [propertyFilter, setPropertyFilter] = React.useState("");
    const [buildingFilter, setBuildingFilter] = React.useState("");
    const [isPopupOpen, setIsPopupOpen] = React.useState(false);


    const resetDefaults = React.useCallback(() =>
    {
        setLocalPropertyId(undefined);
        setPropertyFilter("");
        setBuildingFilter("");

    }, [setLocalPropertyId, setPropertyFilter, setBuildingFilter]);

    const handlePopupToggle = React.useCallback(() =>
    {
        setIsPopupOpen(!isPopupOpen);
        resetDefaults();
    }, [isPopupOpen, setIsPopupOpen, resetDefaults]);

    const handleClosePopup = React.useCallback(() =>
    {
        setIsPopupOpen(false);
        resetDefaults();
    }, [setIsPopupOpen, resetDefaults]);

    /**
     * WATCHER for custom property ID list
     * selects first property in list as default property
     */
    useEffect(() =>
    {
        let customPropertyList = options?.customPropertyList;
        if (customPropertyList?.length > 0 && propertyInfoMap)
        {
            // if the current property is not in the custom list, select new property
            if (!customPropertyList.includes(`${propertyId}`))
            {
                let newPropertyId = customPropertyList[0];
                const buildings = propertyInfoMap[newPropertyId]?.buildings;

                if (buildings)
                {
                    const allBuildingIds = Object.keys(buildings);

                    if (allBuildingIds?.length > 0)
                    {
                        const newBuildingId = allBuildingIds[0];
                        const floorId = getDefaultFloorIdFromPublicBuilding(buildings[newBuildingId]);
                        setPropertyId(Number(newPropertyId));
                        setBuildingId(newBuildingId);
                        setFloorId(floorId);
                    }
                }
            }
        }

    }, [propertyInfoMap, options?.customPropertyList]);

    const displayedPropertyBuildingName = React.useMemo(() =>
    {
        let propertyName = deepValue(propertyInfoMap, `${propertyId}.longName`, "");
        let buildingName = deepValue(propertyInfoMap, `${propertyId}.buildings.${buildingId}.longName`, undefined);

        if (propertyName?.length > 0)
        {
            return `${propertyName} ${(buildingName) ? `-> ${buildingName}` : ""}`;
        }
        else
        {
            return "";
        }

    }, [propertyId, buildingId, propertyInfoMap]);

    /**
     * Resets filters for the entire application
     */
    const resetFilters = React.useCallback(() =>
    {
        setFilter(DEFAULT_FILTER);
        setHeatmapFilter(DEFAULT_HEATMAP_FILTER);
        setHeatmapType(DEFAULT_HEATMAP_TYPE);

    }, [setFilter, setHeatmapFilter, setHeatmapType]);

    /**
     * On building click, get default floorId
     */
    const handleSelectBuilding = React.useCallback((selectedBuildingId) =>
    {
        resetFilters();

        if (buildingId === selectedBuildingId)
        {
            setPropertyId(localPropertyId);
            setBuildingId(undefined);
            setFloorId(undefined);
        }
        else
        {
            let building = propertyInfoMap?.[localPropertyId]?.buildings?.[selectedBuildingId] || {};

            // get default floorId
            let floorId = getDefaultFloorIdFromPublicBuilding(building);

            setPropertyId(localPropertyId);
            setFloorId(floorId);
            setBuildingId(selectedBuildingId);
        }

        handleClosePopup();

    }, [localPropertyId, propertyInfoMap, setFloorId, buildingId, handleClosePopup, resetFilters, setPropertyId, setBuildingId]);


    /**
     * on click property, select property level view
     */
    const handleSelectProperty = React.useCallback((newPropertyId) =>
    {
        // handleMouseOverProperty(propertyId)
        // check if propertyId changed
        if (propertyId === newPropertyId)
        {
            return;
        }

        setPropertyId(Number(newPropertyId));
        const isHandleChangeSelectedSessionIsFunction = typeof handleChangeSelectedSession === "function";

        if (isOnlyBuildingView)
        {
            const buildings = propertyInfoMap[newPropertyId].buildings;

            const allBuildingIds = Object.keys(buildings);

            if (allBuildingIds?.length > 0)
            {
                const newBuildingId = allBuildingIds[0];
                const floorId = getDefaultFloorIdFromPublicBuilding(buildings[newBuildingId]);
                setBuildingId(newBuildingId);
                setFloorId(floorId);

                if (isHandleChangeSelectedSessionIsFunction)
                {
                    handleChangeSelectedSession({ newPropertyId, newBuildingId, floorId });
                }
            }
            else
            {
                setBuildingId(undefined);
                setFloorId(undefined);
                if (isHandleChangeSelectedSessionIsFunction)
                {
                    handleChangeSelectedSession({ propertyId: newPropertyId });
                }
            }
        }
        else
        {
            setBuildingId(undefined);
            setFloorId(undefined);
            if (isHandleChangeSelectedSessionIsFunction)
            {
                handleChangeSelectedSession({ propertyId: newPropertyId });
            }

        }

        resetFilters();

        handleClosePopup();

    }, [propertyId, setPropertyId, setBuildingId, propertyInfoMap, setFloorId, handleClosePopup, resetFilters]);

    useEffect(() =>
    {
        let condition = (isBuildingIdByDefault || isOnlyBuildingView);
        if (!!condition && !!propertyId && (!buildingId || buildingId === -1) && !!propertyInfoMap?.[propertyId]?.buildings)
        {
            const buildings = propertyInfoMap[propertyId].buildings;

            const allBuildingIds = Object.keys(buildings);

            if (allBuildingIds?.length > 0)
            {
                const newBuildingId = allBuildingIds[0];
                const floorId = getDefaultFloorIdFromPublicBuilding(buildings[newBuildingId]);
                setBuildingId(newBuildingId);
                setFloorId(floorId);

            }
        }
        // eslint-disable-next-line
    }, [isBuildingIdByDefault, isOnlyBuildingView, propertyId, propertyInfoMap]);

    const handleChangeFilter = React.useCallback((e, { name, value }) =>
    {
        if (name === "propertyFilter")
        {
            setPropertyFilter(value);
        }
        else
        {
            setBuildingFilter(value);
        }
    }, [setPropertyFilter, setBuildingFilter]);

    const handleMouseOverProperty = React.useCallback((propertyId) =>
    {
        setLocalPropertyId(propertyId);
        setBuildingFilter("");
    }, [setLocalPropertyId, setBuildingFilter]);

    // --- RENDER FUNCTIONS --- //

    /**
     * Property Dropdown Render
     * Map available properties to property list item
     * Temp propertyId should be undefined by default open
     * hover set temp propertyId
     * on click set atom propertyId
     */
    const renderPropertyDropDown = React.useCallback(() =>
    {
        if (propertyInfoMap)
        {
            let filteredPropertyInfoMap = { ...propertyInfoMap };

            // filter property id list based off of custom property list
            if (Array.isArray(options?.customPropertyList))
            {
                Object.keys(filteredPropertyInfoMap).forEach((propertyId) =>
                {
                    if (!options?.customPropertyList.includes(propertyId))
                    {
                        delete filteredPropertyInfoMap[propertyId];
                    }
                });
            }

            let propertyArray = sortDropdownCaseInsensitive(Object.values(filteredPropertyInfoMap), "longName", propertyId, "propertyId");

            return propertyArray.filter((propertyInfo) => doesStringIncludeFilter(propertyInfo.longName, propertyFilter)).map((propertyInfo) => (
                <DropdownListItem
                    className="propertyPopupTooltip"
                    position="bottom left"
                    key={"property-" + propertyInfo.propertyId}
                    isSelected={propertyInfo.propertyId === propertyId}
                    content={propertyInfo.longName}
                    onMouseOver={() => handleMouseOverProperty(propertyInfo.propertyId)}
                    onClick={() => handleSelectProperty(propertyInfo.propertyId)}
                />));
        }

    }, [propertyInfoMap, options, propertyFilter, handleMouseOverProperty, propertyId, handleSelectProperty]);

    /* building popup
     * Map available buildings dependent on local propertyId
     * on click set propertyId,buildingId,floorId
     */
    const renderBuildingDropdown = React.useCallback(() =>
    {
        if (localPropertyId)
        {
            let buildingArray = sortDropdownCaseInsensitive(Object.values(propertyInfoMap[localPropertyId].buildings), "longName", buildingId, "buildingId");

            if (Array.isArray(options.customBuildingList))
            {
                buildingArray = buildingArray.filter((building) => options.customBuildingList.includes(building.buildingId));
            }

            // if building long name fits filter...
            return buildingArray.filter((building) => doesStringIncludeFilter(building.longName, buildingFilter)).map((building) => (
                <DropdownListItem
                    className="propertyPopupTooltip"
                    position="bottom left"
                    isBuilding
                    isSelected={Number(building.buildingId) === Number(buildingId)}
                    key={"building-" + building.buildingId}
                    content={building.longName}
                    onClick={() => handleSelectBuilding(building.buildingId)}
                />));
        }
    }, [localPropertyId, buildingFilter, handleSelectBuilding, options, propertyInfoMap, buildingId]);

    return (
        <Popup
            open={isPopupOpen}
            className="subheaderPropertyPopup"
            position="bottom left"
            on="click"
            closeOnDocumentClick={true}
            closeOnEscape={true}
            onClose={handlePopupToggle}
            trigger={<ButtonIcon className="buttonPropertyToggle" icon="angle-circular" content={displayedPropertyBuildingName} onClick={handlePopupToggle} title={displayedPropertyBuildingName} />}
        >
            <Input icon="search" autoFocus autoComplete="off" placeholder={trans("SubehaderPropertyPopup.Search_Property")} name="propertyFilter" value={propertyFilter} onChange={handleChangeFilter} />
            <div className="searchDropdownCover">
                {
                    renderPropertyDropDown()
                }
            </div>

            {
                (localPropertyId) && (
                    <div className="subheaderBuildingPopup">
                        <Input icon="search" autoFocus autoComplete="off" placeholder={trans("SubehaderPropertyPopup.Search_Building")} name="buildingFilter" value={buildingFilter} onChange={handleChangeFilter} />
                        <div className="searchDropdownCover">
                            {
                                renderBuildingDropdown()
                            }
                        </div>
                    </div>
                )
            }

        </Popup>
    );
};
