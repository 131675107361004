// get zone geofence
// get users
// get selected users trajectory
// process trajectory

import
{
    geoJsonToTurfPolygon,
    isPointInPolygon,
    longLatToTurfPoint,
} from "mapsted.maps/mapFunctions/features";
import
{
    createEntityGeometry,
    createPointStyle,
    createVectorLayer,
} from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { deepCopy } from "mapsted.utils/objects";
import { Feature } from "ol";
import { Icon, Style } from "ol/style.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuid_v4 } from "uuid";
import
{
    useCompleteAnalyticsSummariesQuery,
    useLiveCompleteAnalyticsSummariesQuery, useUserSessionTrajectoryQuery
} from "../../../_api/queries";
import serverApi from "../../../_api/server.api";
import { SUPER_ADMIN_ROLE_ID } from "../../../_constants/config";
import { DATE_TYPES, DEFAULT_DATE_TYPE } from "../../../_constants/constants";
import
{
    AdminEmails,
    InsightsDistanceSignificantDigit,
    InsightsTickNumOfTickThreshold,
    InsightsXTickDistance,
    InsightsXTickDuration,
    SelectedTrajectoryColor,
    TrajectoryColor,
    TrajectoryThresholds,
} from "../../../_constants/insightsConstants";
import { convertDateTimeToLocalJSDateWithoutChangingTime, convertMinsToHrsAndMins, convertUnixSecondsToReadAbleTimeFormatWithTimeZone, createDateTimeZoneDateFromJSDate, formatDateTimeToUTCString, getLatestDateTimeBasedOnTimeZone, getLatestJsDateBasedOnTimeZone, isDateTimeToday } from "../../../_utils/date.luxon.utils";
import { getDateRange } from "../../../_utils/date.utils";
import
{
    createPropertyVisitsFromUserTrajectorySummariesQuery,
    getCleansUserOptions,
    getCoordinatesFromPoint,
    getDistanceBetweenPoints,
    getTeamsAndTeamPropertyIds,
    getUniqVisitId,
    getUserOptionFromUserObj,
    insightsTrajectoryStyle,
    lineSegmentStyleFunction,
    processCompleteAnalyticsSummaryResponseToTrajectorySummary,
    processAnalyticsSummaryResponseToSessionStatus,
    processAnalyticsSummaryResponseToUserInfos,
    generateUniqIdFromPropertyIdBuildingFloorId,
    isTrajectoryConstructed,
} from "../../../_utils/insights.utils";
import { refetchQuery } from "../../../_utils/query.utils";
import { getBuildingName } from "../../../_utils/utils";
import { companyTeamsState, selectedTeamState } from "../../../store/AppAtoms";
import
{
    activeTimeZoneSelectorState,
    activeZoneHistoryByFloorDataState,
    buildingIdState,
    dateTypeState,
    floorIdState,
    propertyIdState,
    propertyInfoMapState
} from "../../../store/DashboardAtoms";
import
{
    insightsSelectedSessionIdxState,
    insightsSelectedSessionUIDState,
    insightsSelectedUserEmailState,
    insightsSelectedUserIdArrayState,
    insightsSessionUserUIDState,
} from "../../../store/InsightsAtoms";
import useCommon from "../useCommon";
import { useTranslation } from "react-i18next";


//after every minute refresh live data
let timeIntervalInMilleSec = 60 * 1000;//10000;//60000; // duration for new live data fetch




/**
 * @summary This hook is intended to be used only once in insight page, because multiple instances of this hook creates multiple clones of same logic and hear we have internal state management not the recoil state management
 * but if multiple components are dependent on the data from this hook , it is recommended
 * to share data in a context and use the `useInsights` hook for common data.
 *
 * The `useInsights` hook is designed to be used  by multiple components of the same page.
 *
 * By using `useInsights` hook, we are able to share data in a context and avoid
 * using this hook in multiple components of the same page.
 *
 */
const useInsightsCommon = () =>
{

    const zoneFloorHistory = useRecoilValue(activeZoneHistoryByFloorDataState);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);
    const propertyInfoMap = useRecoilValue(propertyInfoMapState);
    const selectedTeam = useRecoilValue(selectedTeamState);
    const companyTeams = useRecoilValue(companyTeamsState);

    const [selectedSessionUserId, setSelectedSessionUserUID] = useRecoilState(insightsSessionUserUIDState);
    const [selectedUserUIDArray, setSelectedUserUIDArray] = useRecoilState(insightsSelectedUserIdArrayState);

    const [selectedSessionUserIdx, setSelectedUserSessionIdx] = useRecoilState(insightsSelectedSessionIdxState);
    const [selectedUserEmail, setSelectedUserEmail] = useRecoilState(insightsSelectedUserEmailState);
    const [selectedUserSessionUID, setSelectedUserSessionUID] = useRecoilState(insightsSelectedSessionUIDState);
    const [floorId, setFloorId] = useRecoilState(floorIdState);
    const [buildingId, setBuildingId] = useRecoilState(buildingIdState);
    const [propertyId, setPropertyID] = useRecoilState(propertyIdState);
    const { filters: { analyticsRequestFilter }, considerTimeInDateRange, dateRange, setDateRange, propertyTimeZone, setConsiderTimeInDateRange } = useCommon();

    const { endTimeUTC, } = analyticsRequestFilter;

    const [dataUpdatedAt, setDataUpdatedAt] = useState(undefined); // Keeps track of when the live data is received recently
    const [liveDateRange, setLiveDateRange] = useState({});
    const dateRangeRef = useRef(dateRange);
    const [activePropertyVisitsData, setActivePropertyVisitsData] = useState(undefined); // keeps track of the previously merged property visits memo FOR LIVE QUERIES.
    const [activeUserSessionStatusData, setActiveUserSessionStatusData] = useState(undefined);
    const [userInfoHash, setUserInfoHash] = useState({ emailsHashWithLatestUserInfo: {}, userEmailToUserUIDHash: {} });

    const staticUserInfoHashRef = useRef(userInfoHash);
    const staticPropertyVisitsRef = useRef(activePropertyVisitsData);
    const staticUserSessionStatusRef = useRef(activeUserSessionStatusData);
    const prevStatusOfConsiderTimeRef = useRef(considerTimeInDateRange);
    const intervalRef = useRef(null);
    const setDateType = useSetRecoilState(dateTypeState);
    const prevPropertyTimeZoneRef = useRef({});

    const { t } = useTranslation();


    // #region  static queries
    //this hooks give complete analytics summaries in future we will be using only this api to populate the insights page
    const analyticsCompleteSummaryQuery = useCompleteAnalyticsSummariesQuery();

    // gets all available users with data
    const propertiesUsersQuery = useMemo(() =>
    {
        const companyUsersAndPropertyIdsList = getTeamsAndTeamPropertyIds(companyTeams);

        const queryCopy = { ...analyticsCompleteSummaryQuery, dataUpdatedAt: Date.now() };
        let result;
        if (analyticsCompleteSummaryQuery.isSuccess)
        {
            result = processAnalyticsSummaryResponseToUserInfos(deepCopy(analyticsCompleteSummaryQuery.data), companyUsersAndPropertyIdsList);
        }
        queryCopy.data = result;
        queryCopy.isLoading = !queryCopy.isSuccess;
        return queryCopy;
    }, [companyTeams, analyticsCompleteSummaryQuery.dataUpdatedAt, propertyInfoMap]);







    // gets trajectory data to be plotted on map and chart
    const userSessionTrajectoryQueryCall = useUserSessionTrajectoryQuery();



    // gets list of trajectories for the given userUID/email pair
    const userTrajectorySummariesQuery = useMemo(() =>
    {
        const queryCopy = { ...analyticsCompleteSummaryQuery, dataUpdatedAt: Date.now() };
        // console.log(selectedUserUIDArray);
        let result;
        if (analyticsCompleteSummaryQuery.isSuccess)
        {
            result = processCompleteAnalyticsSummaryResponseToTrajectorySummary(deepCopy(analyticsCompleteSummaryQuery.data), selectedUserUIDArray?.[0], propertyInfoMap);

        }
        queryCopy.data = result;
        queryCopy.isLoading = !queryCopy.isSuccess;
        return queryCopy;

    }, [analyticsCompleteSummaryQuery.dataUpdatedAt, selectedUserUIDArray]);

    // gets users sessions summaries
    const userSessionStatusesQuery = useMemo(() =>
    {

        const queryCopy = { ...analyticsCompleteSummaryQuery, dataUpdatedAt: Date.now() };
        // console.log(selectedUserUIDArray);
        let result;
        if (analyticsCompleteSummaryQuery.isSuccess)
        {
            result = processAnalyticsSummaryResponseToSessionStatus(deepCopy(analyticsCompleteSummaryQuery.data));

        }
        queryCopy.data = result;
        queryCopy.isLoading = !queryCopy.isSuccess;
        return queryCopy;


    }, [analyticsCompleteSummaryQuery.dataUpdatedAt]);

    // #region  live queries

    const liveCompleteAnalyticsSummary = useLiveCompleteAnalyticsSummariesQuery(liveDateRange);

    const liveUsersQuery = useMemo(() =>
    {

        const companyUsersAndPropertyIdsList = getTeamsAndTeamPropertyIds(companyTeams);

        const queryCopy = { ...liveCompleteAnalyticsSummary, dataUpdatedAt: Date.now() };
        let result;
        if (liveCompleteAnalyticsSummary.isSuccess)
        {
            result = processAnalyticsSummaryResponseToUserInfos(liveCompleteAnalyticsSummary.data, companyUsersAndPropertyIdsList);
        }
        queryCopy.data = result;
        queryCopy.isLoading = !queryCopy.isSuccess;
        return queryCopy;
    }, [companyTeams, liveCompleteAnalyticsSummary.dataUpdatedAt]);

    // const liveUserTrajectorySummariesQuery = useLiveUserTrajectorySummariesQuery(liveDateRange);

    // gets live users sessions summaries
    const liveUserSessionStatusesQuery = useMemo(() =>
    {
        const queryCopy = { ...liveCompleteAnalyticsSummary, dataUpdatedAt: Date.now() };

        let result;
        if (analyticsCompleteSummaryQuery.isSuccess)
        {
            result = processAnalyticsSummaryResponseToSessionStatus(deepCopy(analyticsCompleteSummaryQuery.data));

        }
        queryCopy.data = result;
        queryCopy.isLoading = !queryCopy.isSuccess;
        return queryCopy;


    }, [liveCompleteAnalyticsSummary.dataUpdatedAt]);



    const liveUserTrajectorySummariesQuery = useMemo(() =>
    {
        let result;
        if (liveCompleteAnalyticsSummary.isSuccess)
        {
            result = processCompleteAnalyticsSummaryResponseToTrajectorySummary(liveCompleteAnalyticsSummary.data, selectedUserUIDArray?.[0], propertyInfoMap);
        }
        return {
            ...liveCompleteAnalyticsSummary,
            dataUpdatedAt: Date.now(),
            data: result,
        };
    }, [liveCompleteAnalyticsSummary.dataUpdatedAt, selectedUserUIDArray, propertyInfoMap]);

    /**===================================SOME EDGE CASES AND CLEAN UP STARTS HERE========================================================= */

    useEffect(() =>
    {
        dateRangeRef.current = dateRange;
        // date ranges changes all the static api get called if any active interval is running clear it
    }, [dateRange]);


    /**
     * This effect is used to set the initial state of the `dateRange` and `dateType` when the component is mounted.
     * It also resynchronizes the `dateRange` when the `propertyTimeZone` changes.
     *
     * @return {void}
     */
    useEffect(() =>
    {
        // Set the initial state of the `dateRange` and `dateType` when the component is mounted.
        const isTodayDateAllowed = true;
        const dateType = DATE_TYPES.TODAY;
        const dateRange = getDateRange(dateType, isTodayDateAllowed, propertyTimeZone.id);

        setDateRange(dateRange);
        setDateType(dateType);

        // Store the current `propertyTimeZone` in a reference to be used in the next effect.
        prevPropertyTimeZoneRef.current = propertyTimeZone;

        return () =>
        {
            //Today is the only date option available on boost ,tags & insights
            //so resetting to DEFAULT_DATE_TYPE on unmount DEFAULT_DATE_TYPE is  applicable for entire app
            setDateRange(getDateRange(DEFAULT_DATE_TYPE, false, propertyTimeZone.id));
            setConsiderTimeInDateRange(false);
        };
    }, []);


    useEffect(() =>
    {
        // When `considerTimeInDateRange` changes from `true` to `false` and `endDate` is today, update all the static API's as `endDate` might have been altered and we have lost reference to previous merged data
        const endDateTime = createDateTimeZoneDateFromJSDate(dateRange.endDate, propertyTimeZone.id);
        if (prevStatusOfConsiderTimeRef.current && !considerTimeInDateRange && isDateTimeToday(endDateTime))
        {
            setDateRange((prev) => ({ ...prev, endDate: getLatestJsDateBasedOnTimeZone(propertyTimeZone.id) }));
        }
        prevStatusOfConsiderTimeRef.current = considerTimeInDateRange;

    }, [considerTimeInDateRange]);

    /**===================================SOME EDGE CASES AND CLEAN UP ENDS HERE========================================================= */



    //#region static api's data to local state
    /** ==========================STATIC_APIS_STARTS======================================================== */



    /**
     * mapping of email to list of email userUID values
     * - we have multiple casing of the same email so we group them
     * - navigation requires email and userUID to be sent when fetching trajectories (v2)
     */
    const { staticApisUserEmailToUserUIDHash, staticApisUserEmailsHashWithLatestUserInfo } = useMemo(() =>
    {
        // create a mapping of email to list of UIDs
        let staticApisUserEmailToUserUIDHash = {};
        let staticApisUserEmailsHashWithLatestUserInfo = {};

        if (propertiesUsersQuery.isSuccess)
        {
            //filter if not admin account
            let filterOptions = true;
            let userRoleId = serverApi.userData?.user?.userRole?.roleId;

            if (userRoleId === SUPER_ADMIN_ROLE_ID)
            {
                filterOptions = false;
            }

            let userEmails = propertiesUsersQuery?.data?.userEmails;

            if (Array.isArray(userEmails))
            {
                if (filterOptions)
                {
                    userEmails = userEmails.filter((user) => !AdminEmails.includes(user.email));
                }

                userEmails.forEach((user) =>
                {
                    let { userUID, email = "" } = user;
                    email = userUID === user.email ? email : email.toLowerCase();

                    if (staticApisUserEmailToUserUIDHash[email])
                    {
                        staticApisUserEmailToUserUIDHash[email].push(userUID);
                    }
                    else
                    {
                        staticApisUserEmailToUserUIDHash[email] = [userUID];
                    }
                    staticApisUserEmailsHashWithLatestUserInfo[email] = user;
                });
            }
        }

        return { staticApisUserEmailToUserUIDHash, staticApisUserEmailsHashWithLatestUserInfo };
    }, [propertiesUsersQuery.dataUpdatedAt]);

    useEffect(() =>
    {
        setUserInfoHash({ userEmailToUserUIDHash: staticApisUserEmailToUserUIDHash, emailsHashWithLatestUserInfo: staticApisUserEmailsHashWithLatestUserInfo });
        staticUserInfoHashRef.current = { userEmailToUserUIDHash: staticApisUserEmailToUserUIDHash, emailsHashWithLatestUserInfo: staticApisUserEmailsHashWithLatestUserInfo };
    }, [propertiesUsersQuery.dataUpdatedAt, staticApisUserEmailToUserUIDHash, staticApisUserEmailsHashWithLatestUserInfo]);

    /**
     * Hash map of property id -> visit information for the selected user
     */
    const propertyVisits = useMemo(() =>
    {
        const propertyVisits = createPropertyVisitsFromUserTrajectorySummariesQuery({ userTrajectorySummariesQuery, propertyInfoMap });
        return propertyVisits;
    }, [userTrajectorySummariesQuery?.dataUpdatedAt, propertyInfoMap]);

    /**
     * Note: is needed?
     * watcher updates the active static property visits
     */
    useEffect(() =>
    {
        setActivePropertyVisitsData(propertyVisits);
        staticPropertyVisitsRef.current = propertyVisits;
    }, [propertyVisits]);


    /**
     * Watcher
     * updates the session statuses static data based on query data
     */
    useEffect(() =>
    {
        setActiveUserSessionStatusData(userSessionStatusesQuery);
        staticUserSessionStatusRef.current = userSessionStatusesQuery;
    }, [userSessionStatusesQuery.dataUpdatedAt]);


    /** =================================STATIC_APIS_ENDS========================================================= */







    /**========================================= LIVE APIS AND DATA MAPPING LOGICS_START=============================================== */


    /** |===============================|
     *  |CLOCK LOGIC  START             |
     *  |===============================|
     */

    // check if current time (in the properties time zone) is the same "day" as end day and custom time is not being used
    const shouldIncludeLiveData = useMemo(() =>
    {

        const isEndDateToday = isDateTimeToday(createDateTimeZoneDateFromJSDate(dateRange?.endDate, timeZone.id), timeZone.id);

        // check if current time (in the properties time zone) is the same "day" as end day
        if ((isEndDateToday && !considerTimeInDateRange))
        {
            return true;
        }
        return false;
    }, [endTimeUTC, dateRange?.endDate, considerTimeInDateRange]);
    //#region show live data
    /**
     * WATCHER to show live data only if current time is included in the date range
     */
    useEffect(() =>
    {

        // Will check if current time is always included in the Date Range
        if (shouldIncludeLiveData)
        {
            intervalRef.current = setInterval(() =>
            {

                setDataUpdatedAt(() =>
                {

                    let liveQueryStartDate;

                    // OLD CODE BELOW, ASSUMED QUERYING FOR THE PAST MIN WOULD GET TRAJECTORY

                    // if (prevDataUpdatedAt)
                    // {
                    //     liveQueryStartDate = fromJsDateToDateTime(new Date(prevDataUpdatedAt), propertyTimeZone.id);
                    //     liveQueryStartDate = convertDateTimeToLocalJSDateWithoutChangingTime(liveQueryStartDate);
                    // }
                    // else
                    // {
                    //     // do we need ref here??
                    //     liveQueryStartDate = dateRangeRef.current.endDate;
                    // }

                    // find in property's time zone
                    // data updated at will be js time
                    const liveQueryEndDate = getLatestJsDateBasedOnTimeZone(propertyTimeZone.id);

                    // for version 1 of insights live, we will need to query for start of day
                    liveQueryStartDate = getLatestDateTimeBasedOnTimeZone(propertyTimeZone.id);
                    liveQueryStartDate = liveQueryStartDate.startOf("day");
                    liveQueryStartDate = convertDateTimeToLocalJSDateWithoutChangingTime(liveQueryStartDate);


                    // convert to UTC for query based on property time and
                    const startTimeUTC = formatDateTimeToUTCString(createDateTimeZoneDateFromJSDate(liveQueryStartDate, propertyTimeZone.id));
                    const endTimeUTC = formatDateTimeToUTCString(createDateTimeZoneDateFromJSDate(liveQueryEndDate, propertyTimeZone.id));


                    //query based on property time
                    setLiveDateRange({ startTimeUTC: startTimeUTC, endTimeUTC: endTimeUTC });

                    // const newLiveQueryStartTime = liveQueryEndDate;

                    //this date info is used to display time in ui live data updated info
                    const nowDate = new Date();
                    return nowDate;

                }); // maybe move this to the end of the merge function?


            }, timeIntervalInMilleSec);
        }
        // Clean Up timeIntervalInMilleSec on unmount
        return () => clearInterval(intervalRef.current);
    }, [shouldIncludeLiveData]);

    /** |===============================|
     *  |       CLOCK LOGIC ENDS        |
     *  |===============================|
     */


    //#region live data to into state
    /** ================LIVE USERS QUERY AND LOGICS================ */

    const { liveAPIsUserEmailToUserUIDHash, liveApisUserEmailsHashWithLatestUserInfo } = useMemo(() =>
    {
        // create a mapping of email to list of UIDs
        let liveAPIsUserEmailToUserUIDHash = {};
        let liveApisUserEmailsHashWithLatestUserInfo = {};
        if (propertiesUsersQuery.isSuccess)
        {
            //filter if not admin account
            let filterOptions = true;
            let userRoleId = serverApi.userData?.user?.userRole?.roleId;

            if (userRoleId === SUPER_ADMIN_ROLE_ID)
            {
                filterOptions = false;
            }

            let userEmails = liveUsersQuery?.data?.userEmails;

            if (Array.isArray(userEmails))
            {
                if (filterOptions)
                {
                    userEmails = userEmails.filter((user) => !AdminEmails.includes(user.email));
                }

                userEmails.forEach((user) =>
                {
                    let { userUID, email } = user;
                    email = email === userUID ? email : email.toLowerCase();

                    if (liveAPIsUserEmailToUserUIDHash[email])
                    {
                        liveAPIsUserEmailToUserUIDHash[email].push(userUID);
                    }
                    else
                    {
                        liveAPIsUserEmailToUserUIDHash[email] = [userUID];
                    }

                    liveApisUserEmailsHashWithLatestUserInfo[email] = user;

                });
            }
        }

        return { liveAPIsUserEmailToUserUIDHash, liveApisUserEmailsHashWithLatestUserInfo };


    }, [liveUsersQuery.dataUpdatedAt]);

    useEffect(() =>
    {
        if (shouldIncludeLiveData)
        {
            setUserInfoHash((prevHash) =>
            {
                const { userEmailToUserUIDHash, emailsHashWithLatestUserInfo } = deepCopy(prevHash);

                Object.entries(liveAPIsUserEmailToUserUIDHash).forEach(([email, userIds]) =>
                {
                    if (email)
                    {
                        if (!userEmailToUserUIDHash[email])
                        {
                            userEmailToUserUIDHash[email] = userIds;
                        }
                        else if (userIds.length)
                        {
                            const userIdsSet = new Set([...userIds, ...userEmailToUserUIDHash[email]]);
                            userEmailToUserUIDHash[email] = [...userIdsSet];
                        }
                    }
                });

                Object.entries(liveApisUserEmailsHashWithLatestUserInfo).forEach(([email, user]) =>
                {
                    if (user)
                    {
                        emailsHashWithLatestUserInfo[email] = user;
                    }
                });

                return { userEmailToUserUIDHash, emailsHashWithLatestUserInfo };
            });
        }
        else
        {
            setUserInfoHash(staticUserInfoHashRef.current);
        }

    }, [liveAPIsUserEmailToUserUIDHash, liveApisUserEmailsHashWithLatestUserInfo, liveUsersQuery.dataUpdatedAt]);


    /** =========================LIVE USER TRAJECTORIES QUERY AND LOGICS END     ================ */





    /*********************************** LIVE USER TRAJECTORIES DATA MAPPING LOGICS START=============================== */

    /**
     * Hash map of property id -> visit information for the selected user
     */
    const livePropertyVisits = useMemo(() =>
    {
        const livePropertyVisits = createPropertyVisitsFromUserTrajectorySummariesQuery({ userTrajectorySummariesQuery: liveUserTrajectorySummariesQuery, propertyInfoMap });

        return livePropertyVisits;
    }, [liveUserTrajectorySummariesQuery.dataUpdatedAt, propertyInfoMap]);


    /**
     * merge live and static property visits
     * when live data is included
     */
    useEffect(() =>
    {
        if (shouldIncludeLiveData)
        {

            setActivePropertyVisitsData((activePropertyVisitsData) =>
            {
                let mergedPropertyVisits = deepCopy(activePropertyVisitsData);

                Object.keys(livePropertyVisits).forEach((propertyId) =>
                {
                    // if there are already visits for the property ID in the static data
                    if (mergedPropertyVisits[propertyId])
                    {
                        const propertyVisit = livePropertyVisits[propertyId];

                        // for each live visit in the property, check if the sessionUID exists in the static data
                        // if not, add it to the merged data
                        propertyVisit.forEach((visit) =>
                        {
                            if (!mergedPropertyVisits[propertyId].find((liveVisit) => liveVisit.sessionUID === visit.sessionUID))
                            {
                                mergedPropertyVisits[propertyId].push(visit);
                            }
                            else
                            {
                                mergedPropertyVisits[propertyId] = [visit];
                            }
                        });
                    }
                    else
                    {
                        mergedPropertyVisits[propertyId] = livePropertyVisits[propertyId];
                    }
                });

                return mergedPropertyVisits;
            });
        }
        else
        {
            setActivePropertyVisitsData(staticPropertyVisitsRef.current);
        }
    }, [livePropertyVisits]);

    /************* LIVE USER TRAJECTORIES DATA MAPPING LOGICS END *************/






    /************* LIVE USER TRAJECTORIES STATUS DATA MAPPING LOGICS START *************/




    /**
     * Watcher merges live user session statuses with current user session statuses
     *  1. If shouldIncludeLiveData is true, merge live user session statuses with current user session statuses.
     *  2. Otherwise, use regular user session statuses (data without live query updates).
     */
    useEffect(() =>
    {
        let liveUserSessionStatus = liveUserSessionStatusesQuery;
        if (shouldIncludeLiveData)
        {
            setActiveUserSessionStatusData((prev) =>
            {
                let mergedUserSessionStatues = deepCopy(prev || {});
                let mergedUserSessionStatusesData = deepCopy(prev?.data || {});


                mergedUserSessionStatues.data = mergedUserSessionStatusesData;

                if (liveUserSessionStatus?.isSuccess && liveUserSessionStatus?.data)
                {
                    // if live query has data, go through all the session UIDs,
                    // any that exist update status and end times
                    // any that don't exist add them to map
                    // NOTE: we will use prev query status

                    Object.keys(liveUserSessionStatus.data).forEach((sessionUID) =>
                    {
                        if (mergedUserSessionStatues.data[sessionUID])
                        {
                            let updatedSession = deepCopy(mergedUserSessionStatues.data[sessionUID]);
                            updatedSession.endTimeUTC = liveUserSessionStatus.data[sessionUID].endTimeUTC;
                            updatedSession.sessionPostProcessingStatus = liveUserSessionStatus.data[sessionUID].sessionPostProcessingStatus;
                            mergedUserSessionStatues.data[sessionUID] = updatedSession;
                        }
                        else
                        {
                            mergedUserSessionStatues.data[sessionUID] = liveUserSessionStatus.data[sessionUID];
                        }
                    });
                }

                return mergedUserSessionStatues;

            });
        }
        else
        {

            setActiveUserSessionStatusData(staticUserSessionStatusRef.current);
        }
    }, [liveUserSessionStatusesQuery.dataUpdatedAt]);


    /*********************************************** LIVE USER TRAJECTORIES STATUS DATA MAPPING LOGICS END */


    /**** MERGED DATA FROM LIVE AND STATIC START **** */

    const { userEmailToUserUIDHash, emailsHashWithLatestUserInfo } = useMemo(() => userInfoHash, [userInfoHash]);


    const mergedPropertyVisits = useMemo(() => activePropertyVisitsData, [activePropertyVisitsData]);


    const mergedUserSessionStatuses = useMemo(() =>
    {
        const selectedUserId = selectedUserUIDArray?.[0];
        if (!selectedUserId)
        {
            return {
                ...activeUserSessionStatusData,
                data: undefined
            };
        }
        const data = Object.values(activeUserSessionStatusData?.data ?? {}).reduce((acc, session) =>
        {
            // console.log(session.userUID === selectedUserId, session, session.userUID, selectedSessionUserId);
            if (session.userUID === selectedUserId)
            {
                acc[session.sessionUID] = session;
            }
            return acc;
        }, {});

        return {
            ...activeUserSessionStatusData,
            data
        };
    }, [activeUserSessionStatusData, selectedUserUIDArray]);

    /**** MERGED DATA FROM LIVE AND STATIC END **** */


    /**
     * dropdown list of selectable users
     */

    const userDropdownOptions = useMemo(() =>
    {
        let options = [];

        // add any item with an email to the dropdown option list
        Object.keys(userEmailToUserUIDHash).filter((email) => !!email && email?.trim()?.length).forEach((email) =>
        {
            options.push(getUserOptionFromUserObj(emailsHashWithLatestUserInfo[email]));
        });
        return getCleansUserOptions(options);
    }, [userEmailToUserUIDHash, emailsHashWithLatestUserInfo]);


    /**
     * a list of property ids that the user has visited in the selected date range
     */
    const propertyIdsThatHaveDataForSelectedUserDateRange = useMemo(() =>
    {
        let propertiesWithDataArray = [];

        if (mergedPropertyVisits)
        {
            propertiesWithDataArray = Object.keys(mergedPropertyVisits);
        }

        return propertiesWithDataArray;
    }, [mergedPropertyVisits]);

    /**
     * Dropdown options to show sessions for the selected user -> property
     */
    const { userPropertySessionDropdownOptions, userPropertySessionDropdownOptionsHash } = useMemo(() =>
    {
        let optionsHash = {};

        if (mergedPropertyVisits?.[propertyId] && !userTrajectorySummariesQuery.isLoading)
        {
            let userTrajectories = mergedPropertyVisits[propertyId];
            // sort trajectories by start time
            // const sortedUserTrajectories = userTrajectories.sort(
            //     (a, b) => b.startTime.unixTime_ms - a.startTime.unixTime_ms
            // );

            userTrajectories.filter((trajectory) => isTrajectoryConstructed(trajectory)).forEach(
                (trajectory) =>
                {
                    const {
                        sessionUID,
                        duration_min,
                        sessionIdx,
                        startTime,
                        userUID,
                    } = trajectory;
                    const readableStartTime =
                        convertUnixSecondsToReadAbleTimeFormatWithTimeZone(
                            startTime?.unixTime_ms / 1000,
                            timeZone.id
                        );

                    // create key using sessionUID and sessionIdx
                    const optionsKey = getUniqVisitId({ sessionUID, sessionIdx });

                    // DEPRECATED -> find property session with highest duration
                    // if (optionsHash[optionsKey])
                    // {
                    //     if (duration_min > optionsHash[optionsKey].duration_min)
                    //     {
                    //         optionsHash[optionsKey].duration_min = duration_min;
                    //         optionsHash[optionsKey].session_idx = sessionIdx;
                    //         optionsHash[optionsKey].text = readableStartTime;
                    //         optionsHash[optionsKey].userUID = userUID;
                    //     }
                    // } else{
                    optionsHash[optionsKey] = {
                        key: optionsKey,
                        value: optionsKey,
                        sessionUID: sessionUID,
                        text: readableStartTime,
                        duration_min: duration_min,
                        session_idx: sessionIdx,
                        userUID: userUID,
                        data: trajectory
                    };
                    // }
                }
            );
        }
        const optionsArray = Object.values(optionsHash);

        optionsArray.sort(({ data: a }, { data: b }) => b.startTime.unixTime_ms - a.startTime.unixTime_ms);
        return { userPropertySessionDropdownOptions: optionsArray, userPropertySessionDropdownOptionsHash: optionsHash };
    }, [propertyId, mergedPropertyVisits, timeZone, userTrajectorySummariesQuery.dataUpdatedAt]);

    /**
     * WATCHER for user dropdown options, selects default user
     **/
    useEffect(() =>
    {
        if (userDropdownOptions.length > 0)
        {
            let selectedEmail;

            // select default user iff current user is not in the dropdown list
            if (userDropdownOptions.filter((userOption) => userOption.value === selectedUserEmail).length === 0)
            {
                const { data } = userDropdownOptions[0];


                setSelectedUserEmail(data.email);
                setSelectedUserUIDArray([data.userId]);

            }
        }
        else
        {
            setSelectedUserEmail(undefined);
            setSelectedUserUIDArray(undefined);
        }
    }, [userDropdownOptions]);

    /**
     * WATCHER for user/property session options, selects default session
     */
    useEffect(() =>
    {
        let selectedSession;
        let selectedSessionIdx;
        let selectedSessionUserUID;

        // check if selected session is already in list before selecting default session
        if (userPropertySessionDropdownOptions.filter((session) => session.sessionUID === selectedUserSessionUID && session.session_idx === selectedSessionUserIdx).length === 0)
        {

            if (userPropertySessionDropdownOptions.length > 0)
            {
                selectedSession = userPropertySessionDropdownOptions[0].sessionUID;
                selectedSessionIdx = userPropertySessionDropdownOptions[0].session_idx;
                selectedSessionUserUID = userPropertySessionDropdownOptions[0].userUID;
            }
            setSelectedSessionUserUID(selectedSessionUserUID);
            setSelectedUserSessionIdx(selectedSessionIdx);
            setSelectedUserSessionUID(selectedSession);
        }
    }, [userPropertySessionDropdownOptions]);

    /**
     * WATCHER for query changes, sets floor ID to floor ID that has trajectory data
     */
    useEffect(() =>
    {
        const { isSuccess, data } = userSessionTrajectoryQueryCall;

        // console.log(data);

        if (isSuccess && data)
        {
            // NOTE: don't set property ID in this use effect as it will cause a crash (too many state updates error)
            if (
                floorId !== data.floorId
                || Number(buildingId) !== data.buildingId
            )
            {
                setBuildingId(Number(data.buildingId));
                setFloorId(Number(data.floorId));
            }
        }
    }, [userSessionTrajectoryQueryCall?.dataUpdatedAt]);



    //#end region FILTER QUERY METHODS

    //#region DISPLAYED DATA PROCESSING

    const floorZoneGeofenceIdToTurfPolygonHash = useMemo(() =>
    {
        let zoneGeofenceHash = zoneFloorHistory?.data;
        let resultHash = {};

        try
        {
            if (!zoneGeofenceHash)
            {
                return;
            }

            Object.keys(zoneGeofenceHash).forEach((zoneGeofenceId) =>
            {
                const {
                    boundary,
                    floorId: zgFloorId,
                    label,
                } = zoneGeofenceHash[zoneGeofenceId];

                if (floorId === zgFloorId)
                {
                    const turfPolygon = geoJsonToTurfPolygon(boundary);

                    resultHash[zoneGeofenceId] = {
                        turfPolygon,
                        label,
                    };
                }
            });
        }
        catch (error)
        {
            console.error("floorZoneGeofenceIdToTurfPolygonHash error", error);

        }

        return { id: zoneFloorHistory?.id, data: resultHash };
    }, [zoneFloorHistory?.id, floorId]);

    /**
     * Creates an array of objects - {shape, color, deltaTime_s, trajectoryPoint}
     */
    const processedTrajectoryData = useMemo(() =>
    {
        const { isSuccess, data } = userSessionTrajectoryQueryCall;
        let meta = {};
        let processedTrajectoryData = [];
        if (isSuccess && data && data.floorId === floorId)
        {
            const { trajectory, ...rest } = data;
            meta = rest;
            let prevTimeStamp_s = 0;

            trajectory.forEach((trajectoryPoint, i) =>
            {
                const { unix_time_s } = trajectoryPoint;
                let color = TrajectoryColor.BLANK;
                let selectedColor = SelectedTrajectoryColor.BLANK;
                let deltaTime_s = 0;

                if (prevTimeStamp_s)
                {
                    deltaTime_s = unix_time_s - prevTimeStamp_s;
                    color = TrajectoryColor.GREEN;
                    selectedColor = SelectedTrajectoryColor.GREEN;
                    /*
                    if (deltaTime_s > TrajectoryThresholds.RED)
                    {
                        color = TrajectoryColor.RED;
                        selectedColor = SelectedTrajectoryColor.RED;
                    }
                    else if (deltaTime_s > TrajectoryThresholds.ORANGE)
                    {
                        color = TrajectoryColor.ORANGE;
                        selectedColor = SelectedTrajectoryColor.ORANGE;
                    }
                    else if (deltaTime_s > TrajectoryThresholds.GREEN)
                    {
                        color = TrajectoryColor.GREEN;
                        selectedColor = SelectedTrajectoryColor.GREEN;
                    }*/
                }

                let coordinates = getCoordinatesFromPoint(trajectoryPoint);
                const shape = {
                    type: ShapeTypes.CIRCLE,
                    coordinates: coordinates,
                };

                const id = `${unix_time_s}-${i}`;
                processedTrajectoryData.push({
                    color,
                    selectedColor,
                    deltaTime_s,
                    shape,
                    trajectoryPoint,
                    id: id,
                });

                prevTimeStamp_s = unix_time_s;
            });
        }

        return {
            id: `${userSessionTrajectoryQueryCall?.dataUpdatedAt}${floorId}`,
            data: processedTrajectoryData,
            meta
        };
    }, [userSessionTrajectoryQueryCall?.dataUpdatedAt, floorId]);

    /**
     * Gets processed trajectory map layer data from userSessionTrajectoryQueryCall
     */
    const trajectoryMapLayer = useMemo(() =>
    {
        let trajectoryLayers;

        if (processedTrajectoryData?.data)
        {
            let trajectoryLineLayer = createVectorLayer("trajectoryLine");
            let trajectoryLineSource = trajectoryLineLayer.getSource();

            let trajectoryPointLayer = createVectorLayer("trajectoryPoint");
            let trajectoryPointSource = trajectoryPointLayer.getSource();

            let lineStringCoords = [];

            let trajectoryLength = processedTrajectoryData.data.length - 1;
            processedTrajectoryData.data.forEach(
                (
                    { shape, color, selectedColor, deltaTime_s, id: featureId },
                    idx
                ) =>
                {
                    const style = createPointStyle(
                        insightsTrajectoryStyle({ color: "rgba(255, 255, 255, 0)" }) //  keeps all the points hidden
                    );
                    const selectedStyleTemplate = insightsTrajectoryStyle({
                        color: selectedColor,
                    });
                    const selectedStyle = createPointStyle(
                        selectedStyleTemplate
                    );
                    const geometry = createEntityGeometry(shape);
                    const selectedGeometry = createEntityGeometry(shape, { radius: 2 });

                    let zIndex = 0;
                    // don't add point to map if color is blank
                    if (color !== TrajectoryColor.BLANK)
                    {
                        zIndex = 1;
                    }

                    //Create feature
                    let feature = new Feature({
                        id: featureId,
                        color: color,
                        hoverText: undefined,
                        position: shape.coordinates,
                        zIndex: zIndex,
                        defaultStyle: style,
                        selectedStyle: selectedStyle,
                        defaultGeometry: geometry,
                        selectedGeometry: selectedGeometry,
                    });

                    feature.setGeometry(geometry);
                    feature.setId(featureId);
                    feature.setStyle(style);

                    trajectoryPointSource.addFeature(feature);
                    if (idx === 0)
                    {
                        let startGeometry = createEntityGeometry({
                            type: ShapeTypes.POINT,
                            coordinates: shape.coordinates,
                        });
                        let feature = new Feature({
                            id: uuid_v4(),
                            hoverText: "Visit Start",
                            position: shape.coordinates,
                            zIndex: 2,
                        });

                        let style = new Style({
                            geometry: startGeometry,
                            image: new Icon({
                                src: "/img/icon-location-green.svg",
                                crossOrigin: "Anonymous",
                                anchor: [0.5, 0.8],
                                rotateWithView: true,
                                scale: 0.25,
                            }),
                        });
                        feature.setStyle(style);
                        feature.setGeometry(startGeometry);
                        feature.setStyle(style);

                        trajectoryPointSource.addFeature(feature);
                    }
                    else if (idx === trajectoryLength)
                    {
                        let endGeometry = createEntityGeometry({
                            type: ShapeTypes.POINT,
                            coordinates: shape.coordinates,
                        });
                        let feature = new Feature({
                            id: uuid_v4(),
                            hoverText: "Visit End",
                            position: shape.coordinates,
                            zIndex: 2,
                        });

                        let style = new Style({
                            geometry: endGeometry,
                            image: new Icon({
                                src: "/img/icon-location-red.svg",
                                crossOrigin: "Anonymous",
                                anchor: [0.5, 0.8],
                                rotateWithView: true,
                                scale: 0.25,
                            }),
                        });

                        feature.setStyle(style);
                        feature.setGeometry(endGeometry);
                        feature.setStyle(style);

                        trajectoryPointSource.addFeature(feature);
                    }

                    lineStringCoords.push(shape.coordinates);
                }
            );

            // create and add line string feature to map
            const shape = {
                type: ShapeTypes.LINE_STRING,
                coordinates: lineStringCoords,
            };

            const geometry = createEntityGeometry(shape);

            const feature = new Feature({
                geometry: geometry,
                zIndex: 0,
            });

            feature.setId("trajectory line string");
            feature.setStyle(lineSegmentStyleFunction);

            trajectoryLineSource.addFeature(feature);

            trajectoryLayers = [trajectoryPointLayer, trajectoryLineLayer];
        }

        return { layers: trajectoryLayers, floorId };
    }, [processedTrajectoryData?.id]);

    /**
     * Gets processed line chart data from userSessionTrajectoryQueryCall
     */
    const trajectoryChartData = useMemo(() =>
    {
        let timeSpent, startTime, endTime, hasData, timeSpent_m;

        let distanceOverDeltaTimeChart = {
            chartData: [
                {
                    x: "0",
                    y: 0,
                },
            ],
            thresholdChartDataGreen: [
                {
                    x: "0",
                    y: TrajectoryThresholds.GREEN / 60,
                },
            ],

            thresholdChartDataOrange: [
                {
                    x: "0",
                    y: TrajectoryThresholds.ORANGE / 60,
                },
            ],

            thresholdChartDataRed: [
                {
                    x: "0",
                    y: TrajectoryThresholds.RED / 60,
                },
            ],
        };

        let durationOverDeltaTimeChart = {
            chartData: [
                {
                    x: "0",
                    y: 0,
                },
            ],
            thresholdChartDataGreen: [
                {
                    x: "0",
                    y: TrajectoryThresholds.GREEN / 60,
                },
            ],

            thresholdChartDataOrange: [
                {
                    x: "0",
                    y: TrajectoryThresholds.ORANGE / 60,
                },
            ],

            thresholdChartDataRed: [
                {
                    x: "0",
                    y: TrajectoryThresholds.RED / 60,
                },
            ],
        };

        /**
         * Update chart data set, populating threshold with the given x
         * @param {*} x
         * @param {*} y
         * @param {*} chart
         */
        let updateChartDataHelper = (x, y, chart, id) =>
        {

            chart.chartData.push({
                x: x,
                y: y,
                id: id,
            });

            chart.thresholdChartDataGreen.push({
                x: x,
                y: TrajectoryThresholds.GREEN / 60,
            });

            chart.thresholdChartDataOrange.push({
                x: x,
                y: TrajectoryThresholds.ORANGE / 60,
            });

            chart.thresholdChartDataRed.push({
                x: x,
                y: TrajectoryThresholds.RED / 60,
            });
        };

        let cumulativeDistance_m = 0;
        let cumulativeDuration_s = 0;

        if (
            Array.isArray(processedTrajectoryData?.data)
            && processedTrajectoryData?.data.length > 0
            && timeZone
        )
        {
            let prevTrajectoryPoint;
            processedTrajectoryData.data.forEach(
                ({ deltaTime_s, trajectoryPoint, id }) =>
                {
                    hasData = true;
                    if (prevTrajectoryPoint)
                    {
                        let deltaTime_m = deltaTime_s / 60;
                        let deltaDistance_m = getDistanceBetweenPoints(
                            prevTrajectoryPoint,
                            trajectoryPoint
                        );
                        cumulativeDistance_m += deltaDistance_m;
                        cumulativeDuration_s += deltaTime_s;

                        if (deltaDistance_m !== 0)
                        {
                            const distance_x = `${cumulativeDistance_m.toFixed(
                                InsightsDistanceSignificantDigit
                            )}`;
                            const duration_x = cumulativeDuration_s / 60;
                            const y = deltaTime_m;

                            updateChartDataHelper(
                                distance_x,
                                y,
                                distanceOverDeltaTimeChart,
                                id
                            );
                            updateChartDataHelper(
                                duration_x,
                                y,
                                durationOverDeltaTimeChart,
                                id
                            );
                        }
                    }
                    prevTrajectoryPoint = trajectoryPoint;
                }
            );
            const { startUnixTime_s: startTime_s, endUnixTime_s: endTime_s, duration_min } = processedTrajectoryData.meta;
            timeSpent_m = duration_min;
            timeSpent = convertMinsToHrsAndMins(timeSpent_m);
            startTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(
                startTime_s,
                timeZone.id
            );
            endTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(
                endTime_s,
                timeZone.id
            );

        }

        let customXTickValuesDistance = [];
        let customXTickValuesDuration = [];
        let insightsXTickDistance = InsightsXTickDistance;
        let insightsXTickDuration = InsightsXTickDuration;
        let numOfTicksDistance = Math.floor(
            cumulativeDistance_m / insightsXTickDistance
        );
        let numOfTickDuration = Math.floor(
            cumulativeDuration_s / 60 / insightsXTickDuration
        );

        // check number of ticks if too large
        while (numOfTicksDistance > InsightsTickNumOfTickThreshold)
        {
            insightsXTickDistance = insightsXTickDistance * 2;
            numOfTicksDistance = Math.floor(
                cumulativeDistance_m / insightsXTickDistance
            );
        }

        // check number of ticks if too large
        while (numOfTickDuration > InsightsTickNumOfTickThreshold)
        {
            insightsXTickDuration = insightsXTickDuration * 2;
            numOfTickDuration = Math.floor(
                cumulativeDuration_s / 60 / insightsXTickDuration
            );
        }

        // creates a tick every "x" units. X is dependent on tick distance
        for (let i = 0; i <= numOfTicksDistance; i++)
        {
            customXTickValuesDistance.push(i * insightsXTickDistance);
        }

        for (let i = 0; i <= numOfTickDuration; i++)
        {
            customXTickValuesDuration.push(i * insightsXTickDuration);
        }

        return {
            hasData,
            timeSpent,
            timeSpent_m,
            startTime,
            endTime,
            customXTickValuesDistance,
            customXTickValuesDuration,
            distance: `${cumulativeDistance_m.toFixed(
                InsightsDistanceSignificantDigit
            )} meters`,
            distanceChart: [
                {
                    id: "distanceOverDeltaTimeChart-trajectory" + uuid_v4(),
                    data: distanceOverDeltaTimeChart.chartData,
                },
                {
                    id:
                        "distanceOverDeltaTimeChart-green threshold"
                        + uuid_v4(),
                    data: distanceOverDeltaTimeChart.thresholdChartDataGreen,
                },
                {
                    id:
                        "distanceOverDeltaTimeChart-orange threshold"
                        + uuid_v4(),
                    data: distanceOverDeltaTimeChart.thresholdChartDataOrange,
                },
                {
                    id: "distanceOverDeltaTimeChart-red threshold" + uuid_v4(),
                    data: distanceOverDeltaTimeChart.thresholdChartDataRed,
                },
            ],
            durationChart: [
                {
                    id: "durationOverDeltaTimeChart-trajectory" + uuid_v4(),
                    data: durationOverDeltaTimeChart.chartData,
                },
                {
                    id:
                        "durationOverDeltaTimeChart-green threshold"
                        + uuid_v4(),
                    data: durationOverDeltaTimeChart.thresholdChartDataGreen,
                },
                {
                    id:
                        "durationOverDeltaTimeChart-orange threshold"
                        + uuid_v4(),
                    data: durationOverDeltaTimeChart.thresholdChartDataOrange,
                },
                {
                    id: "durationOverDeltaTimeChart-red threshold" + uuid_v4(),
                    data: durationOverDeltaTimeChart.thresholdChartDataRed,
                },
            ],
        };
    }, [processedTrajectoryData?.id, timeZone]);

    /**
     * @returns array of {label, timeSpent_s, zoneGeofenceId}
     */
    const trajectoryTableData = useMemo(() =>
    {
        if (
            !processedTrajectoryData
            || processedTrajectoryData?.data?.length === 0
            || !floorZoneGeofenceIdToTurfPolygonHash?.data
        )
        {
            return;
        }

        const zoneGeofenceIds = Object.keys(
            floorZoneGeofenceIdToTurfPolygonHash.data
        );

        let dwellZoneDataHash = {};

        Object.keys(floorZoneGeofenceIdToTurfPolygonHash.data).forEach(
            (zoneGeofenceId) =>
            {
                dwellZoneDataHash[zoneGeofenceId] = {
                    label: floorZoneGeofenceIdToTurfPolygonHash.data[
                        zoneGeofenceId
                    ].label,
                    timeSpent_s: 0,
                    zoneGeofenceId: zoneGeofenceId,
                };
            }
        );

        processedTrajectoryData.data.forEach(({ shape, deltaTime_s }) =>
        {
            let coordinates = shape.coordinates;
            let turfPoint = longLatToTurfPoint(coordinates[0], coordinates[1]);

            // check if point is in a polygon
            for (let i = 0; i < zoneGeofenceIds.length; i++)
            {
                let currZoneGeofenceId = zoneGeofenceIds[i];
                let zoneGeofencePolygon =
                    floorZoneGeofenceIdToTurfPolygonHash.data[
                        currZoneGeofenceId
                    ].turfPolygon;

                if (isPointInPolygon(turfPoint, zoneGeofencePolygon))
                {
                    dwellZoneDataHash[currZoneGeofenceId].timeSpent_s
                        += deltaTime_s;
                    break;
                }
            }
        });

        const dwellZoneDataArray = Object.values(dwellZoneDataHash).sort((a, b) => b.timeSpent_s - a.timeSpent_s);

        return { id: processedTrajectoryData.id, data: dwellZoneDataArray };
    }, [
        processedTrajectoryData?.id,
        timeZone,
        floorZoneGeofenceIdToTurfPolygonHash?.id,
        floorId,
    ]);

    /** We first iterate over individual property
     * Grab the session and add to the main object
     * Populate the session with the trajectory found with sessionUID
     * In each call,the duration is added to total and start time is updated if trajectory with more older time is detected
     * Finally properties of the session with only the longest duration is populated in propertyVisits Array
     *
     **/
    const { sessionsTableData, trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash, buildingFloorIdsHashSetWithTrajectoryData } = useMemo(() =>
    {
        let sessionsTableData = [];
        const buildingFloorIdsHashSetWithTrajectoryData = {};
        const trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash = {};
        const result = { sessionsTableData, trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash, buildingFloorIdsHashSetWithTrajectoryData };

        if (!mergedPropertyVisits || mergedUserSessionStatuses?.isSuccess === false)
        {
            return result;
        }
        sessionsTableData = Object.values(mergedUserSessionStatuses?.data ?? {}).map((session) =>
        {
            let duration = convertMinsToHrsAndMins((session.endTimeUTC.unixTime_ms - session.startTimeUTC.unixTime_ms) / 60000);
            return {
                sessionUID: session.sessionUID,
                sessionStartTimeUnix_ms: session.startTimeUTC.unixTime_ms,
                sessionEndTimeUnix_ms: session.endTimeUTC.unixTime_ms,
                sessionDuration_ms: session.endTimeUTC.unixTime_ms - session.startTimeUTC.unixTime_ms,
                sessionStatus: session.sessionStatus,
                propertyVisits: [],
                sessionStartTime: convertUnixSecondsToReadAbleTimeFormatWithTimeZone(session.startTimeUTC.unixTime_ms / 1000, timeZone.id),
                sessionEndTime: session?.endTimeUTC?.unixTime_ms ? convertUnixSecondsToReadAbleTimeFormatWithTimeZone(session.endTimeUTC.unixTime_ms / 1000, timeZone.id) : "N/A",
                duration

            };
        });

        // Iterate over property IDs and their sessions
        for (const propertyId in mergedPropertyVisits)
        {
            const visits = mergedPropertyVisits[propertyId];

            for (const visit of visits)
            {
                const sessionUID = visit.sessionUID;

                // Check if the sessionUID exists in sessionsTableData, and initialize it if not
                let sessionData = sessionsTableData.find(
                    (item) => item.sessionUID === sessionUID
                );

                if (!sessionData)
                {
                    let duration = "N/A";


                    //let sessionStatus = mergedUserSessionStatuses?.data[sessionUID]?.sessionPostProcessingStatus;
                    let sessionStatus = visit?.session?.sessionStatus;
                    const visitSession = visit?.session;
                    duration = convertMinsToHrsAndMins((visitSession.endTimeUTC.unixTime_ms - visitSession.startTimeUTC.unixTime_ms) / 60000);
                    sessionData = {
                        sessionUID: sessionUID,
                        sessionStartTimeUnix_ms: visitSession.startTimeUTC.unixTime_ms,
                        sessionStartTime: convertUnixSecondsToReadAbleTimeFormatWithTimeZone(visitSession.startTimeUTC.unixTime_ms / 1000, timeZone.id),
                        sessionEndTime: visitSession?.endTimeUTC?.unixTime_ms ? convertUnixSecondsToReadAbleTimeFormatWithTimeZone(visitSession.endTimeUTC.unixTime_ms / 1000, timeZone.id) : "N/A",
                        propertyVisits: [],
                        sessionStatus: sessionStatus,
                        duration
                    };

                    sessionsTableData.push(sessionData);

                }



                // get building name using session building id, property id, and propertyInfoMap
                const visitBuildingName = getBuildingName(visit.propertyId, visit.buildingId, propertyInfoMap);

                // Create a visit object and add it to propertyVisits
                const visitObject = {
                    propertyId: visit.propertyId,
                    buildingId: visit.buildingId,
                    floorId: visit.floorId,
                    propertyName: visit.propertyName,
                    buildingName: visitBuildingName,
                    startTime: visit.startTime.unixTime_ms,
                    duration_min: (visit.duration_min),
                    sessionIdx: visit.sessionIdx,
                    sessionUID: visit.sessionUID,
                    userUID: visit.userUID,
                    trajectoryReconstructionStatus: visit.trajectoryReconstructionStatus,
                };

                //if trajectory is constructed then add visit to trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash
                //and add floor buildingFloorIdsHashSetWithTrajectoryData
                if (isTrajectoryConstructed(visitObject))
                {
                    const uniqueId = generateUniqIdFromPropertyIdBuildingFloorId(visitObject);
                    const propertyBuildingUniqueId = generateUniqIdFromPropertyIdBuildingFloorId({ propertyId: visitObject.propertyId, buildingId: visitObject.buildingId, floorId: -1 });

                    if (!trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash[uniqueId])
                    {
                        trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash[uniqueId] = { visits: [] };


                    }
                    if (!buildingFloorIdsHashSetWithTrajectoryData[propertyBuildingUniqueId])
                    {
                        buildingFloorIdsHashSetWithTrajectoryData[propertyBuildingUniqueId] = { floorIds: new Set() };
                    }
                    trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash[uniqueId].visits.push(visit);
                    buildingFloorIdsHashSetWithTrajectoryData[propertyBuildingUniqueId].floorIds.add(visit.floorId);
                }



                // DEPRECATED CODE - old logic
                // const propertyVisitIndex = sessionData.propertyVisits.findIndex(
                //     (item) => item.propertyId === session.propertyId
                // );

                // If the property has not been visited during this session or if the current visit has a longer duration,
                // update or add it to propertyVisits
                // if (propertyVisitIndex === -1|| session.duration_min >sessionData.propertyVisits[propertyVisitIndex].duration_min)
                // {
                //     sessionData.propertyVisits.push(visitObject);
                // }

                sessionData.propertyVisits.push(visitObject);

                // Update session start and end time
                if (visit.startTime.unixTime_ms < sessionData.sessionStartTimeUnix_ms || sessionData.sessionStartTimeUnix_ms === 0)
                {
                    sessionData.sessionStartTimeUnix_ms = visit.startTime.unixTime_ms;
                    sessionData.sessionStartTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(visit.startTime.unixTime_ms / 1000, timeZone.id);
                }
            }
        }

        // loop through sessions and sort property visits
        sessionsTableData.forEach((sessionData, idx) =>
        {
            sessionsTableData[idx].propertyVisits = sessionData.propertyVisits.sort((a, b) => b.startTime - a.startTime);
        });

        // this will include not processed sessions where the API does not
        // we could change the logic to first go through this list to create the session map
        if (mergedUserSessionStatuses?.data)
        {
            let sessionsFromSessionSummariesQuery = Object.values(mergedUserSessionStatuses.data);

            sessionsFromSessionSummariesQuery.forEach((session) =>
            {
                const existingSessionIdx = sessionsTableData.findIndex((processedSession) => processedSession.sessionUID === session.sessionUID);
                // if session is not in the processed list already, add it in
                if (existingSessionIdx === -1)
                {
                    let duration = "N/A";
                    let sessionEndTime = "N/A";

                    if (session.endTimeUTC?.unixTime_ms !== 0 || session.endTimeUTC?.unixTime_ms !== 0.0)
                    {
                        duration = convertMinsToHrsAndMins((session.endTimeUTC.unixTime_ms - session.startTimeUTC.unixTime_ms) / 60000);

                        sessionEndTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone(session.endTimeUTC.unixTime_ms / 1000, timeZone.id);
                    }

                    const sessionData = {
                        sessionUID: session.sessionUID,
                        sessionStartTime: convertUnixSecondsToReadAbleTimeFormatWithTimeZone(session.startTimeUTC.unixTime_ms / 1000, timeZone.id),
                        sessionStartTimeUnix_ms: session.startTimeUTC.unixTime_ms,
                        sessionEndTime: sessionEndTime,
                        propertyVisits: [],
                        // sessionStatus: session.sessionPostProcessingStatus,
                        sessionStatus: session.sessionStatus,
                        duration: duration
                    };

                    sessionsTableData.push(sessionData);
                }
                else
                {
                    sessionsTableData[existingSessionIdx].sessionStartTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone((session.startTimeUTC.unixTime_ms) / 1000, timeZone.id);
                    if (session.endTimeUTC)
                    {
                        sessionsTableData[existingSessionIdx].sessionEndTime = convertUnixSecondsToReadAbleTimeFormatWithTimeZone((session.endTimeUTC.unixTime_ms) / 1000, timeZone.id);
                        sessionsTableData[existingSessionIdx].duration = convertMinsToHrsAndMins((session.endTimeUTC.unixTime_ms - session.startTimeUTC.unixTime_ms) / 60000);
                    }

                }
            });
        }

        // Sorting the sessionsTableData by session start time
        sessionsTableData.sort((a, b) => b.sessionStartTimeUnix_ms - a.sessionStartTimeUnix_ms);


        return { sessionsTableData, trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash, buildingFloorIdsHashSetWithTrajectoryData };

    }, [mergedPropertyVisits, mergedUserSessionStatuses, timeZone.id]);

    /**
     * timestamp to select zone geofence history option
     * picked from start time of selected visit
     */
    const zoneGeofenceHistoryOverwriteTimeStamp_ms = useMemo(() =>
    {
        let timeStamp_ms = 0;
        if (Array.isArray(processedTrajectoryData?.data) && processedTrajectoryData?.data.length > 0)
        {
            let timeStamp_s = processedTrajectoryData?.data[processedTrajectoryData?.data.length - 1].trajectoryPoint.unix_time_s;

            timeStamp_ms = timeStamp_s * 1000;
        }
        return timeStamp_ms;
    }, [processedTrajectoryData?.id]);

    //#end region DISPLAYED DATA PROCESSING

    /**
     * Changes the selected user from drop down change
     */
    const handleSelectedUserChange = useCallback((email, op) =>
    {
        const { data } = op;
        setSelectedUserEmail(data.email);
        setSelectedUserUIDArray([data.userId]);
    }, []);

    /**
     * updates all params for selected session
     */
    const handleChangeSelectedSession = useCallback(({ userUID, sessionIdx, sessionUID, propertyId, buildingId, floorId }, updateUser = false) =>
    {
        if (propertyId)
        {
            if (floorId)
            {
                setFloorId(floorId);
            }

            if (buildingId)
            {
                setBuildingId(buildingId);
            }
            setPropertyID(propertyId);
        }

        if (updateUser)
        {
            const userD = userDropdownOptions.find(({ data: user }) => user.userId.toUpperCase() === userUID.toUpperCase());
            setSelectedUserEmail(userD.data.email);
            setSelectedUserUIDArray([userD.data.userId]);
        }


        setSelectedUserSessionIdx(sessionIdx);
        setSelectedUserSessionUID(sessionUID);
        setSelectedSessionUserUID(userUID);

    }, [setSelectedSessionUserUID, setSelectedUserSessionIdx, setSelectedUserSessionUID, userDropdownOptions]);

    /**
     * Changes the selected user sessionUID from drop down change
     */
    const handleSelectedSessionUIDChange = useCallback(
        (e, { options, value }) =>
        {
            let selectedOption = options.find((option) => option.key === value);

            const selectedSession = {
                userUID: selectedOption.userUID,
                sessionIdx: selectedOption.session_idx,
                sessionUID: selectedOption.sessionUID
            };

            handleChangeSelectedSession(selectedSession);
        },
        [handleChangeSelectedSession]
    );

    /**
     * status for property dropdown options. used as helper to determine if loading/no data messages should be displayed for dropdown/map
     * @returns isLoading, isSuccessful, hasData,
     */
    const propertyOptionsStatus = useMemo(() =>
    {
        let { isSuccess, isError, isLoading, dataUpdatedAt } =
            analyticsCompleteSummaryQuery;
        let hasData = false;

        if (mergedPropertyVisits && Object.keys(mergedPropertyVisits).length > 0)
        {
            hasData = true;
        }

        isLoading = isLoading || propertiesUsersQuery.isLoading || userSessionTrajectoryQueryCall.isLoading;
        isSuccess = !isLoading && isSuccess && propertiesUsersQuery.isSuccess && userSessionTrajectoryQueryCall.isSuccess;
        dataUpdatedAt = Date.now();
        if (isLoading | !isSuccess)
        {
            hasData = false;
        }

        return {
            isSuccess,
            isError,
            isLoading,
            hasData,
            dataUpdatedAt,
        };
    }, [
        propertiesUsersQuery.dataUpdatedAt,
        analyticsCompleteSummaryQuery.dataUpdatedAt,
        userSessionTrajectoryQueryCall.dataUpdatedAt,
        mergedPropertyVisits,
        emailsHashWithLatestUserInfo
    ]);

    /**
     * refetch user trajectory query
     */
    const handleRefreshTrajectoryQuery = useCallback(() =>
    {
        refetchQuery(userSessionTrajectoryQueryCall);
    }, [userSessionTrajectoryQueryCall.dataUpdatedAt, userSessionTrajectoryQueryCall.isLoading]);


    /**
     * returns if the zone geofence table data is still loading
     */
    const isZoneGeofenceTableDataLoading = useMemo(() => (userSessionTrajectoryQueryCall.isLoading || userSessionTrajectoryQueryCall.isIdle), [userSessionTrajectoryQueryCall.dataUpdatedAt]);

    /**
     * returns if the session table data is still loading
     */
    const isSessionTableDataLoading = useMemo(() => (userSessionStatusesQuery.isLoading || userTrajectorySummariesQuery.isLoading), [userSessionStatusesQuery.dataUpdatedAt, userTrajectorySummariesQuery.dataUpdatedAt]);


    const dwellTimeForActiveTrajectoryZones = useMemo(() =>
    {
        // console.log(userPropertySessionDropdownOptionsHash);
        if (!userSessionTrajectoryQueryCall.data)
        {
            return [];
        }
        const { zonesDwellInfoHash } = userSessionTrajectoryQueryCall.data;

        return Object.values(zonesDwellInfoHash).sort((a, b) => b.timeSpent_s - a.timeSpent_s);

    }, [userSessionTrajectoryQueryCall.dataUpdatedAt]);


    const onFloorChangeFromMap = useCallback((newFloor) =>
    {

        const uniqId = generateUniqIdFromPropertyIdBuildingFloorId({ propertyId, buildingId, floorId: newFloor });
        //console.log({ newFloor, uniqId, d: trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash[uniqId] });

        const trajectoriesOfFloor = trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash[uniqId];
        if (!trajectoriesOfFloor?.visits?.length)
        {
            return;
        }

        const { visits } = trajectoriesOfFloor;
        const selectedUserId = selectedUserUIDArray?.[0];
        let visitToSelect = visits?.[0];
        if (selectedUserId)
        {
            for (let i = 0; i < visits?.length; i++)
            {
                const visit = visits[i];
                if (visit.userUID === selectedUserId) // find visit of current users
                {
                    if (visit.sessionUID === selectedSessionUserId) // find the sessions visit
                    {
                        visitToSelect = visit;
                        break;
                    }
                }
            }
        }
        const updateSelectedUserState = !selectedUserUIDArray.includes(visitToSelect?.userUID);
        handleChangeSelectedSession(visitToSelect, updateSelectedUserState);

    }, [trajectoryGroupedByPropertyIdBuildingIdAndFloorIdHash, propertyId, buildingId, selectedSessionUserIdx, selectedSessionUserId, handleChangeSelectedSession, selectedUserUIDArray]);

    const addAdditionalBtnProps = useCallback((floor) =>
    {
        const { floorId: sFloorId } = floor;

        const uniqId = generateUniqIdFromPropertyIdBuildingFloorId({ propertyId, buildingId, floorId: - 1 });
        //console.log(buildingFloorIdsHashSetWithTrajectoryData?.[uniqId]?.floorIds, floorId);
        if (!buildingFloorIdsHashSetWithTrajectoryData?.[uniqId]?.floorIds?.has(sFloorId))
        {
            return {
                ...floor,
                title: t("Insights.Info_messages.NO_DATA_ON_FLOOR"),
                clickAllowed: false,
                additionalClasses: "v-disabled"
            };
        }
        return {
            ...floor,
            clickAllowed: true
        };
    }, [buildingFloorIdsHashSetWithTrajectoryData, propertyId, buildingId, t]);



    return {
        addAdditionalBtnProps,
        onFloorChangeFromMap,
        zoneFloorHistory,
        selectedUserSessionUID,
        selectedSessionUserIdx,
        selectedUserEmail,
        handleSelectedUserChange,
        handleSelectedSessionUIDChange,
        handleChangeSelectedSession,
        trajectoryMapLayer,
        trajectoryChartData,
        trajectoryTableData,
        sessionsTableData,
        userSessionTrajectoryQueryCall,
        userDropdownOptions,
        propertyIdsThatHaveDataForSelectedUserDateRange,
        userPropertySessionDropdownOptions,
        propertyOptionsStatus,
        propertyVisits: mergedPropertyVisits,
        handleRefreshTrajectoryQuery,
        userTrajectorySummariesQuery,
        isZoneGeofenceTableDataLoading,
        isSessionTableDataLoading,
        shouldIncludeLiveData,
        dataUpdatedAt,
        propertiesUsersQuery,
        zoneGeofenceHistoryOverwriteTimeStamp_ms,
        processedTrajectoryData,
        timeZone,
        dwellTimeForActiveTrajectoryZones,
        propertyTimeZone
    };
};

export default useInsightsCommon;
