import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useDebounce } from "use-debounce";
import { DEFAULT_SEARCH_FILTER } from "../../../_constants/exportToCsvConstants";
import { getAllUserSelectedItemsHashGroupedByDataTypeFromActivePage, getDataGroupedByDataTypeWhichHasData, hasUserSelectedAtLeastOneItemToExport, onDownloadAfterDataPreparing, preparedZippedContentFromAllActiveItems, updateSelectionOfDataItem } from "../../../_utils/exportToCsvUtils";
import { activePageDataExportHandlersState, connectedPageNameState, isDataExportModalStateIsOpenState, searchForDataItemWidgetState } from "../../../store/ExportToCsvAtoms";

const useCsvExportEventEmitter = () =>
{
    const isDataSelectionModalOpen = useRecoilValue(isDataExportModalStateIsOpenState);
    const availableDataHashGroupedByDataTypesHashFromActivePage = useRecoilValue(activePageDataExportHandlersState);
    const [widgetSearchFilter, setWidgetSearchFilter] = useRecoilState(searchForDataItemWidgetState);
    const connectedPageName = useRecoilValue(connectedPageNameState);

    const setIsCsvExportingTriggered = useSetRecoilState(isDataExportModalStateIsOpenState);
    const setActiveDataItems = useSetRecoilState(activePageDataExportHandlersState);

    const [debouncedWidgetSearchFilter] = useDebounce(widgetSearchFilter?.trim(), 600);


    const mutation = useMutation(preparedZippedContentFromAllActiveItems, {
        onSuccess: (content) => onDownloadAfterDataPreparing(content, connectedPageName),
        onError: console.error
    });

    const allUserSelectedDataItemsHashGroupedByDataTypes = useMemo(() => 
    {
        return getAllUserSelectedItemsHashGroupedByDataTypeFromActivePage(availableDataHashGroupedByDataTypesHashFromActivePage);
    }, [availableDataHashGroupedByDataTypesHashFromActivePage]);

    /**
     * Handles the event when the export button is triggered.
     * It prepares the data to be exported and triggers the mutation to prepare the data and download it.
     */
    const onExportData = useCallback(() =>
    {
        mutation.mutate({ allUserSelectedDataItemsHashGroupedByDataTypes });

    }, [connectedPageName, allUserSelectedDataItemsHashGroupedByDataTypes, mutation]);


    /**
     * Handles the event when the user selects or unselects a data item.
     * Updates the state of the selected data items.
     *
     * @param {string} dataTypeName - The name of the data type.
     * @param {Object} data - The data object containing the ID and the selection status.
     * @param {string} data.id - The ID of the data item.
     * @param {boolean} data.isSelected - The selection status of the data item.
     */
    const onSelectOrUnSelect = useCallback((e, dataTypeName, { name, isSelected }) => 
    {
        setActiveDataItems((prev) => updateSelectionOfDataItem(prev, dataTypeName, name, isSelected));
    }, [setActiveDataItems]);

    /**
     * Determines if the export button click option is allowed.
     * Checks if the user has selected any widget to export data.
     *
     * @return {boolean} - Returns true if the user has selected at least one widget, otherwise false.
     */
    const isUserHasSelectedAnyWidgetToExportData = useMemo(() =>
    {
        if (allUserSelectedDataItemsHashGroupedByDataTypes)
        {
            return hasUserSelectedAtLeastOneItemToExport(allUserSelectedDataItemsHashGroupedByDataTypes);
        }
        return false;

    }, [availableDataHashGroupedByDataTypesHashFromActivePage]);



    const isDataItemSelected = useCallback((dataTypeName, { name }) => 
    {
        return allUserSelectedDataItemsHashGroupedByDataTypes[dataTypeName]?.[name]?.isSelected;
    }, [allUserSelectedDataItemsHashGroupedByDataTypes]);

    const filteredDataTypesBasedOnData = useMemo(() => 
    {
        return getDataGroupedByDataTypeWhichHasData(availableDataHashGroupedByDataTypesHashFromActivePage);
    }, [availableDataHashGroupedByDataTypesHashFromActivePage]);

    /**
    * If any text is searched,it takes the original data and performs filter based on widgets name keeping the old filtered data same
    * @returns {Object}
    */
    const widgetsToDisplay = useMemo(() =>
    {
        if (debouncedWidgetSearchFilter === DEFAULT_SEARCH_FILTER) return filteredDataTypesBasedOnData;

        const result = {};
        const originalData = { ...filteredDataTypesBasedOnData };

        Object.keys(originalData).forEach((dataType) =>
        {
            const currentData = originalData[dataType];
            const matchingItems = {};

            Object.keys(currentData).forEach((itemKey) =>
            {
                const currentItem = currentData[itemKey];
                if (currentItem.name.toLowerCase().includes(debouncedWidgetSearchFilter.toLowerCase()))
                {
                    matchingItems[itemKey] = currentItem;
                }
            });
            if (Object.keys(matchingItems).length > 0)
            {
                result[dataType] = matchingItems;
            }
        });

        return result;
    }, [debouncedWidgetSearchFilter, filteredDataTypesBasedOnData]);

    const selectAll = useCallback((flag) =>
    {
        Object.entries(widgetsToDisplay).map(([dataType, value]) =>
        {
            Object.entries(value).map(([name, value]) =>
            {
                onSelectOrUnSelect(null, dataType, { name: name, isSelected: flag });
            });

        });
    }, [widgetsToDisplay]);
    /**
     * Handles the event when the data selection modal is toggled.
     * It sets the state of the CSV export modal to be toggled.
     * @param {Event} e - The event object.
     * @param {boolean|undefined} flag - The flag to indicate if the modal should be opened or closed.
     */
    const onToggleDataSelectionModal = useCallback((e, flag) =>
    {
        setWidgetSearchFilter(DEFAULT_SEARCH_FILTER);
        setIsCsvExportingTriggered((prev) => [undefined, null].includes(flag) ? !prev : !!flag);
        selectAll(false);
    }, [setIsCsvExportingTriggered, selectAll]);

    /**
    * Handles the event when the export button is clicked.
    * If the export button click option is allowed, it sets the state of the CSV export modal to be opened.
    *
    * @returns {void} No return value
    */
    const onExportButtonClick = useCallback(() =>
    {
        if (isUserHasSelectedAnyWidgetToExportData)
        {
            onToggleDataSelectionModal(true);
        }
    }, [onToggleDataSelectionModal, isUserHasSelectedAnyWidgetToExportData]);

    const allWidgetsChecked = useMemo(() =>
    {
        return Object.entries(widgetsToDisplay).every(([dataType, value]) =>
            Object.entries(value).every(([name, item]) => item.isSelected === true)
        );
    }, [widgetsToDisplay]);

    /**
     * Updates the searched text on the modal
     * @returns {void}
     */
    const onSearch = useCallback((e) =>
    {
        setWidgetSearchFilter(e.target.value);
    }, [setWidgetSearchFilter]);

    return {
        isDataItemSelected,
        onToggleDataSelectionModal,
        isDataSelectionModalOpen: isDataSelectionModalOpen,
        isUserHasSelectedAnyWidgetToExportData,
        onSelectOrUnSelect,
        onExportData,
        isExportingLoading: mutation.isLoading,
        isErrorWhilePreparing: mutation.isError,
        isDataProcessingInProgress: mutation.isMutating,
        onExportButtonClick,
        onSearch,
        availableDataHashGroupedByDataTypesHashFromActivePage,
        widgetsToDisplay,
        widgetSearchFilter,
        setWidgetSearchFilter,
        selectAll,
        allWidgetsChecked,
        debouncedWidgetSearchFilter,
    };
};
export default useCsvExportEventEmitter;
