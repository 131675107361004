import classnames from "classnames";
import { addMinutes, differenceInCalendarDays, differenceInDays, endOfDay, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Checkbox, Popup } from "semantic-ui-react";
import serverApi from "../../../_api/server.api";
import { DATE_TYPES, DATE_TYPES_WITH_TODAY, DEFUALT_DATE_TYPES } from "../../../_constants/constants";
import { createDateTimeZoneDateFromJSDate, DIFFERENT_DATE_FORMATS, getLatestJsDateBasedOnTimeZone, getLocalTimeZoneName, getUTCTimeZoneName, isDateTimeToday } from "../../../_utils/date.luxon.utils";
import
{
    dateRangeToString,
    getDateRange,
    getDateSyncedWithTimezone,
    getSetSizeDateRange,
    mostRecentAvailableDate,
    resyncDateRangeOnActiveTimeZone
} from "../../../_utils/date.utils";
import { languageCodeState } from "../../../store/AppAtoms";
import { activeTimeZoneSelectorState, considerTimeInDateRangeState, dateRangeState, dateTypeState, propertyTimeZoneSelectorState } from "../../../store/DashboardAtoms";
import { ButtonIcon } from "../buttonIcon/ButtonIcon";
import "./DateRangePopup.css";
import TimeRange from "./TimeRange";

export const TimeFormatSelector = () =>
{
    const [activeTimeFormattingState, setActiveTimeFormatting] = useRecoilState(activeTimeZoneSelectorState);
    const propertyTimeZoneId = useRecoilValue(propertyTimeZoneSelectorState);

    const onTimeFormatGroup = useCallback(({ key, formatId }) => setActiveTimeFormatting({ key, id: formatId, name: formatId }), [setActiveTimeFormatting]);

    useEffect(() =>
    {
        //if current active timezone was property TimeZone
        //then user has changed the property
        //i.e active timezone needs to synced with property Timezone
        if (activeTimeFormattingState.key === DIFFERENT_DATE_FORMATS.propertyTimeZoneId)
        {

            if (activeTimeFormattingState.id !== propertyTimeZoneId)
            {
                onTimeFormatGroup({ key: DIFFERENT_DATE_FORMATS.propertyTimeZoneId, formatId: propertyTimeZoneId });
            }
        }
        // if local timezone id is same as property timezoneId we will sync timezone to property timeZone
        else if (activeTimeFormattingState.key === DIFFERENT_DATE_FORMATS.localTimeFormatId)
        {
            if (activeTimeFormattingState.id === propertyTimeZoneId)
            {
                onTimeFormatGroup({ key: DIFFERENT_DATE_FORMATS.propertyTimeZoneId, formatId: propertyTimeZoneId });
            }
        }
        // eslint-disable-next-line
    }, [propertyTimeZoneId, onTimeFormatGroup]);

    const renderactiveTimeZoneSelectorState = useMemo(() =>
    {
        const localTimeFormatId = getLocalTimeZoneName();
        const UTCTimeFormatId = getUTCTimeZoneName();

        //JSON for different time zone options

        const timeFormats = [
            {
                id: DIFFERENT_DATE_FORMATS.propertyTimeZoneId,
                value: propertyTimeZoneId,
                icon: "icon-subheader-property",
                popupProps: {
                    offset: [-130, 40]
                },
                // title: "Property",
                className: classnames("", { active: propertyTimeZoneId === activeTimeFormattingState.id }),
                onClick: () => onTimeFormatGroup({ key: DIFFERENT_DATE_FORMATS.propertyTimeZoneId, formatId: propertyTimeZoneId })
            },
        ];

        //hiding local option when property zone is same as local
        if (propertyTimeZoneId !== localTimeFormatId)
        {
            timeFormats.push({
                id: DIFFERENT_DATE_FORMATS.localTimeFormatId,
                value: localTimeFormatId,
                icon: "icon-subheader-local",
                popupProps: {
                    offset: [-100, 40]
                },
                // title: "Local",
                className: classnames("", { active: localTimeFormatId === activeTimeFormattingState.id }),
                onClick: () => onTimeFormatGroup({ key: DIFFERENT_DATE_FORMATS.localTimeFormatId, formatId: localTimeFormatId })
            });
        }

        //hiding UTC option when property zone is same as UTC
        if (propertyTimeZoneId !== UTCTimeFormatId)
        {
            timeFormats.push({
                id: DIFFERENT_DATE_FORMATS.UTCTimeFormatId,
                value: UTCTimeFormatId,
                icon: "icon-subheader-utc",
                popupProps: {
                    offset: [-50, 40]
                },
                // title: "UTC",
                className: classnames("", { active: UTCTimeFormatId === activeTimeFormattingState.id }),
                onClick: () => onTimeFormatGroup({ key: DIFFERENT_DATE_FORMATS.UTCTimeFormatId, formatId: UTCTimeFormatId })
            });
        }



        return <div className="buttonsRange">
            {
                timeFormats.map(({ popupProps, ...it }) => <Popup
                    key={it.icon}
                    basic
                    className="tooltipPopup"
                    on="hover"
                    position="top center"
                    offset={popupProps.offset}
                    trigger={<Button  {...it} icon={<img src={`/img/${it.icon}.svg`} alt="" />} />}
                    content={it.value}
                />
                )
            }
        </div>;

    }, [activeTimeFormattingState, propertyTimeZoneId, onTimeFormatGroup]);
    return <div>
        {renderactiveTimeZoneSelectorState}
    </div>;
};


/**
 * Generates a Date Range Popup component with customizable date range settings and calendar functionality.
 *
 * @param {Object} props - The properties object containing various options for the Date Range Popup:
 *   - {Array} dateTypes: Array of date types to be displayed
 *   - {number} rangeLength: Length of the date range
 *   - {number} minRangeLength: Minimum length of the date range
 *   - {boolean} openCalendarByDefault: Flag to determine if the calendar should be open by default
 *   - {boolean} isTodayDateAllowed: Flag to allow selection of today's date
 *   - {Date} minDate: Minimum selectable date
 *   - {boolean} hideTimeFormatter: Flag to hide the time formatter
 *   - {number} maxRange: Maximum range length
 *   - {boolean} forceSetDateTypeFromProps: Flag to force set date type from props
 *   - {function} dateRangeProcessingFunction process the current selected from dateRange Popup and returns the date to set to recoil
 *   - {number | function}  timeStep time step in mins
 *
 * @return {JSX.Element} The Date Range Popup component
 */
export const DateRangePopup = (props) =>
{
    const {
        dateTypes = DEFUALT_DATE_TYPES,
        rangeLength,
        minRangeLength,
        openCalendarByDefault,
        isTodayDateAllowed = false,
        minDate = undefined,
        hideTimeFormatter = false,
        maxRange,
        forceSetDateTypeFromProps = false,
        dateRangeProcessingFunction,
        timeStep,

    } = props;

    const languageCode = useRecoilValue(languageCodeState);
    const [scroll, setScroll] = useState(undefined);
    const [dateType, setDateType] = useRecoilState(dateTypeState);
    const [dateRange, setDateRange] = useRecoilState(dateRangeState);
    const [considerTimeInDateRange, setConsiderTimeInDateRange] = useRecoilState(considerTimeInDateRangeState);
    const trans = useTranslation().t;


    const propertyTimeZoneId = useRecoilValue(propertyTimeZoneSelectorState);

    const prevTimeZoneIdRef = useRef();


    const [isPopupOpen, setIsPopupOpen] = React.useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

    /**
    AWA-886
     * Synchronizes the date range with the current active time zone.
     * This effect is triggered whenever the property time zone ID changes.
     * It ensures that the date range is always in sync with the active time zone.
     */
    useEffect(() =>
    {
        const prevActivePropertyTimeZOneId = serverApi.activePropertyTimeZone;

        if (prevActivePropertyTimeZOneId !== propertyTimeZoneId)
        {
            setDateRange((dateRange) => resyncDateRangeOnActiveTimeZone(dateRange, propertyTimeZoneId, prevActivePropertyTimeZOneId, isTodayDateAllowed));
        }
        serverApi.activePropertyTimeZone = propertyTimeZoneId;
        prevTimeZoneIdRef.current = propertyTimeZoneId;
    }, [propertyTimeZoneId]);

    const { showTimeCheckBoxInDateRange, isCheckboxDisabled } = useMemo(() =>
    {
        let showTimeCheckBoxInDateRange = false;

        if (differenceInDays(dateRange.endDate, dateRange.startDate) < 1)
        {
            showTimeCheckBoxInDateRange = true;
        }

        return { showTimeCheckBoxInDateRange };

    }, [dateRange, setConsiderTimeInDateRange, isTodayDateAllowed]);


    const minsToIncrementForTime = useMemo(() =>
    {
        if (typeof timeStep === "function")
        {
            return timeStep(dateRange);
        }
        else if (typeof timeStep === "number")
        {
            return timeStep;
        }

        return 30; // 30 mins



    }, [timeStep, dateRange]);


    const handleDateRangeChange = React.useCallback(
        ({ dateRange }, isValueTouchedByTime = false) =>
        {
            //to do need to clean up this handler and
            //the new  logic either should use dataTypes or min max value of date range
            if (rangeLength)
            {
                dateRange = getSetSizeDateRange(dateRange, rangeLength, propertyTimeZoneId);
            }

            if (minRangeLength && differenceInCalendarDays(dateRange.endDate, dateRange.startDate) < minRangeLength)
            {
                dateRange = getSetSizeDateRange(dateRange, minRangeLength);
            }
            if (maxRange && differenceInCalendarDays(dateRange.endDate, dateRange.startDate) >= maxRange)
            {
                dateRange = getSetSizeDateRange(dateRange, maxRange, propertyTimeZoneId);
            }
            let { endDate, startDate } = dateRange;

            if (endDate - startDate <= 0)
            {
                const startDateTime = createDateTimeZoneDateFromJSDate(startDate, propertyTimeZoneId);
                const endDateTime = createDateTimeZoneDateFromJSDate(endDate, propertyTimeZoneId);
                if (isDateTimeToday(startDateTime, propertyTimeZoneId) && isDateTimeToday(endDateTime, propertyTimeZoneId))
                {
                    const possibleEndDate = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);
                    startDate = startOfDay(possibleEndDate);
                    endDate = possibleEndDate;
                }
                else
                {
                    startDate = startOfDay(startDate);
                    endDate = endOfDay(startDate);
                }
            }

            /**
     * Updates the end date if the start and end date span more than one day and the time
     * has not been touched by the user. The updated end date will be the latest date allowed
     * by the property's time zone, if the current end date is in the future.
     *
     * @param {boolean} isValueTouchedByTime - Indicates whether the time value has been touched by the user
     */
            if (!isValueTouchedByTime && differenceInDays(endDate, startDate) >= 1)
            {
                // Get the latest date allowed for the property's time zone
                // This is the date that is the end of the day in the property's time zone
                const possibleEndDateValueFromPropertyClock = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);

                // Determine the possible end date
                // This is the end of the day of the current end date
                let possibleEndDate = endOfDay(endDate);

                // If the possible end date is in the future, update it to the latest date allowed by the property's time zone
                if (possibleEndDateValueFromPropertyClock < possibleEndDate)
                {
                    possibleEndDate = possibleEndDateValueFromPropertyClock;
                }

                // Update the end date
                endDate = possibleEndDate;
            }

            if (typeof dateRangeProcessingFunction === "function")
            {
                const processedDate = dateRangeProcessingFunction({ startDate, endDate });
                startDate = processedDate.startDate;
                endDate = processedDate.endDate;
            }


            if (differenceInDays(endDate, startDate) >= 1)
            {
                setConsiderTimeInDateRange(false);
            }


            setDateRange({
                startDate,
                endDate,
            });
            setDateType(DATE_TYPES.CUSTOM);
        },
        [setDateRange, setConsiderTimeInDateRange, propertyTimeZoneId, setDateType, rangeLength, minRangeLength, maxRange, dateTypes, dateRangeProcessingFunction]
    );

    const handlePopupToggle = React.useCallback(() =>
    {
        if (isPopupOpen && isCalendarOpen)
        {
            setIsCalendarOpen(false);
        }

        if (!isPopupOpen && (dateTypes.length === 0 || openCalendarByDefault === true))
        {
            setIsCalendarOpen(true);
        }

        setIsPopupOpen(!isPopupOpen);
    }, [isPopupOpen, isCalendarOpen, setIsCalendarOpen, setIsPopupOpen, dateTypes, openCalendarByDefault]);

    const handleClosePopup = React.useCallback(
        (e) =>
        {
            setIsCalendarOpen(false);
            setIsPopupOpen(false);
        },
        [setIsCalendarOpen, setIsPopupOpen]
    );

    const handleTimeRangeChange = React.useCallback(
        ({ startTime, endTime }) =>
        {
            let endDate = new Date(endTime);
            const startDate = new Date(startTime);


            //when start date is greater than end date then add minutes step
            if (startDate > endDate)
            {
                const minsToIncrement = minsToIncrementForTime;
                endDate = addMinutes(startDate, minsToIncrement);
            }

            if (differenceInCalendarDays(endDate, startDate) >= 1)
            {
                endDate = endOfDay(startTime);
            }

            handleDateRangeChange({
                dateRange: {
                    endDate,
                    startDate,
                },
            }, true);
        },
        [handleDateRangeChange, minsToIncrementForTime]
    );

    const handleDateTypeChange = React.useCallback(
        (dateType) =>
        {
            if (Object.values(DATE_TYPES).includes(dateType))
            {
                if (dateType === DATE_TYPES.CUSTOM)
                {
                    setIsCalendarOpen(true);
                    setDateType(dateType);
                }
                else
                {
                    let dateRange = getDateRange(dateType, isTodayDateAllowed, propertyTimeZoneId);

                    setDateRange(dateRange);
                    setDateType(dateType);
                    handleClosePopup();
                }
            }
        },
        [setDateType, setDateRange, isTodayDateAllowed, handleClosePopup, propertyTimeZoneId]
    );

    const handleUpdateDependencies = React.useCallback((e) => setScroll(Date.now()), [setScroll]);

    useEffect(() =>
    {
        document.addEventListener("scroll", handleUpdateDependencies, true);

        return () =>
        {
            document.removeEventListener("scroll", handleUpdateDependencies, true);
        };
    }, [isPopupOpen, handleUpdateDependencies]);

    const getMaxDate = useCallback(() => mostRecentAvailableDate(propertyTimeZoneId, isTodayDateAllowed), [isTodayDateAllowed, propertyTimeZoneId]);

    const selectAbleDateTypes = useMemo(() =>
    {
        //this make sure to set date
        if (forceSetDateTypeFromProps)
        {
            return dateTypes;
        }
        if (isTodayDateAllowed)
        {
            return DATE_TYPES_WITH_TODAY;
        }

        return dateTypes;
    }, [isTodayDateAllowed, dateTypes, forceSetDateTypeFromProps, dateTypes]);

    // RENDER FUNCTIONS

    const renderPopupTrigger = React.useMemo(
        () => (
            <div className="dateRangeCover">
                {/* Date Range Text - Calendar */}
                <ButtonIcon
                    className={`buttonCalendarToggle${isPopupOpen ? " inShow" : ""}`}
                    icon="calendar"
                    content={dateRangeToString(dateRange)}
                    onClick={handlePopupToggle}
                />
            </div>
        ), // eslint-disable-next-line react-hooks/exhaustive-deps
        [dateRange, handlePopupToggle, isPopupOpen, languageCode]
    );

    const toggleconsiderTimeInDateRange = React.useCallback((e) =>
    {

        if (considerTimeInDateRange)
        {
            // Update the dateRange state based on the current state of considerTimeInDateRange
            setDateRange((dateRange) =>
            {
                // Create new Date objects from the startDate and endDate properties of the dateRange
                let startDate = new Date(dateRange.startDate);
                let endDate = new Date(dateRange.endDate);

                // Check if the difference between the endDate and startDate is less than or equal to 0
                if (differenceInCalendarDays(endDate, startDate) < 1)
                {
                    // Create DateTimeZoneDate objects from the startDate and endDate, using the propertyTimeZoneId
                    const startDateTime = createDateTimeZoneDateFromJSDate(startDate, propertyTimeZoneId);
                    const endDateTime = createDateTimeZoneDateFromJSDate(endDate, propertyTimeZoneId);

                    // Check if both the startDateTime and endDateTime are today's date, based on the propertyTimeZoneId
                    if (isDateTimeToday(startDateTime, propertyTimeZoneId) && isDateTimeToday(endDateTime, propertyTimeZoneId))
                    {
                        // If both startDateTime and endDateTime are today's date, get the latest possible end date based on the propertyTimeZoneId
                        const possibleEndDate = getLatestJsDateBasedOnTimeZone(propertyTimeZoneId);

                        // Set the startDate to the start of the day of the possible end date, and the endDate to the possible end date
                        startDate = startOfDay(possibleEndDate);
                        endDate = possibleEndDate;
                    }
                    else
                    {
                        // If startDateTime and endDateTime are not today's date, set the startDate to the start of the day of the startDate, and the endDate to the end of the day of the startDate
                        startDate = startOfDay(startDate);
                        endDate = endOfDay(startDate);
                    }
                }

                // Return the updated startDate and endDate as the new dateRange
                return {
                    startDate,
                    endDate
                };
            });
        }
        setConsiderTimeInDateRange((prev) => !prev);


    }, [setConsiderTimeInDateRange, propertyTimeZoneId, setDateRange, considerTimeInDateRange]);

    const selectionRange = useMemo(() =>
    {
        let range = {
            startDate: dateRange.startDate || new Date(),
            endDate: dateRange.endDate || new Date(),
            key: "dateRange",
        };

        return range;

    }, [dateRange]);

    const renderPopupContent = React.useCallback(() => (
        <div>
            <div>
                {Object.values(selectAbleDateTypes).map((dateTypeValue) => (
                    <DateRangeListButton
                        key={dateTypeValue}
                        dateType={dateTypeValue}
                        active={dateTypeValue === dateType}
                        onClick={handleDateTypeChange}
                    />
                ))}
            </div>

            {
                (isCalendarOpen) && (
                    <>
                        {(showTimeCheckBoxInDateRange) && (
                            <Checkbox
                                disabled={isCheckboxDisabled}
                                className="rdrDateDisplayWrapper"
                                label={trans("Words.Time")}
                                checked={considerTimeInDateRange}
                                onChange={toggleconsiderTimeInDateRange}
                            />
                        )}

                        {(considerTimeInDateRange && showTimeCheckBoxInDateRange) && (
                            <TimeRange
                                minuteIncrement={minsToIncrementForTime}
                                startMoment={dateRange.startDate}
                                endMoment={dateRange.endDate}
                                onChange={handleTimeRangeChange}
                                sameIsValid={false}
                                timeZone={propertyTimeZoneId}

                            //To-do  add maxEndDate and filter the time dropdown
                            />
                        )}
                        <DateRange
                            className={isCalendarOpen ? "" : "dateCalendarHide"} // add or remove this class on show/hide
                            ranges={[selectionRange]}
                            dateSelectionEnabled={false}
                            showSelectionPreview={false}
                            maxDate={getMaxDate()}
                            minDate={minDate || getDateSyncedWithTimezone(new Date("January 1, 2018"), propertyTimeZoneId)}
                            onChange={handleDateRangeChange}
                            locale={locales[languageCode]}

                        />
                    </>
                )
            }
        </div>
    ), [
        propertyTimeZoneId,
        isCalendarOpen,
        dateType,
        dateRange,
        handleDateRangeChange,
        handleDateTypeChange,
        languageCode,
        considerTimeInDateRange,
        getMaxDate,
        handleTimeRangeChange,
        isCheckboxDisabled,
        selectAbleDateTypes,
        showTimeCheckBoxInDateRange,
        toggleconsiderTimeInDateRange,
        minsToIncrementForTime
    ]);


    return (
        <div className={classnames("dateRangePopup-container")}>
            {!hideTimeFormatter ? <TimeFormatSelector /> : null}
            <Popup
                className="dateRangePopup"
                open={isPopupOpen}
                position="bottom right"
                on="click"
                style={{ zIndex: 2 }}
                trigger={renderPopupTrigger}
                content={{ children: renderPopupContent }}
                popperDependencies={[scroll]}
                closeOnEscape={true}
                closeOnDocumentClick={true}
                onClose={handleClosePopup}
            />
        </div>
    );
};

const DateRangeListButton = ({ dateType, active, onClick }) =>
{
    const trans = useTranslation().t;
    const handleDateRangeOptionClick = React.useCallback(
        (e) =>
        {
            onClick && onClick(dateType);
        },
        [dateType, onClick]
    );

    return <Button content={trans(`DateRangeListButton.${dateType}`)} onClick={handleDateRangeOptionClick} />;
};
