import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import serverApi from "../../_api/server.api";
import { environmentConstantsState } from "../../store/AppAtoms";
import { analyticsRequestFilterSelector, hasAccessToPropertySelector } from "../../store/DashboardAtoms";
import { DefaultBox } from '../common/DefaultBox';
import { NAV_ANALYTICS_QUERY_KEYS, QUERY_OPTIONS } from "../../_constants/queryConstants";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { WidgetPercentageText } from "../common/PercentagePara";
import { processCategoryConversionRateWidget, processCategoryWidget } from "../../_utils/chart.utils";
import { FunnelChart } from "../charts/FunnelChart";
import { CATEGORY_CONVERSION_CHART_OPTIONS } from "../../_constants/chartConstants";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";


export const SearchedConversionRateWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const { analyticsRequestFilter, analyticsRequestCompareFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const environmentConstants = useRecoilValue(environmentConstantsState);
    const hasAccessToProperty = useRecoilValue(hasAccessToPropertySelector);

    // query Widget/CategorySearchedVsVisited data with current and compare
    const categorySearchVsVisitedQuery = useQuery([NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED, "Compare", { analyticsRequestFilter, environmentConstants }], async () =>
    {
        if (!!analyticsRequestFilter && environmentConstants)
        {
            let promiseResult = [];

            promiseResult.push(await serverApi.getNavigationAnalyticsData(analyticsRequestFilter, NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED, environmentConstants));
            promiseResult.push(await serverApi.getNavigationAnalyticsData(analyticsRequestCompareFilter, NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED, environmentConstants));

            await Promise.all(promiseResult);

            // if not success throw error
            if (!promiseResult[0]?.success || !promiseResult[1]?.success)
            {
                throw new Error(`${NAV_ANALYTICS_QUERY_KEYS.CATEGORY_SEARCHED_VS_VISITED} response was not ok`);
            }

            return { data: promiseResult[0]?.data?.categorySearchedVsVisitedRecords, compareData: promiseResult[1]?.data?.categorySearchedVsVisitedRecords };
        }
        else
        {
            throw new Error("Filter was not ok");
        }
    }, {
        enabled: (!!analyticsRequestFilter && !!environmentConstants && hasAccessToProperty),
        ...QUERY_OPTIONS
    });


    const processedCategoryConversionRateData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data, refetch } = categorySearchVsVisitedQuery;

        let processedData;

        if (isSuccess && !!data)
        {
            processedData = processCategoryConversionRateWidget(data, trans);
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };

    }, [categorySearchVsVisitedQuery, trans]);

    const processedExportData = useMemo(() =>
    {
        if (!processedCategoryConversionRateData?.data)
        {
            return [];
        }

        const { funnelChart, percentageOfChange, percentageVisited } = processedCategoryConversionRateData.data;

        if (!percentageVisited)
        {
            return [];
        }

        const modifiedData = {
            // PercentageOfChange: percentageOfChange,
            // PercentageVisited: percentageVisited
        };

        funnelChart.forEach(({ label, value }) =>
        {
            modifiedData[label] = value;
        });
        return [modifiedData];

    }, [processedCategoryConversionRateData?.data, connectedPageName]);


    useEffect(() =>
    {

        const exportPayload = [
            {
                name: "What is your conversion rate",
                data: processedExportData,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "What is your conversion rate",
            //     data: categorySearchVsVisitedQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedExportData]);


    const renderSearchedConversionRateWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedCategoryConversionRateData;

        if (isSuccess)
        {
            const { funnelChart, percentageOfChange, percentageVisited } = data;

            if (percentageVisited === 0)
            {
                return <NoDataWidgetMessage />;
            }

            return (
                <React.Fragment>
                    <p className="para gap0"><strong>{percentageVisited}%</strong> {trans("SearchedConversionRateWidget.of_your_app_users_have_visited_your_stor")}</p>
                    <WidgetPercentageText percentage={percentageOfChange} />

                    <div style={{ height: "300px" }}>
                        <FunnelChart
                            options={CATEGORY_CONVERSION_CHART_OPTIONS}
                            data={funnelChart}
                        />
                    </div>
                </React.Fragment>
            );
        }
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processCategoryWidget)} />;
        }
        // if loading
        else 
        {
            return WidgetLoadingBoxReports();
        }

    }, [processedCategoryConversionRateData, trans]);

    return (
        <DefaultBox
            className="alignedWidget"
            heading={trans("SearchedConversionRateWidget.What_is_your_conversion_rate?")}
        >
            {
                renderSearchedConversionRateWidget()
            }
        </DefaultBox >
    );
};