import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSessions } from '../../../_api/queries';
import { processWebEngagementSessionDataToPeakHourVsDay } from '../../../_utils/chart.utils';
import useCommon from '../../pages/useCommon';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const useWebEngagementPeakHourVsDay = () =>
{
  const sessionsQuery = useWebAnalyticsSessions();

  const { trans, languageCode, timeZone } = useCommon();

  const processedWidgetData = useMemo(() =>
  {
    const { isSuccess, data: queryData } = sessionsQuery;


    let processedData = {
      tableData: [],
      overallPeakHourTime: 0,
      overallPeakHourWeekDayName: "",
      overallMaxVisitPercentage: ""
    };

    // process the data
    if (!!isSuccess && queryData)
    {
      const { currentIntervalData: data } = queryData || {};



      processedData = processWebEngagementSessionDataToPeakHourVsDay(data, timeZone, languageCode);
    }

    return processedData;
  }, [sessionsQuery, timeZone, languageCode]);


  return { sessionsQuery, processedWidgetData, trans };
};

export default useWebEngagementPeakHourVsDay;