import { useContext, useEffect, useMemo } from 'react';
import { useWebAnalyticsSessions } from '../../../_api/queries';
import useCommon from '../../pages/useCommon';
import { processNewVsRepeatWebAnalytics } from '../../../_utils/chart.utils';
import useExportDataConnector from '../../common/ExportToCsv/useExportDataConnector';
import { EngagementContext } from "../../pages/engagement/EngagementContext";

const UseNewVsRepeatWebEngamenet = () =>
{
  const { timeZone, filters: { analyticsRequestFilter }, trans } = useCommon();

  const sessionsQuery = useWebAnalyticsSessions();

  const processedWidgetData = useMemo(() =>
  {
    const { isSuccess, data: queryData } = sessionsQuery;


    let processedData = {
      newUsersPercentage: 0,
      returningUsersPercentage: 0,
      chartData: [],
      timeZone: timeZone?.id || "UTC",
    };

    // process the data
    if (!!isSuccess && queryData)
    {
      const { currentIntervalData: currentIntervalSessionsData } = queryData || {};
      const dateRange = {
        startDate: new Date(analyticsRequestFilter.startTimeUTC),
        endDate: new Date(analyticsRequestFilter.endTimeUTC),
      };

      processedData = processNewVsRepeatWebAnalytics(currentIntervalSessionsData, dateRange, timeZone);

    }

    return { trans, data: processedData };
  }, [sessionsQuery, timeZone, analyticsRequestFilter, trans]);


  return { sessionsQuery, processedWidgetData, analyticsRequestFilter };

};

export default UseNewVsRepeatWebEngamenet;