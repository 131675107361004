import React from "react";

const CommonlyUsedDeviceInfoText = ({ mostUsedModel, trans }) => {
    return (
        <p className="para">
            {!!mostUsedModel && (
                <React.Fragment>
                    {mostUsedModel.percentageOfUsers}% {trans("CommonlyUsedDevicesWidget.of_your_users_use")}
                    <strong>{mostUsedModel.modelName}</strong> {trans("CommonlyUsedDevicesWidget.in_your_property")}
                </React.Fragment>
            )}
        </p>
    );
};

export default CommonlyUsedDeviceInfoText;
