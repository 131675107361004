import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";


export const WidgetPercentageText = ({ percentage }) =>
{
    const trans = useTranslation().t;
    const arrow = percentage > 0 ? "up" : "down";
    const text = percentage > 0 ? trans("PercentagePara.An_increase_of") : trans("PercentagePara.A_decrease_of");

    // if no diffrence 
    if (percentage === 0)
    {
        return (<PercentagePara content="" />)
    }

    // if percentage of change is number
    else if (!isNaN(percentage))
    {
        return (
            <PercentagePara arrow={arrow} content={`${text} ${Math.abs(percentage)}%`} />
        )
    }
    // if percentage of change is not a number 
    else 
    {
        return (
            <PercentagePara content="" />
        )
    }
}
export const PercentagePara = ({ content, arrow }) =>
{
    return (
        <p className={`percentagePara${arrow ? " arrow" + arrow : ""}`}>
            {content}
            {
                arrow && (<Icon name={"arrow " + arrow} />)
            }
        </p>
    );
}