import React, { useEffect } from "react";
import { calcualtePercentage } from "../../_utils/utils";
import { calculatePercentageOfChange } from "mapsted.utils/numbers";
import { OverviewDetailBox, OverviewDetailBoxLoading } from "../common/overviewDetailBox/OverviewDetailBox";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

const NewUsersNumberWidget = ({ isSuccess, isError, trans, dashboardSummary, dashboardSummaryCompare, injectProcessedData }) =>
{
    const connectedPageName = useRecoilValue(connectedPageNameState);
    
    useEffect(() =>
    {
        const newUserPercentage = calcualtePercentage(dashboardSummary?.numberNewUsers, dashboardSummary?.numberUsers);
        const percentageChange = calculatePercentageOfChange(dashboardSummary?.numberNewUsers, dashboardSummaryCompare?.numberNewUsers);

        const payload = {
            name: "New Users",
            data: [{
                "NewUserPercentage": newUserPercentage,
                "PercentageOfChange": percentageChange
            }],
            description: "",
            inject: injectProcessedData
        };
        injectProcessedData && injectProcessedData(payload);
    }, [dashboardSummary?.numberNewUsers, dashboardSummaryCompare?.numberNewUsers, connectedPageName]);
    if (isSuccess)
    {
        return (
            <OverviewDetailBox
                max="180px"
                popupContent={trans("DashboardOverviewWidget.The_percentage_of_new_users_measured_ove")}
                icon="users"
                content={`${calcualtePercentage(dashboardSummary.numberNewUsers, dashboardSummary.numberUsers)}%`}
                slogan={trans("DashboardOverviewWidget.New_users")}
                percentage={calculatePercentageOfChange(
                    dashboardSummary.numberNewUsers,
                    dashboardSummaryCompare.numberNewUsers
                )}
            />
        );
    } else if (isError)
    {
        return (
            <OverviewDetailBox
                max="180px"
                popupContent={trans("DashboardOverviewWidget.The_percentage_of_new_users_measured_ove")}
                icon="users"
                content="--"
                slogan={trans("DashboardOverviewWidget.New_users")}
                percentage="--"
            />
        );
    }

    return <OverviewDetailBoxLoading />;
};

export { NewUsersNumberWidget };
