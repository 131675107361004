import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { analyticsRequestFilterSelector, activeTimeZoneSelectorState } from "../../store/DashboardAtoms";
import { DefaultBox } from "../common/DefaultBox";
import { WidgetPercentageText } from "../common/PercentagePara";
import { WidgetLoadingBoxReports } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage, NoDataWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { getDateGroupAndFormat, processAppUsageVsTimeWidget } from "../../_utils/chart.utils";
import { LineChart } from "../charts/LineChart";
import { APP_USAGE_PER_DAY_LINE_CHART_OPTIONS } from "../../_constants/chartConstants";
import { useTranslation } from "react-i18next";
import { refetchQuery } from "../../_utils/query.utils";
import { useEngagementOverviewQuery } from "../../_api/queries";
import useExportDataConnector from "../common/ExportToCsv/useExportDataConnector";
import { EngagementContext } from "../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../store/ExportToCsvAtoms";
import { formatDateValuesFromChartToCSV } from "../../_utils/exportToCsvUtils";

export const AppUsageVsTimeWidget = ({ injectProcessedData }) =>
{
    const trans = useTranslation().t;
    const connectedPageName = useRecoilValue(connectedPageNameState);


    const { analyticsRequestFilter } = useRecoilValue(analyticsRequestFilterSelector);
    const timeZone = useRecoilValue(activeTimeZoneSelectorState);

    // query  engagement/overview data with current and compare
    const engagementOverviewQuery = useEngagementOverviewQuery();

    // render widget/loading/error

    const processedWidgetData = useMemo(() =>
    {
        const { isLoading, isSuccess, isError, data: queryData, refetch } = engagementOverviewQuery;
        const { data, compareData } = queryData || {};

        let processedData;

        // process the data
        if (!!isSuccess && data)
        {
            const dateRange = {
                startDate: new Date(analyticsRequestFilter.startTimeUTC),
                endDate: new Date(analyticsRequestFilter.endTimeUTC)
            };

            processedData = processAppUsageVsTimeWidget({ data, compareData, dateRange, timeZone });
        }

        return { isLoading, isSuccess, isError, data: processedData, refetch };
    }, [engagementOverviewQuery, analyticsRequestFilter, timeZone]);

    const processedChartDataForExport = useMemo(() =>
    {
        if (!processedWidgetData?.data)
        {
            return [];
        };
        const { lineData, avgSessionsOpensPerUser, percentageOfChange } = processedWidgetData?.data;

        let startDate = new Date(analyticsRequestFilter.startTimeUTC);
        let endDate = new Date(analyticsRequestFilter.endTimeUTC);

        const { dateGroupFormat, dateGroupRange } = getDateGroupAndFormat(
            startDate,
            endDate
        );

        return lineData?.flatMap(item =>
            item?.data?.map(point => ({
                Date: formatDateValuesFromChartToCSV(point.x, dateGroupFormat),
                Sessions: point.y, 
                // avgSessionsOpensPerUser,
                // percentageOfChange,
            }))
        );
    }, [processedWidgetData?.data, connectedPageName, analyticsRequestFilter]);

    useEffect(() =>
    {
        const exportPayload = [
            {
                name: "On average, how often do users open your app",
                data: processedChartDataForExport,
                description: "",
                injector: injectProcessedData
            },
            // {
            //     name: "On average, how often do users open your app",
            //     data: engagementOverviewQuery?.data,
            //     description: "",
            //     injector : injectRawData
            // },
        ];

        exportPayload.forEach(({ name, data, description, injector }) =>
        {
            injector && injector({ name, data, description });
        });

    }, [processedChartDataForExport]);

    const customSliceTooltip = React.useCallback(({ slice }) => (
        <div className="widgetsTooltipBox">
            <strong>
                {slice.points[0].data.x}
            </strong>
            {
                // each points data ie. "[point.color] 120 New Visits"
                slice.points.map((point) => (
                    <div className="tooltipItemRow" key={point.id}>
                        <span style={{ background: point.borderColor }}></span>
                        {point.data.label} {trans("AppUsageVsTimeWidget.Sessions")}
                        <strong>{`${point.data.y}`}</strong>
                    </div>
                ))
            }

        </div>
    ), [trans]);

    const renderAppUsagePerDayVsTimeWidget = useCallback(() =>
    {
        const { isError, isSuccess, data } = processedWidgetData;

        if (isSuccess)
        {
            const { lineData, percentageOfChange, avgSessionsOpensPerUser } = data;

            if (!lineData)
            {
                return <NoDataWidgetMessage />;
            }
            return (
                // change to template
                <React.Fragment>
                    <p className="para gap0"> {trans("AppUsageVsTimeWidget.Users_have")} <strong>{avgSessionsOpensPerUser} {avgSessionsOpensPerUser > 1 ? trans("AppUsageVsTimeWidget.session") : trans("AppUsageVsTimeWidget.sessions")}  </strong>, {trans("AppUsageVsTimeWidget.on_average__in_the_selected_time_period_")} </p>
                    <WidgetPercentageText percentage={percentageOfChange} />

                    {/* <Image as="span" src="/img/chart-reports-users.svg" alt="" /> */}
                    <div className="ChartWrapper">
                        <LineChart
                            data={lineData}
                            options={APP_USAGE_PER_DAY_LINE_CHART_OPTIONS}
                            customSliceTooltip={customSliceTooltip}
                        />
                    </div>
                </React.Fragment>
            );
        }
        // if error
        else if (isError)
        {
            return <ErrorWidgetMessage onReloadClick={() => refetchQuery(processedWidgetData)} />;
        }
        else
        {
            return WidgetLoadingBoxReports();
        }
    }, [processedWidgetData, customSliceTooltip, trans]);


    return (
        <DefaultBox className="alignedWidget" heading={trans("AppUsageVsTimeWidget.On_average__how_often_do_users_open_your")}>
            {
                renderAppUsagePerDayVsTimeWidget()
            }
        </DefaultBox >
    );
};
