import { deepCopy } from "mapsted.utils/objects";
import { formatName } from "./utils";

const { createPointStyle } = require("mapsted.maps/mapFunctions/plotting");
const {
    LineDash,
    TrajectoryColor,
    NO_TRAJECTORY_DATA_STATUSES,
} = require("../_constants/insightsConstants");
const turf = require("@turf/turf");
const { Icon, Style } = require("ol/style.js");
const { Point } = require("ol/geom");

export const insightsTrajectoryStyle = ({
    color,
    lineDash,
    radius = 5,
    strokeColor,
}) => ({
    stroke: {
        color: color,
        width: 2,
        lineDash,
    },
    fill: {
        color: color,
    },
    shape: {
        // points: 4,
        // radius: 6,
        // radius2: 0,
        // angle: Math.PI / 4,
    },
    radius,
});

/**
 * @param {*} trajectoryPoint - {longitude, latitude}
 * @returns
 */
export const getCoordinatesFromPoint = ({ longitude, latitude }) => [longitude, latitude];

/**
 * returns the distance between two points in  the units provided (default meters).
 * this function assumes point2 comes after point1 in the trajectory array.
 * @param {Object} point1 -  { latitude, longitude, }
 * @param {Object} point2 -  { latitude, longitude, }
 * @param {String} units - defaults meters
 */
export const getDistanceBetweenPoints = (point1, point2, units = "meters") =>
{
    const turfPoint1 = turf.point(getCoordinatesFromPoint(point1));
    const turfPoint2 = turf.point(getCoordinatesFromPoint(point2));

    const distance = turf.distance(turfPoint1, turfPoint2, { units });

    return distance;
};

/**
 * Creates line segment style for insights trajectory
 * includes blue dotted path and arrow directions at the end of each segment
 * @param {*} feature
 * @returns
 */
export const lineSegmentStyleFunction = (feature) =>
{
    const geometry = feature.getGeometry();

    const styles = [
        // line string style
        createPointStyle(
            insightsTrajectoryStyle({
                color: TrajectoryColor.BLUE,
                lineDash: LineDash,
            })
        ),
    ];

    // Logic is as follows
    // 1. create an array of style arrays
    // -> each array should create arrows that travel along the same direction before it breaks
    // 2. for each arrow style array, only add the first, mid, last arrows to the styles array if greater than 3.
    let prevRotationRounded;
    let arrowStyleArray = [];
    let currentArray = [];

    // logic for drawing directional arrows
    geometry.forEachSegment(function (start, end)
    {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = Math.atan2(dy, dx);
        const roundedRotation = Math.floor(rotation);

        let style = new Style({
            geometry: new Point(end),
            image: new Icon({
                src: "/img/icon-chevron-right-blue.svg",
                anchor: [0.75, 0.5],
                rotateWithView: true,
                rotation: -rotation,
                scale: 0.55,
            }),
        });
        // If rotation = prev rotation -> don't add to style, add to prev style.
        if (roundedRotation === prevRotationRounded)
        {
            currentArray.push(style);
        }
        // else add to style list,
        else
        {
            arrowStyleArray.push(currentArray);
            currentArray = [style];
        }

        prevRotationRounded = Math.floor(rotation);
    });

    arrowStyleArray.forEach((arrowsArray) =>
    {
        if (arrowsArray.length !== 0)
        {
            if (arrowsArray.length <= 3)
            {
                styles.push(...arrowsArray);
            }
            else
            {
                let arrowsArrayLastIdx = arrowsArray.length - 1;
                styles.push(arrowsArray[0]);
                styles.push(arrowsArray[Math.floor(arrowsArrayLastIdx / 2)]);
                styles.push(arrowsArray[arrowsArrayLastIdx]);
            }
        }
    });

    // // logic for drawing start and endpoint
    // const firstPoint = geometry.getFirstCoordinate();
    // const lastPoint = geometry.getLastCoordinate();

    // styles.push(
    //     new Style({
    //         geometry: new Point(firstPoint),
    //         image: new Icon({
    //             src: "/img/icon-location-green.svg",
    //             anchor: [0.5, 0.8],
    //             rotateWithView: true,
    //             scale: .25,
    //         }),
    //     })
    // );
    // styles.push(
    //     new Style({
    //         geometry: new Point(lastPoint),
    //         image: new Icon({
    //             src: "/img/icon-location-red.svg",
    //             anchor: [0.5, 0.8],
    //             rotateWithView: true,
    //             scale: .25,
    //         }),
    //     })
    // );

    return styles;
};

/**
 * Creates property visits from user trajectory summaries query.
 * Hash map of property id -> visit information for the selected user
 * @param {Object} userTrajectorySummariesQuery - The user trajectory summaries query object.
 * @param {Object} propertyInfoMap - The property information map.
 * @return {Object} The property visits object.
 */
export const createPropertyVisitsFromUserTrajectorySummariesQuery = ({ userTrajectorySummariesQuery, propertyInfoMap }) =>
{
    let propertyVisits = {};
    // console.log(userTrajectorySummariesQuery.data);
    if (userTrajectorySummariesQuery && userTrajectorySummariesQuery.isSuccess && userTrajectorySummariesQuery.data && propertyInfoMap)
    {

        propertyVisits = deepCopy(userTrajectorySummariesQuery?.data);

        // filter user trajectories to only feature floor level data
        if (propertyVisits)
        {
            Object.keys(propertyVisits).forEach((propertyId) =>
            {
                let filteredUserTrajectories = propertyVisits[propertyId].filter((trajectory) => trajectory.floorId !== -1);

                if (!propertyInfoMap[propertyId] || filteredUserTrajectories.length === 0)
                {
                    delete propertyVisits[propertyId];
                }
                else
                {
                    propertyVisits[propertyId] = filteredUserTrajectories;
                }
            });
        }

    }
    return propertyVisits;
};


/**
 * Returns a hash map of zone id -> zone dwell information for the given trajectory info and property zones.
 * The hash map only contains zone dwell information that is within the time interval of the given trajectory info.
 * The zone dwell information is filtered to only include data for the same property and floor id as the given trajectory info.
 * @param {Object} trajectoryInfo - The trajectory info object.
 * @param {Array} propertyZones - The property zones array.
 * @return {Object} The zone dwell information hash map.
 */
export const getZonesDwellHashThatFitsInTrajectoryTimeSpan = (trajectoryInfo, propertyZones) =>
{
    // const getVisitHashKey = ({ startUnixTime_s, endUnixTime_s }) => `${startUnixTime_s},${endUnixTime_s}`;
    const isPropertyLevel = ({ propertyId, buildingId, floorId }) =>
    {
        if (!!propertyId && [buildingId, floorId].every((val) => val !== -1)) return true;

        return false;
    };

    const isFloorLevel = ({ propertyId, buildingId, floorId }) =>
    {

        if (!!propertyId && buildingId !== -1 && floorId !== -1) return true;

        return false;
    };

    const zonesHash = {};
    if (!propertyZones || !propertyZones?.length) return zonesHash;
    const { startUnixTime_s: t_startUnixTime_s, endUnixTime_s: t_endUnixTime_s, sessionId: t_sessionId } = trajectoryInfo;

    const hasValidIntervalThatFallsIncurrentTrajectory = ({ startUnixTime_s, endUnixTime_s, sessionId }) =>
    {
        const avgVisitTimeInSeconds = (endUnixTime_s + startUnixTime_s) / 2;

        // consider valid zone info's if session id of tarjectory and zone info are same
        // and zone info's start and end time falls in current trajectory's time interval
        const isValidZoneVisit = avgVisitTimeInSeconds >= t_startUnixTime_s && avgVisitTimeInSeconds <= t_endUnixTime_s && t_sessionId === sessionId;

        return isValidZoneVisit;

    };
    propertyZones.forEach((zg) =>
    {
        const { zoneGeofenceId: zoneId } = zg;

        const trajectoryAndZoneDataBothArePropertyLevel = isPropertyLevel(trajectoryInfo) && isPropertyLevel(zg) && trajectoryInfo.propertyId === zg.propertyId;
        const trajectoryAndZoneDataBothAreFloorLevel = isFloorLevel(trajectoryInfo) && isFloorLevel(zg) && trajectoryInfo.propertyId === zg.propertyId && trajectoryInfo.buildingId === zg.buildingId && trajectoryInfo.floorId === zg.floorId;
        // const hasSameSession = trajectoryInfo.sessionId === zg.sessionId;
        // console.log((trajectoryAndZoneDataPropertyBothPropertyLevel, trajectoryAndZoneDataBothAreFloorLevel), hasValidIntervalThatFallsIncurrentTrajectory(zg), trajectoryInfo, zg);
        if (hasValidIntervalThatFallsIncurrentTrajectory(zg) && (trajectoryAndZoneDataBothArePropertyLevel || trajectoryAndZoneDataBothAreFloorLevel))
        {
            if (zonesHash[zoneId])
            {

                zonesHash[zoneId].timeSpent_s += zg.timeSpent_s;
                if (zonesHash[zoneId].startUnixTime_s > zg.startUnixTime_s)
                {
                    zonesHash[zoneId].startUnixTime_s = zg.startUnixTime_s;
                    zonesHash[zoneId].startTime = zg.startTime;
                }
                if (zonesHash[zoneId].endUnixTime_s < zg.endUnixTime_s)
                {
                    zonesHash[zoneId].endUnixTime_s = zg.endUnixTime_s;
                    zonesHash[zoneId].endTime = zg.endTime;
                }

            }
            else
            {
                zonesHash[zoneId] = zg;
            }

        }
    });
    // console.log("zonesHash->", zonesHash);
    return zonesHash;
};


/**
 * Process complete analytics summary response to trajectory summary
 * @param {Object} analyticsCompleteSummaryData - The complete analytics summary response
 * @param {string} selectedUserUID - The selected user's ID
 * @param {Object} propertyInfoMap - The map of propertyId to propertyInfo
 * @return {Object} - The processed trajectory summary data
 * @property {Object} result
 * @property {Array} result[propertyId]
 * @property {Object} result[propertyId][0]
 * @property {string} result[propertyId][0].sessionUID
 * @property {string} result[propertyId][0].userUID
 * @property {Object} result[propertyId][0].startTime
 * @property {number} result[propertyId][0].startTime.unixTime_ms
 * @property {Object} result[propertyId][0].endTime
 * @property {number} result[propertyId][0].endTime.unixTime_ms
 * @property {Array} result[propertyId][0].trajectory
 * @property {Object} result[propertyId][0].zonesDwellInfoHash
 * @property {string} result[propertyId][0].zonesDwellInfoHash[zoneId]
 * @property {number} result[propertyId][0].zonesDwellInfoHash[zoneId].timeSpent_s
 */
export const processCompleteAnalyticsSummaryResponseToTrajectorySummary = (analyticsCompleteSummaryData, selectedUserUID, propertyInfoMap) =>
{
    let result;
    if (!analyticsCompleteSummaryData)
    {
        return result;
    }
    let propertyIdToUserTrajectoriesHash = {};
    let propertyInfo = {};
    let zonesPropertyHash = {};
    const { sessionInfos, trajectoryInfos, visitZoneGeofences: zonesDwellInfoInVisits = [], analyticsPropertyInfos } = analyticsCompleteSummaryData;
    analyticsPropertyInfos.forEach((property) =>
    {
        propertyIdToUserTrajectoriesHash[property.propertyId] = [];
        propertyInfo[property.propertyId] = property;

    });

    const sessionInfosHash = sessionInfos.filter((ss) =>
    {
        const userUID = ss.userId.toUpperCase();
        Object.assign(ss, { userUID, userId: userUID });
        // ss.userId = ss.userId.toUpperCase();
        return ss.userId === selectedUserUID?.toUpperCase();
    }).reduce((acc, sessionInfo) =>
    {
        sessionInfo.startTimeUTC = {
            unixTime_ms: sessionInfo.startUnixTime_s * 1000,
        };
        sessionInfo.endTimeUTC = {
            unixTime_ms: sessionInfo.endUnixTime_s * 1000,
        };
        sessionInfo.sessionUID = sessionInfo.sessionId;
        sessionInfo.userUID = sessionInfo.userId;

        acc[sessionInfo.sessionId] = sessionInfo;

        return acc;

    }, {});

    zonesDwellInfoInVisits.forEach((zg) =>
    {
        zg.userId = zg.userId.toUpperCase();
        zg.startTime = {
            unixTime_ms: zg.startUnixTime_s * 1000,
        };
        if (!zg.endUnixTime_s)
        {
            zg.endUnixTime_s = zg.startUnixTime_s + zg.duration_min * 60;
        }
        zg.endTime = {
            unixTime_ms: zg.endUnixTime_s * 1000,
        };
        zg.timeSpent_s = zg.duration_min * 60; // convert from minutes to seconds

        zg.userUID = zg.userId;

        if (!zonesPropertyHash[zg.propertyId])
        {
            zonesPropertyHash[zg.propertyId] = [];
        }

        zonesPropertyHash[zg.propertyId].push(zg);
    });


    trajectoryInfos.forEach((trajectoryInfo) =>
    {
        Object.assign(trajectoryInfo, { userId: trajectoryInfo.userId.toUpperCase() });
        if (sessionInfosHash[trajectoryInfo.sessionId]) // add only valid sessions
        {

            trajectoryInfo.sessionUID = trajectoryInfo.sessionId;

            // trajectoryInfosHash[getVisitHashKey(trajectoryInfo)] = trajectoryInfo;

            trajectoryInfo.zonesDwellInfoHash = getZonesDwellHashThatFitsInTrajectoryTimeSpan(trajectoryInfo, zonesPropertyHash?.[trajectoryInfo.propertyId] ?? []);
            trajectoryInfo.userUID = trajectoryInfo.userId;
            trajectoryInfo.sessionUID = trajectoryInfo.sessionId;
            trajectoryInfo.startTime = {
                unixTime_ms: trajectoryInfo.startUnixTime_s * 1000,
            };

            trajectoryInfo.endUnixTime_s = trajectoryInfo?.endUnixTime_s ?? trajectoryInfo.startUnixTime_s + trajectoryInfo.duration_min * 60;

            trajectoryInfo.endTime = {
                unixTime_ms: trajectoryInfo.endUnixTime_s * 1000,
            };

            trajectoryInfo.timeSpent_s = trajectoryInfo.duration_min * 60; // convert from minutes to seconds
            Object.assign(trajectoryInfo, { session: sessionInfosHash[trajectoryInfo.sessionId] });


            if (!Array.isArray(propertyIdToUserTrajectoriesHash[trajectoryInfo.propertyId]))
            {
                propertyInfo[trajectoryInfo.propertyId] = propertyInfoMap[trajectoryInfo.propertyId];
                propertyIdToUserTrajectoriesHash[trajectoryInfo.propertyId] = [];
            }
            propertyIdToUserTrajectoriesHash[trajectoryInfo.propertyId].push(trajectoryInfo);

        }
    });


    return propertyIdToUserTrajectoriesHash;
};

const getReliableUniqueKeyFromUserObj = ({ email }) =>
{
    let key = ([email].join('').trim()).toUpperCase();
    return key;
};
export const processAnalyticsSummaryResponseToUserInfos = (analyticsCompleteSummaryData, companyUsersAndPropertyIdsList = {}) =>
{
    let result;
    if (!analyticsCompleteSummaryData)
    {
        return result;
    }
    const { trajectoryInfos, userInfos = [] } = analyticsCompleteSummaryData;
    const { propertyIdsList = [], users = [], filterUserBasedOfTeamAndPropertyVisits } = companyUsersAndPropertyIdsList;

    const teamInfoHashHashByName = {};
    const usersInfoHashFromTeams = users.reduce((acc, user) =>
    {
        const userUID = user.id.toUpperCase();
        const uniqueKey = getReliableUniqueKeyFromUserObj(user);
        if (!teamInfoHashHashByName[uniqueKey])
        {
            teamInfoHashHashByName[uniqueKey] = [];
        }

        acc[userUID] = {
            ...user,
            id: userUID

        };
        teamInfoHashHashByName[uniqueKey].push(acc[userUID]);
        return acc;
    }, {});


    result = deepCopy(userInfos).map((userInfo) =>
    {
        Object.assign(userInfo, { userId: userInfo.userId.toUpperCase() });
        const { id, ...userDataFromTeam } = usersInfoHashFromTeams[(userInfo.userId)] ?? {};
        const processedObj = {
            ...userDataFromTeam,
            ...userInfo,
            userUID: userInfo.userId,
            email: userInfo?.email ?? userInfo.userId,// this is set as dummy value for now not to break the UI
        };

        return {
            ...processedObj,
            firstName: processedObj?.firstName ?? "",
            lastName: processedObj?.lastName ?? "",
        };
    });



    let filteredPropertyVisitStructuredHash = {};

    filteredPropertyVisitStructuredHash = trajectoryInfos.filter((trajectoryInfo) =>
    {
        Object.assign(trajectoryInfo, { userId: trajectoryInfo.userId.toUpperCase() });
        if (filterUserBasedOfTeamAndPropertyVisits)
        {

            return propertyIdsList?.includes(trajectoryInfo.propertyId);
        }

        return true;
    }).reduce((acc, trajectoryInfo) =>
    {

        if (!acc[trajectoryInfo.userId])
        {
            acc[trajectoryInfo.userId] = [];
        }

        acc[trajectoryInfo.userId].push(trajectoryInfo);

        return acc;

    }, {});

    // console.log(filteredPropertyVisitStructuredHash);
    if (Object.keys(filteredPropertyVisitStructuredHash).length)
    {

        result = result.filter((userInfo) =>
        {
            //console.log("userInfo.userId", userInfo.userId, filteredPropertyVisitStructuredHash?.[userInfo.userId], userInfo);
            let isValidUser = Object.keys(filteredPropertyVisitStructuredHash?.[userInfo.userId] ?? {}).length;

            //filter users who are not teams
            if (filterUserBasedOfTeamAndPropertyVisits && !usersInfoHashFromTeams[userInfo.userId])
            {

                const key = getReliableUniqueKeyFromUserObj(userInfo); //

                isValidUser = isValidUser && !!key && !!teamInfoHashHashByName[key]?.length;
            }

            return isValidUser;
        });

    }


    return { userEmails: result };



};


/**
 * Process session summary status from analytics summary response
 * @param {Object} analyticsCompleteSummaryData
 * @return {Object} result
 * @property {Object} result
 * @property {Object} result[sessionUID]
 * @property {number} result[sessionUID].startUnixTime_s
 * @property {number} result[sessionUID].endUnixTime_s
 * @property {Object} result[sessionUID].startTime
 * @property {number} result[sessionUID].startTime.unixTime_ms
 * @property {Object} result[sessionUID].endTime
 * @property {number} result[sessionUID].endTime.unixTime_ms
 * @property {string} result[sessionUID].sessionUID
 * @property {number} result[sessionUID].sessionPostProcessingStatus
 * @property {number} result[sessionUID].sessionStatus
 */
export const processAnalyticsSummaryResponseToSessionStatus = (analyticsCompleteSummaryData) =>
{
    let result;
    if (!analyticsCompleteSummaryData)
    {
        return result;
    }

    const { sessionInfos = [] } = analyticsCompleteSummaryData;
    result = deepCopy(sessionInfos).reduce((acc, sessionInfo) =>
    {
        sessionInfo.startTimeUTC = {
            unixTime_ms: sessionInfo.startUnixTime_s * 1000,
        };
        if (sessionInfo.endUnixTime_s)
        {
            sessionInfo.endTimeUTC = {
                unixTime_ms: sessionInfo.endUnixTime_s * 1000,
            };
        }

        sessionInfo.startTime = {
            unixTime_ms: sessionInfo.startUnixTime_s * 1000,
        };
        if (sessionInfo.endUnixTime_s)
        {
            sessionInfo.endTime = {
                unixTime_ms: sessionInfo.endUnixTime_s * 1000,
            };
        }

        sessionInfo.sessionUID = sessionInfo.sessionId;
        Object.assign((sessionInfo), { userUID: sessionInfo?.userId?.toUpperCase() });



        acc[sessionInfo.sessionUID] = sessionInfo;


        return acc;
    }, {});

    return result;
};


/**
 * Returns a unique visit id that can be used to identify a trajectory.
 * @param {Object} trajectoryInfo - The trajectory info object.
 * @returns {string} - The unique visit id in the format of sessionUID-sessionIdx.
 */
export const getUniqVisitId = ({ sessionUID, sessionIdx }) =>
{

    return `${sessionUID}-${sessionIdx}`;
};



/**
 * Process the trajectory data from the analytics summary response
 * @param {Object} analyticsCompleteSummaryData - The response data from the analytics summary api
 * @returns {Object} - The processed trajectory data.
 * @property {Array} trajectories - The list of trajectories
 * @property {Object} trajectories[0] - The first trajectory in the list
 * @property {string} trajectories[0].sessionUID - The session UID
 * @property {string} trajectories[0].userUID - The user UID
 * @property {Object} trajectories[0].startTime - The start time of the trajectory
 * @property {number} trajectories[0].startTime.unixTime_ms - The start time in milliseconds
 * @property {Object} trajectories[0].endTime - The end time of the trajectory
 * @property {number} trajectories[0].endTime.unixTime_ms - The end time in milliseconds
 * @property {Array} trajectories[0].trajectory - The list of points in the trajectory
 * @property {Object} trajectories[0].zonesDwellInfoHash - The hash of all the zones that the user visited
 * @property {string} trajectories[0].zonesDwellInfoHash[zoneId] - The time spent in the zone in seconds
 */

export const processTrajectoryDataFromAnalyticsSummaryResponse = (analyticsCompleteSummaryData) =>
{
    let result;
    if (!analyticsCompleteSummaryData)
    {
        return result;
    }

    const { trajectories, visitZoneGeofences: zonesDwellInfoInVisits = [] } = analyticsCompleteSummaryData;

    zonesDwellInfoInVisits.forEach((zg) =>
    {
        zg.userId = zg.userId.toUpperCase();
        zg.startTime = {
            unixTime_ms: zg.startUnixTime_s * 1000,
        };
        if (!zg.endUnixTime_s)
        {
            zg.endUnixTime_s = zg.startUnixTime_s + zg.duration_min * 60;
        }
        zg.endTime = {
            unixTime_ms: zg.endUnixTime_s * 1000,
        };
        zg.timeSpent_s = zg.duration_min * 60; // convert from minutes to seconds

        zg.userUID = zg.userId;
    });
    trajectories.forEach((trajectoryInfo) =>
    {
        trajectoryInfo.userId = trajectoryInfo.userId.toUpperCase();
        trajectoryInfo.sessionUID = trajectoryInfo.sessionId;
        trajectoryInfo.userUID = trajectoryInfo.userId;
        trajectoryInfo.startTime = {
            unixTime_ms: trajectoryInfo.startUnixTime_s * 1000,
        };

        trajectoryInfo.endUnixTime_s = trajectoryInfo.startUnixTime_s + trajectoryInfo.duration_min * 60; //compute the trajectory end time

        trajectoryInfo.endTime = {
            unixTime_ms: trajectoryInfo.endUnixTime_s * 1000,
        };

        trajectoryInfo.trajectory = trajectoryInfo.trajectory.map(([longitude, latitude, unix_time_s]) => ({ longitude, latitude, unix_time_s }));




        trajectoryInfo.zonesDwellInfoHash = getZonesDwellHashThatFitsInTrajectoryTimeSpan(trajectoryInfo, zonesDwellInfoInVisits);
    });


    return trajectories[0];
};

export const isTrajectoryConstructed = ({ trajectoryReconstructionStatus }) => !NO_TRAJECTORY_DATA_STATUSES.includes(trajectoryReconstructionStatus);


/**
 * @function
 * @description Returns a dropdown option object from a user object. If the user's email is different than their userUID and they have a first or last name, the text of the option will be their name; otherwise, it will be their email.
 * @param {Object} userObj - The user object
 * @param {string} userObj.userUID - The user's UID
 * @param {string} userObj.email - The user's email
 * @param {string} [userObj.firstName] - The user's first name
 * @param {string} [userObj.lastName] - The user's last name
 * @returns {Object} The dropdown option object
 * @property {string} key - The key of the option
 * @property {string} text - The text of the option
 * @property {string} value - The value of the option
 */

export const getUserOptionFromUserObj = (userObj) =>
{
    const { userUID, email, firstName, lastName } = userObj;

    const option = { key: email, text: email, value: email, popupContent: email, data: userObj };

    if ([firstName, lastName].some((f) => f?.trim()?.length))
    {
        option.text = `${formatName(firstName ?? '')} ${formatName(lastName ?? '')}`;

        option.popupContent = `${option.text} , ${option.key} `;
    }

    return option;

};

export const getCleansUserOptions = (_options) =>
{
    let options = _options;
    // Sorting names based on alphabetical order
    options = options.sort((a, b) =>
    {
        if (a.text < b.text)
        {
            return -1;
        }
        if (a.text > b.text)
        {
            return 1;
        }
        return 0;
    });
    const [usersWithEmailIds, usersWithoutEmailIds] = options.reduce((acc, option) =>
    {
        if (option.data.email !== option.data.userUID)
        {
            acc[0].push(option);
        }
        else
        {
            acc[1].push(option);
        }
        return acc;
    }, [[], []]);

    return [...usersWithEmailIds, ...usersWithoutEmailIds];
};


/**
 * @function
 * @description Process the company teams and return the users and property Ids for the selected team
 * @param {Array} companyTeams - List of teams from the company
 * @param {String} selectedTeamId - The selected team id
 * @returns {Object} - Object with the following properties
 *                    - filterUserBasedOfTeamAndPropertyVisits: boolean flag to filter users based on the selected team and its properties
 *                    - users: Array of users from the selected team
 *                    - propertyIdsList: Array of property ids from the selected team
 *                    - key: unique key for the object
 */
export const getTeamsAndTeamPropertyIds = (companyTeams, selectedTeamId) =>
{
    const selectedTeam = selectedTeamId;
    const result = { filterUserBasedOfTeamAndPropertyVisits: false, users: [], propertyIdsList: [], key: Date.now() };
    if (Array.isArray(companyTeams))
    {
        if (selectedTeam)
        {
            const team = companyTeams.find((team) => team.id === selectedTeam);
            result.filterUserBasedOfTeamAndPropertyVisits = true;

            result.users = team?.users ?? [];
            result.propertyIdsList = team?.properties.map((property) => property.id) ?? [];
        }
        else
        {
            companyTeams.forEach((team) =>
            {
                result.users = [...result.users, ...team.users];
                result.propertyIdsList = [...result.propertyIdsList, ...team?.properties.map((property) => property.id)];
            });
        }

    }
    result.propertyIdsList = [...new Set(result.propertyIdsList)];


    return result;



};


export const generateUniqIdFromPropertyIdBuildingFloorId = ({ propertyId, buildingId, floorId }) => [propertyId, buildingId, floorId].join('_');
export const getFloorIdFromPropertyIdBuildingFloorId = (uniqId) => uniqId.split('_')[2] ?? -1;


/**
 * filterInsightSummaryDataByEmails is a utility function that takes in an object containing
 * userInfos, analyticsPropertyInfos, sessionInfos, and trajectoryInfos and an array of email
 * addresses. It filters out the userInfos, sessionInfos, and trajectoryInfos based on the
 * provided email addresses and returns the filtered data.
 *
 * @param {object} data - an object containing userInfos, analyticsPropertyInfos, sessionInfos, and trajectoryInfos
 * @param {string[]} emails - an array of email addresses
 * @return {object} the filtered data
 */
export const filterInsightSummaryDataByEmails = (data, emails) =>
{
    const { userInfos, sessionInfos, trajectoryInfos } = data;

    if (!emails?.length)
    {
        return data;
    }

    const filteredUserInfos = userInfos.filter((user) =>
    {
        Object.assign(user, { userId: user.userId.toUpperCase() });
        return !!user?.email && emails.includes(user.email.toLowerCase());
    });

    const hash = filteredUserInfos.reduce((acc, user) => ({ ...acc, [user.userId]: user }), {});

    const filteredSessions = sessionInfos.filter((session) => !!hash[session.userId.toUpperCase()]);
    const filteredTrajectoryInfos = trajectoryInfos.filter((trajectory) => !!hash[trajectory.userId.toUpperCase()]);

    return { ...data, userInfos: filteredUserInfos, sessionInfos: filteredSessions, trajectoryInfos: filteredTrajectoryInfos };

};
