import React, { memo, useContext, useEffect } from "react";
import { NumberWidgetConditionalLoader } from "../../common/NumberWidgetCommon";
import { OverviewDetailBox } from "../../common/overviewDetailBox/OverviewDetailBox";
import Translation from "../../common/translation/Translation";
import useWebEnagamentNumberWidget from "./useWebEnagamentNumberWidget";
import { EngagementContext } from "../../pages/engagement/EngagementContext";
import { connectedPageNameState } from "../../../store/ExportToCsvAtoms";
import { useRecoilValue } from "recoil";

export const NoOfVisitorsNumberWidgetWebEngagement = memo(({ injectProcessedData }) =>
{

    const { allNumberWidgetsProcessedData, rawQuery } = useWebEnagamentNumberWidget();

    const { noOfVisitorsPercentage, totalVisitors } = allNumberWidgetsProcessedData;
    const connectedPageName = useRecoilValue(connectedPageNameState);

    useEffect(() =>
    {
        const payload =
        {
            name: "Visits",
            data: [{
                "Visits": totalVisitors,
                "PercentageOfChange": noOfVisitorsPercentage
            }],
            description: "",
        };
        injectProcessedData && injectProcessedData(payload);

    }, [allNumberWidgetsProcessedData, connectedPageName]);

    return <>
        <NumberWidgetConditionalLoader
            rawQuery={rawQuery}
            errorChildren={<OverviewDetailBox
                appOnly={false}
                max="220px"
                popupContent={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.popupContent" />}
                icon="visitors"
                content={"--"}
                percentage={0}
                slogan={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.slogan" />}
            />}
            noDataChildren={<OverviewDetailBox
                appOnly={false}
                max="220px"
                popupContent={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.popupContent" />}
                icon="visitors"
                content={"--"}
                percentage={0}
                slogan={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.slogan" />}
            />}
        >
            <OverviewDetailBox
                appOnly={false}
                webOnly={true}
                max="220px"
                popupContent={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.popupContent" />}
                icon="visitors"
                content={totalVisitors}
                percentage={noOfVisitorsPercentage}
                slogan={<Translation text="Widgets.NoOfVisitorsNumberWidgetWebEngagement.slogan" />}
            />
        </NumberWidgetConditionalLoader>



    </>;
});