const { Tile: TileLayer, Vector: VectorLayer, Heatmap: HeatmapLayer } = require("ol/layer");
const { Vector: VectorSource } = require("ol/source");
const { Point, LineString, Polygon, Circle: CircleGeom } = require("ol/geom");
const { Style, Fill, Stroke, Text, Circle, RegularShape, Icon } = require("ol/style");
const { XYZ } = require("ol/source");
const { toMercatorFromArray, isGeoRef, getIndexOfFeature, isImageFeature } = require("../utils/map.utils");
const { ShapeTypes, EntityRefTypes } = require("../utils/entityTypes");
const { MapConstants } = require("../utils/map.constants");
const { deepValue } = require("mapsted.utils/objects");
const { styleClassic, MAP_TEXT_STYLE, MAP_ID_TEXT_STYLE, TILE_LAYERS_IDS } = require("../utils/defualtStyles");
const { Feature } = require("ol");
const { getTextFontSettings } = require("./stylesAndThemes");
const turf = require("@turf/turf");

exports.cartoLayer = (tileLayerStyle = styleClassic.tileLayer, options = { trans: undefined, transLookup: undefined }) =>
{
    const year = new Date().getFullYear();

    const { trans, transLookup } = options;

    let attribution = (trans) ? `<p>${trans(`${transLookup}.Copyright`)} © 2014-${year} <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">Mapsted Corp</a>. ${trans(`${transLookup}.All_rights_reserved`)}.</p>`
        : `<p>Copyright © 2014-${year} <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">Mapsted Corp</a>. All rights reserved.</p>`;

    let contributors = (trans) ? trans(`${transLookup}.Contributors`)
        : "contributors";

    //https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png

    let tileLayer = new TileLayer({
        source: new XYZ({
            url: tileLayerStyle.url,
            projection: tileLayerStyle.projection,
            attributions:
                `<div class="map-attribution">
                ${attribution}
                <p>
                    ${tileLayerStyle.attributions}
                    ${contributors}
                </p>

            </div>`,
            crossOrigin: 'Anonymous',
        }),
    });

    tileLayer.set("isBaseMapLayer", true);
    tileLayer.set("id", TILE_LAYERS_IDS.CARTO_LAYER);

    return tileLayer;
};

exports.tileLayerFromGoogle = (tileLayerStyle, options) =>
{

    const year = new Date().getFullYear();

    const { trans, transLookup } = options || {};
    let attribution = (trans) ? `<p>${trans(`${transLookup}.Copyright`)} © 2014-${year} <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">Mapsted Corp</a>. ${trans(`${transLookup}.All_rights_reserved`)}.</p>`
        : `<p>Copyright © 2014-${year} <a href="https://mapsted.com" target="_blank" rel="noopener noreferrer">Mapsted Corp</a>. All rights reserved.</p>`;

    let contributors = (trans) ? trans(`${transLookup}.Contributors`)
        : "contributors";
    let tileLayer = new TileLayer({
        source: new XYZ({
            url: tileLayerStyle.url,
            attributions:
                `<div class="map-attribution">
                ${attribution}
                <p>
                    ${tileLayerStyle.attributions}
                    ${contributors}
                </p>

            </div>`,
            crossOrigin: 'Anonymous',
            projection: tileLayerStyle.projection, // Specify the projection
            tileLoadFunction: function (imageTile, src)
            {
                const url = src.replace('{s}', Math.floor(Math.random() * 4)); // select the google server at random from 0 to 3
                imageTile.getImage().src = url;
            }
        })
    });


    tileLayer.set("isBaseMapLayer", true);
    tileLayer.set("id", "google");

    return tileLayer;
};

exports.getSatelliteViewTileLayerFromGoogle = (layerStyles = styleClassic.satelliteTileLayer, options) =>
{
    const tileLayer = exports.tileLayerFromGoogle(layerStyles, options);
    tileLayer.set("id", TILE_LAYERS_IDS.SATELLITE_TILE_LAYER);
    return tileLayer;
};



/**
 * Updates the entity's style with the options given.
 *
 * @param {Object} feature - feature to be updated
 * @param {Object} styleOptions - new style options
 */
exports.changeEntityStyle = (feature, styleOptions) =>
{
    const entityStyle = exports.createEntityStyle(styleOptions);

    (feature) && feature.setStyle(entityStyle);
};

/**
 * Updates the entity text style given the params.
 * @param {Object} textFeature - the text feature to be updated
 * @param {String} name
 * @param {Number} textRotation
 * @param {Object} textStyle
 */
exports.changeEntityText = (textFeature, name, textRotation, textStyle) =>
{
    const text = exports.createTextStyle(name, textRotation, textStyle);

    let style = textFeature.getStyle();

    style.setText(text);

    textFeature.setStyle(style);
};

exports.createVectorLayer = (layerId, style, options = {}) =>
{
    const vectorLayer = new VectorLayer({
        id: layerId,
        style: style,
        ...options
    });

    vectorLayer.setSource(new VectorSource());

    return vectorLayer;
};

exports.createHeatmapLayer = (layerId, options = {}) =>
{
    let heatmapLayerDefaultOptions = {
        id: layerId,
        radius: 3,
        blur: 12,
        opacity: 1,
        weight: (feature) => feature.get("weight")
    };

    heatmapLayerDefaultOptions = Object.assign(heatmapLayerDefaultOptions, options);

    const heatmapLayer = new HeatmapLayer(heatmapLayerDefaultOptions);

    heatmapLayer.setSource(new VectorSource());

    return heatmapLayer;
};

/**
 * Creates entity labels for property entities using building information.
 * @param {Object} entities
 * @param {Object} property
 */
exports.linkBuildingsToEntityLabel = (entities, property) =>
{
    if (!!entities && !!property)
    {
        Object.values(entities).forEach((entity) =>
        {
            // check that the entity belongs to a property and is linked to a building.
            if (entity.refType === EntityRefTypes.PROPERTY && !!entity.building)
            {
                entity.entityLabel = deepValue(property, `allBuildings.${entity.building}`, {});
            }
        });
    }
    return;
};

/**
 * Adds text with styling to text style.
 *
 * @param {String} name
 * @param {Number} textRotation
 * @param {Object} textStyle
 */
exports.createTextStyle = (name, textRotation = 0, textStyle) =>
{
    const rotationRad = textRotation * Math.PI / 180;
    const [isMulti, text] = exports.addLineBreaksToString(name);
    const LARGE_SCALE = 1.1;
    const SMALL_SCALE = 1;
    if (textStyle)
    {
        return new Text({
            text,
            rotation: rotationRad,
            overflow: true,
            fill: new Fill(textStyle.fill),
            font: textStyle.font,
            stroke: new Stroke(textStyle.stroke),
            scale: isMulti ? SMALL_SCALE : LARGE_SCALE,
            rotateWithView: textStyle.rotateWithView || false // to handle undefined case
        });
    }
    else
    {
        return new Text({
            text,
            rotation: rotationRad,
            scale: isMulti ? SMALL_SCALE : LARGE_SCALE
        });
    }
};

/**
 * Creates a style objects based on the params given.
 *
 * @param {Object} style
 * @param {Object} [style.stroke]
 * @param {Object} [style.fill]
 */
exports.createEntityStyle = (style = {}) =>
{
    const { stroke, fill, zIndex, shape } = style;
    if (!!shape)
    {
        let style = new Style({
            image: new RegularShape({
                stroke: new Stroke(stroke),
                fill: new Fill(fill),
                ...shape
            })
        });

        return style;
    }
    if (!!stroke && !!fill)
    {
        let style = new Style({
            stroke: new Stroke(stroke),
            fill: new Fill(fill),
            zIndex
        });
        return style;
    }
    else
    {
        return new Style();
    }
};

/**
 * Generate styles for arrow features on a line.
 * pulled from OL example https://openlayers.org/en/latest/examples/line-arrows.html
 * @param {Object} feature - The feature to create arrow styles for.
 * @return {Array} An array of styles for the arrow features.
 */
exports.createArrowStylesOnLineFeature = (feature) =>
{
    const geometry = feature.getGeometry();
    const styles = [];

    // geometry.forEachSegment(function (start, end)
    // {
    //     // add triangle to end of the line
    //     const dx = end[0] - start[0];
    //     const dy = end[1] - start[1];
    //     const rotation = Math.atan2(dy, dx);

    //     const trianglePointerGeometry = turf.sector(end, 15, rotation, 45);
    //     const featureGeometry = exports.createEntityGeometry(trianglePointerGeometry.geometry);

    //     console.log({ trianglePointerGeometry })
    //     let triangleFeature = new Feature({
    //         geometry: featureGeometry,
    //     });
    //     // arrows
    //     styles.push(triangleFeature);
    // });

    return styles;
};

/**
 * Creates a style objects based on the params given.
 *
 * @param {Object} style
 * @param {Object} [style.stroke]
 * @param {Object} [style.fill]
 */
exports.createPointStyle = (style = {}) =>
{
    const { stroke, fill, zIndex, radius } = style;

    if (!!stroke && !!fill)
    {
        let style = new Style({
            image: new Circle({
                radius: radius,
                stroke: new Stroke(stroke),
                fill: new Fill(fill),
            }),
            stroke: new Stroke(stroke),
            fill: new Fill(fill),
            zIndex
        });

        return style;
    }
    else
    {
        return undefined;

    }
};

/**
 * Creates layers based on the information given in the Array "layerStyles".
 * Adds an additional text cluster layer on top of all the layers.
 *
 * Note: layerIdx is used to to sort the layers from least to highest, however it does not plot -1.
 * @param {Array} layerStyles
 */
exports.createEntityLayers = (layerStyles) =>
{
    let entityLayers = {};

    layerStyles.forEach((layerStyle) =>
    {
        const vectorLayer = new VectorLayer({
            minZoom: layerStyle.minZoomLevel,
            maxZoom: layerStyle.maxZoomLevel,
            opacity: layerStyle.defaultOpacity,
            id: parseInt(layerStyle.layerIdx),
        });

        vectorLayer.setSource(new VectorSource());
        entityLayers[parseInt(layerStyle.layerIdx)] = vectorLayer;

    });

    // Create the text layer to be clustered.
    const textLayer = new VectorLayer({
        id: [MapConstants.TEXT_LAYER],
        // style: exports.getClusterStyle,
        declutter: true
    });

    textLayer.setSource(new VectorSource());
    entityLayers[MapConstants.TEXT_LAYER] = textLayer;

    return entityLayers;
};

exports.createEntityCategoryLayers = (categoryMap) =>
{
    let entityCategoryLayers = {};

    Object.keys(categoryMap).forEach((categoryId) =>
    {
        const vectorLayer = new VectorLayer({
            id: categoryId
        });

        vectorLayer.setSource(new VectorSource());

        entityCategoryLayers[categoryId] = vectorLayer;
    });

    return entityCategoryLayers;
};

/**
 * Creates entity OL geometry based on the shape object stored in entities.
 * @param {Object} shape
 * @param {Object} options {radius}
 */
exports.createEntityGeometry = (shape, options = { pointToCircle: false, radius: 0.6 }) =>
{
    let geometry = undefined;

    if (!shape)
    {
        return;
    }

    const radius = options
        ? options.radius
        : 0.6;

    switch (shape.type)
    {
        case ShapeTypes.POINT:
            {
                let coordinates = toMercatorFromArray(shape.coordinates);
                geometry = new Point(coordinates);

                if (options && options.pointToCircle)
                {
                    geometry = new CircleGeom(coordinates, radius);
                }
                break;
            }

        case ShapeTypes.LINE_STRING:
            {
                let coordinates = [];
                (shape.coordinates) && shape.coordinates.forEach((coordinate) =>
                {
                    coordinates.push(toMercatorFromArray(coordinate));
                });

                geometry = new LineString(coordinates);
                break;
            }
        case ShapeTypes.POLYGON:
            {
                let coordinates = [];
                (shape.coordinates) && shape.coordinates[0].forEach((coordinate) =>
                {
                    coordinates.push(toMercatorFromArray(coordinate));
                });
                coordinates = [coordinates];

                geometry = new Polygon(coordinates);
                break;
            }

        // TEXT POINT are points that are already in mercator
        case ShapeTypes.TEXT_POINT:
            {
                geometry = new Point(shape.coordinates);
                break;
            }
        case ShapeTypes.CIRCLE:
            {

                let coordinates = toMercatorFromArray(shape.coordinates);
                geometry = new CircleGeom(coordinates, radius);
                break;
            }

        default:
            {
                geometry = {};
                break;
            }
    }

    return geometry;
};

/**
 * Create a custom text feature with the provided text.
 *
 * @param {string} text - The text to be used for the custom feature
 * @return {void}
 */
exports.createCustomTextStyle = (text) =>
{
    // create default text style
    const textStyle = new Style(MAP_TEXT_STYLE);

    const textObject = exports.createTextStyle(text, 0, MAP_ID_TEXT_STYLE);
    textStyle.setText(textObject);

    return textStyle;
};

exports.createCustomTextFeature = (text, coordinate) =>
{
    const textStyle = exports.createCustomTextStyle(text);

    // Create text geometry
    const textGeometry = exports.createEntityGeometry({ type: ShapeTypes.POINT, coordinates: coordinate });

    // attach it to style
    textStyle.setGeometry(textGeometry);

    let textId = text;

    let textFeature = new Feature({
        geometry: textGeometry,
        type: "TextPoint",
        coordinates: coordinate,
        id: textId,
        isText: true,
        //connected feature?
    });

    textFeature.setId(textId);

    (textStyle) && textFeature.setStyle(textStyle);

    return textFeature;
};

/**
 * Adds feature to the source of the layer
 * @param {*} layer
 * @param {*} feature
 */
exports.addFeatureToLayer = (layer, feature) =>
{
    const source = layer.getSource();
    source.addFeature(feature);
};

/**
 * Removes feature from the source of the layer
 * @param {*} layer
 * @param {*} feature
 */
exports.removeFeatureFromLayer = (layer, feature) =>
{
    const source = layer.getSource();
    source.removeFeature(feature);
};

/**
 * Checks style object if the entity style has a specific selected style.
 * If specific style does not exists, this function returns the default selected style in the object.
 * @deprecated
 * @param {Object} style - property or building style object. should contain a map from type to style.
 * @param {Object} entity - entity containg at least entityType and subEntityType
 */
exports.getEntitySelectedStyle = (style, entity) =>
{
    /* check for specific entity selcted style */
    let selectedStyle = deepValue(style, `${entity.entityType}.${entity.subEntityType}.selected`, undefined);

    /* If entity selected style does not exist, use default selected style */
    if (!selectedStyle)
    {
        selectedStyle = deepValue(style, "default.selected", undefined);
    }

    return selectedStyle;
};

/************************************************************************************************/
/**************************** HELPER FUNCTIONS USED TO CREATE LAYERS ****************************/
/************************************************************************************************/


/**
 * Apples placement and decision logic to the clusters based off of num words and area of entity.
 * @param {Object} feature - feature passed by style function
 */
exports.getClusterStyle = (feature) =>
{
    let textFeatures = feature.get("features");
    let displayedTextFeature;

    if (textFeatures.length === 1)
    {
        displayedTextFeature = textFeatures[0];
    }
    else
    {
        const filteredTextFeatures = filterTextFeaturesRecursive(textFeatures, 1, 3);
        displayedTextFeature = getTextFeatureWithBiggestArea(filteredTextFeatures);
    }


    let style = undefined;
    if (displayedTextFeature)
    {
        style = displayedTextFeature.getStyle();
        textFeatures.connectedFeature = displayedTextFeature.get("connectedFeature");
    }

    textFeatures.displayedTextFeature = displayedTextFeature;

    feature.set("features", textFeatures);

    return style;
};

/**
 * Used for text clustering.
 * Finds the text feature that has the biggest area.
 * Looks at the 'connectedFeature' to find property 'area'.
 * @param {*} textFeatures
 */
const getTextFeatureWithBiggestArea = (textFeatures) =>
{
    if (Array.isArray(textFeatures))
    {
        textFeatures.sort((a, b) =>
        {
            const areaA = a.get("connectedFeature").get("area");
            const areaB = b.get("connectedFeature").get("area");

            if (areaA < areaB)
            {
                return 1;
            }
            else if (areaA > areaB)
            {
                return -1;
            }
            else
            {
                return 0;
            }
        });

        return textFeatures[0];
    }
    else
    {
        return textFeatures;
    }
};

/**
 * Used for text clustering.
 * Filters text features based on num of words the text string.
 * If no names are found with number of words === num words, it will call itself recursively increasing word count by one untill it is greater than maxWords.
 *  In the case where the filtered list has no features, the orginal list is returned.
 * @param {*} textFeatures
 * @param {*} numWords
 */
const filterTextFeaturesRecursive = (textFeatures, numWords, maxWords = 3) =>
{
    if (numWords > maxWords)
    {
        return textFeatures;
    }

    if (Array.isArray(textFeatures))
    {
        let filteredTextFeatures = textFeatures.filter((feature) =>
        {

            let text = feature.getStyle().getText();

            if (!!text && typeof text === "string")
            {
                text = text.getText();
                const words = text.trim().split(" ");

                if (words.length === numWords)
                {
                    return true;
                }
            }

            return false;
        });

        if (filteredTextFeatures.length > 0)
        {
            return filteredTextFeatures;
        }
        else
        {
            return filterTextFeaturesRecursive(textFeatures, numWords + 1, maxWords);
        }

    }
    else
    {
        return textFeatures;
    }

};

exports.createImageLayer = ({ imgId, entityShape, rotation = 0, filerUrl, extent }) =>
{
    if (!imgId)
    {
        return undefined;
    }

    let convertedExtent = undefined;

    if (Array.isArray(extent))
    {
        let minPoint = [extent[0], extent[1]];
        let maxPoint = [extent[2], extent[3]];

        convertedExtent = [...toMercatorFromArray(minPoint), ...toMercatorFromArray(maxPoint)];
    }

    let imageSource = exports.createImageSource({ imgId, entityShape, filerUrl, extent: convertedExtent, rotation });

    let imageLayer = new ImageLayer({
        source: imageSource,
        crossOrigin: 'Anonymous',
    });

    return imageLayer;
};

exports.createIconFeature = ({ iconStyle, geometry, name, options = {} }) =>
{
    const iconFeature = new Feature({
        geometry: geometry,
        name: name,
        ...options
    });

    iconFeature.setStyle(iconStyle);

    return iconFeature;
};

/**
 * functionality that adds line breaks to strings mimicing what is currently done on mobile
 * @param {string} input
 * @returns {[isMult: boolean, result: string]} [isMult]
 */
exports.addLineBreaksToString = (input) =>
{
    if (typeof input !== "string")
    {
        return [false, ""];
    }
    const splitByWhite = input.split(/\s+/);
    if (splitByWhite.length > 2)
    {
        // add \n after second word
        return [true, splitByWhite.slice(0, 2).join(" ") + "\n" + splitByWhite.slice(2).join(" ")];
    }
    else if (splitByWhite.length === 2)
    {
        // add \n in between if either word is longer than 6
        if (splitByWhite.some((word) => word.length >= 7))
        {
            return [true, splitByWhite.join("\n")];
        }
    }
    return [false, splitByWhite.join(" ")];
};

exports.createPolygonStyleObject = (polygonStyle) =>
{
    const { fill, stroke } = polygonStyle;

    let styleObj = new Style({
        stroke: new Stroke(stroke),
        fill: new Fill(fill),
    });

    return styleObj;
};

exports.createTextStyleObject = (textStyle) =>
{
    const { fill, stroke, fontName, size } = textStyle;

    const font = getTextFontSettings({
        fontName,
        size: parseInt(size.replace("px", ""))
    });

    return {
        fill: new Fill(fill),
        stroke: new Stroke(stroke),
        font
    };
};


exports.createBoostDeviceIconStyle = (iconStyle) =>
{
    return new Style({
        zIndex: iconStyle.zIndex,
        image: new Icon({
            ...iconStyle.image,
        })
    });
};



/*geo-ref-floor-plan*/

const cursorStyles = [
    new Style({
        image: new Circle({
            radius: 5,
            stroke: new Stroke({
                color: "#fff",
                width: 1
            })
        })
    }),
    new Style({
        image: new Circle({
            radius: 5,
            stroke: new Stroke({
                color: "#000",
                width: 1
            })
        })
    }),

];



const mapActivePointStyles = [
    new Style({
        image: new RegularShape({
            radius: 10,
            points: 4,
            stroke: new Stroke(
                {
                    color: "#727aff",
                    width: 3
                })
        })
    }),
];

const activeImageCorOnImageStyles = [
    new Style({
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: "#F15944",
                width: 3
            })
        }),
    }),

];

/**
 * Retrieves the styles for a geo-referenced floor plan that includes text styles.
 *
 * @param {Feature} feature - The geo-referenced floor plan feature.
 * @return {Array} An array of styles including the common styles and a text style.
 */
exports.getStylesForGeoRefFloorPlanIncludingTextStyles = (feature) =>
{
    const indexOfFeature = getIndexOfFeature(feature);
    if (indexOfFeature)
    {
        return [
            ...this.getCommonGoeRefFloorPlanStyles(feature),
            new Style({
                text: new Text({
                    text: "" + indexOfFeature,
                    fill: new Fill({
                        color: "#000" // Text color
                    }),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.6)',
                        width: 1.5,
                    })
                })
            })
        ];
    }
};
exports.getCommonGoeRefFloorPlanStyles = (feature) =>
{


    let styles = cursorStyles;

    if (isGeoRef(feature) && !isImageFeature(feature))
    {
        styles = mapActivePointStyles;
    }

    if (isImageFeature(feature))
    {
        styles = activeImageCorOnImageStyles;
    }

    return styles;
};
