import PropTypes from "prop-types";
import React from "react";
import { PlaceholderBox } from "../common/placeholders/Placeholders";
import { ErrorWidgetMessage } from "../common/WidgetMessage/WidgetMessage";
import { DefaultBox } from "../dashboard/DefaultBox";

export const WidgetViewPropTypes = {
    isError: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ErrorComponent: PropTypes.func,
    LoadingComponent: PropTypes.func,
    boxClassName: PropTypes.string,
    onError: PropTypes.func,
};

const WidgetView = (props) => {
    const { isError, isLoading, ErrorComponent, LoadingComponent, children, isSuccess, onError, boxClassName } = props;
    const RenderBody = () => {
        if (isLoading) {
            if (!!LoadingComponent) return <LoadingComponent />;
            return <PlaceholderBox height="100%" width="100%" />;
        }

        if (isError) {
            if (!!ErrorComponent) {
                return <ErrorComponent />;
            }
            return (
                <div className="errorWidgetWrap">
                    <ErrorWidgetMessage onReloadClick={onError} />
                </div>
            );
        }

        if (!isSuccess) {
            if (!!LoadingComponent) return <LoadingComponent />;
            return <PlaceholderBox height="100%" width="100%" />;
        }

        return children;
    };

    return (
        <DefaultBox {...props} className={boxClassName}>
            <RenderBody />
        </DefaultBox>
    );
};

WidgetView.prototype = WidgetViewPropTypes;
export default WidgetView;
