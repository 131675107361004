import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button, Dropdown, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";
import { REPORTS_ROUTES } from "../../_constants/constants";

export const ExportButton = ({ generatePdf, onExportToCSV }) =>
{
    const trans = useTranslation().t;
    let currentPath = useLocation();
    let pathName = currentPath.pathname.split("/")[currentPath.pathname.split("/").length - 1];
    const isEngagement = pathName === REPORTS_ROUTES.ENGAGEMENT || pathName === REPORTS_ROUTES.ENGAGEMENT_WEB; //to find out in which page we are and calculate orientation

    const handleExportClick = useCallback(() =>
    {
        if (isEngagement)
        {
            generatePdf("engagement");
        }
        else
        {
            generatePdf("reports");
        }
    }, [isEngagement]);

    return (
        <Dropdown text={<>{trans("ExportButton.Export")}</>} className="ExportDropDown" >
            <DropdownMenu direction={"left"} >
                <DropdownItem text="Generate Pdf" onClick={generatePdf} />
                <DropdownItem text="Export to CSV" onClick={onExportToCSV} />

            </DropdownMenu>
        </Dropdown >

    );
};
